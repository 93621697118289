import { admin } from "../../../actions/action.types";
import { convertObjToFields, checkFormValidation } from "../../../common/forms";
import createReducer from "../../createReducer";

const initialState = {
  fields: {
    useMinuteSensorAggregation: {},
    useTenMinuteSensorAggregation: {},
    useHourSensorAggregation: {},
  },
  isSaving: false,
  hasChanges: false,
  error: undefined,
  vesselId: undefined,
};

export const configureSensorReplication = createReducer(initialState, {
  [admin.vessels.sensorReplication.CONFIGURE]: (state, { vessel }) => {
    return {
      ...initialState,
      fields: convertObjToFields({
        useMinuteSensorAggregation: vessel.useMinuteSensorAggregation,
        useTenMinuteSensorAggregation: vessel.useTenMinuteSensorAggregation,
        useHourSensorAggregation: vessel.useHourSensorAggregation,
      }),
      vesselId: vessel.id,
    };
  },

  [admin.vessels.sensorReplication.SET_VALUE]: (state, { name, value }) => {
    const field = state.fields[name];
    return checkFormValidation({
      ...state,
      fields: {
        ...state.fields,
        [name]: {
          ...field,
          value: value,
          hasChanges: true,
        },
      },
    });
  },

  [admin.vessels.sensorReplication.SAVE]: (state) => ({
    ...state,
    isSaving: true,
    error: undefined,
    errorMessage: undefined,
  }),

  [admin.vessels.sensorReplication.SAVE_SUCCESS]: (state) => ({
    ...state,
    isSaving: false,
  }),

  [admin.vessels.sensorReplication.SAVE_ERROR]: (state, { error }) => {
    const errorResponse = error && error.response;
    const errorData = errorResponse && errorResponse.data;
    const getRemoteServiceError = () =>
      errorResponse.status === 502
        ? ` (A remote service I depend on failed: ${errorData.remoteMessage})`
        : "";
    const getErrorResponseMessage = () =>
      errorResponse ? `Error ${errorResponse.status}` : "Unknown error";
    const errorMessage = errorData
      ? `${errorData.message} ${getRemoteServiceError()}`
      : getErrorResponseMessage();

    return {
      ...state,
      isSaving: false,
      error,
      errorMessage,
    };
  },
});
