import {
  DATEPICKER_UPDATE,
  DATEPICKER_SHOW,
  DATEPICKER_HIDE,
  DATEPICKER_SELECT,
  DATEPICKER_CHANGE_TYPE,
  DATEPICKER_NEXT_OPTIONS,
  DATEPICKER_PREV_OPTIONS,
  DATEPICKER_CHANGE_DATE,
} from "./action.types";

export const update = (context) => ({ type: DATEPICKER_UPDATE, context });

export const show = (context) => ({ type: DATEPICKER_SHOW, context });

export const hide = () => ({ type: DATEPICKER_HIDE });

export const select = (option, context) => ({
  type: DATEPICKER_SELECT,
  option,
  context,
});

export const getNextOptions = (context) => ({
  type: DATEPICKER_NEXT_OPTIONS,
  context,
});

export const getPrevOptions = (context) => ({
  type: DATEPICKER_PREV_OPTIONS,
  context,
});

export const changeType = (type, context) => ({
  type: DATEPICKER_CHANGE_TYPE,
  dateType: type,
  context,
});

export const changeDate = (dateValues, context) => ({
  type: DATEPICKER_CHANGE_DATE,
  dateValues,
  context,
});
