import createReducer from "./createReducer";
import { components } from "../actions/action.types";
import { mean, meanBy } from "lodash";

export default createReducer(
  {},
  {
    [components.equinor.FETCH_VESSEL_REPORT]: () => {
      return {
        isLoading: true,
      };
    },
    [components.equinor.FETCH_VESSEL_REPORT_SUCCESS]: (state, { data }) => {
      const operationGroups = data.operationGroups || [];
      return {
        ...state,
        operationGroups,
        isLoading: false,
      };
    },
    [components.equinor.FETCH_VESSEL_REPORT_ERROR]: (_state, { error }) => {
      return {
        isLoading: false,
        error: `Error loading Equinor Report: ${error}`,
      };
    },
  }
);

export const calculateEquionorOperations = (operationGroups, unit = "l") => {
  const operations = operationGroups.map((x) => {
    return {
      name: x.name,
      legends: x.operations.map((o) => o.legend),
      avgFuelConsumption: mean(
        x.operations.map((o) => {
          switch (unit) {
            case "l":
              return o.avgFuelConsumption;
            case "kg":
              return o.avgFuelConsumption * o.avgDensity;
          }
        })
      ),
    };
  });
  return {
    operations,
    average: meanBy(operations, "avgFuelConsumption"),
  };
};

export const calculateDailyOperations = (operationGroups, unit = "m3") => {
  const operations = operationGroups.map((x) => {
    return {
      name: x.name,
      legends: x.operations.map((o) => o.legend),
      avgFuelConsumption: mean(
        x.operations.map((o) => {
          switch (unit) {
            case "m3":
              return (o.avgFuelConsumption * 24) / 1000;
            case "t":
              return (o.avgFuelConsumption * o.avgDensity * 24) / 1000;
          }
        })
      ),
    };
  });
  return {
    operations,
    average: meanBy(operations, "avgFuelConsumption"),
  };
};
