import styles from "./Logo.css";

import React from "react";
import logoSrc from "../../../style/img/VP_Icon_SVG_100px.svg";

export default class Logo extends React.Component {
  render() {
    return (
      <div
        className={styles.logo}
        style={{ backgroundImage: `url(${logoSrc})` }}
        onClick={this.props.clickAction}
      />
    );
  }
}
