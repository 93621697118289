import { Header } from "../../components/Header";
import React, { useEffect } from "react";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import { DateRange, DatePickerValue } from "../../components/DatePicker/Types";
import Grid, { GridCell } from "../../components/Grid";
import { useSelector } from "react-redux";
import {
  convertFiltersToQueryParams,
  DateFilter,
  getDateRangeFilter,
  getGroupFilter,
} from "../../selectors/filters";
import { createSelector } from "reselect";
import { VIEWS } from "../../config";
import MapContainer from "../../pageConfig/MapContainer";
import FleetActivity from "../FleetActivity/FleetActivity";
import TopPerformingVessels from "../TopPerformingVessels/TopPerformingVessels";
import styles from "./MainDashboard.css";
import { OperationClassID } from "../../../common/operation/operationClassID";
import Emissions from "../Emissions/Emissions";
import FleetCIIScore from "../FleetCIIScore/FleetCIIScore";
import { ManualFuelDataIndicator } from "../../components/ManualFuelDataIndicator/ManualFuelDataIndicator";
import { IState } from "../../components/FuelEmissions/commonTypes";
import { useQueryContext } from "../../providers/QueryContextProvider";
import { Direction } from "../../common/direction";
import SelectBox from "../../components/SelectBox";
import { getNonEmptyVesselGroups } from "../../reducers/reducer.pageContext";

const MainDashboard = () => {
  const queryContext = useQueryContext();

  const getFilters = createSelector(
    [getDateRangeFilter, getGroupFilter],
    (dateFilter: DateFilter, groupFilter: any) => {
      return {
        isValid: dateFilter.isValid && groupFilter.isValid,
        dateFilter,
        groupFilter,
      };
    }
  );

  const filters = useSelector((state) => getFilters(state, { queryContext }));
  const pageContext = useSelector((state: IState) => state.pageContext);
  const groups = getNonEmptyVesselGroups(pageContext) || [];

  useEffect(() => {
    if (!filters.isValid) {
      queryContext.navigate(
        VIEWS.mainDashboard.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }, []);

  const datePickerLimits = {
    from: new Date(2000, 0, 1),
    to: null,
  };

  function onDateChanged(newDate: DatePickerValue) {
    queryContext.setDate(newDate);
  }

  function getInterval(): DateRange {
    return {
      from: filters.dateFilter.value.range.from,
      to: filters.dateFilter.value.range.to,
    };
  }

  function getGroup(): string {
    return filters.groupFilter.value;
  }

  const hasVesselWithManualFuelData: boolean = useSelector((state: IState) => {
    const vessels = state.pageContext.vessels;
    let hasVesselWithManualFuelData = false;
    for (const key in vessels) {
      if (vessels[key].useManualFuelDataOnly === true) {
        hasVesselWithManualFuelData = true;
        break;
      }
    }
    return hasVesselWithManualFuelData;
  });

  return (
    <div>
      <Header title="Main Dashboard" contentDistribution="space-between">
        {filters.groupFilter.length === 0 || (
          <SelectBox
            options={groups}
            optionValKey="id"
            optionLabelKey="name"
            onSelect={(option: any) =>
              queryContext.setSelectedGroupId(option, "")
            }
            selected={groups.find((x) => x.id === queryContext.groupId)}
          />
        )}
        <div style={{ flexGrow: 1 }} />
        {hasVesselWithManualFuelData && <ManualFuelDataIndicator />}
        <div
          className={`${styles.datepickerContainer} ${styles.rightHeaderContainer}`}
        >
          {filters.isValid && (
            <DatePickerContainer
              limits={datePickerLimits}
              onChange={onDateChanged}
              current={filters.dateFilter.value}
              supportedTypes={["year", "quarter", "month"]}
            />
          )}
        </div>
      </Header>
      <div>
        <Grid>
          <GridCell cellSpan={2}>
            <Emissions interval={getInterval()} group={getGroup()} />
          </GridCell>
          <GridCell>
            <FleetActivity interval={getInterval()} group={getGroup()} />
          </GridCell>
          <GridCell>
            <TopPerformingVessels
              interval={getInterval()}
              group={getGroup()}
              operationType={OperationClassID.DP}
              direction={Direction.LowToHigh}
              numberOfItems={5}
            />
          </GridCell>
          <GridCell>
            <TopPerformingVessels
              interval={getInterval()}
              group={getGroup()}
              operationType={OperationClassID.Transit}
              direction={Direction.HighToLow}
              numberOfItems={5}
            />
          </GridCell>
        </Grid>
        <Grid>
          <GridCell cellSpan={2}>
            <FleetCIIScore group={getGroup()} />
          </GridCell>
          <GridCell cellSpan={3}>
            <MapContainer />
          </GridCell>
        </Grid>
      </div>
    </div>
  );
};

export default MainDashboard;
