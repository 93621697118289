// NOTE: This file is generated from Parameters.css by the webpack-build
import parameters from "../style/css/Parameters.generated.js";
export type CssParam = keyof typeof parameters.customProperties;

export const cssParam = <T extends string | undefined>(
  name: CssParam,
  defaultValue: T
): T extends string ? string : string | undefined => {
  const value = parameters.customProperties[name]?.trim() ?? "";
  if (!value) {
    console.warn(`Invalid CSS parameter ${name} requested.`);
  }
  return (value || defaultValue) as T extends string
    ? string
    : string | undefined;
};
