import React, { Component } from "react";
import styles from "./Auth.css";

export default class LoggedOut extends Component {
  render() {
    return (
      <div className={styles.container}>
        <h1>You are logged out</h1>
        <p>
          Go back to the <a href="/">dashboard</a>.
        </p>
      </div>
    );
  }
}
