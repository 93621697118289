import React from "react";
import { connect } from "react-redux";
import { groupBy, isNil } from "lodash";
import {
  fetchVesselGroups,
  fetchUserGroups,
  closeUserAccessPopup,
  saveUserGroupsInCompany,
  setUserAccessSelectedCompany,
  setUserAccessSelectedGroups,
} from "../../../actions/admin";
import { FormPopup } from "../../../components/Form";
import {
  selectAllCompanies,
  selectCompanyById,
} from "../../../reducers/admin/reducer.companies";
import {
  selectGroupsByIds,
  selectGroupsByCompany,
  selectGroupById,
} from "../../../reducers/admin/reducer.vesselGroups";
import { UserAccessForm } from "./UserAccessForm";

class UserAccessPopup extends React.Component {
  constructor(props) {
    super(props);
    this.onPopupFormHide = this.onPopupFormHide.bind(this);
    this.onPopupFormSave = this.onPopupFormSave.bind(this);
    this.onPopupFormCancel = this.onPopupFormCancel.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onGroupsChange = this.onGroupsChange.bind(this);
  }

  onPopupFormHide() {
    this.props.closeUserAccessPopup();
  }

  onPopupFormSave() {
    this.props.saveUserGroupsInCompany(this.props.accessEditor);
  }

  onPopupFormCancel() {
    this.props.closeUserAccessPopup();
  }

  onCompanyChange(_name, company) {
    this.props.setUserAccessSelectedCompany(company.id);
  }

  onGroupsChange(_name, groups) {
    this.props.setUserAccessSelectedGroups(groups);
  }

  componentDidMount() {
    this.props.fetchVesselGroups();
    this.props.fetchUserGroups(this.props.accessEditor.userId);
  }

  render() {
    const {
      companies,
      groups,
      selectedCompany,
      selectedGroups,
      currentAssignments,
    } = this.props;

    const { isSaving, hasChanges } = this.props.accessEditor;
    return (
      <FormPopup
        title={"Edit User Group Roles"}
        visible={true}
        isSaving={isSaving}
        enableSave={hasChanges && !isSaving}
        onHide={this.onPopupFormHide}
        onSave={this.onPopupFormSave}
        onCancel={this.onPopupFormCancel}
      >
        <UserAccessForm
          companies={companies}
          groups={groups}
          selectedCompany={selectedCompany}
          selectedGroups={selectedGroups}
          currentAssignments={currentAssignments}
          onCompanyChange={this.onCompanyChange}
          onGroupsChange={this.onGroupsChange}
          onRolesChange={this.onRolesChange}
        />
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => {
  const companies = selectAllCompanies(state.admin.companies);
  const accessEditor = state.admin.users.accessEditor;

  const assignmentsByCompany = groupBy(
    accessEditor.userGroups
      .filter(
        (x) =>
          !isNil(selectGroupById(x.vesselGroupId, state.admin.vesselGroups)) &&
          !isNil(selectCompanyById(x.companyId, state.admin.companies))
      )
      .map(({ vesselGroupId, companyId }) => ({
        group: selectGroupById(vesselGroupId, state.admin.vesselGroups).name,
        company: selectCompanyById(companyId, state.admin.companies).name,
      })),
    "company"
  );

  return {
    accessEditor,
    companies,
    groups: selectGroupsByCompany(
      accessEditor.selectedCompanyId,
      state.admin.vesselGroups
    ),
    selectedCompany: selectCompanyById(
      accessEditor.selectedCompanyId,
      state.admin.companies
    ),
    selectedGroups: selectGroupsByIds(
      accessEditor.selectedGroupIds,
      state.admin.vesselGroups
    ),
    currentAssignments: assignmentsByCompany,
  };
};

const mapDispatchToProps = {
  fetchVesselGroups,
  fetchUserGroups,
  closeUserAccessPopup,
  saveUserGroupsInCompany,
  setUserAccessSelectedCompany,
  setUserAccessSelectedGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessPopup);
