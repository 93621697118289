import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import { ConfiguredGrid } from "../../pageConfig/ConfiguredGrid";
import {
  selectPageConfig,
  filterPageConfigs,
  filterAdminDashboards,
} from "../../reducers/reducer.pageConfig";
import { setSelectedDashboard } from "../../actions/action.setSelectedDashboard";
import SelectBox from "../../components/SelectBox";
import { isKongsbergAdmin } from "../../common/hasAccess";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboards: [],
      pageConfig: {},
    };

    this.onDashboardSelect = this.onDashboardSelect.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.state.dashboards) !==
      JSON.stringify(this.props.dashboards)
    ) {
      this.updateState();
    } else if (this.state.pageConfig.id !== this.props.pageConfig.id) {
      this.setState((state) => ({
        ...state,
        pageConfig: this.updatePageConfig(
          this.state.dashboards,
          this.props.pageConfig
        ),
      }));
    }
  }

  onDashboardSelect(dashboard) {
    this.props.setSelectedDashboard(dashboard);
  }

  renderDashboardSelector() {
    if (this.props.dashboards.length <= 1) {
      return null;
    }
    return (
      <SelectBox
        options={this.state.dashboards}
        optionValKey="id"
        optionLabelKey="name"
        onSelect={this.onDashboardSelect}
        selected={this.state.pageConfig}
      />
    );
  }

  updateState() {
    this.setState(() => ({
      dashboards: this.props.dashboards,
      pageConfig: this.updatePageConfig(
        this.props.dashboards,
        this.props.pageConfig
      ),
    }));
  }

  updatePageConfig(dashboards, pageConfig) {
    return pageConfig && dashboards.find((d) => d.id === pageConfig.id);
  }

  render() {
    return (
      <div>
        <Header title="Dashboard" contentDistribution="space-between">
          {this.renderDashboardSelector()}
        </Header>
        <ConfiguredGrid config={this.props.pageConfig} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userRoles = state.user.roles;
  const userVesselGroups = Object.values(state.pageContext.groups);
  const pageConfigs = state.pageConfig.dashboard;
  const { companyId, companyName } = state.user;
  return {
    dashboards: isKongsbergAdmin(userRoles, companyId)
      ? filterAdminDashboards(
          pageConfigs,
          userVesselGroups,
          companyId,
          companyName
        ) || []
      : filterPageConfigs(pageConfigs, companyId) || [],
    pageConfig:
      selectPageConfig(
        state.user,
        state.pageConfig,
        state.pageContext.selectedVesselId,
        state.pageContext.vessels[state.pageContext.selectedVesselId],
        state.pageContext.groups[state.pageContext.selectedGroupId],
        "dashboard",
        {
          userDefaultId: state.user.defaultDashboardId,
          selectedId: state.dashboard.selectedDashboardId,
          userRoles,
          userVesselGroups,
        }
      ) || {},
  };
};

const mapDispatchToProps = {
  setSelectedDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
