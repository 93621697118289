import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../../../common/components/Loader/Loader";
import styles from "./HealthManagementEventEdit.css";
import DataDetails from "../../../../components/DataDetails/DataDetails";

export default class HealthManagementEventEditData extends React.PureComponent {
  render() {
    const { eventDetails } = this.props;
    const { loading, data } = eventDetails;
    if (loading) {
      return <Loader text={"Loading event data"} expand={true} />;
    }
    if (!data) {
      return <div />;
    }
    return (
      <div className={styles.dataContainer}>
        <div className={styles.header}>Event Data</div>
        <div className={styles.content}>
          <DataDetails dataDetails={data} />
        </div>
      </div>
    );
  }
}

HealthManagementEventEditData.propTypes = {
  eventDetails: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.any,
  }).isRequired,
};
