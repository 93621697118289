import React from "react";
import PropTypes from "prop-types";
import { FormCheckBox } from "../../../../components/Form";
import styles from "./VesselItemMetrics.css";
import { sortBy } from "lodash";

export const VesselItemMetrics = ({ metrics, onChange }) => {
  return (
    <table className={styles.vesselItemMetricsContainer}>
      <thead>
        <tr>
          <th>Metric</th>
          <th>Trend</th>
          <th>Operation</th>
          <th>Operation details</th>
          <th>Compare</th>
        </tr>
      </thead>
      <tbody>
        {sortBy(metrics, "metric").map((x) => renderMetricRow(x, onChange))}
      </tbody>
    </table>
  );
};

const renderMetricRow = (row, onChange) => {
  const {
    metric,
    metricId,
    showOperation,
    showOperationDetail,
    showTrend,
    showCompare,
  } = row;

  return (
    <tr key={metricId}>
      <th>{metric}</th>
      <td>
        <FormCheckBox
          value={showTrend}
          label=""
          name={metricId + "_T"}
          onChange={onChange}
        />
      </td>
      <td>
        <FormCheckBox
          value={showOperation}
          label=""
          name={metricId + "_O"}
          onChange={onChange}
        />
      </td>
      <td>
        <FormCheckBox
          value={showOperationDetail}
          label=""
          name={metricId + "_D"}
          onChange={onChange}
        />
      </td>
      <td>
        <FormCheckBox
          value={showCompare}
          label=""
          name={metricId + "_C"}
          onChange={onChange}
        />
      </td>
    </tr>
  );
};

VesselItemMetrics.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      metricId: PropTypes.string.isRequired,
      showTrend: PropTypes.bool.isRequired,
      showOperation: PropTypes.bool.isRequired,
      showOperationDetail: PropTypes.bool.isRequired,
      showCompare: PropTypes.bool.isRequired,
    })
  ),
};

export default VesselItemMetrics;
