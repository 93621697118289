import { TOGGLE_NAV, FETCH_USER_DATA_SUCCESS } from "../actions/action.types";
import createReducer from "./createReducer";
import { get } from "lodash";

const initialState = {
  isOpen: false,
  isToggleRequested: false,
  menu: { admin: [], main: [] },
};

export const nav = createReducer(initialState, {
  [TOGGLE_NAV]: (state) => ({ ...state, isOpen: !state.isOpen }),
  [FETCH_USER_DATA_SUCCESS]: (state, action) => {
    return {
      ...state,
      menu: {
        admin: get(action, "data.menu.admin", []),
        main: get(action, "data.menu.main", []),
      },
    };
  },
});
