import React, { Component } from "react";
import { connect } from "react-redux";
import { Portal } from "react-portal";
import { hide, show } from "../../actions/action.dataQuality";
import styles from "./DataQualityPopup.css";
import loader from "../../../common/components/Loader/Loader.css";
import centeredModal from "../../../style/css/CenteredModal.css";
import Button from "../../../common/components/Button";
import classNames from "../../../common/classNames";
import { formatNumber } from "../../../common/numbers";
import { dataQuality } from "../../../common/boundaries";
import Loader from "../../../common/components/Loader";
import { FormTextBox } from "../../components/Form";
import { formatDateTime } from "../../common/dates";
import { formatDurationSec } from "../../../common/durations";
import * as moment from "moment";

class DataQualityPopup extends Component {
  constructor(props) {
    super(props);
  }
  renderDataQuality = () => {
    const { selectedPeriod, lastPageRefresh } = this.props;
    const { maxHullOperationStartTime, missingDataSeconds } =
      selectedPeriod || {};

    const pageRefresh =
      lastPageRefresh && `${formatDateTime(lastPageRefresh)} UTC`;
    const lastData =
      maxHullOperationStartTime &&
      `${formatDateTime(maxHullOperationStartTime)} UTC`;
    const missingDuration =
      missingDataSeconds &&
      `${moment
        .duration(missingDataSeconds, "seconds")
        .humanize()} (${formatDurationSec(missingDataSeconds)})`;
    return (
      <div className={styles.form}>
        <FormTextBox
          readOnly={true}
          name="pageRefresh"
          label="Page refresh"
          value={pageRefresh}
        />
        <FormTextBox
          readOnly={true}
          name="lastData"
          label="Last data for selected period"
          value={lastData}
        />
        <FormTextBox
          readOnly={true}
          name="missingDuration"
          label="Missing data in selected period"
          value={missingDuration}
        />
      </div>
    );
  };
  render() {
    const { selectedPeriod, isLoading, showPopup, hide, show, error } =
      this.props;
    const dataPercentage = selectedPeriod && selectedPeriod.dataQuality;

    let displayText;
    let displayClassNames;

    if (isLoading) {
      displayText = "";
    } else {
      displayText = dataPercentage
        ? `${formatNumber(dataPercentage, "percent")}`
        : "-";
    }
    if (isLoading || !dataPercentage || dataPercentage === 0) {
      displayClassNames = classNames(
        styles.dataQualityLevel,
        styles.dataQualityLevelUnknown
      );
    } else if (dataPercentage >= dataQuality.percentage.BEST) {
      displayClassNames = classNames(
        styles.dataQualityLevel,
        styles.dataQualityLevelOk
      );
    } else if (dataPercentage >= dataQuality.percentage.GOOD) {
      displayClassNames = classNames(
        styles.dataQualityLevel,
        styles.dataQualityLevelSoSo
      );
    } else if (dataPercentage >= dataQuality.percentage.OKAY) {
      displayClassNames = classNames(
        styles.dataQualityLevel,
        styles.dataQualityLevelPoor
      );
    }

    return (
      <div>
        <Button
          type="content"
          margin="0px 0px 0px 16px"
          width="120px"
          clickAction={show}
        >
          <div className={displayClassNames} />

          {isLoading ? (
            <span className={classNames(loader.spinner, styles.spinner)}>
              |
            </span>
          ) : (
            <span className={styles.dataQualityLabel}>{displayText}</span>
          )}
        </Button>

        {showPopup && (
          <Portal node={document && document.getElementById("popupContainer")}>
            <div className={centeredModal.container}>
              <div className={centeredModal.overlay} onClick={hide} />

              <div
                className={classNames(
                  centeredModal.boxContainer,
                  styles.boxContainer
                )}
              >
                <div className={centeredModal.content}>
                  <div className={centeredModal.header}>
                    <div>Data quality</div>
                    <button className={centeredModal.resetBtn} onClick={hide}>
                      ×
                    </button>
                  </div>
                  <div className={centeredModal.body}>
                    {isLoading ? (
                      <Loader text="Loading" error={error} />
                    ) : (
                      this.renderDataQuality()
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.pageContext.dataQuality.error,
    isLoading: state.pageContext.dataQuality.isLoading,
    lastPageRefresh: state.pageContext.dataQuality.lastPageRefresh,
    selectedPeriod: state.pageContext.dataQuality.selectedPeriod,
    showPopup: state.pageContext.dataQuality.showPopup,
  };
};

const mapDispatchToProps = {
  hide,
  show,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataQualityPopup);
