import t from "prop-types";
import React from "react";
import styles from "./VesselStatus.css";
import moment from "moment";

const _6_hours = 6 * 60 * 60;
const _24_hours = 24 * 60 * 60;

const statusStyle = ({ dataAgeInSeconds }) => {
  if (dataAgeInSeconds < 0) {
    return styles.gray;
  } else if (dataAgeInSeconds < _6_hours) {
    return styles.green;
  } else if (dataAgeInSeconds < _24_hours) {
    return styles.yellow;
  }
  return styles.red;
};

const classNames = (props) => `${styles.vesselStatus} ${statusStyle(props)}`;

const dataAge = ({ dataAgeInSeconds, pingAgeInSeconds }) => {
  return humanizeDataAge(dataAgeInSeconds) + humanizePingAge(pingAgeInSeconds);
};

const humanizeDataAge = (dataAgeInSeconds) => {
  if (!dataAgeInSeconds && dataAgeInSeconds !== 0) {
    return "More than a month";
  }

  return dataAgeInSeconds < 0
    ? "n/a"
    : moment.duration(-dataAgeInSeconds, "seconds").humanize();
};

const humanizePingAge = (pingAgeInSeconds) => {
  return !pingAgeInSeconds
    ? ""
    : " (ping: " +
        moment.duration(-pingAgeInSeconds, "seconds").humanize() +
        ")";
};

export const VesselStatus = (props) => (
  <div className={classNames(props)}>
    <h1>{props.vesselName}</h1>
    <p>{props.companyName}</p>
    <p>
      <small>{dataAge(props)}</small>
    </p>
  </div>
);

VesselStatus.propTypes = {
  vesselId: t.string.isRequired,
  vesselName: t.string.isRequired,
  companyName: t.string.isRequired,
  dataAgeInSeconds: t.number.isRequired,
};
