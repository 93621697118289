import React from "react";
import Popup from "../../../components/Popup/Popup";
import PropTypes from "prop-types";
import styles from "./ConfirmDeleteDialog.css";

export default class ConfirmDeleteDialog extends React.PureComponent {
  render() {
    const { title, message, onConfirmClick, onCancelClick } = this.props;

    return (
      <Popup
        visible={true}
        testId={"confirmDeleteDialog"}
        title={title}
        onHide={onCancelClick}
        target={"popupContainer"}
        buttons={[
          {
            label: "Cancel",
            action: onCancelClick,
          },
          {
            label: "Confirm delete",
            action: onConfirmClick,
          },
        ]}
      >
        <div className={styles.content}>
          <p>{message}</p>
        </div>
      </Popup>
    );
  }
}

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};
