import { VESSEL_STATUS_HISTORY } from "../api";
import {
  FETCH_VESSEL_STATUS_HISTORY,
  SET_VESSEL_STATUS_HISTORY_SELECTED_DAY,
} from "./action.types";
import fetch from "./action.fetch";

export const setVesselStatusHistorySelectedDay =
  (selectedDay) => (dispatch) => {
    dispatch({
      type: SET_VESSEL_STATUS_HISTORY_SELECTED_DAY,
      selectedDay,
    });
  };

export const fetchVesselStatusHistoryData = (vesselId, dateRange, timeOffset) =>
  fetch({
    url: VESSEL_STATUS_HISTORY.get(vesselId, dateRange, timeOffset),
    actionType: FETCH_VESSEL_STATUS_HISTORY,
  });
