import {
  all,
  call,
  fork,
  getContext,
  put,
  takeLeading,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import { downloadFile } from "../../common/downloads";
import { orderBy } from "lodash";

const actions = vesselConfig.onboardProcessorConfig;
const api = vesselConfigApi.onboardProcessorConfig;
const baselineApi = vesselConfigApi.baseline;

///
/// Initialize
///

export function* initOnboardProcessorConfig({ vesselId }) {
  yield call(fetchData, { vesselId });
  yield fork(deployWatcher);
  yield fork(downloadWatcher);
  yield fork(generateWatcher);
}

///
/// Fetch data
///

function* fetchData({ vesselId }) {
  const http = yield getContext("http");

  yield put({ type: actions.FETCH_DATA, vesselId: vesselId });
  try {
    const [configsRes, baselinesRes, processorBuildRes] = yield all([
      call(http.get, api.getConfigs(vesselId)),
      call(http.get, baselineApi.getBaselines(vesselId)),
      call(http.get, api.getProcessorBuild(vesselId)),
    ]);
    yield put({
      type: actions.FETCH_DATA_SUCCESS,
      vesselId,
      configs: configsRes.data,
      baselines: baselinesRes.data,
      processorBuild: processorBuildRes.data,
    });
    yield put({
      type: actions.SELECT_BASELINE,
      selected: orderBy(baselinesRes.data, "version", "desc")[0],
    });
  } catch (error) {
    yield put({ type: actions.FETCH_DATA_ERROR, error });
  }
}

///
/// Deploy
///

function* deployWatcher() {
  yield takeLeading(actions.DEPLOY_REQUESTED, deployConfig);
}

function* deployConfig({ vesselId, configId }) {
  const http = yield getContext("http");
  try {
    yield put({ type: actions.DEPLOY, vesselId, configId });
    const res = yield call(http.post, api.deploy(vesselId, configId));
    yield put({
      type: actions.DEPLOY_SUCCESS,
      vesselId,
      configId,
      data: res.data,
    });
  } catch (err) {
    yield put({ type: actions.DEPLOY_ERROR, vesselId, configId, error: err });
  }
}

///
/// Download
///

function* downloadWatcher() {
  yield takeLeading(actions.DOWNLOAD_REQUESTED, downloadConfigSaga);
}

function* downloadConfigSaga({ vesselId, configId }) {
  const http = yield getContext("http");

  yield put({ type: actions.DOWNLOAD, vesselId, configId });
  try {
    const res = yield call(http, {
      method: "post",
      url: api.download(vesselId, configId),
      responseType: "arraybuffer",
    });
    yield call(downloadFile, res);
    yield put({ type: actions.DOWNLOAD_SUCCESS, vesselId, configId });
  } catch (err) {
    yield put({ type: actions.DOWNLOAD_ERROR, vesselId, configId, error: err });
  }
}

///
/// Generate
///

function* generateWatcher() {
  yield takeLeading(actions.GENERATE_REQUESTED, generateConfigSaga);
}

function* generateConfigSaga({ vesselId, baselineId }) {
  const http = yield getContext("http");

  yield put({ type: actions.GENERATE });

  try {
    const { data } = yield call(http.post, api.generate(vesselId), {
      baselineId,
    });
    yield put({ type: actions.GENERATE_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.GENERATE_ERROR, error });
  }
}
