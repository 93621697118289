import { Services } from "./services";
import { AppConfig } from "../../config/onshore/environments/AppConfig";
import { Dispatch } from "redux";
import { setAccessToken } from "./reducers/reducer.auth";
import AuthService from "./authentication/AuthService";
import IAuthService from "./authentication/IAuthService";

export type RootContext = Services & {
  authService: IAuthService;
};

export const createRootContext = (
  services: Services,
  appConfig: AppConfig,
  dispatch: Dispatch
): RootContext => {
  const updateAccessTokenInStore = (accessToken: string) =>
    dispatch(setAccessToken(accessToken));
  const updateAccessTokenInHttpClient = (accessToken: string) =>
    (services.http.defaults.headers.Authorization = `Bearer ${accessToken}`);
  const authService = new AuthService(
    appConfig.OIDC_AUTHORITY,
    appConfig.OIDC_CLIENT_ID,
    [updateAccessTokenInStore, updateAccessTokenInHttpClient]
  );

  return {
    ...services,
    authService,
  };
};
