import { vesselConfig } from "../action.types";

export const init = (vesselId, isNew) => ({
  type: vesselConfig.INIT,
  vesselId,
  isNew,
});

export const togglePanel = (name, isVisible) => ({
  type: vesselConfig.TOGGLE_PANEL,
  name,
  isVisible,
});

export const close = () => ({
  type: vesselConfig.CLOSE,
});
