import React from "react";
import PropTypes from "prop-types";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

const buttonStyle = {
  position: "absolute !important",
  margin: "-2px auto auto -5px",
  right: "6px",
  height: "24px",
  width: "24px",
};

export default class DataDetailsTooltip extends React.Component {
  render() {
    const { title, text } = this.props;

    return text ? (
      <div>
        <ComponentInfo
          modalTargetId={"popupContainer"}
          title={title}
          text={text}
          buttonStyle={buttonStyle}
        >
          {" "}
        </ComponentInfo>
      </div>
    ) : null;
  }
}

DataDetailsTooltip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
