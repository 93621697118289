import React, { useEffect, useState } from "react";
import styles from "./SetFuelTargetsDialog.css";
import { FormGroup, FormPopup, FormTextBox } from "../../../components/Form";
import {
  getLegendColor,
  getLegendName,
  getLegendTextColor,
} from "../OperationProfileHelper";
import {
  IState,
  IFuelTargetsData,
  IOperationTargetsData,
} from "../commonTypes";
import Loader from "../../../../../src/common/components/Loader";
import { IFuelDataFieldValue } from "../../../../onshore/reducers/reducer.fuelEmissions";
import {
  fetchFuelTargets,
  targetFuelDataFieldValueChanged,
} from "../../../../onshore/actions/action.fuelEmissions";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../src/common/components/Button";
import { AnyAction } from "redux";
import moment from "moment";

interface IProps {
  visible: boolean;
  vesselId: string;
  lastUpdate?: string;
  save: () => void;
  cancel: () => void;
  hide: () => void;
}

function SetFuelTargetsDialog({
  visible,
  vesselId,
  lastUpdate,
  save,
  cancel,
  hide,
}: IProps): JSX.Element {
  const { hasChanges, isLoading, error } = useSelector(
    (state: IState) => state.fuelEmissions.setTargetFuelDataDialog
  );
  const { fuels, data } = useSelector(
    (state: IState) =>
      state.fuelEmissions.setTargetFuelDataDialog.data ??
      ({} as IFuelTargetsData)
  );
  const [lastVesselId, setLastVesselId] = useState("");
  const dispatch = useDispatch();
  const rowValueChanged = (value: IFuelDataFieldValue) =>
    dispatch(targetFuelDataFieldValueChanged(value));
  useEffect(() => {
    if (visible && lastVesselId !== vesselId) {
      setLastVesselId(vesselId);
      dispatch(fetchFuelTargets(vesselId) as AnyAction);
    }
  }, [vesselId, visible]);

  const formatedDate = moment(lastUpdate ?? new Date(), "YYYY-MM-DD").format(
    "Do MMMM YYYY"
  );

  return (
    <FormPopup
      title={"Target fuel use settings"}
      visible={visible}
      isSaving={false}
      enableSave={!!hasChanges}
      onCancel={() => {
        setLastVesselId("");
        cancel();
      }}
      onHide={hide}
      onSave={() => {
        setLastVesselId("");
        save();
      }}
    >
      {isLoading ? (
        <Loader text="" error={error} />
      ) : (
        <FormGroup>
          <div
            className={styles.gridContainer}
            style={{ gridTemplateColumns: `repeat(${1 + fuels?.length}, 1fr)` }}
          >
            <div>Mode</div>
            {fuels?.map((f: string) => (
              <div key={f}>{f.toUpperCase()}</div>
            ))}
            {data?.map((d) => renderRow(fuels, d, rowValueChanged))}
          </div>
        </FormGroup>
      )}
      <div className={styles.info}>
        <Button
          circle={true}
          width={"16"}
          padding={"1px"}
          disabled={true}
          value={<p style={{ fontSize: "12px" }}>i</p>}
          type={"smallButton"}
        />
        <p>Fuel data last updated on {formatedDate}</p>
      </div>
      <div className={styles.info}>
        <Button
          circle={true}
          width={"16"}
          padding={"1px"}
          disabled={true}
          value={<p style={{ fontSize: "12px" }}>i</p>}
          type={"smallButton"}
        />
        <p>
          Metric units for {fuels?.map((f) => f.toUpperCase()).join(", ")} are
          in tons
        </p>
      </div>
    </FormPopup>
  );
}

const renderRow = (
  fuels: string[],
  data: IOperationTargetsData,
  rowValueChanged: (value: IFuelDataFieldValue) => unknown
) => {
  return (
    <>
      {renderOperationMode(data.operationMode)}
      {fuels.map((ft) => (
        <FormTextBox
          name={ft}
          onChange={(field: string, value: number) =>
            rowValueChanged({ field, operationMode: data.operationMode, value })
          }
          multiLine={false}
          value={data.targets.find((d) => d.fuelType === ft)?.value}
          disabled={false}
          width={"150px"}
          type={"number"}
          key={ft}
          unit={"t/h"}
          formatValue={(v: number) => Number(v)}
        />
      ))}
    </>
  );
};

const renderOperationMode = (profile: string) => {
  const style: React.CSSProperties = {};
  style.backgroundColor = getLegendColor(profile);
  const textColor = getLegendTextColor(profile);
  if (textColor) {
    style.color = textColor;
  }
  return (
    <div className={styles.operationMode}>
      <div style={style} className={`${styles.legendButton} ${styles.legend}`}>
        <span>{profile}</span>
      </div>
      {getLegendName(profile)}
    </div>
  );
};

export default SetFuelTargetsDialog;
