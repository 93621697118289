import {
  flow,
  flatMap,
  map,
  filter,
  values,
  uniqBy,
  isNil,
  compact,
} from "lodash/fp";
import {
  FETCH_COMPARE_SUCCESS,
  REMOVE_COMPARE_VESSEL,
  REMOVE_ALL_COMPARE_VESSEL,
  FETCH_COMPARE_ERROR,
  FETCH_COMPARE,
} from "../actions/action.types";
import { formatDateRange } from "../common/dateFilter";
import { getMetricsForVesselItem } from "../selectors/common";
import { get } from "lodash";
import { roundConvert } from "../../common/numbers";

const initialState = {
  categories: [
    {
      key: "operation.distribution",
      name: "Operation",
      icon: "distance",
    },
    {
      key: "hull.hours",
      name: "Operation hours",
      icon: "distance",
    },
    {
      key: "hull.distance",
      name: "Distance",
      icon: "distance",
    },
    {
      key: "eeoi",
      name: "EEOI",
      icon: "emissions",
    },
    {
      key: "engine.avgFuelConsumption",
      name: "Avg. fuel consumption",
      icon: "fuel",
    },
    {
      key: "engine.fuelConsumption",
      name: "Fuel consumption",
      icon: "fuel",
    },
    {
      key: "hull.avgSpeed",
      name: "Avg. speed",
      icon: "dashboard",
    },
    {
      key: "engine.avgRunningEngines",
      name: "Avg. running engines",
      icon: "dashboard",
    },
    {
      key: "engine.avgCO2Emission",
      name: "Avg. CO2 emissions",
      icon: "emissions",
    },
    {
      key: "engine.co2Emissions",
      name: "CO2 emission",
      icon: "emissions",
    },
    {
      key: "hull.avgDraftBow",
      name: "Avg. draft bow",
      icon: "trim",
    },
    {
      key: "hull.avgDraftStern",
      name: "Avg. draft stern",
      icon: "trim",
    },
    {
      key: "engine.avgPower",
      name: "Avg. power",
      icon: "dashboard",
    },
    {
      key: "prop.avgPropulsionPower",
      name: "Avg. propulsion power",
      icon: "dashboard",
    },
    {
      key: "battery.energy",
      name: "Battery Energy",
      icon: "battery",
      defaultHidden: true,
    },
    {
      key: "battery.avgSoc",
      name: "Battery Avg. SOC",
      icon: "battery",
      defaultHidden: true,
    },
    {
      key: "consumer.shoreConnection",
      name: "Shore Connection",
      icon: "power",
      defaultHidden: true,
    },
    {
      key: "hull.avgWind",
      name: "Avg. wind",
      icon: "weather",
    },
    {
      key: "hull.avgBeaufort",
      name: "Avg. beaufort",
      icon: "weather",
    },
  ],
  data: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_COMPARE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: {
            ...state.data[action.key],
            isLoading: true,
            values:
              state.data[action.key] && state.data[action.key].values
                ? state.data[action.key].values.filter(
                    (d) => d.id === "operation.distribution"
                  )
                : [],
            settings: {
              datetype: action.datetype,
              daterange: action.daterange,
              key: action.key,
              timeOffset: action.timeOffset,
            },
          },
        },
      };
    case FETCH_COMPARE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: {
            ...action.data,
            isLoading: false,
            settings: {
              datetype: action.datetype,
              daterange: action.daterange,
              key: action.key,
              timeOffset: action.timeOffset,
            },
          },
        },
      };
    case FETCH_COMPARE_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: {
            ...state.data[action.key],
            isLoading: false,
            error: action.message,
          },
        },
      };
    case REMOVE_COMPARE_VESSEL:
      delete state.data[action.key];
      return {
        ...state,
        data: { ...state.data },
      };
    case REMOVE_ALL_COMPARE_VESSEL:
      return initialState;
    default:
      return state;
  }
};

// see VesselComparisonValueType in rrm-web-backend - todo: use strings instead of enum indices
export const CHART_VALUE = 0;
export const AGGREGATED_VALUE = 1;
export const PERFORMANCE_VALUE = 2;
export const GROUPED_AGGREGATED_VALUES = 3;
export const EEOI_VALUE = 4;

export const extractPerformanceIndicators = flow(
  (state) => values(state.data),
  flatMap((entry) => entry.values || []),
  filter((value) => value.valueType === PERFORMANCE_VALUE),
  map(({ id, name, description, icon = "performance" }) => ({
    id,
    name,
    description,
    icon,
  })),
  uniqBy("id")
);

const showCompare = (value, pageContext) => {
  const vesselItemId = get(value, "vesselItemId", undefined);
  const vesselItemMetrics =
    (vesselItemId && getMetricsForVesselItem(vesselItemId, pageContext)) || [];
  const metricId = get(value, "metricId");

  return {
    ...value,
    showCompare: get(
      vesselItemMetrics.find((x) => x.metricId === metricId),
      "showCompare",
      true
    ),
  };
};

const isValueEmpty = (value) => {
  return (
    !value ||
    !value.showCompare ||
    (value.valueType === AGGREGATED_VALUE && isNil(value.value)) ||
    (value.valueType === GROUPED_AGGREGATED_VALUES &&
      (value.values || []).every((v) => isNil(v.value)))
  );
};

const convertValueSpec = (compareValue) => {
  const { value, unit, valueType } = compareValue;
  if (valueType === AGGREGATED_VALUE && value !== undefined) {
    const converted = roundConvert(value, unit);
    return {
      ...compareValue,
      unit: converted.unit,
      value: converted.number,
    };
  }
  return compareValue;
};

export function prepCompareTable({ compare = {}, pageContext = {} }) {
  const entries = Object.values(compare.data || {});
  const categories = (compare.categories || []).concat(
    extractPerformanceIndicators(compare)
  );

  return {
    headers: entries.map(({ settings, vesselId }) => ({
      key: settings.key,
      name: get(pageContext, ["vessels", vesselId, "name"]),
      timeOffset: settings.timeOffset,
      description:
        settings.daterange &&
        formatDateRange(settings.daterange, settings.datetype),
    })),
    entries,
    rows: compact(
      categories.map((c) => {
        let key = c.key || c.id;
        const cells = entries.map((entry) => {
          const value = convertValueSpec(
            showCompare(
              entry.values.find(({ id }) => id === key),
              pageContext
            )
          );

          if (value && value.valueType === GROUPED_AGGREGATED_VALUES) {
            value.values = get(value, "values", [])
              .map((x) => showCompare(x, pageContext))
              .map((x) => convertValueSpec(x))
              .filter((x) => x.showCompare);
          }

          return {
            key: key + entry.settings.key,
            entry: entry,
            value: value.showCompare ? value : null,
            isEmpty: isValueEmpty(value),
          };
        });

        if (c.defaultHidden && cells.every((cell) => cell.isEmpty)) {
          return null;
        }

        return {
          key,
          category: c,
          cells,
        };
      })
    ),
  };
}
