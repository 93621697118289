import { ADMIN_ITEM_CONFIG } from "../../api";
import fetch from "../action.fetch";
import { admin } from "../action.types";

const actions = admin.itemConfig;

export const fetchContextData = () =>
  fetch({
    url: ADMIN_ITEM_CONFIG.getContext(),
    actionType: actions.FETCH_CONTEXT_DATA,
  });

export const editItemConfig = ({ itemTypeId, vesselId, vesselItemId }) =>
  fetch({
    url: ADMIN_ITEM_CONFIG.configJson({ itemTypeId, vesselId, vesselItemId }),
    actionType: actions.FETCH_ITEM_CONFIG,
    actionData: { itemTypeId, vesselId, vesselItemId },
  });

export const setFormValue = (name, value, valid, message) => {
  return {
    type: actions.SET_FORM_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const cancelEdit = () => ({
  type: actions.CANCEL_EDIT,
});

export const saveItemConfig = ({
  itemTypeId,
  vesselId,
  vesselItemId,
  fields,
}) => {
  const isDelete = !fields.configJson.value;
  return fetch({
    method: isDelete ? "delete" : "put",
    url: ADMIN_ITEM_CONFIG.configJson({ itemTypeId, vesselId, vesselItemId }),
    actionType: actions.SAVE_ITEM_CONFIG,
    payload: !isDelete && JSON.parse(fields.configJson.value),
  });
};

export const sortItemTypes = ({ sortBy, sortDirection }) => ({
  type: actions.SORT_ITEM_TYPES,
  sortBy,
  sortDirection,
});
