import { ADMIN_USERS } from "../../api";
import { isSystemAdmin } from "../../reducers/reducer.user";
import fetch, { post, put } from "../action.fetch";
import {
  admin,
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USER,
  FETCH_USER_GROUPS,
  EDIT_USER_ACCESS,
  CREATE_ADMIN_USER,
  CLOSE_EDIT_USER_ACCESS_POPUP,
  CLOSE_ADMIN_USER_POPUP,
  SAVE_ADMIN_USER,
  SAVE_ADMIN_USER_ERROR,
  SAVE_ADMIN_USER_SUCCESS,
  SET_ADMIN_USER_VALUE,
  SET_USER_ACCESS_SELECTED_GROUPS,
  SET_USER_ACCESS_SELECTED_COMPANY,
  REQUEST_USER_PASSWORD_RESET,
  CONFIRM_USER_PASSWORD_RESET,
  SEND_USER_PASSWORD_RESET,
  CANCEL_USER_PASSWORD_RESET,
  SORT_ADMIN_USERS,
  SAVE_USER_GROUPS_IN_COMPANY,
} from "../action.types";
import { parseExcelDocument } from "../../common/filereader";
import { get } from "lodash";

export const fetchUsers = (companyId) =>
  fetch({
    url: ADMIN_USERS.get(companyId),
    actionType: FETCH_ADMIN_USERS,
    actionData: {
      companyId,
    },
  });

export const fetchUser = (id) =>
  fetch({
    url: ADMIN_USERS.getById(id),
    actionType: FETCH_ADMIN_USER,
    actionData: {
      id,
    },
  });

export const createUser = () => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: CREATE_ADMIN_USER,
    selectedCompanyId: state.admin.selectedCompanyId,
  });
};

export const closeUserPopup = () => ({
  type: CLOSE_ADMIN_USER_POPUP,
});

export const setUserValue = (name, value, valid, message) => {
  return {
    type: SET_ADMIN_USER_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const saveUser =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const currentUser = state.user;
    const { user, form } = state.admin.users.editor;
    const isUpdate = form.isUpdate;

    const payload = {
      firstName: form.fields.firstName.value,
      lastName: form.fields.lastName.value,
      companyId: form.fields.companyId.value,
    };

    if (!isUpdate) {
      if (form.fields.email) {
        payload.email = form.fields.email.value;
      }
      if (form.fields.clientId) {
        payload.clientId = form.fields.clientId.value;
      }
    }

    if (isSystemAdmin(currentUser)) {
      payload.roles = form.fields.roles.value;
      payload.healthManagementAccess =
        form.fields.healthManagementAccessEventType.value;
      payload.isApiClientUserOnly = form.fields.isApiClientUserOnly.value;
    }

    dispatch({ type: SAVE_ADMIN_USER });

    http({
      method: isUpdate ? "put" : "post",
      url: isUpdate ? ADMIN_USERS.put(user.id) : ADMIN_USERS.post(),
      data: payload,
    })
      .then(() => {
        dispatch({ type: SAVE_ADMIN_USER_SUCCESS });
        dispatch(fetchUsers(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({ type: SAVE_ADMIN_USER_ERROR, error });
      });
  };

export const fetchUserGroups = (userId) =>
  fetch({
    url: ADMIN_USERS.getUserGroups(userId),
    actionType: FETCH_USER_GROUPS,
  });

export const editUserAccess = (user) => ({
  type: EDIT_USER_ACCESS,
  user,
});

export const setUserAccessSelectedCompany = (selectedCompanyId) => ({
  type: SET_USER_ACCESS_SELECTED_COMPANY,
  selectedCompanyId,
});

export const setUserAccessSelectedGroups = (selectedGroups) => ({
  type: SET_USER_ACCESS_SELECTED_GROUPS,
  selectedGroupIds: (selectedGroups || []).map((x) => x.id),
});

export const closeUserAccessPopup = () => ({
  type: CLOSE_EDIT_USER_ACCESS_POPUP,
});

export const saveUserGroupsInCompany = (accessEditor) =>
  put({
    url: ADMIN_USERS.putUserGroupsInCompany(
      accessEditor.userId,
      accessEditor.selectedCompanyId
    ),
    payload: accessEditor.selectedGroupIds,
    actionType: SAVE_USER_GROUPS_IN_COMPANY,
  });

export const requestUserPasswordReset = (user) => ({
  type: REQUEST_USER_PASSWORD_RESET,
  user,
});

export const confirmUserPasswordReset = () => (dispatch, getState) => {
  let state = getState();
  let { user } = state.admin.users.passwordReset;
  dispatch({ type: CONFIRM_USER_PASSWORD_RESET });
  dispatch(
    post({
      url: ADMIN_USERS.passwordReset(user.id),
      actionType: SEND_USER_PASSWORD_RESET,
      actionData: {
        user,
      },
    })
  );
};

export const cancelUserPasswordReset = () => ({
  type: CANCEL_USER_PASSWORD_RESET,
});

export const sortUsers = ({ sortBy, sortDirection }) => ({
  type: SORT_ADMIN_USERS,
  sortBy,
  sortDirection,
});
export const importUsers = (selectedCompanyId) => ({
  type: admin.users.import.OPEN,
  selectedCompanyId,
});

export const cancelUserImport = () => ({
  type: admin.users.import.CANCEL,
});
export const confirmUserImport =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const userImportData = prepareUserImportData(state.admin.users.userImport);

    dispatch({ type: admin.users.import.SAVE });

    http({
      method: "post",
      url: ADMIN_USERS.import(),
      data: userImportData,
    })
      .then(({ data }) => {
        dispatch({
          type: admin.users.import.SAVE_SUCCESS,
          importData: data.map((x) => ({
            ...x.user,
            status: x.status,
            error: x.error,
            message: x.message,
          })),
        });
        dispatch(fetchUsers(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({ type: admin.users.import.SAVE_ERROR, error });
      });
  };
export const setUserImportSelectedCompany = (selectedCompanyId) => ({
  type: admin.users.import.SET_SELECTED_COMPANY,
  selectedCompanyId,
});
export const setUserImportSelectedGroup = (selectedGroupId) => ({
  type: admin.users.import.SET_SELECTED_GROUP,
  selectedGroupId,
});
export const onSelectedUserImportFile = (file) => (dispatch) => {
  dispatch({ type: admin.users.import.SET_FILE });

  parseExcelDocument(file).then((res) => {
    dispatch({
      type: admin.users.import.SET_FILE_SUCCESS,
      rows: res.map((r) => ({
        firstName: r[0],
        lastName: r[1],
        email: r[2],
      })),
    });
  });
};

export const prepareUserImportData = (userImport) => {
  return {
    companyId: userImport.selectedCompanyId,
    groupId: userImport.selectedGroupId,
    users: userImport.data.map((u) => ({
      firstName: u.firstName,
      lastName: u.lastName,
      email: u.email,
    })),
  };
};

export const deactivateUser = (user) =>
  post({
    url: ADMIN_USERS.deactivate(user.id),
    actionType: admin.users.DEACTIVATE,
    actionData: { user },
  });

export const reactivateUser = (user) =>
  post({
    url: ADMIN_USERS.reactivate(user.id),
    actionType: admin.users.REACTIVATE,
    actionData: { user },
  });

export const deletePopupOpen = (user) => ({
  type: admin.users.DELETE_POPUP_OPEN,
  user,
});

export const deletePopupClose = () => ({
  type: admin.users.DELETE_POPUP_CLOSE,
});

export const deleteUser =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const user = state.admin.users.deleteConfirmPopup.user;
    dispatch({ type: admin.users.DELETE });

    http({
      method: "post",
      url: ADMIN_USERS.delete(user.id),
    })
      .then(() => {
        dispatch({ type: admin.users.DELETE_SUCCESS });
        dispatch(fetchUsers(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({ type: admin.users.DELETE_ERROR, error });
      });
  };

export const generateApiSecret = (user) =>
  post({
    url: ADMIN_USERS.generateApiSecret(user.id),
    actionType: admin.users.GENERATE_API_SECRET,
    actionData: { user },
  });

export const requestRegenerateApiSecret = (user) => ({
  type: admin.users.REQUEST_REGENERATE_API_SECRET,
  user,
});

export const confirmRegenerateApiSecret = () => (dispatch, getState) => {
  const state = getState();
  const user = get(state, "admin.users.apiSecretPopup.user");
  dispatch(generateApiSecret(user));
};

export const requestClearApiSecret = (user) => ({
  type: admin.users.REQUEST_CLEAR_API_SECRET,
  user,
});

export const confirmClearApiSecret = () => (dispatch, getState) => {
  const state = getState();
  const user = get(state, "admin.users.apiSecretPopup.user");
  dispatch(clearApiSecret(user));
};

export const clearApiSecret = (user) =>
  post({
    url: ADMIN_USERS.clearApiSecret(user.id),
    actionType: admin.users.CLEAR_API_SECRET,
    actionData: { user },
  });

export const closeGenerateApiSecretPopup = () => ({
  type: admin.users.CLOSE_USER_API_SECRET_POPUP,
});

export const clearErrors = () => ({
  type: admin.users.CLEAR_USER_ERRORS,
});

export const downloadUsersExample = (data, fileName) => ({
  type: admin.users.DOWNLOAD_USERS_EXAMPLE,
  data,
  fileName,
});
