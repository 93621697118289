import { IEmissionsData } from "../../components/FuelEmissions/commonTypes";

export const emissionsByOperationModesResponse: IEmissionsData[] = [
  { cO2: 3.733587229507, nOx: 0.0, sOx: 0.0, operationMode: "O" },
  { cO2: 3815.09508729917, nOx: 0.0, sOx: 0.0, operationMode: "P" },
  { cO2: 3950.22398522443, nOx: 0.65805, sOx: 0.0142188, operationMode: "M" },
  {
    cO2: 36349.9758826858,
    nOx: 65.919169,
    sOx: 1.424346904,
    operationMode: "ET",
  },
  {
    cO2: 164443.505099964,
    nOx: 73.1956505,
    sOx: 1.581573308,
    operationMode: "T",
  },
  { cO2: 64816.6429429024, nOx: 0.0, sOx: 0.0, operationMode: "FT" },
  { cO2: 715.82038584701, nOx: 0.0, sOx: 0.0, operationMode: "SC" },
  { cO2: 13421.6943076184, nOx: 0.0, sOx: 0.0, operationMode: "DP" },
  { cO2: 7561.78021692141, nOx: 0.0, sOx: 0.0, operationMode: "DP2" },
];
