import { pageLayoutConfiguration } from "../../actions/action.types";
import { areParamsValid } from "../../common/pageConfig";
import { COMPONENTS } from "../../config";
import createReducer from "../createReducer";

const initialState = {
  layout: [],
  rowsConfig: [],
  rowsCount: 0,
  settingsDialog: {
    visible: false,
  },
};

function createGridLayout(rows) {
  const layout = [];
  const rowsConfig = [];
  let idCounter = 1;
  for (let i = 0; i < rows.length; i++) {
    let xPosition = 0;
    rows[i].forEach((x) => {
      const colSpan = x.colSpan || 1;
      const type = COMPONENTS.includes(x.type) ? x.type : "Invalid Type";
      const name = areParamsValid(x) ? type : "Invalid Params";
      const id = `${idCounter++}_${name}`;
      const rowElement = {
        ...x,
        id,
        colSpan,
        row: i,
      };
      const layoutElement = {
        w: colSpan,
        h: 1,
        x: xPosition,
        y: i,
        i: id,
        maxH: 1,
        moved: false,
        static: false,
      };
      xPosition += colSpan;
      layout.push(layoutElement);
      rowsConfig.push(rowElement);
    });
  }
  return { layout, rowsConfig };
}

export default createReducer(initialState, {
  [pageLayoutConfiguration.CREATE_GRID_LAYOUT]: (state, { rows }) => {
    const { layout, rowsConfig } = createGridLayout(rows);
    return {
      ...state,
      layout,
      rowsConfig,
    };
  },
  [pageLayoutConfiguration.UPDATE_GRID_LAYOUT]: (
    state,
    { layout, rowsConfig }
  ) => {
    return {
      ...state,
      layout,
      rowsConfig,
    };
  },
  [pageLayoutConfiguration.UPDATE_ROWS_COUNT]: (state, { rowsCount }) => {
    return {
      ...state,
      rowsCount,
    };
  },
  [pageLayoutConfiguration.CLEAR_PAGE_LAYOUT_CONFIGURATION]: () => {
    return {
      ...initialState,
    };
  },
  [pageLayoutConfiguration.OPEN_SETTINGS_DIALOG]: (state, { id }) => {
    return {
      ...state,
      settingsDialog: {
        visible: true,
        selectedId: id,
      },
    };
  },
  [pageLayoutConfiguration.CLOSE_SETTINGS_DIALOG]: (state) => {
    return {
      ...state,
      settingsDialog: {
        visible: false,
      },
    };
  },
  [pageLayoutConfiguration.UPDATE_ROWS_CONFIG]: (state, { rowsConfig }) => {
    return {
      ...state,
      rowsConfig,
      settingsDialog: {
        visible: false,
      },
    };
  },
});
