import { vesselConfig } from "../action.types";

export const vesselItemSelected = (selectedVesselItem) => ({
  type: vesselConfig.energyFlow.VESSELITEM_SELECTED,
  selectedVesselItem,
});

export const openNew = () => ({
  type: vesselConfig.energyFlow.VESSELITEM_NEW_OPEN,
});

export const closeNew = () => ({
  type: vesselConfig.energyFlow.VESSELITEM_NEW_CLOSE,
});

export const addNew = (vesselItem) => ({
  type: vesselConfig.energyFlow.VESSELITEM_NEW_ADD,
  vesselItem,
});

export const save = (layout, relations) => ({
  type: vesselConfig.energyFlow.SAVE,
  layout,
  relations,
});

export const addRelation = ({ sourceId, destinationId }) => ({
  type: vesselConfig.energyFlow.ADD_RELATION,
  sourceId,
  destinationId,
});
export const deleteRelation = ({ sourceId, destinationId }) => ({
  type: vesselConfig.energyFlow.DELETE_RELATION,
  sourceId,
  destinationId,
});

export const updateNodePosition = (id, position) => ({
  type: vesselConfig.energyFlow.UPDATE_NODE,
  id,
  position,
});
export const removeNode = (id) => ({
  type: vesselConfig.energyFlow.REMOVE_NODE,
  id,
});
export const reset = (vesselId) => ({
  type: vesselConfig.energyFlow.RESET,
  vesselId,
});
