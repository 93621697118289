import t from "prop-types";
import React from "react";
import styles from "../../FuelConsumption/FuelConsumption.css";
import * as d3 from "d3";

const d = d3
  .line()
  .x((data) => data.x)
  .y((data) => data.y);

const TrendLine = ({ data }) => {
  return (
    <g>
      <path className={styles.trendLine} d={d(data)} />
    </g>
  );
};

TrendLine.propTypes = {
  data: t.array.isRequired,
};

export default TrendLine;
