import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { startCase, get, isEqual } from "lodash";
import { VIEWS } from "../../../config";

export default createReducer(
  {},
  {
    [vesselConfig.onshoreFrontendConfig.FETCH]: () => ({
      isLoading: true,
    }),
    [vesselConfig.onshoreFrontendConfig.FETCH_SUCCESS]: (_state, { data }) => ({
      data,
      fields: getMenuConfigFields(data),
    }),
    [vesselConfig.onshoreFrontendConfig.FETCH_ERROR]: (_state, { error }) => ({
      error: get(error, "message", error),
    }),
    [vesselConfig.onshoreFrontendConfig.INPUT_CHANGED]: (
      state,
      { field, value }
    ) => ({
      ...state,
      fields: state.fields.map((f) => (f.name !== field ? f : { ...f, value })),
    }),
    [vesselConfig.onshoreFrontendConfig.SAVE]: (state) => ({
      ...state,
      isSaving: true,
      error: null,
    }),
    [vesselConfig.onshoreFrontendConfig.SAVE_SUCCESS]: (_state, { data }) => ({
      data,
      fields: getMenuConfigFields(data),
    }),
    [vesselConfig.onshoreFrontendConfig.SAVE_ERROR]: (state, { error }) => ({
      ...state,
      isSaving: false,
      error: get(error, "message", error),
    }),
  }
);

export const getMenuConfigFields = ({ menuConfig } = {}) => {
  return (
    (menuConfig &&
      menuConfig
        .sort((next, prev) => next.name > prev.name)
        .map((x) => ({
          name: x.name,
          value: x.visible,
          label: get(VIEWS, [x.name, "name"], startCase(x.name)),
        }))) ||
    []
  );
};

export const prepState = (state) => {
  const { data, fields, isSaving, isLoading, error } = state;
  const hasChanges = fields && !isEqual(getMenuConfigFields(data), fields);
  const savedOn = data && (data.modifiedOn || data.createdOn);
  return {
    fields,
    error,
    isLoading,
    savedOn,
    canSave: !isSaving && (!savedOn || hasChanges),
  };
};
