import { isEmpty, keyBy } from "lodash";

export const getUser = (state) => state.user;
export const getPageContext = (state) => state.pageContext || {};
export const getVesselItemById = (id, { vesselItems = {} }) => vesselItems[id];
export const getItemTypeById = (id, { itemTypes = {} }) => itemTypes[id];
export const getActiveItemTypeMetrics = (
  itemTypeId,
  { itemTypeMetrics = {} }
) =>
  Object.values(itemTypeMetrics).filter(
    (itm) => itm.itemTypeId === itemTypeId && itm.viewPosition > 0
  ) || [];
export const getUnitSymbol = (unitId, pageContext) => {
  const unit = pageContext.units[unitId];
  return (unit && unit.symbol) || "";
};

export const itemTypeContainsMetric = (
  itemTypeId,
  metricId,
  { itemTypeMetrics = {} }
) => {
  return Object.values(itemTypeMetrics).some(
    (x) => x.itemTypeId === itemTypeId && x.metricId === metricId
  );
};

export const vesselContainsPerformanceIndicator = (
  vesselId,
  performanceIndicatorId,
  { vesselPerformanceIndicators = {} }
) => {
  return Object.values(vesselPerformanceIndicators).some(
    (x) =>
      x.vesselId === vesselId &&
      x.performanceIndicatorId === performanceIndicatorId
  );
};

export const vesselItemContainsMetric = (
  vesselItemId,
  metricId,
  pageContext
) => {
  const vesselItem = getVesselItemById(vesselItemId, pageContext);
  const itemType =
    vesselItem && getItemTypeById(vesselItem.itemTypeId, pageContext);
  return itemType && itemTypeContainsMetric(itemType.id, metricId, pageContext);
};

export const getVesselItemsWithMetrics = (vesselId, pageContext) => {
  const vesselItemMetrics = Object.values(pageContext.vesselItems)
    .filter((x) => x.vesselId === vesselId)
    .map((x) => ({
      id: x.id,
      metrics: getMetricsForVesselItem(x.id, pageContext),
    }));
  return keyBy(vesselItemMetrics, "id");
};

export const getMetricsForVesselItem = (vesselItemId, pageContext) => {
  const vesselItem = getVesselItemById(vesselItemId, pageContext);
  const itemType =
    vesselItem && getItemTypeById(vesselItem.itemTypeId, pageContext);
  const itemTypeMetrics =
    itemType && getActiveItemTypeMetrics(itemType.id, pageContext);

  const vesselItemMetrics = getVesselItemMetrics(
    vesselItemId,
    pageContext.vesselItemMetrics
  );

  const metrics =
    itemTypeMetrics &&
    new Set(
      itemTypeMetrics
        .map((itm) => {
          return {
            ...itm,
            ...pageContext.metrics[itm.metricId],
          };
        })
        .map((m) => {
          const settings = vesselItemMetricSetting(vesselItemMetrics, m);
          return {
            ...m,
            ...settings,
          };
        })
        .filter((x) => !isEmpty(x.name))
    );
  return metrics ? Array.from(metrics) : [];
};

export const vesselItemMetricSetting = (vesselItemMetrics, metric) => {
  const setting =
    vesselItemMetrics.find((vim) => vim.metricId === metric.id) || metric;

  return {
    showTrend: setting.showTrend,
    showOperation: setting.showOperation,
    showOperationDetail: setting.showOperationDetail,
    showCompare: setting.showCompare,
  };
};

export const getDefaultMetricIdsForVesselItem = (vesselItemId, pageContext) => {
  const itemTypeMetrics = getMetricsForVesselItem(vesselItemId, pageContext);

  if (!itemTypeMetrics) {
    return [];
  }

  return Array.from(
    new Set(
      itemTypeMetrics
        .filter((itm) => itm.viewPosition === 2 && itm.showTrend)
        .map((itm) => itm.metricId)
    )
  );
};

export const getVesselItemMetrics = (vesselItemId, vesselItemMetrics) => {
  return (
    (vesselItemMetrics &&
      Object.values(vesselItemMetrics).filter(
        (x) => x.vesselItemId === vesselItemId
      )) ||
    []
  );
};

export const getPerformanceIndicatorsForVessel = (vesselId, pageContext) => {
  return Array.from(
    new Set(
      Object.values(pageContext.vesselPerformanceIndicators)
        .filter((vpi) => vpi.vesselId === vesselId)
        .map(
          (vpi) => pageContext.performanceIndicators[vpi.performanceIndicatorId]
        )
    )
  );
};

export const getVesselAllItems = (vesselId, pageContext) => {
  return Array.from(
    new Set(
      Object.values(pageContext.vesselItems)
        .filter((x) => x.vesselId === vesselId)
        .map((x) => ({
          id: x.id,
        }))
    )
  );
};
