import {
  FETCH_ANALYSIS_PLOT,
  SET_ANALYSIS_AXIS_VALUE_TYPE,
  SET_ANALYSIS_DATE,
  SET_ANALYSIS_FILTER_VALUE,
  TOGGLE_ANALYSIS_OPERATION,
  TOGGLE_ANALYSIS_ENGINE,
  TOGGLE_ANALYSIS_PROPULSOR,
  SET_ANALYSIS_Z_DOMAIN,
  SET_ANALYSIS_VESSEL,
} from "./action.types";
import fetch from "./action.fetch";
import { ANALYSIS } from "../api";

export const setAnalysisDate = (date) => {
  return {
    type: SET_ANALYSIS_DATE,
    date,
  };
};

export const setAnalysisVessel = (vesselId, date) => {
  return {
    type: SET_ANALYSIS_VESSEL,
    vesselId,
    date,
  };
};

export const setZDomain = (domain) => ({
  type: SET_ANALYSIS_Z_DOMAIN,
  domain,
});

export const setAxisValueType = (axis, valueField) => ({
  type: SET_ANALYSIS_AXIS_VALUE_TYPE,
  axis,
  valueField,
});

export const toggleOperation = (operationId) => ({
  type: TOGGLE_ANALYSIS_OPERATION,
  operationId,
});

export const toggleEngine = (engineId) => ({
  type: TOGGLE_ANALYSIS_ENGINE,
  engineId,
});

export const togglePropulsor = (propulsorId) => ({
  type: TOGGLE_ANALYSIS_PROPULSOR,
  propulsorId,
});

export const fetchAnalysisPlot =
  (
    vesselId,
    dateRange,
    filters,
    operationIds,
    engineIds = [],
    propulsorIds = []
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const { xAxisType, yAxisType, zAxisType, date } = state.analysis;
    const { from, to } = dateRange;
    const timeOffset = parseInt(date.timeOffset);
    dispatch(
      fetch({
        actionType: FETCH_ANALYSIS_PLOT,
        method: "post",
        key: FETCH_ANALYSIS_PLOT,
        url: ANALYSIS.fetchAnalysisPlot(),
        payload: {
          filters: filters
            ? Object.entries(filters).map(([key, value]) => ({
                name: key,
                min: value.minValue,
                max: value.maxValue,
              }))
            : null,
          operationIds: operationIds,
          engineIds: engineIds,
          propulsorIds: propulsorIds,
          vesselId,
          from,
          to,
          timeOffset,
          x: xAxisType.id,
          y: yAxisType.id,
          z: zAxisType.id,
        },
      })
    );
  };

export const setFilterValue = (filterType, minValue, maxValue) => ({
  type: SET_ANALYSIS_FILTER_VALUE,
  filterType,
  minValue,
  maxValue,
});
