import React from "react";

export const Drop = () => (
  <svg
    width="11.199957"
    height="16"
    viewBox="0 0 11.199957 16"
    fill="none"
    version="1.1"
    id="svg4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs8" />
    <g
      id="Close"
      transform="matrix(1.7226464,0,0,1.7142827,-6.5978735,-3.99985)"
    >
      <path
        id="Mask"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m 3.83008,8.39992 c 0,1.80598 1.46066,3.26668 3.26666,3.26668 1.806,0 3.26666,-1.4607 3.26666,-3.26668 0,-0.59335 -0.0741,-1.32678 -0.39337,-1.91737 C 9.82993,6.20449 8.87278,4.80338 8.87278,4.80338 c 0,0 -1.45528,-2.47013 -1.77604,-2.47013 -0.33277,0 -1.831,2.55755 -1.831,2.55755 0,0 -0.84228,1.23192 -1.04007,1.58569 C 3.91313,7.07771 3.83008,7.80476 3.83008,8.39992 Z M 4.95484,7.1793 C 4.86241,7.15772 4.77559,7.13746 4.74301,7.11674 4.56614,7.71963 4.19992,9.03495 5.13709,9.96871 5.61416,10.4546 6.15003,10.6912 6.70919,10.8099 l 0.06777,-0.422 C 6.32944,10.2994 5.92536,10.0562 5.42029,9.57893 4.89586,9.05447 4.83751,8.32103 5.13709,7.22606 5.08413,7.20948 5.01817,7.19409 4.95484,7.1793 Z"
        fill="#b1bbc8"
      />
    </g>
  </svg>
);
