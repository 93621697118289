import React from "react";
import { get } from "lodash";
import styles from "./PerformanceIndicator.css";
import { roundNumberAt } from "../../../common/numbers";
import classNames from "../../../common/classNames";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

class PerformanceIndicator extends React.Component {
  indicatorPerformanceClass() {
    const indicator = get(this.props, "indicators[0]");
    const performance = indicator?.performance;

    if (performance === undefined) {
      return styles.indicatorLevelUnknown;
    } else if (performance >= 0) {
      return styles.indicatorLevelOk;
    } else if (performance < 0) {
      return styles.indicatorLevelPoor;
    }
  }

  indicatorInfo() {
    const buttonStyle = {
      position: "absolute !important",
      right: "0px",
      top: "0px",
      height: "100%",
      width: "100%",
      opacity: "0",
    };
    return (
      <ComponentInfo
        width={-1}
        modalTargetId={"popupContainer"}
        buttonClick={(e) => {
          e.stopPropagation();
        }}
        buttonStyle={buttonStyle}
        template={this.indicatorInfoTemplate()}
      >
        {" "}
      </ComponentInfo>
    );
  }

  indicatorInfoTemplate() {
    const { indicators } = this.props;
    return (
      <p className={styles.indicatorTemplate}>
        {indicators.length
          ? indicators.map((indicator) => (
              <div key={indicator.performanceIndicatorName}>
                {indicator.performanceIndicatorName}:{" "}
                <strong>{roundNumberAt(1, indicator.performance)}%</strong>
              </div>
            ))
          : "No performance data"}
      </p>
    );
  }

  render() {
    const displayClassNames = classNames(
      styles.indicatorLevel,
      this.indicatorPerformanceClass()
    );
    return <div className={displayClassNames}>{this.indicatorInfo()}</div>;
  }
}

export default PerformanceIndicator;
