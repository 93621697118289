import React from "react";
import PerformanceGauge from "../../../../../common/components/PerformanceGauge";
import { formatNumber } from "../../../../../common/numbers";
import DonutContainer from "../../../../components/DonutContainer";
import EnergyBudgetResult from "../../../../components/EnergyBudget/EnergyBudgetResult";
import { VesselProfile } from "../../../../components/VesselProfile/VesselProfile";
import vesselImage from "../../../../../../src/style/img/vessels/kvtibjorn.png";
import VesselMap from "../../../../components/VesselMap/VesselMap";
import appConfig from "../../../../../../config/onshore/environments/dev.json";
import EnergyBudgetOverview from "../../../../components/EnergyBudget/EnergyBudgetOverview";
import { FleetConnectionStatus } from "../../../../components/FleetConnectionStatus/FleetConnectionStatus";
import EquinorReport from "./EquinorReport";
import { HealthEventStatus } from "../../../../components/HealthEventStatus/HealthEventStatus";
import { prepareDonutData } from "../../../../common/donuts";
import {
  dailyOperations,
  donutFuelConsumptionData,
  donutRunData,
  equinorOperations,
  FUTURE_ENERGY_BUDGET_DATA,
  GAUGE_DATA,
  HEALTH_EVENTS_DATA,
  HISTORICAL_ENERGY_BUDGET_DATA,
  ITEM_TYPE_METRIC_MAPPINGS,
  MAP_VIEW,
  METRIC_MAPPINGS,
  MONTHLY_FUEL_CONSUMPTION_DATA,
  VESSEL_COUNT,
} from "./pageConfigurationGridData";
import { get, startCase } from "lodash";
import { PERFORMANCE_INDICATOR_ICONS } from "../../../../config";
import { getMetricName } from "../../../../components/EnergyBudget/EnergyBudgetOverviewHelper";
import {
  addMonths,
  addYears,
  atStartOfMonth,
  atStartOfYear,
} from "../../../../common/dates";
import MonthlyFuelUsage from "./MonthlyFuelUsage";
import { convertToArray } from "../../../../common/pageConfig";

const prepareDonuts = (...donutsJson) =>
  donutsJson.map((d) => ({ data: prepareDonutData(d) }));

export function displayDonuts(params, { metrics, itemTypeMetrics, units }) {
  const donuts = [];
  const itemTypeMetricIds = get(params, "itemTypeMetricIds", []);
  itemTypeMetricIds.forEach((i) => {
    const id = ITEM_TYPE_METRIC_MAPPINGS[i.toLowerCase()] || i.toLowerCase();
    const itemTypemetric = get(itemTypeMetrics, id, {});
    const { metricName, metricUnit } = getMetricParams(
      metrics,
      units,
      itemTypemetric.metricId
    );
    const donutData =
      metricName.toLowerCase() === "run"
        ? donutRunData(metricName, metricUnit)
        : donutFuelConsumptionData(metricName, metricUnit);
    donuts.push(donutData);
  });

  return <DonutContainer donuts={[...prepareDonuts(...donuts)]} />;
}

export function displayGauge(params, { performanceIndicators, units }) {
  const id = get(params, "performanceIndicatorId", "").toLowerCase();
  const performanceIndicator = get(performanceIndicators, id, {});
  const performanceIndicatorUnit = get(units, performanceIndicator.unitId, {});
  const header = performanceIndicator.label || "";
  const icon = PERFORMANCE_INDICATOR_ICONS[header.toLowerCase()];
  const unit = performanceIndicatorUnit.symbol || "";
  const values = GAUGE_DATA[icon] || { value: 250, performanceValue: 2 };

  return (
    <PerformanceGauge
      value={values.value}
      minValue={performanceIndicator.minValue || 0}
      maxValue={performanceIndicator.maxValue || 0}
      performanceValue={values.performanceValue}
      performanceMinValue={-20}
      performanceMaxValue={20}
      header={header}
      unit={unit}
      icon={icon}
      formatValue={formatNumber}
    />
  );
}

export function displayEnergyBudgetResult(
  params,
  { metrics, units },
  _,
  energyBudgetChanged
) {
  const metricIds = convertToArray(get(params, "metricId", []));
  const data = [];
  metricIds.forEach((mi, index) => {
    const id = METRIC_MAPPINGS[mi.toLowerCase()] || mi.toLowerCase();
    const { metricName, metricUnit } = getMetricParams(metrics, units, id);
    const metricData = {
      metricName,
      metricUnit,
      budgetValue: (index + 1) * 4000,
      actualValue: (index + 1) * 5000,
    };
    data.push(metricData);
  });

  return energyBudgetChanged ? null : <EnergyBudgetResult data={data} />;
}

export function displayVesselProfile() {
  return (
    <VesselProfile
      name={"Test vessel"}
      imo={123456789}
      imageUrl={vesselImage}
    />
  );
}

export function displayMap() {
  return (
    <VesselMap
      apiKey={appConfig.GOOGLE_MAPS_API_KEY}
      defaultCenter={MAP_VIEW.defaultCenter}
      defaultZoom={MAP_VIEW.defaultZoom}
      onChange={() => {
        /* This function is required by VesselMap component. */
      }}
    />
  );
}

export function displayEnergyBudgetOverview(params, { metrics, units }) {
  const metricIds = get(params, "metricIds", []);
  const groupBy = get(params, "groupBy", "");
  const historicalCount = get(params, "historicalCount", 0);
  const futureCount = get(params, "futureCount", 0);
  const fuelConsumptions = [];
  metricIds.forEach((mi) => {
    const id = METRIC_MAPPINGS[mi.toLowerCase()] || mi.toLowerCase();
    const { metricName, metricUnit } = getMetricParams(metrics, units, id);
    const fuelConsumption = getFuelConsumption(
      groupBy,
      historicalCount,
      futureCount,
      getMetricName(metricName),
      metricUnit
    );
    fuelConsumptions.push(fuelConsumption);
  });
  const data = {
    groupBy,
    fuelConsumptions,
  };
  return <EnergyBudgetOverview data={data} />;
}

export function displayFleetConnectionStatus() {
  return <FleetConnectionStatus vesselCount={23} connectedVesselCount={0} />;
}

export function displayVesselCount() {
  return <DonutContainer donuts={[...prepareDonuts(VESSEL_COUNT)]} />;
}

export function displayMonthlyFuelUsage(
  params,
  { units, itemTypeMetrics, metrics }
) {
  const itemTypeMetricId = get(params, "itemTypeMetricId", "").toLowerCase();
  const itemTypemetric = get(itemTypeMetrics, itemTypeMetricId, {});
  const { metricName, metricUnit } = getMetricParams(
    metrics,
    units,
    itemTypemetric.metricId
  );
  const fuelConsumptionData = prepareFuelConsumptionData(
    metricName,
    metricUnit
  );

  return <MonthlyFuelUsage fuelConsumptionData={fuelConsumptionData} />;
}

export function displayFuelEfficiency(_params, _pageContext, componentTitle) {
  return (
    <PerformanceGauge
      value={64.5}
      minValue={80}
      maxValue={120}
      performanceValue={-16.2}
      performanceMinValue={-20}
      performanceMaxValue={20}
      header={startCase(componentTitle)}
      unit={"%"}
      icon={"fuel"}
      formatValue={formatNumber}
    />
  );
}

export function displayEquinorVesselReport(params) {
  const equinorUnit = params.equinorUnit || "-";
  const dailyUnit = params.dailyUnit || "-";
  return (
    <EquinorReport
      equinorOperations={equinorOperations(equinorUnit)}
      dailyOperations={dailyOperations(dailyUnit)}
    />
  );
}

export function displayHealthEventStatus() {
  return <HealthEventStatus data={HEALTH_EVENTS_DATA} />;
}

function getFuelConsumption(
  groupBy,
  historicalCount,
  futureCount,
  metricName,
  metricUnit
) {
  const currentDate = new Date();
  const firstDayOfMonth = atStartOfMonth(currentDate);
  const firstMonthOfYear = atStartOfYear(currentDate);
  const values = [];

  for (let i = historicalCount; i > 0; i--) {
    const date =
      groupBy.toLowerCase() === "month"
        ? addMonths(-i, firstDayOfMonth).toISOString()
        : addYears(-i, firstMonthOfYear).toISOString();
    values.push({
      ...HISTORICAL_ENERGY_BUDGET_DATA[
        i % HISTORICAL_ENERGY_BUDGET_DATA.length
      ],
      date,
    });
  }

  values.push({
    ...HISTORICAL_ENERGY_BUDGET_DATA[0],
    date:
      groupBy.toLowerCase() === "month"
        ? firstDayOfMonth.toISOString()
        : firstMonthOfYear.toISOString(),
  });

  for (let y = 1; y <= futureCount; y++) {
    const date =
      groupBy.toLowerCase() === "month"
        ? addMonths(y, firstDayOfMonth).toISOString()
        : addYears(y, firstMonthOfYear).toISOString();
    values.push({
      ...FUTURE_ENERGY_BUDGET_DATA[y % FUTURE_ENERGY_BUDGET_DATA.length],
      date,
    });
  }

  return {
    metricName,
    metricUnit,
    values,
  };
}

function prepareFuelConsumptionData(metricName, metricUnit) {
  const currentDate = new Date();
  const firstDayOfMonth = atStartOfMonth(currentDate);
  const months = 12;
  const data = [];

  for (let i = months - 1; i >= 0; i--) {
    const date = addMonths(-i, firstDayOfMonth).toISOString();
    data.push({
      ...MONTHLY_FUEL_CONSUMPTION_DATA[
        i % MONTHLY_FUEL_CONSUMPTION_DATA.length
      ],
      date,
    });
  }

  return {
    aggregation: "month",
    title: `${metricName} per month`,
    unit: metricUnit,
    data,
  };
}

function getMetricParams(metrics, units, id) {
  const metric = get(metrics, id, {});
  const unit = get(units, metric.unitId, {});
  return {
    metricName: metric.name || "",
    metricUnit: unit.symbol || "",
  };
}
