import { ADMIN_TELEMETRY } from "../../api";
import fetch from "../action.fetch";
import { admin } from "../action.types";

export const fetchTelemetryData = ({ range }) =>
  fetch({
    url: ADMIN_TELEMETRY.get(range),
    actionType: admin.telemetry.FETCH_ADMIN_TELEMETRY,
  });

export const sortTelemetry = ({ sortBy, sortDirection }) => ({
  type: admin.telemetry.ADMIN_TELEMETRY_SORT,
  sortBy,
  sortDirection,
});
