import { identity } from "lodash";
import createReducer from "./createReducer";

const createResetActionHandlers = (initialState, actions) =>
  actions.reduce((handlers, action) => {
    handlers[action] = () => initialState;
    return handlers;
  }, {});

export const createRequestReducer = (
  requestAction,
  { prepareData = identity, resetActions = [] } = {}
) => {
  const initialState = {};

  return createReducer(initialState, {
    [requestAction]: () => ({ isLoading: true }),

    [`${requestAction}_SUCCESS`]: (_state, { data }) => ({
      data: prepareData(data),
    }),

    [`${requestAction}_ERROR`]: (_state, { error = true }) => ({ error }),

    ...createResetActionHandlers(initialState, resetActions),
  });
};

export const createIndexedRequestReducer = (
  requestAction,
  createKey,
  prepareData = identity
) =>
  createReducer(
    {},
    {
      [requestAction]: (state, action) => ({
        ...state,
        [createKey(action)]: { isLoading: true },
      }),

      [`${requestAction}_SUCCESS`]: (state, action) => ({
        ...state,
        [createKey(action)]: { data: prepareData(action.data) },
      }),

      [`${requestAction}_ERROR`]: (state, action) => ({
        ...state,
        [createKey(action)]: { error: action.error || true },
      }),
    }
  );
