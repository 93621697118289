import fetch from "./action.fetch";
import { CII_SCORES } from "../api_ts";
import { FETCH_CII_SCORES } from "./action.types";
import { AnyAction } from "redux";

export function fetchCIIScores(
  operationModes: string[],
  group: string
): AnyAction {
  return fetch({
    url: CII_SCORES.get(operationModes, group),
    actionType: FETCH_CII_SCORES,
    takeAll: true,
  }) as AnyAction;
}
