import PropTypes from "prop-types";
import React from "react";
import classNames from "../../../../common/classNames";
import styles from "./Metric.css";
import { roundConvert } from "../../../../common/numbers";

export default function Metric({ value, unit, description, testId }) {
  const newValue = roundConvert(value, unit);
  return (
    <div className={styles.metric}>
      <p className={styles.valueContainer}>
        <span
          className={classNames(newValue.hasValue && styles.value)}
          data-test-id={testId}
        >
          {newValue.number}
        </span>
        {<span className={styles.unit}>{newValue.unit}</span>}
      </p>
      <span className={styles.description}>{description}</span>
    </div>
  );
}

Metric.propTypes = {
  value: PropTypes.any,
  unit: PropTypes.string,
  description: PropTypes.string,
};
