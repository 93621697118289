import t from "prop-types";
import React, { Component } from "react";
import OperationalProfilePlan from "../components/OperationalProfilePlan";
import { fetchOperationalProfilePlan } from "../actions/action.operationalProfilePlan";
import { connect } from "react-redux";
import { isContextChanged, isContextValid } from "../common/contexts";

class OperationalProfilePlanContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.config.params && isContextValid(this.props.context)) {
      this.props.fetchOperationalProfilePlan(
        this.props.config.params.itemTypeMetricId[0],
        this.props.context
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.config.params &&
      isContextValid(this.props.context) &&
      (isContextChanged(this.props.context, prevProps.context) ||
        this.props.config !== prevProps.config)
    ) {
      this.props.fetchOperationalProfilePlan(
        this.props.config.params.itemTypeMetricId[0],
        this.props.context
      );
    }
  }

  render() {
    return (
      <OperationalProfilePlan
        context={this.props.context}
        itemTypeMetricId={this.props.config.params.itemTypeMetricId[0]}
      />
    );
  }
}

OperationalProfilePlanContainer.propTypes = {
  config: t.shape({
    params: t.shape({
      itemTypeMetricId: t.array.isRequired,
    }).isRequired,
  }),
  context: t.object.isRequired,
};

const mapDispatchToProps = {
  fetchOperationalProfilePlan,
};

export default connect(() => {}, mapDispatchToProps)(
  OperationalProfilePlanContainer
);
