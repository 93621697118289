import fetch from "./action.fetch";
import { FETCH_TREND_FOR_PERFORMANCE_INDICATOR } from "./action.types";
import { TREND } from "../api";

export const fetchTrendForPerformanceIndicator = (
  vesselId,
  performanceIndicatorIds,
  dateRange,
  timeOffset,
  states
) =>
  fetch({
    url: TREND.getTrendForPerformanceIndicator(
      vesselId,
      performanceIndicatorIds,
      dateRange,
      timeOffset,
      states
    ),
    actionType: FETCH_TREND_FOR_PERFORMANCE_INDICATOR,
    actionData: {
      vesselId,
      performanceIndicatorIds,
      dateRange,
      timeOffset,
      states,
    },
    key: `${FETCH_TREND_FOR_PERFORMANCE_INDICATOR}_${vesselId}`,
  });
