import createReducer from "./createReducer";
import {
  FETCH_LOCATIONS_IN_AREA,
  FETCH_LOCATIONS_IN_AREA_ERROR,
  FETCH_LOCATIONS_IN_AREA_SUCCESS,
} from "../actions/action.types";

export default createReducer(
  { locations: [], isLoading: false },
  {
    [FETCH_LOCATIONS_IN_AREA]: (state) => {
      return { ...state, isLoading: true };
    },
    [FETCH_LOCATIONS_IN_AREA_SUCCESS]: (state, { data }) => {
      return {
        ...state,
        isLoading: false,
        locations: data || [],
      };
    },
    [FETCH_LOCATIONS_IN_AREA_ERROR]: (state, { error }) => {
      return {
        ...state,
        location: [],
        isLoading: false,
        error: `Could not load locations: ${error}`,
      };
    },
  }
);
