import { keyBy } from "lodash";
import { VIEWS } from "../config";

const getViews = () => {
  return Object.keys(VIEWS).map((x) => {
    const value = VIEWS[x];
    return {
      key: x,
      ...value,
    };
  });
};

const caseInsensitiveMatch = (str1, str2) => {
  return str1.toLowerCase() === str2.toLowerCase();
};

export const getViewsByPath = () => {
  return keyBy(getViews(), "url");
};

export const getViewTitle = (path) => {
  const viewsByPath = getViewsByPath();

  // Try to find an exact match
  const exactMatch = viewsByPath[path];
  if (exactMatch) {
    return exactMatch.name;
  }

  // If no exact match, try case-insensitive matching
  const caseInsensitiveMatchedView = Object.values(viewsByPath).find((view) =>
    caseInsensitiveMatch(view.url, path)
  );

  if (caseInsensitiveMatchedView) {
    return caseInsensitiveMatchedView.name;
  }

  // If still no match, try matching all subpaths
  const subpathMatches = Object.values(viewsByPath)
    .filter((view) => path.startsWith(view.url) || view.url.startsWith(path))
    .sort((a, b) => b.url.length - a.url.length);

  console.log(subpathMatches);
  if (subpathMatches.length > 0) {
    return subpathMatches[0].name;
  }

  // Return null or a default title if no match is found
  return null;
};
