import { ADMIN_PAGE_CONFIG } from "../../api";
import fetch from "../action.fetch";
import { convertFieldsToKeyValues } from "../../common/forms";
import {
  FETCH_ADMIN_PAGE_CONFIGS,
  FETCH_ADMIN_PAGE_CONFIG,
  CREATE_ADMIN_PAGE_CONFIG,
  CLOSE_ADMIN_PAGE_CONFIG_POPUP,
  SAVE_ADMIN_PAGE_CONFIG,
  SAVE_ADMIN_PAGE_CONFIG_ERROR,
  SAVE_ADMIN_PAGE_CONFIG_SUCCESS,
  SET_ADMIN_PAGE_CONFIG_VALUE,
  OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  DELETE_ADMIN_PAGE_CONFIG,
  DELETE_ADMIN_PAGE_CONFIG_ERROR,
  DELETE_ADMIN_PAGE_CONFIG_SUCCESS,
  SET_ADMIN_PAGE_JSON_CONFIG,
  CLEAR_ADMIN_PAGE_JSON_CONFIG,
  CLEAR_ADMIN_PAGE_CONFIG_ROWS,
  CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID,
  CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID,
} from "../action.types";

export const fetchPageConfigs = (companyId) =>
  fetch({
    url: ADMIN_PAGE_CONFIG.get(companyId),
    actionType: FETCH_ADMIN_PAGE_CONFIGS,
    actionData: {
      companyId,
    },
  });

export const fetchPageConfig = (id) =>
  fetch({
    url: ADMIN_PAGE_CONFIG.getById(id),
    actionType: FETCH_ADMIN_PAGE_CONFIG,
    actionData: {
      id,
    },
  });

export const createPageConfig = () => ({
  type: CREATE_ADMIN_PAGE_CONFIG,
});

export const closePageConfigPopup = () => ({
  type: CLOSE_ADMIN_PAGE_CONFIG_POPUP,
});

export const openDeletePageConfigPopup = (pageConfig) => ({
  type: OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  pageConfig,
});

export const closeDeletePageConfigPopup = (id) => ({
  type: CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  id,
});

export const setPageConfigValue = (name, value, valid, message) => {
  return {
    type: SET_ADMIN_PAGE_CONFIG_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const setPageJsonConfig = (jsonConfig) => ({
  type: SET_ADMIN_PAGE_JSON_CONFIG,
  jsonConfig,
});

export const clearPageJsonConfig = () => ({
  type: CLEAR_ADMIN_PAGE_JSON_CONFIG,
});

export const savePageConfig =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { form } = state.admin.pageConfig;
    let payload = convertFieldsToKeyValues(form);
    try {
      //parse to JSON and re-stringify to always have consistent formatting
      const json = JSON.parse(payload.rows);
      payload.configurationJson = JSON.stringify(json, null, 1);
      performSave(form, dispatch, payload, http);
    } catch (e) {
      //do nothing
    }
  };

const performSave = (form, dispatch, payload, http) => {
  const isUpdate = form.isUpdate;

  dispatch({ type: SAVE_ADMIN_PAGE_CONFIG });

  http({
    method: isUpdate ? "put" : "post",
    url: isUpdate
      ? ADMIN_PAGE_CONFIG.put(payload.id)
      : ADMIN_PAGE_CONFIG.post(),
    data: payload,
  })
    .then(() => {
      dispatch({ type: SAVE_ADMIN_PAGE_CONFIG_SUCCESS });
      dispatch(fetchPageConfigs());
    })
    .catch((error) => {
      dispatch({ type: SAVE_ADMIN_PAGE_CONFIG_ERROR, error });
    });
};

export const deletePageConfig =
  (id) =>
  (dispatch, _getState, { http }) => {
    dispatch({ type: DELETE_ADMIN_PAGE_CONFIG });
    http({
      method: "delete",
      url: ADMIN_PAGE_CONFIG.delete(id),
      data: {},
    })
      .then(() => {
        dispatch({ type: DELETE_ADMIN_PAGE_CONFIG_SUCCESS });
        dispatch(fetchPageConfigs());
      })
      .catch(() => {
        dispatch({ type: DELETE_ADMIN_PAGE_CONFIG_ERROR });
      });
  };

export const clearPageConfigRows = () => ({
  type: CLEAR_ADMIN_PAGE_CONFIG_ROWS,
});

export const clearPageConfigVesselGroupId = () => ({
  type: CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID,
});

export const clearPageConfigVesselId = () => ({
  type: CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID,
});
