import { FETCH_POSITION_TRACK } from "./action.types";
import { POSITION_TRACK } from "../api";
import fetch from "./action.fetch";

export const fetchPositionTrack = (vesselId, dateRange, timeOffset) => {
  return fetch({
    url: POSITION_TRACK.get(vesselId, dateRange, timeOffset),
    actionType: FETCH_POSITION_TRACK,
  });
};
