import React, { useMemo } from "react";
import styles from "./FleetPerformance.css";
import { Header } from "../../components/Header";
import { Pagination } from "../../components/Pagination";
import { useGetFleetPerformanceQuery } from "./api.fleetPerformance";
import Loader from "../../../common/components/Loader";
import { FleetPerformanceTable } from "./FleetPerformanceTable/FleetPerformanceTable";
import { useDatePickerFilter } from "./hooks/useDatePickerFilter";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import { usePage } from "./hooks/usePage";
import { useGroupId } from "./hooks/useGroupId";
import { operationClassToOperations } from "../../../common/operation/operationId";
import { OperationClassID } from "../../../common/operation/operationClassID";
import SelectBox from "../../components/SelectBox";
import { FleetPerformanceRequest } from "./types";
import { useAppSelector } from "../../store/rootState";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import { ColumnInfo } from "./ColumnInfo";
import { useDispatch } from "react-redux";
import { SelectItemsHeader } from "../../components/SelectItemsHeader/SelectItemsHeader";
import { clearSelectedVessels, selectVessel } from "./reducer.fleetPerformance";
import { useDatePickerType } from "./hooks/useDatePickerType";
import { fetchCompare } from "../../actions";
import { VIEWS } from "../../config";
import { useQueryContext } from "../../providers/QueryContextProvider";
import { AnyAction } from "redux";

export const FleetPerformance = () => {
  const dispatch = useDispatch();
  const queryContext = useQueryContext();
  const [page, setPage] = usePage();
  const [groupId, setGroupId] = useGroupId();
  const [isValid, dateRange, dateValue, onDateChange] = useDatePickerFilter();
  const datePickerType = useDatePickerType();
  const groups = useAppSelector((state) => state.pageContext.groups);
  const groupOptions = useMemo(() => {
    const groupArray = Object.values(groups) as { id: string; name: string }[];
    return groupArray.map((x) => ({ id: x.id, name: x.name }));
  }, [groups]);

  const sorting = useAppSelector((state) => state.fleetPerformance.sorting);
  const sortingOrder = useAppSelector(
    (state) => state.fleetPerformance.sortingOrder
  );
  const selectedVessels = useAppSelector(
    (state) => state.fleetPerformance.selectedVessels
  );
  const onCompare = () => {
    selectedVessels.forEach(({ id }) => {
      dispatch(
        fetchCompare(
          id,
          dateRange,
          datePickerType,
          parseInt(dateValue.timeOffset)
        ) as AnyAction
      );
    });
    queryContext.navigate(VIEWS.compare.url);
  };

  const request: FleetPerformanceRequest = useMemo(
    () => ({
      sorting: sorting,
      sortingOrder: sortingOrder,
      from: dateRange.from,
      to: dateRange.to!,
      pageNumber: page,
      pageSize: 10,
      groupId,
      operationIds,
    }),
    [
      sorting,
      sortingOrder,
      dateRange.from,
      dateRange.to,
      page,
      groupId,
      operationIds,
    ]
  );

  const result = useGetFleetPerformanceQuery(request);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header
          title={"Fleet Performance"}
          contentDistribution={"space-between"}
        >
          <SelectBox
            options={groupOptions}
            optionValKey="id"
            optionLabelKey="name"
            onSelect={(group: { id: string }) => {
              setGroupId(group.id);
            }}
            selected={groupOptions.find((x) => x.id === groupId)}
          />
          {isValid && (
            <DatePickerContainer
              limits={datePickerLimits}
              onChange={onDateChange}
              current={dateValue}
              supportedTypes={["year", "quarter", "month"]}
            />
          )}
        </Header>
        {selectedVessels.length > 0 && (
          <SelectItemsHeader
            items={selectedVessels}
            idSelector={(v) => v.id}
            labelSelector={(v) => v.name}
            onClick={onCompare}
            buttonText={"Compare"}
            onClose={() => dispatch(clearSelectedVessels())}
            onRemoveItem={(v) => dispatch(selectVessel(v))}
          />
        )}
      </div>

      {(result.isFetching || result.isError || !result.currentData) && (
        <Loader error={result.isError} />
      )}
      {result.isSuccess && result.currentData && (
        <>
          <div className={styles.body}>
            <ComponentInfo
              modalTargetId={"popupContainer"}
              buttonStyle={{ rights: "1rem" }}
              title="Fleet Performance"
              template={<ColumnInfo />}
            >
              <FleetPerformanceTable vessels={result.currentData.vessels} />
            </ComponentInfo>
          </div>
          <div className={styles.footer}>
            <Pagination
              drawSpacer={false}
              totalPosts={result.currentData.numberOfVessels}
              postsPerPage={Math.max(
                result.currentData.vessels.length,
                request.pageSize
              )}
              currentPageNumber={page}
              paginate={setPage}
            />
          </div>
        </>
      )}
    </div>
  );
};

const datePickerLimits = {
  from: new Date(2000, 0, 1),
  to: null,
};

const operationIds = [
  ...operationClassToOperations[OperationClassID.Transit],
  ...operationClassToOperations[OperationClassID.DP],
  ...operationClassToOperations[OperationClassID.Passive],
];
