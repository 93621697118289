import React from "react";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import { createPropertyValueComponent } from "../PropertyValues/PropertyValues";
import styles from "./DataDetailsPropertyList.css";

class DataDetailsPropertyList extends React.PureComponent {
  render() {
    const { properties, fullWidth } = this.props;
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <div className={styles.container}>
          {properties &&
            properties.map((p, i) =>
              createPropertyValueComponent({ ...p, key: `property_${i}` })
            )}
        </div>
      </DataDetailsComponentContainer>
    );
  }
}

export default DataDetailsPropertyList;
