import { fetchFleetTable } from "./action.fetchFleetTable";
import { isEqual } from "lodash";

export const fetchFleetPageData =
  (filters, prevFilters = {}) =>
  (dispatch) => {
    const { groupFilter, dateFilter, vesselFilter } = filters;

    if (
      filters.isValid &&
      (!isEqual(groupFilter, prevFilters.groupFilter) ||
        !isEqual(dateFilter, prevFilters.dateFilter) ||
        !isEqual(vesselFilter, prevFilters.vesselFilter))
    ) {
      dispatch(
        fetchFleetTable(
          groupFilter.value,
          dateFilter.value.range,
          parseInt(dateFilter.value.timeOffset),
          vesselFilter
        )
      );
    }
  };
