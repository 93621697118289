import React from "react";
import styles from "./NoAccess.css";

const NoAccess = () => {
  return (
    <div className={styles.container}>
      <h1>The selected vessel does not have this module enabled.</h1>
      <p>
        Please select another vessel, or contact Kongsberg support for access.
      </p>
    </div>
  );
};

export default NoAccess;
