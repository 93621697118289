import { DateRange } from "../components/DatePicker/Types";
import { AnyAction } from "redux";
import { FETCH_EMISSIONS_BY_OPERATION_MODES } from "./action.types";
import { EMISSIONS_BY_OPERATION_MODES } from "../api_ts";
import fetch from "./action.fetch";

export function fetchEmissionsByOperationModes(
  interval: DateRange,
  vesselGroup: string
): AnyAction {
  return fetch({
    url: EMISSIONS_BY_OPERATION_MODES.get(
      interval.from,
      interval.to,
      vesselGroup
    ),
    actionType: FETCH_EMISSIONS_BY_OPERATION_MODES,
    takeAll: true,
  }) as AnyAction;
}
