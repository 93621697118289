import {
  admin,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE,
  FETCH_ADMIN_VESSELS,
  FETCH_ADMIN_VESSELS_ERROR,
  FETCH_ADMIN_VESSELS_SUCCESS,
} from "../../../actions/action.types";
import createReducer from "../../createReducer";
import moment from "moment";
import { now } from "../../../common/dates";
import { get } from "lodash";

const formatRemoteAccess = ({ actions }) => {
  const remoteAccessAction = actions.find((x) => x.type === "RemoteAccess");
  const currentDate = moment(now());
  let remoteAccessEnd = get(remoteAccessAction, "actionMetadata.validTo")
    ? moment(get(remoteAccessAction, "actionMetadata.validTo"))
    : undefined;
  if (remoteAccessEnd === undefined) {
    return "";
  }

  return remoteAccessEnd > currentDate && remoteAccessEnd.diff(currentDate)
    ? `${moment.duration(remoteAccessEnd.diff(currentDate)).humanize()} left`
    : null;
};

export const vesselList = createReducer(
  {},
  {
    /*
     *  Vessel admin
     */

    [FETCH_ADMIN_VESSELS]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [FETCH_ADMIN_VESSELS_SUCCESS]: (state, { data }) => ({
      ...state,
      data: data.map((x) => ({
        ...x,
        remoteAccess: formatRemoteAccess(x),
      })),
      isLoading: false,
    }),

    [FETCH_ADMIN_VESSELS_ERROR]: (state, { error }) => ({
      ...state,
      error,
      isLoading: false,
    }),

    /*
     * Configure sensor replication
     */
    [admin.vessels.sensorReplication.CONFIGURE]: (state) => ({
      ...state,
      showSensorReplicationPopup: true,
    }),

    [admin.vessels.sensorReplication.CONFIGURE_CLOSE]: (state) => ({
      ...state,
      showSensorReplicationPopup: false,
    }),

    [admin.vessels.sensorReplication.SAVE_SUCCESS]: (state) => ({
      ...state,
      showSensorReplicationPopup: false,
    }),

    /*
     * Remote control
     */
    [ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE]: (state) => ({
      ...state,
      showRemoteAccessControlPopup: false,
    }),

    [ADMIN_VESSEL_REMOTECONTROL_CONFIGURE]: (state) => ({
      ...state,
      showRemoteAccessControlPopup: true,
    }),

    [admin.vessels.remoteControl.acknowledgeActionFailure.SAVE_SUCCESS]: (
      state
    ) => ({
      ...state,
      showRemoteAccessControlPopup: false,
    }),

    [admin.vessels.remoteControl.remoteAccess.SAVE_SUCCESS]: (state) => ({
      ...state,
      showRemoteAccessControlPopup: false,
    }),

    [admin.vessels.remoteControl.remoteAccess.REVOKE_SUCCESS]: (state) => ({
      ...state,
      showRemoteAccessControlPopup: false,
    }),

    [admin.vessels.remoteControl.updateSystem.SAVE_SUCCESS]: (state) => ({
      ...state,
      showRemoteAccessControlPopup: false,
    }),

    [admin.vessels.remoteControl.updateTargets.FETCH_ERROR]: (
      state,
      { error }
    ) => ({
      ...state,
      error,
      isLoading: false,
    }),

    [admin.vessels.remoteControl.updateTargets.FETCH_SUCCESS]: (
      state,
      { data }
    ) => ({
      ...state,
      updateTargets: data,
    }),
  }
);
