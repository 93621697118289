import { MACHINERYREPORTS } from "../../../api";

export const machineryReportExportConfigurations = [
  {
    name: "Machinery report export",
    description: "Export machinery report to Microsoft Excel format",
    url: (vesselId, dateRange, startTimeOffset, endTimeOffset) =>
      MACHINERYREPORTS.getExport(
        vesselId,
        dateRange,
        startTimeOffset,
        endTimeOffset
      ),
  },
];
