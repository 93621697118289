/**
 * Transformation helper class
 */
export default class Transform {
  x = 0;
  y = 0;
  k = 0;

  constructor(x: number, y: number, k: number) {
    this.x = x;
    this.y = y;
    this.k = k;
  }

  /**
   * Returns the identity transformation
   * @returns {Transform}
   */
  static identityTransform() {
    return new Transform(0, 0, 1);
  }

  /**
   * Translates the transform in the x-axis
   * @param x Transform value
   * @param translateExtent Translate extent
   * @returns {Transform}
   */
  translateX(x = 0, translateExtent: number[] | undefined = undefined) {
    const transform = this.copy();
    transform.x = translateExtent
      ? Math.min(
          translateExtent[0],
          Math.max(translateExtent[1], transform.x + x)
        )
      : transform.x + x;
    return transform;
  }

  /**
   * Inverts the x-value
   * @param x
   * @returns {number}
   */
  invertX = (x: number) => {
    return (x - this.x) / this.k;
  };

  /**
   * Scales the transform in the x-axis
   * @param scale Scale factor
   * @returns {Transform}
   */
  scaleX(scale: number) {
    const transform = this.copy();
    transform.k *= scale;
    return transform;
  }

  /**
   * Scales the transform in the x-axis centered around a point
   * @param scale Scale factor
   * @param x Point x-value
   * @param width Width
   * @param scaleExtent Scale extent
   * @param translateExtent Translate extent
   * @returns {Transform}
   */
  scaleXToPoint(
    scale: number,
    x: number,
    width: number,
    scaleExtent: number[],
    translateExtent: number[]
  ) {
    let transform = this.copy();
    transform.k = scaleExtent
      ? Math.max(scaleExtent[0], Math.min(scaleExtent[1], this.k * scale))
      : this.k * scale;

    const prevFullWidth = width * this.k;
    const widthDiff = (this.k - transform.k) * width;

    transform = transform.translateX(
      (widthDiff * (-this.x + x)) / prevFullWidth,
      translateExtent
    );

    return transform;
  }

  /**
   * Creates a copy of the transformation
   * @returns {Transform}
   */
  copy() {
    return new Transform(this.x, this.y, this.k);
  }

  /**
   * Rescales a d3.scale with the current transformation
   * @param x d3.scale
   * @returns {*|never}
   */
  rescaleX = (x: any) => {
    return x.copy().domain(x.range().map(this.invertX, this).map(x.invert, x));
  };
}
