import React from "react";
import { hexStringToInt, rgbToHex } from "../PlotChart/PlotChart";
import DataDetailsHeaderContainer from "./DataDetailsHeaderContainer";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import { COSMETIC_CONSTS } from "../../config";
import { color } from "../../../common/colors";
import * as PIXI from "pixi.js";
import { formatNumber } from "../../../common/numbers";
import { formatDateTimeWithSeconds } from "../../common/dates";
import { MultiSeriesPlotChart } from "../MultiSeriesPlotChart/MultiSeriesPlotChart";
import { getYAxisConfig } from "./Helpers";
import * as d3 from "d3";
import { get } from "lodash";

const axisLabelStyle = {
  fontSize: "11px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--light-grey"),
  fontWeight: "400",
  textAlign: "right",
};

const hoverLabelStyle = {
  fontSize: "12px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--page-background"),
  fontWeight: "600",
  textAlign: "center",
};

class DataDetailsPlotChart extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }
  static getTickFormat(dataType) {
    switch (dataType) {
      case "Date":
        return d3.timeFormat("%Y-%m-%d");
      case "DateTime":
        return d3.timeFormat("%Y-%m-%d %H:%M:%S");
      default:
        return null;
    }
  }

  renderXAxisLegend = (context) => {
    const { xLabel, xUnit, margins, axisLabelTextStyle, width, height } =
      context;

    const xUnitOrEmptyString = xUnit ? ` (${xUnit})` : "";

    const xAxisLabel = new PIXI.Text(
      `${xLabel ? xLabel : ""}${xUnitOrEmptyString}`,
      axisLabelTextStyle
    );
    xAxisLabel.x = width - margins.right - xAxisLabel.width;
    xAxisLabel.y = height - margins.bottom - xAxisLabel.height - 2;
    return xAxisLabel;
  };

  renderHoverItem = (context) => {
    const {
      sprite,
      value,
      zUnit,
      xUnit,
      yUnit,
      zDataType,
      hoverLabelTextStyle,
      width,
      seriesColor,
      renderer,
    } = context;
    const spriteHoverContainer = new PIXI.Container();

    let zLabel;
    if (zDataType === "datetime") {
      zLabel = `${formatDateTimeWithSeconds(value.z)} `;
    } else {
      zLabel = `${formatNumber(value.z)}${zUnit ? " " + zUnit : ""} `;
    }
    const label = new PIXI.Text(
      zLabel +
        `(${formatNumber(value.x)}${xUnit ? " " + xUnit : ""}, ` +
        `${formatNumber(value.y)}${yUnit ? " " + yUnit : ""})`,
      hoverLabelTextStyle
    );
    label.x = 15;
    label.y = 11 - label.height / 2;

    const hoverGfx = new PIXI.Graphics();
    hoverGfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
    hoverGfx.beginFill(hexStringToInt(color("--white")));
    hoverGfx.drawRoundedRect(0, 0, label.width + 30, 20, 20);
    hoverGfx.endFill();
    const hoverBackgroundTexture = renderer.generateTexture(hoverGfx);
    const hoverSprite = new PIXI.Sprite(hoverBackgroundTexture);
    hoverSprite.tint = rgbToHex(seriesColor);
    spriteHoverContainer.addChild(hoverSprite);
    spriteHoverContainer.addChild(label);

    if (spriteHoverContainer.width + sprite.x + 14 > width) {
      spriteHoverContainer.x = sprite.x - spriteHoverContainer.width - 2;
      spriteHoverContainer.y = sprite.y - 6;
    } else {
      spriteHoverContainer.x = sprite.x + 14;
      spriteHoverContainer.y = sprite.y - 6;
    }
    return spriteHoverContainer;
  };

  render() {
    const {
      normalLineB,
      normalLineM,
      fullWidth,
      series,
      title,
      componentDescription,
      yAxisConfig,
      baseline,
    } = this.props;
    const mappedSeries = series.map((s, index) => {
      return {
        ...s,
        colorIndex: index,
      };
    });
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <DataDetailsHeaderContainer
          contentTitle={title}
          componentDescription={componentDescription}
          showHeader={false}
        >
          <AutoSizer>
            {({ width, height }) => (
              <MultiSeriesPlotChart
                width={width}
                height={height}
                series={mappedSeries}
                margins={{ left: 30, top: 30, right: 30, bottom: 30 }}
                axisLabelStyle={axisLabelStyle}
                customXAxisLegendRenderer={this.renderXAxisLegend}
                hoverLabelStyle={hoverLabelStyle}
                customHoverItemRenderer={this.renderHoverItem}
                normalLineB={normalLineB}
                normalLineM={normalLineM}
                forceCanvas={true}
                baseline={baseline}
                colorizeZIndex={mappedSeries.length === 1}
                formatTickLabel={DataDetailsPlotChart.getTickFormat(
                  get(series, "[0].xDataType", "")
                )}
                {...getYAxisConfig(yAxisConfig)}
              />
            )}
          </AutoSizer>
        </DataDetailsHeaderContainer>
      </DataDetailsComponentContainer>
    );
  }
}

export default DataDetailsPlotChart;
