import { fetchTrendForVesselItem } from "./action.fetchTrendForVesselItem";
import { fetchTrendForPerformanceIndicator } from "./action.fetchTrendForPerformanceIndicator";
import { fetchOperationTrend } from "./action.fetchOperationTrend";
import fetch from "./action.fetch";
import { VESSELS } from "../api";
import { components, FETCH_TREND_DATA } from "./action.types";
import { get, isEqual, keys } from "lodash";

export const fetchTrendData =
  (
    { vesselId, date, vesselItems, performanceIndicators, states },
    refreshOperations = true
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_TREND_DATA,
      dateRange: date.range,
      vesselId,
      vesselItems,
      performanceIndicators,
      timeOffset: parseInt(date.timeOffset),
      states,
    });

    let { trend } = getState();
    let { performanceIndicatorsData, vesselItemsData } = trend;

    vesselItems
      .filter((vi) => vi.isActive)
      .forEach((vi) => {
        const vesselItemValues = get(vesselItemsData, `[${vi.id}].values`, []);
        const metrics = vi.metrics.filter(
          (m) =>
            m.isActive === true &&
            !vesselItemValues.some((x) => isEqual(m.id, x.id))
        );
        if (metrics && metrics.length > 0) {
          dispatch(
            fetchTrendForVesselItem(
              vesselId,
              vi.id,
              metrics.map((m) => m.id),
              date.range,
              parseInt(date.timeOffset),
              states
            )
          );
        }
      });

    if (refreshOperations) {
      dispatch(
        fetchOperationTrend(
          vesselId,
          date.range,
          parseInt(date.timeOffset),
          states
        )
      );
    }

    if (performanceIndicators.isActive) {
      const performanceIndicatorsInState = keys(performanceIndicatorsData);
      const performanceIndicatorIds = performanceIndicators.entries
        .filter(
          (pi) =>
            pi.isActive === true &&
            !performanceIndicatorsInState.some((pis) => isEqual(pi.id, pis))
        )
        .map((pi) => pi.id);
      if (performanceIndicatorIds && performanceIndicatorIds.length > 0) {
        dispatch(
          fetchTrendForPerformanceIndicator(
            vesselId,
            performanceIndicatorIds,
            date.range,
            parseInt(date.timeOffset),
            states
          )
        );
      }
    }
  };

export const fetchTrendVessel = (vesselId) =>
  fetch({
    url: VESSELS.getWithId(vesselId),
    actionType: components.trend.FETCH_VESSEL,
  });
