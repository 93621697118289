import React from "react";
import VesselAction from "./VesselAction";
import { connect } from "react-redux";

class ConfigureVesselAction extends React.Component {
  render() {
    const { currentAction } = this.props;

    return <div>{currentAction && <VesselAction />}</div>;
  }
}

const mapStateToProps = (state) => ({
  currentAction: state.admin.vessels.configureRemoteControl.currentAction,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureVesselAction);
