import { keyBy } from "lodash";
import {
  OperationClassID,
  operationClassTitle,
} from "./operation/operationClassID";
import {
  operationClassToOperations,
  operationColor,
  operationLegend,
  operationName,
  operationTextColor,
} from "./operation/operationId";

export const OPERATION_MODES = Object.entries(operationClassToOperations).map(
  ([operationClassId, operationIds]) => ({
    name: operationClassTitle[operationClassId as unknown as OperationClassID],
    profiles: operationIds.map((operationId) => ({
      name: operationLegend[operationId],
      color: operationColor[operationId],
      title: operationName[operationId],
      textColor: operationTextColor[operationId],
    })),
  })
);

//A reduced/flattened version of OPERATION_MODES
const allProfiles = OPERATION_MODES.flatMap((x) => x.profiles);
export const PROFILES = keyBy(allProfiles, "name");
