import { FETCH_LEGS, FETCH_VOYAGES } from "../actions/action.types";
import createReducer from "./createReducer";

const createVesselStateReducer = (fetchAction) =>
  createReducer(
    {},
    {
      [fetchAction]: (state, action) => ({
        ...state,
        [action.vesselId]: {
          month: action.month,
          timeOffset: action.timeOffset,
          isLoading: true,
        },
      }),

      [`${fetchAction}_SUCCESS`]: (state, action) => ({
        ...state,
        [action.vesselId]: {
          month: action.month,
          data: action.data,
          timeOffset: action.timeOffset,
        },
      }),

      [`${fetchAction}_ERROR`]: (state, action) => ({
        ...state,
        [action.vesselId]: {
          month: action.month,
          timeOffset: action.timeOffset,
          error: action.error,
        },
      }),
    }
  );

export const legs = createVesselStateReducer(FETCH_LEGS);
export const voyages = createVesselStateReducer(FETCH_VOYAGES);
