import React from "react";
import PropTypes from "prop-types";
import styles from "./Settings.css";
import CategoryItem from "../../../components/CompareTable/CategoryItem/CategoryItem";

const SettingsGroup = ({ title, description, icon, children }) => {
  let childArray = React.Children.toArray(children);
  return (
    <div className={styles.settingsRow}>
      <div className={styles.settingsCellContainer}>
        <CategoryItem title={title} description={description} icon={icon} />
      </div>
      {[...new Array(4).keys()].map((_, index) => {
        let child = childArray[index];
        return (
          <div className={styles.settingsCellContainer} key={index}>
            {child}
          </div>
        );
      })}
    </div>
  );
};

SettingsGroup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
};

export default SettingsGroup;
