import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import Popup from "../../../components/Popup/Popup";
import styles from "../Vessels/GenerateApiTokenPopup.css";
import Loader from "../../../../common/components/Loader";
import {
  closeGenerateApiSecretPopup,
  confirmClearApiSecret,
  confirmRegenerateApiSecret,
} from "../../../actions/admin/action.users";
import { admin } from "../../../actions/action.types";

const LoadingContent = () => {
  return <Loader expand={true} />;
};

const ApiSecretGeneratedSuccessContent = ({ apiSecretPopup }) => {
  const { secret } = apiSecretPopup;
  const [keyCopied, setKeyCopied] = useState(false);
  const textInputRef = useRef("null");

  const copyKeyToClipboard = () => {
    navigator.clipboard.writeText(secret);
    setKeyCopied(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.successText}>Success</div>
      <p>
        User API Client Secret generated successfully. Copy this secret and keep
        it safe. After closing this window you will not be able to retrieve it
        unless you generate an new.
      </p>
      <input
        type={"text"}
        value={secret || ""}
        readOnly={true}
        ref={textInputRef}
        className={styles.tokenContainer}
      />
      <div className={styles.copyLinkButton} onClick={copyKeyToClipboard}>
        Copy to clipboard
        {keyCopied && <span className={styles.check}>&#x2713;</span>}
      </div>
    </div>
  );
};

const ErrorContent = ({ header, error }) => {
  return (
    <div className={styles.container}>
      <div className={styles.warningText}>{header}</div>
      <p>{error}</p>
    </div>
  );
};

const InfoContent = ({ header, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.successText}>{header}</div>
      {text && <p>{text}</p>}
    </div>
  );
};

const WarningContent = ({ header, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.warningText}>{header}</div>
      {text && <p>{text}</p>}
    </div>
  );
};

const UserApiClientSecretPopup = (props) => {
  const {
    apiSecretPopup,
    closeGenerateApiSecretPopup,
    confirmClearApiSecret,
    confirmRegenerateApiSecret,
  } = props;
  const { isVisible, isLoading, hasError, error, action } = apiSecretPopup;

  let buttons, content, title;
  if (isLoading) {
    title =
      action === admin.users.GENERATE_API_SECRET
        ? "Generating User API Secret"
        : "Clearing User API Secret";
    buttons = [];
    content = <LoadingContent {...props} />;
  } else if (hasError) {
    title =
      action === admin.users.GENERATE_API_SECRET
        ? "Failed Generating User API Secret"
        : "Failed Clearing User API Secret";
    buttons = [{ label: "Close", action: closeGenerateApiSecretPopup }];
    content = <ErrorContent header={"Error"} error={error} />;
  } else {
    if (action === admin.users.GENERATE_API_SECRET) {
      title = "Generated User API Secret";
      buttons = [{ label: "Close", action: closeGenerateApiSecretPopup }];
      content = <ApiSecretGeneratedSuccessContent {...props} />;
    } else if (action === admin.users.REQUEST_CLEAR_API_SECRET) {
      title = "Confirm clearing User API Secret";
      buttons = [
        { label: "Cancel", action: closeGenerateApiSecretPopup },
        { label: "Confirm", action: confirmClearApiSecret },
      ];
      content = (
        <WarningContent
          header={"Warning! This operation cannot be undone"}
          text={
            "By clearing the User API Client Secret existing applications using this secret no longer will be able to authenticate"
          }
        />
      );
    } else if (action === admin.users.CLEAR_API_SECRET) {
      title = "Cleared User API Secret";
      buttons = [{ label: "Close", action: closeGenerateApiSecretPopup }];
      content = (
        <InfoContent header={"Success"} text={"Cleared User API Secret"} />
      );
    } else if (action === admin.users.REQUEST_REGENERATE_API_SECRET) {
      title = "Confirm regenerate User API Secret";
      buttons = [
        { label: "Cancel", action: closeGenerateApiSecretPopup },
        { label: "Confirm", action: confirmRegenerateApiSecret },
      ];
      content = (
        <WarningContent
          header={"Warning! This operation cannot be undone"}
          text={
            "By regenerating the User API Client Secret existing applications using this secret no longer will be able to authenticate"
          }
        />
      );
    }
  }

  return (
    <Popup visible={isVisible} title={title} buttons={buttons}>
      {content}
    </Popup>
  );
};

const mapStateToProps = (state) => ({
  apiSecretPopup: state.admin.users.apiSecretPopup,
});

const mapDispatchToProps = {
  closeGenerateApiSecretPopup,
  confirmClearApiSecret,
  confirmRegenerateApiSecret,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserApiClientSecretPopup);
