import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import adminStyles from "../Admin.css";
import styles from "./DataMaintenance.css";
import SelectBox from "../../../components/SelectBox";
import { createMonthFilter } from "../../../common/dateFilter";
import DatePickerContainer from "../../../components/DatePicker/DatePickerContainer";
import { getLimits } from "../../../common/vessels";
import {
  fetchVessels,
  fetchDataCount,
  deleteData,
} from "../../../actions/admin/action.dataMaintenance";

class DataMaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = { selectedDate: createMonthFilter(new Date()) };
  }

  componentDidMount() {
    this.props.fetchVessels();
  }

  componentDidUpdate(_, prevState) {
    const {
      selectedVessel: selectedVesselPrev,
      selectedDate: selectedDatePrev,
    } = prevState;
    const { selectedVessel, selectedDate } = this.state;
    if (
      selectedVessel &&
      selectedDate &&
      (selectedVesselPrev !== selectedVessel ||
        selectedDatePrev !== selectedDate)
    ) {
      this.props.fetchDataCount({
        vesselId: selectedVessel.id,
        dateRange: selectedDate.range,
      });
    }
  }

  onSelectedVesselChange(vessel) {
    this.setState((state) => ({
      ...state,
      selectedVessel: vessel,
    }));
  }

  onDateChange(date) {
    this.setState((state) => ({
      ...state,
      selectedDate: date,
    }));
  }

  renderDataCount(data) {
    return Object.entries(data).map(([key, value]) => (
      <div key={key} className={styles.dataCountEntry}>
        <p>{key}</p>
        <p>{value}</p>
      </div>
    ));
  }

  onDelete() {
    this.props.deleteData({
      vesselId: this.state.selectedVessel.id,
      dateRange: this.state.selectedDate.range,
    });
  }

  render() {
    const {
      vesselOptions,
      isLoading,
      hasError,
      data,
      isDeleting,
      getVesselLimits,
    } = this.props;
    const limits = getVesselLimits(this.state.selectedVessel?.id);
    return (
      <div>
        <Header
          title={"Admin - Data Maintenance"}
          contentDistribution={"space-between"}
        >
          <div className={adminStyles.headerContainer}>
            <SelectBox
              className={adminStyles.headerContainerItem}
              placeholder={
                vesselOptions.isLoading ? "Loading..." : "Select vessel"
              }
              options={vesselOptions}
              optionValKey="id"
              optionLabelKey="name"
              selected={this.state.selectedVessel}
              onSelect={this.onSelectedVesselChange.bind(this)}
            />
          </div>
          <div className={styles.rightHeaderContainer}>
            {this.state.selectedVessel && (
              <DatePickerContainer
                limits={limits}
                current={this.state.selectedDate}
                vesselId={this.state.selectedVessel.id}
                onChange={this.onDateChange.bind(this)}
                supportedTypes={["month", "dateRange"]}
              />
            )}
          </div>
        </Header>
        <div className={styles.content}>
          <p className={styles.infoBox}>
            Select a vessel, and a date range. The date range can not be longer
            than 31 days, due to amount of data affected. This might take a long
            time, so be patient. If it does not work, try a shorter time period.
            Hour of data is sum of all vessel items in the period.
          </p>
          {isLoading ? (
            <Loader />
          ) : (
            data && (
              <div className={styles.container}>
                <h1> Hours of data in Hour tables:</h1>
                {this.renderDataCount(data)}
                <div className={styles.actions}>
                  <Button
                    value="Delete"
                    clickAction={this.onDelete.bind(this)}
                    isLoading={isDeleting}
                    disabled={isDeleting}
                  />
                </div>
                {hasError && (
                  <div className={styles.errorContainer}>
                    <p className={styles.error}>
                      Something happened, maybe try a shorter time period. If
                      this error persists, contact the VP Team.
                    </p>
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedVesselId } = state.pageContext;
  const { vesselOptions, data, error, isLoading, hasError, isDeleting } =
    state.admin.dataMaintenance || {};
  const getVesselLimits = (id) =>
    id ? getLimits(state.pageContext.vessels[id]) : {};

  return {
    getVesselLimits,
    vesselOptions,
    isLoading,
    hasError,
    data,
    error,
    isDeleting,
    selectedVessel:
      selectedVesselId && vesselOptions
        ? vesselOptions.find((x) => x.id === selectedVesselId)
        : null,
    canEdit: isSystemAdmin(state.user),
  };
};

const mapActionsToProps = {
  fetchVessels,
  deleteData,
  fetchDataCount,
};

export default connect(mapStateToProps, mapActionsToProps)(DataMaintenance);
