import * as d3 from "d3";
import { has, isNil, sortBy } from "lodash";

export const parseCurve = (curveString) => {
  if (!curveString) return null;
  return d3[`curve${curveString}`];
};

export const getUniqueXValues = (data, disableSort) => {
  const set = new Set();
  let isNumbers = true;
  for (let ds of data)
    for (let d of ds) {
      const val = has(d, "x") ? d.x : d;
      if (!isNil(val)) {
        if (!isFinite(val)) {
          isNumbers = false;
        }
        set.add(val);
      }
    }

  return !disableSort && isNumbers
    ? sortBy(Array.from(set), (x) => +x)
    : Array.from(set);
};

export const getUniqueYValues = (data) => {
  const set = new Set();
  for (let ds of data)
    for (let d of ds) {
      const val = has(d, "y") ? d.y : d;
      if (!isNil(val)) {
        set.add(val);
      }
    }
  return Array.from(set).sort((a, b) => a - b);
};

export const getLines = (text) => (Array.isArray(text) ? text : [text]);

export const adjustHoverPoints = (
  hoverPointBounds,
  sortedHoverPoints,
  height,
  width
) => {
  let curYIndex = 0;
  let curHeight = 0;
  const newWidth =
    Math.ceil(hoverPointBounds.height / height) * hoverPointBounds.width;
  for (const sortedHoverPoint of sortedHoverPoints) {
    const hoverPoint = sortedHoverPoint;

    if (hoverPoint.circleX + newWidth > width) {
      hoverPoint.tooltipX = hoverPoint.circleX - hoverPoint.width - 5;
    }

    if (curHeight + hoverPoint.height > height) {
      curHeight = 0;
      curYIndex++;
    }

    hoverPoint.tooltipY = curHeight;
    if (hoverPoint.tooltipX > hoverPoint.circleX) {
      hoverPoint.tooltipX = hoverPoint.tooltipX + hoverPoint.width * curYIndex;
    } else {
      hoverPoint.tooltipX = hoverPoint.tooltipX - hoverPoint.width * curYIndex;
    }

    curHeight += hoverPoint.height;
  }
};
