import { faker } from "@faker-js/faker";
import { FuelType } from "../../../onboard/models/FuelType";
import { OperationId } from "../../../common/operation/operationId";
import { Vessel } from "../../views/FleetPerformance/types";

const randomConsumption = () => faker.number.float({ min: 0, max: 100 });
const randomConsumptionGraph = () =>
  faker.helpers.multiple(randomConsumption, { count: 31 });
const randomConsumptionPerOperation = () => randomConsumption() / 6;
const randomConsumptionArray = (randomConsumptionMethod: () => number) => [
  { fuelType: FuelType.MDO, consumption: randomConsumptionMethod() },
  { fuelType: FuelType.HFO, consumption: randomConsumptionMethod() },
  { fuelType: FuelType.LNG, consumption: randomConsumptionMethod() },
  { fuelType: FuelType.MGO, consumption: randomConsumptionMethod() },
];

const createRandomVessel = (): Vessel => ({
  vessel: {
    id: faker.string.uuid(),
    imo: faker.number.int({ min: 10000000, max: 99999999 }),
    name: `${faker.word.adjective({})} ${faker.word.noun()}`,
  },
  consumption: randomConsumptionArray(randomConsumption),
  trend: {
    graph: [
      {
        fuelType: FuelType.MDO,
        consumption: randomConsumptionGraph(),
      },
      {
        fuelType: FuelType.HFO,
        consumption: randomConsumptionGraph(),
      },
      {
        fuelType: FuelType.LNG,
        consumption: randomConsumptionGraph(),
      },
      {
        fuelType: FuelType.MGO,
        consumption: randomConsumptionGraph(),
      },
    ],
    percentageValue: faker.number.float({ min: -120, max: 120 }),
  },
  consumptionPerOperation: [
    {
      operationId: OperationId.Transit,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.TransitFast,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.TransitEco,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.Maneuver,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.Port,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.Dp,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.DpClass2,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
    {
      operationId: OperationId.DpGangway,
      consumption: randomConsumptionArray(randomConsumptionPerOperation),
    },
  ],
});

export const VESSELS: Vessel[] = faker.helpers.multiple(createRandomVessel, {
  count: 23,
});
