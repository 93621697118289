import React from "react";
import { EmissionsForVessel, VesselListProps } from "./Types";
import styles from "./VesselList.css";
import { roundNumber } from "../../../common/numbers";
import { VIEWS } from "../../config";
import { useNavigate } from "react-router-dom";

const VesselList: React.FC<VesselListProps> = ({ list }: VesselListProps) => {
  const unit = "t/h";
  const navigate = useNavigate();

  return (
    <div className={styles.vesselList} data-test-id="vesselList">
      <table>
        <colgroup>
          <col className={styles.nameCol} />
          <col className={styles.emissionCol} />
          <col className={styles.unitCol} />
        </colgroup>
        <thead>
          <tr>
            <th>Vessel</th>
            <th colSpan={2} style={{ textAlign: "end" }}>
              CO2 Emission
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item: EmissionsForVessel) => {
            return (
              <tr className={styles.row} key={item.vesselId}>
                <td
                  className={styles.vesselName}
                  onClick={() =>
                    navigate(
                      `${VIEWS.fuelEmissions.url}?vesselId=${item.vesselId}`
                    )
                  }
                >
                  {item.vesselName}
                </td>
                <td className={styles.emission}>
                  {roundNumber(item.co2 / 1000.0, 1)}
                </td>
                <td className={styles.unit}>{unit}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VesselList;
