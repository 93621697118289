import {
  FETCH_MONTHLY_FUEL_CONSUMPTION_SUCCESS,
  FETCH_MONTHLY_FUEL_CONSUMPTION_ERROR,
} from "../actions/action.types";
import createReducer from "./createReducer";
import { getConvertSpec } from "../../common/numbers";
import { get, max } from "lodash";

const initialState = {};

export const fetchMonthlyFuelConsumption = createReducer(initialState, {
  [FETCH_MONTHLY_FUEL_CONSUMPTION_SUCCESS]: (_state, { data }) => {
    const values = get(data, "data", []);
    const convertSpec = getConvertSpec(
      max(values.map((x) => x.value)),
      data.unit
    );

    return {
      data: {
        ...data,
        unit: convertSpec.unit,
        data: values.map((v) => {
          return {
            ...v,
            value: v.value / convertSpec.conversion,
          };
        }),
      },
    };
  },
  [FETCH_MONTHLY_FUEL_CONSUMPTION_ERROR]: (_state, { error }) => ({ error }),
});
