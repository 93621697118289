import React from "react";
import { connect } from "react-redux";
import {
  cancelEdit,
  saveItemConfig,
  setFormValue,
  editItemConfig,
} from "../../../actions/admin/action.itemConfig";
import { FormPopup, FormSelectBox } from "../../../components/Form";
import Loader from "../../../../common/components/Loader/Loader";
import { EditItemForm } from "./EditItemForm";
import { prepVesselItemOptions } from "../../../reducers/admin/reducer.itemConfig";

class EditItemPopup extends React.Component {
  onVesselSelected = (_, vessel) => {
    const { itemTypeId } = this.props.form;
    this.props.editItemConfig({ itemTypeId, vesselId: vessel && vessel.id });
  };

  onVesselItemSelected = (_, vesselItem) => {
    const { itemTypeId, vesselId } = this.props.form;
    this.props.editItemConfig({
      itemTypeId,
      vesselId,
      vesselItemId: vesselItem && vesselItem.id,
    });
  };

  render() {
    const {
      vessels,
      vesselItems,
      itemTypes,
      form,
      cancelEdit: onClose,
      saveItemConfig: onSave,
      setFormValue: onChange,
    } = this.props;

    const itemType = itemTypes.find((it) => it.id === form.itemTypeId);

    return (
      <FormPopup
        title={`Edit ${itemType.name}`}
        visible
        enableSave={
          !form.isLoading && !form.isSaving && form.hasChanges && form.isValid
        }
        onCancel={onClose}
        onHide={onClose}
        onSave={() => onSave(form)}
      >
        <FormSelectBox
          name="vessel"
          label="Vessel"
          placeholder="All vessels"
          options={vessels}
          optionValKey="id"
          optionLabelKey="name"
          selectedVal={form.vesselId}
          selectedValKey="id"
          clearable
          onChange={this.onVesselSelected}
        />
        <FormSelectBox
          name="vesselItem"
          label="Item"
          placeholder="All items"
          options={vesselItems}
          optionValKey="id"
          optionLabelKey="name"
          selectedVal={form.vesselItemId}
          selectedValKey="id"
          clearable
          onChange={this.onVesselItemSelected}
        />
        {form.isLoading || form.isSaving ? (
          <Loader expand />
        ) : (
          <EditItemForm
            vessels={vessels}
            fields={form.fields}
            error={form.error}
            onChange={onChange}
          />
        )}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.admin.itemConfig,
  vesselItems: prepVesselItemOptions(state.admin.itemConfig),
});

const mapDispatchToProps = {
  cancelEdit,
  saveItemConfig,
  setFormValue,
  editItemConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditItemPopup);
