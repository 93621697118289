import React from "react";
import PropTypes from "prop-types";
import styles from "./Gauge.css";
import * as d3 from "d3";
import { getUniqueId } from "../../id";
import { color } from "../../../common/colors";
import { describeArc, getGradientTransform, polarToCartesian } from "../../svg";
import { isNullOrUndefined } from "util";

const colors = {
  valueNegative: color("--bright-red"),
  valuePositive: color("--bright-green"),
  valuePassive: color("--blue-base"),
  valueGray: color("--text-normal"),
  backgroundGray: color("--dark-grey"),
};

export default class GaugeBaseCore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.valueGradientId = getUniqueId();
    this.setupScales(props);
    this.valuePaths = [];
    this.gradients = [];
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.maxValue !== prevProps.maxValue ||
      this.props.minValue !== prevProps.minValue ||
      Boolean(this.props.trend) !== Boolean(prevProps.trend) ||
      Boolean(this.props.negative) !== Boolean(prevProps.negative)
    ) {
      this.setupScales();
    }

    if (this.props.value !== prevProps.value) {
      this.updateValue();
    }
  }

  setupScales() {
    const { minValue, maxValue, trend } = this.props;
    this.zeroValue = trend ? (minValue + maxValue) / 2 : minValue;
    this.scale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([-165, 165]);
    this.zeroAngle = this.scale(this.zeroValue);
  }

  static getValueColor(value, trend = false, negative = false, options = {}) {
    options = {
      defaultColor: colors.valueGray,
      passiveColor: colors.valuePassive,
      zeroValue: 0,
      ...options,
    };
    if (!value || options.zeroValue === value) {
      return options.defaultColor;
    }
    if (trend === true) {
      if (negative === true) {
        if (options.zeroValue < value) {
          return colors.valueNegative;
        } else {
          return colors.valuePositive;
        }
      } else {
        if (options.zeroValue < value) {
          return colors.valuePositive;
        } else {
          return colors.valueNegative;
        }
      }
    } else {
      return options.passiveColor;
    }
  }

  renderValuePaths(zeroAngle, valueAngle) {
    const sortedAngles = [zeroAngle, valueAngle].sort((a, b) => a - b);
    const paths = [];
    const angleDiff = Math.abs(valueAngle - this.zeroAngle);
    const segmentCount = Math.floor(angleDiff / 90) + 1;
    let startAngle = sortedAngles[0];
    for (let i = 0; i < 4; i++) {
      let endAngle = startAngle + 90;
      if (endAngle > sortedAngles[1]) {
        endAngle = sortedAngles[1];
      }
      const angles = [startAngle, endAngle].sort((a, b) => a - b);
      paths.push(
        <path
          stroke={
            i < segmentCount ? `url(#${this.valueGradientId}_${i})` : "none"
          }
          fill="none"
          ref={(el) => (this.valuePaths[i] = el)}
          className={styles.valueCircle}
          strokeLinecap={"round"}
          key={`path_segment_${i}`}
          d={describeArc(100, 100, 90, angles[0], angles[1])}
          vectorEffect="non-scaling-stroke"
        />
      );
      startAngle += 90;
    }
    return paths;
  }

  renderValueGradientColor(
    zeroAngle,
    valueAngle,
    zeroValue,
    value,
    trend,
    negative
  ) {
    const sortedAngles = [zeroAngle, valueAngle].sort((a, b) => a - b);
    const colorScale = d3
      .scaleLinear()
      .domain([zeroAngle, valueAngle])
      .range([
        d3.rgb(colors.valueGray),
        d3.rgb(
          GaugeBaseCore.getValueColor(value, trend, negative, { zeroValue })
        ),
      ]);
    const valueGradients = [];
    let startAngle = sortedAngles[0];
    for (let i = 0; i < 4; i++) {
      let endAngle = startAngle + 90;
      if (endAngle > sortedAngles[1]) {
        endAngle = sortedAngles[1];
      }
      valueGradients.push(
        <linearGradient
          key={`valueGradient_${i}`}
          id={this.valueGradientId + "_" + i}
          ref={(el) => (this.gradients[i] = el)}
          gradientUnits="objectBoundingBox"
          gradientTransform={getGradientTransform(
            Math.min(startAngle, endAngle),
            Math.max(startAngle, endAngle)
          )}
        >
          <stop offset="0%" stopColor={colorScale(startAngle)} />
          <stop offset="100%" stopColor={colorScale(endAngle)} />
        </linearGradient>
      );
      startAngle += 90;
    }
    return valueGradients;
  }

  updateValue() {
    const { minValue, maxValue, value, trend, negative } = this.props;
    if (
      !this.valuePaths ||
      !this.valueCircleInner ||
      !this.valueCircleOuter ||
      isNaN(value) ||
      isNullOrUndefined(value)
    ) {
      return;
    }
    const gaugeValue = value || this.zeroValue;
    const valueAngle = Math.max(
      Math.min(this.scale(gaugeValue), this.scale(maxValue)),
      this.scale(minValue)
    );
    const angleDiff = Math.abs(valueAngle - this.zeroAngle);
    const segmentCount = Math.floor(angleDiff / 90) + 1;
    const sortedAngles = [this.zeroAngle, valueAngle].sort((a, b) => a - b);
    const colorScale = d3
      .scaleLinear()
      .domain([this.zeroAngle, valueAngle])
      .range([
        d3.rgb(colors.valueGray),
        d3.rgb(
          GaugeBaseCore.getValueColor(gaugeValue, trend, negative, {
            zeroValue: this.zeroValue,
          })
        ),
      ]);

    let startAngle = sortedAngles[0];
    for (let i = 0; i < 4; i++) {
      const valuePath = this.valuePaths[i];
      if (i < segmentCount) {
        const gradient = this.gradients[i];
        const stop1 = gradient.firstChild;
        const stop2 = gradient.lastChild;
        let endAngle = startAngle + 90;
        if (endAngle > sortedAngles[1]) {
          endAngle = sortedAngles[1];
        }
        const angles = [startAngle, endAngle].sort((a, b) => a - b);
        gradient.setAttribute(
          "gradientTransform",
          getGradientTransform(angles[0], angles[1])
        );
        stop1.setAttribute("stop-color", colorScale(startAngle));
        stop2.setAttribute("stop-color", colorScale(endAngle));
        valuePath.setAttribute(
          "d",
          describeArc(100, 100, 90, angles[0], angles[1])
        );
        valuePath.setAttribute("stroke", `url(#${this.valueGradientId}_${i})`);
      } else {
        valuePath.setAttribute("stroke", "none");
      }
      startAngle += 90;
    }

    const valuePoint = polarToCartesian(100, 100, 90, valueAngle);
    const valueColor = GaugeBaseCore.getValueColor(
      gaugeValue,
      trend,
      negative,
      {
        zeroValue: this.zeroValue,
      }
    );
    this.valueCircleInner.setAttribute("cx", valuePoint.x);
    this.valueCircleInner.setAttribute("cy", valuePoint.y);
    this.valueCircleInner.setAttribute("fill", valueColor);
    this.valueCircleOuter.setAttribute("cx", valuePoint.x);
    this.valueCircleOuter.setAttribute("cy", valuePoint.y);
    this.valueCircleOuter.setAttribute("fill", valueColor);
  }

  render() {
    const { minValue, maxValue, value, trend, negative, width, height } =
      this.props;
    const gaugeValue = value || this.zeroValue;
    const hasValue = Boolean(value || minValue || maxValue);

    const valueAngle = Math.max(
      Math.min(this.scale(gaugeValue), this.scale(maxValue)),
      this.scale(minValue)
    );

    const valuePoint = polarToCartesian(100, 100, 90, valueAngle);

    return (
      <svg height={height} width={width} viewBox="0 0 200 200">
        <defs>
          <linearGradient id="backgroundGradient">
            <stop offset="5%" stopColor={"rgba(43, 49, 63, 0.9)"} />
            <stop offset="55%" stopColor={"rgba(43, 49, 63, 0)"} />
          </linearGradient>
          {this.renderValueGradientColor(
            this.zeroAngle,
            valueAngle,
            this.zeroValue,
            gaugeValue,
            trend,
            negative
          )}
        </defs>
        <circle
          cx={100}
          cy={100}
          r={90}
          fill="url(#backgroundGradient)"
          transform="rotate(90 100 100)"
        />
        <path
          d={describeArc(100, 100, 90, -165, 165)}
          className={styles.baseCircle}
          vectorEffect="non-scaling-stroke"
        />
        {hasValue && [
          <g key="valuePaths">
            {this.renderValuePaths(this.zeroAngle, valueAngle)}
          </g>,
          <circle
            key="valueCircleOuter"
            cx={valuePoint.x}
            cy={valuePoint.y}
            r={1}
            ref={(el) => (this.valueCircleOuter = el)}
            strokeWidth={6.5}
            stroke={GaugeBaseCore.getValueColor(gaugeValue, trend, negative, {
              zeroValue: this.zeroValue,
            })}
            vectorEffect="non-scaling-stroke"
          />,
          <circle
            key="valueCircleInner"
            cx={valuePoint.x}
            cy={valuePoint.y}
            ref={(el) => (this.valueCircleInner = el)}
            r={3.5}
            fill={GaugeBaseCore.getValueColor(gaugeValue, trend, negative, {
              zeroValue: this.zeroValue,
            })}
          />,
        ]}
      </svg>
    );
  }
}

GaugeBaseCore.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  trend: PropTypes.bool,
  negative: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
