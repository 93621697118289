import React from "react";
import { connect } from "react-redux";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import styles from "./AnalysisView.css";
import Header from "../../components/Header/Header";
import AnalysisFilter from "../../components/AnalysisFilter/AnalysisFilter";
import AnalysisPlot from "./AnalysisPlot/AnalysisPlot";
import { createSelector } from "reselect";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
  getGroupFilter,
  getVesselFilter,
} from "../../selectors/filters";
import { VIEWS } from "../../config";
import {
  setAnalysisDate,
  setAnalysisVessel,
} from "../../actions/action.analysis";
import { isEqual } from "lodash";

class AnalysisView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { filters } = this.props;

    if (!filters.isValid) {
      this.adjustFilters();
    } else {
      this.props.setAnalysisDate(filters.dateFilter.value);
    }
  }

  componentDidUpdate(oldProps) {
    const { filters } = this.props;
    const oldFilters = oldProps.filters;

    if (!filters.isValid) {
      this.adjustFilters();
    } else if (!isEqual(filters.vesselFilter, oldFilters.vesselFilter)) {
      this.props.setAnalysisVessel(
        filters.vesselFilter.value,
        filters.dateFilter.value
      );
    } else if (!isEqual(filters.dateFilter, oldFilters.dateFilter)) {
      this.props.setAnalysisDate(filters.dateFilter.value);
    }
  }

  adjustFilters() {
    this.props.queryContext.navigate(
      VIEWS.analysis.url,
      convertFiltersToQueryParams(this.props.filters),
      true
    );
  }

  render() {
    const {
      filters: { vesselFilter, dateFilter, isValid },
      queryContext,
    } = this.props;
    return (
      <div className={styles.analysisViewContainer}>
        <Header title="Analysis" contentDistribution="start">
          <div className={styles.analysisTypeContainer}>
            {isValid && <VesselSelector />}
          </div>
        </Header>
        <div className={styles.analysisContentContainer}>
          <div className={styles.analysisFilterContainer}>
            {isValid && (
              <AnalysisFilter
                setAnalysisDate={queryContext.setDate}
                date={dateFilter.value}
                activeVesselId={vesselFilter.value}
              />
            )}
          </div>
          <div className={styles.analysisChartContainer}>
            {isValid && <AnalysisPlot />}
          </div>
        </div>
      </div>
    );
  }
}

const getAnalysisViewFilters = createSelector(
  [getVesselFilter, getGroupFilter, getDateRangeFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid:
        vesselFilter.isValid && groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const filters = getAnalysisViewFilters(state, props);
  return {
    analysisType: state.analysis.analysisType,
    filters,
    queryContext: props.queryContext,
  };
};

const mapDispatchToProps = {
  setAnalysisDate,
  setAnalysisVessel,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisView);
