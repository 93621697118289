// Calculates the margin of top an bottom of Y axis by given %
// If any of the margin crosses 0 it stays at 0
export default function setYDomainMargins(
  min,
  max,
  marginPercent = 5,
  setMinMargin = true,
  setMaxMargin = true
) {
  const yMin = +min;
  const yMax = +max;
  // Franctional points depends on how large is the margin
  const getFract = (f) => {
    if (f > 1) {
      return 0;
    }
    return f > 0.1 ? 1 : 2;
  };
  const toFixedSign = (v) => +v.toFixed(getFract(v));
  const yMargin = toFixedSign(Math.abs((yMax - yMin) * (marginPercent / 100)));

  return isFinite(yMargin)
    ? getMargins(yMin, yMax, yMargin, setMinMargin, setMaxMargin)
    : { yMin, yMax };
}

function getMargins(yMin, yMax, yMargin, setMinMargin, setMaxMargin) {
  if (setMinMargin && yMin !== 0) {
    const newYMin = yMin - yMargin;
    yMin = yMin > 0 && newYMin - yMargin < 0 ? 0 : newYMin;
  }
  if (setMaxMargin && yMax !== 0) {
    const newYMax = yMax + yMargin;
    yMax = yMax < 0 && newYMax + yMargin > 0 ? 0 : newYMax;
  }
  return { yMin, yMax };
}
