import React from "react";
import PropTypes from "prop-types";
import styles from "./VesselTrackListElement.css";
import { PROFILES } from "../../../../common/config";
import { color } from "../../../../common/colors";
import classNames from "../../../../common/classNames";
import { connect } from "react-redux";
import { formatDate, formatTime } from "../../../common/dates";
import { getTimeOffsetFormat } from "../../../reducers/datePicker";

class VesselTrackListElement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
  }

  handleOnMouseEnter() {
    const { trackElement, onMouseEnter } = this.props;
    if (onMouseEnter) {
      onMouseEnter(trackElement);
    }
  }

  handleOnMouseLeave() {
    const { trackElement, onMouseLeave } = this.props;
    if (onMouseLeave) {
      onMouseLeave(trackElement);
    }
  }

  handleClick() {
    const { trackElement, onClick } = this.props;
    if (onClick) {
      onClick(trackElement);
    }
  }

  render() {
    const {
      trackElement,
      style,
      first,
      hoveredItemIndex,
      showDate,
      timeOffset,
    } = this.props;
    if (!trackElement) {
      return <div />;
    }
    const firstElement = trackElement.track[0] || {};

    const containerStyle = {
      ...style,
    };
    if (!containerStyle.height) {
      containerStyle.height = VesselTrackListElement.calcHeight(trackElement);
    }

    const opModeColor =
      (PROFILES[trackElement.operationLegend] &&
        PROFILES[trackElement.operationLegend].color) ||
      color("--grey-400");

    const markerStyle = {
      borderColor: opModeColor,
      borderWidth: first ? "4px" : "2px",
    };

    const lineStyle = {
      backgroundColor: opModeColor,
    };

    return (
      <div className={styles.outerContainer} style={containerStyle}>
        {showDate && (
          <div className={styles.dateContainer}>
            <span>
              {formatDate(firstElement.positionDate)}{" "}
              {getTimeOffsetFormat(timeOffset)}
            </span>
          </div>
        )}
        <div
          className={classNames(
            styles.elementContainer,
            hoveredItemIndex === trackElement.index && styles.activeElement
          )}
          onMouseEnter={this.handleOnMouseEnter}
          onMouseLeave={this.handleOnMouseLeave}
          onClick={this.handleClick}
        >
          <div className={styles.timeContainer}>
            <span className={styles.timeLabel}>
              {formatTime(firstElement.positionDate)}
            </span>
          </div>
          <div className={styles.lineContainer}>
            <div className={styles.marker} style={markerStyle} />
            <div className={styles.line} style={lineStyle} />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.operationContainer}>
              <span className={styles.operationName}>
                {trackElement.operationName}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VesselTrackListElement.calcHeight = (element, showDate) => {
  let height;
  if (!element || !element.track) {
    height = 40;
  } else {
    height = Math.min(Math.max(element.track.length * 10, 40), 100);
  }
  if (showDate) {
    height += 40;
  }
  return height;
};

VesselTrackListElement.propTypes = {
  trackElement: PropTypes.object.isRequired,
  style: PropTypes.object,
  first: PropTypes.bool,
  showDate: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    hoveredItemIndex: state.mapView.vesselTrack.hoveredItemIndex,
  };
};

export default connect(mapStateToProps, null)(VesselTrackListElement);
