import t from "prop-types";
import React, { Component } from "react";
import Loader from "../../../common/components/Loader";
import styles from "./Image.css";

export default class Img extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      opacity: 0,
    };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  componentDidMount() {
    if (this.props.src) {
      this.loadImage();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.loadImage();
    }
  }

  loadImage() {
    const image = new Image();
    image.onload = () => this.handleImageLoaded();
    image.onerror = () => this.handleImageError();
    image.src = this.props.src;
    this.setState({
      loading: true,
      opacity: 0,
    });
  }

  handleImageLoaded() {
    this.setState({
      loading: false,
      opacity: 1,
    });
  }

  handleImageError() {
    this.setState({
      loading: false,
    });
  }

  render() {
    if (!this.props.src) {
      return <div />;
    }

    const imageStyle = {
      backgroundImage: `url(${this.props.src})`,
      opacity: this.state.opacity,
    };

    return (
      <div className={styles.container}>
        {this.state.loading && (
          <div className={styles.loader}>
            <Loader text={this.props.loadingText} />
          </div>
        )}
        <div className={styles.image} style={imageStyle} />
      </div>
    );
  }
}

Image.propTypes = {
  src: t.string,
  altText: t.string,
  titleText: t.string,
  loadingText: t.string.isRequired,
};
