import React from "react";
import { connect } from "react-redux";
import { get, matchesProperty } from "lodash";
import {
  fetchVesselGroup,
  fetchCompanies,
  fetchVessels,
  closeVesselGroupPopup,
  saveVesselGroup,
  setVesselGroupValue,
} from "../../../actions/admin";
import { deleteVesselGroupPopupOpen } from "../../../actions/admin/action.vesselGroups";
import {
  FormTextBox,
  FormSelectBox,
  FormPopup,
} from "../../../components/Form";

class VesselGroupPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onPopupFormHide = this.onPopupFormHide.bind(this);
    this.onPopupFormSave = this.onPopupFormSave.bind(this);
    this.onPopupFormCancel = this.onPopupFormCancel.bind(this);
    this.onPopupFormInputChange = this.onPopupFormInputChange.bind(this);
    this.onPopupFormSelectChange = this.onPopupFormSelectChange.bind(this);
    this.onPopupMultiSelectChange = this.onPopupMultiSelectChange.bind(this);
  }

  onPopupFormHide() {
    this.props.closeVesselGroupPopup();
  }

  onPopupFormSave() {
    this.props.saveVesselGroup();
  }

  onPopupFormCancel() {
    this.props.closeVesselGroupPopup();
  }

  onPopupFormInputChange(name, value, valid, message) {
    this.props.setVesselGroupValue(name, value, valid, message);
  }

  onPopupFormSelectChange(name, selected, valid, message) {
    this.props.setVesselGroupValue(name, selected.id, valid, message);
    this.props.fetchVessels(selected.id);
  }

  onPopupMultiSelectChange(name, selected, valid, message) {
    this.props.setVesselGroupValue(
      name,
      (selected || []).map((x) => x.id),
      valid,
      message
    );
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.props.fetchVessels(this.props.selectedCompanyId);
  }

  renderForm() {
    const { companies, vessels, form } = this.props;

    const selectedCompany = companies.find(
      matchesProperty("id", get(form, "fields.companyId.value"))
    );

    const selectedVesselIds = get(form, "fields.vessels.value", []);
    const selectedVessels = vessels.filter((x) =>
      selectedVesselIds.includes(x.id)
    );

    return (
      <div>
        <FormTextBox
          name="name"
          label="Name"
          value={get(form, "fields.name.value")}
          onChange={this.onPopupFormInputChange}
          required
        />

        <FormSelectBox
          name="companyId"
          label="Company"
          selected={selectedCompany}
          options={companies}
          optionValKey="id"
          optionLabelKey="name"
          onChange={this.onPopupFormSelectChange}
          required
        />

        <FormSelectBox
          name="vessels"
          label="Vessels"
          selected={selectedVessels}
          onChange={this.onPopupMultiSelectChange}
          optionValKey="id"
          optionLabelKey="name"
          options={vessels}
          multi
        />
      </div>
    );
  }

  render() {
    const { form, showPopup } = this.props;
    const { isSaving } = form;
    const enableSave =
      (!form.isUpdate || form.hasChanges) && form.isValid && !isSaving;
    const additionalButtons = [
      form.isUpdate
        ? {
            label: "Delete",
            visible: true,
            action: () => this.props.deleteVesselGroupPopupOpen(),
          }
        : {},
    ];
    return (
      <FormPopup
        title={form.isUpdate ? "Edit Vessel Group" : "Create new Vessel Group"}
        visible={showPopup}
        isSaving={isSaving}
        enableSave={enableSave}
        onHide={this.onPopupFormHide}
        onSave={this.onPopupFormSave}
        onCancel={this.onPopupFormCancel}
        testId={"vesselGroupPopup"}
        additionalButtons={additionalButtons}
      >
        {this.renderForm()}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  vessels: state.admin.vesselGroups.vessels,
  companies: state.admin.vesselGroups.companies,
  showPopup: state.admin.vesselGroups.showPopup,
  form: state.admin.vesselGroups.form,
  selectedCompanyId: state.admin.selectedCompanyId,
});

const mapDispatchToProps = {
  fetchVesselGroup,
  fetchCompanies,
  fetchVessels,
  closeVesselGroupPopup,
  saveVesselGroup,
  setVesselGroupValue,
  deleteVesselGroupPopupOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(VesselGroupPopup);
