import { FETCH_VESSEL_ITEM_DETAILS } from "./action.types";
import { VESSEL_ITEM_DETAILS } from "../api";
import fetch from "./action.fetch";

export const fetchVesselItemDetails = (
  vesselItemId,
  dateRange,
  timeOffset,
  states
) =>
  fetch({
    url: VESSEL_ITEM_DETAILS.get(vesselItemId, dateRange, timeOffset, states),
    actionType: FETCH_VESSEL_ITEM_DETAILS,
    actionData: { vesselItemId },
  });
