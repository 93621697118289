import React from "react";
import { formatNumber } from "../../../../common/numbers";
import { secondsToTimeString } from "../../../common/dates";
import DataGrid from "../../../components/DataGrid/DataGrid";
import styles from "./TelemetryTable.css";
import PropTypes from "prop-types";
import Loader from "../../../../common/components/Loader";

const getCssClass = (value) => {
  return value ? styles.telemetryValue : styles.noTelemetry;
};

const TelemetryTable = (props) => {
  const gridConfig = {
    sortBy: props.sortBy,
    sortDirection: props.sortDirection,
    onSort: props.onSort,
    columns: [
      {
        header: "Page name",
        field: "name",
        align: "left",
        flexGrow: 2,
      },
      {
        header: "Path",
        field: "path",
        align: "left",
        flexGrow: 1,
      },
      {
        header: "Page views",
        field: "pageviews",
        align: "right",
        flexGrow: 1,
        template: ({ pageviews }) => {
          return (
            <span className={getCssClass(pageviews)}>
              {formatNumber(pageviews || 0)}
            </span>
          );
        },
      },
      {
        header: "Sessions",
        align: "right",
        field: "sessions",
        flexGrow: 1,
        template: ({ sessions }) => {
          return (
            <span className={getCssClass(sessions)}>
              {formatNumber(sessions || 0)}
            </span>
          );
        },
      },
      {
        header: "Average duration",
        align: "right",
        field: "averageDuration",
        flexGrow: 1,
        template: ({ averageDuration }) => {
          return (
            <span className={getCssClass(averageDuration)}>
              {secondsToTimeString(averageDuration || 0)}
            </span>
          );
        },
      },
    ],
  };

  if (props.isLoading || props.error) {
    return <Loader isLoading={props.isLoading} error={props.error} />;
  }

  return <DataGrid data={props.data} {...gridConfig} />;
};

TelemetryTable.defaultProps = {
  sortBy: "name",
  sortDirection: "asc",
};

TelemetryTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      pageviews: PropTypes.number,
      sessions: PropTypes.number,
      averageDuration: PropTypes.number,
    })
  ),
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TelemetryTable;
