import React, { Component } from "react";
import { connect } from "react-redux";
import AutoSizer from "../../common/components/AutoSizer/AutoSizer";
import { fetchMonthlyFuelConsumption } from "../actions/action.fetchMonthlyFuelConsumption";
import Loader from "../../common/components/Loader/Loader";
import FuelConsumption from "../components/FuelConsumption/FuelConsumption";

class MonthlyFuelUsageContainer extends Component {
  constructor(props) {
    super(props);
    this.renderComponent = this.renderComponent.bind(this);
  }
  componentDidMount() {
    this.props.fetchFuelUsage(this.props.config.params);
  }

  componentDidUpdate(prevProps) {
    if (this.props.config !== prevProps.config) {
      this.props.fetchFuelUsage(this.props.config.params);
    }
  }

  renderComponent({ width, height }) {
    return (
      <FuelConsumption
        data={this.props.fuelUsage.data}
        size={{
          width,
          height,
          margin: {
            top: 100,
            right: 60,
            bottom: 60,
            left: 90,
          },
        }}
      />
    );
  }

  render() {
    const { data, error } = this.props.fuelUsage;

    if (!data) {
      return (
        <Loader
          text="Loading fuel consumption data"
          error={error}
          expand={true}
        />
      );
    }

    return <AutoSizer>{this.renderComponent}</AutoSizer>;
  }
}

export default connect(
  (state) => ({ fuelUsage: state.fetchMonthlyFuelConsumption }),
  { fetchFuelUsage: fetchMonthlyFuelConsumption }
)(MonthlyFuelUsageContainer);
