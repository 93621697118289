import { takeLeading, call, getContext, put, fork } from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";

const actions = vesselConfig.onshoreFrontendConfig;
const api = vesselConfigApi.onshoreFrontendConfig;

export function* initOnshoreFrontendConfig({ vesselId }) {
  yield call(fetchData, vesselId);
  yield fork(saveWatcher, vesselId);
}

function* fetchData(vesselId) {
  const http = yield getContext("http");
  try {
    yield put({
      type: actions.FETCH,
    });
    const res = yield call(http.get, api.get(vesselId));
    yield put({
      type: actions.FETCH_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
    });
  }
}

function* saveWatcher(vesselId) {
  yield takeLeading(actions.SAVE, saveConfig, vesselId);
}

function* saveConfig(vesselId, { fields }) {
  try {
    const http = yield getContext("http");
    const payload = {
      visibleMenuItems: fields.filter((x) => x.value).map((x) => x.name),
    };
    const res = yield call(http.post, api.post(vesselId), payload);
    yield put({ type: actions.SAVE_SUCCESS, data: res.data });
  } catch (err) {
    yield put({ type: actions.SAVE_ERROR, error: err });
  }
}
