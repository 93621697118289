import React, { Component } from "react";
import AutoSizer from "../../../../../common/components/AutoSizer/AutoSizer";
import FuelConsumption from "../../../../components/FuelConsumption";
import styles from "./MonthlyFuelUsage.css";

class MonthlyFuelUsage extends Component {
  constructor(props) {
    super(props);

    this.renderComponent = this.renderComponent.bind(this);
  }

  renderComponent({ width, height }) {
    return (
      <FuelConsumption
        data={this.props.fuelConsumptionData}
        size={{
          width,
          height,
          margin: {
            top: 100,
            right: 60,
            bottom: 60,
            left: 90,
          },
        }}
      />
    );
  }

  render() {
    return (
      <div className={styles.monthlyFuelUsageItem}>
        <AutoSizer>{this.renderComponent}</AutoSizer>
      </div>
    );
  }
}

export default MonthlyFuelUsage;
