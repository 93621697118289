import { isSystemAdmin } from "../reducers/reducer.user";
import { get } from "lodash";
import { getViewsByPath } from "../common/views";
import {
  ADMIN_ROLES,
  KONGSBERG_DIGITAL_ID,
  KONGSBERG_MARITIME_ID,
} from "../config";

export function userHasAccess(vessels, vesselId, pathname, user) {
  const vessel = get(vessels, vesselId);
  const menu = get(getViewsByPath(), [pathname, "key"]);

  if (!vessel || !menu || pathname.includes("/admin")) {
    return true;
  }

  const vesselMenus = get(vessel, "menuItems", []);

  return (
    isSystemAdmin(user) ||
    vesselMenus.filter((m) => m.name === menu && m.visible).length > 0
  );
}

export const isKongsbergAdmin = (userRoles, companyId) => {
  return (
    userRoles.some((ur) => ADMIN_ROLES.includes(ur)) &&
    (companyId === KONGSBERG_MARITIME_ID || companyId === KONGSBERG_DIGITAL_ID)
  );
};
