import {
  CreateSigninRequestArgs,
  SigninSilentArgs,
  User,
  UserManager,
  UserManagerSettings,
} from "oidc-client-ts";
import IAuthService from "./IAuthService";
import State from "./State";

export default class AuthService implements IAuthService {
  private userManager: UserManager;
  private readonly userManagerSettings: UserManagerSettings;
  private readonly keyOfStoredTenantId: string =
    "VesselPerformance_AuthService_Authority";
  private readonly keyOfPrevLogin: string =
    "VesselPerformance_PreviouslyLoggedInUser";
  private readonly updateStoredTokens: (accessToken: string) => void;

  constructor(
    authority: string,
    clientId: string,
    newTokenCallbackFunctions: ((accessToken: string) => void)[]
  ) {
    const origin: string =
      window.origin ||
      window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "");
    const storedAuthority = localStorage.getItem(this.keyOfStoredTenantId);

    this.userManagerSettings = {
      authority: storedAuthority ?? authority,
      client_id: clientId,
      redirect_uri: `${origin}/auth`,
      post_logout_redirect_uri: `${origin}/loggedOut`,
      silent_redirect_uri: `${origin}/signin-silent`,
      automaticSilentRenew: true,
      accessTokenExpiringNotificationTimeInSeconds: 300,
      revokeTokensOnSignout: false,
      scope: "api://d9ddc222-79ae-4739-97ba-e1d8da12920a/access_as_user",
    };
    this.userManager = new UserManager(this.userManagerSettings);

    this.updateStoredTokens = (accessToken) => {
      newTokenCallbackFunctions.forEach((fn) => fn(accessToken));
      console.log("Access token was updated");
    };

    this.userManager.events.addUserLoaded(() => {
      this.userManager
        .getUser()
        .then((user) => {
          const accessToken = user?.access_token;
          if (accessToken) {
            this.updateStoredTokens(accessToken);
          }
        })
        .catch((e) =>
          console.error(
            "Could not get oidc user from UserManager. Auth might not work as expected.",
            e
          )
        );
    });
    this.userManager.events.addSilentRenewError(() =>
      console.warn("Unable to complete silent renew of token")
    );
  }

  reinitialize(tenantId: string): IAuthService {
    const authority = "https://login.microsoftonline.com/" + tenantId;
    localStorage.setItem(this.keyOfStoredTenantId, authority);
    this.userManagerSettings.authority = authority;
    this.userManager = new UserManager(this.userManagerSettings);
    return this;
  }

  async getUser(): Promise<User | null> {
    let user = await this.userManager.getUser();
    if (!user || user.expired) {
      user = await this.silentLogin();
    }
    if (user?.access_token) {
      this.updateStoredTokens(user.access_token);
    }

    return user;
  }

  login(login_hint: string | undefined = undefined) {
    if (login_hint) {
      localStorage.setItem(this.keyOfPrevLogin, login_hint);
    }
    const args: CreateSigninRequestArgs = {
      state: this.getState(),
      login_hint: login_hint,
      extraQueryParams: {
        hsu: 1,
      },
    };
    return this.userManager.signinRedirect(args);
  }

  loginCallback() {
    return this.userManager.signinRedirectCallback();
  }

  logout() {
    localStorage.removeItem(this.keyOfPrevLogin);
    return this.userManager.signoutRedirect();
  }

  silentLoginCallback() {
    return this.userManager.signinSilentCallback();
  }

  async silentLogin() {
    const prevLogin = localStorage.getItem(this.keyOfPrevLogin) ?? undefined;
    const args: SigninSilentArgs = {
      state: this.getState(),
      login_hint: prevLogin,
    };
    return await this.userManager.signinSilent(args);
  }

  private getState(): State {
    return {
      tenantId: this.userManagerSettings.authority,
    };
  }
}
