import React from "react";
import styles from "./Emissions.css";
import DataGridView, { DataGridViewProps } from "../DataGridView/DataGridView";
import { IEmissionsByOperationsState, IEmissionsData } from "../commonTypes";
import Loader from "../../../../common/components/Loader";
import AutoSizer from "../../../../common/components/AutoSizer/AutoSizer";
import { DataItem } from "../DataGridView/Types";
import { generateUUID } from "../../../../common/id";

interface IProps {
  data: IEmissionsByOperationsState;
}

function Emissions({ data }: IProps): JSX.Element {
  return data.isLoading ? (
    <Loader text="Loading Emissions" />
  ) : (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <div
          className={styles.emissionsContainer}
          style={{ height, width, minHeight: "320px" }}
        >
          <DataGridView {...makeGridData(data.data)} />
        </div>
      )}
    </AutoSizer>
  );
}

const makeGridData = (data: IEmissionsData[]): DataGridViewProps => {
  function getGridData(data: IEmissionsData[]): DataItem[] {
    if (data === undefined) {
      return [];
    }

    return data.map((item) => {
      const gridItem: DataItem = { id: generateUUID() };

      Object.entries(item).forEach(([key, value]) => {
        gridItem[key] = value;
      });

      return gridItem;
    });
  }

  return {
    ...emissionsInitialGridData,
    data: getGridData(data),
  };
};

const emissionsInitialGridData: DataGridViewProps = {
  columns: [
    {
      field: "operationMode",
      unit: "",
      total: false,
    },
    {
      name: "CO2",
      field: "cO2",
      unit: "t",
      total: true,
      toFixed: 2,
    },
    {
      name: "NOx",
      field: "nOx",
      unit: "t",
      total: true,
      toFixed: 2,
    },
    {
      name: "SOx",
      field: "sOx",
      unit: "t",
      total: true,
      toFixed: 2,
    },
  ],
  data: [],
};

export default Emissions;
