import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "../../../../common/classNames";
import ContextBrowser from "../ContextBrowser";
import styles from "./VesselStatePicker.css";
import pickerStyles from "../PickerStyles.css";
import Loader from "../../../../common/components/Loader";
import { List } from "react-virtualized";
import AutoSizer from "../../../../common/components/AutoSizer/AutoSizer";
import {
  getVesselStateMonthRange,
  optionData,
} from "../../../reducers/datePicker/vesselStatePicker";
import { get } from "lodash";
import moment from "moment";

function fetch(props, oldProps = {}) {
  const value = props.options.month;
  const dateRange = getVesselStateMonthRange(value);
  if (props.options !== oldProps.options && props.options.shouldFetch) {
    props.fetch(dateRange);
  }
}

const CheckIcon = () => (
  <span className={styles.selectedIcon}>
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      enableBackground="new 0 0 14 12"
      xmlSpace="preserve"
    >
      <path d="M4.9,8.7l7.9-7.9l1.1,1.1l-9.2,9.2L0.1,6.5l1.3-1.3L4.9,8.7z" />
    </svg>
  </span>
);

export class VesselStatePicker extends Component {
  constructor(props) {
    super(props);
    this.renderOption = this.renderOption.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  componentDidMount() {
    fetch(this.props);
  }

  componentDidUpdate(prevProps) {
    fetch(this.props, prevProps);
  }

  renderOption({ index, key, style }) {
    const { options, select } = this.props;
    const option = options.items[index];
    const data = optionData(option, options);
    return (
      <div
        key={key}
        style={style}
        className={classNames(
          styles.vesselState,
          data.isActive && styles.isActive
        )}
        onClick={() => select(option)}
      >
        <span className={styles.dateTime}>{data.date}</span>
        <span
          className={styles.dateTime}
        >{`${data.time} ${data.timeOffsetFormat}`}</span>
        <span
          className={styles.titleContainer}
          title={`${data.name} (${data.duration})`}
        >
          <span className={styles.title}>{data.name}</span>
          <span className={styles.description}>{data.duration}</span>
        </span>
        <span>{data.isActive && <CheckIcon />}</span>
      </div>
    );
  }

  renderOptions() {
    const { options } = this.props;

    if (options.isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      );
    }

    return (
      <AutoSizer>
        {({ width, height }) => (
          <List
            // The List component is a PureComponent, so we need to pass `items`
            // and `selectedValue`, which are the properties that vary, in order
            // for the component to update.
            items={options.items}
            selectedValue={options.selectedValue}
            width={width}
            height={height}
            rowCount={options.items.length}
            rowRenderer={this.renderOption}
            rowHeight={50}
            overscanRowCount={50}
            autoHeight={false}
          />
        )}
      </AutoSizer>
    );
  }

  onChangeDate(e) {
    // We compensate the wrong time zone coming from the react-datepicker library.
    const date = new Date(moment(e).format("MM/DD/YYYY"));
    const dateInUtc = new Date(date.getTime() - e.getTimezoneOffset() * 60000);

    const dateValues = {
      type: this.props.type,
      options: {
        ...this.props.options,
        month: dateInUtc,
      },
    };
    this.props.changeDate(dateValues);
  }

  render() {
    const { options, getNextOptions, getPrevOptions, apply, limits } =
      this.props;
    const applyDisabled =
      options.items?.length === 0 ||
      get(options, "selectedValue.id") === undefined;
    return (
      <div>
        <ContextBrowser
          mode={"unified"}
          title={options.title}
          next={options.hasNext}
          prev={options.hasPrev}
          onNext={getNextOptions}
          onPrev={getPrevOptions}
          onClickTitle={this.onChangeDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          selectedValue={options.month}
          isTitleDatePicker
          limits={limits}
        />
        <div style={{ height: "500px" }}>{this.renderOptions()}</div>
        <button
          disabled={applyDisabled}
          className={pickerStyles.applyBtn}
          onClick={apply}
        >
          Apply
        </button>
      </div>
    );
  }
}

VesselStatePicker.propTypes = {
  options: PropTypes.shape({
    selectedValue: PropTypes.any,
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    shouldFetch: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
  getNextOptions: PropTypes.func.isRequired,
  getPrevOptions: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  limits: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
};
