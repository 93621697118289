import React from "react";
import styles from "./FuelConsumptionRow.css";
import {
  getLegendColor,
  getLegendTextColor,
  getLegendName,
} from "../OperationProfileHelper";
import { FuelConsumptionTrend } from "./FuelConsumptionTrend";
import OperationsGroup from "../../OperationsGroup";
import { IFuelConsumptionPerOperationGroup } from "../commonTypes";
import { PerformanceIndicator } from "./PerformanceIndicator";

interface Props {
  fuelConsumption: IFuelConsumptionPerOperationGroup;
}

export function FuelConsumptionRow({ fuelConsumption }: Props): JSX.Element {
  const {
    operationsGroup,
    operationsGroupName,
    operationsValue,
    trendData,
    operations,
  } = fuelConsumption;
  const { color, text, textColor } =
    getOperationGroupAttributes(operationsGroup);

  return (
    <>
      <div className={styles.blockContainer}>
        <div className={styles.operationsGroupContainer}>
          <OperationsGroup
            text={operationsGroup}
            name={operationsGroupName}
            color={color ?? ""}
            textColor={textColor}
            operations={operations}
          />
        </div>
        <div className={styles.fuelConsumptionValueContainer}>
          <div className={styles.fuelConsumptionTitle}>{text}</div>
          <div className={styles.fuelConsumptionValue}>
            <div>
              <span>{operationsValue.value}</span>
              <span>{operationsValue.unit}</span>
            </div>
            <div>
              <span>
                {operations.map(
                  (x, i) => `${x}${i === operations.length - 1 ? "" : ", "}`
                )}
              </span>
            </div>
          </div>
        </div>
        <PerformanceIndicator performance={operationsValue.performance} />
      </div>
      <div className={styles.blockContainer} style={{ padding: "1rem" }}>
        <FuelConsumptionTrend data={trendData} />
      </div>
    </>
  );
}

const getOperationGroupAttributes = (operationsGroup: string) => ({
  color: getLegendColor(operationsGroup),
  text: getLegendName(operationsGroup) + " mode fuel cons.",
  textColor: getLegendTextColor(operationsGroup),
});

export default FuelConsumptionRow;
