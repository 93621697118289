import { extendBounds } from "../../common/map";
import createReducer from "./createReducer";
import {
  FETCH_MACHINERY_STATUS,
  FETCH_MACHINERY_STATUS_ERROR,
  FETCH_MACHINERY_STATUS_SUCCESS,
  FETCH_VESSEL_PERIOD_POSITIONS,
  FETCH_VESSEL_PERIOD_POSITIONS_SUCCESS,
  FETCH_VESSEL_TOTAL_POSITIONS,
  FETCH_VESSEL_TOTAL_POSITIONS_SUCCESS,
  SET_MACHINERY_STATUS_MAP_PROPERTIES,
} from "../actions/action.types";
import { filter, sortBy } from "lodash";
import { fitBounds } from "google-map-react";
import { ITEM_TYPE_CATEGORIES } from "../config";

export default createReducer(
  {
    totalPositions: {
      level: 2,
    },
    periodPositions: {
      level: 2,
    },
    engines: [],
    propulsion: [],
    defaultCenter: { lat: 57.448844, lng: -7.675874 },
    center: { lat: 57.448844, lng: -7.675874 },
    defaultZoom: 3,
    zoom: 3,
  },
  {
    [FETCH_VESSEL_TOTAL_POSITIONS]: (state) => {
      return {
        ...state,
        totalPositions: {
          ...state.totalPositions,
          isLoading: true,
          data: [],
        },
      };
    },
    [FETCH_VESSEL_TOTAL_POSITIONS_SUCCESS]: (
      state,
      { data, level, recalculateBounds }
    ) => {
      const points = data.map((x) => ({
        value: x.count,
        lat: x.latitude,
        lng: x.longitude,
      }));

      const values = points.map((x) => x.value);
      const maxResult = Math.max(...values);
      const result = points.find((x) => x.value === maxResult);
      const coordinates = {
        lat: state.defaultCenter.lat,
        lng: state.defaultCenter.lng,
      };

      if (result) {
        coordinates.lat = result.lat;
        coordinates.lng = result.lng;
      }

      const mapPosition = recalculateBounds
        ? fitPointsToMap(points, state.zoom, {
            width: state.width,
            height: state.height,
          })
        : {
            center: { lat: coordinates.lat, lng: coordinates.lng },
            zoom: state.zoom,
          };

      return {
        ...state,
        totalPositions: {
          ...state.totalPositions,
          data: points,
          isLoading: false,
          level,
        },
        center: mapPosition.center,
        zoom: mapPosition.zoom,
      };
    },
    [FETCH_VESSEL_PERIOD_POSITIONS]: (state) => {
      return {
        ...state,
        periodPositions: {
          ...state.periodPositions,
          isLoading: true,
          data: [],
        },
      };
    },
    [FETCH_VESSEL_PERIOD_POSITIONS_SUCCESS]: (state, { data, level }) => {
      return {
        ...state,
        periodPositions: {
          ...state.periodPositions,
          isLoading: false,
          level,
          data: data.map((x) => ({
            value: x.count,
            lat: x.latitude,
            lng: x.longitude,
          })),
        },
      };
    },
    [FETCH_MACHINERY_STATUS]: (state) => {
      return {
        ...state,
        startTime: null,
        isLoading: true,
        engines: [],
        propulsion: [],
        error: null,
      };
    },
    [FETCH_MACHINERY_STATUS_SUCCESS]: (state, { data }) => {
      return {
        ...state,
        error: null,
        isLoading: false,
        startTime: new Date(data.startTime),
        engines: sortBy(
          filter(
            data.vesselItems,
            (x) =>
              x.itemTypeCategoryId.toUpperCase() ===
              ITEM_TYPE_CATEGORIES.Engine.toUpperCase()
          ),
          "viewPosition"
        ),
        propulsion: sortBy(
          filter(
            data.vesselItems,
            (x) =>
              x.itemTypeCategoryId.toUpperCase() ===
              ITEM_TYPE_CATEGORIES.Propulsor.toUpperCase()
          ),
          "viewPosition"
        ),
      };
    },
    [FETCH_MACHINERY_STATUS_ERROR]: (state) => {
      return {
        ...state,
        isLoading: false,
        error:
          "Could not get load profiles. Please verify configuration of engines/propulsion.",
      };
    },
    [SET_MACHINERY_STATUS_MAP_PROPERTIES]: (
      state,
      { zoom, center, width, height }
    ) => {
      return {
        ...state,
        zoom: zoom || state.zoom,
        center: center || state.center,
        width: width || state.width,
        height: height || state.height,
      };
    },
  }
);

const fitPointsToMap = (points, currentZoom, size) => {
  if (!points.length || !size) {
    return { center: null, zoom: null };
  }
  if (points.length === 1) {
    return { center: points[0], zoom: Math.max(11, currentZoom) };
  }
  const bounds = points.reduce(extendBounds, null);
  const { center, zoom } = fitBounds(bounds, size);
  return { center, zoom: zoom - 1 };
};
