import React from "react";
import PropTypes from "prop-types";
import { isNullOrUndefined } from "../../../../common/objects";
import styles from "../Form.css";
import classNames from "../../../../common/classNames";
import { isInRange } from "../../../../common/numbers";
import { checkParamsValidity } from "../../../common/pageConfig";
import {
  isDate,
  isDateTime,
  isEmail,
  isInteger,
  isJson,
  isNumber,
  isPositiveInteger,
  isPositiveNumber,
  isRequired,
} from "../../../common/forms.validators";

const getDisplayValue = (isFocused, formatValue, value, unit) => {
  let displayValue;
  if (!isFocused && formatValue) {
    displayValue = formatValue(value || "");
    if (unit) {
      if (displayValue !== "-" && displayValue !== "") {
        displayValue = displayValue + " " + unit || "";
      }
    }
  } else {
    displayValue = isNullOrUndefined(value) ? "" : value;
  }

  return displayValue;
};

const validateValue = (value, type) => {
  const validationFunctions = {
    email: isEmail,
    number: isNumber,
    positiveNumber: isPositiveNumber,
    integer: isInteger,
    positiveInteger: isPositiveInteger,
    dateTime: isDateTime,
    date: isDate,
    json: isJson,
    text: isRequired,
  };

  const validationFunction = validationFunctions[type];
  if (!validationFunction) {
    throw new Error(`Unsupported validation type: ${type}`);
  }

  const result = validationFunction(value);

  return {
    valid: result.isValid,
    message: result.validationMessage ?? "",
  };
};

class FormTextBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      isFocused: false,
      isTouched: false,
    };
  }

  handleChange(event) {
    const {
      name,
      onChange,
      value,
      type,
      label,
      multiLine,
      password,
      required,
    } = this.props;
    if (!onChange) return;
    if (type === "number" && event.target.value) {
      event.target.value = event.target.value.replace(/,/g, ".");
    }
    const newValue = event.target.value;
    const { valid, message } = this.validate(newValue);
    if (newValue !== value) {
      onChange(name, newValue, valid, message, {
        label,
        type,
        required,
        multiLine,
        password,
      });
    }
  }

  handleClick(e) {
    if (this.props.onClick) {
      this.props.onClick(this.props.name, e);
    }
  }

  handleBlur(e) {
    this.setState({
      isFocused: false,
      isTouched: true,
    });
    if (this.props.onBlur) {
      this.props.onBlur(e, this.props.name);
    }
  }

  handleFocus() {
    this.textBox.focus();
    this.setState({
      isFocused: true,
      isTouched: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(this.props.name);
    }
  }

  validate(value) {
    const { type, required, integerRange, jsonConfigValidation } = this.props;
    return validate(type, value, required, integerRange, jsonConfigValidation);
  }

  getInputField(showValidationError) {
    const {
      name,
      value,
      formatValue,
      unit,
      disabled,
      readOnly,
      multiLine,
      password,
      inputClassName,
      maxLength,
      rows,
      url,
      border,
      width,
      textAlign,
    } = this.props;
    const { isFocused } = this.state;
    const textBoxStyle = {
      ...createBorderStyle(border),
      width,
      textAlign,
    };
    const displayValue = getDisplayValue(isFocused, formatValue, value, unit);
    if (multiLine === true) {
      return (
        <textarea
          ref={(el) => (this.textBox = el)}
          style={textBoxStyle}
          id={name}
          name={name}
          value={displayValue}
          disabled={disabled}
          readOnly={readOnly}
          onChange={this.handleChange}
          data-valid={!showValidationError}
          onClick={this.handleClick}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          rows={rows}
          className={inputClassName}
          maxLength={maxLength}
        />
      );
    } else if (password === true) {
      return (
        <input
          ref={(el) => (this.textBox = el)}
          style={textBoxStyle}
          type="password"
          id={name}
          name={name}
          value={displayValue}
          disabled={disabled}
          readOnly={readOnly}
          onChange={this.handleChange}
          data-valid={!showValidationError}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          className={inputClassName}
          maxLength={maxLength}
        />
      );
    } else if (url === true) {
      return (
        <div className={styles.url}>
          <a href={displayValue}>{displayValue}</a>
        </div>
      );
    } else {
      return (
        <input
          ref={(el) => (this.textBox = el)}
          style={textBoxStyle}
          type="text"
          id={name}
          name={name}
          value={displayValue}
          disabled={disabled}
          readOnly={readOnly}
          onChange={this.handleChange}
          data-valid={!showValidationError}
          onClick={this.handleClick}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          className={inputClassName}
          maxLength={maxLength}
        />
      );
    }
  }

  render() {
    const {
      name,
      label,
      value,
      margin,
      width,
      labelPosition,
      required,
      isChanged,
      labelTag,
      customValidation,
      isValid,
      validationMessage,
      validationLabel,
      inline,
    } = this.props;
    const { isTouched } = this.state;
    const { valid, message } = customValidation
      ? { valid: isValid, message: validationMessage }
      : this.validate(value);
    const showValidationError = isTouched && !valid;
    const formElementContainerStyle = {
      margin,
      width,
      alignItems: inline ? "flex-end" : null,
    };
    const inputField = this.getInputField(showValidationError);

    return (
      <div
        className={classNames(
          styles.formElementContainer,
          labelPosition === "left" ? styles.leftAlignedContainer : "",
          isChanged && styles.isChanged
        )}
        style={formElementContainerStyle}
      >
        {!!label && (
          <div className={styles.labelContainer}>
            <label htmlFor={name} className={styles.label}>
              {label}
            </label>
            {!!required && (
              <span className={styles.requiredMessage}>required</span>
            )}
            {!!customValidation && validationLabel && (
              <span className={styles.requiredMessage}>{validationLabel}</span>
            )}
            {showValidationError && (
              <span className={styles.validationMessage}>{message}</span>
            )}
            {labelTag && <span className={styles.labelTag}>{labelTag}</span>}
          </div>
        )}
        {inputField}
      </div>
    );
  }
}

export const validate = (
  type,
  value,
  isRequired = false,
  integerRange = undefined,
  jsonConfigValidation = false
) => {
  if (!value && isRequired) {
    return {
      valid: false,
      message: "Please enter a value",
    };
  }

  if (value && integerRange && type === "integer") {
    const { min, max } = integerRange;
    if (!isInRange(value, min, max)) {
      return {
        valid: isInRange(value, min, max),
        message: `Integer must be within ${min} and ${max}`,
      };
    }
  }

  if (value && type === "json" && jsonConfigValidation) {
    const message = checkParamsValidity(value);
    if (message) {
      return {
        valid: false,
        message,
      };
    }
  }

  let validatedResult;
  if (value) {
    validatedResult = validateValue(value, type);
  }

  return validatedResult || { valid: true, message: "" };
};

export const createBorderStyle = (border) => {
  const borderWidth = "2px";
  const borderRadius = "10px";
  const zeroPx = "0px";
  const borderIncludesLeft = border?.includes("left");
  const borderIncludesTop = border?.includes("top");
  const borderIncludesRight = border?.includes("right");
  const borderIncludesBottom = border?.includes("bottom");
  const getBorderWidth = (borderIncludesSide, sideNoRadius) =>
    !border || borderIncludesSide || border.includes(sideNoRadius)
      ? borderWidth
      : zeroPx;
  const getBorderCornerRadius = (
    borderIncludesFirstSide,
    borderIncludesSecondSide
  ) =>
    !border || (borderIncludesFirstSide && borderIncludesSecondSide)
      ? borderRadius
      : zeroPx;

  return {
    borderLeftWidth: getBorderWidth(borderIncludesLeft, "leftNoRadius"),
    borderTopLeftRadius: getBorderCornerRadius(
      borderIncludesLeft,
      borderIncludesTop
    ),
    borderTopWidth: getBorderWidth(borderIncludesTop, "topNoRadius"),
    borderTopRightRadius: getBorderCornerRadius(
      borderIncludesTop,
      borderIncludesRight
    ),
    borderRightWidth: getBorderWidth(borderIncludesRight, "rightNoRadius"),
    borderBottomRightRadius: getBorderCornerRadius(
      borderIncludesRight,
      borderIncludesBottom
    ),
    borderBottomWidth: getBorderWidth(borderIncludesBottom, "bottomNoRadius"),
    borderBottomLeftRadius: getBorderCornerRadius(
      borderIncludesBottom,
      borderIncludesLeft
    ),
  };
};

FormTextBox.defaultProps = {
  disabled: false,
  type: "text",
  labelPosition: "top",
  multiLine: false,
  password: false,
  rows: 3,
  textAlign: "left",
  jsonConfigValidation: false,
};

FormTextBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelTag: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "positiveNumber",
    "integer",
    "positiveInteger",
    "date",
    "dateTime",
    "json",
  ]),
  rows: PropTypes.number,
  required: PropTypes.bool,
  isChanged: PropTypes.bool,
  border: PropTypes.arrayOf(
    PropTypes.oneOf([
      "top",
      "topNoRadius",
      "right",
      "rightNoRadius",
      "bottom",
      "bottomNoRadius",
      "left",
      "leftNoRadius",
    ])
  ),
  margin: PropTypes.string,
  width: PropTypes.string,
  labelPosition: PropTypes.oneOf(["top", "left"]),
  formatValue: PropTypes.func,
  multiLine: PropTypes.bool,
  password: PropTypes.bool,
  inputClassName: PropTypes.string,
  maxLength: PropTypes.number,
  integerRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  textAlign: PropTypes.oneOf(["left", "right"]),
  customValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  validationLabel: PropTypes.string,
  inline: PropTypes.any,
  jsonConfigValidation: PropTypes.bool,
};

export default FormTextBox;
