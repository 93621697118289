import { DateType } from "../../../components/DatePicker/Types";
import { useQueryContext } from "../../../providers/QueryContextProvider";
import { useSelector } from "react-redux";
import { getDateRangeFilter } from "../../../selectors/filters";

export const useDatePickerType = (): DateType | null => {
  const queryContext = useQueryContext();
  const dateRangeFilter = useSelector((state) =>
    getDateRangeFilter(state, { queryContext })
  );
  return dateRangeFilter.value.type ?? null;
};
