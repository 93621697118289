import React from "react";
import styles from "./OperationsGroup.css";
import { lightenDarkenColor } from "../../../common/colors";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

interface Props {
  text: string;
  color: string;
  size?: size;
  textColor?: string;
  operations: string[];
  name: string;
}
type size = "s" | "m" | "l";

export function OperationsGroup({
  text,
  color,
  size,
  textColor,
  operations,
  name,
}: Props): JSX.Element {
  const circleSize =
    (
      {
        s: 24,
        m: 32,
        l: 42,
      } as Record<string, number | undefined>
    )[size ?? "l"] ?? 32;

  const circleStyle = {
    width: `${circleSize}px`,
    height: `${circleSize}px`,
    fontSize: `${circleSize * 0.4}px`,
    fontWeight: "bold",
    color: textColor,
  };
  const mainCircleStyle = {
    backgroundColor: color,
  };
  const leftCircleStyle = {
    backgroundColor: lightenDarkenColor(color, -60),
    transform: `translateX(${-2 * (circleSize / 6)}px)`,
  };
  const middleCircleStyle = {
    backgroundColor: lightenDarkenColor(color, -30),
    transform: `translateX(${-1 - circleSize / 6}px)`,
  };
  const buttonStyle = {
    opacity: "0",
  };
  const componentInfoTemplate = (
    <div>
      <div
        style={{
          textAlign: "left",
          display: "flex",
          fontSize: "20px",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <div
          className={styles.operationsGroup}
          style={{ width: "14px", height: "14px" }}
        >
          {" "}
          <div style={{ backgroundColor: color }}></div>
        </div>
        {`${operations.length} ${name} Modes`}
      </div>
      <div
        style={{ textAlign: "left", display: "flex", fontSize: "16px" }}
      >{`${operations}`}</div>
    </div>
  );
  return (
    <ComponentInfo
      modalTargetId={"popupContainer"}
      buttonStyle={buttonStyle}
      template={componentInfoTemplate}
    >
      <div className={styles.operationsGroup} style={circleStyle}>
        <div style={leftCircleStyle}></div>
        <div style={middleCircleStyle}></div>
        <div style={mainCircleStyle}>{text}</div>
      </div>
    </ComponentInfo>
  );
}

export default OperationsGroup;
