import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import {
  ANALYSIS_FETCHING_METADATA,
  ANALYSIS_FETCHING_METADATA_FAILED,
  ANALYSIS_FETCHING_METADATA_SUCCESS,
  FETCH_ANALYSIS_ENGINES,
  FETCH_ANALYSIS_ENGINES_SUCCESS,
  FETCH_ANALYSIS_FILTER,
  FETCH_ANALYSIS_OPERATIONS,
  FETCH_ANALYSIS_OPERATIONS_SUCCESS,
  FETCH_ANALYSIS_PLOT,
  FETCH_ANALYSIS_PROPULSORS,
  FETCH_ANALYSIS_PROPULSORS_SUCCESS,
  FETCH_ANALYSIS_AXIS_TYPES,
  FETCH_ANALYSIS_AXIS_TYPES_SUCCESS,
  SET_ANALYSIS_AXIS_VALUE_TYPE,
  SET_ANALYSIS_DATE,
  SET_ANALYSIS_FILTER_VALUE,
  SET_ANALYSIS_VESSEL,
  TOGGLE_ANALYSIS_ENGINE,
  TOGGLE_ANALYSIS_OPERATION,
  TOGGLE_ANALYSIS_PROPULSOR,
} from "../actions/action.types";

import { ANALYSIS } from "../api";
import { httpGet, httpPost } from "./common/httpHelpers";

const fetchOperations = (vesselId, dateRange, timeOffset) =>
  httpGet({
    action: FETCH_ANALYSIS_OPERATIONS,
    url: ANALYSIS.fetchOperations(vesselId, dateRange, timeOffset),
    actionData: { vesselId, dateRange, timeOffset },
  });

const fetchEngines = (vesselId, dateRange, timeOffset) =>
  httpGet({
    action: FETCH_ANALYSIS_ENGINES,
    url: ANALYSIS.fetchEngines(vesselId, dateRange, timeOffset),
    actionData: { vesselId, dateRange, timeOffset },
  });

const fetchPropulsors = (vesselId, dateRange, timeOffset) =>
  httpGet({
    action: FETCH_ANALYSIS_PROPULSORS,
    url: ANALYSIS.fetchPropulsors(vesselId, dateRange, timeOffset),
    actionData: { vesselId, dateRange, timeOffset },
  });

const fetchAxisTypes = (vesselId, dateRange, timeOffset) =>
  httpGet({
    action: FETCH_ANALYSIS_AXIS_TYPES,
    url: ANALYSIS.fetchAxisTypes(vesselId, dateRange, timeOffset),
    actionData: { vesselId, dateRange, timeOffset },
  });

const fetchFilter = (
  filterType,
  vesselId,
  dateRange,
  operationIds,
  timeOffset,
  numberOfColumns = 25
) =>
  httpPost({
    action: FETCH_ANALYSIS_FILTER,
    url: ANALYSIS.fetchFilterValues(),
    data: {
      filterType,
      vesselId,
      from: dateRange.from,
      to: dateRange.to,
      operationIds,
      numberOfColumns,
      timeOffset,
    },
    actionData: {
      filterType,
    },
  });

function* fetchAnalysisPlot(
  vesselId,
  dateRange,
  filters,
  operationIds,
  engineIds = [],
  propulsorIds = []
) {
  const state = yield select();
  const { xAxisType, yAxisType, zAxisType, date } = state.analysis;
  const { from, to } = dateRange;
  const timeOffset = parseInt(date.timeOffset);
  return yield call(httpPost, {
    action: FETCH_ANALYSIS_PLOT,
    url: ANALYSIS.fetchAnalysisPlot(),
    data: {
      filters: filters
        ? Object.entries(filters).map(([key, value]) => ({
            name: key,
            min: value.minValue,
            max: value.maxValue,
          }))
        : null,
      operationIds: operationIds,
      engineIds: engineIds,
      propulsorIds: propulsorIds,
      vesselId,
      from,
      to,
      timeOffset,
      x: xAxisType.id,
      y: yAxisType.id,
      z: zAxisType.id,
    },
  });
}

function* fetchFilters(vesselId, dateRange, operationIds, timeOffset) {
  yield all([
    call(fetchFilter, "Speed", vesselId, dateRange, operationIds, timeOffset),
    call(fetchFilter, "Wind", vesselId, dateRange, operationIds, timeOffset),
    call(
      fetchFilter,
      "RunningEngines",
      vesselId,
      dateRange,
      operationIds,
      timeOffset
    ),
    call(
      fetchFilter,
      "PitchEnergyFast",
      vesselId,
      dateRange,
      operationIds,
      timeOffset
    ),
  ]);
}

function* fetchMetadata() {
  yield put({ type: ANALYSIS_FETCHING_METADATA });

  const { analysis } = yield select();
  const { vesselId, date } = analysis;

  if (!vesselId || !date) {
    return;
  }

  const results = yield all({
    operations: call(
      fetchOperations,
      vesselId,
      date.range,
      parseInt(date.timeOffset)
    ),
    engines: call(
      fetchEngines,
      vesselId,
      date.range,
      parseInt(date.timeOffset)
    ),
    propulsors: call(
      fetchPropulsors,
      vesselId,
      date.range,
      parseInt(date.timeOffset)
    ),
    axisTypes: call(
      fetchAxisTypes,
      vesselId,
      date.range,
      parseInt(date.timeOffset)
    ),
  });

  if (
    results.operations.type !== FETCH_ANALYSIS_OPERATIONS_SUCCESS ||
    results.engines.type !== FETCH_ANALYSIS_ENGINES_SUCCESS ||
    results.propulsors.type !== FETCH_ANALYSIS_PROPULSORS_SUCCESS ||
    results.axisTypes.type !== FETCH_ANALYSIS_AXIS_TYPES_SUCCESS
  ) {
    yield put({ type: ANALYSIS_FETCHING_METADATA_FAILED });
    return;
  }

  yield put({
    type: ANALYSIS_FETCHING_METADATA_SUCCESS,
    vesselId: vesselId,
    date: date,
    operations: results.operations.data,
    engines: results.engines.data,
    propulsors: results.propulsors.data,
    axisTypes: results.axisTypes.data,
  });
}

function* handleOperationChange() {
  const { analysis } = yield select();
  const { vesselId, date, operations } = analysis;
  const { data: operationsData } = operations;
  const timeOffset = parseInt(date.timeOffset);
  const selectedOperationIds = operationsData
    .filter((x) => x.isSelected === true)
    .map((x) => x.id);

  yield call(
    fetchFilters,
    vesselId,
    date.range,
    selectedOperationIds,
    timeOffset
  );

  yield call(performFetchAnalysisPlot);

  yield fork(watchFilterValueChanges);
}

function* performFetchAnalysisPlot() {
  const { analysis } = yield select();
  const {
    vesselId,
    date,
    operations,
    filters,
    engines,
    propulsors,
    axisTypes,
  } = analysis;
  const { data: operationsData } = operations;
  const { data: engineData } = engines;
  const { data: propulsorData } = propulsors;
  const selectedOperationIds =
    operationsData &&
    operationsData.filter((x) => x.isSelected === true).map((x) => x.id);
  const selectedEngineIds =
    engineData &&
    engineData.filter((x) => x.isSelected === true).map((x) => x.id);
  const selectedPropulsorIds =
    propulsorData &&
    propulsorData.filter((x) => x.isSelected === true).map((x) => x.id);

  yield call(
    fetchAnalysisPlot,
    vesselId,
    date.range,
    filters,
    selectedOperationIds,
    selectedEngineIds,
    selectedPropulsorIds,
    axisTypes
  );
}

function* watchFilterValueChanges() {
  yield takeLatest(
    [
      SET_ANALYSIS_FILTER_VALUE,
      TOGGLE_ANALYSIS_ENGINE,
      TOGGLE_ANALYSIS_PROPULSOR,
      TOGGLE_ANALYSIS_OPERATION,
      SET_ANALYSIS_AXIS_VALUE_TYPE,
    ],
    performFetchAnalysisPlot
  );
}

function* watchLoadFilters() {
  yield takeLatest([SET_ANALYSIS_DATE, SET_ANALYSIS_VESSEL], fetchMetadata);
}

function* watchMetadataLoaded() {
  yield takeLatest(
    [ANALYSIS_FETCHING_METADATA_SUCCESS, TOGGLE_ANALYSIS_OPERATION],
    handleOperationChange
  );
}

export function* analysisSaga() {
  yield fork(watchLoadFilters);
  yield fork(watchMetadataLoaded);
}
