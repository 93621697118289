import React from "react";
import styles from "../Admin.css";
import { formatDateTime } from "../../../common/dates";
import { isFailed } from "../../../common/vesselActions";
import Button from "../../../../common/components/Button/Button";
import { connect } from "react-redux";
import { acknowledgeActionFailure } from "../../../actions/admin";

class VesselAction extends React.Component {
  render() {
    const { currentAction, acknowledgeActionFailure } = this.props;

    if (!currentAction || !currentAction.actionMetadata) {
      return null;
    }

    const {
      createdOn,
      completedOn,
      failedOn,
      failedForReason,
      failureAcknowledgedOn,
      scheduledOn,
      updateTarget,
    } = currentAction.actionMetadata;

    const isActionFailed = isFailed(currentAction);

    return (
      <div>
        <div className={styles.detailHeader}>Current Action status</div>
        <table className={styles.vesselActionTable}>
          <tbody>
            {updateTarget && (
              <tr>
                <td>Update target:</td>
                <td>{updateTarget}</td>
              </tr>
            )}
            <tr>
              <td>Created on:</td>
              <td>{formatDateTime(createdOn)}</td>
            </tr>
            <tr>
              <td>Scheduled on:</td>
              <td>{scheduledOn && formatDateTime(scheduledOn)}</td>
            </tr>
            {completedOn && (
              <tr className={styles.ok}>
                <td>Completed on:</td>
                <td>{formatDateTime(completedOn)}</td>
              </tr>
            )}
            {failedOn && (
              <tr className={isActionFailed ? styles.error : undefined}>
                <td>Failed on:</td>
                <td>{formatDateTime(failedOn)}</td>
              </tr>
            )}
            {failedOn && (
              <tr>
                <td>Error message:</td>
                <td>{failedForReason}</td>
              </tr>
            )}
            {failedOn && (
              <tr>
                <td>Error acknowledged on:</td>
                <td>
                  {failureAcknowledgedOn &&
                    formatDateTime(failureAcknowledgedOn)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isActionFailed && (
          <Button
            value="Acknowledge failed action"
            clickAction={acknowledgeActionFailure}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAction: state.admin.vessels.configureRemoteControl.currentAction,
});

const mapDispatchToProps = {
  acknowledgeActionFailure,
};

export default connect(mapStateToProps, mapDispatchToProps)(VesselAction);
