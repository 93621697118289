import createReducer from "../createReducer";
import { admin } from "../../actions/action.types";
import { combineReducers } from "redux";
import { orderBy, uniqBy } from "lodash";
import { createRequestReducer } from "../createRequestReducer";

export const approvalStatuses = [
  {
    id: "AutoApproved",
    name: "Auto Approved",
  },
  {
    id: "EmailFailed",
    name: "Received But Notification Failed",
  },
  {
    id: "Received",
    name: "Received",
  },
  {
    id: "ReviewedHidden",
    name: "Reviewed And Hidden",
    allowSet: true,
  },
  {
    id: "ReviewedApproved",
    name: "Reviewed And Approved",
    allowSet: true,
  },
  {
    id: "WaitingForReview",
    name: "Waiting For Review",
  },
  {
    id: "Archived",
    name: "Archived",
    allowSet: true,
  },
];

const eventListDefaultState = {
  events: [],
  loading: false,
  selectedApprovalStatus: "Received",
  selectedVessel: null,
};

const eventList = createReducer(eventListDefaultState, {
  [admin.healthManagement.FETCH_EVENTS]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [admin.healthManagement.FETCH_EVENTS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      events: data,
    };
  },
  [admin.healthManagement.FETCH_EVENTS_ERROR]: (state, { error }) => {
    return {
      ...state,
      events: [],
      loading: false,
      error,
    };
  },
  [admin.healthManagement.SET_STATUS_FILTER]: (state, { approvalStatus }) => {
    return {
      ...state,
      selectedApprovalStatus: approvalStatus,
    };
  },
  [admin.healthManagement.SORT_EVENTS]: (state, { sortBy, sortDirection }) => ({
    ...state,
    sortBy,
    sortDirection,
    events: sortItems({ sortBy, sortDirection }, state.events),
  }),
  [admin.healthManagement.SELECT_VESSEL]: (state, { vessel }) => ({
    ...state,
    selectedVessel: vessel,
  }),
});

function sortItems({ sortBy, sortDirection }, items) {
  return orderBy(items, [sortBy], [sortDirection]);
}

const eventDefaultState = {
  data: {},
  loading: false,
  error: null,
};

const event = createReducer(eventDefaultState, {
  [admin.healthManagement.FETCH_EVENT]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [admin.healthManagement.FETCH_EVENT_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      data,
      loading: false,
    };
  },
  [admin.healthManagement.FETCH_EVENT_ERROR]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
  [admin.healthManagement.UPDATE_EVENT_FIELD]: (state, { field, value }) => {
    return {
      ...state,
      data: {
        ...state.data,
        [field]: value,
      },
      hasChanges: true,
    };
  },
  [admin.healthManagement.APPROVE_EVENT]: (state) => {
    return {
      ...state,
      isSaving: true,
    };
  },
  [admin.healthManagement.APPROVE_EVENT_SUCCESS]: (state) => {
    return {
      ...state,
      isSaving: false,
    };
  },
  [admin.healthManagement.APPROVE_EVENT_ERROR]: (state, { error, data }) => {
    return {
      ...state,
      isSaving: false,
      error: (data && data.message) || error,
    };
  },
  [admin.healthManagement.CLOSE_ERROR_POPUP]: (state) => {
    return {
      ...state,
      error: null,
    };
  },
});

const eventDetailsInitialState = {
  data: {},
  loading: false,
};

const eventDetails = createReducer(eventDetailsInitialState, {
  [admin.healthManagement.FETCH_EVENT_DETAILS]: () => {
    return {
      ...eventDetailsInitialState,
      loading: true,
    };
  },
  [admin.healthManagement.FETCH_EVENT_DETAILS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      data,
      loading: false,
    };
  },
  [admin.healthManagement.FETCH_EVENT_DETAILS_ERROR]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
});

const standardTexts = createRequestReducer(
  admin.healthManagement.FETCH_STANDARD_TEXTS
);

export default combineReducers({
  eventList,
  event,
  eventDetails,
  standardTexts,
});

export function getVesselOptions(events) {
  return orderBy(
    uniqBy(
      events.map((x) => ({ id: x.vesselId, name: x.vesselName })),
      "name"
    ),
    "name"
  );
}
