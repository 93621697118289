import React from "react";
import styles from "./StateFilter.css";
import { useQueryContext } from "../../providers/QueryContextProvider";
import SelectBox from "../SelectBox";
import { get, keyBy } from "lodash";
import { STATE_FILTERS } from "../../config";

const getStateKey = (vesselState) => {
  return `${vesselState.stateType}_${get(vesselState, "value")}`;
};

const getFilterOptions = () => {
  return STATE_FILTERS.map((x) => {
    return {
      ...x,
      stateKey: getStateKey(x),
    };
  });
};

export const StateFilter = () => {
  const queryContext = useQueryContext();
  const stateFilters = get(queryContext, "states", []);

  const handleOnSelect = (selected) => {
    const selectedValues = selected.map((x) => ({
      stateType: x.stateType,
      value: x.value,
    }));
    queryContext.setStates(selectedValues);
  };

  const options = getFilterOptions();

  const selectedOptions = stateFilters.map((x) => {
    const stateKey = getStateKey(x);
    return {
      ...x,
      stateKey,
      name: get(keyBy(options, "stateKey")[stateKey], "name"),
    };
  });

  return (
    <div className={styles.stateFilterContainer}>
      <SelectBox
        options={options}
        multi
        optionValKey={"stateKey"}
        optionLabelKey={"name"}
        selected={selectedOptions}
        onSelect={handleOnSelect}
        placeholder={"Page filter"}
      />
    </div>
  );
};
