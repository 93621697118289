import React from "react";
import { connect } from "react-redux";
import Button from "../../../../../common/components/Button";
import Loader from "../../../../../common/components/Loader";
import {
  prepListState,
  prepUploadState,
} from "../../../../reducers/admin/vesselConfig/reducer.baseline";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import FormFileUpload from "../../../../components/Form/FormFileUpload/FormFileUpload";
import styles from "../OnboardProcessorConfig/OnboardProcessorConfigController.css";

export default connect((state) => ({
  upload: prepUploadState(state.admin.baseline),
  list: prepListState(state.admin.baseline),
}))(BaselineController);

function BaselineController({
  enabled,
  isOpen,
  onToggleClick,
  upload,
  list,
  dispatch,
}) {
  return (
    <CollapsePanel
      enabled={enabled}
      isOpen={isOpen}
      onToggleClick={onToggleClick}
      title="Baseline"
      subTitle="Upload baseline.txt files that can be bundled with the onboard processor config"
      testId="baselineController"
      buttons={[
        {
          label: "New",
          disabled: !enabled || !isOpen || upload.isVisible,
          isVisible: isOpen,
          action: () => dispatch(upload.actions.show),
        },
      ]}
    >
      {upload.isVisible && (
        <UploadBaselineForm {...upload} dispatch={dispatch} />
      )}
      <BaselineTable {...list} dispatch={dispatch} />
    </CollapsePanel>
  );
}

function UploadBaselineForm({ isLoading, error, actions, dispatch }) {
  return (
    <div className={styles.formContent}>
      <div className={styles.block}>
        <FormFileUpload
          name="baselineTxt"
          label="baseline.txt"
          required
          onChange={(_name, value) =>
            dispatch({ ...actions.setBaselineTxt, value })
          }
        />
      </div>
      <div className={styles.block}>
        <Button
          value="Upload"
          isLoading={isLoading}
          clickAction={() => dispatch(actions.upload)}
        />
      </div>
      {error && (
        <div className={styles.block}>
          <span className={styles.actionError} title={error} />
          Error!
        </div>
      )}
    </div>
  );
}

function BaselineTable({
  showLoader,
  showNoDataMsg,
  error,
  entries,
  dispatch,
}) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th />
          <th>Version</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {showLoader && (
          <tr>
            <td />
            <td colSpan={2}>
              <Loader expand error={error} />
            </td>
          </tr>
        )}
        {showNoDataMsg && (
          <tr>
            <td />
            <td colSpan={2}>
              <em>No baselines has been uploaded yet.</em>
            </td>
          </tr>
        )}
        {entries.map((entry) => (
          <tr key={entry.id}>
            <td />
            <td>{entry.version}</td>
            <td>
              <div className={styles.actions}>
                <ActionLink
                  text="Download"
                  error={entry.downloadError}
                  isLoading={entry.isDownloading}
                  onClick={() => dispatch(entry.download)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function ActionLink({ text, error, isLoading, onClick }) {
  return (
    <div className={styles.action}>
      {error && <span className={styles.actionError} title={error} />}
      <Button
        type="link"
        value={text}
        clickAction={onClick}
        isLoading={isLoading}
      />
    </div>
  );
}
