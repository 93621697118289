import { combineReducers } from "redux";
import { vesselList } from "./reducer.vesselList";
import { configureRemoteControl } from "./reducer.configureRemoteControl";
import { configureSensorReplication } from "./reducer.configureSensorReplication";

export default combineReducers({
  vesselList,
  configureSensorReplication,
  configureRemoteControl,
});
