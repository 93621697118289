import React, { Component } from "react";
import { get } from "lodash";
import styles from "./SettingsEditForm.css";
import {
  getEnergyBudgetOverviewPeriod,
  GROUP_BY,
} from "../../PageConfigurationGrid/pageConfigurationGridData";
import { FormCheckBox, FormSelectBox } from "../../../../../components/Form";
import { getMetrics } from "../../../../../common/pageConfig";
import { METRIC_IDS } from "../../../../../config";

class EnergyBudgetOverviewEditForm extends Component {
  render() {
    const { params, onChangeCheckBox, onChangeSelectBox } = this.props;
    const period = getEnergyBudgetOverviewPeriod();
    const metricIds = get(params, "metricIds", []).map((mi) =>
      mi.toLowerCase()
    );
    const groupBy = get(params, "groupBy", "").toLowerCase();
    const selectedGroupBy = GROUP_BY.find((g) => g.id === groupBy);
    const selectedHistoricalCount = period.find(
      (p) => p.id === params.historicalCount
    );
    const selectedFutureCount = period.find((p) => p.id === params.futureCount);
    const metrics = getMetrics(metricIds, METRIC_IDS);
    return (
      <div>
        <h2 className={styles.title}>Fuel Type</h2>
        <div className={styles.selectedMetrics}>
          {`[${metrics.join(", ")}]`}
        </div>
        <div className={styles.field}>
          {METRIC_IDS.map((i) => (
            <FormCheckBox
              key={i.name}
              value={metricIds.includes(i.name)}
              label={i.label}
              name={i.name}
              iconPosition="left"
              onChange={onChangeCheckBox}
            />
          ))}
        </div>
        <FormSelectBox
          name="groupBy"
          label="Group By"
          selected={selectedGroupBy}
          options={GROUP_BY}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onChangeSelectBox}
        />
        <FormSelectBox
          name="historicalCount"
          label="Historical Count"
          selected={selectedHistoricalCount}
          options={period}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onChangeSelectBox}
        />
        <FormSelectBox
          name="futureCount"
          label="Future Count"
          selected={selectedFutureCount}
          options={period}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onChangeSelectBox}
        />
      </div>
    );
  }
}

export default EnergyBudgetOverviewEditForm;
