import PropTypes from "prop-types";
import React from "react";
import styles from "./ButtonClose.css";
import classNames from "../../../common/classNames";

interface Props {
  title: string;
  clickAction: () => void;
  disabled?: boolean;
  className?: string;
}
const ButtonClose = (props: Props) => {
  return (
    <button
      className={classNames(styles.removeBtn, props.className)}
      onClick={props.clickAction}
      title={props.title}
    >
      <span className={styles.closeIcon}>
        <span className={styles.visuallyHidden}>{props.title}</span>
      </span>
    </button>
  );
};

ButtonClose.propTypes = {
  title: PropTypes.string,
  clickAction: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonClose;
