import {
  FETCH_MACHINERY_STATUS,
  FETCH_VESSEL_PERIOD_POSITIONS,
  FETCH_VESSEL_TOTAL_POSITIONS,
  SET_MACHINERY_STATUS_MAP_PROPERTIES,
} from "./action.types";
import { VESSELS } from "../api";
import fetch from "./action.fetch";

export const fetchVesselTotalPositions =
  (id, recalculateBounds = true) =>
  (dispatch, getState) => {
    const state = getState();
    const { machineryReport } = state;
    const { zoom } = machineryReport;
    const level = getLevelForZoom(zoom);
    dispatch(
      fetch({
        url: VESSELS.getPositions(id, level),
        actionType: FETCH_VESSEL_TOTAL_POSITIONS,
        actionData: {
          level,
          recalculateBounds,
          vesselId: id,
        },
      })
    );
  };

export const fetchVesselPeriodPositions =
  (id, dateRange, timeOffset) => (dispatch, getState) => {
    const state = getState();
    const { machineryReport } = state;
    const { zoom } = machineryReport;
    const level = getLevelForZoom(zoom);
    dispatch(
      fetch({
        url: VESSELS.getPositionsForPeriod(id, dateRange, level, timeOffset),
        actionType: FETCH_VESSEL_PERIOD_POSITIONS,
        actionData: {
          level,
          vesselId: id,
        },
      })
    );
  };

export const fetchMachineryStatus = (id, dateRange, timeOffset) =>
  fetch({
    url: VESSELS.getMachineryStatus(id, dateRange, timeOffset),
    actionType: FETCH_MACHINERY_STATUS,
  });

export const setMachineryStatusMapProperties =
  ({ zoom, center, width, height, vesselId, range, timeOffset }) =>
  (dispatch, getState) => {
    const state = getState();
    const { machineryReport } = state;
    const { totalPositions, periodPositions } = machineryReport;
    const level = getLevelForZoom(zoom);
    if (totalPositions.level !== level) {
      dispatch(fetchVesselTotalPositions(vesselId, false));
    }
    if (periodPositions.level !== level) {
      dispatch(fetchVesselPeriodPositions(vesselId, range, timeOffset));
    }

    dispatch({
      type: SET_MACHINERY_STATUS_MAP_PROPERTIES,
      zoom,
      center,
      width,
      height,
    });
  };

const getLevelForZoom = (zoom) => {
  let level = 3;
  if (zoom < 6) {
    level = 2;
  }
  return level;
};
