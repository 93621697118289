import React from "react";
import styles from "./Title.css";
import { observer } from "mobx-react";

export default observer(
  class Title extends React.Component {
    render() {
      return <h1 className={styles.title}>{this.props.title}</h1>;
    }
  }
);
