import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../common/components/Loader";
import { panelMappings } from "./Controllers";
import Header from "../../../components/Header/Header";
import styles from "./VesselConfigView.css";
import {
  togglePanel,
  close,
  init,
} from "../../../actions/admin/action.vesselconfig";
import Button from "../../../../common/components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";

const VesselConfigView = (props) => {
  // Get vesselId from router
  const { vesselId } = useParams();

  // Get values from store
  const mode = useSelector((state) => state.admin.vesselConfig.mode);
  const panels = useSelector((state) => state.admin.vesselConfig.panels);
  const isLoading = useSelector((state) => state.admin.vesselConfig.isLoading);
  const error = useSelector((state) => state.admin.vesselConfig.error);
  const title = useSelector((state) => getTitle(state));

  // Setup actions
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClose = () => {
    dispatch(close());
    navigate("/admin/vessels");
  };
  const handlePaneToggleClick = (name, isVisible) => {
    dispatch(togglePanel(name, !isVisible));
  };
  const onInit = () => {
    dispatch(init(vesselId, props.isNew));
  };

  useEffect(() => onInit(), []);

  const renderContent = () => {
    const isEdit = mode === "edit";

    if (isLoading || error) {
      return (
        <div className={styles.contentContainer}>
          <Loader error={error} expand />
        </div>
      );
    }

    return (
      <div className={styles.contentContainer}>
        {panels.map((p) => {
          const PanelController = panelMappings[p.name];
          return (
            <PanelController
              mode={mode}
              enabled={isEdit}
              key={p.name}
              isOpen={p.isVisible}
              onToggleClick={() => handlePaneToggleClick(p.name, p.isVisible)}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header title={title}>
        <div className={styles.headerButtonContainer}>
          <Button
            value={mode === "new" ? "Cancel" : "Close"}
            clickAction={onClose}
          />
        </div>
      </Header>
      {renderContent()}
    </div>
  );
};

const getTitle = createSelector(
  (state) => state.admin.vesselConfig.vessel?.name,
  (state) => state.admin.vesselConfig.mode,
  (vesselName, mode) => {
    if (mode === "new") {
      return "Admin - Vessels - New vessel";
    } else if (vesselName) {
      return `Admin - Vessels - Edit ${vesselName}`;
    } else {
      return "Admin - Vessels - Edit";
    }
  }
);

export default VesselConfigView;
