import React from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
  dateFilterToJSON,
  defaultDateFilter,
  convertVesselSpecificDateRange,
} from "../common/dateFilter";
import { getViewsByPath } from "../common/views";
import { get } from "lodash";
import { parseQueryString } from "../../common/queryStrings";
import { DatePickerValue } from "../components/DatePicker/Types";

interface QueryParams {
  date?: any;
  kpis?: any[];
  items?: any[];
  vesselItems?: any[];
  vesselId?: string;
  groupId?: string;
  states?: any[];
  pageNum?: number;
  showOperations?: boolean;
  kpisDisabled?: boolean;
}

export interface IQueryContext extends QueryParams {
  pathname: string;
  setSelectedVesselId: ({ id }: { id: string }) => void;
  setSelectedGroupId: ({ id }: { id: string }, vesselId: string) => void;
  setDate: (dateFilter: DatePickerValue) => void;
  setItems: (items: any[]) => void;
  setPageNum: (pageNum: number) => void;
  setShowOperations: (showOperations: boolean) => void;
  setKpisDisabled: (kpisDisabled: boolean) => void;
  toggleKpi: (kpi: string) => void;
  navigate: (
    path: string,
    queryString?: QueryParams,
    replace?: boolean
  ) => void;
  setStates: (states: any[]) => void;
}

const initialQueryParams: QueryParams = {
  date: defaultDateFilter(),
  kpis: [],
  items: [],
  vesselItems: [],
  states: [],
};

interface Location {
  pathname: string;
  search: string;
}

const QueryContext = React.createContext(initialQueryParams);

function getQueryString(obj: Record<string, any>, parentKey?: string): string {
  const queryParams = [];

  for (const key in obj) {
    const value = obj[key];
    const paramName = parentKey ? `${parentKey}.${key}` : key;

    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        // Handle arrays by iterating through their elements
        for (let i = 0; i < value.length; i++) {
          const arrayItem = value[i];
          if (typeof arrayItem === "object") {
            // Recursively handle nested objects in arrays
            queryParams.push(getQueryString(arrayItem, `${paramName}[${i}]`));
          } else {
            // Encode and add the key-value pair to the query parameters
            queryParams.push(`${paramName}[${i}]=${arrayItem}`);
          }
        }
      } else if (typeof value === "object") {
        // Recursively handle nested objects
        queryParams.push(getQueryString(value, paramName));
      } else {
        // Encode and add the key-value pair to the query parameters
        queryParams.push(`${paramName}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryParams.join("&");
}

function getMergedQueryObject(
  { pathname, search }: Location,
  newParams: QueryParams = {}
): QueryParams {
  const validQueryParams: string[] = get(
    get(getViewsByPath(), pathname),
    "queryParams",
    []
  );

  const currentParams = parseQueryString(search);

  const currentQuery: QueryParams = validQueryParams.reduce(
    (acc: any, q: any) => {
      return {
        ...acc,
        [q]: currentParams[q],
      };
    },
    {}
  );

  return {
    ...currentQuery,
    ...newParams,
  };
}

function setQueryParams(
  navigate: NavigateFunction,
  location: Location,
  params: QueryParams = {},
  replace = false
): void {
  const newQuery = getMergedQueryObject(location, {
    ...params,
  });
  const newQueryString = getQueryString(newQuery);

  navigate(
    {
      pathname: location.pathname,
      search: newQueryString,
    },
    { replace: replace }
  );
}

function setSelectedVesselId(
  navigate: NavigateFunction,
  location: Location
): ({ id }: { id: string }) => void {
  return ({ id }: { id: string }) => {
    const newDate = getNewDate(location);

    setQueryParams(navigate, location, {
      vesselId: id,
      kpis: undefined,
      items: undefined,
      vesselItems: undefined,
      date: newDate,
    });
  };
}

function setSelectedGroupId(
  navigate: NavigateFunction,
  location: Location
): ({ id }: { id: string }, vesselId: string) => void {
  return ({ id }: { id: string }, vesselId: string) => {
    const newDate = getNewDate(location);

    setQueryParams(navigate, location, {
      groupId: id,
      vesselId,
      kpis: undefined,
      items: undefined,
      vesselItems: undefined,
      states: undefined,
      date: newDate,
      pageNum: 1,
    });
  };
}

function setPageNum(
  navigate: NavigateFunction,
  location: Location
): (pageNum: number) => void {
  return (pageNum: number) => {
    setQueryParams(navigate, location, { pageNum }, true);
  };
}

function getNewDate(location: Location) {
  const date = get(getMergedQueryObject(location), "date", {});
  return convertVesselSpecificDateRange(date);
}

function setDate(
  navigate: NavigateFunction,
  location: Location
): (dateFilter: DatePickerValue) => void {
  return (dateFilter: DatePickerValue) => {
    const dateFilterJSON = dateFilterToJSON(dateFilter);
    setQueryParams(navigate, location, {
      date: {
        ...dateFilterJSON,
      },
    });
  };
}

function setItems(
  navigate: NavigateFunction,
  location: Location
): (items: any[]) => void {
  return (items: any[]) => {
    setQueryParams(navigate, location, { items }, true);
  };
}

function setShowOperations(
  navigate: NavigateFunction,
  location: Location
): (showOperations: boolean) => void {
  return (showOperations: boolean) => {
    setQueryParams(
      navigate,
      location,
      {
        showOperations,
      },
      true
    );
  };
}

function setKpisDisabled(
  navigate: NavigateFunction,
  location: Location
): (kpisDisabled: boolean) => void {
  return (kpisDisabled: boolean) => {
    setQueryParams(
      navigate,
      location,
      {
        kpisDisabled,
      },
      true
    );
  };
}

function toggleKpi(
  navigate: NavigateFunction,
  location: Location
): (kpi: string) => void {
  return (kpi: string) => {
    const current = get(getMergedQueryObject(location), "kpis", []);
    if (current.some((x: string) => x === kpi)) {
      setQueryParams(
        navigate,
        location,
        { kpis: [...current.filter((x: string) => x !== kpi)] },
        true
      );
    } else {
      setQueryParams(navigate, location, { kpis: [...current, kpi] }, true);
    }
  };
}

function setStates(
  navigate: NavigateFunction,
  location: Location
): (states: any[]) => void {
  return (states: any) => {
    setQueryParams(navigate, location, { states }, true);
  };
}

function navigateTo(
  navigate: NavigateFunction,
  location: Location
): (path: string, queryString?: QueryParams, replace?: boolean) => void {
  return (path: string, queryString: QueryParams = {}, replace = false) => {
    const query = getMergedQueryObject(
      {
        pathname: path,
        search: location.search,
      },
      queryString
    );

    const mergedQueryString = "?" + getQueryString(query);

    navigate(
      {
        pathname: path,
        search: mergedQueryString,
      },
      { replace: replace }
    );
  };
}

function QueryContextProvider({ children }: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const currentParams = getMergedQueryObject(location);

  const context: IQueryContext = {
    ...currentParams,
    pathname: location.pathname,
    setSelectedVesselId: setSelectedVesselId(navigate, location),
    setSelectedGroupId: setSelectedGroupId(navigate, location),
    setDate: setDate(navigate, location),
    setItems: setItems(navigate, location),
    setPageNum: setPageNum(navigate, location),
    setShowOperations: setShowOperations(navigate, location),
    setKpisDisabled: setKpisDisabled(navigate, location),
    toggleKpi: toggleKpi(navigate, location),
    navigate: navigateTo(navigate, location),
    setStates: setStates(navigate, location),
  };

  return (
    <QueryContext.Provider value={context}>{children}</QueryContext.Provider>
  );
}

function useQueryContext(): IQueryContext {
  const context = React.useContext(QueryContext);
  if (context === undefined) {
    throw new Error(
      "useQueryContext must be used within a QueryContextProvider"
    );
  }
  return context as IQueryContext;
}

export { QueryContext, QueryContextProvider, useQueryContext };
