import { curry, flow, isDate, isNumber, isString } from "lodash";
import { getTimeOffsetFormat } from "../reducers/datePicker";
import { DateRange } from "../components/DatePicker/Types";

const ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;
export const CURRENT_DAY = "CurrentDay";
export const CURRENT_MONTH = "CurrentMonth";
export const CURRENT_YEAR = "CurrentYear";

export const createDate = (
  year: number,
  month = 1,
  day = 1,
  hour = 0,
  minute = 0,
  second = 0,
  milli = 0
) => new Date(Date.UTC(year, month - 1, day, hour, minute, second, milli));

const dateTimePattern =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,3})?(Z|([+-]\d{2}:\d{2}))$/;

export const defaultDateRanges = [
  { id: CURRENT_DAY, name: "Current day" },
  { id: CURRENT_MONTH, name: "Current month" },
  { id: CURRENT_YEAR, name: "Current year" },
  { id: null, name: "Use system default" },
];

export const parseDateTime = (str: string) =>
  dateTimePattern.test(str) ? new Date(str) : undefined;

export const parseDateTimeWithoutTimeZoneOffset = (str: string) => {
  if (dateTimePattern.test(str)) {
    const date = new Date(str);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
};

export const dateWithTimezoneOffset = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

const localDateTimePattern =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{1,3}))?)?$/;
export const parseLocalDateTime = (str: string) => {
  const match = localDateTimePattern.exec(str);
  if (match !== null) {
    return createDate(
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3], 10),
      parseInt(match[4], 10),
      parseInt(match[5], 10),
      match[6] ? parseInt(match[6], 10) : 0,
      match[7] ? parseInt(match[7].padEnd(3, "0"), 10) : 0
    );
  }
};

const localDatePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
export const parseLocalDate = (str: string) => {
  const match = localDatePattern.exec(str);
  if (match !== null) {
    return createDate(
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3], 10)
    );
  }
};

const yearMonthPattern = /^(\d{4})-(\d{2})$/;
export const parseYearMonth = (str: string) => {
  const match = yearMonthPattern.exec(str);
  if (match !== null) {
    return createDate(parseInt(match[1], 10), parseInt(match[2], 10));
  }
};

const quarterPattern = /^(\d{4})-Q(\d)$/;
export const parseQuarter = (str: string) => {
  const match = quarterPattern.exec(str);
  if (match !== null) {
    const year = parseInt(match[1], 10);
    const quarter = parseInt(match[2], 10);
    if (quarter >= 1 && quarter <= 4) {
      return createDate(year, quarterToMonth(quarter));
    }
  }
};

const yearPattern = /^(\d{4})$/;
export const parseYear = (str: string) => {
  const match = yearPattern.exec(str);
  if (match !== null) {
    return createDate(parseInt(match[1], 10));
  }
};

export const fromISOString = (str: string) => {
  const date =
    parseDateTime(str) ||
    parseLocalDateTime(str) ||
    parseLocalDate(str) ||
    parseYearMonth(str) ||
    parseQuarter(str) ||
    parseYear(str);

  if (date === undefined) {
    throw Error(`unsupported input string ${str}`);
  }

  return date;
};

export const from = (val: unknown) => {
  if (isDate(val)) {
    return val;
  } else if (isNumber(val)) {
    return new Date(val);
  } else if (isString(val)) {
    return fromISOString(val);
  }
  throw new Error(`unsupported input value: ${val}`);
};

export const now = () => new Date();
export const today = () => atStartOfDay(now());
export const tomorrow = () => addDays(1, today());

export const getYear = (date: Date) => date.getUTCFullYear();
export const getMonth = (date: Date) => date.getUTCMonth() + 1;
export const getDay = (date: Date) => date.getUTCDate();
export const getQuarter = (date: Date) =>
  Math.floor((date.getUTCMonth() + 3) / 3);
export const quarterToMonth = (quarter: number) => quarter * 3 - 2;
export const getDayOfWeek = (date: Date) =>
  date.getUTCDay() === 0 ? 7 : date.getUTCDay();

export const addYears = curry((years: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCFullYear(d.getUTCFullYear() + years);
  return d;
});

export const addMonths = curry((months: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCMonth(d.getUTCMonth() + months);
  return d;
});

export const addDays = curry((days: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCDate(d.getUTCDate() + days);
  return d;
});

export const addHours = curry((hours: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCHours(d.getUTCHours() + hours);
  return d;
});

export const addMinutes = curry((minutes: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCMinutes(d.getUTCMinutes() + minutes);
  return d;
});

export const addSeconds = curry((seconds: number, date: Date) => {
  const d = new Date(date.getTime());
  d.setUTCSeconds(d.getUTCSeconds() + seconds);
  return d;
});

export const addMillis = curry((millis: number, date: Date) => {
  return new Date(date.getTime() + millis);
});

export const addTimeOffset = curry((timeOffset: number, date: Date) => {
  return new Date(date.setHours(date.getHours() + timeOffset));
});

export const atStartOfYear = (date: Date) => createDate(getYear(date));
export const atEndOfYear = flow(atStartOfYear, addYears(1), addMillis(-1));

export const atStartOfQuarter = (date: Date) =>
  createDate(getYear(date), quarterToMonth(getQuarter(date)));
export const atEndOfQuarter = flow(
  atStartOfQuarter,
  addMonths(3),
  addMillis(-1)
);

export const atStartOfMonth = (date: Date) =>
  createDate(getYear(date), getMonth(date));
export const atEndOfMonth = flow(atStartOfMonth, addMonths(1), addMillis(-1));

export const atStartOfDay = (date: Date) =>
  createDate(getYear(date), getMonth(date), getDay(date));
export const atEndOfDay = flow(atStartOfDay, addDays(1), addMillis(-1));

export const isInYearMonth = (year: number, month: number, date: Date) =>
  getYear(date) === year && getMonth(date) === month;

export const getDaysInMonth = flow(atEndOfMonth, getDay);

export const getDaysBetween = (a: Date, b: Date) => {
  return Math.abs(Math.floor((a.getTime() - b.getTime()) / ONE_DAY_MILLIS));
};

export const isSameDate = (a: unknown, b: unknown) =>
  a && b && from(a).getTime() === from(b).getTime();

export const isBefore = (candidate: unknown, date: unknown) =>
  from(candidate).getTime() < from(date).getTime();

export const isAfter = (candidate: unknown, date: unknown) =>
  from(candidate).getTime() > from(date).getTime();

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const shortMonthNames = monthNames.map((x) => x.substring(0, 3));

export const dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const shortDayNames = dayNames.map((x) =>
  x.substring(0, 1).toLowerCase()
);

export const getMonthName = (monthNumber: number) =>
  monthNames[monthNumber - 1];
export const getShortMonthName = (monthNumber: number) =>
  shortMonthNames[monthNumber - 1];

export const getDayName = (dayNumber: number) => dayNames[dayNumber - 1];
export const getShortDayName = (dayNumber: number) =>
  shortDayNames[dayNumber - 1];

export const toYearISOString = (date: Date) =>
  date.toISOString().substring(0, 4);
export const toYearMonthISOString = (date: Date) =>
  date.toISOString().substring(0, 7);
export const toQuarterISOString = (date: Date) =>
  `${toYearISOString(date)}-Q${getQuarter(date)}`;
export const toLocalDateISOString = (date: Date) =>
  date.toISOString().substring(0, 10);

export const toLocalDateTimeISOString = (date: Date) =>
  date.toISOString().slice(0, -1);

export const formatShortMonth = (val: unknown) =>
  getShortMonthName(getMonth(from(val)));

export const formatShortYear = (val: unknown) =>
  from(val).toISOString().substring(2, 4);

export const formatDate = (val: unknown) =>
  from(val).toISOString().substring(0, 10);

export const formatTime = (val: unknown) =>
  from(val).toISOString().substring(11, 16);

export const formatTimeWithSeconds = (val: unknown) =>
  from(val).toISOString().substring(11, 19);

export const formatDateTime = (date: unknown) =>
  `${formatDate(date)} ${formatTime(date)}`;
export const formatDateTimeWithSeconds = (date: unknown) =>
  `${formatDate(date)} ${formatTimeWithSeconds(date)}`;
export const formatDateTimeWithUtc = (date: unknown) =>
  `${formatDateTime(date)} UTC`;
export const formatDateTimeWithUtcTimeOffset = (
  date: Date,
  timeOffset: number
) => {
  const dateWithTimeOffset = addTimeOffset(timeOffset, date);
  return `${formatDateTime(dateWithTimeOffset)} ${getTimeOffsetFormat(
    timeOffset
  )}`;
};

export const formatDateWithTimeOffset = (date: unknown, timeOffset: number) => {
  return addHours(timeOffset, from(date)).toISOString();
};

export const removeTimeOffsetFromDate = (date: Date, timeOffset: number) => {
  return addHours(-timeOffset, date);
};

export const formatYear = (val: unknown) =>
  from(val).toISOString().substring(0, 4);

export const formatYearMonth = (val: unknown) =>
  from(val).toISOString().substring(0, 7);

export const formatQuarter = (val: unknown) => {
  const d = from(val);
  return `${getYear(d)} Q${getQuarter(d)}`;
};
export const isoStringToFormattedDateTime = (isoString: string) => {
  if (!isoString) return "";
  return `${isoString.substring(0, 10)} ${isoString.substring(11, 16)}`;
};

export const formatWeek = (date: Date, endDate: Date) => {
  if (!endDate) {
    const daysToAdd = 7 - getDayOfWeek(date);
    endDate = new Date(date);
    endDate.setDate(date.getDate() + daysToAdd);
  }
  return `${getDay(date)} ${getShortMonthName(getMonth(date))} -
  ${getDay(from(endDate))} ${getShortMonthName(getMonth(from(endDate)))}`;
};

export const formatHour = (date: Date) => {
  const currentHour = date.getUTCHours();
  return `${("0" + currentHour).slice(-2)}:00`;
};

/**
 * Returns a string representation of a timspan.
 *
 * @param seconds - Number of seconds in timespan. Does not account for fractional seconds. E.g. input 1.99 will return 1s.
 * @returns String representation of timespan
 */
export const secondsToTimeString = (seconds: number): string => {
  if (!isFinite(seconds)) {
    throw new Error(`input must be finite. Input was ${seconds}`);
  }
  if (seconds < 0) {
    throw new Error(`input cannot be negative. Input was ${seconds}`);
  }

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;

  const d = Math.trunc(seconds / day);
  const h = Math.trunc((seconds % day) / hour);
  const m = Math.trunc((seconds % hour) / minute);
  const s = Math.trunc(seconds % minute);

  const pad = (x: number): string => (x < 10 ? `0${x}` : `${x}`);

  const dString = d ? `${d}d ` : "";
  const hString = d || h ? `${pad(h)}h ` : "";
  const mString = d || h || m ? `${pad(m)}m ` : "";
  const sString = `${pad(s)}s`;

  return `${dString}${hString}${mString}${sString}`;
};

export const secondsToDate = (seconds: number) => {
  return addSeconds(seconds, now());
};

const convertToDate = (input: string | Date): Date => {
  const date = input instanceof Date ? input : new Date(input);
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date input: ${input}`);
  }
  return date;
};

export const getDifferenceInMinutes = (
  date1: Date | string,
  date2: Date | string
) => {
  const firstDate = convertToDate(date1);
  const secondDate = convertToDate(date2);
  const diffInMs = Math.abs(secondDate.getTime() - firstDate.getTime());
  return diffInMs / (1000 * 60);
};

export const getTotalMillisecondsUTC = (date: Date) => {
  return Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
};

export const conformDateRange = (src: Date[] | DateRange): DateRange => {
  if (Array.isArray(src)) {
    // This is a fallback, date ranges as array should be retired when found
    return { from: src[0], to: src[1] };
  }
  return src;
};
