import { FETCH_ENERGYBUDGET_OVERVIEW } from "../actions/action.types";
import { createRequestReducer } from "./createRequestReducer";
import { get, max } from "lodash";
import { getConvertSpec, roundNumber } from "../../common/numbers";
import { getMetricName } from "../components/EnergyBudget/EnergyBudgetOverviewHelper";

export const prepareEnergyBudgetOverviewData = (data) => {
  const result = data.map((item) => {
    const values = get(item, "entries", []);
    const metricUnit = get(item, "metricUnit", "");
    const budgetMax = max(values.map((x) => x.budgetValue));
    const actualMax = max(values.map((x) => x.actualValue));
    const convertSpec = {
      budget: getConvertSpec(budgetMax, metricUnit),
      actual: getConvertSpec(actualMax, metricUnit),
    };

    if (
      convertSpec.actual.unit !== convertSpec.budget.unit &&
      actualMax !== 0 &&
      budgetMax !== 0
    ) {
      return {
        metricName: getMetricName(item.metricName),
        metricUnit,
        values,
      };
    }

    return {
      metricName: getMetricName(item.metricName),
      metricUnit:
        actualMax !== 0 ? convertSpec.actual.unit : convertSpec.budget.unit,
      values: values.map((x) => {
        return {
          ...x,
          budgetValue: roundNumber(
            x.budgetValue / convertSpec.budget.conversion,
            2
          ),
          actualValue: roundNumber(
            x.actualValue / convertSpec.actual.conversion,
            2
          ),
        };
      }),
    };
  });

  return {
    fuelConsumptions: result,
    groupBy: get(data[0], "groupBy", "month"),
  };
};

export default createRequestReducer(FETCH_ENERGYBUDGET_OVERVIEW, {
  prepareData: prepareEnergyBudgetOverviewData,
});
