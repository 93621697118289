import styles from "./Legends.css";
import t from "prop-types";
import React from "react";

export default function Legend(props) {
  const style = props.style || {};
  style.backgroundColor = props.color;
  if (props.textColor) {
    style.color = props.textColor;
  }

  return (
    <button
      data-test-id={props.testId}
      style={style}
      onClick={props.onClick}
      className={`${styles.legendButton} ${styles.legend} ${
        props.selected ? "" : styles.legendButtonNotSelected
      }`}
      title={`${props.category} - ${props.profile}`}
    >
      <span>{props.legend}</span>
    </button>
  );
}

Legend.propTypes = {
  category: t.string.isRequired,
  legend: t.string.isRequired,
  profile: t.string.isRequired,
  color: t.string.isRequired,
  textColor: t.string,
  selected: t.bool.isRequired,
  onClick: t.func.isRequired,
  testId: t.string,
};
