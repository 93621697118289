import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import Button from "../../../../../common/components/Button";
import {
  inputChanged,
  dashboardChanged,
  save,
  download,
  deploy,
  vesselImageChanged,
  vesselImageChangedError,
} from "../../../../actions/admin/action.vesselconfig.onboardFrontendConfig";
import { noop } from "lodash";
import Loader from "../../../../../common/components/Loader";
import { formatDateTime } from "../../../../common/dates";
import {
  prepState,
  prepSave,
} from "../../../../reducers/admin/vesselConfig/reducer.onboardFrontendConfig";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import styles from "./OnboardFrontendConfigController.css";
import {
  FormCheckBox,
  FormSelectBox,
  FormImageUpload,
} from "../../../../components/Form";
import VesselImagePreviewPopup from "./VesselImagePreviewPopup";

class OnboardFrontendConfigController extends React.PureComponent {
  handleVesselImageChanged = (name, file) => {
    const errors = [];
    const oneMegabyte = 1048576;
    if (file) {
      if (!file.type.match("image/png")) {
        errors.push("The image must be a file of type png");
      }
      if (file.size > oneMegabyte) {
        errors.push("The file is too large. Allowed maximum size is 1 MB");
      }
    }

    if (!errors.length) {
      this.props.vesselImageChanged(name, file);
    }
    this.props.vesselImageChangedError(errors);
  };

  handleSave = async () => {
    const prepared = await prepSave(
      this.props.fields,
      this.props.dashboard,
      this.props.vesselImage
    );
    this.props.save(prepared);
  };

  renderContent() {
    if (this.props.isLoading || this.props.error) {
      return (
        <div className={styles.contentContainer}>
          <Loader expand={true} error={this.props.error} />
        </div>
      );
    }

    const canPreview =
      !this.props.vesselImageErrors?.length &&
      (this.props.vesselImage.file || this.props.vesselImage.src);

    return (
      <div className={styles.contentContainer}>
        <div className={styles.menuConfigContainer}>
          <h1 className={styles.heading}>Pages</h1>
          {this.props.fields.map((f) => (
            <div className={styles.menuConfigItem} key={f.name}>
              <FormCheckBox
                value={f.value}
                label={f.label}
                name={f.name}
                iconPosition="left"
                onChange={this.props.inputChanged}
              />
            </div>
          ))}
        </div>
        <div className={styles.menuConfigContainer}>
          <h1 className={styles.heading}>Dashboard</h1>
          {this.props.dashboard.map((row, i) =>
            row.map((component, j) => {
              if (component.value === "operation") {
                return (
                  <div className={styles.componentSelect} key={`${i}${j}`}>
                    <span className={styles.operation}>Operation</span>
                  </div>
                );
              } else {
                return (
                  <div className={styles.componentSelect} key={`${i}${j}`}>
                    <FormSelectBox
                      name={""}
                      label={""}
                      options={this.props.componentLabels}
                      optionLabelKey={"label"}
                      optionValKey={"name"}
                      selected={component}
                      onChange={this.props.dashboardChanged.bind(this, i, j)}
                    />
                  </div>
                );
              }
            })
          )}
        </div>
        <div className={styles.menuConfigContainer}>
          <h1 className={styles.heading}>Vessel Image</h1>
          <div className={styles.formImageUploadContainerChild}>
            <FormImageUpload
              src={this.props.vesselImage.src}
              fileName={this.props.vesselImage.name}
              name={"vesseloverview"}
              onChange={this.handleVesselImageChanged}
            />
            {this.props.vesselImageErrors?.map((error) => (
              <h3 key={error} className={styles.error}>
                {error}
              </h3>
            ))}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <VesselImagePreviewPopup canPreview={canPreview} />
          <Button
            value="Save"
            clickAction={this.handleSave}
            disabled={!this.props.canSave}
          />
          {Boolean(this.props.savedOn) && (
            <span>Last saved {formatDateTime(this.props.savedOn)}</span>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            value="Download"
            clickAction={this.props.download}
            disabled={!this.props.canDownload}
          />
          <Button
            value="Deploy"
            clickAction={this.props.deploy}
            disabled={!this.props.canDeploy}
          />
          {Boolean(this.props.deployedOn) && (
            <span>Last deployed {formatDateTime(this.props.deployedOn)}</span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, enabled, onToggleClick } = this.props;

    return (
      <CollapsePanel
        title={"Onboard Frontend Config"}
        subTitle={"Toggle available menu items for onboard portal."}
        isOpen={isOpen}
        testId={"onboardFrontendConfigController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
      >
        {this.renderContent()}
      </CollapsePanel>
    );
  }
}

OnboardFrontendConfigController.defaultProps = {
  isOpen: false,
  enabled: false,
  onToggleClick: noop,
  fields: [],
  dashboard: [],
  vesselImage: {},
};

OnboardFrontendConfigController.propTypes = {
  isOpen: PropTypes.bool,
  enabled: PropTypes.bool,
  onToggleClick: PropTypes.func,
  isLoading: PropTypes.bool,
  canSave: PropTypes.bool,
  canDownload: PropTypes.bool,
  canDeploy: PropTypes.bool,
  savedOn: PropTypes.string,
  deployedOn: PropTypes.string,
  error: PropTypes.any,
  fields: PropTypes.array,
  inputChanged: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  download: PropTypes.func.isRequired,
  deploy: PropTypes.func.isRequired,
};

const mapStateToProps = createSelector(
  (state) => state.admin.onboardFrontendConfig,
  prepState
);

const mapDispatchToProps = {
  inputChanged,
  save,
  download,
  deploy,
  dashboardChanged,
  vesselImageChanged,
  vesselImageChangedError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardFrontendConfigController);
