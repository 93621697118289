import { FuelType } from "../onboard/models/FuelType";
import {
  OperationId,
  operationLegend,
  operationName,
} from "./operation/operationId";

/**
 * Unit enums as identified with integers backend
 */
export enum CurrencyUnits {
  Nok = 0,
  Usd = 1,
  Cad = 2,
  Gbp = 3,
  Eur = 4,
}
export enum SpeedUnits {
  MetersPerSecond = 0,
  KilometersPerHour = 1,
  Knots = 2,
}

export enum WindSpeedUnits {
  MeterPerSecond = SpeedUnits.MetersPerSecond,
  Knot = SpeedUnits.Knots,
}

export enum PowerUnits {
  KiloWatts = 0,
  BreakHorsePower = 2,
}

export enum FuelUnits {
  Liters = 0,
  Kilograms = 1,
}

export enum PropellerLocations {
  None = 0,
  Starboard = 1,
  Port = 2,
  Center = 3,
}

export enum WaveRadarTypes {
  None = 0,
  XBand = 1,
  SBand = 2,
}

export enum BatteryOperationMode {
  Charging = 1,
  Harbour = 2,
  Steaming = 3,
  PeakShaving = 4,
  Discharging = 5,
}

export enum TrendSignalCategory {
  Hull = 0,
  Propulsion = 1,
  Engine = 2,
  Battery = 3,
}

export enum TrendSignalUnit {
  Degrees = 0,
  Knots = 1,
  KilometersPerHour = 2,
  Meters = 3,
  MetersPerSecond = 4,
  Kilowatts = 5,
  RevolutionsPerMinute = 6,
  KilogramsPerHour = 7,
  LitersPerHour = 8,
  Percent = 9,
}

/**
 * Internal type to map
 */
type LabelMapping = {
  currencyUnit: CurrencyUnits;
  speedUnit: SpeedUnits;
  windUnit: WindSpeedUnits;
  powerUnit: PowerUnits;
  fuelConsumptionPerDistance: FuelUnits;
  fuelConsumptionPerTime: FuelUnits;
  fuelUnit: FuelUnits;
  fuelType: FuelType;
  propellerLocation: PropellerLocations;
  waveRadarType: WaveRadarTypes;
  batteryOperation: BatteryOperationMode;
  operationLegend: OperationId;
  operationName: OperationId;
  trendSignalCategory: TrendSignalCategory;
  trendSignalUnit: TrendSignalUnit;
};

/**
 * Constants for the repeated units
 */
const metersPerSecond = "m/s";
const kilometersPerHour = "km/h";
const knots = "kn";
const kilowatts = "kW";

/**
 * Lookup table of rendered units
 */
const unitLabels: {
  [ut in keyof LabelMapping]: Record<LabelMapping[ut], string>;
} = {
  currencyUnit: {
    [CurrencyUnits.Nok]: "NOK",
    [CurrencyUnits.Usd]: "USD",
    [CurrencyUnits.Cad]: "CAD",
    [CurrencyUnits.Gbp]: "GBP",
    [CurrencyUnits.Eur]: "EUR",
  },
  speedUnit: {
    [SpeedUnits.MetersPerSecond]: metersPerSecond,
    [SpeedUnits.KilometersPerHour]: kilometersPerHour,
    [SpeedUnits.Knots]: knots,
  },
  windUnit: {
    [WindSpeedUnits.MeterPerSecond]: metersPerSecond,
    [WindSpeedUnits.Knot]: knots,
  },
  powerUnit: {
    [PowerUnits.BreakHorsePower]: "bhp",
    [PowerUnits.KiloWatts]: kilowatts,
  },
  fuelConsumptionPerDistance: {
    [FuelUnits.Liters]: "L/nm",
    [FuelUnits.Kilograms]: "Kg/nm",
  },
  fuelConsumptionPerTime: {
    [FuelUnits.Liters]: "L/h",
    [FuelUnits.Kilograms]: "Kg/h",
  },
  fuelUnit: {
    [FuelUnits.Liters]: "L",
    [FuelUnits.Kilograms]: "kg",
  },
  fuelType: {
    [FuelType.MDO]: "MDO",
    [FuelType.LNG]: "LNG",
    [FuelType.HFO]: "HFO",
    [FuelType.MGO]: "MGO",
    [FuelType.Methanol]: "Methanol",
    [FuelType.ULSFO]: "ULSFO",
    [FuelType.VLSFO]: "VLSFO",
  },
  propellerLocation: {
    [PropellerLocations.None]: "None",
    [PropellerLocations.Starboard]: "Starboard",
    [PropellerLocations.Port]: "Port",
    [PropellerLocations.Center]: "Center",
  },
  waveRadarType: {
    [WaveRadarTypes.None]: "-",
    [WaveRadarTypes.XBand]: "X-Band",
    [WaveRadarTypes.SBand]: "S-Band",
  },
  batteryOperation: {
    [BatteryOperationMode.Charging]: "Charging",
    [BatteryOperationMode.Harbour]: "Harbour",
    [BatteryOperationMode.Steaming]: "Steaming",
    [BatteryOperationMode.PeakShaving]: "Peak Shaving",
    [BatteryOperationMode.Discharging]: "Discharging",
  },
  operationLegend: operationLegend,
  operationName: operationName,
  trendSignalCategory: {
    [TrendSignalCategory.Hull]: "Hull",
    [TrendSignalCategory.Propulsion]: "Propulsion",
    [TrendSignalCategory.Engine]: "Engines",
    [TrendSignalCategory.Battery]: "Batteries",
  },
  trendSignalUnit: {
    [TrendSignalUnit.Degrees]: "degrees",
    [TrendSignalUnit.Knots]: "knots",
    [TrendSignalUnit.KilometersPerHour]: kilometersPerHour,
    [TrendSignalUnit.Meters]: "m",
    [TrendSignalUnit.MetersPerSecond]: metersPerSecond,
    [TrendSignalUnit.Kilowatts]: kilowatts,
    [TrendSignalUnit.RevolutionsPerMinute]: "rpm",
    [TrendSignalUnit.KilogramsPerHour]: "k/h",
    [TrendSignalUnit.LitersPerHour]: "L/h",
    [TrendSignalUnit.Percent]: "%",
  },
};

/**
 * Internally used method to pick value from object w/fallback string
 * @param units
 */
const labelResolver =
  <T extends Record<string, string>>(units: T) =>
  (target: keyof T): string => {
    return units[target] ?? "[missing label]";
  };

/**
 * Get a readable unit from a numerical id
 *
 * @param units
 * @see unitLabels
 */
export const resolveLabel: {
  [key in keyof typeof unitLabels]: (unit: LabelMapping[key]) => string;
} = Object.entries(unitLabels).reduce(
  (acc, [unitType, units]) => ({
    ...acc,
    [unitType]: labelResolver(units),
  }),
  {} as {
    [key in keyof typeof unitLabels]: (unit: LabelMapping[key]) => string;
  }
);
