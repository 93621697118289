import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../components/Header/Header";
import {
  isHmProductExpert,
  isSystemAdmin,
} from "../../../../reducers/reducer.user";
import styles from "./HealthManagementEventEdit.css";
import HealthManagementEventEditDetails from "./HealthManagementEventEditDetails";
import HealthManagementEventEditData from "./HealthManagementEventEditData";
import {
  fetchEvent,
  fetchEventDetails,
  fetchStandardTexts,
  updateEventField,
  approveEvent,
  closeError,
} from "../../../../actions/admin/action.healthManagemenEvent";
import Button from "../../../../../common/components/Button/Button";
import Loader from "../../../../../common/components/Loader/Loader";
import { Portal } from "react-portal";
import Popup from "../../../../components/Popup/Popup";
import { useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";

const HealthManagementEventEdit = () => {
  // Get eventId from URL
  const { eventId } = useParams();

  // Get data from store
  const canEdit = useSelector((state) => getCanEdit(state));
  const event = useSelector((state) => state.admin.healthManagementEvent.event);
  const eventDetails = useSelector(
    (state) => state.admin.healthManagementEvent.eventDetails
  );
  const standardTexts = useSelector(
    (state) => state.admin.healthManagementEvent.standardTexts
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onAllEvents = () => {
    navigate("/admin/healthmanagement/");
  };
  const onSave = () => {
    dispatch(approveEvent());
  };
  const onFieldChange = (field, value) => {
    dispatch(updateEventField(field, value));
  };
  const onHide = () => {
    dispatch(closeError());
  };

  useEffect(() => {
    dispatch(fetchEvent(eventId));
    dispatch(fetchEventDetails(eventId));
    dispatch(fetchStandardTexts(eventId));
  }, [eventId]);

  return (
    <div>
      <Header
        title={"Admin - Health Management Event Approval"}
        contentDistribution={"flex-end"}
      >
        <Button
          value={"All events"}
          clickAction={onAllEvents}
          margin={"0 8px"}
        />
        {canEdit && (
          <Button
            value={"Save"}
            clickAction={onSave}
            disabled={!event.hasChanges}
            margin={"0 8px"}
          />
        )}
      </Header>
      <div className={styles.container}>
        <HealthManagementEventEditDetails
          event={event}
          onFieldChange={onFieldChange}
          canEdit={canEdit}
          standardTexts={standardTexts.data}
        />
        <HealthManagementEventEditData eventDetails={eventDetails} />
      </div>
      {event.isSaving && (
        <Portal node={document?.getElementById("popupContainer")}>
          <div className={styles.savingContainer}>
            <Loader expand={true} text={"Saving"} />
          </div>
        </Portal>
      )}
      <Popup title={"Error"} visible={Boolean(event.error)} onHide={onHide}>
        <div className={styles.errorContainer}>
          <p>{event.error}</p>
        </div>
      </Popup>
    </div>
  );
};

const getCanEdit = createSelector(
  (state) => state.user,
  (user) => isSystemAdmin(user) || isHmProductExpert(user)
);

export default HealthManagementEventEdit;
