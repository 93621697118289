import React from "react";
import PropTypes from "prop-types";
import styles from "./CollapsePanel.css";
import Button from "../../../../../common/components/Button";
import { Icon } from "../../../../../common/components/Icon/Icon";
import { noop, isEmpty, orderBy } from "lodash";
import classNames from "../../../../../common/classNames";
import { FormSelectBox } from "../../../../components/Form";

export default class CollapsePanel extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      title,
      subTitle,
      icon,
      isOpen,
      enabled,
      onToggleClick,
      children,
      testId,
      buttons,
      isEdit,
      vessels,
      copyFromVesselChanged,
      copyFromVessel,
    } = this.props;
    return (
      <div
        className={classNames(
          styles.container,
          enabled !== true ? styles.disabled : ""
        )}
        data-testid={testId}
      >
        <div
          className={classNames(
            styles.headerContainer,
            enabled ? styles.headerContainerClickable : ""
          )}
          onClick={enabled ? onToggleClick : undefined}
        >
          <div className={styles.iconContainer}>
            {icon && <Icon icon={icon} size={"l"} />}
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{title}</div>
            {!isEmpty(subTitle) && (
              <div className={styles.subTitle}>{subTitle}</div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <div
              className={styles.selectBoxContainer}
              onClick={(e) => e.stopPropagation()}
            >
              {isOpen && isEdit && (
                <FormSelectBox
                  label="Copy config from"
                  name="vessels"
                  labelPosition={"left"}
                  options={orderBy(vessels, ["name"]) || []}
                  optionValKey={"id"}
                  optionLabelKey={"name"}
                  onChange={copyFromVesselChanged}
                  style={{ marginBottom: "0px" }}
                  selected={copyFromVessel}
                />
              )}
            </div>
            {actionButtons(buttons)}
          </div>
          <div className={styles.toggleContainer}>
            <Button
              disabled={!enabled}
              type={"content"}
              width={"48px"}
              clickAction={onToggleClick}
              testId="toggle-panel-button"
            >
              {buttonIcon(isOpen)}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: isOpen && enabled ? "block" : "none",
          }}
          className={styles.contentContainer}
        >
          {children}
        </div>
      </div>
    );
  }
}

CollapsePanel.defaultProps = {
  enabled: true,
  onToggleClick: noop,
  buttons: [],
};

CollapsePanel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  enabled: PropTypes.bool,
  onToggleClick: PropTypes.func,
  testId: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      isVisible: PropTypes.bool,
      type: PropTypes.oneOf(["button", "alert"]),
    })
  ),
};

const actionButtons = (buttons) => {
  return (
    buttons &&
    buttons.map((b, i) => {
      let label = b.label;

      if (typeof label === "function") {
        label = label();
      }
      return (
        b.isVisible && (
          <div
            key={`panelButton_${i}`}
            data-testid={label}
            className={styles.button}
          >
            <Button
              type={b.type}
              value={label}
              className={styles.button}
              disabled={b.disabled}
              clickAction={(e) => {
                e.stopPropagation();
                b.action();
              }}
            />
          </div>
        )
      );
    })
  );
};

const buttonIcon = (isOpen) => (
  <div>
    <svg
      viewBox="0 0 12.7 12.7"
      preserveAspectRatio="xMidYMid meet"
      xmlSpace="preserve"
      height="26px"
      width="26px"
    >
      <g className={classNames(styles.rotationGroup, isOpen && styles.isOpen)}>
        <g transform="translate(0,-284.3)" id="layer1">
          <path
            style={{ stroke: "none", fill: "#ffffff" }}
            d="m 6.370148,286.65348 c -2.1034724,2.10937 -4.2069446,4.21875 -6.31041694,6.32812 0.50866149,0.52046 1.01732294,1.04092 1.52598434,1.56137 1.5948108,-1.5994 3.1896217,-3.1988 4.7844326,-4.7982 1.5948109,1.5994 3.1896218,3.1988 4.784433,4.7982 0.508661,-0.52045 1.017322,-1.04091 1.525983,-1.56137 -2.103472,-2.10937 -4.2069439,-4.21875 -6.310416,-6.32812 z"
          />
        </g>
      </g>
    </svg>
  </div>
);
