const rootPosition = { left: 0, top: 0 };

const mouseEventOffset = (ev, target, out) => {
  target = target || ev.currentTarget || ev.srcElement;
  if (!Array.isArray(out)) {
    out = [0, 0];
  }
  let cx =
    ev.type === "touchmove" && ev.touches && ev.touches.length
      ? ev.touches[0].clientX
      : ev.clientX || 0;
  let cy =
    ev.type === "touchmove" && ev.touches && ev.touches.length
      ? ev.touches[0].clientY
      : ev.clientY || 0;
  let rect = getBoundingClientOffset(target);
  out[0] = cx - rect.left;
  out[1] = cy - rect.top;
  return out;
};

const getBoundingClientOffset = (element) => {
  if (element === window || element === document || element === document.body) {
    return rootPosition;
  } else {
    return element.getBoundingClientRect();
  }
};

export default mouseEventOffset;
