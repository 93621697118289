import t from "prop-types";
import React from "react";
import { connect } from "react-redux";
import styles from "./Header.css";
import Title from "./Title/Title";
import Logo from "../../../common/components/Logo/Logo";
import { toggleNav } from "../../actions/action.toggleNav";
import classNames from "../../../common/classNames";

function contentDistribution(prop) {
  switch (prop) {
    case "start":
      return { justifyContent: "flex-start" };
    case "end":
      return { justifyContent: "flex-end" };
    case "center":
      return { justifyContent: "center" };
    case "space-between":
      return { justifyContent: "space-between" };
    case "space-around":
      return { justifyContent: "space-around" };
    default:
      return { justifyContent: "flex-end" };
  }
}

function getHeaderPosition(props) {
  if (props.fixed) {
    return styles.fixed;
  }
  if (props.sticky) {
    return styles.sticky;
  }
  return "";
}

function Header(props) {
  return (
    <header className={classNames(styles.header, getHeaderPosition(props))}>
      <div
        className={styles.navTrigger}
        onClick={props.toggleNav}
        data-test-id="navTrigger"
      >
        <Logo size="medium" />
        <Title title={props.title} />
      </div>
      <div
        className={styles.headerContent}
        style={contentDistribution(props.contentDistribution)}
      >
        {props.user.companyImage && (
          <img src={props.user.companyImage} className={styles.logo} />
        )}
        {props.children}
      </div>
    </header>
  );
}

Header.propTypes = {
  title: t.string.isRequired,
  children: t.oneOfType([t.object, t.array]),
  contentDistribution: t.string,
  fixed: t.bool,
  toggleNav: t.func.isRequired,
  sticky: t.bool,
};

export default connect((state) => ({ user: state.user }), { toggleNav })(
  Header
);
