import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";

const actionTypes = vesselConfig.baseline;

const initialState = {
  list: {},
  upload: {},
  download: {},
};

export default createReducer(initialState, {
  [actionTypes.INITIALIZE]: (_state, { vesselId }) => ({
    ...initialState,
    vesselId,
  }),
  [actionTypes.FETCH]: (state) => ({
    ...state,
    list: { isLoading: true },
  }),
  [actionTypes.FETCH_SUCCESS]: (state, { data }) => ({
    ...state,
    list: { data },
  }),
  [actionTypes.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    list: { error },
  }),
  [actionTypes.SHOW_UPLOAD_FORM]: (state) => ({
    ...state,
    upload: { ...state.upload, isVisible: true },
  }),
  [actionTypes.SET_BASELINE_TXT]: (state, { value }) => ({
    ...state,
    upload: {
      ...state.upload,
      baselineTxt: value,
    },
  }),
  [actionTypes.UPLOAD]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      isLoading: true,
    },
  }),
  [actionTypes.UPLOAD_SUCCESS]: (state, { data }) => {
    const updatedEntries = [data, ...state.list.data];
    return {
      ...state,
      upload: initialState.upload,
      list: { data: updatedEntries },
    };
  },
  [actionTypes.UPLOAD_ERROR]: (state, { error }) => ({
    ...state,
    upload: {
      ...state.upload,
      isLoading: false,
      error,
    },
  }),
});

export function prepUploadState({ vesselId, upload }) {
  const { isVisible, isLoading, error, baselineTxt } = upload;
  return {
    isVisible,
    isLoading,
    error,
    actions: {
      show: { type: actionTypes.SHOW_UPLOAD_FORM },
      setBaselineTxt: { type: actionTypes.SET_BASELINE_TXT },
      upload: {
        type: actionTypes.UPLOAD_REQUESTED,
        vesselId,
        baselineTxt,
      },
    },
  };
}

function prepListEntry(download, entry) {
  return {
    id: entry.id,
    version: entry.version,
    isDownloading: download && download.id === entry.id,
    downloadError: download && download.id === entry.id && download.error,
    download: {
      type: vesselConfig.baseline.DOWNLOAD,
      version: entry.version,
      baselineTxt: entry.baselineTxt,
    },
  };
}

export function prepListState({ download, list }) {
  const { isLoading, data, error } = list || {};
  const showLoader = Boolean(isLoading || error);
  const entries = data || [];
  return {
    showLoader,
    showNoDataMsg: !showLoader && !entries.length,
    error: error,
    entries: entries.map((entry) => prepListEntry(download, entry)),
  };
}
