import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";
import classNames from "../../../../common/classNames";
import { formatNumber } from "../../../../common/numbers";
import { shortMonthNames } from "../../../common/dates";
import { FormTextBox } from "../../../components/Form";
import styles from "./MonthsForm.css";

export default class MonthsForm extends React.Component {
  render() {
    const { unit, inputs, calculateTotal, onChange, onBlur } = this.props;

    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <th />
            {shortMonthNames.map((monthName) => (
              <th key={monthName}>
                <span className={styles.heading}>{monthName}</span>
              </th>
            ))}
            <th>
              <span className={classNames(styles.heading, styles.total)}>
                Total
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {inputs.map((row, i) => (
            <tr key={i}>
              <th>
                <span className={styles.rowHeading}>{row[0].year}</span>
              </th>
              {row.map(({ value, isChanged }, j) => (
                <td key={j}>
                  <FormTextBox
                    name={`${i}-${j}`}
                    type="number"
                    value={value}
                    isChanged={isChanged}
                    onChange={(_, updatedValue, isValid) =>
                      onChange({
                        rowIndex: i,
                        colIndex: j,
                        value: updatedValue,
                        isValid,
                      })
                    }
                    onBlur={() => onBlur({ rowIndex: i, colIndex: j })}
                    border={compact([
                      "top",
                      "bottom",
                      j === 0 && "left",
                      j < row.length - 1 ? "rightNoRadius" : "right",
                    ])}
                    width="auto"
                    margin="0 0 1rem 0"
                    textAlign="right"
                  />
                </td>
              ))}
              <td>
                <span className={styles.total}>
                  {formatNumber(calculateTotal(row))} {unit}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

MonthsForm.propTypes = {
  unit: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        year: PropTypes.number.isRequired,
        value: PropTypes.any.isRequired,
        isChanged: PropTypes.bool,
      })
    )
  ),
  calculateTotal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};
