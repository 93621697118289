import { admin } from "../../actions/action.types";
import createReducer from "../createReducer";
import { sortItems } from "../../common/sort";

const initialState = {
  applicationVersions: [],
  vessels: [],
  hasError: false,
  isLoading: true,
  isUpdating: false,
  isVersionListLoading: true,
  selectedCompanyId: null,
  showVesselInsightVessels: false,
};

export default createReducer(initialState, {
  [admin.devOps.FETCH]: (state) => ({
    ...state,
    isLoading: state.vessels.length === 0,
    isUpdating: state.vessels.length > 0,
  }),

  [admin.devOps.FETCH_SUCCESS]: (state, { data }) => ({
    ...state,
    vessels: sortItems(state, data),
    isLoading: false,
    isUpdating: false,
  }),

  [admin.devOps.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    isUpdating: false,
    hasError: true,
    error,
  }),
  [admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD_SUCCESS]: (state, { data }) => ({
    ...state,
    minimumProcessorBuild: data,
  }),
  [admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD_ERROR]: (state, { error }) => ({
    ...state,
    hasError: true,
    isLoading: false,
    error,
  }),
  [admin.devOps.application.version.FETCH]: (state) => ({
    ...state,
    isVersionListLoading: state.applicationVersions.length === 0,
    isUpdating: state.applicationVersions.length > 0,
  }),

  [admin.devOps.application.version.FETCH_SUCCESS]: (state, { data }) => ({
    ...state,
    isVersionListLoading: false,
    isUpdating: false,
    applicationVersions:
      data &&
      data.map((x) => {
        return {
          name: x.name,
          version: x.version,
        };
      }),
  }),

  [admin.devOps.application.version.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    isVersionListLoading: false,
    isUpdating: false,
    hasError: true,
    error,
  }),
  [admin.devOps.SORT_VESSELS]: (state, { sortBy, sortDirection }) => ({
    ...state,
    sortBy,
    sortDirection,
    vessels: sortItems({ sortBy, sortDirection }, state.vessels),
  }),
  [admin.devOps.SET_DEVOPS_COMPANY_ID]: (state, { companyId }) => ({
    ...state,
    selectedCompanyId: companyId,
    vessels: [],
  }),
  [admin.devOps.TOGGLE_VESSEL_INSIGHT_VESSELS]: (
    state,
    { showVesselInsightVessels }
  ) => ({
    ...state,
    showVesselInsightVessels,
  }),
});
