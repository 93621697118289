import {
  call,
  fork,
  getContext,
  put,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import download from "downloadjs";

const actions = vesselConfig.baseline;
const api = vesselConfigApi.baseline;

export function* initBaseline({ vesselId }) {
  yield put({ type: actions.INITIALIZE, vesselId });
  yield fork(uploadWatcher);
  yield fork(downloadWatcher);
  yield call(fetchBaselines, { vesselId });
}

function* fetchBaselines({ vesselId }) {
  const http = yield getContext("http");
  yield put({ type: actions.FETCH, vesselId });
  try {
    const res = yield call(http.get, api.getBaselines(vesselId));
    yield put({ type: actions.FETCH_SUCCESS, vesselId, data: res.data });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, vesselId, error });
  }
}

function* uploadWatcher() {
  yield takeLeading(actions.UPLOAD_REQUESTED, uploadBaseline);
}

function* uploadBaseline({ vesselId, baselineTxt }) {
  if (!baselineTxt) {
    return;
  }

  const http = yield getContext("http");
  yield put({ type: actions.UPLOAD });
  try {
    const payload = new FormData();
    payload.append("baselineTxt", baselineTxt);
    const res = yield call(http.post, api.postBaseline(vesselId), payload);
    yield put({ type: actions.UPLOAD_SUCCESS, data: res.data });
  } catch (error) {
    yield put({ type: actions.UPLOAD_ERROR, error });
  }
}

function* downloadWatcher() {
  yield takeLatest(actions.DOWNLOAD, downloadBaseline);
}

function* downloadBaseline({ version, baselineTxt }) {
  yield call(download, baselineTxt, `baseline-${version}.txt`, "text/plain");
}
