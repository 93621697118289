import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./DataTable.css";
import { Icon } from "../../../common/components/Icon/Icon";
import { InlineSpinner } from "../../../common/components/Loader/Loader";
import { get } from "lodash";

export default class DataTable extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  renderHeaderColumn(col) {
    return <th key={col.header}>{col.header}</th>;
  }

  renderHeader(columns) {
    return (
      <thead>
        <tr>
          {this.props.canEdit && <th className={styles.actionCell} />}
          {columns.map((x) => this.renderHeaderColumn(x))}
        </tr>
      </thead>
    );
  }

  renderBody(columns, data) {
    return <tbody>{data.map((row) => this.renderRow(columns, row))}</tbody>;
  }

  renderRow(columns, row) {
    const { form } = row;

    const showEdit = form && form.isVisible;
    const isLoading = get(form, "isLoading", false);
    const rowId = this.props.getRowId ? this.props.getRowId(row) : row.id;

    const editRow = showEdit && (
      <tr className={styles.editRow}>
        <td className={styles.inlineEdit} colSpan={columns.length + 1}>
          {this.props.editTemplate(row, rowId)}
        </td>
      </tr>
    );
    const displayRow = rowId && (
      <tr className={showEdit ? styles.selectedRow : undefined}>
        {this.renderEditButton(row, rowId, isLoading)}
        {columns.map((c) => {
          return (
            <td
              key={c.header}
              className={isLoading ? styles.disabledRow : undefined}
            >
              {row[c.field]}
            </td>
          );
        })}
      </tr>
    );
    return (
      <Fragment key={"k_" + rowId}>
        {displayRow}
        {editRow}
      </Fragment>
    );
  }

  renderEditButton(row, rowId, isLoading) {
    return (
      this.props.canEdit && (
        <td className={styles.actionCell} key={"editbutton_cell_" + rowId}>
          {isLoading && <InlineSpinner />}
          {!isLoading && (
            <button
              className={styles.editButton}
              onClick={(e) => {
                this.props.onEdit(row);
                e.preventDefault();
              }}
            >
              <Icon icon={"edit-row"} size={"ms"} />
            </button>
          )}
        </td>
      )
    );
  }

  render() {
    const { columns, data } = this.props;
    return (
      <div>
        <table data-testid={this.props.testId} className={styles.datatable}>
          {this.renderHeader(columns)}
          {this.renderBody(columns, data)}
        </table>
      </div>
    );
  }
}

DataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  editTemplate: PropTypes.func,
  getRowId: PropTypes.func,
  testId: PropTypes.string,
};
