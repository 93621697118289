import React from "react";
import styles from "../../FuelConsumption/FuelConsumption.css";

const Points = ({ data }) => {
  const points = (pointsData) => {
    return pointsData.map((d, i) => {
      return <circle key={`point-${i}`} cx={d.x} cy={d.y} r="4" />;
    });
  };

  return <g className={styles.points}>{points(data)}</g>;
};

Points.propTypes = {};

export default Points;
