import React from "react";
import { connect } from "react-redux";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import styles from "./EnergyFlowConfigController.css";
import {
  getPanelSize,
  convertCoordinateToTopLeft,
} from "../../../../reducers/admin/vesselConfig/reducer.energyFlow";
import { FormSelectBox } from "../../../../components/Form";
import Button from "../../../../../common/components/Button";
import {
  vesselItemSelected,
  openNew,
  closeNew,
  addNew,
  save,
  addRelation,
  deleteRelation,
  updateNodePosition,
  removeNode,
  reset,
} from "../../../../actions/admin/action.vesselconfig.energyflow";
import NodeRelation from "../../../../components/NodeRelation/NodeRelation";
import AutoSizer from "../../../../../common/components/AutoSizer/AutoSizer";
import Loader from "../../../../../common/components/Loader";
import { get } from "lodash";

const EnergyFlowAdd = (props) => {
  const { isOpen, items, selectedVesselItem, onSelected, onAdd, onClose } =
    props;

  return (
    isOpen && (
      <div className={styles.form}>
        <FormSelectBox
          name="vesselitem"
          label="Vessel Item"
          options={items}
          optionValKey="id"
          optionLabelKey="name"
          selected={selectedVesselItem}
          onChange={onSelected}
          testId="flowItemSelect"
        />

        <div className={styles.buttonContainer}>
          <Button value="Add" clickAction={onAdd} />
          <Button value="Close" clickAction={onClose} />
        </div>
      </div>
    )
  );
};

class EnergyFlowConfigController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleVesselItemSelected = this.handleVesselItemSelected.bind(this);
    this.handleVesselItemAdd = this.handleVesselItemAdd.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRelationAdded = this.handleRelationAdded.bind(this);
    this.handleRelationRemoved = this.handleRelationRemoved.bind(this);
    this.handleNodeDrop = this.handleNodeDrop.bind(this);
    this.handleNodeRemoved = this.handleNodeRemoved.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleVesselItemSelected(_name, value) {
    this.props.vesselItemSelected(value);
  }
  handleVesselItemAdd() {
    this.props.addNew(this.props.selectedVesselItem);
  }
  handleSave() {
    this.props.save(this.props.layout, this.props.relations);
  }
  handleRelationAdded(relation) {
    this.props.addRelation(relation);
  }
  handleRelationRemoved(relation) {
    this.props.deleteRelation(relation);
  }
  handleNodeDrop(id, x, y) {
    this.props.updateNodePosition(id, { x, y });
  }
  handleNodeRemoved(id) {
    this.props.removeNode(id);
  }
  handleReset() {
    this.props.reset(this.props.vesselId);
  }

  renderContent() {
    const { layout, relations, panelHeight, showLoader, error } = this.props;

    if (showLoader) {
      return (
        <div className={styles.contentContainer}>
          <Loader error={error} expand={true} resetAction={this.handleReset} />
        </div>
      );
    }
    return (
      <div className={styles.contentContainer}>
        <div className={styles.content} style={{ height: panelHeight + "px" }}>
          <AutoSizer>
            {({ width }) => {
              const mappedLayout = layout.map(
                convertCoordinateToTopLeft(width, panelHeight)
              );

              return (
                <NodeRelation
                  nodes={mappedLayout}
                  relations={relations}
                  width={width}
                  height={panelHeight}
                  onRelationAdded={this.handleRelationAdded}
                  onRelationRemoved={this.handleRelationRemoved}
                  onNodeDrop={this.handleNodeDrop}
                  onNodeRemoved={this.handleNodeRemoved}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }

  render() {
    const {
      isOpen,
      enabled,
      onToggleClick,
      isFormOpen,
      hasChanges,
      items,
      selectedVesselItem,
      closeNew: onClose,
    } = this.props;

    return (
      <CollapsePanel
        title={"Energy Flow"}
        subTitle={
          "Configure releations between items to visualize energy flow onboard"
        }
        isOpen={isOpen}
        testId={"energyFlowController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
        buttons={[
          {
            label: "New",
            disabled: !enabled || isFormOpen,
            isVisible: isOpen,
            action: this.props.openNew,
          },
          {
            label: "Save",
            isVisible: isOpen,
            disabled: !enabled || !hasChanges,
            action: this.handleSave,
          },
        ]}
      >
        <EnergyFlowAdd
          isOpen={isFormOpen}
          onSelected={this.handleVesselItemSelected}
          onAdd={this.handleVesselItemAdd}
          items={items}
          selectedVesselItem={selectedVesselItem}
          onClose={onClose}
        />
        {isOpen && this.renderContent()}
      </CollapsePanel>
    );
  }
}

const mapStateToProps = (state) => {
  const { energyFlow } = state.admin;
  const { width, height } = getPanelSize(energyFlow);

  const existingItems = energyFlow.layout.map((x) => x.vesselItemId);
  const availableItems = energyFlow.items.filter((item) => {
    return !existingItems.some((x) => x === item.id);
  });

  const layout = energyFlow.layout.map((x) => ({
    ...x,
    id: x.vesselItemId,
    title: x.name,
    subTitle: get(
      energyFlow.items.find((i) => i.id === x.vesselItemId),
      "itemType"
    ),
  }));

  return {
    ...energyFlow,
    layout,
    items: [...availableItems],
    panelWidth: width,
    panelHeight: height,
    showLoader: energyFlow.isLoading || energyFlow.error,
  };
};

const mapDispatchToProps = {
  vesselItemSelected,
  openNew,
  closeNew,
  addNew,
  save,
  addRelation,
  deleteRelation,
  updateNodePosition,
  removeNode,
  reset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnergyFlowConfigController);
