import { mockBaseUrl } from "../config";
import { delay, http, HttpResponse } from "msw";
import { FUEL_EMISSIONS } from "../../api";
import { fuelConsumptionsResponse } from "./fuelConsumptionsMock";
import { emissionsTrendResponse } from "./emissionsTrendMock";
import { runAndEmissionsDonutDataResponse } from "./runAndEmissionsDonutDataMock";
import { emissionsByOperationModesResponse } from "./emissionsByOperationModesMock";
import { fuelAndTargetResponse } from "./fuelAndTargetMock";

export const fuelEmissionsEndpoints = [
  http.get(
    `${mockBaseUrl}${FUEL_EMISSIONS.getFuelConsumption(
      ":vesselId",
      { from: null, to: null },
      null
    )}`,
    () => HttpResponse.json(fuelConsumptionsResponse)
  ),
  http.get(
    `${mockBaseUrl}${FUEL_EMISSIONS.getEmissionsTrend(":vesselId")}`,
    async () => {
      await delay(4000);
      return HttpResponse.json(emissionsTrendResponse);
    }
  ),
  http.get(
    `${mockBaseUrl}${FUEL_EMISSIONS.getDonutData(
      ":vesselId",
      { from: null, to: null },
      null
    )}`,
    () => HttpResponse.json(runAndEmissionsDonutDataResponse)
  ),
  http.get(
    `${mockBaseUrl}${FUEL_EMISSIONS.getEmissionsByOperationModes(
      ":vesselId",
      { from: null, to: null },
      null
    )}`,
    () => HttpResponse.json(emissionsByOperationModesResponse)
  ),
  http.get(
    `${mockBaseUrl}${FUEL_EMISSIONS.getFuelAndTarget(
      ":vesselId",
      { from: null, to: null },
      null
    )}`,
    async () => {
      await delay(3000);
      return HttpResponse.json(fuelAndTargetResponse);
    }
  ),
];
