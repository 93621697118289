export function latLng2World({ lat, lng }) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  const getY = () => (y > 1 ? 1 : y);
  y =
    y < 0 // eslint-disable-line
      ? 0
      : getY();
  return { x, y };
}
export function latLng2Scaled({ lat, lng }, zoom) {
  const worldCoords = latLng2World({ lat, lng });
  const scale = Math.pow(2, zoom);

  return {
    x: worldCoords.x * scale,
    y: worldCoords.y * scale,
  };
}

export function distance({ x: x0, y: y0 }, { x: x1, y: y1 }) {
  return Math.sqrt(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2));
}
