import React from "react";
import PropTypes from "prop-types";
import { SparklineContext } from "./Sparkline";

class SparklineCustomElement extends React.PureComponent {
  render() {
    const { children, ...props } = this.props;
    return <g>{children(props)}</g>;
  }
}

SparklineCustomElement.propTypes = {
  children: PropTypes.func.isRequired,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineCustomElement {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
