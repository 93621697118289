import t from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Nav.css";

export class Nav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderMenuItem = this.renderMenuItem.bind(this);
  }

  renderMenuItem({ name }) {
    const view = this.props.views[name];
    if (view.isHidden) {
      return undefined;
    }
    return (
      <li className={styles.navItem} key={name}>
        <NavLink
          data-test-id={`navLink-${name}`}
          to={view.url}
          className={({ isActive }) => (isActive ? styles.active : "")}
          end={view.isIndex}
          onClick={(event) => this.props.navClickAction(event, view)}
        >
          {view.name}
        </NavLink>
      </li>
    );
  }

  render() {
    if (this.props.disabled) {
      return <div />;
    }
    return (
      <nav className={styles.nav}>
        <ul>{this.props.menu.map(this.renderMenuItem)}</ul>
      </nav>
    );
  }
}

Nav.propTypes = {
  navClickAction: t.func.isRequired,
  menu: t.arrayOf(
    t.shape({
      name: t.string.isRequired,
    })
  ).isRequired,
  views: t.objectOf(
    t.shape({
      url: t.string.isRequired,
      name: t.string.isRequired,
      isIndex: t.bool,
      isHidden: t.bool,
    })
  ).isRequired,
  disabled: t.bool,
};
