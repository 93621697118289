import React from "react";
import PropTypes from "prop-types";
import styles from "./DataDetailsValueTable.css";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import classNames from "../../../common/classNames";
import { isNumber, get, isNil, has } from "lodash";
import { roundConvert } from "../../../common/numbers";

class DataDetailsValueTable extends React.PureComponent {
  renderHeader() {
    const { columns } = this.props;
    return (
      <thead>
        <tr>
          {columns &&
            columns.map((c, i) => <td key={`head_${i}`}>{c.name}</td>)}
        </tr>
      </thead>
    );
  }

  renderValues() {
    const { columns, values } = this.props;
    return (
      <tbody>
        {values &&
          values.map((r, ri) => (
            <tr key={`row_${ri}`} className={styles.dataRow}>
              {r.map((c, ci) => {
                const val = isNumber(c)
                  ? roundConvert(c, get(columns, `[${ci}].unit`))
                  : c;
                return (
                  <td key={`val_${ri}_${ci}`}>
                    <span className={styles.value}>
                      {has(val, "number") && !isNil(val.number)
                        ? val.number
                        : val}
                    </span>{" "}
                    {has(val, "unit") && (
                      <span className={styles.unit}>{val.unit}</span>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        <tr key={"bottom"} />
      </tbody>
    );
  }

  renderFooter() {
    const { footer, columns } = this.props;
    if (!footer) return;
    return (
      <tfoot>
        {footer && (
          <tr>
            {footer.map((f, i) => {
              const val = isNumber(f)
                ? roundConvert(f, get(columns, `[${i}].unit`))
                : f;
              return (
                <td key={`footer_${i}`}>
                  <span className={styles.value}>
                    {val && !isNil(val.number) ? val.number : val}
                  </span>{" "}
                  {val && val.unit && (
                    <span className={styles.unit}>{val.unit}</span>
                  )}
                </td>
              );
            })}
          </tr>
        )}
      </tfoot>
    );
  }

  render() {
    const { height, firstColumnIsTitle, fullWidth } = this.props;
    return (
      <DataDetailsComponentContainer
        fullWidth={fullWidth}
        style={{
          height,
        }}
      >
        <div className={styles.container}>
          <table
            className={classNames(
              styles.table,
              firstColumnIsTitle && styles.firstColumnIsTitle
            )}
          >
            {this.renderHeader()}
            {this.renderValues()}
            {this.renderFooter()}
          </table>
        </div>
      </DataDetailsComponentContainer>
    );
  }
}

DataDetailsValueTable.defaultProps = {
  type: "DataDetailsValueTable",
  height: "auto",
  firstColumnIsTitle: false,
};

DataDetailsValueTable.propTypes = {
  height: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.unit,
    })
  ),
  values: PropTypes.arrayOf(PropTypes.array),
  footer: PropTypes.array,
  firstColumnIsTitle: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default DataDetailsValueTable;
