import { vesselConfig } from "../action.types";

export const inputChanged = (name, value) => ({
  type: vesselConfig.hullPerformance.INPUT_CHANGED,
  name,
  value,
});

export const save = () => ({
  type: vesselConfig.hullPerformance.SAVE,
});

export const openValidation = () => ({
  type: vesselConfig.hullPerformance.SHOW_POPUP,
});
export const hideValidation = () => ({
  type: vesselConfig.hullPerformance.HIDE_POPUP,
});

export const downloadConfigFile = (data, filename) => ({
  type: vesselConfig.hullPerformance.DOWNLOAD,
  data,
  filename,
});

export const requestCalculation = () => ({
  type: vesselConfig.hullPerformance.REQUEST_CALCULATION,
});

export const recalculate = () => ({
  type: vesselConfig.hullPerformance.RECALCULATE,
});

export const getCalculationResult = () => ({
  type: vesselConfig.hullPerformance.FETCH_CALCULAION_RESULT,
});

export const resetPanel = () => ({
  type: vesselConfig.hullPerformance.RESET,
});
