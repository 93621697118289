import React from "react";
import styles from "./Settings.css";
import SettingsRow from "./SettingsGroup";

const SettingsContainer = ({ title, children }) => {
  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsHeader}>
        <h1>{title}</h1>
      </div>
      <div>{children}</div>
    </div>
  );
};

SettingsContainer.propTypes = {
  children: (props, propName, componentName) => {
    const prop = props[propName];
    return (
      React.Children.toArray(prop).find(
        (child) => child.type !== SettingsRow
      ) &&
      new Error(`${componentName} only accepts "<SettingsGroup />" elements`)
    );
  },
};

export default SettingsContainer;
