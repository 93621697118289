import {
  FETCH_BEST_PERFORMANCE_SUCCESS,
  FETCH_BEST_PERFORMANCE_ERROR,
} from "../actions/action.types";
import createReducer from "./createReducer";

const initialState = {};

export const fetchBestPerformance = createReducer(initialState, {
  [FETCH_BEST_PERFORMANCE_SUCCESS]: (_state, { data, period }) => ({
    data,
    period,
  }),
  [FETCH_BEST_PERFORMANCE_ERROR]: (_state, { error }) => ({ error }),
});
