import { vesselConfig } from "../action.types";

export const editVesselItem = ({ id }) => {
  return {
    type: vesselConfig.vesselItems.BEGIN_EDIT,
    id,
  };
};

export const cancelEditVesselItem = (id) => {
  return {
    type: vesselConfig.vesselItems.CANCEL_EDIT,
    id,
  };
};

export const inputChanged = (id, name, value, valid) => ({
  type: vesselConfig.vesselItems.INPUT_CHANGED,
  id,
  name,
  value,
  valid,
});

export const fuelTypesChanged = (id, value, valid) => ({
  type: vesselConfig.vesselItems.FUEL_TYPES_CHANGED,
  id,
  value,
  valid,
});

export const metricChanged = (id, metricId, setting, value) => {
  return {
    type: vesselConfig.vesselItems.METRIC_CHANGED,
    id,
    metricId,
    setting,
    value,
  };
};

export const saveVesselItem = ({ id, form, metrics }) => ({
  type: vesselConfig.vesselItems.SAVE,
  id,
  form,
  metrics,
});

export const newVesselItem = () => ({
  type: vesselConfig.vesselItems.CREATE,
});

export const deleteVesselItem = (id) => ({
  type: vesselConfig.vesselItems.DELETE,
  id,
});

export const cancelDeleteVesselItem = (id) => ({
  type: vesselConfig.vesselItems.CANCEL_DELETE,
  id,
});

export const confirmDeleteVesselItem = (id) => ({
  type: vesselConfig.vesselItems.CONFIRM_DELETE,
  id,
});
