/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import styles from "./FuelEmissions.css";
import Header, { IHeaderSelectOptions } from "./Header";
import {
  changeFuelType,
  changeSelectedEmissionTrend,
  fetchEmissionsDonutData,
  fetchFuelConsumptions,
  fetchEmissionsTrend,
  fetchEmissionsByOperationModes,
  fetchFuelAndTarget,
  setTargetFuelDataCancel,
  setTargetFuelDataSave,
  setTargetFuelDataHide,
  changeFuelAndTargetFuelType,
} from "../../actions/action.fuelEmissions";
import FuelTarget from "./FuelTarget";
import Emissions from "./Emissions";
import FuelConsumption from "./FuelConsumption";
import EmissionDonuts from "./Donuts";
import EmissionsTrend from "./EmissionsTrend";
import SetFuelTargetsDialog from "./SetFuelTargets";
import classNames from "../../../common/classNames";
import {
  IFuelEmissionsState,
  IOperationTargetsData,
  IState,
} from "./commonTypes";
import { AnyAction } from "redux";
import { Option } from "src/common/components/RadioButton";

const selectFilters = (state: any) => state.fuelEmissions.filters;

const selectEmissionsFilters = createSelector(
  selectFilters,
  (filters) => filters || {}
);

export function FuelEmissions(): JSX.Element {
  const filters = useSelector(selectEmissionsFilters);
  const fuelEmissions: IFuelEmissionsState = useSelector(
    (state: IState) => state.fuelEmissions
  );
  const dispatch = useDispatch();
  const dispatchAction = (action: any) => dispatch(action) as AnyAction;

  useEffect(() => {
    if (!filters?.vesselId || !filters?.dateFilter) return;

    dispatchAction(fetchFuelConsumptions(filters));
    dispatchAction(fetchEmissionsDonutData(filters));
    dispatchAction(fetchEmissionsByOperationModes(filters));
    dispatchAction(fetchEmissionsTrend(filters));
    dispatchAction(fetchFuelAndTarget(filters));
  }, [filters]);

  const fuelOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.fuelConsumption.fuels,
    onChange: (o: Option) => dispatchAction(changeFuelType(o.id)),
  };
  const emissionsTrendOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.emissionsTrend.options,
    onChange: (o: Option) =>
      dispatchAction(changeSelectedEmissionTrend(o.name)),
  };
  const fuelAndTargetOptions: IHeaderSelectOptions = {
    options: fuelEmissions?.fuelAndTarget.fuels ?? [],
    onChange: (o: Option) => dispatchAction(changeFuelAndTargetFuelType(o.id)),
  };

  const setFuelTargetsDialogCancel = () => {
    dispatchAction(setTargetFuelDataCancel());
  };
  const setFuelTargetsDialogHide = () => {
    dispatchAction(setTargetFuelDataHide());
  };
  const setFuelTargetsDialogSave = () => {
    const changes: IOperationTargetsData[] =
      fuelEmissions.setTargetFuelDataDialog.data?.data.filter(
        (d) => !!d.hasChanges
      ) ?? [];
    dispatchAction(setTargetFuelDataSave(filters.vesselId, changes));
  };
  return (
    <div className={styles.topGridContainer}>
      <div className={styles.gridItem}>
        <Header
          title={"Fuel Consumption"}
          headerSelectOptions={fuelOptions}
          info={"CO2 emissions calculated by Fuel Consumption"}
        />
        <div className={styles.componentBlockContainer}>
          <FuelConsumption fuelConsumption={fuelEmissions.fuelConsumption} />
        </div>
      </div>
      <div className={styles.gridItem}>
        <Header
          title={"Emissions trend"}
          headerSelectOptions={emissionsTrendOptions}
          info={"Emissions trend component."}
        />
        <div className={styles.componentBlockContainer}>
          <EmissionsTrend />
        </div>
      </div>
      <div className={styles.gridItem}>
        <Header
          title={"Fuel & Target"}
          headerSelectOptions={fuelAndTargetOptions}
          info={"Fuel & Target component."}
        />
        <div className={styles.componentBlockContainer}>
          <FuelTarget data={fuelEmissions.fuelAndTarget} />
        </div>
      </div>
      <div className={classNames(styles.gridItem, styles.donut)}>
        <EmissionDonuts donutData={fuelEmissions.donutData} />
      </div>
      <div className={styles.gridItem}>
        <Header title={"Emissions"} info={"Emissions component."} />
        <div className={styles.componentBlockContainer}>
          <Emissions data={fuelEmissions.emissionsData} />
        </div>
      </div>

      <div className={styles.hidden}>
        <SetFuelTargetsDialog
          visible={fuelEmissions.setTargetFuelDataDialog.visible}
          vesselId={filters.vesselId}
          lastUpdate={fuelEmissions.setTargetFuelDataDialog.data?.lastUpdate}
          cancel={setFuelTargetsDialogCancel}
          save={setFuelTargetsDialogSave}
          hide={setFuelTargetsDialogHide}
        />
      </div>
    </div>
  );
}

export default FuelEmissions;
