import { FIT_MAP_TO_VESSELS } from "./action.types";

export const fitMapToFirstVessel = (vessels) => {
  if (!vessels || !vessels.length) {
    return fitMapToVessels([]);
  }
  return fitMapToVessels([vessels[0]]);
};

export const fitMapToVessels = (vessels) => ({
  type: FIT_MAP_TO_VESSELS,
  vessels: vessels || [],
});
