import React from "react";
import styles from "./NotFound.css";

export default class NotFound extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <h1 className={styles.header}>Not found</h1>
        <p className={styles.block}>
          We can't find the page you're looking for.
        </p>
        <p className={styles.block}>
          Try the <a href="/">Home</a> page instead.
        </p>
      </div>
    );
  }
}
