import React from "react";
import PropTypes from "prop-types";
import styles from "./FormImageUpload.css";
import classNames from "../../../../common/classNames";
import { noop } from "lodash";

export default class FormImageUpload extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.inputRef = React.createRef();
    this.state = {
      file: null,
      fileName: null,
    };
  }

  handleChange = (event) => {
    const file = event.target.files[0];
    const { name, onChange } = this.props;
    if (!file) {
      return;
    }
    this.setState({
      file: file && URL.createObjectURL(file),
      fileName: file.name,
    });
    onChange(name, file);
  };

  triggerFileDialog = (e) => {
    this.inputRef.current.click();
    e.preventDefault();
  };

  render() {
    let fileUrl;
    let fileName;

    if (this.state.file) {
      fileUrl = this.state.file;
      fileName = this.state.fileName;
    } else if (this.props.src) {
      fileUrl = this.props.src;
      fileName = this.props.fileName;
    }

    return (
      <div className={styles.container}>
        <input
          ref={this.inputRef}
          className={styles.fileInput}
          name={"file"}
          id={"file"}
          type={"file"}
          onChange={this.handleChange}
        />
        <div
          className={classNames(
            styles.imageContainerOuter,
            fileUrl ? styles.hasImage : ""
          )}
        >
          <div
            className={styles.imageContainer}
            onClick={this.triggerFileDialog}
          >
            {fileUrl && <img src={fileUrl} />}
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <span>{fileName}</span>
        </div>
      </div>
    );
  }
}

FormImageUpload.defaultProps = {
  onChange: noop,
};

FormImageUpload.propTypes = {
  name: PropTypes.string,
  src: PropTypes.string,
  fileName: PropTypes.string,
  onChange: PropTypes.func,
};
