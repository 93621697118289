import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import createFetchMiddleware from "./fetchMiddleware";
import reducer from "../reducers";
import { rootSaga } from "../sagas/rootSaga";
import { AppConfig } from "../../../config/onshore/environments/AppConfig";
import { Services } from "../services";
import { baseApi } from "./baseApi";

export default (appConfig: AppConfig, services: Services) => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      http: services.http,
      history: services.history,
    },
  });
  const preloadedState = { appConfig };
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            history: services.history,
            http: services.http,
            appConfig: services.appConfig,
          },
        },
      })
        .concat(createFetchMiddleware({ http: services.http }))
        .concat(sagaMiddleware)
        .concat(baseApi.middleware),
    devTools: true,
  });
  sagaMiddleware.run(rootSaga);
  return store;
};
