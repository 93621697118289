import React from "react";
import { connect } from "react-redux";
import Loader from "../../../../../common/components/Loader";
import {
  FormCheckBox,
  FormGroup,
  FormFileUpload,
} from "../../../../components/Form";
import Button from "../../../../../common/components/Button";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import styles from "./HullPerformanceConfigController.css";
import {
  inputChanged,
  save,
  openValidation,
  hideValidation,
  downloadConfigFile,
  resetPanel,
  requestCalculation,
  getCalculationResult,
  recalculate,
} from "../../../../actions/admin/action.vesselconfig.hullPerformanceConfig";
import { get, isNil } from "lodash";
import { STATES } from "../../../../reducers/admin/vesselConfig/reducer.hullPerformanceConfig";
import { now, toLocalDateISOString } from "../../../../common/dates";
import HullPerformanceConfigPopup from "./HullPerformanceConfigPopup";

class HullPerformanceConfigController extends React.PureComponent {
  constructor(props) {
    super(props);
    this.setFile = this.setFile.bind(this);
    this.handleResetPanel = this.handleResetPanel.bind(this);
    this.handleSpeedPowerExampleData =
      this.handleSpeedPowerExampleData.bind(this);
    this.handleDisplacementDraughtExampleData =
      this.handleDisplacementDraughtExampleData.bind(this);

    this.getValidationLabel = this.getValidationLabel.bind(this);
    this.handleHideValidation = this.handleHideValidation.bind(this);
    this.handleRequestCalculation = this.handleRequestCalculation.bind(this);
    this.handleRecalculate = this.handleRecalculate.bind(this);
    this.handleGetCalculationResult =
      this.handleGetCalculationResult.bind(this);
  }

  setFile(name, file) {
    this.props.inputChanged(name, file);
  }

  handleDownloadClick(data, filename) {
    this.props.downloadConfigFile(data, filename);
  }
  handleResetPanel() {
    this.props.resetPanel();
  }
  handleSpeedPowerExampleData(e) {
    e.preventDefault();
    this.props.downloadConfigFile(
      this.props.exampleData.speedPower,
      "EXAMPLE_hullPerformanceSpeedPower.csv"
    );
  }
  handleDisplacementDraughtExampleData(e) {
    e.preventDefault();
    this.props.downloadConfigFile(
      this.props.exampleData.displacementDraught,
      "EXAMPLE_hullPerformanceDisplacementDraught.csv"
    );
  }

  handleHideValidation() {
    this.props.hideValidation();
  }
  handleRequestCalculation() {
    this.props.requestCalculation();
  }
  handleRecalculate() {
    this.props.recalculate();
  }
  handleGetCalculationResult() {
    this.props.getCalculationResult();
  }

  renderContent(props) {
    const { showLoader, error, fields, imo, inputChanged: onChange } = props;

    if (showLoader) {
      return (
        <div className={styles.contentContainer}>
          <Loader
            expand={true}
            error={error}
            resetAction={this.handleResetPanel}
          />
        </div>
      );
    }

    const { isIsoCompliant, speedPowerTxt, displacementDraughtTxt } = fields;

    return (
      <div className={styles.contentContainer}>
        <div className={styles.configContainer}>
          <FormGroup>
            <div className={styles.formgroup}>
              <div className={styles.isoInput}>
                <FormCheckBox
                  key={"isIsoCompliant"}
                  value={isIsoCompliant.value}
                  label={"Enable ISO 19030 compliant mode"}
                  name={"isIsoCompliant"}
                  iconPosition="left"
                  onChange={onChange}
                />
              </div>
              <div className={styles.infoText}>
                ISO 19030 compliant mode requires high resolution 0.07 Hz sample
                data to be received from vessel. Non ISO 19030 compliant mode
                uses 10 min (0.0017 Hz) aggregated data
              </div>
            </div>
            <div className={styles.uploadPanels}>
              <div className={styles.formgroup}>
                <h3>Speed/Power</h3>

                <div className={styles.button}>
                  <FormFileUpload
                    name="speedPowerTxt"
                    label="Upload"
                    labelPosition={"top"}
                    onChange={this.setFile}
                  />
                </div>
                <div className={styles.button}>
                  <Button
                    value={"Download"}
                    disabled={isNil(get(speedPowerTxt, "value"))}
                    clickAction={() =>
                      this.handleDownloadClick(
                        get(speedPowerTxt, "value"),
                        `imo${imo}_SpeedPower_${toLocalDateISOString(
                          now()
                        )}.csv`
                      )
                    }
                  />
                </div>
                <div className={styles.infoText}>
                  Speed/power reference curves contain speed(power, draught,
                  trim) data
                  <p>
                    <a href="#" onClick={this.handleSpeedPowerExampleData}>
                      Download example data
                    </a>
                  </p>
                </div>
              </div>
              <div className={styles.formgroup}>
                <h3>Displacement/draught</h3>
                <div className={styles.upload}>
                  <div className={styles.button}>
                    <FormFileUpload
                      name="displacementDraughtTxt"
                      label="Upload"
                      labelPosition={"top"}
                      onChange={this.setFile}
                    />
                  </div>
                </div>
                <div className={styles.button}>
                  <Button
                    value={"Download"}
                    disabled={isNil(get(displacementDraughtTxt, "value"))}
                    clickAction={() =>
                      this.handleDownloadClick(
                        get(displacementDraughtTxt, "value"),
                        `imo${imo}_displacementDraught_${toLocalDateISOString(
                          now()
                        )}.csv`
                      )
                    }
                  />
                </div>
                <div className={styles.infoText}>
                  Displacement/draught reference curves contain displacement as
                  a function of draught and trim. <br />
                  The unit of the displacement value does not matter as this
                  value is only used in relative calculations. <br />
                  Thus, any unit representing displacement can be used (tonnes,
                  kg, long tonnes etc.).
                  <p>
                    <a
                      href="#"
                      onClick={this.handleDisplacementDraughtExampleData}
                    >
                      Download example data
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }

  getValidationLabel = ({ isValid, errors }) => {
    if (!isValid) {
      return (
        <span>
          Show{" "}
          <span className={styles.validationError}>
            {get(errors, "length")}
          </span>{" "}
          validation errors
        </span>
      );
    } else {
      return <span>Validation and Calculation</span>;
    }
  };

  render() {
    const {
      isOpen,
      enabled,
      onToggleClick,
      validation,
      calculationResult,
      showPopup,
      popupDisabled,
      saveDisabled,
      showLoaderPopup,
    } = this.props;

    return (
      <CollapsePanel
        title={"Hull Performance"}
        subTitle={"Configure Hull Performance settings"}
        isOpen={isOpen}
        testId={"hullPerformanceConfigController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
        buttons={[
          {
            label: () => this.getValidationLabel(validation),
            action: this.props.openValidation,
            isVisible: isOpen,
            disabled: popupDisabled,
            type: !get(validation, "isValid", false) ? "alert" : "button",
          },
          {
            label: "Save",
            action: this.props.save,
            disabled: saveDisabled,
            isVisible: isOpen,
          },
        ]}
      >
        {this.renderContent(this.props)}
        <HullPerformanceConfigPopup
          visible={showPopup}
          requestCalculation={this.handleRequestCalculation}
          recalculate={this.handleRecalculate}
          cancel={this.handleHideValidation}
          getCalculationResult={this.handleGetCalculationResult}
          errors={validation.errors}
          isValid={validation.isValid}
          isLoading={showLoaderPopup}
          calculationResult={calculationResult}
        />
      </CollapsePanel>
    );
  }
}

const mapStateToProps = (state) => {
  const hullPerformanceState = get(state, "admin.hullPerformanceConfig");
  const operationState = get(
    hullPerformanceState,
    "operationState",
    STATES.READY
  );

  return {
    fields: hullPerformanceState.fields,
    operationState: operationState,
    error: hullPerformanceState.error,
    showLoaderPopup: [
      STATES.FETCHING_CALCULATION_RESULT,
      STATES.REQUESTING_CALCULATION,
    ].includes(operationState),
    showLoader: [STATES.LOADING, STATES.SAVING, STATES.ERROR].includes(
      operationState
    ),
    showPopup: [
      STATES.SHOW_POPUP,
      STATES.REQUESTING_CALCULATION,
      STATES.FETCHING_CALCULATION_RESULT,
    ].includes(operationState),
    saveDisabled:
      Object.values(hullPerformanceState.fields).every((x) => !x?.hasChanges) ||
      (!hullPerformanceState?.fields?.speedPowerTxt?.hasChanges &&
        !hullPerformanceState?.fields?.speedPowerTxt?.existsInConfig) ||
      (!hullPerformanceState?.fields?.displacementDraughtTxt?.hasChanges &&
        !hullPerformanceState?.fields?.displacementDraughtTxt?.existsInConfig),
    popupDisabled:
      [STATES.LOADING, STATES.SAVING].includes(operationState) ||
      (!hullPerformanceState?.fields?.speedPowerTxt?.existsInConfig &&
        !hullPerformanceState?.fields?.displacementDraughtTxt?.existsInConfig),
    imo: get(state, "admin.vesselConfig.vessel.imo"),
    vesselId: get(hullPerformanceState, "vesselId"),
    exampleData: {
      ...hullPerformanceState.exampleData,
    },
    calculationResult: hullPerformanceState.calculationResult,
    validation: hullPerformanceState.validation,
  };
};

const mapDispatchToProps = {
  inputChanged,
  save,
  openValidation,
  hideValidation,
  downloadConfigFile,
  resetPanel,
  requestCalculation,
  recalculate,
  getCalculationResult,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HullPerformanceConfigController);
