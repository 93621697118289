import t from "prop-types";
import React from "react";
import styles from "./OperationalProfilePlan.css";
import { connect } from "react-redux";
import { setOperationalProfilePlanPeriod } from "../../actions/action.operationalProfilePlan";
import DonutContainer from "../DonutContainer";
import SelectBox from "../../components/SelectBox";

class OperationalProfilePlan extends React.Component {
  constructor(props) {
    super(props);

    this.onPlanSelect = this.onPlanSelect.bind(this);
  }

  onPlanSelect(plan) {
    this.props.setOperationalProfilePlanPeriod(plan.realValue);
  }

  render() {
    let header = () => {
      return (
        <div key="header" className={styles.titleDiv}>
          <h2 className={styles.title}>Operational profile plan</h2>
        </div>
      );
    };

    let plansSelect = () => {
      if (
        this.props.operationalProfilePlan.data &&
        this.props.operationalProfilePlan.data.operationalProfilePlanDateRanges
      )
        return (
          <div className={styles.profilePlanSelectBox}>
            <SelectBox
              key="modeSelect"
              options={
                this.props.operationalProfilePlan.data
                  .operationalProfilePlanDateRanges
              }
              optionValKey="visibleValue"
              onSelect={this.onPlanSelect.bind(this)}
              selected={this.props.operationalProfilePlan.data.operationalProfilePlanDateRanges.find(
                (x) => x.selected === true
              )}
            />
          </div>
        );
    };

    return (
      <div key="donut-div" className={styles.mainProfileDiv}>
        {header()}
        {plansSelect()}
        <div className={styles.donutsDiv}>
          <DonutContainer
            donuts={[
              this.props.operationalProfile,
              this.props.operationalProfilePlan,
            ]}
          />
        </div>
      </div>
    );
  }
}

OperationalProfilePlan.propTypes = {
  context: t.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    operationalProfile: state.operationalProfile,
    operationalProfilePlan: state.operationalProfilePlan,
  };
};

const mapDispatchToProps = {
  setOperationalProfilePlanPeriod,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationalProfilePlan);
