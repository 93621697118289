import {
  SystemAdmin,
  CompanyAdmin,
  HmProductExpert,
  EhmClient,
  VesselClient,
  Em2ApiClient,
} from "../common/roles";
import createReducer from "./createReducer";
import {
  FETCH_USER_DATA_ERROR,
  FETCH_USER_DATA_SUCCESS,
  SAVE_USER_PROFILE_SUCCESS,
  FETCH_API_ALIVE_ERROR,
  FETCH_API_ALIVE_SUCCESS,
} from "../actions/action.types";

const initialState = {
  isLoggedIn: false,
};

export const user = createReducer(initialState, {
  [FETCH_USER_DATA_SUCCESS]: (_state, { data }) => ({
    isLoggedIn: true,
    ...data.userDetails,
  }),

  [SAVE_USER_PROFILE_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),

  [FETCH_USER_DATA_ERROR]: (_state, { error }) => ({
    isLoggedIn: false,
    error,
  }),

  [FETCH_API_ALIVE_SUCCESS]: (state) => ({
    ...state,
    isLoggedIn: true,
    error: null,
  }),

  [FETCH_API_ALIVE_ERROR]: (state, { error }) => ({
    ...state,
    isLoggedIn: false,
    error,
  }),
});

export const isSystemAdmin = ({ roles }) => {
  return (roles || []).some((role) => role === SystemAdmin);
};

export const isCompanyAdmin = ({ roles }) => {
  return (roles || []).some((r) => r === CompanyAdmin);
};

export const isHmProductExpert = ({ roles }) => {
  return (roles || []).some((r) => r === HmProductExpert);
};

export const isEhmClient = ({ roles }) => {
  return (roles || []).some((r) => r === EhmClient);
};

export const isVesselClient = ({ roles }) => {
  return (roles || []).some((r) => r === VesselClient);
};

export const isEm2ApiClient = ({ roles }) => {
  return (roles || []).some((r) => r === Em2ApiClient);
};
