import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { noop, get, matchesProperty } from "lodash";
import Loader from "../../../../../common/components/Loader";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import DataTable from "../../../../components/DataTable/DataTable";
import styles from "./VesselPerformanceIndicatorsController.css";
import {
  editVesselPerformanceIndicator,
  inputChanged,
  saveVesselPerformanceIndicator,
  cancelEditVesselPerformanceIndicator,
  newVesselPerformanceIndicator,
  deleteVesselPerformanceIndicator,
  cancelDeleteVesselPerformanceIndicator,
  confirmDeleteVesselPerformanceIndicator,
} from "../../../../actions/admin/action.vesselconfig.vesselPerformanceIndicators";
import VesselPerformanceIndicatorForm from "./VesselPerformanceIndicatorForm";

class VesselPerformanceIndicatorsController extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.editTemplate = this.editTemplate.bind(this);

    this.columns = [
      {
        header: "Performance indicator",
        field: "label",
      },
      {
        header: "Min value",
        field: "minValue",
      },
      {
        header: "Max Value",
        field: "maxValue",
      },
    ];
  }

  onEdit = (row) => {
    this.props.editVesselPerformanceIndicator(row.id);
  };

  editTemplate = ({ label, form }, rowId) => {
    const title = label ? "Edit " + label : "New performance indicator";
    const { vesselPerformanceIndicators } = this.props;
    const selectedPerformanceIndicators = get(
      vesselPerformanceIndicators,
      "data",
      []
    );
    const selectedPerformanceIndicator = get(
      selectedPerformanceIndicators.find((sp) => sp.id === rowId),
      "performanceIndicatorId",
      {}
    );
    const performanceIndicators = get(
      vesselPerformanceIndicators,
      "performanceIndicators",
      []
    ).filter(
      (p) =>
        !selectedPerformanceIndicators.some(
          matchesProperty("performanceIndicatorId", p.id)
        ) || selectedPerformanceIndicator === p.id
    );
    return (
      <VesselPerformanceIndicatorForm
        performanceIndicators={performanceIndicators}
        title={title}
        form={form}
        rowId={rowId}
        inputChanged={this.props.inputChanged}
        saveAction={this.props.saveVesselPerformanceIndicator}
        cancelAction={this.props.cancelEditVesselPerformanceIndicator}
        deleteAction={this.props.deleteVesselPerformanceIndicator}
        cancelDeleteAction={this.props.cancelDeleteVesselPerformanceIndicator}
        confirmDeleteAction={this.props.confirmDeleteVesselPerformanceIndicator}
      />
    );
  };

  renderContent() {
    const { isLoading, data, error } = this.props.vesselPerformanceIndicators;
    if (isLoading || error) {
      return <Loader expand={true} error={error} />;
    }
    return (
      <div className={styles.gridContainer}>
        <DataTable
          testId={"datatable"}
          data={data}
          columns={this.columns}
          canEdit={true}
          onEdit={this.onEdit}
          editTemplate={this.editTemplate}
        />
      </div>
    );
  }

  render() {
    const { isOpen, enabled, onToggleClick, isCreating } = this.props;

    const buttons = [
      {
        label: "New",
        action: this.props.newVesselPerformanceIndicator,
        disabled: !enabled || isCreating,
        isVisible: isOpen,
      },
    ];

    return (
      <CollapsePanel
        title={"Performance Indicators"}
        subTitle={"Configure available performance indicators"}
        isOpen={isOpen}
        testId={"vesselPerformanceIndicatorsController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
        buttons={buttons}
      >
        {this.renderContent()}
      </CollapsePanel>
    );
  }
}
VesselPerformanceIndicatorsController.defaultProps = {
  isOpen: false,
  enabled: false,
  onToggleClick: noop,
};

VesselPerformanceIndicatorsController.propTypes = {
  isOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  vesselPerformanceIndicators: state.admin.vesselConfigPerformanceIndicators,
  performanceIndicators:
    state.admin.vesselConfigPerformanceIndicators.performanceIndicators,
  isCreating: state.admin.vesselConfigPerformanceIndicators.data.some(
    (x) => x.id === undefined
  ),
});

const mapDispatchToProps = {
  editVesselPerformanceIndicator,
  inputChanged,
  saveVesselPerformanceIndicator,
  cancelEditVesselPerformanceIndicator,
  newVesselPerformanceIndicator,
  deleteVesselPerformanceIndicator,
  cancelDeleteVesselPerformanceIndicator,
  confirmDeleteVesselPerformanceIndicator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselPerformanceIndicatorsController);
