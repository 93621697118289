import t from "prop-types";
import React from "react";
import GoogleMapReact from "google-map-react";
import { MAP_CONFIG } from "../../config";
import VesselMapMarker from "./VesselMapMarker";
import styles from "./Map.css";
import { latLng } from "../../common/customPropTypes";
import latLngEquals from "../../common/latLngEquals";
import VesselTrackOverlay from "./VesselTrackMapOverlay/VesselTrackOverlay";
import VesselTrackMapMarker from "./VesselTrackMapMarker/VesselTrackMapMarker";
import LocationMapMarker from "./LocationMapMarker/LocationMapMarker";
import { get } from "lodash";
import { getMetersInPixelsForZoomLevel } from "../../../common/map";
import Loader from "../../../common/components/Loader";

const createMapOptions = () => ({
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  scrollwheel: true,
  backgroundColor: "none",
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
  styles: [
    {
      featureType: "all",
      stylers: [{ visibility: "off" }, { color: "#2b313f" }],
    },
    {
      featureType: "landscape.natural",
      stylers: [{ visibility: "on" }, { color: MAP_CONFIG.landscapeColor }],
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        { visibility: "on" },
        { color: MAP_CONFIG.countryBorderColor },
        { weight: 2 },
      ],
    },
    {
      featureType: "water",
      stylers: [{ visibility: "on" }, { color: MAP_CONFIG.defaultWaterColor }],
    },
  ],
});

export default class VesselMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: this.props.zoom,
      width: 0,
      height: 0,
      bounds: {},
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapChange = this.onMapChange.bind(this);
    this.onVesselTrackMarkerClick = this.onVesselTrackMarkerClick.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.onZoomStart = this.onZoomStart.bind(this);
    this.onZoomEnd = this.onZoomEnd.bind(this);
  }
  componentDidMount() {
    let args = {
      center: {
        lat: 0,
        lng: 0,
      },
      zoom: this.props.zoom,
      size: {
        width: this.props.width,
        height: this.props.height,
      },
      bounds: this.props.bounds,
      marginBounds: this.props.bounds,
    };
    this.onMapChange(args);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.vessels !== nextProps.vessels ||
      this.props.zoom !== nextProps.zoom ||
      this.state.bounds !== nextState.bounds ||
      !latLngEquals(this.props.center, nextProps.center) ||
      (Boolean(this.props.vesselTrack) &&
        Boolean(nextProps.vesselTrack) &&
        (this.props.vesselTrack.startIndex !==
          nextProps.vesselTrack.startIndex ||
          this.props.vesselTrack.stopIndex !==
            nextProps.vesselTrack.stopIndex ||
          this.props.vesselTrack.isVisible !==
            nextProps.vesselTrack.isVisible ||
          this.props.vesselTrack.hoveredItem !==
            nextProps.vesselTrack.hoveredItem ||
          this.props.vesselTrack.hoveredGroupIndex !==
            nextProps.vesselTrack.hoveredGroupIndex))
    );
  }

  onMarkerClick(_, { vessel } = {}) {
    if (vessel) {
      this.props.onVesselClick(vessel);
    }
  }

  onVesselTrackMarkerClick(point) {
    this.props.onVesselTrackItemClick(point);
  }

  renderVesselMarker(vessel) {
    return (
      <VesselMapMarker
        key={vessel.vesselId}
        vessel={vessel}
        position={vessel.position}
        lat={vessel.position.lat}
        lng={vessel.position.lng}
        connection={vessel.connectivity}
        efficiency={vessel.performanceIndicator || "noValue"}
        vesselName={vessel.vesselName}
        showVesselName={this.state.zoom >= 9}
      />
    );
  }

  renderLocation(location) {
    const { zoom } = this.state;
    return (
      <LocationMapMarker
        key={`location_${location.id}`}
        lat={location.latitude}
        lng={location.longitude}
        name={location.name}
        type={get(location, "locationTypeName", "area").toLowerCase()}
        showCircle={true}
        radius={getMetersInPixelsForZoomLevel(
          location.locationRadiusInMeter,
          location.latitude,
          zoom
        )}
        showLabel={true}
      />
    );
  }

  renderHoveredTrackItem(hoveredVesselTrackItem) {
    return (
      <VesselTrackMapMarker
        lat={hoveredVesselTrackItem.data.lat}
        lng={hoveredVesselTrackItem.data.lng}
        data={hoveredVesselTrackItem.data}
        onClick={this.onVesselTrackMarkerClick}
      />
    );
  }

  onMapChange(args) {
    let { size } = args;
    this.setState({
      width: size.width,
      height: size.height,
      zoom: args.zoom,
      bounds: args.bounds,
    });
    this.props.onChange(args);
  }

  onZoomStart() {
    this.props.onZoomStart();
  }

  onZoomEnd() {
    this.props.onZoomEnd();
  }

  render() {
    const {
      apiKey,
      defaultCenter,
      defaultZoom,
      vessels,
      locations,
      vesselTrack,
      center,
      zoom,
      showOverlay,
      showPoi,
      isLoading,
      isZooming,
    } = this.props;
    const { width, height, bounds } = this.state;

    return (
      <div className={styles.outerContainer}>
        <div className={styles.container}>
          {isLoading && (
            <div
              style={{ position: "absolute", zIndex: "11000", width, height }}
            >
              <Loader className={styles.loader} />
            </div>
          )}
          <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            defaultCenter={defaultCenter}
            defaultZoom={defaultZoom}
            center={center}
            zoom={zoom}
            options={createMapOptions}
            onChildClick={this.onMarkerClick}
            onChange={this.onMapChange}
            onZoomAnimationStart={this.onZoomStart}
            onZoomAnimationEnd={this.onZoomEnd}
          >
            {showOverlay &&
              vesselTrack &&
              !isZooming &&
              vesselTrack.isVisible &&
              vesselTrack.hoveredItem &&
              vesselTrack.hoveredItem.data &&
              this.renderHoveredTrackItem(vesselTrack.hoveredItem)}
            {showOverlay &&
              locations &&
              showPoi &&
              this.state.zoom >= 9 &&
              locations.map((location) => this.renderLocation(location))}
            {showOverlay &&
              vessels &&
              vessels.map((vessel) => this.renderVesselMarker(vessel))}
            {showOverlay &&
              vesselTrack &&
              !isZooming &&
              vesselTrack.isVisible &&
              !vesselTrack.isLoading && (
                <VesselTrackOverlay
                  width={width}
                  height={height}
                  centerLat={center.lat}
                  centerLng={center.lng}
                  zoom={zoom}
                  bounds={bounds}
                  track={vesselTrack.track}
                  startIndex={vesselTrack.startIndex}
                  stopIndex={vesselTrack.stopIndex}
                  hoverGroupIndex={vesselTrack.hoveredGroupIndex}
                  onTrackPointHover={this.props.onVesselDetailsItemHover}
                />
              )}
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

VesselMap.propTypes = {
  apiKey: t.string.isRequired,
  vessels: t.array,
  locations: t.array,
  defaultCenter: latLng.isRequired,
  defaultZoom: t.number.isRequired,
  center: latLng,
  zoom: t.number,
  onChange: t.func.isRequired,
  onVesselClick: t.func,
  onVesselDetailsItemHover: t.func,
  vesselTrack: t.object,
  onVesselTrackItemClick: t.func,
  showOverlay: t.bool,
  showPoi: t.bool,
  isLoading: t.bool,
  onZoomStart: t.func,
  onZoomEnd: t.func,
};

VesselMap.defaultProps = {
  onVesselClick: () => {},
  showOverlay: true,
};
