import React from "react";
import PropTypes from "prop-types";
import styles from "./HeaderStyles.css";
import PrevNextArrow from "./PrevNextArrow/PrevNextArrow";

const renderValue = (formattedValue, placeHolder) => {
  if (formattedValue) {
    return <span>{formattedValue}</span>;
  } else if (placeHolder) {
    return <span className={styles.placeHolder}>{placeHolder}</span>;
  }
  return <span />;
};

const DatePickerHeader = ({
  formattedValue,
  canSelectNext,
  canSelectPrev,
  selectPrev,
  selectNext,
  show,
  containerStyle,
  alwaysShowPrevNext,
  placeHolder,
}) => {
  return (
    <div className={styles.headerContainer}>
      <PrevNextArrow
        testId="datePickerHeader-prev"
        dir="prev"
        visible={canSelectPrev || alwaysShowPrevNext === true}
        disabled={canSelectPrev !== true}
        clickAction={canSelectPrev === true ? selectPrev : null}
      />
      <div
        className={styles.selectedDateContainer}
        style={containerStyle}
        onClick={show}
        data-test-id="datePickerHeader-button"
      >
        {renderValue(formattedValue, placeHolder)}
      </div>
      <PrevNextArrow
        testId="datePickerHeader-next"
        dir="next"
        visible={canSelectNext || alwaysShowPrevNext === true}
        disabled={canSelectNext !== true}
        clickAction={canSelectNext === true ? selectNext : null}
      />
    </div>
  );
};

DatePickerHeader.propTypes = {
  formattedValue: PropTypes.any,
  canSelectNext: PropTypes.bool.isRequired,
  canSelectPrev: PropTypes.bool.isRequired,
  selectNext: PropTypes.func.isRequired,
  selectPrev: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
  alwaysShowPrevNext: PropTypes.bool,
  placeHolder: PropTypes.string,
};

export default DatePickerHeader;
