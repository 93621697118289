import PropTypes from "prop-types";
import React from "react";
import styles from "./RadioCheck.css";

export default class RadioCheckbox extends React.Component {
  render() {
    let { checked, label, name, onCheck, horizontalStretch } = this.props;

    let checkedStyles = {
      display: "none",
    };

    let containerStyle = {};

    let labelStyle = {};

    if (checked) {
      checkedStyles.display = "block";
    }

    if (horizontalStretch) {
      containerStyle.width = "100%";
      labelStyle.flexGrow = 1;
    }

    return (
      <label className={styles.container} style={containerStyle}>
        <span className={styles.label} style={labelStyle}>
          {label}
        </span>
        <input
          type="checkbox"
          className={styles.input}
          checked={checked}
          name={name}
          onChange={onCheck.bind(this)}
        />
        <span className={styles.stateGraphics}>
          <svg
            style={checkedStyles}
            width="14"
            height="12"
            viewBox="0 0 14 12"
            enableBackground="new 0 0 14 12"
            xmlSpace="preserve"
          >
            <path d="M4.9,8.7l7.9-7.9l1.1,1.1l-9.2,9.2L0.1,6.5l1.3-1.3L4.9,8.7z" />
          </svg>
        </span>
      </label>
    );
  }
}

RadioCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  checked: PropTypes.bool,
  name: PropTypes.string,
  onCheck: PropTypes.func,
  horizontalStretch: PropTypes.bool,
};
