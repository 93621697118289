import React from "react";
import { FormSelectBox, FormTextBox } from "../../../components/Form";
import { get } from "lodash";
import Button from "../../../../common/components/Button";
import styles from "../Admin.css";
import { formatDateTime } from "../../../common/dates";
import {
  revokeRemoteAccessConfiguration,
  setRemoteAccessValue,
} from "../../../actions/admin";
import {
  isExpired,
  remoteControlDurations,
} from "../../../common/vesselActions";
import { connect } from "react-redux";

//  Duration is measured in seconds

class ConfigureRemoteAccessForm extends React.Component {
  render() {
    const {
      form,
      setRemoteAccessValue,
      revokeRemoteAccessConfiguration,
      currentAction,
      vessel,
    } = this.props;
    const { remoteAccessPort } = vessel;
    const getRemoteAccessPortMessage = () =>
      remoteAccessPort !== undefined ? remoteAccessPort : "not set";
    return isExpired(currentAction) ? (
      <div>
        <FormSelectBox
          name="duration"
          label="Session length"
          selected={remoteControlDurations.find(
            (x) => x.length === form.fields.duration.value
          )}
          options={remoteControlDurations}
          optionValKey="length"
          optionLabelKey="description"
          onChange={(name, selected) =>
            setRemoteAccessValue(name, selected && selected.length)
          }
          required
        />
        <FormTextBox
          name="reason"
          label="Reason for remote access"
          value={get(form.fields, "reason.value")}
          required
          onChange={setRemoteAccessValue}
        />
        <p>
          Remote access port for this vessel is {getRemoteAccessPortMessage()}.
        </p>
      </div>
    ) : (
      <div>
        <p className={styles.remoteControlPopupFormSection}>
          {currentAction && currentAction.actionMetadata && (
            <span>
              Remote access for this vessel is enabled until{" "}
              {formatDateTime(currentAction.actionMetadata.validTo)} (UTC)
            </span>
          )}
        </p>
        <Button
          type="button"
          value="Revoke remote access"
          clickAction={revokeRemoteAccessConfiguration}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAction: state.admin.vessels.configureRemoteControl.currentAction,
  form: state.admin.vessels.configureRemoteControl.remoteAccessForm,
  vessel: state.admin.vessels.configureRemoteControl.vessel,
});

const mapDispatchToProps = {
  setRemoteAccessValue,
  revokeRemoteAccessConfiguration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureRemoteAccessForm);
