import React from "react";
import PropTypes from "prop-types";
import { COSMETIC_CONSTS } from "../../../onshore/config";
import { color } from "../../colors";
import { find, isNaN, compact, sortBy, sumBy, isNil, isFinite } from "lodash";
import { SparklineContext } from "./Sparkline";
import { adjustHoverPoints, getLines } from "./SparklineHelpers";
import { roundNumber } from "../../numbers";

const defaultFormat = ({ y }) => y;
const defaultValueLookup = ({ data, dataSetIndex, hoveredXValue }) =>
  find(data[dataSetIndex], { x: hoveredXValue });
const precision = 3;

const sizeMap = {
  m: 60,
  l: 120,
  xl: 180,
};

class SparklineTooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  getHoverPoints(hoveredXValue) {
    if (isNil(hoveredXValue)) return;
    const {
      data,
      tooltips,
      scaleX,
      scaleY,
      xOffset,
      size,
      width,
      height,
      filter,
      valueLookup,
    } = this.props;
    return sortBy(
      compact(
        tooltips.map((tooltip) => {
          const { dataSetIndex = 0, yDomainIndex = 0 } = tooltip;
          const hoverValue = valueLookup({ data, dataSetIndex, hoveredXValue });

          if (!hoverValue) return;
          if (filter && !filter(hoverValue)) return;
          const x = scaleX(hoverValue.x) + xOffset;
          const y = scaleY[yDomainIndex](hoverValue.y);
          if (!isFinite(x) || !isFinite(y)) return;
          const format = tooltip.format || defaultFormat;
          const text = format(hoverValue);
          const lines = getLines(text);

          const rectWidth = sizeMap[size];
          const rectHeight = 15 * lines.length + 5;

          let xTranslate = x + 5;
          let yTranslate = y - 10;

          if (x + rectWidth > width) {
            xTranslate = x - rectWidth - 5;
          }
          if (y + rectHeight - 10 > height) {
            yTranslate = y - rectHeight;
          } else if (y < (rectHeight - 10) / 2) {
            yTranslate = y;
          }
          const translate = [xTranslate, yTranslate];

          return {
            circleX: x,
            circleY: y,
            tooltipX: translate[0],
            tooltipY: translate[1],
            width: rectWidth,
            height: rectHeight,
            lines,
            hoverValue,
            tooltip,
          };
        })
      ),
      "y"
    );
  }

  distributeHoverPointsInYAxis(h) {
    if (!h) return;
    const sortedHoverPoints = sortBy(h, "tooltipY");
    const { height, width } = this.props;
    for (let i = 0; i < sortedHoverPoints.length; i++) {
      const targetHoverPoint = sortedHoverPoints[i];
      const intersectedHoverPoints = [targetHoverPoint];
      let boundingRect = SparklineTooltip.getTooltipRect(targetHoverPoint);

      for (let s = i + 1; s < sortedHoverPoints.length; s++) {
        boundingRect = SparklineTooltip.extendBoundingRectangle(
          boundingRect,
          intersectedHoverPoints,
          height,
          sortedHoverPoints[s]
        );
      }

      for (let s = i - 1; s >= 0; s--) {
        boundingRect = SparklineTooltip.extendBoundingRectangle(
          boundingRect,
          intersectedHoverPoints,
          height,
          sortedHoverPoints[s]
        );
      }
      const sortedIntersectedHoverPoints = sortBy(
        intersectedHoverPoints,
        "tooltipY"
      );
      if (sortedIntersectedHoverPoints.length > 1) {
        let currentY = boundingRect.y;
        for (const sortedIntersectedHoverPoint of sortedIntersectedHoverPoints) {
          const hoverPoint = sortedIntersectedHoverPoint;
          hoverPoint.tooltipY = currentY;
          currentY += hoverPoint.height;
        }
      }
    }

    const hoverPointBounds = sortedHoverPoints.reduce(
      (agg, cur) => {
        agg.height += cur.height;
        agg.width = Math.max(cur.width, agg.width);
        return agg;
      },
      { height: 0, width: 0 }
    );

    if (hoverPointBounds.height > height) {
      adjustHoverPoints(hoverPointBounds, sortedHoverPoints, height, width);
    }

    return sortedHoverPoints;
  }

  static extendBoundingRectangle(
    boundingRect,
    intersectedHoverPoints,
    height,
    nextHoverPoint
  ) {
    const nextHoverPointTooltipRect =
      SparklineTooltip.getTooltipRect(nextHoverPoint);
    if (SparklineTooltip.intersects(boundingRect, nextHoverPointTooltipRect)) {
      intersectedHoverPoints.push(nextHoverPoint);
      boundingRect = SparklineTooltip.union(
        boundingRect,
        nextHoverPointTooltipRect
      );
      const newHeight = sumBy(intersectedHoverPoints, "height");
      const diffHeight = newHeight - boundingRect.height;
      const newY = boundingRect.y - diffHeight / 2;

      if (newY < 0) {
        boundingRect.y = 0;
      } else if (newY + newHeight > height) {
        boundingRect.y = height - newHeight;
        boundingRect.height = height;
      } else {
        boundingRect.height = newHeight;
        boundingRect.y = newY;
      }
    }
    return boundingRect;
  }

  static getTooltipRect({ tooltipX, tooltipY, width, height }) {
    return {
      x: tooltipX,
      y: tooltipY,
      width,
      height,
    };
  }

  static intersects(r1, r2) {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    );
  }

  static union(r1, r2) {
    return {
      x: Math.min(r1.x, r2.x),
      y: Math.min(r1.y, r2.y),
      width:
        r1.x < r2.x
          ? Math.max(r1.width, r2.x - r1.x + r2.width)
          : Math.max(r2.width, r1.x - r2.x + r1.width),
      height:
        r1.y < r2.y
          ? Math.max(r1.height, r2.y - r1.y + r2.height)
          : Math.max(r2.height, r1.y - r2.y + r1.height),
    };
  }

  renderTooltip(
    { tooltip, width, height, lines, circleX, circleY, tooltipX, tooltipY },
    index
  ) {
    if (isNaN(circleX) || isNaN(circleY)) {
      return;
    }
    const { showPoints, deselectedSeries } = this.props;
    if (deselectedSeries.has(tooltip.dataSetIndex)) {
      return null;
    }
    const textStyle = {
      fontSize: 12,
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fill: color("--content-card-bg"),
      textAnchor: "middle",
      fontWeight: "bold",
    };

    const { showBorders } = this.props;

    const borderProps = {
      stroke: showBorders ? "#171b25" : "none",
      strokeWidth: showBorders ? "1" : "0",
    };

    const circleStyle = {
      ...borderProps,
      fill: tooltip.color,
      pointerEvents: "auto",
    };

    if (tooltip.position) {
      const customPosition = tooltip.position({
        width,
        height,
        lines,
        circleX,
        circleY,
        tooltipX,
        tooltipY,
      });
      tooltipX = customPosition.tooltipX;
      tooltipY = customPosition.tooltipY;
    }

    return (
      <g key={`tooltip_${index}`}>
        {showPoints && (
          <g>
            <circle cx={circleX} cy={circleY} r={4} style={circleStyle} />
          </g>
        )}
        <g
          key={`tooltip_${index}`}
          transform={`translate(${tooltipX},${tooltipY})`}
        >
          <rect
            width={width}
            height={height}
            rx="10"
            ry="10"
            {...borderProps}
            fill={tooltip.color}
          />
          <text x={width / 2} y="15" style={textStyle}>
            {lines.map((line, i) => (
              <tspan key={i} x={width / 2} dy={`${i ? 1.2 : 0}em`}>
                {roundNumber(line, precision)}
              </tspan>
            ))}
          </text>
        </g>
      </g>
    );
  }

  render() {
    const { hoveredXValue } = this.props;
    const hoverPoints = compact(
      this.distributeHoverPointsInYAxis(this.getHoverPoints(hoveredXValue))
    );
    return (
      <g>
        {hoverPoints &&
          hoverPoints.map((hoverPoint, index) => {
            return this.renderTooltip(hoverPoint, index);
          })}
      </g>
    );
  }
}

SparklineTooltip.propTypes = {
  tooltips: PropTypes.arrayOf(
    PropTypes.shape({
      dataSetIndex: PropTypes.number,
      yDomainIndex: PropTypes.number,
      format: PropTypes.func,
      color: PropTypes.string.isRequired,
      position: PropTypes.func,
    })
  ).isRequired,
  allDomains: PropTypes.bool,
  showPoints: PropTypes.bool,
  showBorders: PropTypes.bool,
  size: PropTypes.oneOf(["m", "l", "xl"]),
  filter: PropTypes.func,
  valueLookup: PropTypes.func,
};

SparklineTooltip.defaultProps = {
  allDomains: true,
  showPoints: true,
  showBorders: false,
  size: "m",
  valueLookup: defaultValueLookup,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineTooltip {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
