import { vesselConfig } from "../action.types";

export const vesselImageChanged = (name, file) => ({
  type: vesselConfig.vesselDetails.VESSEL_IMAGE_CHANGED,
  name,
  file,
});

export const companyChanged = (value, isValid) => ({
  type: vesselConfig.vesselDetails.COMPANY_CHANGED,
  value,
  isValid,
});

export const vesselGroupChanged = (value, isValid) => {
  return {
    type: vesselConfig.vesselDetails.VESSEL_GROUP_CHANGED,
    value,
    isValid,
  };
};

export const inputChanged = (field, value, isValid) => ({
  type: vesselConfig.vesselDetails.INPUT_CHANGED,
  field,
  value,
  isValid,
});

export const saveVessel = (fields, copyFromVesselId) => ({
  type: vesselConfig.vesselDetails.SAVE_VESSEL,
  fields,
  copyFromVesselId,
});

export const showGenerateApiTokenDialog = () => ({
  type: vesselConfig.vesselDetails.SHOW_GENERATE_API_TOKEN_DIALOG,
});

export const closeGenerateApiTokenDialog = () => ({
  type: vesselConfig.vesselDetails.CLOSE_GENERATE_API_TOKEN_DIALOG,
});

export const generateApiToken = () => ({
  type: vesselConfig.vesselDetails.GENERATE_API_TOKEN,
});

export const showWindConfigPopup = () => ({
  type: vesselConfig.vesselDetails.SHOW_WINDCONFIG_POPUP,
});
export const hideWindConfigPopup = () => ({
  type: vesselConfig.vesselDetails.HIDE_WINDCONFIG_POPUP,
});
export const windConfigChanged = (file) => ({
  type: vesselConfig.vesselDetails.WIND_CONFIG_CHANGED,
  file,
});

export const saveWindConfig = (windCoefficients) => ({
  type: vesselConfig.vesselDetails.SAVE_WINDCONFIG,
  windCoefficients,
});
export const downloadWindConfig = (data, filename) => ({
  type: vesselConfig.vesselDetails.DOWNLOAD_WINDCONFIG,
  data,
  filename,
});

export const copyFromVesselChanged = (copyFromVessel) => ({
  type: vesselConfig.vesselDetails.COPY_FROM_VESSEL_CHANGED,
  copyFromVessel,
});

export const showSaveDialog = () => ({
  type: vesselConfig.vesselDetails.SHOW_SAVE_DIALOG,
});

export const hideSaveDialog = () => ({
  type: vesselConfig.vesselDetails.HIDE_SAVE_DIALOG,
});
