import React from "react";
import PropTypes from "prop-types";
import styles from "./Timer.css";

export default class Timer extends React.PureComponent {
  render() {
    return (
      <div className={styles.timer}>
        {this.props.prefix && <span>{this.props.prefix}</span>}
        <span className={styles.seconds}>{this.props.seconds}</span>
        {this.props.postfix && <span>{this.props.postfix}</span>}
      </div>
    );
  }

  static propTypes = {
    seconds: PropTypes.number.isRequired,
    prefix: PropTypes.string,
    postfix: PropTypes.string,
  };
}
