import React from "react";
import PropTypes from "prop-types";
import FormFileUpload from "../../../components/Form/FormFileUpload/FormFileUpload";
import DataGrid from "../../../components/DataGrid/DataGrid";
import styles from "./UserImportForm.css";

export class UserImportForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.gridConfig = {
      height: 400,
      columns: [
        {
          header: "First name",
          align: "left",
          field: "firstName",
          template: (rowData) => {
            return <span>{rowData.firstName}</span>;
          },
        },
        {
          header: "Last name",
          align: "left",
          field: "lastName",
          template: (rowData) => {
            return <span>{rowData.lastName}</span>;
          },
        },
        {
          header: "Email",
          align: "left",
          field: "email",
          template: (rowData) => {
            return <span>{rowData.email}</span>;
          },
        },
        {
          header: "Status",
          align: "left",
          field: "error",
          flexGrow: 2,
          template: (rowData) => {
            switch (rowData.error) {
              case "Validation":
                return (
                  <span className={styles.error} title={rowData.message}>
                    Validation error
                  </span>
                );
              case "AlreadyExists":
                return <span className={styles.error}>Already exists</span>;
              case "System":
                return <span className={styles.error}>Error</span>;
              default:
                return rowData.status === "Success" ? <>OK</> : <></>;
            }
          },
        },
      ],
    };
  }

  render() {
    return (
      <div>
        {this.props.data.length === 0 && (
          <FormFileUpload
            name={"file"}
            label={"Upload file"}
            onChange={this.props.onFileChange}
          />
        )}
        {this.props.data.length > 0 && (
          <div style={{ height: "500px" }}>
            <DataGrid data={this.props.data} {...this.gridConfig} />
          </div>
        )}
      </div>
    );
  }
}

UserImportForm.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      errors: PropTypes.array,
    })
  ).isRequired,
};
