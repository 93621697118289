import React from "react";
import PropTypes from "prop-types";
import { SparklineContext } from "./Sparkline";
import Hammer from "hammerjs";
import { get } from "lodash";

class SparklinePanAndZoomHandler extends React.PureComponent {
  isPanning = false;

  getTargetPos = (x, y) => {
    const rect = this.zoomTarget.getBoundingClientRect();
    return {
      x: x - rect.left,
      y: y - rect.top,
    };
  };

  handleWheel = (e) => {
    e.preventDefault();
    const { scrollStep } = this.props;
    const scale = e.deltaY > 0 ? 1 - scrollStep : 1 / (1 - scrollStep);
    if (this.props.onZoom) {
      this.props.onZoom({
        scale,
        ...this.getTargetPos(e.clientX, e.clientY),
        width: this.props.context.width,
      });
    }
  };

  handlePanRight = (e) => {
    const prevPanDistance = this.panDistance;
    let distance = e.distance;
    this.panDistance = distance;
    let diffDistance = Math.abs(prevPanDistance - distance);
    let targetPos = this.getTargetPos(e.clientX, e.clientY);
    if (this.props.onPan) {
      this.props.onPan({
        x: diffDistance,
        targetX: targetPos.x,
      });
    }
  };

  handlePanLeft = (e) => {
    const prevPanDistance = this.panDistance;
    let distance = e.distance;
    this.panDistance = distance;
    let diffDistance = Math.abs(prevPanDistance - distance);
    if (this.props.onPan) {
      this.props.onPan({
        x: -diffDistance,
      });
    }
  };

  handlePanStart = () => {
    if (this.isPanning === true) {
      return;
    }
    this.isPanning = true;
    this.panDistance = 0;
  };

  handlePanEnd = () => {
    this.isPanning = false;
  };

  handlePinchEnd = (e) => {
    if (this.props.onZoom) {
      this.props.onZoom({
        scale: e.scale,
        ...this.getTargetPos(e.center.x, e.center.y, e.target),
        width: this.props.context.width,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      (get(this.props, "context.width") !== get(prevProps, "context.width") ||
        get(this.props, "context.height") !==
          get(prevProps, "context.height")) &&
      this.props.onResize
    ) {
      this.props.onResize({
        width: get(this.props, "context.width"),
        height: get(this.props, "context.height"),
      });
    }
  }

  componentDidMount() {
    this.hammer = new Hammer(this.zoomElement);

    this.hammer.get("pinch").set({
      enable: true,
      threshold: 0,
    });

    this.hammer.get("pan").set({
      direction: Hammer.DIRECTION_HORIZONTAL,
      pointers: 1,
    });

    this.hammer.on("panleft", this.handlePanLeft);
    this.hammer.on("panright", this.handlePanRight);
    this.hammer.on("panstart", this.handlePanStart);
    this.hammer.on("panend", this.handlePanEnd);
    this.hammer.on("pinchend", this.handlePinchEnd);

    this.zoomElement.addEventListener("wheel", this.handleWheel);
  }

  componentWillUnmount() {
    this.zoomElement.removeEventListener("wheel", this.handleWheel);
    this.hammer.destroy();
  }

  render() {
    const { children, context } = this.props;
    return (
      <g ref={(el) => (this.zoomElement = el)} pointerEvents="bounding-box">
        <g
          ref={(el) => (this.zoomTarget = el)}
          width={context.width}
          height={context.height}
          fillOpacity={0}
          fill={"#000000"}
        />
        <SparklineContext.Provider
          value={{
            ...context,
          }}
        >
          {children}
        </SparklineContext.Provider>
      </g>
    );
  }
}

SparklinePanAndZoomHandler.defaultProps = {
  scrollStep: 0.2,
};

SparklinePanAndZoomHandler.propTypes = {
  onZoom: PropTypes.func,
  onPan: PropTypes.func,
  onResize: PropTypes.func,
  scrollStep: PropTypes.number,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => (
      <SparklinePanAndZoomHandler context={context} {...props} ref={ref} />
    )}
  </SparklineContext.Consumer>
));
