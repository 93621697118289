import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVessels,
  fetchEnergyBudgetForVessel,
} from "../../../actions/admin/action.energyBudget";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import SelectBox from "../../../components/SelectBox/SelectBox";
import MonthsContainer from "./MonthsContainer";
import adminStyles from "../Admin.css";
import { useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import {
  parseQueryString,
  serializeQueryString,
} from "../../../../common/queryStrings";

const EnergyBudget = () => {
  const { search = {} } = useLocation();
  const queryParams = parseQueryString(search);

  const vessels = useSelector(selectVessels);
  const energyBudget = useSelector(
    (state) => state.admin.energyBudget.master.data
  );
  const vesselOptions = useSelector(selectVesselOptions);

  const selectedVessel = useMemo(
    () =>
      vesselOptions.find(
        (vesselOption) => vesselOption.id === queryParams.vesselId
      ),
    [vesselOptions, queryParams.vesselId]
  );
  const selectedMetric = useMemo(
    () =>
      metricOptions.find(
        (metricOption) => metricOption.id === queryParams.metricId
      ),
    [metricOptions, queryParams.metricId]
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = `/admin/energybudget`;

  useEffect(() => {
    dispatch(fetchVessels());
    if (!queryParams.aggregation) {
      navigate(
        {
          pathname,
          search: serializeQueryString({
            ...queryParams,
            aggregation: "month",
          }),
        },
        { replace: true }
      );
    } else {
      fetchEnergyBudget();
    }
  }, []);

  useEffect(() => {
    fetchEnergyBudget();
  }, [JSON.stringify(selectedVessel), JSON.stringify(selectedMetric)]);

  const fetchEnergyBudget = () => {
    if (selectedVessel && selectedMetric) {
      dispatch(
        fetchEnergyBudgetForVessel(selectedVessel.id, selectedMetric.id)
      );
    }
  };

  const onSelectedVesselChange = ({ id }) => {
    navigate({
      pathname,
      search: serializeQueryString({ ...queryParams, vesselId: id }),
    });
  };

  const onSelectedMetricChange = ({ id }) => {
    navigate({
      pathname,
      search: serializeQueryString({ ...queryParams, metricId: id }),
    });
  };

  const renderForm = () => {
    if (energyBudget.isLoading || energyBudget.error) {
      return <Loader error={energyBudget.error} />;
    }

    if (!selectedVessel || !selectedMetric || !energyBudget.data) {
      return;
    }

    if (queryParams.aggregation === "month") {
      return (
        <MonthsContainer
          vesselId={selectedVessel.id}
          metric={selectedMetric}
          data={energyBudget.data}
        />
      );
    }
  };

  return (
    <div>
      <Header title="Admin - Energy Budget" contentDistribution="space-between">
        <div className={adminStyles.headerContainer}>
          <SelectBox
            className={adminStyles.headerContainerItem}
            placeholder={vessels.isLoading ? "Loading..." : "Select vessel"}
            options={vesselOptions}
            optionValKey="id"
            optionLabelKey="name"
            selected={selectedVessel}
            onSelect={onSelectedVesselChange}
          />
          <SelectBox
            className={adminStyles.headerContainerItem}
            placeholder={"Select metric"}
            options={metricOptions}
            optionValKey="id"
            optionLabelKey="name"
            selected={selectedMetric}
            onSelect={onSelectedMetricChange}
          />
        </div>
      </Header>
      <div className={adminStyles.container}>{renderForm()}</div>
    </div>
  );
};

// todo fetch applicable metrics from backend
const metricOptions = [
  {
    id: "23210EA9-2882-473D-B4EA-5EFE1A94DBBC",
    name: "Fuel consumption (MDO) m3",
    unit: "m3",
  },
  {
    id: "00ADBED8-8A4B-4F6A-9572-61000BAA066F",
    name: "Fuel consumption (MDO) t",
    unit: "t",
  },
  {
    id: "D9522680-7340-4150-8EEF-6335D452B5F4",
    name: "Fuel consumption (HFO) m3",
    unit: "m3",
  },
  {
    id: "8DD1E72E-E507-4D70-92A5-D2B319845FEE",
    name: "Fuel consumption (HFO) t",
    unit: "t",
  },
  {
    id: "31482F1F-010F-4E41-BF8D-EF3F2EBBD115",
    name: "Fuel consumption (LNG) t",
    unit: "t",
  },
];

const selectVessels = (state) => state.admin.energyBudget.master.vessels;

const selectVesselOptions = createSelector(
  selectVessels,
  (vessels) => vessels.data || []
);

export default EnergyBudget;
