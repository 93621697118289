import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchVesselCount } from "../actions";
import DonutContainer from "../components/DonutContainer";
import { AnyAction } from "redux";

const VesselCountContainer = () => {
  const dispatch = useDispatch();
  const donuts = useSelector((state: any) => [state.vesselCount]);

  useEffect(() => {
    dispatch(fetchVesselCount() as AnyAction);
  }, []);

  return <DonutContainer donuts={donuts} testId="widget-vesselCount" />;
};

export default VesselCountContainer;
