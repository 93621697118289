import React, { FC } from "react";
import FormGroup from "../Form/FormGroup/FormGroup";
import FormPopup from "../Form/FormPopup/FormPopup";
import { FuelDataType } from "./Types";
import Row from "./Row";
import EntryDate from "./EntryDate";
import Info from "./Info";
import AddButton from "./AddButton";

interface Props {
  visible: boolean;
  isSaving: boolean;
  data: FuelDataType[];
  fuelTypes: string[];
  date: string;
  onFormDataChanged: (
    field: string,
    value: any,
    index: number,
    meta?: any
  ) => void;
  onSave: () => void;
  onCancel: () => void;
  addRow: () => void;
  removeRow: (index: number) => void;
}

const ManualFuelDataDialog: FC<Props> = ({
  visible,
  isSaving,
  data,
  fuelTypes,
  date,
  onFormDataChanged,
  onSave,
  onCancel,
  addRow,
  removeRow,
}) => {
  if (!visible) return <div />;

  function isValid(): boolean {
    return data.every((row) => row.operationLegend && row.duration);
  }

  const renderRows = () => {
    return data.map((_item, i) => {
      return (
        <Row
          rowNumber={i}
          disabled={isSaving}
          data={data}
          fuelTypes={fuelTypes}
          onFormDataChanged={onFormDataChanged}
          removeRow={removeRow}
          key={`Row-${i}`}
        />
      );
    });
  };

  return (
    <FormPopup
      visible={true}
      title={"Add/Edit Fuel Data"}
      onHide={onCancel}
      onCancel={onCancel}
      onSave={onSave}
      enableSave={isValid() && !isSaving}
      enableCancel={!isSaving}
    >
      <EntryDate date={date} />
      <FormGroup>
        {renderRows()}
        <AddButton addRow={addRow} />
      </FormGroup>
      <Info />
    </FormPopup>
  );
};

export default ManualFuelDataDialog;
