import React from "react";
import { formatDate, formatTime } from "../../common/dates";

const HmAlertTimestampPropertyValue = ({ value }) => {
  const date = new Date(value);
  return (
    <div className={"propertyContainer"}>
      <span className={"propertyValue"}>{formatDate(date)}</span>
      <span className={"propertyName"}>{formatTime(date)}</span>
    </div>
  );
};

export default HmAlertTimestampPropertyValue;
