import vpLogoSrc from "../../style/img/VP_Icon_SVG_100px_Vessel_Performance.svg";
import styles from "./styling/styles.css";
import React from "react";

function VpLogo() {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${vpLogoSrc})` }}
        className={styles.vpLogo}
      />
    </div>
  );
}

export default VpLogo;
