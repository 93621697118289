import React from "react";
import { connect } from "react-redux";
import { getLimits } from "../../common/vessels";
import { getSelectedVessel } from "../../reducers/reducer.pageContext";
import styles from "./AnalysisFilter.css";
import {
  Slider,
  VALUE_TYPE_RANGE_EXCLUSIVE_END,
  VALUE_TYPE_RANGE,
} from "../Slider/Slider";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import DatePickerContainer from "../DatePicker/DatePickerContainer";
import Label from "../Label/Label";
import {
  setFilterValue,
  toggleOperation,
  toggleEngine,
  togglePropulsor,
  setAxisValueType,
  setZDomain,
  setAnalysisVessel,
} from "../../actions/action.analysis";

import SelectBox from "../SelectBox/SelectBox";
import { find, isEmpty } from "lodash";
import Loader from "../../../common/components/Loader";

class AnalysisFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleOperationItemChecked =
      this.handleOperationItemChecked.bind(this);
    this.handleEngineItemChecked = this.handleEngineItemChecked.bind(this);
    this.handlePropulsorItemChecked =
      this.handlePropulsorItemChecked.bind(this);
  }

  componentDidMount() {
    const { activeVesselId } = this.props;

    if (activeVesselId) {
      this.props.setAnalysisVessel(activeVesselId);
    }
  }

  handleDateChange(date) {
    this.props.setAnalysisDate(date);
  }

  handleValueChange(filterType, evt) {
    this.props.setFilterValue(filterType, evt.minValue, evt.maxValue);
  }

  handleZDomainChange(evt) {
    this.props.setZDomain([evt.minValue, evt.maxValue]);
  }

  handleOperationItemChecked(item) {
    this.props.toggleOperation(item.id);
  }

  handleEngineItemChecked(item) {
    this.props.toggleEngine(item.id);
  }

  handlePropulsorItemChecked(item) {
    this.props.togglePropulsor(item.id);
  }

  handleAxisValueTypeChange(axis, type) {
    //These checks are to prevent the selectbox from crashing the page
    if (!type) {
      return;
    }
    if (axis === "x" || axis === "y") {
      if (!find(this.props.axisTypes, (x) => x.id === type.id)) {
        return;
      }
    } else if (axis === "z") {
      if (!find(this.props.valueTypes, (x) => x.id === type.id)) {
        return;
      }
    }
    this.props.setAxisValueType(axis, type);
  }

  static filterDataToCheckboxItems(filterData) {
    if (!filterData || !filterData.data) return [];
    return filterData.data.map((d) => ({
      id: d.id,
      label: d.name,
      subLabel: `(${d.numberOfEntries})`,
      checked: !!d.isSelected,
    }));
  }

  render() {
    const {
      date,
      vesselDateLimits,
      activeVesselId,
      filters,
      operations,
      engines,
      propulsors,
      valueTypes,
      xAxisType,
      yAxisType,
      zAxisType,
      zDomain,
      axisTypes,
    } = this.props;
    const { Speed, Wind, RunningEngines, PitchEnergyFast } = filters.data;

    return (
      <div className={styles.analysisFilterContainer}>
        <div className={styles.filterElement}>
          <Label label="Color axis" />
          <SelectBox
            options={valueTypes}
            selected={zAxisType}
            optionValKey="label"
            onSelect={(val) => this.handleAxisValueTypeChange("z", val)}
          />
        </div>
        <div className={styles.filterElement}>
          <Slider
            label={zAxisType.label}
            valueType="single"
            maxValue={zDomain.max}
            minValue={zDomain.min}
            values={zDomain.values}
            onValueChange={(evt) => this.handleZDomainChange(evt)}
          />
        </div>
        <div className={styles.filterElement}>
          <Label label="Y axis" />
          <SelectBox
            options={axisTypes}
            selected={yAxisType}
            optionValKey="label"
            onSelect={(val) => this.handleAxisValueTypeChange("y", val)}
          />
        </div>
        <div className={styles.filterElement}>
          <Label label="X axis" />
          <SelectBox
            options={axisTypes}
            selected={xAxisType}
            optionValKey="label"
            onSelect={(val) => this.handleAxisValueTypeChange("x", val)}
          />
        </div>
        <div className={styles.filterElement}>
          <Label label="Date" />
          <DatePickerContainer
            current={date}
            limits={vesselDateLimits}
            vesselId={activeVesselId}
            onChange={this.handleDateChange}
            headerContainerStyle={{
              minWidth: "13.5rem",
              margin: "0 0.5rem",
            }}
          />
        </div>
        {!isEmpty(operations.data) && (
          <div className={styles.filterElement}>
            <CheckboxGroup
              label="Operations"
              items={AnalysisFilter.filterDataToCheckboxItems(operations)}
              onItemChecked={this.handleOperationItemChecked}
            />
          </div>
        )}
        {engines.isLoading !== true && !isEmpty(engines.data) && (
          <div className={styles.filterElement}>
            <CheckboxGroup
              label="Engines"
              items={AnalysisFilter.filterDataToCheckboxItems(engines)}
              onItemChecked={this.handleEngineItemChecked}
            />
          </div>
        )}
        {!isEmpty(propulsors.data) && (
          <div className={styles.filterElement}>
            <CheckboxGroup
              label="Propulsors"
              items={AnalysisFilter.filterDataToCheckboxItems(propulsors)}
              onItemChecked={this.handlePropulsorItemChecked}
            />
          </div>
        )}
        {(engines.isLoading === true ||
          propulsors.isLoading === true ||
          operations.isLoading === true) && (
          <div className={styles.filterElement}>
            <Loader text={"Loading"} expand={false} />
          </div>
        )}
        {Wind.isLoading !== true && (
          <div className={styles.filterElement}>
            <Slider
              label="Wind"
              unit="m/s"
              valueType={VALUE_TYPE_RANGE}
              minValue={Wind.minValue}
              maxValue={Wind.maxValue}
              values={Wind.values}
              onValueChange={(evt) => this.handleValueChange("Wind", evt)}
            />
          </div>
        )}
        {RunningEngines.isLoading !== true && (
          <div className={styles.filterElement}>
            <Slider
              label="Number of engines running"
              valueType={VALUE_TYPE_RANGE_EXCLUSIVE_END}
              minValue={RunningEngines.minValue}
              maxValue={RunningEngines.maxValue}
              values={RunningEngines.values}
              onValueChange={(evt) =>
                this.handleValueChange("RunningEngines", evt)
              }
            />
          </div>
        )}
        {Speed.isLoading !== true && (
          <div className={styles.filterElement}>
            <Slider
              label="Vessel speed"
              unit="kn"
              valueType={VALUE_TYPE_RANGE}
              minValue={Speed.minValue}
              maxValue={Speed.maxValue}
              values={Speed.values}
              onValueChange={(evt) => this.handleValueChange("Speed", evt)}
            />
          </div>
        )}
        {Speed.isLoading !== true && (
          <div className={styles.filterElement}>
            <Slider
              label="Wave indicator"
              valueType={VALUE_TYPE_RANGE}
              minValue={PitchEnergyFast.minValue}
              maxValue={PitchEnergyFast.maxValue}
              values={PitchEnergyFast.values}
              onValueChange={(evt) =>
                this.handleValueChange("PitchEnergyFast", evt)
              }
            />
          </div>
        )}
        {Object.values(filters.data).some((f) => f.isLoading === true) && (
          <div className={styles.filterElement}>
            <Loader text={"Loading filters"} expand={false} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  valueTypes: state.analysis.valueTypes,
  xAxisType: state.analysis.xAxisType,
  yAxisType: state.analysis.yAxisType,
  zAxisType: state.analysis.zAxisType,
  filters: {
    data: state.analysis.filters,
    isLoading: Object.entries(state.analysis.filters)
      .map(([filter, value]) => ({ filter, isLoading: value.isLoading }))
      .some((f) => f.isLoading),
  },
  operations: state.analysis.operations,
  engines: state.analysis.engines,
  propulsors: state.analysis.propulsors,
  vesselDateLimits: getLimits(
    getSelectedVessel({
      selectedVesselId: props.activeVesselId,
      vessels: state.pageContext.vessels,
    })
  ),
  vessels: state.pageContext.vessels,
  zDomain: state.analysis.zDomain,
  axisTypes: state.analysis.axisTypes.data,
});

const mapDispatchToProps = {
  setAnalysisVessel,
  setFilterValue,
  toggleOperation,
  toggleEngine,
  togglePropulsor,
  setAxisValueType,
  setZDomain,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisFilter);
