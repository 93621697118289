import React, { Component } from "react";
import { connect } from "react-redux";
import { VesselStatusList } from "../../components/VesselStatus/VesselStatusList";
import { fetchVesselStatus } from "../../actions/action.fetchVesselStatus";
import { Header } from "../../components/Header";

class ServiceDashboard extends Component {
  constructor(props) {
    super(props);
    props.fetchVesselStatus();
  }

  render() {
    return (
      <div>
        <Header title="Service dashboard" />
        <VesselStatusList {...this.props.vesselStatus} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ vesselStatus: state.vesselStatus });
const mapActionsToProps = { fetchVesselStatus };

export default connect(mapStateToProps, mapActionsToProps)(ServiceDashboard);
