import * as d3 from "d3";

export function getScale(scaleType, { exponent }) {
  let scale;
  switch (scaleType.toLowerCase()) {
    case "logarithmic":
      scale = d3.scaleSymlog();
      break;

    case "exponential":
      scale = d3.scalePow().exponent(exponent ? +exponent : 1);
      break;

    default:
      scale = d3.scaleLinear();
      break;
  }

  return scale;
}
