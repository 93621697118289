import React from "react";
import PropTypes from "prop-types";
import styles from "./LocationMapMarker.css";
import { LOCATIONMARKERS } from "../../../config";

export default class LocationMapMarker extends React.PureComponent {
  render() {
    const { name, type, showLabel, showCircle, radius } = this.props;
    return (
      <div className={styles.container}>
        {!!showCircle && !!radius && (
          <div
            className={styles.circle}
            style={{
              width: radius * 2,
              height: radius * 2,
              top: 24 - radius,
              left: 24 - radius,
            }}
          />
        )}
        <img className={styles.marker} src={LOCATIONMARKERS.base64[type]} />
        {showLabel && (
          <div className={styles.label}>
            <span>{name}</span>
          </div>
        )}
      </div>
    );
  }
}

LocationMapMarker.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf(["area", "platform", "harbor"]),
  radius: PropTypes.number,
  showLabel: PropTypes.bool,
  showCircle: PropTypes.bool,
};
