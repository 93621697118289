import PropTypes from "prop-types";
import React from "react";
import styles from "./FleetConnectionStatus.css";

export function FleetConnectionStatus(props) {
  return (
    <div className={styles.container}>
      <h2>Connection status</h2>
      <p>
        <em>{props.connectedVesselCount}</em> of <em>{props.vesselCount}</em>{" "}
        vessels connected.
      </p>
    </div>
  );
}

FleetConnectionStatus.propTypes = {
  vesselCount: PropTypes.number,
  connectedVesselCount: PropTypes.number,
};
