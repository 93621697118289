import React from "react";
import styles from "./styling/styles.css";
import backgroundImageSrc from "./styling/background.jpg";
import VpLogo from "./VpLogo";
import IAuthService from "./IAuthService";
import LoginHeader from "./LoginHeader";
import { PrivacyContainer } from "./PrivacyContainer";

function UnauthorizedForm({ authService }: { authService: IAuthService }) {
  function handleLogout() {
    void authService.logout();
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevents the default form submission behavior
    handleLogout();
  }

  return (
    <form onSubmit={handleSubmit} data-testid="logout-form">
      <div className={styles.message}>Unauthorized login attempt</div>
      <div className={styles.formGroup}>
        <button
          type="submit"
          className={styles.loginBtn}
          data-testid="logout-form-submit"
        >
          Log out
        </button>
      </div>
    </form>
  );
}

interface Props {
  authService: IAuthService;
}

export const Unauthorized = ({ authService }: Props) => {
  return (
    <div>
      <div
        style={{ backgroundImage: `url(${backgroundImageSrc})` }}
        className={styles.mainContainer}
      >
        <div className={styles.mainContainerOverlay}>
          <LoginHeader />
          <div className={styles.innerContainer}>
            <VpLogo />
            <UnauthorizedForm authService={authService} />
            <PrivacyContainer />
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};
