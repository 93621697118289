import {
  takeEvery,
  put,
  fork,
  select,
  getContext,
  call,
  all,
  takeLeading,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi, ADMIN_PERFORMANCE_INDICATORS } from "../../api";
import { convertFieldsToKeyValues } from "../../common/forms";

const actions = vesselConfig.vesselPerformanceIndicators;
const api = vesselConfigApi.vesselPerformanceIndicators;

export function* initVesselPerformanceIndicators({ vesselId }) {
  yield call(fetchData, vesselId);
  yield fork(saveWatcher);
  yield fork(deleteWatcher);
}

function* fetchData(vesselId) {
  const http = yield getContext("http");
  yield put({ type: actions.FETCH, vesselId });
  try {
    const [piRes, vpiRes] = yield all([
      call(http.get, ADMIN_PERFORMANCE_INDICATORS.get()),
      call(http.get, api.get(vesselId)),
    ]);
    yield put({
      type: actions.FETCH_SUCCESS,
      performanceIndicators: piRes.data,
      vesselPerformanceIndicators: vpiRes.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* saveWatcher() {
  yield takeEvery(actions.SAVE, saveVesselPerformanceIndicator);
}

function* saveVesselPerformanceIndicator({ id }) {
  const http = yield getContext("http");
  const state = yield select((s) => s.admin.vesselConfigPerformanceIndicators);
  const { vesselId } = state;
  const { form } = state.data.find((x) => x.id === id);
  const { showMinMaxValue } = form;
  const formFields = convertFieldsToKeyValues({ ...form });
  const data = [...formFields.performanceIndicators.map((x) => ({ ...x }))];
  const firstPerformanceIndicator = data[0];

  if (showMinMaxValue) {
    firstPerformanceIndicator.minValue = formFields.minValue;
    firstPerformanceIndicator.maxValue = formFields.maxValue;
  }

  const isDuplicate = state.data.some(
    (x) =>
      x.id !== id && x.performanceIndicatorId === data.performanceIndicatorId
  );
  if (isDuplicate) {
    yield put({
      type: actions.SAVE_ERROR,
      id,
      error: { message: "The selected performance indicator already exists." },
    });
    return;
  }

  try {
    const isNew = !id;
    const res = yield isNew
      ? call(http.post, api.post(vesselId), data)
      : call(http.put, api.put(vesselId, id), firstPerformanceIndicator);
    yield put({
      type: actions.SAVE_SUCCESS,
      isNew,
      data: res.data,
    });
  } catch (e) {
    yield put({ type: actions.SAVE_ERROR, id, error: e });
  }
}

function* deleteWatcher() {
  yield takeLeading(actions.CONFIRM_DELETE, deleteVesselPerformanceIndicator);
}

function* deleteVesselPerformanceIndicator({ id }) {
  const http = yield getContext("http");
  const vesselId = yield select(
    (s) => s.admin.vesselConfigPerformanceIndicators.vesselId
  );

  try {
    yield call(http.delete, api.delete(vesselId, id));
    yield put({ type: actions.CONFIRM_DELETE_SUCCESS, id });
  } catch (error) {
    yield put({ type: actions.CONFIRM_DELETE_ERROR, error });
  }
}
