import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NodeItemContainer from "./NodeItemContainer";
import NodeItemDragLayer from "./NodeItemDragLayer";
import PropTypes from "prop-types";

export const NodeRelationContext = React.createContext({
  onRelationAdded: null,
  onRelationRemoved: null,
});

const NodeRelation = ({
  width,
  height,
  nodes,
  relations,
  onRelationAdded,
  onRelationRemoved,
  onNodeDrop,
  onNodeRemoved,
}) => {
  return (
    <div style={{ width, height }}>
      <DndProvider backend={HTML5Backend}>
        <NodeRelationContext.Provider
          value={{
            onRelationAdded,
            onRelationRemoved,
            onNodeRemoved,
            onNodeDrop,
          }}
        >
          <NodeItemContainer nodes={nodes} relations={relations} />
          <NodeItemDragLayer />
        </NodeRelationContext.Provider>
      </DndProvider>
    </div>
  );
};

NodeRelation.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  relations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sourceId: PropTypes.string.isRequired,
      destinationId: PropTypes.string.isRequired,
    })
  ),
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      transformX: PropTypes.number,
      transformY: PropTypes.number,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
    })
  ),
  onRelationAdded: PropTypes.func,
  onRelationRemoved: PropTypes.func,
  onNodeDrop: PropTypes.func,
  onNodeRemoved: PropTypes.func,
};

export default NodeRelation;
