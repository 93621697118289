import PropTypes from "prop-types";
import React from "react";
import styles from "./Divider.css";

export default class Divider extends React.Component {
  render() {
    let dividerStyles = {
      display: "block",
    };

    if (!this.props.visible) {
      dividerStyles.display = "none";
    }

    return <div style={dividerStyles} className={styles.divider} />;
  }
}

Divider.propTypes = {
  visible: PropTypes.bool,
};
