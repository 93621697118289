import PropTypes from "prop-types";
import React from "react";
import Button from "../../../common/components/Button";
import { color } from "../../../common/colors";

export default class ButtonToggle extends React.Component {
  render() {
    const buttonProps = {
      ...this.props,
      type: "smallButton",
      borderColor:
        this.props.active === true
          ? this.props.borderColor
          : color("--mid-grey"),
      color:
        this.props.active === true ? color("--white") : color("--mid-grey"),
    };

    return <Button {...buttonProps} />;
  }
}

ButtonToggle.propTypes = {
  value: PropTypes.string,
  clickAction: PropTypes.func,
  active: PropTypes.bool,
  borderColor: PropTypes.string,
};
