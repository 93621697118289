import { FETCH_COMPARE } from "./action.types";
import { COMPARE } from "../api";
import fetch from "./action.fetch";

export const fetchCompare = (
  vesselId,
  daterange,
  datetype,
  timeOffset,
  operationIds,
  key = window.crypto.randomUUID()
) =>
  fetch({
    url: COMPARE.get(vesselId, daterange, operationIds, timeOffset),
    actionType: FETCH_COMPARE,
    actionData: {
      daterange,
      datetype,
      vesselId,
      key,
      timeOffset,
    },
    takeAll: true,
  });
