export const uppercaseFirstChar = (item: string | undefined = undefined) => {
  return item ? item.charAt(0).toUpperCase() + item.slice(1) : item;
};

export function truncate(string: string, maxLength: number): string {
  if (string.length > maxLength) {
    string = string.substring(0, maxLength - 3) + "...";
  }
  return string;
}
