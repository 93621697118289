import * as d3 from "d3";
import {
  compact,
  filter,
  flatMap,
  flatten,
  flow,
  getOr,
  groupBy,
  map,
  sortBy,
  sumBy,
  toPairs,
} from "lodash/fp";
import {
  components,
  FETCH_TREND_DATA,
  FETCH_OPERATION_TREND,
  FETCH_OPERATION_TREND_ERROR,
  FETCH_OPERATION_TREND_SUCCESS,
  FETCH_TREND_FOR_PERFORMANCE_INDICATOR,
  FETCH_TREND_FOR_PERFORMANCE_INDICATOR_ERROR,
  FETCH_TREND_FOR_PERFORMANCE_INDICATOR_SUCCESS,
  FETCH_TREND_FOR_VESSEL_ITEM,
  FETCH_TREND_FOR_VESSEL_ITEM_SUCCESS,
} from "../actions/action.types";
import { color } from "../../common/colors";
import {
  addHours,
  addMillis,
  fromISOString,
  getDaysBetween,
} from "../common/dates";
import { roundConvert } from "../../common/numbers";
import {
  find,
  get,
  keys,
  values,
  reduce,
  isEqual,
  unionBy,
  isArray,
} from "lodash";
import { OPERATION_MODES } from "../../common/config";

const getZeroValues = (acc, curVal, curIdx, arr) => {
  if (!curVal.performanceValue) {
    acc.push(curVal);
  } else {
    const prevVal = arr[curIdx - 1];
    if (prevVal && !prevVal.performanceValue) {
      acc.push({ ...curVal, hideTooltip: true });
    } else {
      acc.push({ ...curVal, value: undefined });
    }
  }
  return acc;
};

const getAboveValues = (acc, curVal, curIdx, arr) => {
  if (curVal.performanceValue > 0) {
    acc.push(curVal);
  } else {
    const prevVal = arr[curIdx - 1];
    if (prevVal && prevVal.performanceValue > 0) {
      acc.push({ ...curVal, hideTooltip: true });
    } else {
      acc.push({ ...curVal, value: undefined });
    }
  }
  return acc;
};

const getBelowValues = (acc, curVal, curIdx, arr) => {
  if (curVal.performanceValue < 0) {
    acc.push(curVal);
  } else {
    const prevVal = arr[curIdx - 1];
    if (prevVal && prevVal.performanceValue < 0) {
      acc.push({ ...curVal, hideTooltip: true });
    } else {
      acc.push({ ...curVal, value: undefined });
    }
  }
  return acc;
};

const isActionEqualValue = (action, value) =>
  isEqual(action.vesselId, value.vesselId) &&
  isEqual(action.dateRange, value.dateRange) &&
  isEqual(action.timeOffset, value.timeOffset) &&
  isEqual(action.states, value.states);

export default (
  state = {
    operationsData: {},
    vesselItemsData: {},
    performanceIndicatorsData: {},
    hover: {},
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_TREND_DATA: {
      return {
        ...state,
        vesselItemsData: reduce(
          state.vesselItemsData,
          (agg, value, key) => {
            const activeVesselItem = action.vesselItems?.find(
              (vi) => isEqual(vi.id, key) && vi.isActive === true
            );
            if (activeVesselItem && isActionEqualValue(action, value)) {
              agg[key] = {
                ...value,
                values: value.values?.reduce((prev, cur) => {
                  const activeMetric = activeVesselItem.metrics?.find(
                    (m) => isEqual(m.id, cur.id) && m.isActive === true
                  );
                  if (activeMetric) {
                    prev.push(cur);
                  }
                  return prev;
                }, []),
              };
            }
            return agg;
          },
          {}
        ),
        performanceIndicatorsData: reduce(
          state.performanceIndicatorsData,
          (agg, value, key) => {
            if (
              action.performanceIndicators.entries.some(
                (pi) => isEqual(pi.id, key) && pi.isActive === true
              )
            ) {
              if (isActionEqualValue(action, value)) {
                agg[key] = value;
              }
            }
            return agg;
          },
          {}
        ),
      };
    }

    case FETCH_OPERATION_TREND:
      return {
        ...state,
        operationsData: {
          isLoading: true,
        },
      };

    case FETCH_OPERATION_TREND_SUCCESS:
      return {
        ...state,
        operationsData: {
          isLoading: false,
          aggregated: get(action.data, "aggregated", false),
          data: get(action.data, "values", []),
          dateRange: action.dateRange,
          vesselId: action.vesselId,
          timeOffset: action.timeOffset,
        },
      };

    case FETCH_OPERATION_TREND_ERROR:
      return {
        ...state,
        operationsData: {
          isLoading: false,
          error: action.error,
        },
      };

    case FETCH_TREND_FOR_VESSEL_ITEM:
      return {
        ...state,
        vesselItemsData: {
          ...state.vesselItemsData,
          [action.vesselItemId]: {
            ...state.vesselItemsData[action.vesselItemId],
            dateRange: action.dateRange,
            vesselId: action.vesselId,
            timeOffset: action.timeOffset,
            values: unionBy(
              get(action, "metricIds", {}).map((m) => ({
                id: m,
                isLoading: true,
              })),
              get(state, `vesselItemsData[${action.vesselItemId}].values`, {}),
              "id"
            ),
          },
        },
      };

    case FETCH_TREND_FOR_VESSEL_ITEM_SUCCESS: {
      const data = get(action, "data[0]", []);
      const currentVesselItemData = get(
        state,
        `vesselItemsData[${action.vesselItemId}]`,
        {}
      );
      const newData = get(data, "values");
      return {
        ...state,
        vesselItemsData: {
          ...state.vesselItemsData,
          [action.vesselItemId]: {
            ...currentVesselItemData,
            ...data,
            values: isArray(newData)
              ? unionBy(
                  newData.map((m) => ({ ...m, isLoading: false })),
                  get(currentVesselItemData, "values", []) || [],
                  "id"
                )
              : get(currentVesselItemData, "values", []).map((m) => ({
                  ...m,
                  isLoading: false,
                })),
          },
        },
      };
    }

    case FETCH_TREND_FOR_PERFORMANCE_INDICATOR: {
      return {
        ...state,
        performanceIndicatorsData: {
          ...state.performanceIndicatorsData,
          ...action.performanceIndicatorIds.reduce((acc, cur) => {
            let curVal = {
              ...state.performanceIndicatorsData[cur],
              isLoading: true,
              dateRange: action.dateRange,
              vesselId: action.vesselId,
              timeOffset: action.timeOffset,
            };
            curVal.isLoading = true;
            acc[cur] = curVal;
            return acc;
          }, {}),
        },
      };
    }

    case FETCH_TREND_FOR_PERFORMANCE_INDICATOR_ERROR:
      return {
        ...state,
        performanceIndicatorsData: {
          ...state.performanceIndicatorsData,
          isLoading: false,
          error: true,
        },
      };

    case FETCH_TREND_FOR_PERFORMANCE_INDICATOR_SUCCESS: {
      const performanceIndicatorIds = [
        ...keys(state.performanceIndicatorsData),
        ...action.performanceIndicatorIds,
      ];
      return {
        ...state,
        performanceIndicatorsData: performanceIndicatorIds.reduce((acc, id) => {
          if (action.performanceIndicatorIds.some((x) => x === id)) {
            acc[id] = {
              ...state.performanceIndicatorsData[id],
              isLoading: false,
              data: find(get(action, "data.values"), (x) => x.id === id),
            };
          } else {
            acc[id] = state.performanceIndicatorsData[id];
          }
          return acc;
        }, {}),
      };
    }

    case components.trend.FETCH_VESSEL_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case components.trend.FETCH_VESSEL_SUCCESS:
      return {
        ...state,
        vessel: action.data,
      };

    default:
      return state;
  }
};

const isFuelConsumption = (metricId) =>
  metricId === "23210ea9-2882-473d-b4ea-5efe1a94dbbc" ||
  metricId === "31482f1f-010f-4e41-bf8d-ef3f2ebbd115";

const withValueGaps = (items, range) => {
  return items.map((item) => {
    item.values = addValueGaps(item.values, range);
    return item;
  });
};

const addValueGaps = (values, range) => {
  const dayDiff =
    range && range.length === 2
      ? getDaysBetween(new Date(range.from), new Date(range.to))
      : 1;
  let stepSize = 86400000;
  if (dayDiff <= 7) {
    stepSize = 3600000;
  }
  return values
    ? values.reduce((acc, currentItem, currentIndex) => {
        if (currentIndex > 0) {
          const prevItem = values[currentIndex - 1];
          const prevDate = new Date(prevItem.dateTime);
          const currDate = new Date(currentItem.dateTime);
          const dateDiff = currDate - prevDate;
          if (dateDiff > stepSize) {
            acc.push({
              dateTime: addMillis(-stepSize, currDate),
            });
          }
        }
        acc.push(currentItem);
        return acc;
      }, [])
    : [];
};

const withTotalFuelConsumption = (metricId, items) => {
  if (
    !isFuelConsumption(metricId) ||
    items.length < 2 ||
    items.some((x) => x.loading)
  ) {
    return items;
  }

  const groupedItems = values(
    groupBy(
      "dateTime",
      filter(
        "value",
        compact(flatten([...items.map(({ values }) => values || [])]))
      )
    )
  );

  const totalItem = {
    id: `${metricId}-total`,
    metricId: metricId,
    description: "(Total)",
    unit: items[0].unit,
    color: color("--orange-darkest"),
    values: sortBy(
      "dateTime",
      values(groupedItems).map((tuple) => ({
        dateTime: tuple[0].dateTime,
        value: sumBy("value", tuple),
      }))
    ),
  };

  return [...items, totalItem];
};

const findMetricValues = (metricId, vesselItemId, vesselItemsData) => {
  const metricsData = getOr([], [vesselItemId, "values"], vesselItemsData);
  return getOr(
    [],
    "values",
    metricsData.find((x) => x.id === metricId)
  );
};

const findInterval = (metricId, vesselItemId, vesselItemsData) => {
  const metricsData = getOr([], [vesselItemId, "values"], vesselItemsData);
  return getOr(
    1,
    "interval",
    metricsData.find((x) => x.id === metricId)
  );
};

const findMetricUnit = (metricId, vesselItemId, vesselItemsData) => {
  const metricsData = getOr([], [vesselItemId, "values"], vesselItemsData);
  return getOr(
    "",
    ["unit"],
    metricsData.find((x) => x.id === metricId)
  );
};

const isVesselItemLoading = (metricId, vesselItemId, vesselItemsData) => {
  const metricsData = getOr([], [vesselItemId, "values"], vesselItemsData);
  return getOr(
    "",
    ["isLoading"],
    metricsData.find((x) => x.id === metricId)
  );
};

const combineVesselItemAndMetric = (vesselItem, metric, vesselItemsData) => ({
  id: vesselItem.id,
  metricId: metric.id,
  metricName: metric.name,
  description: `(${vesselItem.name})`,
  unit: findMetricUnit(metric.id, vesselItem.id, vesselItemsData),
  color: vesselItem.color,
  values: findMetricValues(metric.id, vesselItem.id, vesselItemsData),
  isLoading: isVesselItemLoading(metric.id, vesselItem.id, vesselItemsData),
  interval: findInterval(metric.id, vesselItem.id, vesselItemsData),
});

export const dateWithUtcTimeOffset = (date, timeOffset) => {
  return addHours(timeOffset, new Date(date));
};

export const getActiveMetricItemsData = ({
  vesselItems,
  vesselItemsData,
  dateRange,
}) => {
  return flow(
    filter((vesselItem) => vesselItem.isActive),
    flatMap((vesselItem) =>
      vesselItem.metrics
        .filter((metric) => metric.isActive)
        .map((metric) =>
          combineVesselItemAndMetric(vesselItem, metric, vesselItemsData)
        )
    ),
    groupBy("metricId"),
    toPairs,
    map(([metricId, items]) => ({
      id: metricId,
      description: items[0].metricName,
      interval: items[0].interval,
      items: withTotalFuelConsumption(
        metricId,
        withValueGaps(items, dateRange)
      ),
    })),
    sortBy("description")
  )(vesselItems);
};

const findPerformanceIndicatorValues = (id, performanceIndicatorsData) => {
  const data = performanceIndicatorsData[id];
  return getOr([], "data.values", data);
};

const findPerformanceIndicatorInterval = (id, performanceIndicatorsData) => {
  const data = performanceIndicatorsData[id];
  return getOr(1, "data.interval", data);
};

export const getActivePerformanceItemsData = ({
  performanceIndicators,
  performanceIndicatorsData,
  dateRange,
}) => {
  if (!performanceIndicators.isActive) {
    return [];
  }

  return performanceIndicators.entries
    .filter((x) => x.isActive)
    .map((x) => {
      const performanceIndicatorValues = findPerformanceIndicatorValues(
        x.id,
        performanceIndicatorsData
      );
      const interval = findPerformanceIndicatorInterval(
        x.id,
        performanceIndicatorsData
      );
      const isLoading = get(
        performanceIndicatorsData,
        `[${x.id}].isLoading`,
        false
      );
      const valuesWithGap = addValueGaps(performanceIndicatorValues, dateRange);
      return {
        id: x.id,
        description: x.name,
        isLoading: isLoading,
        interval: interval,
        items: [
          {
            id: `${x.id}-zero`,
            color: color("--white"),
            unit: x.unit,
            performanceUnit: "%",
            description: "(Actual)",
            performanceDescription: "(Performance)",
            values: valuesWithGap.reduce(getZeroValues, []),
          },
          {
            id: `${x.id}-above`,
            color: color("--bright-green"),
            unit: x.unit,
            performanceUnit: "%",
            description: "(Actual)",
            performanceDescription: "(Performance)",
            values: valuesWithGap.reduce(getAboveValues, []),
          },
          {
            id: `${x.id}-below`,
            color: color("--bright-red"),
            unit: x.unit,
            performanceUnit: "%",
            description: "(Actual)",
            performanceDescription: "(Performance)",
            values: valuesWithGap.reduce(getBelowValues, []),
          },
        ],
      };
    });
};

export const prepareGraphData = ({ items = [] }, timeOffset = 0) =>
  items.map((item) =>
    (item.values || []).map((d) => ({
      x: dateWithUtcTimeOffset(d.dateTime, timeOffset),
      y: d.value,
      performanceValue: d.performanceValue,
      hideTooltip: d.hideTooltip || false,
    }))
  );

export const prepareXDomain = ({ from, to }) => [
  fromISOString(from),
  fromISOString(to),
];

export const prepareYDomain = (data) => [
  Math.min(
    0,
    d3.min(data, (values) => d3.min(values, (v) => v.y))
  ),
  d3.max(data, (values) => d3.max(values, (v) => v.y)),
];

export const prepareTickData = (xDomain) => {
  const [fromDate, toDate] = xDomain;
  const dayDiff = getDaysBetween(fromDate, toDate);

  const scaleX = d3.scaleUtc().domain(xDomain);
  let xTicks, xTickFormatStr, activeTickFormatStr;

  if (dayDiff <= 1) {
    xTicks = scaleX.ticks(d3.utcHour.every(1));
    xTickFormatStr = "%H:%M";
    activeTickFormatStr = "%H:%M";
  } else if (dayDiff <= 7) {
    xTicks = scaleX.ticks(d3.utcDay.every(1));
    xTickFormatStr = "%d %b %y";
    activeTickFormatStr = "%H:%M";
  } else if (dayDiff <= 32) {
    xTicks = scaleX.ticks(d3.utcDay.every(1));
    xTickFormatStr = "%d";
    activeTickFormatStr = "%d %b %y";
  } else {
    xTicks = scaleX.ticks(d3.utcMonth.every(1));
    xTickFormatStr = "%B";
    activeTickFormatStr = "%d %b %y";
  }
  return {
    xTicks,
    xTickFormat: d3.utcFormat(xTickFormatStr),
    activeTickFormat: d3.utcFormat(activeTickFormatStr),
    scaleX,
  };
};

export const prepareOperationGraphData = (
  data = [],
  aggregated = false,
  timeOffset = 0
) => {
  if (aggregated === true) {
    return prepareOperationAggregatedGraphData(data, timeOffset);
  } else {
    return data.map((d) => ({
      x: dateWithUtcTimeOffset(d.startTime, timeOffset),
      y: 0,
      operations: [
        {
          ...d,
          endTime: new Date(
            dateWithUtcTimeOffset(d.startTime, timeOffset).getTime() +
              d.secondCount * 1000
          ),
        },
      ],
    }));
  }
};

const prepareOperationAggregatedGraphData = (data, timeOffset) => {
  const getKey = (date) => {
    const d = date.getDate();
    const m = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return m[date.getMonth()] + " " + ((d < 10 ? "0" : "") + d);
  };
  const legendDict = flow(flatMap((o) => o.profiles.map((p) => p.name)))(
    OPERATION_MODES
  );
  let activeLegends = [];
  return values(
    data
      .map((d) => {
        if (activeLegends.indexOf(d.operationLegend) < 0) {
          activeLegends = [...activeLegends, d.operationLegend].sort(
            (a, b) => legendDict.indexOf(a) - legendDict.indexOf(b)
          );
        }
        return {
          ...d,
          startTime: dateWithUtcTimeOffset(d.startTime, timeOffset),
        };
      })
      .reduce((acc, d) => {
        const key = getKey(d.startTime);
        if (!acc[key]) {
          acc[key] = {
            x: new Date(
              Date.UTC(
                d.startTime.getFullYear(),
                d.startTime.getMonth(),
                d.startTime.getDate()
              )
            ),
            y: 0,
            operations: [],
          };
        }
        const group = acc[key];
        d.z = activeLegends.indexOf(d.operationLegend);
        d.endTime = new Date(
          group.x.getTime() + Math.min(86400, d.secondCount) * 1000
        );
        group.y = Math.max(group.y, d.z + 1);
        group.operations.push(d);
        return acc;
      }, [])
  );
};

export const createTooltipFormatter = (valueItem) => (data) => {
  const { number, unit } = roundConvert(data.y, valueItem.unit);
  const result = [`${number} ${unit || ""} ${valueItem.description || ""}`];

  if (data.performanceValue !== undefined) {
    const rounded = roundConvert(data.performanceValue);
    result.push(`${rounded.number}% ${valueItem.performanceDescription || ""}`);
  }

  return result;
};
