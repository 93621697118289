import { FETCH_BEST_PERFORMANCE } from "./action.types";
import { BEST_PERFORMANCE } from "../api";
import fetch from "./action.fetch";
import { addDays, atEndOfDay, now } from "../common/dates";

function lastSevenDays() {
  return [
    addDays(-7, atEndOfDay(now())).toISOString(),
    atEndOfDay(now()).toISOString(),
  ];
}

const count = 5;

export const fetchBestPerformance = () =>
  fetch({
    url: BEST_PERFORMANCE.get(lastSevenDays(), count),
    actionType: FETCH_BEST_PERFORMANCE,
    actionData: { period: "Last 7 days" },
  });
