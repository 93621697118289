import createReducer from "../createReducer";
import { admin } from "../../actions/action.types";

const initialState = {
  data: null,
  isLoading: false,
  isDeleting: false,
  vesselOptions: [],
  hasError: false,
};

export default createReducer(initialState, {
  [admin.dataMaintenance.INIT]: (state) => {
    return {
      ...state,
      data: null,
      isLoading: false,
    };
  },
  [admin.dataMaintenance.FETCH_VESSELS]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [admin.dataMaintenance.FETCH_VESSELS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      hasError: false,
      isLoading: false,
      vesselOptions: data,
    };
  },
  [admin.dataMaintenance.FETCH_VESSELS_ERROR]: (state) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  },
  [admin.dataMaintenance.FETCH_DATA_COUNT]: (state) => {
    return {
      ...state,
      data: null,
      hasError: false,
      isLoading: true,
    };
  },
  [admin.dataMaintenance.FETCH_DATA_COUNT_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      data,
    };
  },
  [admin.dataMaintenance.FETCH_DATA_COUNT_ERROR]: (state) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  },
  [admin.dataMaintenance.DELETE_DATA]: (state) => {
    return {
      ...state,
      isDeleting: true,
      hasError: false,
    };
  },
  [admin.dataMaintenance.DELETE_DATA_SUCCESS]: (state) => {
    return {
      ...state,
      isDeleting: false,
    };
  },
  [admin.dataMaintenance.DELETE_DATA_ERROR]: (state) => {
    return {
      ...state,
      isDeleting: false,
      hasError: true,
    };
  },
});
