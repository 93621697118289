import { vesselConfig } from "../action.types";

export const inputChanged = (id, name, value, valid) => ({
  type: vesselConfig.vesselPerformanceIndicators.INPUT_CHANGED,
  id,
  name,
  value,
  valid,
});

export const newVesselPerformanceIndicator = () => ({
  type: vesselConfig.vesselPerformanceIndicators.NEW,
});

export const editVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.EDIT,
  id,
});

export const saveVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.SAVE,
  id,
});

export const cancelEditVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.CANCEL_EDIT,
  id,
});

export const deleteVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.DELETE,
  id,
});
export const cancelDeleteVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.CANCEL_DELETE,
  id,
});
export const confirmDeleteVesselPerformanceIndicator = (id) => ({
  type: vesselConfig.vesselPerformanceIndicators.CONFIRM_DELETE,
  id,
});
