import {
  FETCH_VESSEL_FUEL_CONSUMPTION,
  FETCH_VESSEL_FUEL_CONSUMPTION_SUCCESS,
  FETCH_VESSEL_FUEL_CONSUMPTION_ERROR,
} from "../actions/action.types";
import createReducer from "./createReducer";
import { roundNumber, getConvertSpec } from "../../common/numbers";
import { maxBy, get } from "lodash";
import { graphColors } from "../../common/colors";
import { getDay, getMonth, from, addHours } from "../common/dates";
import { DAY, MONTH } from "../common/timeUnits";

const initialState = {};

export const fetchVesselFuelConsumption = createReducer(initialState, {
  [FETCH_VESSEL_FUEL_CONSUMPTION]: () => ({
    ...initialState,
    isLoading: true,
  }),
  [FETCH_VESSEL_FUEL_CONSUMPTION_SUCCESS]: (_state, { timeOffset, data }) => ({
    data: getGraphData(data, timeOffset),
  }),
  [FETCH_VESSEL_FUEL_CONSUMPTION_ERROR]: (_state, { error }) => ({ error }),
});

const getX = (aggregation, date) => {
  switch (aggregation) {
    case DAY:
      return getDay(from(date));
    case MONTH:
      return getMonth(from(date));
    default:
      return date;
  }
};

const getFuelConsumptionType = (aggregation) => {
  if (aggregation === DAY) {
    return "daily";
  }
  return `${aggregation}ly`;
};

export const getGraphData = (fuelConsumption, timeOffset) => {
  return fuelConsumption.map((f, i) => {
    const maxValue = get(maxBy(f.data, "value"), "value", 0);
    const convertSpec = getConvertSpec(maxValue, f.unit);
    const aggregation = f.aggregation;
    return {
      fuelConsumptionType: getFuelConsumptionType(aggregation),
      aggregation: aggregation,
      unit: convertSpec.unit,
      text: f.type,
      color: graphColors[i],
      data: f.data.map((d) => {
        return {
          x: getX(aggregation, addHours(timeOffset, from(d.date))),
          y: roundNumber(
            d.value / convertSpec.conversion,
            convertSpec.precision
          ),
        };
      }),
    };
  });
};
