import React from "react";

export const RightArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5813_10901)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-1.5 18.75L-1.5 19.5L-0.75 19.5L-0.75 18.75L-1.5 18.75ZM4.9444 14.1092L6.30565 15.4442L13.0556 8.69421L6.30565 1.94421L4.9444 3.30171L10.0969 8.69421L4.9444 14.1092Z"
        fill="#8391A5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5813_10901">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 18) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
