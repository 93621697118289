import React from "react";
import { connect } from "react-redux";
import Loader from "../../../../common/components/Loader/Loader";
import PropTypes from "prop-types";
import styles from "./MetricsListItem.css";
import { MetricsListItemDetails } from "../../MetricsListItemDetails";
import { toggleVesselItemDetails } from "../../../actions/action.toggleVesselItemDetails";
import MetricsList from "../MetricsList";
import { get, isNil } from "lodash";
import DataDetails from "../../DataDetails/DataDetails";
import DataDetailsGroup from "../../DataDetails/DataDetailsGroup";
import DataDetailsLoadProfile from "../../DataDetailsLoadProfile/DataDetailsLoadProfile";
import { DataDetailsListContext } from "../../DataDetails/DataDetailsList";
import { fetchHealthEventDetails } from "../../../actions/action.fetchHealthEventDetails";
import {
  acknowledgeEvent,
  resolveEvent,
  revertEvent,
} from "../../../actions/action.healthManagementEventResolution";
import { getMetricsForVesselItem } from "../../../selectors/common";
import memoize from "memoize-one";

const memoizedItemDataProvider = memoize((dataDetails, id) => {
  return dataDetails[id];
});

const createDataDetailListContextproviderValue = (props) => ({
  itemDataProvider: (itemProps) => {
    return memoizedItemDataProvider(
      props.vesselItemDetails.dataDetails,
      itemProps.id
    );
  },
  itemHandlers: {
    "Hm.Event": {
      onLoad: (itemProps) => {
        props.fetchHealthEventDetails(itemProps.id);
      },
      acknowledgeEvent: (itemProps) => {
        props.acknowledgeEvent(itemProps.id, props.data.vesselItemId);
      },
      resolveEvent: (itemProps) => {
        props.resolveEvent(itemProps.id, props.data.vesselItemId);
      },
      revertEvent: (itemProps) => {
        props.revertEvent(itemProps.id, props.data.vesselItemId);
      },
    },
  },
});

class MetricDetailsContainer extends React.Component {
  renderNoData() {
    return (
      <div className={styles.missingData}>
        <p>No data found for this equipment, for the selected period</p>
      </div>
    );
  }

  renderProfileData(profile) {
    if (!profile || !profile.metrics) {
      return;
    }
    if (profile.metrics.length === 0) {
      return (
        <DataDetailsGroup key="profile_data" title={"Profile Details"}>
          {this.renderNoData()}
        </DataDetailsGroup>
      );
    }

    return (
      <DataDetailsGroup key="profile_data" title={"Profile Details"}>
        <DataDetailsLoadProfile {...profile} />
      </DataDetailsGroup>
    );
  }

  renderChildren({ children }) {
    if (!children || children.length === 0) {
      return;
    }
    const { columnCount } = this.props;
    return (
      <div className={styles.tableContainer}>
        {children.map((row, i) => (
          <MetricsList
            key={`metricList_row_${i}`}
            showTitle={false}
            vesselItemData={row.data}
            vesselItemDetails={this.props.vesselItemDetails}
            toggleVesselItemDetails={this.props.toggleVesselItemDetails}
            toggleTrendVesselItem={this.props.toggleTrendVesselItem}
            dateRange={this.props.dateRange}
            columnCount={columnCount}
            timeOffset={this.props.timeOffset}
          />
        ))}
      </div>
    );
  }

  filterMetricsFromData = (data, pageContext) => {
    const availableMetricIds = getMetricsForVesselItem(
      data.vesselItemId,
      pageContext
    )
      .filter((x) => x.showOperationDetail === true)
      .map((m) => m.id);

    return {
      ...data,
      data: data.data.map((d) => ({
        ...d,
        metrics:
          !isNil(d) && d.metrics
            ? d.metrics.filter((m) => {
                return availableMetricIds.some((id) => id === m.id);
              })
            : [],
      })),
    };
  };

  render() {
    const { detailsUrl, imageUrl, data, error } = this.props;
    const pageContext = this.props.pageContext;

    if (!data) {
      return (
        <div className={styles.loaderContainer}>
          <Loader text="Loading detailed equipment metrics" error={error} />
        </div>
      );
    } else if (data.data.length === 0 && data.children.length === 0) {
      return this.renderNoData();
    }
    return (
      <div className={styles.detailsContainer}>
        <div className={`${styles.detailsItem} ${styles.data}`}>
          <MetricsListItemDetails
            img={imageUrl}
            imgAltText="Equipment image"
            detailsUrl={detailsUrl}
            data={data}
          />
        </div>
        <DataDetailsListContext.Provider
          value={createDataDetailListContextproviderValue(this.props)}
        >
          <DataDetails
            dataDetails={get(
              this.filterMetricsFromData(data, pageContext),
              "data",
              []
            )}
            customResolver={{
              LoadProfile: (d) => this.renderProfileData(d),
            }}
          />
        </DataDetailsListContext.Provider>
        {this.renderProfileData(this.filterMetricsFromData(data, pageContext))}
        {this.renderChildren(data)}
      </div>
    );
  }
}

MetricDetailsContainer.propTypes = {
  data: PropTypes.object,
  error: PropTypes.any,
  detailsUrl: PropTypes.string, // TODO check if this should come from itemTypeData
  imageUrl: PropTypes.string, // TODO check if this should come from itemTypeData
  columnCount: PropTypes.number.isRequired,
  dateRange: PropTypes.array.isRequired,
  toggleTrendVesselItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vesselItemDetails: state.fetchVesselItemDetails,
  pageContext: state.pageContext,
});

const mapDispatchToProps = {
  toggleVesselItemDetails,
  fetchHealthEventDetails,
  acknowledgeEvent,
  resolveEvent,
  revertEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricDetailsContainer);
