import React from "react";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import { get, matchesProperty } from "lodash";
import {
  saveUserProfile,
  setUserProfileUserSetting,
  toggleNav,
} from "./../../actions";
import styles from "./UserProfileView.css";
import Grid from "../../components/Grid/Grid";
import GridCell from "../../components/Grid/GridCell/GridCell";
import Button from "../../../common/components/Button/Button";
import ProfileImage from "./ProfileImage/ProfileImage";
import FormGroup from "../../components/Form/FormGroup/FormGroup";
import { FormSelectBox, FormTextBox } from "../../components/Form";
import { SettingsContainer, SettingsRow } from "./SettingsContainer";
import SettingsCell from "./SettingsContainer/SettingsCell";
import { filterPageConfigs } from "../../reducers/reducer.pageConfig";
import { defaultDateRanges } from "../../common/dates";
import { getTimeZones } from "../../../common/units";

class UserProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectBoxChange = this.handleSelectBoxChange.bind(this);
    this.saveUserProfile = this.saveUserProfile.bind(this);
  }

  saveUserProfile() {
    this.props.saveUserProfile(this.props.userProfile.userSettings);
  }

  handleInputChange(name, value, valid, message) {
    this.props.setUserProfileUserSetting(name, value, valid, message);
  }

  handleSelectBoxChange(name, value, valid, message) {
    this.props.setUserProfileUserSetting(name, value.id, valid, message);
  }

  render() {
    let { userProfile, user, dashboards, groups } = this.props;
    let userSettings = userProfile.userSettings;
    let userSettingFields = userSettings.fields;
    let enableSave = userSettings.hasChanges && userSettings.isValid;
    let fleets = Object.entries(groups).map(([, group]) => group);
    const defaultTimeOffsetZones = getTimeZones().map((x) => ({
      ...x,
      id: x.utcOffset,
    }));

    const selectedDashboard = dashboards.find(
      matchesProperty(
        "id",
        get(userSettingFields, "defaultDashboardId.value", null)
      )
    );

    const selectedDateRange = defaultDateRanges.find(
      matchesProperty(
        "id",
        get(userSettingFields, "defaultDateRange.value", null)
      )
    );

    const selectedFleet = fleets.find(
      matchesProperty(
        "id",
        get(userSettingFields, "defaultVesselGroupId.value", null)
      )
    );

    const selectedTimeOffset = defaultTimeOffsetZones.find(
      matchesProperty(
        "id",
        get(userSettingFields, "defaultTimeOffset.value", null)
      )
    );

    return (
      <div className={styles.outerContainer}>
        <Header
          title="User profile"
          contentDistribution="end"
          toggleNav={this.props.toggleNav}
        >
          <Button
            value="Save"
            clickAction={this.saveUserProfile}
            disabled={!enableSave}
          />
        </Header>

        <div className={styles.topGridContainer}>
          <Grid>
            <GridCell cellSpan={1}>
              <ProfileImage
                name={`${user.firstName} ${user.lastName}`}
                companyName={user.companyName}
                imageUrl={user.imageUrl}
              />
            </GridCell>
            <GridCell cellSpan={2}>
              <FormGroup title="User settings">
                <FormTextBox
                  label="User identification"
                  name="email"
                  disabled={true}
                  value={userSettingFields.email.value}
                />
                <FormTextBox
                  label="First name"
                  name="firstName"
                  required
                  value={userSettingFields.firstName.value}
                  onChange={this.handleInputChange}
                />
                <FormTextBox
                  label="Last name"
                  name="lastName"
                  required
                  value={userSettingFields.lastName.value}
                  onChange={this.handleInputChange}
                />

                {/*<FormTextBox*/}
                {/*label="Notification email"*/}
                {/*name="notificationEmail"*/}
                {/*type="email"*/}
                {/*value={userSettingFields.notificationEmail.value}*/}
                {/*onChange={this.handleInputChange}*/}
                {/*/>*/}
                {/*<FormCheckBox*/}
                {/*type="checkbox"*/}
                {/*disabled={true}*/}
                {/*label="Receive notifications"*/}
                {/*name="receiveNotifications"*/}
                {/*value={userSettingFields.receiveNotifications.value}*/}
                {/*onChange={this.handleInputChange}*/}
                {/*/>*/}
              </FormGroup>
            </GridCell>
            <GridCell cellSpan={2} />
          </Grid>
        </div>
        <div className={styles.settingsContainer}>
          <SettingsContainer title={"User application settings"}>
            <SettingsRow
              title="Dashboard"
              icon="dashboard"
              description="User dashboard settings"
            >
              <SettingsCell>
                <FormSelectBox
                  name="defaultDashboardId"
                  label="Default dashboard"
                  selected={selectedDashboard}
                  options={dashboards}
                  optionValKey={"name"}
                  onChange={this.handleSelectBoxChange}
                />
              </SettingsCell>
            </SettingsRow>
            <SettingsRow
              title="Fleet"
              icon="hull"
              description="User fleet settings"
            >
              <SettingsCell>
                <FormSelectBox
                  name="defaultVesselGroupId"
                  label="Default fleet"
                  selected={selectedFleet}
                  options={fleets}
                  optionValKey="id"
                  optionLabelKey="name"
                  onChange={this.handleSelectBoxChange}
                />
              </SettingsCell>
            </SettingsRow>
            <SettingsRow
              title="Date"
              icon="calendar"
              description="User date settings"
            >
              <SettingsCell>
                <FormSelectBox
                  name="defaultDateRange"
                  label="Default date range"
                  selected={selectedDateRange}
                  options={defaultDateRanges}
                  optionValKey="id"
                  optionLabelKey="name"
                  onChange={this.handleSelectBoxChange}
                />
              </SettingsCell>
              <SettingsCell>
                <FormSelectBox
                  name="defaultTimeOffset"
                  label="Default time offset"
                  selected={selectedTimeOffset}
                  options={defaultTimeOffsetZones}
                  optionValKey="id"
                  optionLabelKey="name"
                  onChange={this.handleSelectBoxChange}
                />
              </SettingsCell>
            </SettingsRow>
          </SettingsContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
    user: state.user,
    dashboards:
      filterPageConfigs(state.pageConfig.dashboard, state.user.companyId) || [],
    groups: state.pageContext.groups,
  };
};

const mapDispatchToProps = {
  toggleNav,
  setUserProfileUserSetting,
  saveUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileView);
