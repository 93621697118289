import React from "react";
import { List } from "react-virtualized";
import styles from "./VirtualList.css";
import PropTypes from "prop-types";

/**
 * @class Wrapper component for react-virtualized list
 * adding dynamic height
 */
class VirtualList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: props.width,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({
      height: this.container.clientHeight,
    });
  }

  render() {
    const { children } = this.props;
    const { height } = this.state;
    return (
      <div
        ref={(el) => (this.container = el)}
        className={styles.virtualListContainer}
      >
        <List
          height={height}
          autoHeight={false}
          rowHeight={this.props.rowHeight}
          rowCount={this.props.rowCount}
          rowRenderer={this.props.rowRenderer}
          onRowsRendered={this.props.onRowsRendered}
          width={this.props.width}
        >
          {children}
        </List>
      </div>
    );
  }
}

VirtualList.propTypes = {
  rowHeight: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  onRowsRendered: PropTypes.func,
  width: PropTypes.number.isRequired,
};

export default VirtualList;
