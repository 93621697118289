import { FETCH_CALENDAR_OPERATIONALDATA } from "./action.types";
import { CALENDAR } from "../api";
import fetch from "./action.fetch";

export const fetchCalendarOperationalData = (
  vesselId,
  dateRange,
  timeOffset
) => {
  return fetch({
    url: CALENDAR.getOperationalData(vesselId, dateRange, timeOffset),
    actionType: FETCH_CALENDAR_OPERATIONALDATA,
    actionData: { vesselId, dateRange, timeOffset },
  });
};
