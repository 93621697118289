import React, { useContext } from "react";
import styles from "./NodeRelation.css";
import PropTypes from "prop-types";
import NodeItemConnector from "./NodeItemConnector";
import { useDrop } from "react-dnd";
import { NodeRelationContext } from "./NodeRelation";
import classNames from "../../../common/classNames";
import Draggable from "react-draggable";
import { useXarrow } from "react-xarrows";
import { ansiColor } from "../../../common/colors";

const NodeItem = ({ id, left, top, relations, title, subTitle }) => {
  const updateXArrow = useXarrow();
  const { onRelationAdded, onNodeRemoved, onNodeDrop } =
    useContext(NodeRelationContext);
  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: "BOX",
      canDrop: (item) => {
        if (item.parentId === id) {
          return false;
        }
        return !relations.some(
          (r) => r.sourceId === item.parentId && r.destinationId === id
        );
      },
      drop: (item) => {
        if (
          relations &&
          !relations.some(
            (r) => r.sourceId === item.parentId && r.destinationId === id
          )
        ) {
          onRelationAdded({ sourceId: item.parentId, destinationId: id });
        }
      },
      collect: (e) => ({
        isOver: e.isOver(),
        didDrop: e.didDrop(),
        canDrop: e.canDrop(),
      }),
    }),
    [relations]
  );

  const handleOnDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateXArrow();
  };
  const handleOnStop = (nodeId) => (e, ui) => {
    e.preventDefault();
    e.stopPropagation();
    onNodeDrop && onNodeDrop(nodeId, ui.lastX, ui.lastY);
    updateXArrow();
  };
  const handleNodeRemoved = (nodeId) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    onNodeRemoved(nodeId);
  };
  const getBorderColor = () =>
    canDrop ? ansiColor("green") : ansiColor("red");

  return (
    <Draggable
      grid={[10, 10]}
      bounds={{ left: -left }}
      cancel={".nodeConnectorContainer"}
      onDrag={handleOnDrag}
      onStop={handleOnStop(id)}
    >
      <div
        title={title}
        id={`nodeContainer${id}`}
        className={classNames(styles.itemContainer)}
        ref={dropRef}
        style={{
          left,
          top,
          borderColor: isOver ? getBorderColor() : undefined,
        }}
      >
        <NodeItemConnector left={"5px"} top={"50%"} parentId={id} />
        <NodeItemConnector left={"50%"} top={"5px"} parentId={id} />
        <NodeItemConnector right={0} top={"50%"} parentId={id} />
        <NodeItemConnector bottom={0} left={"50%"} parentId={id} />
        <button onClick={handleNodeRemoved(id)} className={styles.delete} />
        <div className={styles.itemInner}>
          <div className={styles.itemTitle}>{title}</div>
          <div className={styles.itemSubTitle}>{subTitle}</div>
        </div>
      </div>
    </Draggable>
  );
};

NodeItem.propTypes = {
  id: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
};

NodeItem.defaultPropTypes = {
  left: 0,
  top: 0,
};

export default NodeItem;
