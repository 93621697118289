import { IOperationMode } from "./IOperationMode";
import { EcoTransit } from "./EcoTransit";
import { FastTransit } from "./FastTransit";
import { Transit } from "./Transit";
import { Maneuver } from "./Maneuver";
import { DP } from "./DP";
import { DPClass2 } from "./DPClass2";
import { DPManeuver } from "./DPManeuver";
import { Standby } from "./Standby";
import { DPGangway } from "./DPGangway";
import { AnchorHandlingTensioning } from "./AnchorHandlingTensioning";
import { AnchorHandlingTowing } from "./AnchorHandlingTowing";
import { AnchorHandlingDP } from "./AnchorHandlingDP";
import { Hoisting } from "./Hoisting";
import { Shooting } from "./Shooting";
import { Fishing } from "./Fishing";
import { SingleTrawl } from "./SingleTrawl";
import { DoubleTrawl } from "./DoubleTrawl";
import { TripleTrawl } from "./TripleTrawl";
import { Embarking } from "./Embarking";
import { Port } from "./Port";
import { Other } from "./Other";
import { ShoreConnection } from "./ShoreConnection";
import { PortWithBattery } from "./PortWithBattery";
import { Offline } from "./Offline";
import { OperationId } from "../operation/operationId";

export function createOperationMode(operationId: OperationId): IOperationMode {
  const idModeMapping: Record<OperationId, () => IOperationMode> = {
    [OperationId.TransitEco]: () => new EcoTransit(),
    [OperationId.TransitFast]: () => new FastTransit(),
    [OperationId.Transit]: () => new Transit(),
    [OperationId.Maneuver]: () => new Maneuver(),
    [OperationId.Dp]: () => new DP(),
    [OperationId.DpClass2]: () => new DPClass2(),
    [OperationId.DpGangway]: () => new DPGangway(),
    [OperationId.DpManeuver]: () => new DPManeuver(),
    [OperationId.Standby]: () => new Standby(),
    [OperationId.AnchorHandlingTensioning]: () =>
      new AnchorHandlingTensioning(),
    [OperationId.AnchorHandlingTowing]: () => new AnchorHandlingTowing(),
    [OperationId.AnchorHandlingDp]: () => new AnchorHandlingDP(),
    [OperationId.TripleTrawl]: () => new TripleTrawl(),
    [OperationId.DoubleTrawl]: () => new DoubleTrawl(),
    [OperationId.SingleTrawl]: () => new SingleTrawl(),
    [OperationId.Fishing]: () => new Fishing(),
    [OperationId.Shooting]: () => new Shooting(),
    [OperationId.Hoisting]: () => new Hoisting(),
    [OperationId.Embarking]: () => new Embarking(),
    [OperationId.Port]: () => new Port(),
    [OperationId.Other]: () => new Other(),
    [OperationId.PortShoreConnection]: () => new ShoreConnection(),
    [OperationId.PortBattery]: () => new PortWithBattery(),
    [OperationId.None]: () => new Offline(),
  };

  return idModeMapping[operationId]();
}
