import { admin } from "../../../actions/action.types";
import createReducer from "../../createReducer";

export default createReducer(
  {
    vessels: {},
    data: {},
  },
  {
    [admin.energyBudget.FETCH_VESSELS]: (state) => ({
      ...state,
      vessels: {
        isLoading: true,
      },
    }),

    [admin.energyBudget.FETCH_VESSELS_SUCCESS]: (state, { data }) => ({
      ...state,
      vessels: { data },
    }),

    [admin.energyBudget.FETCH_VESSELS_ERROR]: (state, { error }) => ({
      ...state,
      vessels: { error },
    }),

    [admin.energyBudget.FETCH]: (state) => ({
      ...state,
      data: { isLoading: true },
    }),

    [admin.energyBudget.FETCH_SUCCESS]: (state, { data }) => ({
      ...state,
      data: { data },
    }),

    [admin.energyBudget.FETCH_ERROR]: (state, { error }) => ({
      ...state,
      data: { error },
    }),
  }
);

export const getVessels = (state) => state.vessels.data || [];

export const getVesselById = (state, id) =>
  getVessels(state).find((x) => x.id === id);
