import React from "react";
import PropTypes from "prop-types";
import styles from "./InlinePanel.css";
import Button from "../../../../../common/components/Button";
import Loader from "../../../../../common/components/Loader";

export default class InlinePanel extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { title, children, buttons, isLoading, error } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.buttonContainer}>{actionButtons(buttons)}</div>
        </div>
        {(isLoading || error) && (
          <div className={styles.loaderContainer}>
            <Loader error={error} expand />
          </div>
        )}
        {!isLoading && !error && (
          <div className={styles.contentContainer}>{children}</div>
        )}
      </div>
    );
  }
}

InlinePanel.defaultProps = {
  buttons: [],
  isLoading: false,
};

InlinePanel.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ),
};

const actionButtons = (buttons) => {
  return (
    buttons &&
    buttons.map((b, i) => {
      return (
        <div
          key={`inlinePanelButton_${i}`}
          data-testid={b.label}
          className={styles.button}
        >
          <Button
            className={styles.button}
            value={b.label}
            disabled={b.disabled}
            clickAction={b.action}
          />
        </div>
      );
    })
  );
};
