import * as React from "react";
import PropTypes from "prop-types";
import styles from "./Grid.css";

export default class GridCell extends React.PureComponent {
  render() {
    const { children, rowSpan, colSpan, colStart, rowStart, spacer, style } =
      this.props;
    const gridCellStyle = {
      gridColumn: colSpan ? `span ${colSpan}` : "auto",
      gridRow: rowSpan ? `span ${rowSpan}` : "auto",
      msGridColumn: colStart,
      msGridColumnSpan: colSpan,
      msGridRow: rowStart,
      msGridRowSpan: rowSpan,
      background: spacer ? `#0f1114` : "",
      ...style,
    };
    return (
      <div className={styles.gridCell} style={gridCellStyle}>
        {children}
      </div>
    );
  }
}

GridCell.defaultProps = {
  rowSpan: 1,
  colSpan: 1,
};

/*
NOTE:
IE11 only supports the old specification of css grid. Following restrictions apply:
- colStart and rowStart must be given to GridCells
- Gap is not supported. Add margin/padding to cell content as needed.
- Repeat in template strings is not supported, repeat fractions explicitly instead.
Implementation using Grid/GridCell is best tested against IE11 during PR sessions.
*/

GridCell.propTypes = {
  rowSpan: PropTypes.number,
  rowStart: PropTypes.number.isRequired,
  colSpan: PropTypes.number,
  colStart: PropTypes.number.isRequired,
  style: PropTypes.any,
};
