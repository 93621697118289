import IApiClient from "../services/IApiClient";
import IAuthService from "../authentication/IAuthService";
import { NavigateFunction } from "react-router-dom";
import { Dispatch } from "redux";
import { LoginHandler } from "../authentication/LoginHandler";
import { ILoginHandler } from "../authentication/ILoginHandler";
import { IApiAvailabilityHandler } from "../authentication/IApiAvailabilityHandler";
import { ApiAvailabilityHandler } from "../authentication/ApiAvailabilityHandler";
import { RootState } from "../store/rootState";

export const login =
  (navigate: NavigateFunction, auth: IAuthService, apiClient: IApiClient) =>
  (dispatch: Dispatch, _getState: () => RootState) => {
    const apiAvailabiltyHandler: IApiAvailabilityHandler =
      new ApiAvailabilityHandler(apiClient, dispatch);
    const loginHandler: ILoginHandler = new LoginHandler(
      auth,
      apiClient,
      dispatch,
      navigate,
      apiAvailabiltyHandler
    );
    void loginHandler.login();
  };
