import React from "react";
import PropTypes from "prop-types";
import styles from "./TimeOffsetPicker.css";
import pickerStyles from "../PickerStyles.css";
import classNames from "../../../../common/classNames";
import ContextBrowser from "../ContextBrowser";
import Divider from "../Divider";

function renderColumn(items, select) {
  return (
    <div className={styles.timeColumn}>
      {items.map((item) => (
        <div className={styles.timeItem} key={item.name}>
          <button
            className={classNames(
              styles.button,
              item.isSelected && styles.active
            )}
            data-test-id="datePicker-timeOffset"
            onClick={() => select(item)}
          >
            {item.name}
          </button>
        </div>
      ))}
    </div>
  );
}

const TimeOffsetPicker = (props) => {
  const { options, select, apply } = props;
  const { items, title, hasNext, hasPrev } = options;

  return (
    <div>
      <ContextBrowser
        mode={"unified"}
        title={title}
        next={hasNext}
        prev={hasPrev}
      />
      <div className={styles.timeContainer}>
        {renderColumn(items.slice(0, 13), select)}
        <Divider visible={true} />
        {renderColumn(items.slice(13), select)}
      </div>

      <button
        className={pickerStyles.applyBtn}
        onClick={apply}
        data-test-id="timeOffsetPickerApply-button"
      >
        Apply
      </button>
    </div>
  );
};

TimeOffsetPicker.propTypes = {
  options: PropTypes.shape({
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    hasNext: PropTypes.bool.isRequired,
    hasPrev: PropTypes.bool.isRequired,
  }).isRequired,
  select: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
};

export default TimeOffsetPicker;
