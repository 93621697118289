import React, { FC } from "react";
import styles from "./ManualFuelDataFormDialog.css";
import moment from "moment/moment";

interface EntryDateProps {
  date: string;
}

const EntryDate: FC<EntryDateProps> = ({ date }) => {
  const formattedDate = moment(date, "YYYY-MM-DD").format("Do MMMM YYYY");

  return (
    <div className={styles.date}>
      Date :
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.332 4H14.582V2.5H13.082V4H7.08203V2.5H5.58203V4H4.83203C3.99953 4 3.33953 4.675 3.33953 5.5L3.33203 16C3.33203 16.825 3.99953 17.5 4.83203 17.5H15.332C16.157 17.5 16.832 16.825 16.832 16V5.5C16.832 4.675 16.157 4 15.332 4ZM7.83202 9.25H6.33202V10.75H7.83202V9.25ZM10.832 9.25H9.33204V10.75H10.832V9.25ZM12.332 9.25H13.832V10.75H12.332V9.25ZM4.83202 16H15.332V7.75H4.83202V16Z"
          fill="#5C6B7F"
        />
      </svg>
      {formattedDate}
    </div>
  );
};

export default EntryDate;
