import {
  FETCH_EMISSIONS_LIST,
  FETCH_EMISSIONS_LIST_ERROR,
  FETCH_EMISSIONS_LIST_SUCCESS,
} from "../actions/action.types";
import createReducer from "./createReducer";
import { AnyAction } from "redux";
import {
  EmissionsDictionary,
  EmissionsForVessel,
  EmissionsState,
} from "../views/TopPerformingVessels/Types";

interface EmissionsDataFromSource {
  from: Date;
  to: Date;
  operationClass: string;
  data: EmissionsForVessel[];
}

const initialState: EmissionsState = {
  emissionsLists: {},
  isLoading: false,
  error: undefined,
  hasError: false,
};

function createEmissionsListsReducer() {
  return createReducer(initialState, {
    [FETCH_EMISSIONS_LIST]: (
      state: EmissionsState,
      action: AnyAction
    ): EmissionsState => {
      const updatedDictionary: EmissionsDictionary = {
        ...state.emissionsLists,
        [action.operationClass]: {
          isLoading: true,
          data: [],
        },
      };
      return {
        ...state,
        emissionsLists: updatedDictionary,
        isLoading: true,
      };
    },

    [FETCH_EMISSIONS_LIST_SUCCESS]: (
      state: EmissionsState,
      { data }: { data: EmissionsDataFromSource }
    ): EmissionsState => {
      const updatedDictionary: EmissionsDictionary = {
        ...state.emissionsLists,
        [data.operationClass]: {
          isLoading: false,
          data: data.data,
        },
      };
      return {
        ...state,
        emissionsLists: updatedDictionary,
      };
    },

    [FETCH_EMISSIONS_LIST_ERROR]: (
      state: EmissionsState,
      { error }: { error: string }
    ): EmissionsState => {
      return {
        ...state,
        hasError: true,
        error,
      };
    },
  });
}

export const emissionsPerVessel = createEmissionsListsReducer();
