import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import { getPageContext } from "../../../selectors/common";
import { pageDefault, pageKey } from "./usePage";

const groupIdKey = "groupId";

const selectDefaultGroupId = createSelector(
  getPageContext,
  (pageContext) => pageContext.defaultGroupId as string
);

export const useGroupId = (): [
  groupId: string,
  setGroupId: (groupId: string) => void
] => {
  const defaultGroupId = useSelector(selectDefaultGroupId);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const groupId = urlSearchParams.get(groupIdKey) ?? defaultGroupId;

  const setGroupId = (groupId: string) =>
    setUrlSearchParams((prev) => {
      prev.set(pageKey, pageDefault);
      prev.set(groupIdKey, groupId);
      return prev;
    });

  return [groupId, setGroupId];
};
