import React from "react";
import PropTypes from "prop-types";
import styles from "./YearPicker.css";
import pickerStyles from "../PickerStyles.css";
import classNames from "../../../../common/classNames";
import ContextBrowser from "../ContextBrowser";
import Divider from "../Divider";

const YearPicker = (props) => {
  const { options, select, apply, getNextOptions, getPrevOptions } = props;
  const { items, title, hasNext, hasPrev } = options;

  const listItems = items.map((item) => (
    <li key={item.title}>
      <button
        disabled={!item.isEnabled}
        title={item.title}
        className={classNames(
          styles.yearButton,
          item.isSelected && styles.active
        )}
        data-test-id="datePicker-years"
        onClick={() => select(item)}
      >
        {item.title}
      </button>
    </li>
  ));

  const pageSize = Math.ceil(listItems.length / 2);

  return (
    <div>
      <ContextBrowser
        mode={"unified"}
        title={title}
        next={hasNext}
        prev={hasPrev}
        onNext={getNextOptions}
        onPrev={getPrevOptions}
      />
      <div className={styles.yearContainer}>
        <ul className={styles.yearList}>{listItems.slice(0, pageSize)}</ul>
        <Divider visible={true} />
        <ul className={styles.yearList}>{listItems.slice(pageSize)}</ul>
      </div>
      <button
        className={pickerStyles.applyBtn}
        onClick={apply}
        data-test-id="datePickerYearApply-button"
      >
        Apply
      </button>
    </div>
  );
};

YearPicker.propTypes = {
  options: PropTypes.shape({
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    hasNext: PropTypes.bool.isRequired,
    hasPrev: PropTypes.bool.isRequired,
  }).isRequired,
  select: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  getNextOptions: PropTypes.func.isRequired,
  getPrevOptions: PropTypes.func.isRequired,
};

export default YearPicker;
