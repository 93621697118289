import {
  BASELINE,
  ONBOARD_FRONTEND_CONFIG,
  ONBOARD_PROCESSOR_CONFIG,
  ONSHORE_FRONTEND_CONFIG,
  VESSEL_DETAILS,
  VESSEL_ITEMS,
  VESSEL_OPERATIONS,
  VESSEL_PERFORMANCE_INDICATORS,
  HULL_PERFORMANCE_CONFIG,
  ENERGY_FLOW,
} from "../../../reducers/admin/vesselConfig/reducer.vesselConfig";
import BaselineController from "./Baseline/BaselineController";
import VesselDetailsController from "./VesselDetails/VesselDetailsController";
import OnboardFrontendConfigController from "./OnboardFrontendConfig/OnboardFrontendConfigController";
import OnboardProcessorConfigController from "./OnboardProcessorConfig/OnboardProcessorConfigController";
import OnshoreFrontendConfigController from "./OnshoreFrontendConfig/OnshoreFrontendConfigController";
import VesselOperationsController from "./VesselOperations/VesselOperationsController";
import VesselItemsController from "./VesselItems/VesselItemsController";
import VesselPerformanceIndicatorsController from "./VesselPerformanceIndicators/VesselPerformanceIndicatorsController";
import HullPerformanceConfigController from "./HullPerformance/HullPerformanceConfigController";
import EnergyFlowConfigController from "./EnergyFlow/EnergyFlowConfigController";

export const panelMappings = {
  [VESSEL_DETAILS]: VesselDetailsController,
  [VESSEL_ITEMS]: VesselItemsController,
  [VESSEL_OPERATIONS]: VesselOperationsController,
  [VESSEL_PERFORMANCE_INDICATORS]: VesselPerformanceIndicatorsController,
  [ONBOARD_FRONTEND_CONFIG]: OnboardFrontendConfigController,
  [ONBOARD_PROCESSOR_CONFIG]: OnboardProcessorConfigController,
  [ONSHORE_FRONTEND_CONFIG]: OnshoreFrontendConfigController,
  [BASELINE]: BaselineController,
  [HULL_PERFORMANCE_CONFIG]: HullPerformanceConfigController,
  [ENERGY_FLOW]: EnergyFlowConfigController,
};
