import { FETCH_ITEM_TYPE_DATA } from "../actions/action.types";
import { createRequestReducer } from "./createRequestReducer";
import { size, flatMap, max, flow, keyBy, get } from "lodash";
import { ITEM_CONFIG_GENERIC_METRIC } from "../config";

export default createRequestReducer(FETCH_ITEM_TYPE_DATA);

const getRows = (data) => flatMap(data, (category) => category.data);

// TODO: Should this exclude metrics with viewposition <= 0?
const getColumnCount = (row) => size(row.metrics) + size(row.properties);

export const getMaxColumnCount = flow(
  (data) => getRows(data),
  (rows) => rows.map(getColumnCount),
  (counts) => max(counts) || 0
);

const prepareVesselItem = (vesselItem = {}, vesselitemsWithMetrics = {}) => {
  const vesselItemMetrics = [
    { ...ITEM_CONFIG_GENERIC_METRIC },
    ...get(vesselitemsWithMetrics[vesselItem.vesselItemId], "metrics", []),
  ];

  return {
    ...vesselItem,
    metrics: get(vesselItem, "metrics", [])
      .map((metric) => {
        const vesselItemMetric = vesselItemMetrics.find(
          (vim) => vim.id === metric.metricId
        );

        return {
          ...metric,
          ...vesselItemMetric,
        };
      })
      .filter((x) => x.showOperation === true),
  };
};

export const prepareVesselItemCategories = (
  { data = [] },
  vesselitemsWithMetrics
) => {
  return data
    .map((category) => ({
      ...category,
      data: get(category, "data", []).map((vesselitem) =>
        prepareVesselItem(vesselitem, vesselitemsWithMetrics)
      ),
    }))
    .filter((category) => category.data.length > 0);
};

export const prepareVesselItemDetails = (
  vesselItemDetails = {},
  vesselitemsWithMetrics = {}
) => {
  return keyBy(
    Object.values(vesselItemDetails)
      .filter((x) => x.data || x.isExpanded)
      .map((vid) => ({
        ...vid,
        data: {
          ...vid.data,
          children: get(vid.data, "children", []).map((ch) => ({
            ...ch,
            data: get(ch, "data", []).map((vesselitem) =>
              prepareVesselItem(vesselitem, vesselitemsWithMetrics)
            ),
          })),
        },
      })),
    "data.vesselItemId"
  );
};
