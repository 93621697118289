import t from "prop-types";
import React from "react";
import styles from "./VesselProfile.css";
import Image from "../Image";

export const VesselProfile = (props) => {
  const altText = `Picture of ${props.name}`;

  return (
    <div className={styles.vesselProfileContainer}>
      <div className={styles.vesselImageContainer}>
        <Image
          src={props.imageUrl}
          titleText={altText}
          altText={altText}
          loadingText="Loading vessel image"
        />
        <div className={styles.imageGradient} />
      </div>

      <div className={styles.titleContainer}>
        <h2 className={styles.vesselName}>{props.name}</h2>
        <p className={styles.imo}>IMO {props.imo}</p>
      </div>
    </div>
  );
};

VesselProfile.propTypes = {
  name: t.string.isRequired,
  imo: t.number.isRequired,
  imageUrl: t.string,
};
