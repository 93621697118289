import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import VesselMap from "../../components/VesselMap/VesselMap";
import VesselDetails from "../../components/VesselDetails/VesselDetails";
import MapFlyout from "../../components/MapFlyout/MapFlyout";
import styles from "./Map.css";
import {
  fetchMapData,
  fetchPositionDetails,
  setMapViewData,
  fitMapToVessels,
  closePositionDetails,
  hoverVesselTrackItem,
  setActiveVesselTrackItem,
  toggleMapPoi,
  togglePositionTrack,
  fetchPositionTrack,
  setVesselTrackScrollBoundary,
  hoverVesselTrackGroup,
  centerPointsInMap,
  startZoom,
  endZoom,
} from "../../actions";
import {
  getVessels,
  getVesselsByGroup,
} from "../../reducers/reducer.fetchMapData";
import { values } from "lodash/fp";
import SelectBox from "../../components/SelectBox/SelectBox";
import { isEqual } from "lodash";
import { createSelector } from "reselect";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
  getGroupFilter,
  getVesselFilter,
} from "../../selectors/filters";
import { VIEWS } from "../../config";
import Loader from "../../../common/components/Loader";

const propTypes = {
  interactive: PropTypes.bool,
};

const defaultProps = {
  interactive: true,
};

class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Map",
    };
    this.onVesselClick = this.onVesselClick.bind(this);
    this.onVesselTrackItemHover = this.onVesselTrackItemHover.bind(this);
    this.onVesselTrackItemClick = this.onVesselTrackItemClick.bind(this);
    this.onTogglePoi = this.onTogglePoi.bind(this);
    this.onZoomStart = this.onZoomStart.bind(this);
    this.onZoomEnd = this.onZoomEnd.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props;

    this.props.fetchMapData();

    if (!filters.isValid) {
      const params = convertFiltersToQueryParams(filters);
      this.props.queryContext.navigate(
        VIEWS.map.url,
        {
          date: params.date,
          groupId: params.groupId,
        },
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { vessel, selectedGroupId } = this.props;

    if (
      selectedGroupId !== prevProps.selectedGroupId ||
      !isEqual(
        this.props.mapVesselsOfSelectedGroup,
        prevProps.mapVesselsOfSelectedGroup
      )
    ) {
      this.props.fitMapToVessels(this.props.mapVesselsOfSelectedGroup);
    }

    if (vessel && !isEqual(vessel, prevProps.vessel)) {
      this.props.fitMapToVessels([vessel]);
      this.props.fetchPositionDetails(
        vessel.vesselId,
        vessel.position.positionDate
      );
    }
  }

  onTogglePoi(value) {
    this.props.toggleMapPoi(value);
  }

  onVesselClick(vessel) {
    if (this.props.vessel && this.props.vessel.vesselId === vessel.vesselId) {
      this.props.fitMapToVessels([vessel]);
      this.props.fetchPositionDetails(
        vessel.vesselId,
        vessel.position.positionDate
      );
    } else {
      this.props.queryContext.setSelectedVesselId({ id: vessel.vesselId });
    }
  }

  onVesselTrackItemHover(trackItem) {
    this.props.setHoveredVesselTrackItem(trackItem);
  }

  onVesselTrackItemClick(trackItem) {
    this.props.setActiveVesselTrackItem(trackItem);
  }

  onZoomStart() {
    this.props.startZoom();
  }

  onZoomEnd() {
    this.props.endZoom();
  }

  renderPositionDetails() {
    return (
      this.props.mapView.showDetails && (
        <VesselDetails
          date={this.props.filters.dateFilter.value}
          vesselId={this.props.filters.vesselFilter.value}
          isLoading={this.props.mapView.isLoadingDetails}
          {...this.props.positionDetails}
          vesselTrack={this.props.mapView.vesselTrack}
          vessels={this.props.vessels}
          onClose={this.props.closePositionDetails}
          onDateChanged={this.props.queryContext.setDate}
          togglePositionTrack={this.props.togglePositionTrack}
          fetchPositionTrack={this.props.fetchPositionTrack}
          setVesselTrackScrollBoundary={this.props.setVesselTrackScrollBoundary}
          hoverVesselTrackGroup={this.props.hoverVesselTrackGroup}
          centerPointsInMap={this.props.centerPointsInMap}
        />
      )
    );
  }

  render() {
    let {
      appConfig,
      groups,
      queryContext,
      isNavOpen,
      mapView,
      isLoading,
      isZooming,
    } = this.props;
    return (
      <div>
        <Header title={this.state.title} contentDistribution={"space-between"}>
          <SelectBox
            options={values(groups)}
            optionValKey={"id"}
            optionLabelKey={"name"}
            onSelect={queryContext.setSelectedGroupId}
            selected={groups[queryContext.groupId]}
          />
        </Header>
        <div className={styles.container}>
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            <>
              <VesselMap
                apiKey={appConfig.GOOGLE_MAPS_API_KEY}
                vessels={this.props.mapVesselsOfSelectedGroup}
                center={this.props.mapView.center}
                zoom={this.props.mapView.zoom}
                defaultCenter={this.props.mapView.defaultCenter}
                defaultZoom={this.props.mapView.defaultZoom}
                onVesselClick={this.onVesselClick}
                onChange={this.props.setMapViewData}
                locations={this.props.locations.locations}
                vesselTrack={this.props.mapView.vesselTrack}
                onVesselDetailsItemHover={this.onVesselTrackItemHover}
                onVesselTrackItemClick={this.onVesselTrackItemClick}
                showOverlay={!isNavOpen}
                showPoi={mapView.showPoi}
                isLoading={this.props.locations.isLoading}
                onZoomStart={this.onZoomStart}
                onZoomEnd={this.onZoomEnd}
                isZooming={isZooming}
              />
              <MapFlyout
                title="Vessel list"
                subtitle="Click to show on map"
                vesselLength={
                  (this.props.mapVesselsOfSelectedGroup &&
                    this.props.mapVesselsOfSelectedGroup.length) ||
                  0
                }
                activeOperationalProfiles={this.props.activeOperationalProfiles}
                data={this.props.mapVesselsOfSelectedGroup}
                activeVesselId={queryContext.vesselId}
                showing={false}
                vesselListItemClick={this.onVesselClick}
                togglePoi={this.onTogglePoi}
                showPoi={mapView.showPoi}
              />
            </>
          )}
          {this.renderPositionDetails()}
        </div>
      </div>
    );
  }
}

MapView.propTypes = propTypes;

MapView.defaultProps = defaultProps;

const getMapPageFilters = createSelector(
  [getVesselFilter, getGroupFilter, getDateRangeFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid: groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const mapVessels = getVessels(state.fetchMapData) || [];
  const filters = getMapPageFilters(state, props);
  return {
    appConfig: state.appConfig,
    filters,
    mapView: state.mapView,
    locations: state.locations,
    activeOperationalProfiles:
      (state.fetchMapData.data &&
        state.fetchMapData.data.activeOperationalProfiles) ||
      [],
    mapVessels,
    vessels: state.pageContext.vessels,
    groups: state.pageContext.groups,
    mapVesselsOfSelectedGroup: getVesselsByGroup(
      mapVessels,
      state.pageContext.vesselGroupVessels,
      filters.groupFilter.value
    ),
    vessel:
      filters.vesselFilter.value &&
      mapVessels.find((x) => x.vesselId === filters.vesselFilter.value),
    selectedGroupId: filters.groupFilter.value,
    positionDetails: state.fetchPositionDetails.data,
    isNavOpen: state.nav.isOpen,
    isLoading: !!state.fetchMapData.isLoading,
    isZooming: state.mapView.isZooming,
  };
};

const mapDispatchToProps = {
  fetchMapData,
  fetchPositionDetails,
  setMapViewData,
  fitMapToVessels,
  closePositionDetails,
  setHoveredVesselTrackItem: hoverVesselTrackItem,
  setActiveVesselTrackItem,
  toggleMapPoi,
  togglePositionTrack,
  fetchPositionTrack,
  setVesselTrackScrollBoundary,
  hoverVesselTrackGroup,
  centerPointsInMap,
  startZoom,
  endZoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
