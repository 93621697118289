import React from "react";
import styles from "./DataGridView.css";
import { Headers } from "./Headers";
import { Row } from "./Row";
import { Footer } from "./Footer";
import { Column, DataItem } from "./Types";

export type DataGridViewProps = {
  columns: Column[];
  data: DataItem[];
};

const DataGridView: React.FC<DataGridViewProps> = ({
  data,
  columns,
}: DataGridViewProps) => {
  const gridStyle = {
    gridTemplateColumns: columns.reduce(
      (total, _, i) => total + (i ? " auto" : "1fr"),
      ""
    ),
  };

  return (
    <div className={styles.gridContainer} style={gridStyle}>
      {columns && <Headers columns={columns} />}

      {data.map((row) => (
        <Row rowData={row} columns={columns} key={`row_${row.id}`} />
      ))}
      <Footer data={data} columns={columns} />
    </div>
  );
};

export default DataGridView;
