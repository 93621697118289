import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../common/components/Button";
import CollapseButtonIcon from "../../CollapseButtonIcon/CollapseButtonIcon";
import TrendCategoryItem from "../TrendCategoryItem/TrendCategoryItem";
import { hasSelectedItems } from "../TrendCategoryPane";
import styles from "./TrendCategoryItemType.css";

export default class TrendCategoryItemType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showItems: props.showItems,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.vesselId !== this.props.vesselId ||
      this.props.isDeselectAllActivated
    ) {
      this.props.disableDeselectAllButton();
      this.setState(() => ({
        showItems: false,
      }));
    }
  }

  showItems() {
    this.setState((state) => ({
      showItems: !state.showItems,
    }));
  }

  render() {
    const { vesselItemType, onToggleVesselItem, onToggleMetric } = this.props;
    return (
      <div key={vesselItemType.title}>
        <div className={styles.trendCategoryHeaderContainer}>
          <Button
            type={"content"}
            width={"36px"}
            padding={"0 10px"}
            circle
            clickAction={this.showItems.bind(this)}
          >
            <CollapseButtonIcon
              showItems={this.state.showItems}
              height={"16px"}
              width={"16px"}
            />
          </Button>
          <span className={styles.itemTypeCategoryName}>
            {vesselItemType.title}
          </span>
        </div>
        {this.state.showItems &&
          vesselItemType.itemTypes.map((vi) =>
            renderVesselItem(vi, onToggleVesselItem, onToggleMetric)
          )}
      </div>
    );
  }
}

export const renderVesselItem = (
  vi,
  onToggleVesselItem,
  onToggleMetric,
  isChildren
) => {
  return (
    <TrendCategoryItem
      key={vi.id}
      id={vi.id}
      name={vi.name}
      isActive={Boolean(vi.isActive)}
      items={vi.metrics}
      onToggle={() => onToggleVesselItem(vi.id)}
      onToggleItem={(id) => onToggleMetric(vi.id, id)}
      showButton
      showItems={
        Boolean(vi.isActive) ||
        hasSelectedItems(vi.items || []) ||
        vi.metrics?.some((m) => m.isActive)
      }
      onToggleVesselItem={onToggleVesselItem}
      onToggleMetric={onToggleMetric}
      childItems={vi.items}
      isChildren={isChildren}
    />
  );
};

TrendCategoryItemType.propTypes = {
  onToggleVesselItem: PropTypes.func.isRequired,
  onToggleMetric: PropTypes.func.isRequired,
  showItems: PropTypes.bool.isRequired,
  vesselId: PropTypes.string.isRequired,
  vesselItemType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    vesselItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
      })
    ),
  }),
  isDeselectAllActivated: PropTypes.bool.isRequired,
  disableDeselectAllButton: PropTypes.func.isRequired,
};
