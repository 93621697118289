import React from "react";
import { connect } from "react-redux";
import {
  closeRemoteControlVesselPopup,
  postUpdateSystem,
  revokeRemoteAccessConfiguration,
  saveRemoteAccessConfiguration,
  setRemoteAccessValue,
  setRemoteControlType,
} from "../../../actions/admin";
import { FormPopup, FormSelectBox } from "../../../components/Form";
import ConfigureRemoteAccessForm from "./ConfigureRemoteAccessForm";
import ConfigureVesselAction from "./ConfigureVesselAction";
import styles from "../Admin.css";
import Loader from "../../../../common/components/Loader";
import {
  availableTemplates,
  defaultTemplate,
} from "../../../reducers/admin/vessels/reducer.configureRemoteControl";
import { isExpired } from "../../../common/vesselActions";

class ConfigureRemoteControlPopup extends React.Component {
  render() {
    const {
      closeRemoteControlVesselPopup: onClose,
      setRemoteControlType: onChange,
    } = this.props;
    const { selectedTemplate } = this.props.configureRemoteControl;

    const { formTemplate, saveAction, enableSave, isSaving, isLoading, error } =
      this.getRemoteControlForm(selectedTemplate);

    const loader = <Loader error={error} />;

    return (
      <FormPopup
        title={"Remote Control"}
        visible={true}
        isSaving={isSaving}
        enableSave={enableSave}
        onSave={saveAction}
        onCancel={onClose}
        onHide={onClose}
        saveButtonText="Send new action to vessel"
      >
        <FormSelectBox
          name="remoteControl"
          label="Vessel action"
          selected={
            selectedTemplate.type !== undefined
              ? selectedTemplate
              : availableTemplates.find((x) => x.type === defaultTemplate.type)
          }
          options={availableTemplates}
          optionValKey="type"
          optionLabelKey="name"
          onChange={onChange}
        />
        <div className={styles.vesselActionForm}>
          {isLoading ? loader : formTemplate}
        </div>
        {error && error.response && error.response.data && (
          <div>{error.response.data}</div>
        )}
      </FormPopup>
    );
  }

  getRemoteControlForm() {
    const { postUpdateSystem, saveRemoteAccessConfiguration } = this.props;
    const {
      currentAction,
      remoteAccessForm,
      selectedTemplate,
      updateSystemForm,
    } = this.props.configureRemoteControl;

    const result = {};
    const templateType =
      selectedTemplate && selectedTemplate.type
        ? selectedTemplate.type
        : defaultTemplate.type;
    const isActionExpired = isExpired(currentAction);
    let form;

    if (templateType === "UpdateSystem") {
      result.formTemplate = <ConfigureVesselAction />;
      result.saveAction = postUpdateSystem;
      form = updateSystemForm;
    } else {
      result.formTemplate = <ConfigureRemoteAccessForm />;
      result.saveAction = saveRemoteAccessConfiguration;
      form = remoteAccessForm;
    }

    result.isLoading = form.isLoading;
    result.isSaving = form.isSaving;
    result.error = form.error;
    result.enableSave =
      isActionExpired &&
      !form.error &&
      !form.isSaving &&
      form.isValid &&
      result.saveAction !== undefined;
    return result;
  }
}

const mapStateToProps = (state) => ({
  configureRemoteControl: state.admin.vessels.configureRemoteControl,
  updateTargets: state.admin.vessels.vesselList.updateTargets,
});

const mapDispatchToProps = {
  closeRemoteControlVesselPopup,
  postUpdateSystem,
  revokeRemoteAccessConfiguration,
  saveRemoteAccessConfiguration,
  setRemoteAccessValue,
  setRemoteControlType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigureRemoteControlPopup);
