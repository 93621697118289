import fetch, { post } from "../action.fetch";
import { admin } from "../action.types";
import { ADMIN_HEALTH_MANAGEMENT_EVENT } from "../../api";

export const fetchEvents = (statusType) => (dispatch, getState) =>
  dispatch(
    fetch({
      url: ADMIN_HEALTH_MANAGEMENT_EVENT.get(
        statusType ||
          getState().admin.healthManagementEvent.eventList
            .selectedApprovalStatus
      ),
      actionType: admin.healthManagement.FETCH_EVENTS,
    })
  );

export const fetchEvent = (eventId) =>
  fetch({
    url: ADMIN_HEALTH_MANAGEMENT_EVENT.getEvent(eventId),
    actionType: admin.healthManagement.FETCH_EVENT,
  });

export const fetchEventDetails = (eventId) =>
  fetch({
    url: ADMIN_HEALTH_MANAGEMENT_EVENT.getEventDetails(eventId),
    actionType: admin.healthManagement.FETCH_EVENT_DETAILS,
  });

export const fetchStandardTexts = (eventId) =>
  fetch({
    url: ADMIN_HEALTH_MANAGEMENT_EVENT.getStandardTexts(eventId),
    actionType: admin.healthManagement.FETCH_STANDARD_TEXTS,
  });

export const updateEventField = (field, value) => ({
  type: admin.healthManagement.UPDATE_EVENT_FIELD,
  field,
  value,
});

export const setStatusFilter = (approvalStatus) => (dispatch, getState) => {
  const state = getState();
  if (
    state.admin.healthManagementEvent.selectedApprovalStatus !== approvalStatus
  ) {
    dispatch(fetchEvents(approvalStatus));
  }
  dispatch({
    approvalStatus,
    type: admin.healthManagement.SET_STATUS_FILTER,
  });
};

export const sortItems = ({ sortBy, sortDirection }) => ({
  type: admin.healthManagement.SORT_EVENTS,
  sortBy,
  sortDirection,
});

export const selectVessel = (vessel) => ({
  type: admin.healthManagement.SELECT_VESSEL,
  vessel,
});

export const approveEvent = () => (dispatch, getState) => {
  const state = getState();
  const {
    id,
    approvalCommentReason,
    approvalCommentDetails,
    approvalStatus,
    markAsStandardText,
  } = state.admin.healthManagementEvent.event.data;

  dispatch(
    post({
      url: ADMIN_HEALTH_MANAGEMENT_EVENT.approveEvent(id),
      payload: {
        commentReason: approvalCommentReason,
        commentDetails: approvalCommentDetails,
        status: approvalStatus,
        markAsStandardText,
      },
      actionType: admin.healthManagement.APPROVE_EVENT,
      onSuccess: () => {
        dispatch(fetchEvent(id));
        dispatch(fetchEventDetails(id));
        dispatch(fetchStandardTexts(id));
      },
    })
  );
};

export const closeError = () => (dispatch) => {
  dispatch({
    type: admin.healthManagement.CLOSE_ERROR_POPUP,
  });
};
