import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styles from "./ContextMenu.css";

export default class ContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    const { modalTargetId } = this.props;
    this.modalTarget = document.getElementById(modalTargetId);
    this.modalTarget?.appendChild(this.el);
    this.width = this.props.width || 250;
    this.height = this.props.height || 160;

    if (this.props.menuClassname) {
      const element = document.createElement("div");
      element.className = this.props.menuClassname;
      document.body.appendChild(element);
      const style = window.getComputedStyle(element);
      this.backgroundColor = style.getPropertyValue("background-color").trim();
    } else {
      this.backgroundColor = getComputedStyle(this.el).getPropertyValue(
        "--dark-grey"
      );
    }
  }

  componentWillUnmount() {
    this.modalTarget?.removeChild(this.el);
  }

  renderArrow(leftWidth, rightWidth, topWidth, bottomWidth) {
    this.arrowRef.style.borderTopWidth = topWidth;
    this.arrowRef.style.borderBottomWidth = bottomWidth;
    this.arrowRef.style.borderLeftWidth = leftWidth;
    this.arrowRef.style.borderRightWidth = rightWidth;
    this.arrowRef.style.borderTopColor =
      topWidth === "15px" ? "transparent" : this.backgroundColor;
    this.arrowRef.style.borderBottomColor =
      bottomWidth === "15px" ? "transparent" : this.backgroundColor;
    this.arrowRef.style.borderLeftColor =
      leftWidth === "15px" ? "transparent" : this.backgroundColor;
    this.arrowRef.style.borderRightColor =
      rightWidth === "15px" ? "transparent" : this.backgroundColor;
  }
  renderShadow(horizontal, vertical) {
    this.optionsRef.style.boxShadow = `${horizontal} ${vertical} 10px rgba(0, 0, 0, 0.5)`;
  }
  renderLeftArrow() {
    this.renderArrow("0px", "20px", "15px", "15px");
    this.renderShadow("10px", "0px");
  }

  renderRightArrow() {
    this.renderArrow("20px", "0px", "15px", "15px");
    this.renderShadow("-10px", "0px");
  }

  renderUpArrow() {
    this.renderArrow("15px", "15px", "0px", "20px");
    this.renderShadow("0px", "10px");
  }

  renderDownArrow() {
    this.renderArrow("15px", "15px", "20px", "0px");
    this.renderShadow("0px", "-10px");
  }

  renderList() {
    const { x, y, width, height } =
      this.props.buttonRef.getBoundingClientRect();
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let arrowX, arrowY, optionsX, optionsY;

    if (y + height + this.height > windowHeight + scrollTop) {
      arrowY = y - 20;
      arrowX = x + width / 2 - 15;
      optionsX = arrowX - 20;
      optionsY = arrowY - this.height;
      this.renderDownArrow();
    } else {
      arrowY = y + height;
      arrowX = x + width / 2 - 15;
      optionsX = arrowX - 20;
      optionsY = arrowY + 20;
      this.renderUpArrow();
    }
    if (windowWidth + scrollLeft - (x + width / 2 - 15) < this.width) {
      arrowY = y + height / 2 - 15;
      arrowX = x - 20;
      optionsX = arrowX - this.width;
      optionsY = arrowY - 20;
      if (arrowY + this.width > windowHeight + scrollTop) {
        optionsY = arrowY - this.height + 20 + 25;
      }
      this.renderRightArrow();
    }
    if (x + width / 2 - 15 < 50) {
      arrowY = y + height / 2 - 15;
      arrowX = x + width;
      if (arrowY + this.width > windowHeight + scrollTop) {
        optionsY = arrowY - this.height + 20 + 25;
      }
      this.renderLeftArrow();
    }

    this.arrowRef.style.display = "block";
    this.arrowRef.style.left = arrowX + "px";
    this.arrowRef.style.top = arrowY + "px";

    this.optionsRef.style.display = "flex";
    this.optionsRef.style.left = optionsX + "px";
    this.optionsRef.style.top = optionsY + "px";
  }

  componentDidUpdate() {
    if (this.props.isVisible) {
      this.renderList();
    }
  }

  renderPopup() {
    if (!this.props.isVisible) return;
    return ReactDOM.createPortal(
      <div>
        <div className={styles.arrow} ref={(el) => (this.arrowRef = el)}></div>
        <div
          className={this.props.menuClassname || styles.optionContainer}
          style={{
            width: this.width + "px",
            height: this.height + "px",
            display: "none",
            flexDirection: "column",
            position: "absolute",
          }}
          ref={(el) => (this.optionsRef = el)}
        >
          {this.props.options.map((x) => {
            return (
              <div
                className={styles.option}
                key={x.id}
                onClick={() => x.onClick()}
              >
                {x.text}
              </div>
            );
          })}
        </div>
      </div>,
      this.el
    );
  }

  render() {
    return <>{this.renderPopup()}</>;
  }
}

ContextMenu.propTypes = {
  modalTargetId: PropTypes.string.isRequired,
  buttonRef: PropTypes.instanceOf(Element).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  menuClassname: PropTypes.string,
  arrowClassname: PropTypes.object,
};
