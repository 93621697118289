import { IOperationMode } from "./IOperationMode";
import {
  OperationId,
  operationLegend,
  operationName,
} from "../operation/operationId";

export abstract class OperationMode implements IOperationMode {
  abbreviation: string;
  id: OperationId;
  title: string;

  protected constructor(id: OperationId) {
    this.id = id;
    this.abbreviation = operationLegend[id];
    this.title = operationName[id];
  }
}
