import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMapData } from "../actions/action.fetchMapData";
import { setMapViewData } from "../actions/action.setMapViewData";
import Loader from "../../common/components/Loader/Loader";
import VesselMap from "../components/VesselMap/VesselMap";
import { getVessels } from "../reducers/reducer.fetchMapData";
import { fitMapToVessels } from "../actions/action.fitMapToVessels";

class MapContainer extends Component {
  componentDidMount() {
    this.props.fetchMapData();
  }

  componentDidUpdate(prevProps) {
    const vessels = getVessels(this.props.mapData);
    const prevVessels = getVessels(prevProps.mapData);
    if (vessels !== prevVessels) {
      this.props.fitMapToVessels(vessels);
    }
  }

  render() {
    const {
      appConfig,
      mapData,
      mapView,
      locations,
      setMapViewData: onChange,
    } = this.props;
    if (!mapData.data) {
      return (
        <Loader text="Loading map data" error={mapData.error} expand={true} />
      );
    }
    return (
      <VesselMap
        apiKey={appConfig.GOOGLE_MAPS_API_KEY}
        vessels={mapData.data.vessels}
        locations={locations.locations}
        center={mapView.center}
        zoom={mapView.zoom}
        defaultCenter={mapView.defaultCenter}
        defaultZoom={mapView.defaultZoom}
        onChange={onChange}
      />
    );
  }
}

export default connect(
  (state) => ({
    appConfig: state.appConfig,
    mapData: state.fetchMapData,
    mapView: state.mapView,
    locations: state.locations,
  }),
  { fetchMapData, setMapViewData, fitMapToVessels }
)(MapContainer);
