import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../../common/components/Button";
import Popup from "../../components/Popup/Popup";
import {
  hideExport,
  showExport,
  setEndTimeOffset,
  setStartTimeOffset,
  toggleTimeOffsetOption,
  excelExportItem,
} from "../../actions/action.export";
import styles from "./ExportPopup.css";
import Loader from "../../../common/components/Loader";
import classNames from "../../../common/classNames";
import SelectBox from "../../components/SelectBox";
import { formatDateTime } from "../../common/dates";

const formatDateWithOffset = (date, offset) => {
  const formatOffset = () => (offset > 0 ? `+${offset}` : offset);
  return `${formatDateTime(date)} UTC ${offset === 0 ? "" : formatOffset()}`;
};

const buttonOnColor = "#8395ac";

class ExportPopup extends Component {
  constructor(props) {
    super(props);
  }

  onExportClick = () => {
    const { vesselId, dateRange, states } = this.props;
    this.props.showExport(vesselId, dateRange, states);
  };

  onHide = () => {
    this.props.hideExport();
  };

  onExport_Click = (item) => {
    this.props.excelExportItem(item);
  };

  validateItem = (item) => {
    if (item.validate === undefined) {
      return {
        isValid: true,
      };
    }
    const { vesselId, dateRange } = this.props.excelExport;
    return item.validate(vesselId, dateRange);
  };

  renderContent() {
    const {
      excelExport,
      toggleTimeOffsetOption: onClick,
      setStartTimeOffset: onStartSelect,
      setEndTimeOffset: onEndSelect,
      exportConfigurations,
    } = this.props;
    const { running, error, options, initialDateRange } = excelExport;
    const { timeOffset } = options;
    if (running === true || error) {
      return (
        <div className={styles.exportProgressContainer}>
          <Loader text={"Generating export, please wait"} error={error} />
        </div>
      );
    } else {
      return (
        <div className={styles.exportContainer}>
          <div className={styles.header}>Options</div>
          <div className={styles.exportActionOptionContainer}>
            <div className={classNames(styles.exportActionContainer)}>
              <div className={styles.infoContainer}>
                <span className={styles.title}>Time offset</span>
                <p className={styles.description}>
                  Optional timezone offset adjustment
                </p>
              </div>
              <div className={styles.actionContainer}>
                <Button
                  type="smallButton"
                  className={styles.timeOffsetButton}
                  value={timeOffset.enabled ? "On" : "Off"}
                  backgroundColor={
                    timeOffset.enabled ? buttonOnColor : undefined
                  }
                  clickAction={onClick}
                />
              </div>
            </div>
            {timeOffset.enabled && (
              <div className={styles.optionContainer}>
                <div className={styles.optionRow}>
                  <div className={styles.labelContainer}>
                    <span className={styles.label}>Start time offset</span>
                  </div>
                  <div className={styles.valueContainer}>
                    <SelectBox
                      options={timeOffset.options}
                      optionValKey={"value"}
                      optionLabelKey={"label"}
                      selectedValKey={"value"}
                      onSelect={onStartSelect}
                      selectedVal={timeOffset.startTimeOffset}
                    />
                  </div>
                  <div className={styles.previewContainer}>
                    <p>
                      {formatDateWithOffset(
                        initialDateRange.from,
                        timeOffset.startTimeOffset
                      )}
                    </p>
                  </div>
                </div>
                <div className={styles.optionRow}>
                  <div className={styles.labelContainer}>
                    <span className={styles.label}>End time offset</span>
                  </div>
                  <div className={styles.valueContainer}>
                    <SelectBox
                      options={timeOffset.options}
                      optionValKey={"value"}
                      optionLabelKey={"label"}
                      selectedValKey={"value"}
                      onSelect={onEndSelect}
                      selectedVal={timeOffset.endTimeOffset}
                    />
                  </div>
                  <div className={styles.previewContainer}>
                    <p>
                      {formatDateWithOffset(
                        initialDateRange.to,
                        timeOffset.endTimeOffset
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.header}>Exports</div>
          {exportConfigurations.map((exportConfiguration, i) => {
            const validation = this.validateItem(exportConfiguration);
            return (
              <div
                key={`export_action_${i}`}
                className={styles.exportActionContainer}
              >
                <div className={styles.infoContainer}>
                  <span className={styles.title}>
                    {exportConfiguration.name}
                    {validation.isValid !== true && (
                      <span className={styles.errorMessage}>
                        {validation.message}
                      </span>
                    )}
                  </span>
                  <p className={styles.description}>
                    {exportConfiguration.description}
                  </p>
                </div>
                <div className={styles.actionContainer}>
                  <Button
                    value={"Export"}
                    type="smallButton"
                    clickAction={() => this.onExport_Click(exportConfiguration)}
                    disabled={validation.isValid !== true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    const { excelExport } = this.props;
    const { visible, running, error } = excelExport;

    return (
      <div>
        <Button
          value="Export"
          type="smallButton"
          margin="0px 0px 0px 16px"
          clickAction={this.onExportClick}
        />
        {(visible || running || error) && (
          <Popup
            title="Export"
            visible={visible || running || error}
            onHide={this.onHide}
            target="popupContainer"
            transparentContent={true}
          >
            {this.renderContent()}
          </Popup>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  excelExport: state.excelExport,
});

const mapDispatchToProps = {
  excelExportItem,
  showExport,
  hideExport,
  toggleTimeOffsetOption,
  setStartTimeOffset,
  setEndTimeOffset,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportPopup);
