import axios, { AxiosStatic } from "axios";
import { ApiClient } from "./ApiClient";
import { createBrowserHistory } from "history";
import withQueries, { EnhancedHistory } from "history-query-enhancer";
import {
  parseQueryString,
  serializeQueryString,
} from "../../common/queryStrings";
import { now } from "../common/dates";
import { createTelemetryService } from "./TelemetryService";
import { get } from "lodash";
import IApiClient from "./IApiClient";
import { AppConfig } from "../../../config/onshore/environments/AppConfig";

export interface Services {
  http: AxiosStatic;
  apiClient: IApiClient;
  history: EnhancedHistory;
  appConfig: AppConfig;
  telemetryService: any;
  now: () => Date;
}

export function initServices(appConfig: AppConfig): Services {
  axios.defaults.baseURL = appConfig.BACKEND_URL;
  const publicClient = axios.create({
    baseURL: appConfig.PUBLICBACKEND_URL,
    timeout: 8000,
    headers: { Accept: "text/plain" },
  });
  const browserHistory = createBrowserHistory();
  const history = withQueries({
    parse: parseQueryString,
    stringify: serializeQueryString,
  })(browserHistory);

  const apiClient = new ApiClient(axios, publicClient);

  const telemetryService = createTelemetryService();
  if (get(appConfig, "INSTRUMENTATION_KEY", "") !== "") {
    telemetryService.initialize(appConfig.INSTRUMENTATION_KEY, history);
  }

  return {
    http: axios,
    apiClient: apiClient,
    history,
    appConfig,
    telemetryService,
    now: () => now(),
  };
}
