import React, { Component } from "react";
import PropTypes from "prop-types";
import RGL, { WidthProvider } from "react-grid-layout";
import styles from "./PageConfigurationGrid.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Icon } from "../../../../../common/components/Icon/Icon";
import { connect } from "react-redux";
import {
  updateGridLayout,
  updateRowsCount,
  openSettingsDialog,
} from "../../../../actions/admin";
import { get, sortBy } from "lodash";
import {
  displayDonuts,
  displayEnergyBudgetResult,
  displayFleetConnectionStatus,
  displayGauge,
  displayMap,
  displayVesselCount,
  displayVesselProfile,
  displayEnergyBudgetOverview,
  displayMonthlyFuelUsage,
  displayFuelEfficiency,
  displayEquinorVesselReport,
  displayHealthEventStatus,
} from "./pageConfigurationGridHelper";
import classNames from "../../../../../common/classNames";
import { EDITABLE_COMPONENTS } from "./pageConfigurationGridData";
import {
  convertToArray,
  getComponentTitle,
} from "../../../../common/pageConfig";

const ReactGridLayout = WidthProvider(RGL);

const componentMap = {
  MONTHLY_FUEL_USAGE: displayMonthlyFuelUsage,
  VESSEL_COUNT: displayVesselCount,
  VESSEL_PROFILE: displayVesselProfile,
  MAP: displayMap,
  VESSEL_MAP: displayMap,
  FUEL_EFFICIENCY_TODAY: displayFuelEfficiency,
  FUEL_EFFICIENCY_YESTERDAY: displayFuelEfficiency,
  FLEET_CONNECTION_STATUS: displayFleetConnectionStatus,
  HEALTH_EVENT_STATUS: displayHealthEventStatus,
  ENERGY_BUDGET_OVERVIEW: displayEnergyBudgetOverview,
  ENERGY_BUDGET_RESULT: displayEnergyBudgetResult,
  DONUTS: displayDonuts,
  GAUGE: displayGauge,
  EQUINOR_VESSEL_REPORT: displayEquinorVesselReport,
};

class PageConfigurationGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      energyBudgetChanged: false,
    };

    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const type = "ENERGY_BUDGET_RESULT";
    const prevEnergyBudgetResultComponent = prevProps.rowsConfig.find(
      (c) => c.type === type
    );
    const energyBudgetResultComponent = this.props.rowsConfig.find(
      (c) => c.type === type
    );
    if (prevEnergyBudgetResultComponent && energyBudgetResultComponent) {
      const prevParams = convertToArray(
        get(prevEnergyBudgetResultComponent, "params.metricId", [])
      );
      const params = convertToArray(
        get(energyBudgetResultComponent, "params.metricId", [])
      );
      if (prevParams.length !== params.length) {
        this.setState((state) => ({
          ...state,
          energyBudgetChanged: true,
        }));
      } else if (this.state.energyBudgetChanged) {
        this.setState((state) => ({
          ...state,
          energyBudgetChanged: false,
        }));
      }
    }
  }

  onLayoutChange(updatedLayout) {
    const { layout, rowsConfig, rowsCount } = this.props;
    updatedLayout = sortBy(updatedLayout, ["y", "x"]);
    const updatedRowsCount =
      updatedLayout.reduce((acc, item) => (acc > item.y ? acc : item.y), -1) +
      1;
    if (rowsCount !== updatedRowsCount) {
      this.props.updateRowsCount(updatedRowsCount);
    }
    if (JSON.stringify(layout) !== JSON.stringify(updatedLayout)) {
      const updatedRowsConfig = updatedLayout.map((l) => {
        return {
          ...rowsConfig.find((rc) => rc.id === l.i),
          colSpan: l.w,
          row: l.y,
        };
      });
      this.props.updateGridLayout(updatedLayout, updatedRowsConfig);
      // We trigger this event in order to activate the Autosizer for the components
      // in case the size of the columns is changed.
      window.dispatchEvent(new Event("resize"));
    }
  }

  onRemoveItem(id) {
    const layout = this.props.layout.filter((x) => x.i !== id);
    const rowsConfig = this.props.rowsConfig.filter((x) => x.id !== id);
    this.props.updateGridLayout(layout, rowsConfig);
  }

  openDialog(id) {
    const componentTitle = getComponentTitle(id);
    if (EDITABLE_COMPONENTS.includes(componentTitle)) {
      this.props.openSettingsDialog(id);
    }
  }

  render() {
    const { layout, maxRows, maxCols, rowsConfig, pageContext } = this.props;

    return (
      <div className={styles.gridContainer}>
        <ReactGridLayout
          layout={layout}
          cols={maxCols}
          rowHeight={470}
          maxRows={maxRows}
          margin={[8, 8]}
          preventCollision={false}
          compactType={null}
          onLayoutChange={this.onLayoutChange}
          resizeHandles={["e"]}
        >
          {layout.map((item) => {
            const componentTitle = getComponentTitle(item.i);
            const component = componentMap[componentTitle];
            const params = get(
              rowsConfig.find((rc) => rc.id === item.i),
              "params",
              {}
            );
            return (
              <div key={item.i} className={styles.gridCell}>
                <div className={styles.overlay}></div>
                {component ? (
                  component(
                    params,
                    pageContext,
                    componentTitle,
                    this.state.energyBudgetChanged
                  )
                ) : (
                  <span className={styles.componentTitle}>
                    {componentTitle}
                  </span>
                )}
                <span
                  className={classNames(
                    styles.settings,
                    !EDITABLE_COMPONENTS.includes(componentTitle)
                      ? styles.disabled
                      : ""
                  )}
                  onClick={this.openDialog.bind(this, item.i)}
                >
                  <Icon icon={"settingsIcon"} size={"s"}></Icon>
                </span>
                <span
                  className={styles.remove}
                  onClick={this.onRemoveItem.bind(this, item.i)}
                >
                  <Icon icon={"removeIcon"} size={"ms"}></Icon>
                </span>
              </div>
            );
          })}
        </ReactGridLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const layout = state.admin.pageLayoutConfiguration.layout;
  const rowsCount = state.admin.pageLayoutConfiguration.rowsCount;
  const rowsConfig = state.admin.pageLayoutConfiguration.rowsConfig;
  const pageContext = state.pageContext;
  return {
    layout,
    rowsCount,
    rowsConfig,
    pageContext,
  };
};

const mapActionsToProps = {
  updateGridLayout,
  updateRowsCount,
  openSettingsDialog,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageConfigurationGrid);

PageConfigurationGrid.propTypes = {
  maxRows: PropTypes.number.isRequired,
  maxCols: PropTypes.number.isRequired,
};
