import React from "react";
import PropTypes from "prop-types";
import DataDetailsGroup from "./DataDetailsGroup";
import DataDetailsBarChart from "./DataDetailsBarChart";
import DataDetailsLineChart from "./DataDetailsLineChart";
import DataDetailsInformation from "./DataDetailsInformation";
import DataDetailsImage from "./DataDetailsImage";
import DataDetailsValueTable from "./DataDetailsValueTable";
import DataDetailsPropertyList from "./DataDetailsPropertyList";
import DataDetailsList from "./DataDetailsList";
import DataDetailsHeatMapChart from "./DataDetailsHeatMapChart";
import DataDetailsPlotChart from "./DataDetailsPlotChart";
import DataDetailsComponentStatus from "./DataDetailsComponentStatus";
import { isArray, has } from "lodash";
import styles from "./DataDetails.css";
import DataDetailsRULChart from "./DataDetailsRULChart";

class DataDetails extends React.PureComponent {
  static createDataDetailsComponent(props, i, dataDetails = {}) {
    const { customResolver } = props;
    switch (dataDetails?.dataType) {
      case "DetailsGroup":
        return (
          <DataDetailsGroup key={`details_${i}`} {...props} {...dataDetails} />
        );
      case "BarChart":
        return <DataDetailsBarChart key={`details_${i}`} {...dataDetails} />;
      case "LineChart":
        return (
          <DataDetailsLineChart
            key={`details_${i}`}
            {...processChartDetails(dataDetails)}
          />
        );
      case "HeatMapChart":
        return (
          <DataDetailsHeatMapChart key={`details_${i}`} {...dataDetails} />
        );
      case "PlotChart":
        return <DataDetailsPlotChart key={`details_${i}`} {...dataDetails} />;
      case "Information":
        return <DataDetailsInformation key={`details_${i}`} {...dataDetails} />;
      case "List":
        return (
          <DataDetailsList key={`details_${i}`} {...props} {...dataDetails} />
        );
      case "Image":
        return <DataDetailsImage key={`details_${i}`} {...dataDetails} />;
      case "ValueTable":
        return <DataDetailsValueTable key={`details_${i}`} {...dataDetails} />;
      case "PropertyList":
        return (
          <DataDetailsPropertyList key={`details_${i}`} {...dataDetails} />
        );
      case "ComponentStatus":
        return (
          <DataDetailsComponentStatus key={`details_${i}`} {...dataDetails} />
        );
      case "RULChart":
        return <DataDetailsRULChart key={`details_${i}`} {...dataDetails} />;
      default:
        return (
          customResolver &&
          dataDetails &&
          has(customResolver, dataDetails?.dataType, i) &&
          customResolver[dataDetails?.dataType](dataDetails, props, i)
        );
    }
  }

  render() {
    const { dataDetails = [], ...props } = this.props;
    return (
      <div className={styles.container}>
        {(isArray(dataDetails)
          ? dataDetails.map((d, i) =>
              DataDetails.createDataDetailsComponent(props, i, d)
            )
          : DataDetails.createDataDetailsComponent(props, 0, dataDetails)) || (
          <div />
        )}
      </div>
    );
  }
}

const processChartDetails = (dataDetails) => {
  if (!dataDetails.series) return dataDetails;

  const detailsCopy = { ...dataDetails };
  const findValue = (values, current) => {
    if (current === "min") {
      return Math.min(...values.map((v) => v.y));
    }
    if (current === "max") {
      return Math.max(...values.map((v) => v.y));
    }
    return current;
  };

  detailsCopy.series.forEach((s) => {
    if (s.yDomain && s.yDomain.some((x) => x === "min" || x === "max")) {
      if (!s.values || !s.values.length) {
        s.yDomain = [];
        return;
      }
      s.yDomain = s.yDomain.map((x) => findValue(s.values, x));
    }
  });

  return detailsCopy;
};

DataDetails.propTypes = {
  dataDetails: PropTypes.any,
  customResolver: PropTypes.object,
};

export default DataDetails;
