import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../../../common/components/Loader";
import Popup from "../../../../components/Popup/Popup";
import styles from "./WindConfigPopup.css";
import { FormGroup, FormFileUpload } from "../../../../components/Form";
import Button from "../../../../../common/components/Button";
import ReactMarkdown from "react-markdown";

export default class WindConfigPopup extends React.Component {
  constructor(props) {
    super(props);
    this.handleFileChanged = this.handleFileChanged.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleDownloadClick(data, filename) {
    this.props.onDownload(data, filename);
  }
  handleSave() {
    this.props.onSave(this.props.file);
  }
  handleFileChanged(_, file) {
    this.props.onInputChanged(file);
  }
  render() {
    const {
      onCancel,
      value,
      hasChanges,
      hasValue,
      isLoading,
      error,
      exampleData,
      imo,
    } = this.props;

    const buttons = [
      {
        label: "Save",
        action: this.handleSave,
        disabled: isLoading || !hasChanges,
      },
      {
        label: "Cancel",
        action: onCancel,
      },
    ];

    let content;

    if (isLoading || error) {
      content = (
        <div className={styles.container}>
          <Loader error={error} expand={true} />
        </div>
      );
    } else {
      content = (
        <FormGroup>
          <div className={styles.uploadbutton}>
            <div>
              <FormFileUpload
                name="windCoefficients"
                label="Upload"
                labelPosition={"left"}
                onChange={this.handleFileChanged}
              />
            </div>
            <div className={styles.button}>
              <Button
                value={"Download"}
                disabled={!hasValue}
                clickAction={() =>
                  this.handleDownloadClick(
                    value,
                    `imo${imo}_windCoefficients.csv`
                  )
                }
              />
            </div>
          </div>

          <div className={styles.infoText}>
            <ReactMarkdown skipHtml={true}>
              {getWindCoefficientsText()}
            </ReactMarkdown>
            <p>
              <a
                href="#"
                onClick={() =>
                  this.handleDownloadClick(
                    exampleData,
                    `example_windCoefficients.csv`
                  )
                }
              >
                Download example data
              </a>
            </p>
          </div>
        </FormGroup>
      );
    }

    return (
      <Popup
        title={"Wind Coefficients configuration"}
        visible
        buttons={buttons}
        onHide={onCancel}
        testId="windConfigPopup"
      >
        <div>{content}</div>
      </Popup>
    );
  }
}

WindConfigPopup.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string,
  hasChanges: PropTypes.bool,
  hasValue: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  exampleData: PropTypes.string.isRequired,
  imo: PropTypes.number.isRequired,
};

const getWindCoefficientsText = () => {
  return `Wind coefficient reference curves contains wind(WindDirectionRelative,WindResistanceCoefRelative) data`;
};
