export const getMetricName = (metricName) => {
  let regExp = /\(([^)]+)\)/;
  let fuelType = regExp.exec(metricName);
  if (!fuelType) {
    return "";
  }
  return fuelType[1];
};

export const maxOf = (values) => {
  if (!values) {
    return 0;
  }

  let max;

  values.forEach((value) => {
    let currentMax = Math.max(value.budgetValue, value.actualValue);

    if (!max || max < currentMax) {
      max = currentMax;
    }
  });
  return max;
};
