import React from "react";
import { connect } from "react-redux";
import {
  closeDeletePageConfigPopup,
  deletePageConfig,
} from "../../../../actions/admin";
import { FormPopup } from "../../../../components/Form";
import styles from "./PageConfigs.css";

class PageConfigsPopup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onPopupFormHide = this.onPopupFormHide.bind(this);
    this.onPopupFormCancel = this.onPopupFormCancel.bind(this);
  }

  onPopupFormHide() {
    this.props.closeDeletePageConfigPopup();
  }

  onPopupFormCancel() {
    this.props.closeDeletePageConfigPopup();
  }

  renderError(hasError) {
    if (hasError) {
      return (
        <p className={styles.detailsErrorStyle}>Error deleting page config.</p>
      );
    }
    return <p />;
  }

  render() {
    const { showDeletePopup, form } = this.props;
    const { isSaving } = form;
    const enableSave =
      (!form.isUpdate || form.hasChanges) && form.isValid && !isSaving;
    const id = form.fields.id;
    const company = this.props.companies.find(
      (c) => c.id === form.fields.companyId
    );
    const vessel = this.props.companies.find(
      (v) => v.id === form.fields.vesselId
    );
    return (
      <FormPopup
        title="Permanently delete Page Config?"
        visible={showDeletePopup}
        isSaving={isSaving}
        enableSave={enableSave}
        onHide={this.onPopupFormHide}
        onSave={() => this.props.deletePageConfig(id)}
        onCancel={this.onPopupFormCancel}
        saveButtonText={"Delete"}
      >
        <div>
          {this.renderError(form.failed)}
          <p className={styles.detailsStyle}>Name: {form.fields.name}</p>
          <p className={styles.detailsStyle}>
            PageName: {form.fields.pageName}
          </p>
          <p className={styles.detailsStyle}>
            Company: {company ? company.name : ""}
          </p>
          <p className={styles.detailsStyle}>
            Vessel: {vessel ? vessel.name : ""}
          </p>
        </div>
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  vessels: state.admin.pageConfig.vessels,
  companies: state.admin.pageConfig.companies,
  showDeletePopup: state.admin.pageConfig.showDeletePopup,
  form: state.admin.pageConfig.deleteForm,
});

const mapDispatchToProps = {
  closeDeletePageConfigPopup,
  deletePageConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageConfigsPopup);
