export function getYAxisConfig(yAxisConfig) {
  const yScaleConfig = {};
  if (yAxisConfig) {
    if (yAxisConfig.scaleType) yScaleConfig.yScaleType = yAxisConfig.scaleType;
    if (yAxisConfig.properties)
      yScaleConfig.yScaleProperties = yAxisConfig.properties;
  }

  return yScaleConfig;
}
