import React from "react";
import { connect } from "react-redux";
import {
  cancelEditFolder,
  saveEditFolder,
  setFolderDescription,
  setFolderName,
} from "../../../actions/action.documents";
import Popup from "../../../components/Popup/Popup";
import FormGroup from "../../../components/Form/FormGroup/FormGroup";
import { FormTextBox } from "../../../components/Form";
import styles from "./FolderEditDialog.css";

class FolderEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onHide = this.onHide.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);
    this.onFolderNameChange = this.onFolderNameChange.bind(this);
    this.onFolderDescriptionChange = this.onFolderDescriptionChange.bind(this);
  }

  onHide() {
    this.props.cancelAddFolder();
  }

  onCancelClick() {
    this.props.cancelAddFolder();
  }

  onOkClick() {
    this.props.saveAddFolder();
  }

  onFolderNameChange(_name, fileName) {
    this.props.setFolderName(fileName);
  }

  onFolderDescriptionChange(_name, description) {
    this.props.setFolderDescription(description);
  }

  renderContent() {
    const { editFolder } = this.props;
    return (
      <div className={styles.contentContainer}>
        <FormGroup onEnter={this.onOkClick}>
          <FormTextBox
            name={"folderName"}
            label={"Folder name"}
            labelPosition={"top"}
            value={editFolder.name}
            onChange={this.onFolderNameChange}
            required={true}
            maxLength={255}
          />
          <FormTextBox
            name={"folderDesc"}
            label={"Description"}
            labelPosition={"top"}
            value={editFolder.description}
            onChange={this.onFolderDescriptionChange}
            required={false}
            multiLine={true}
            maxLength={255}
          />
        </FormGroup>
      </div>
    );
  }

  render() {
    const { editFolder } = this.props;
    const visible = editFolder !== undefined && editFolder !== null;
    return (
      <Popup
        visible={visible}
        target={"popupContainer"}
        title={
          editFolder && editFolder.new === true
            ? "Add new folder"
            : "Edit folder"
        }
        onHide={this.onHide}
        buttons={[
          {
            label: "Cancel",
            action: this.onCancelClick,
            disabled: visible && editFolder.isSaving,
          },
          {
            label: "Ok",
            action: this.onOkClick,
            disabled:
              (visible && !(editFolder.isValid && editFolder.hasChanges)) ||
              (visible && editFolder.isSaving),
          },
        ]}
      >
        {visible && this.renderContent()}
      </Popup>
    );
  }
}

const mapStateToDispatch = (state) => ({
  editFolder: state.documents.editFolder,
});

const mapPropsToDispatch = {
  cancelAddFolder: cancelEditFolder,
  saveAddFolder: saveEditFolder,
  setFolderName,
  setFolderDescription,
};

export default connect(
  mapStateToDispatch,
  mapPropsToDispatch
)(FolderEditDialog);
