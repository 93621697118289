import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import * as d3 from "d3";
import { color } from "../../colors";
import { isEmpty, isNil } from "lodash";
import { SparklineContext } from "./Sparkline";

class SparklineBars extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getValueMap = memoize((data) => {
      const map = new Map();
      for (let s = 0; s < data.length; s++) {
        const ds = data[s];
        for (let i = 0; i < ds.length; i++) {
          const d = ds[i];
          const val = !isNil(d.x) ? d.x : i;
          let valueGroup;
          if (map.has(val)) {
            valueGroup = map.get(val);
          } else {
            valueGroup = [];
            map.set(val, valueGroup);
          }
          valueGroup[s] = !isNil(d.y) ? d.y : d;
        }
      }
      return map;
    });
  }

  renderBallLineBars(values, x1, groupKey) {
    const { scaleY, height, colors } = this.props;
    return values.map((value, index) => {
      const bandWidth = x1.bandwidth();
      const y = scaleY[0](value);
      const barColor = colors[index % colors.length];
      const gradientId = `bar_${groupKey}_${index}`;
      const circleStyle = {
        fill: barColor,
      };
      const rectStyle = {
        fill: `url(#${gradientId})`,
      };

      const barWidth = 7;
      const barHeight = height - y;
      return (
        <g key={`bar_${index}`} transform={`translate(${x1(index)})`}>
          <defs>
            <linearGradient
              gradientUnits={"userSpaceOnUse"}
              id={gradientId}
              x1={"0%"}
              y1={"100%"}
              x2={"0%"}
              y2={"0%"}
            >
              <stop offset={"5%"} stopColor={color("--mid-grey")} />
              <stop offset={"90%"} stopColor={barColor} />
            </linearGradient>
          </defs>
          {barHeight > 0 && (
            <rect
              x={bandWidth / 2 - barWidth / 2}
              y={scaleY[0](value)}
              width={barWidth}
              height={barHeight}
              style={rectStyle}
            />
          )}
          <circle cx={bandWidth / 2} cy={y} r={5} style={circleStyle} />
        </g>
      );
    });
  }

  getColor(index, value, colors, intervalColors) {
    if (intervalColors) {
      const interval =
        intervalColors.find((x) => x.from <= value && x.to > value) ||
        intervalColors[0];
      return interval.color;
    } else {
      return colors[index % colors.length];
    }
  }

  renderBoxBars(values, x1, groupKey) {
    const { scaleY, height, colors, intervalColors, yScaleCoeff } = this.props;
    return values.map((value, index) => {
      const bandWidth = x1.bandwidth();
      // We calculate the value for the second bar group in order to have ratio same as the second Y Axis.
      let xOffset = 0;
      if (yScaleCoeff && isFinite(yScaleCoeff)) {
        if (index > 1 && index < 4) {
          value *= yScaleCoeff;
        }
        if (index === 1) {
          xOffset = -10;
        }
        if (index === 2) {
          xOffset = 10;
        }
      }
      const y = scaleY[0](value);
      const barColor = this.getColor(
        index,
        intervalColors?.colorByAxis === "Y" ? value : groupKey,
        colors,
        intervalColors?.colors
      );
      const maxWidth = 30;
      const barWidth = Math.min(bandWidth, maxWidth);
      const barHeight = Math.max(height - y, 0);
      const rectStyle = {
        fill: barColor,
      };
      const shadowStyle = {
        fill: "#000",
        fillOpacity: 0.5,
      };

      return (
        <g key={`bar_${index}`} transform={`translate(${x1(index)})`}>
          <rect
            x={(bandWidth - barWidth) / 2 + xOffset}
            y={scaleY[0](value)}
            width={barWidth}
            height={barHeight}
            style={rectStyle}
          />
          {barHeight > 0 && (
            <rect
              x={xOffset + barWidth - 5 + (bandWidth - barWidth) / 2}
              y={scaleY[0](value)}
              width={5}
              height={barHeight}
              style={shadowStyle}
            />
          )}
        </g>
      );
    });
  }

  renderBars(values, x1, groupKey) {
    const { barStyle, customBarRenderer } = this.props;
    switch (barStyle) {
      case "ball":
        return this.renderBallLineBars(values, x1, groupKey);
      case "box":
        return this.renderBoxBars(values, x1, groupKey);
      case "custom":
        return customBarRenderer(this.props, values, x1, groupKey);
    }
  }

  renderGroupBars() {
    const {
      data,
      width,
      scaleX,
      xScaleType,
      xScalePadding,
      xScalePaddingOuter,
      uniqueXValues,
    } = this.props;
    const valueGroups = this.getValueMap(data);
    const keys = !isEmpty(uniqueXValues)
      ? uniqueXValues
      : Array.from(valueGroups.keys());
    const series = data.map((_, i) => i);

    let x0 =
      xScaleType === "band"
        ? scaleX
        : d3
            .scaleBand()
            .paddingOuter(xScalePaddingOuter || xScalePadding)
            .paddingInner(xScalePadding)
            .domain(keys)
            .rangeRound([0, width]);

    const x1 = d3
      .scaleBand()
      .padding(0.1)
      .domain(series)
      .rangeRound([0, x0.bandwidth()]);

    const bars = [];
    for (let key of keys) {
      bars.push(
        <g key={`group_${key}`} transform={`translate(${x0(key)})`}>
          {this.renderBars(valueGroups.get(key), x1, key)}
        </g>
      );
    }
    return bars;
  }

  render() {
    return <g>{this.renderGroupBars()}</g>;
  }
}

const defaultColors = [
  color("--blue-dark"),
  color("--blue-bright"),
  color("--orange-base"),
  color("--orange-dark"),
  color("--red-bright"),
  color("--red-dark"),
  color("--yellow-base"),
  color("--yellow-dark"),
  color("--grey-600"),
  color("--grey-400"),
];

SparklineBars.defaultProps = {
  colors: defaultColors,
  barStyle: "ball",
};

SparklineBars.propTypes = {
  colors: PropTypes.array,
  intervalColors: PropTypes.shape({
    colorByAxis: PropTypes.oneOf(["X", "Y"]),
    colors: PropTypes.array.isRequired,
  }),
  barStyle: PropTypes.oneOf(["ball", "box", "custom"]),
  customBarRenderer: PropTypes.func,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineBars {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
