import createReducer from "./createReducer";
import { SUBHEADER_HIDDEN, SUBHEADER_VISIBLE } from "../actions/action.types";

export default createReducer(
  { isSubHeaderVisible: false },
  {
    [SUBHEADER_VISIBLE]: () => ({ isSubHeaderVisible: true }),
    [SUBHEADER_HIDDEN]: () => ({ isSubHeaderVisible: false }),
  }
);
