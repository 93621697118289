import t from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { VIEWS } from "../../config";
import styles from "./NavAdmin.css";

const NavItem = ({ view }) => {
  if (!view) return null;
  return (
    <li className={styles.navItem}>
      <NavLink to={view.url}>{view.name}</NavLink>
    </li>
  );
};

export function NavAdmin({ menu }) {
  return (
    <nav className={styles.navAdmin}>
      <ul>
        {menu.map(({ name }) => {
          const view = VIEWS[name];
          if (!view) {
            console.warn(
              "Reference to non-existing view in menu config:",
              name
            );
            return null;
          }
          return <NavItem key={name} view={view} />;
        })}
      </ul>
    </nav>
  );
}

NavAdmin.propTypes = {
  menu: t.array.isRequired,
};
