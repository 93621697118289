import { admin } from "../action.types";

export const fetchVessels = () => ({
  type: admin.cargoConfig.FETCH_VESSELS,
});

export const fetchCargo = (args) => ({
  type: admin.cargoConfig.FETCH_CARGO,
  payload: args,
});

export const init = () => ({
  type: admin.cargoConfig.INIT,
});

export const updateCargo = (data) => ({
  type: admin.cargoConfig.UPDATE_CARGO,
  payload: data,
});

export const saveCargo = () => ({
  type: admin.cargoConfig.SAVE_CARGO,
});

export const saveCargoError = (error) => ({
  type: admin.cargoConfig.SAVE_CARGO_ERROR,
  error,
});

export const reset = (payload) => ({ type: admin.cargoConfig.RESET, payload });

export const fetchVesselSuccess = (data) => ({
  type: admin.cargoConfig.FETCH_VESSELS_SUCCESS,
  data,
});

export const fetchVesselError = (error) => ({
  type: admin.cargoConfig.FETCH_VESSELS_ERROR,
  error,
});

export const fetchCargoSuccess = (data) => ({
  type: admin.cargoConfig.FETCH_CARGO_SUCCESS,
  data,
});

export const fetchCargoError = (error) => ({
  type: admin.cargoConfig.FETCH_CARGO_ERROR,
  error,
});
