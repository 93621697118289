import { get, isEqual, isNil, sortBy } from "lodash";
import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import TrendCategoryPane from "../../components/TrendCategoryPane/TrendCategoryPane";
import TrendGraphView from "../../components/TrendGraphView/TrendGraphView";
import {
  fetchTrendData,
  fetchTrendVessel,
} from "../../actions/action.fetchTrendData";
import {
  convertFiltersToQueryParams,
  getDateFilter,
  getGroupFilter,
  getLimitsForVesselInQuery,
  getPerformanceIndicatorsFilter,
  getShowOperationsFilter,
  getStateFilter,
  getVesselFilter,
  getVesselId,
  getVesselItemsFilter,
} from "../../selectors/filters";
import styles from "./Trend.css";
import { color } from "../../../common/colors";
import {
  getMetricsForVesselItem,
  getPageContext,
  getPerformanceIndicatorsForVessel,
  getUnitSymbol,
  getVesselAllItems,
  getVesselItemById,
} from "../../selectors/common";
import { ITEM_TYPE_CATEGORIES, VIEWS } from "../../config";
import { StateFilter } from "../../components/StateFilter/StateFilter";
import VesselSelector from "../../components/VesselSelector/VesselSelector";

class Trend extends React.PureComponent {
  constructor(props) {
    super(props);
    this.trendContainerRef = React.createRef();
    this.state = {
      isDeselectAllActivated: false,
    };
  }

  componentDidMount() {
    const { filters } = this.props;
    const { vesselItemsFilter } = filters;

    if (!filters.isValid) {
      this.adjustFilters();
    } else if (
      vesselItemsFilter.hasVesselItems &&
      !vesselItemsFilter.hasSelectedMetrics
    ) {
      this.props.queryContext.navigate(
        VIEWS.trend.url,
        convertFiltersToQueryParams({
          ...filters,
          vesselItemsFilter: {
            ...vesselItemsFilter,
            value: vesselItemsFilter.defaultValue,
          },
        }),
        true
      );
    } else {
      this.updateData();
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (!filters.isValid) {
      this.adjustFilters();
    } else if (!isEqual(filters, prevProps.filters)) {
      this.updateData();
    }
  }

  adjustFilters() {
    this.props.queryContext.navigate(
      VIEWS.trend.url,
      convertFiltersToQueryParams(this.props.filters),
      true
    );
  }

  updateData() {
    const { vesselFilter } = this.props.filters;
    this.props.fetchTrendData(this.props);
    this.props.fetchTrendVessel(vesselFilter.value);
  }

  onToggleVesselItem = (vesselItemId) => {
    const currentItems = get(this.props, "filters.vesselItemsFilter.value", []);
    if (!currentItems.some((i) => i.id === vesselItemId)) {
      currentItems.push({ id: vesselItemId });
    }
    const updated = currentItems
      .map((vi) => {
        if (vi.id !== vesselItemId) {
          return vi;
        }
        const selected = !vi.selected;
        return {
          ...vi,
          selected: selected || null,
        };
      })
      .filter((vi) => vi.selected);

    const currentItem = updated.find((i) => i.id === vesselItemId);

    if (currentItem) {
      const metrics = get(
        this.props.vesselItems.find((x) => x.id === vesselItemId),
        "metrics",
        []
      );
      currentItem.metricIds = !currentItem.metricIds
        ? metrics.map((x) => x.id)
        : [];
    }

    if (currentItem && !currentItem.color) {
      currentItem.color = getColorForSelectedItem(vesselItemId, updated);
    }
    this.props.queryContext.setItems(updated);
  };

  onToggleMetric = (vesselItemId, metricId) => {
    const currentItems = get(this.props, "filters.vesselItemsFilter.value", []);
    if (!currentItems.some((i) => i.id === vesselItemId)) {
      currentItems.push({ id: vesselItemId });
    }
    const updated = currentItems
      .map((vi) => {
        if (vi.id !== vesselItemId) {
          return vi;
        }
        const metricIds = new Set(vi.metricIds);
        if (metricIds.has(metricId)) {
          metricIds.delete(metricId);
        } else {
          metricIds.add(metricId);
        }
        return {
          ...vi,
          metricIds: Array.from(metricIds),
          selected: !!metricIds.size,
        };
      })
      .filter((vi) => vi.selected);

    const currentItem = updated.find((i) => i.id === vesselItemId);

    if (currentItem && !currentItem.color) {
      currentItem.color = getColorForSelectedItem(vesselItemId, updated);
    }

    this.props.queryContext.setItems(updated);
  };

  onTogglePerformanceIndicators = () => {
    this.props.queryContext.setKpisDisabled(
      !this.props.filters.performanceIndicatorsFilter.disabled
    );
  };

  onTogglePerformanceIndicator = (performanceIndicatorId) => {
    this.props.queryContext.toggleKpi(performanceIndicatorId);
  };

  onToggleOperations = () => {
    this.props.queryContext.setShowOperations(!this.props.showOperations);
  };

  onClearVesselItems = () => {
    const { filters } = this.props;
    this.enableDeselectAllButton();
    this.props.queryContext.navigate(VIEWS.trend.url, {
      ...convertFiltersToQueryParams(filters),
      items: [],
      kpis: [],
    });
  };

  disableDeselectAllButton = () => {
    this.setState({
      isDeselectAllActivated: false,
    });
  };

  enableDeselectAllButton = () => {
    this.setState({
      isDeselectAllActivated: true,
    });
  };

  render() {
    const {
      isValid,
      vesselId,
      date,
      limits,
      trend,
      vesselItems,
      performanceIndicators,
      showOperations,
      vessel,
      queryContext,
      showStateFilter,
      vesselItemsByCategories,
    } = this.props;

    if (!isValid) {
      return <div />;
    }

    const title = vessel ? `Trend - ${vessel.name}` : "Trend";

    return (
      <div className="trendOuterContainer">
        <Header title={title} contentDistribution="space-between">
          <VesselSelector />
          <div className={styles.rightHeaderContainer}>
            {showStateFilter && <StateFilter />}{" "}
            <DatePickerContainer
              current={date}
              vesselId={vesselId}
              limits={limits}
              onChange={queryContext.setDate}
              allowNoCurrent
            />
          </div>
        </Header>
        <div className={styles.trendContainer} ref={this.trendContainerRef}>
          <TrendCategoryPane
            vesselItems={vesselItemsByCategories}
            performanceIndicators={performanceIndicators}
            showOperations={showOperations}
            onToggleVesselItem={this.onToggleVesselItem}
            onToggleMetric={this.onToggleMetric}
            onTogglePerformanceIndicators={this.onTogglePerformanceIndicators}
            onTogglePerformanceIndicator={this.onTogglePerformanceIndicator}
            onToggleOperations={this.onToggleOperations}
            onClearVesselItems={this.onClearVesselItems}
            vesselId={vesselId}
            isDeselectAllActivated={this.state.isDeselectAllActivated}
            disableDeselectAllButton={this.disableDeselectAllButton}
            disabled={!queryContext.items && !queryContext.kpis}
          />
          <TrendGraphView
            vesselItems={vesselItems}
            vesselItemsData={trend.vesselItemsData}
            performanceIndicators={performanceIndicators}
            performanceIndicatorsData={trend.performanceIndicatorsData}
            operationsData={trend.operationsData}
            showOperations={showOperations}
            dateRange={date.range}
            trendContainer={this.trendContainerRef.current}
            timeOffset={parseInt(date.timeOffset)}
          />
        </div>
      </div>
    );
  }
}

const getFilters = createSelector(
  [
    getVesselFilter,
    getGroupFilter,
    getDateFilter,
    getVesselItemsFilter,
    getPerformanceIndicatorsFilter,
    getShowOperationsFilter,
    getStateFilter,
  ],
  (
    vesselFilter,
    groupFilter,
    dateFilter,
    vesselItemsFilter,
    performanceIndicatorsFilter,
    showOperationsFilter,
    stateFilter
  ) => {
    return {
      isValid:
        vesselFilter.isValid &&
        groupFilter.isValid &&
        dateFilter.isValid &&
        vesselItemsFilter.isValid &&
        performanceIndicatorsFilter.isValid &&
        stateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
      vesselItemsFilter,
      performanceIndicatorsFilter,
      showOperationsFilter,
      stateFilter,
    };
  }
);

const colors = [
  color("--blue-base"),
  color("--orange-base"),
  color("--yellow-base"),
  color("--grey-600"),
  color("--blue-dark"),
  color("--orange-dark"),
  color("--yellow-dark"),
  color("--grey-400"),
  color("--red-base"),
  color("--normal-green"),
  color("--white"),
  color("--red-dark"),
  color("--blue-bright"),
  color("--orange-brightest"),
  color("--bright-red"),
  color("--bright-green"),
];

export const getTrendVesselItems = createSelector(
  [getVesselItemsFilter, getPageContext, getVesselId],
  (vesselItemsFilter, pageContext, vesselId) => {
    if (!vesselItemsFilter.isValid) {
      return [];
    }
    const selectedItems = get(vesselItemsFilter, "value", []);
    const vesselAllItems = getVesselAllItems(vesselId, pageContext);
    const vesselItems = vesselAllItems.map(
      (vi) => selectedItems.find((si) => si.id === vi.id) || vi
    );
    return vesselItems.map((vi, index) => {
      const vesselItem = getVesselItemById(vi.id, pageContext);
      const selectedMetrics = new Set(vi.metricIds);
      const metrics = getMetricsForVesselItem(
        vesselItem.id,
        pageContext
      ).filter((x) => x.showTrend === true);

      return {
        id: vesselItem.id,
        viewPosition: vesselItem.viewPosition,
        itemTypeCategoryId: vesselItem.itemTypeCategoryId,
        parentVesselItemId: vesselItem.parentVesselItemId,
        name: get(vesselItem, "name"),
        color: vi.color || colors[index % colors.length],
        isActive: vi.selected,
        metrics: sortBy(
          metrics.map((metric) => ({
            ...metric,
            unit: getUnitSymbol(metric.unitId, pageContext),
            isActive: selectedMetrics.has(metric.id),
          })),
          "name"
        ),
      };
    });
  }
);

export const getTrendPerformanceIndicators = createSelector(
  [getPerformanceIndicatorsFilter, getVesselId, getPageContext],
  (performanceIndicatorsFilter, vesselId, pageContext) => {
    if (!performanceIndicatorsFilter.isValid) {
      return {
        entries: [],
      };
    }

    const activePerformanceIndicators = new Set(
      performanceIndicatorsFilter.value
    );

    const performanceIndicators = getPerformanceIndicatorsForVessel(
      vesselId,
      pageContext
    ).map((pi) => ({
      id: pi.id,
      name: pi.label,
      unit: getUnitSymbol(pi.unitId, pageContext),
      isActive: activePerformanceIndicators.has(pi.id),
    }));

    return {
      entries: performanceIndicators,
      isActive: !performanceIndicatorsFilter.disabled,
      isVisible: performanceIndicators.length > 0,
    };
  }
);

const groupItemsByCategory = (vesselItems = [], itemTypeCategories = []) => {
  for (const vesselItem of vesselItems) {
    vesselItem.items = sortBy(
      vesselItems.filter((vi) => vi.parentVesselItemId === vesselItem.id),
      ["viewPosition"]
    );
  }
  const itemTypes = sortBy(
    vesselItems.filter((vi) => !vi.parentVesselItemId),
    ["viewPosition", "name"]
  );

  return itemTypeCategories
    .map((itc) => ({
      title: itc.name,
      itemTypes: itemTypes.filter(
        (it) =>
          it.itemTypeCategoryId === itc.id &&
          it.itemTypeCategoryId.toUpperCase() !==
            ITEM_TYPE_CATEGORIES.Energy.toUpperCase()
      ),
    }))
    .filter((itc) => itc.itemTypes.length);
};

export const getColorForSelectedItem = (id, selectedItems) => {
  const items = selectedItems.filter((i) => i.id !== id);
  const takenColors = new Set();
  for (const item of items) {
    takenColors.add(item.color);
    if (takenColors.size === colors.length) {
      takenColors.clear();
    }
  }
  const freeColors = colors.filter((c) => !takenColors.has(c));

  return freeColors[0];
};

const mapStateToProps = (state, props) => {
  const filters = getFilters(state, props);
  const vesselItems = getTrendVesselItems(state, props);
  const vesselId = filters.vesselFilter.value;
  const itemTypeCategories = sortBy(
    get(state, "pageContext.itemTypeCategories", []),
    ["viewPosition", "name"]
  );
  const vesselItemsByCategories = groupItemsByCategory(
    vesselItems,
    itemTypeCategories
  );

  return {
    queryContext: props.queryContext,
    vessel: state.trend.vessel,
    vesselItems,
    vesselItemsByCategories,
    performanceIndicators: getTrendPerformanceIndicators(state, props),
    limits: getLimitsForVesselInQuery(state, props),
    trend: state.trend,
    vesselId,
    groupId: filters.groupFilter.value,
    date: filters.dateFilter.value,
    isValid: filters.isValid,
    showOperations: filters.showOperationsFilter.value,
    states: filters.stateFilter.value,
    filters,
    showStateFilter: !isNil(
      state.pageContext?.vessels[vesselId]?.draftLadenDetectThreshold
    ),
  };
};

const mapDispatchToProps = {
  fetchTrendData,
  fetchTrendVessel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trend);
