import React from "react";

export const LeftArrow = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5813_10900)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 18.75L19.5 19.5L18.75 19.5L18.75 18.75L19.5 18.75ZM13.0556 14.1092L11.6944 15.4442L4.94435 8.69421L11.6944 1.94421L13.0556 3.30171L7.9031 8.69421L13.0556 14.1092Z"
        fill="#8391A5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5813_10900">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 18 18)"
        />
      </clipPath>
    </defs>
  </svg>
);
