import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAppConfigFromDocument } from "../../../config/onshore/environments/AppConfig";
import { AuthState } from "../reducers/reducer.auth";

const getBackendUrl = () => {
  try {
    const { BACKEND_URL } = getAppConfigFromDocument();
    return BACKEND_URL;
  } catch (e) {
    // We need to be able to run this without failure when running storybook,
    // where it is not possible to inject appConfig in the document.
    // Therefore, we log a warning and return an empty string, even though during normal operation we should throw instead.
    console.warn("Could not load appConfig from document", e);
    return "";
  }
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: getBackendUrl(),
    prepareHeaders: (headers, { getState }) => {
      // It would be safer to cast to RooState, but this creates a circular reference which I do not know how to fix:
      // baseApi --> rootState --> index --> baseApi
      const accessToken = (getState() as { auth: AuthState }).auth.accessToken;
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    paramsSerializer: (params) => {
      const urlSearchParams = new URLSearchParams();
      Object.entries(params).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          v.forEach((arrayValue) => urlSearchParams.append(k, arrayValue));
        } else {
          urlSearchParams.append(k, v);
        }
      });

      return urlSearchParams.toString();
    },
  }),
  endpoints: () => ({}),
});
