import { FETCH_CALENDAR_HEALTH_EVENTS } from "./action.types";
import { CALENDAR } from "../api";
import fetch from "./action.fetch";

export const fetchCalendarHealthEvents = (vesselId, dateRange, timeOffset) =>
  fetch({
    url: CALENDAR.fetchCalendarHealthEvents(vesselId, dateRange, timeOffset),
    actionType: FETCH_CALENDAR_HEALTH_EVENTS,
    actionData: { timeOffset },
  });
