import {
  admin,
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_ERROR,
  FETCH_ADMIN_USER_SUCCESS,
  FETCH_ADMIN_USER_ERROR,
  CREATE_ADMIN_USER,
  CLOSE_ADMIN_USER_POPUP,
  SAVE_ADMIN_USER,
  SAVE_ADMIN_USER_SUCCESS,
  SAVE_ADMIN_USER_ERROR,
  SET_ADMIN_USER_VALUE,
  EDIT_USER_ACCESS,
  CLOSE_EDIT_USER_ACCESS_POPUP,
  SET_USER_ACCESS_SELECTED_GROUPS,
  SET_USER_ACCESS_SELECTED_COMPANY,
  SAVE_USER_GROUPS_IN_COMPANY,
  SAVE_USER_GROUPS_IN_COMPANY_SUCCESS,
  SAVE_USER_GROUPS_IN_COMPANY_ERROR,
  REQUEST_USER_PASSWORD_RESET,
  CONFIRM_USER_PASSWORD_RESET,
  SEND_USER_PASSWORD_RESET,
  SEND_USER_PASSWORD_RESET_SUCCESS,
  SEND_USER_PASSWORD_RESET_ERROR,
  CANCEL_USER_PASSWORD_RESET,
  SORT_ADMIN_USERS,
  FETCH_USER_GROUPS_SUCCESS,
} from "../../actions/action.types";
import { orderBy, get, isString } from "lodash";
import { checkFormValidation } from "../../common/forms";
import {
  HEALTH_MANAGEMENT_EVENT_TYPE,
  KONGSBERG_API_ID,
  KONGSBERG_DIGITAL_ID,
  KONGSBERG_MARITIME_ID,
} from "../../config";
import createReducer from "../createReducer";
import {
  CompanyAdmin,
  EhmClient,
  Em2ApiClient,
  HmProductExpert,
  SystemAdmin,
  VesselClient,
} from "../../common/roles";
import { now } from "../../common/dates";

const getUsersExampleData = () => {
  return [
    [
      { value: "Roy" },
      { value: "Trenneman" },
      { value: "roy.trenneman@kdi.kongsberg.com" },
    ],
    [
      { value: "Maurice" },
      { value: "Moss" },
      { value: "maurice.moss@kdi.kongsberg.com" },
    ],
    [
      { value: "Jen" },
      { value: "Barber" },
      { value: "jen.barber@kdi.kongsberg.com" },
    ],
  ];
};

const initialState = {
  data: [],
  sortBy: "firstName",
  sortDirection: "asc",
  isLoading: false,
  hasError: false,
  accessEditor: {
    isVisible: false,
  },
  editor: {
    isVisible: false,
    form: {
      fields: {},
      isSaving: false,
      isValid: false,
      hasChanges: false,
      isUpdate: false,
    },
  },
  companies: [],
  groups: [],
  passwordReset: { showDialog: false },
  deleteConfirmPopup: { isVisible: false },
  userImport: {
    showDialog: false,
    isSaving: false,
    data: [],
    exampleData: getUsersExampleData(),
  },
  apiSecretPopup: {
    isVisible: false,
  },
};

function sortUsers({ sortBy, sortDirection }, users) {
  return orderBy(
    users,
    (x) => (isString(x[sortBy]) ? x[sortBy].toLowerCase() : x[sortBy] || ""),
    [sortDirection]
  );
}

function getSelectedGroupIds(userGroups, selectedCompanyId) {
  return userGroups
    .filter((x) => x.companyId === selectedCompanyId)
    .map((x) => x.vesselGroupId);
}

export default createReducer(initialState, {
  [FETCH_ADMIN_USERS]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_ADMIN_USERS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      data: sortUsers(state, data),
    };
  },
  [FETCH_ADMIN_USERS_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [FETCH_ADMIN_USER_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      editor: {
        user: data,
        isVisible: true,
        form: {
          ...state.form,
          fields: {
            firstName: { value: data.firstName },
            lastName: { value: data.lastName },
            companyId: { value: data.companyId },
            roles: { value: data.roles },
            healthManagementAccessEventType: {
              value: get(data, "healthManagementAccess"),
            },
            isApiClientUserOnly: {
              value: data.isApiClientUserOnly,
            },
            clientId: { value: data.clientId },
          },
          isValid: true,
          hasChanges: false,
          isUpdate: true,
          isSaving: false,
        },
      },
    };
  },
  [FETCH_ADMIN_USER_ERROR]: (state, { error }) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        form: {
          ...state.editor.form,
          fields: {},
        },
      },
      hasError: true,
      error,
    };
  },
  [CREATE_ADMIN_USER]: (state, { selectedCompanyId }) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        user: null,
        isVisible: true,
        form: {
          fields: {
            firstName: { isValid: false },
            lastName: { isValid: false },
            email: { isValid: false },
            companyId: { isValid: true, value: selectedCompanyId },
            roles: { value: [] },
            healthManagementAccessEventType: { value: [] },
            isApiClientUserOnly: { value: false },
          },
          isSaving: false,
          isValid: false,
          hasChanges: false,
          isUpdate: false,
          companiesFetchedAt: now(),
        },
      },
    };
  },
  [CLOSE_ADMIN_USER_POPUP]: (state) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        isVisible: false,
        form: {
          ...state.editor.form,
          fields: {},
        },
      },
    };
  },
  [SAVE_ADMIN_USER]: (state) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        form: {
          ...state.editor.form,
          isSaving: true,
        },
      },
    };
  },
  [SAVE_ADMIN_USER_SUCCESS]: (state) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        isVisible: false,
        form: {
          ...state.editor.form,
          isSaving: false,
        },
      },
    };
  },
  [SAVE_ADMIN_USER_ERROR]: (state, { error }) => {
    return {
      ...state,
      editor: {
        ...state.editor,
        form: {
          ...state.editor.form,
          isSaving: false,
        },
      },
      hasError: true,
      error,
    };
  },
  [SET_ADMIN_USER_VALUE]: (state, { name, value, valid, message }) => {
    const field = state.editor.form[name];

    let fields = {
      ...state.editor.form.fields,
      [name]: {
        ...field,
        value: value,
        isValid: valid !== undefined ? valid : true,
        message: message || "",
        hasChanges: true,
      },
    };

    if (
      name === "companyId" &&
      value !== KONGSBERG_MARITIME_ID &&
      value !== KONGSBERG_DIGITAL_ID
    ) {
      fields = {
        ...fields,
        roles: {
          ...fields.roles,
          value: fields.roles.value.filter((role) => role !== SystemAdmin),
        },
      };
    }

    return {
      ...state,
      editor: {
        ...state.editor,
        form: checkFormValidation({
          ...state.editor.form,
          fields,
        }),
      },
    };
  },
  [EDIT_USER_ACCESS]: (state, { user }) => {
    return {
      ...state,
      accessEditor: {
        isVisible: true,
        userId: user.id,
        userGroups: [],
        selectedGroupIds: [],
        selectedCompanyId: user.companyId,
      },
    };
  },
  [FETCH_USER_GROUPS_SUCCESS]: (state, { data }) => ({
    ...state,
    accessEditor: {
      ...state.accessEditor,
      userGroups: data,
      selectedGroupIds: getSelectedGroupIds(
        data,
        state.accessEditor.selectedCompanyId
      ),
    },
  }),
  [SET_USER_ACCESS_SELECTED_COMPANY]: (state, { selectedCompanyId }) => {
    return {
      ...state,
      accessEditor: {
        ...state.accessEditor,
        selectedCompanyId,
        selectedGroupIds: getSelectedGroupIds(
          state.accessEditor.userGroups,
          selectedCompanyId
        ),
        hasChanges: false,
      },
    };
  },
  [SET_USER_ACCESS_SELECTED_GROUPS]: (state, { selectedGroupIds }) => {
    return {
      ...state,
      accessEditor: {
        ...state.accessEditor,
        selectedGroupIds,
        hasChanges: true,
      },
    };
  },
  [SAVE_USER_GROUPS_IN_COMPANY]: (state) => {
    return {
      ...state,
      accessEditor: {
        ...state.accessEditor,
        isSaving: true,
      },
    };
  },
  [SAVE_USER_GROUPS_IN_COMPANY_SUCCESS]: (state) => {
    return {
      ...state,
      accessEditor: {
        isVisible: false,
      },
    };
  },
  [SAVE_USER_GROUPS_IN_COMPANY_ERROR]: (state, { error }) => {
    return {
      ...state,
      accessEditor: {
        isVisible: false,
      },
      hasError: true,
      error,
    };
  },
  [CLOSE_EDIT_USER_ACCESS_POPUP]: (state) => {
    return {
      ...state,
      accessEditor: {
        isVisible: false,
      },
    };
  },
  [REQUEST_USER_PASSWORD_RESET]: (state, { user }) => {
    return {
      ...state,
      passwordReset: {
        showDialog: true,
        user: user,
      },
    };
  },
  [CONFIRM_USER_PASSWORD_RESET]: (state) => {
    return {
      ...state,
      passwordReset: {
        ...state.passwordReset,
        confirmed: true,
      },
    };
  },
  [SEND_USER_PASSWORD_RESET]: (state) => {
    return {
      ...state,
      passwordReset: {
        ...state.passwordReset,
        sending: true,
      },
    };
  },
  [SEND_USER_PASSWORD_RESET_SUCCESS]: (state) => {
    return {
      ...state,
      passwordReset: {
        ...state.passwordReset,
        sending: false,
        completed: true,
        success: true,
      },
    };
  },
  [SEND_USER_PASSWORD_RESET_ERROR]: (state) => {
    return {
      ...state,
      passwordReset: {
        ...state.passwordReset,
        sending: false,
        completed: true,
        success: false,
      },
    };
  },
  [CANCEL_USER_PASSWORD_RESET]: (state) => {
    return {
      ...state,
      passwordReset: {
        passwordReset: { showDialog: false },
      },
    };
  },
  [SORT_ADMIN_USERS]: (state, { sortBy, sortDirection }) => ({
    ...state,
    sortBy,
    sortDirection,
    data: sortUsers({ sortBy, sortDirection }, state.data),
  }),
  [admin.users.import.SET_SELECTED_COMPANY]: (state, { selectedCompanyId }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        selectedCompanyId,
        selectedGroupId: null,
      },
    };
  },
  [admin.users.import.SET_SELECTED_GROUP]: (state, { selectedGroupId }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        selectedGroupId,
      },
    };
  },
  [admin.users.import.SET_FILE]: (state) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        isParsing: true,
      },
    };
  },
  [admin.users.import.SET_FILE_SUCCESS]: (state, { rows }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        data: rows,
        isParsing: false,
      },
    };
  },
  [admin.users.import.SET_FILE_ERROR]: (state, { error }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        data: [],
        error,
      },
    };
  },
  [admin.users.import.SAVE]: (state) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        isSaving: true,
      },
    };
  },
  [admin.users.import.SAVE_SUCCESS]: (state, { importData }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        data: importData,
        isSaving: false,
        isComplete: true,
      },
    };
  },
  [admin.users.import.SAVE_ERROR]: (state, { error }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        data: [],
        isSaving: false,
        error,
      },
    };
  },
  [admin.users.import.OPEN]: (state, { selectedCompanyId }) => {
    return {
      ...state,
      userImport: {
        ...state.userImport,
        showDialog: true,
        selectedGroupId: null,
        selectedCompanyId: selectedCompanyId,
        data: [],
      },
    };
  },
  [admin.users.import.CANCEL]: (state) => {
    return {
      ...state,
      userImport: initialState.userImport,
    };
  },
  [admin.users.DELETE_POPUP_OPEN]: (state, { user }) => ({
    ...state,
    editor: {
      ...state.editor,
      isVisible: false,
    },
    deleteConfirmPopup: {
      isVisible: true,
      user: user,
    },
  }),
  [admin.users.DELETE_POPUP_CLOSE]: (state) => ({
    ...state,
    editor: {
      ...state.editor,
      isVisible: true,
    },
    deleteConfirmPopup: {
      isVisible: false,
    },
  }),
  [admin.users.DELETE]: (state) => ({
    ...state,
    editor: {
      ...state.editor,
      form: {
        ...state.editor.form,
        isSaving: true,
      },
    },
    deleteConfirmPopup: {
      isVisible: false,
    },
  }),
  [admin.users.DELETE_SUCCESS]: (state) => ({
    ...state,
    editor: {
      ...state.editor,
      isVisible: false,
      form: {
        ...state.editor.form,
        isSaving: false,
      },
    },
  }),
  [admin.users.DELETE_ERROR]: (state, { error }) => ({
    ...state,
    editor: {
      ...state.editor,
      form: {
        ...state.editor.form,
        isSaving: false,
      },
    },
    hasError: true,
    error,
  }),
  [admin.users.DEACTIVATE_SUCCESS]: (state, { user }) => ({
    ...state,
    data: state.data.map((x) =>
      x.id === user.id ? { ...x, deactivated: true } : x
    ),
    editor: { ...state.editor, isVisible: false },
  }),
  [admin.users.DEACTIVATE_ERROR]: (state, { error }) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    hasError: true,
    error,
  }),
  [admin.users.REACTIVATE_SUCCESS]: (state, { user }) => ({
    ...state,
    data: state.data.map((x) =>
      x.id === user.id ? { ...x, deactivated: false } : x
    ),
    editor: { ...state.editor, isVisible: false },
  }),
  [admin.users.REACTIVATE_ERROR]: (state, { error }) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    hasError: true,
    error,
  }),
  [admin.users.REQUEST_REGENERATE_API_SECRET]: (state) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    apiSecretPopup: {
      action: admin.users.GENERATE_API_SECRET,
      isVisible: true,
      isLoading: true,
    },
  }),
  [admin.users.GENERATE_API_SECRET]: (state) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    apiSecretPopup: {
      action: admin.users.GENERATE_API_SECRET,
      isVisible: true,
      isLoading: true,
    },
  }),
  [admin.users.GENERATE_API_SECRET_SUCCESS]: (state, { data }) => ({
    ...state,
    apiSecretPopup: {
      ...state.apiSecretPopup,
      isLoading: false,
      secret: data.token,
    },
  }),
  [admin.users.GENERATE_API_SECRET_ERROR]: (state, { error }) => ({
    ...state,
    apiSecretPopup: {
      ...state.apiSecretPopup,
      isLoading: false,
      hasError: true,
      error,
    },
  }),
  [admin.users.REQUEST_REGENERATE_API_SECRET]: (state, { user }) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    apiSecretPopup: {
      action: admin.users.REQUEST_REGENERATE_API_SECRET,
      isVisible: true,
      isLoading: false,
      user,
    },
  }),
  [admin.users.REQUEST_CLEAR_API_SECRET]: (state, { user }) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    apiSecretPopup: {
      action: admin.users.REQUEST_CLEAR_API_SECRET,
      isVisible: true,
      isLoading: false,
      user,
    },
  }),
  [admin.users.CLEAR_API_SECRET]: (state) => ({
    ...state,
    editor: { ...state.editor, isVisible: false },
    apiSecretPopup: {
      action: admin.users.CLEAR_API_SECRET,
      isVisible: true,
      isLoading: true,
    },
  }),
  [admin.users.CLEAR_API_SECRET_SUCCESS]: (state) => ({
    ...state,
    apiSecretPopup: {
      ...state.apiSecretPopup,
      isLoading: false,
    },
  }),
  [admin.users.CLEAR_API_SECRET_ERROR]: (state, { error }) => ({
    ...state,
    apiSecretPopup: {
      ...state.apiSecretPopup,
      isLoading: false,
      hasError: true,
      error,
    },
  }),
  [admin.users.CLOSE_USER_API_SECRET_POPUP]: (state) => ({
    ...state,
    apiSecretPopup: initialState.apiSecretPopup,
  }),
  [admin.users.CLEAR_USER_ERRORS]: (state) => ({
    ...state,
    error: null,
    hasError: false,
  }),
  [admin.users.DOWNLOAD_USERS_EXAMPLE_ERROR]: (state, { error }) => ({
    ...state,
    userImport: {
      ...state.userImport,
      error,
    },
  }),
});

export const getRoleOptions = (form) => {
  const companyId = get(form, ["fields", "companyId", "value"]) || "";
  if (
    companyId.toLocaleLowerCase() === KONGSBERG_MARITIME_ID ||
    companyId.toLocaleLowerCase() === KONGSBERG_DIGITAL_ID
  ) {
    return [
      HmProductExpert,
      CompanyAdmin,
      SystemAdmin,
      EhmClient,
      VesselClient,
      Em2ApiClient,
    ];
  } else if (companyId.toLocaleLowerCase() === KONGSBERG_API_ID) {
    return [EhmClient, Em2ApiClient];
  }
  return [HmProductExpert, CompanyAdmin];
};

export const healthManagementEventTypeOptions = [
  HEALTH_MANAGEMENT_EVENT_TYPE.DMEvent,
  HEALTH_MANAGEMENT_EVENT_TYPE.EngineEvent,
  HEALTH_MANAGEMENT_EVENT_TYPE.PropulsorEvent,
];
