import React from "react";
import SelectBox from "../SelectBox";
import PrevNextArrow from "../DatePicker/PrevNextArrow/PrevNextArrow";
import PropTypes from "prop-types";
import styles from "./Pagination.css";

export default class Pagination extends React.Component {
  getPageNumbers() {
    const numberOfPages = this.getNumberOfPages();

    const pageNumbers = [];
    for (let i = 1; i <= numberOfPages; i++) {
      pageNumbers.push(i);
    }
    if (pageNumbers.length < 2) {
      pageNumbers.length = 0;
    }
    return pageNumbers;
  }

  getNumberOfPages() {
    const { totalPosts, postsPerPage } = this.props;
    return Math.ceil(totalPosts / postsPerPage);
  }

  getOptions() {
    const numberOfPages = this.getNumberOfPages();
    const pageNumbers = this.getPageNumbers();
    return pageNumbers.map((pageNum) => ({
      id: pageNum,
      label: `Page ${pageNum} of ${numberOfPages}`,
    }));
  }

  handleSelect(option) {
    const { paginate } = this.props;

    paginate(option.id);
  }

  handleSelectPrev() {
    const { paginate, currentPageNumber } = this.props;

    paginate(currentPageNumber - 1);
  }

  handleSelectNext() {
    const { paginate, currentPageNumber } = this.props;

    paginate(currentPageNumber + 1);
  }

  canSelectPrev() {
    const { currentPageNumber } = this.props;
    return (
      currentPageNumber > 1 && currentPageNumber <= this.getNumberOfPages()
    );
  }

  canSelectNext() {
    const { currentPageNumber } = this.props;
    return currentPageNumber < this.getNumberOfPages();
  }

  render() {
    const { currentPageNumber, drawSpacer, pageOptions, disable } = this.props;
    const options = pageOptions ? pageOptions : this.getOptions();
    const canSelectPrev = this.canSelectPrev();
    const canSelectNext = this.canSelectNext();

    if (currentPageNumber <= 0 || this.getNumberOfPages() === 1) {
      return <div />;
    } else {
      return (
        <div>
          {drawSpacer && <div className={styles.paginationSpacer} />}
          <div className={styles.paginationContent}>
            <PrevNextArrow
              testId="pagination-prev"
              dir="prev"
              visible={!disable && canSelectPrev}
              disabled={disable || canSelectPrev !== true}
              clickAction={
                canSelectPrev === true && !disable
                  ? () => this.handleSelectPrev()
                  : null
              }
            />
            <div className={styles.paginationDropdown}>
              <SelectBox
                options={options}
                optionValKey={"id"}
                optionLabelKey={"label"}
                onSelect={(option) => this.handleSelect(option)}
                selected={options.find((x) => x.id === currentPageNumber)}
                disabled={disable}
              />
            </div>
            <PrevNextArrow
              testId="pagination-next"
              dir="next"
              visible={!disable && canSelectNext}
              disabled={disable || canSelectNext !== true}
              clickAction={
                canSelectNext === true && !disable
                  ? () => this.handleSelectNext()
                  : null
              }
            />
          </div>
        </div>
      );
    }
  }

  static defaultProps = {
    drawSpacer: true,
    disable: false,
  };

  static propTypes = {
    totalPosts: PropTypes.number.isRequired,
    postsPerPage: PropTypes.number.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    paginate: PropTypes.func.isRequired,
    drawSpacer: PropTypes.bool,
    disable: PropTypes.bool,
    pageOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
      })
    ),
  };
}
