import React from "react";
import Popup from "../../../components/Popup/Popup";
import { connect } from "react-redux";
import styles from "./DocumentDeleteDialog.css";
import {
  cancelDeleteFile,
  deleteFile,
} from "../../../actions/action.documents";

class DocumentDeleteDialog extends React.Component {
  constructor(props) {
    super(props);

    this.onYesClick = this.onYesClick.bind(this);
    this.onNoClick = this.onNoClick.bind(this);
  }

  onYesClick() {
    this.props.deleteFile();
  }

  onNoClick() {
    this.props.cancelDeleteFile();
  }

  render() {
    const { documents } = this.props;
    const { deleteFile = {} } = documents || {};
    const { fileId, isDeleting } = deleteFile || {};
    return (
      <Popup
        title={"Delete Document?"}
        visible={!!fileId}
        target={"popupContainer"}
        onHide={this.onNoClick}
        buttons={[
          {
            label: "No, Keep Document",
            action: this.onNoClick,
            disabled: !!isDeleting,
          },
          {
            label: "Yes, Delete Document",
            action: this.onYesClick,
            type: "alert",
            disabled: !!isDeleting,
          },
        ]}
      >
        <div className={styles.container}>
          <p>Deleting a document will permanently remove it</p>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: state.documents,
});

const mapDispatchToProps = {
  deleteFile,
  cancelDeleteFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDeleteDialog);
