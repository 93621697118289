import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { validateFields } from "../../../common/forms";
import { getErrorMessage } from "../../../common/error";

const actionTypes = vesselConfig.hullPerformance;

export const getSpeedPowerExampleData = () => {
  return `Power,Draught,Trim,Speed
100,5.0,-0.8,7.7
400,5.0,-0.8,8.5
600,5.0,-0.8,9.0
1500,5.0,-0.8,11.5
3000,5.0,-0.8,11.8`;
};

export const getDisplacementDraughtExampleData = () => {
  return `Trim,Draught,Displacement
0,5,100000
0,10,150000
2,5,110000
2,10,130000`;
};

export const STATES = {
  READY: "READY",
  LOADING: "LOADING",
  SAVING: "SAVING",
  ERROR: "ERROR",
  DOWNLOADING: "DOWNLOADING",
  SHOW_POPUP: "SHOW_POPUP",
  REQUESTING_CALCULATION: "REQUESTING_CALCULATION",
  FETCHING_CALCULATION_RESULT: "FETCHING_CALCULATION_RESULT",
};

const initialState = {
  operationState: STATES.READY,
  fields: {
    isIsoCompliant: {
      value: false,
    },
    speedPowerTxt: {
      value: undefined,
    },
    displacementDraughtTxt: {
      value: undefined,
    },
  },
  exampleData: {
    speedPower: getSpeedPowerExampleData(),
    displacementDraught: getDisplacementDraughtExampleData(),
  },
  validation: {
    errors: [],
    isValid: true,
  },
};

export default createReducer(initialState, {
  [actionTypes.FETCH]: (state, { vesselId }) => ({
    ...initialState,
    vesselId,
    operationState: STATES.LOADING,
  }),
  [actionTypes.FETCH_SUCCESS]: (
    state,
    { configData, validationData, calculationResultData }
  ) => ({
    ...state,
    fields: {
      ...state.fields,
      isIsoCompliant: {
        value: configData.isIsoCompliant,
      },
      speedPowerTxt: {
        value: configData.speedPowerTxt,
        existsInConfig: Boolean(configData.speedPowerTxt),
      },
      displacementDraughtTxt: {
        value: configData.displacementDraughtTxt,
        existsInConfig: Boolean(configData.displacementDraughtTxt),
      },
    },
    validation: {
      errors: validationData,
      isValid: validationData && validationData.length === 0,
    },
    calculationResult: calculationResultData,
    operationState: STATES.READY,
  }),
  [actionTypes.FETCH_ERROR]: (state, data) => ({
    ...state,
    operationState: STATES.ERROR,
    error: getErrorMessage(data),
  }),
  [actionTypes.INPUT_CHANGED]: (state, { name, value }) => ({
    ...state,
    fields: validateFields({
      ...state.fields,
      [name]: {
        ...state.fields[name],
        value,
        hasChanges: true,
      },
    }),
  }),
  [actionTypes.SAVE]: (state) => ({
    ...state,
    operationState: STATES.SAVING,
  }),
  [actionTypes.SAVE_SUCCESS]: (state, { data }) => ({
    ...state,
    fields: {
      ...state.fields,
      isIsoCompliant: {
        value: data.isIsoCompliant,
      },
      speedPowerTxt: {
        value: data.speedPowerTxt,
      },
      displacementDraughtTxt: {
        value: data.displacementDraughtTxt,
      },
    },
    operationState: STATES.READY,
  }),
  [actionTypes.SAVE_ERROR]: (state, data) => ({
    ...state,
    operationState: STATES.ERROR,
    error: getErrorMessage(data),
  }),
  [actionTypes.DOWNLOAD]: (state) => ({
    ...state,
    operationState: STATES.DOWNLOADING,
  }),
  [actionTypes.DOWNLOAD_SUCCESS]: (state) => ({
    ...state,
    operationState: STATES.READY,
  }),
  [actionTypes.DOWNLOAD_ERROR]: (state, data) => ({
    ...state,
    operationState: STATES.ERROR,
    error: getErrorMessage(data),
  }),
  [actionTypes.SHOW_POPUP]: (state) => ({
    ...state,
    operationState: STATES.SHOW_POPUP,
  }),
  [actionTypes.HIDE_POPUP]: (state) => ({
    ...state,
    operationState: STATES.READY,
  }),
  [actionTypes.REQUEST_CALCULATION]: (state) => ({
    ...state,
    operationState: STATES.REQUESTING_CALCULATION,
  }),
  [actionTypes.REQUEST_CALCULATION_SUCCESS]: (state) => ({
    ...state,
    operationState: STATES.SHOW_POPUP,
  }),
  [actionTypes.REQUEST_CALCULATION_ERROR]: (state, data) => ({
    ...state,
    operationState: STATES.ERROR,
    error: getErrorMessage(data),
  }),
  [actionTypes.FETCH_CALCULAION_RESULT]: (state) => ({
    ...state,
    operationState: STATES.FETCHING_CALCULATION_RESULT,
  }),
  [actionTypes.FETCH_CALCULAION_RESULT_SUCCESS]: (state, { data }) => ({
    ...state,
    operationState: STATES.SHOW_POPUP,
    calculationResult: data,
  }),
  [actionTypes.FETCH_CALCULAION_RESULT_ERROR]: (state, data) => ({
    ...state,
    operationState: STATES.ERROR,
    error: getErrorMessage(data),
  }),
});
