import { getTimeZones } from "../../../common/units";

export default {
  defaultValue: ({ current }, defaultDate) => {
    const currentValue =
      current &&
      (current.currentSelectedUtcValue || current.currentSelectedUtcValue === 0
        ? current.currentSelectedUtcValue
        : parseInt(current.timeOffset));
    if (currentValue || currentValue === 0) {
      return currentValue;
    }
    if (defaultDate) {
      return defaultDate.timeOffset;
    }
    return 0;
  },
  options: (selectedValue) => {
    return createOptions(selectedValue);
  },
  selectOption: (option, _options, { current }, _type, defaultDate) => {
    if (current) {
      current.currentSelectedUtcValue = option.utcOffset;
    } else if (defaultDate) {
      defaultDate.timeOffset = option.utcOffset;
    }
    return createOptions(option.utcOffset);
  },
};

function createOptions(selectedValue) {
  const items = getTimeZones().map((i) => ({
    ...i,
    isSelected: i.utcOffset === selectedValue ? true : false,
  }));

  return {
    selectedValue,
    items,
    title: "UTC-12 - UTC+14",
    hasNext: false,
    hasPrev: false,
  };
}
