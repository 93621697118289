import { FETCH_FLEET_TABLE } from "./action.types";
import { FLEET_TABLE } from "../api";
import { post } from "./action.fetch";
import ContentType from "../../common/contentType";

export const fetchFleetTable = (
  groupId,
  dateRange,
  timeOffset,
  vesselFilter
) => {
  return post({
    url: FLEET_TABLE.get(groupId, dateRange, timeOffset),
    actionType: FETCH_FLEET_TABLE,
    payload: { vesselFilter },
    contentType: ContentType.ApplicationJson,
  });
};
