import React from "react";
import styles from "./VesselCheckbox.css";
import { useDispatch, useSelector } from "react-redux";
import { selectVessel } from "../../../reducer.fleetPerformance";
import { RootState } from "../../../../../store/rootState";

interface Props {
  id: string;
  name: string;
}

export const VesselCheckbox = ({ id, name }: Props) => {
  const isChecked = useSelector((state: RootState) =>
    state.fleetPerformance.selectedVessels.some((x) => x.id === id)
  );
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <label
        className={styles.checkboxLabel}
        onClick={() => dispatch(selectVessel({ id, name }))}
      >
        {isChecked ? "✔" : ""}
      </label>
    </div>
  );
};
