import { fuelAndEmissions } from "./action.types";
import fetch from "./action.fetch";
import { FUEL_EMISSIONS } from "../api";

export const changeFilters = (filters) => ({
  type: fuelAndEmissions.CHANGE_FILTERS,
  payload: filters,
});

export const fetchFuelConsumptions = (filters) => {
  return fetch({
    url: FUEL_EMISSIONS.getFuelConsumption(
      filters.vesselId,
      filters.dateFilter.range,
      filters.dateFilter.timeOffset
    ),
    actionType: fuelAndEmissions.fuelConsumptions.FETCH_FUEL_CONSUMPTIONS,
  });
};

export const changeFuelType = (fuel) => ({
  type: fuelAndEmissions.fuelConsumptions.CHANGE_FUEL,
  fuel,
});

export const fetchEmissionsDonutData = (filters) => {
  return fetch({
    url: FUEL_EMISSIONS.getDonutData(
      filters.vesselId,
      filters.dateFilter.range,
      filters.dateFilter.timeOffset
    ),
    actionType: fuelAndEmissions.donutData.FETCH_DONUT_DATA,
  });
};

export const fetchEmissionsTrend = (filters) => {
  return fetch({
    url: FUEL_EMISSIONS.getEmissionsTrend(filters.vesselId),
    actionType: fuelAndEmissions.emissionsTrend.FETCH_EMISSIONS_TREND,
  });
};

export const changeSelectedEmissionTrend = (emission) => ({
  type: fuelAndEmissions.emissionsTrend.CHANGE_EMISSIONS,
  emission,
});
export const fetchEmissionsByOperationModes = (filters) => {
  return fetch({
    url: FUEL_EMISSIONS.getEmissionsByOperationModes(
      filters.vesselId,
      filters.dateFilter.range,
      filters.dateFilter.timeOffset
    ),
    actionType:
      fuelAndEmissions.emissionsByOperationModes
        .FETCH_EMISSIONS_BY_OPERATION_MODES_DATA,
  });
};

export const fetchFuelAndTarget = (filters) => {
  return fetch({
    url: FUEL_EMISSIONS.getFuelAndTarget(
      filters.vesselId,
      filters.dateFilter.range,
      filters.dateFilter.timeOffset
    ),
    actionType: fuelAndEmissions.fuelAndTarget.FETCH_FUEL_AND_TARGET_DATA,
  });
};

//SET FUEL TARGET
export const fetchFuelTargets = (vesselId) => {
  return fetch({
    url: FUEL_EMISSIONS.getFuelTargets(vesselId),
    actionType: fuelAndEmissions.setFuelTargetDialog.FETCH_FUEL_TARGET_DATA,
  });
};

export const setTargetFuelDataOpen = (vesselId) => ({
  type: fuelAndEmissions.setFuelTargetDialog.OPEN,
  payload: vesselId,
});

export const setTargetFuelDataCancel = () => ({
  type: fuelAndEmissions.setFuelTargetDialog.CANCEL,
});

export const setTargetFuelDataHide = () => ({
  type: fuelAndEmissions.setFuelTargetDialog.HIDE,
});

export const setTargetFuelDataSave = (vesselId, changes) => {
  console.log("setTargetFuelDataSave", vesselId, changes);
  return fetch({
    actionType: fuelAndEmissions.setFuelTargetDialog.SAVE_FUEL_TARGET_DATA,
    method: "post",
    url: FUEL_EMISSIONS.saveFuelTargets(vesselId),
    payload: changes,
  });
};

export const targetFuelDataFieldValueChanged = (value) => ({
  type: fuelAndEmissions.setFuelTargetDialog
    .TARGET_FUEL_DATA_FIELD_VALUE_CHANGED,
  payload: value,
});

export const changeFuelAndTargetFuelType = (fuel) => ({
  type: fuelAndEmissions.fuelAndTarget.CHANGE_FUEL,
  fuel,
});
