import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ColumnSortingOptions, SortByColumn, SortByOperation } from "./types";
import { Direction } from "../../common/direction";
import { OperationId } from "../../../common/operation/operationId";

interface State {
  selectedVessels: SelectedVessel[];
  sortingOrder: Direction;
  sorting: SortByColumn | SortByOperation;
}

export interface SelectedVessel {
  id: string;
  name: string;
}

const initialState: State = {
  selectedVessels: [],
  sortingOrder: Direction.LowToHigh,
  sorting: {
    sorting: ColumnSortingOptions.Name,
    type: "column",
  },
};

const fleetPerformanceSlice = createSlice({
  name: "fleetPerformance",
  initialState: initialState,
  reducers: {
    selectVessel(state, action: PayloadAction<SelectedVessel>) {
      if (state.selectedVessels.some((x) => x.id === action.payload.id)) {
        state.selectedVessels = state.selectedVessels.filter(
          (x) => x.id !== action.payload.id
        );
      } else {
        state.selectedVessels.push(action.payload);
      }
    },
    clearSelectedVessels(state) {
      state.selectedVessels = [];
    },
    sortByVesselName(state, action: PayloadAction<Direction>) {
      state.sorting = { type: "column", sorting: ColumnSortingOptions.Name };
      state.sortingOrder = action.payload;
    },
    sortByConsumption(state, action: PayloadAction<Direction>) {
      state.sorting = {
        type: "column",
        sorting: ColumnSortingOptions.Consumption,
      };
      state.sortingOrder = action.payload;
    },
    sortByTrend(state, action: PayloadAction<Direction>) {
      state.sorting = { type: "column", sorting: ColumnSortingOptions.Trend };
      state.sortingOrder = action.payload;
    },
    sortByOperation(
      state,
      action: PayloadAction<{
        sortingOrder: Direction;
        operationId: OperationId;
      }>
    ) {
      state.sorting = {
        type: "operation",
        sorting: action.payload.operationId,
      };
      state.sortingOrder = action.payload.sortingOrder;
    },
  },
});

export const {
  selectVessel,
  clearSelectedVessels,
  sortByConsumption,
  sortByVesselName,
  sortByOperation,
  sortByTrend,
} = fleetPerformanceSlice.actions;
export default fleetPerformanceSlice.reducer;
