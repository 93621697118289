import React from "react";
import PropTypes from "prop-types";
import styles from "./DataDetailsInformation.css";
import { createPropertyValueComponent } from "../PropertyValues/PropertyValues";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import ReactMarkdown from "react-markdown";

class DataDetailsInformation extends React.Component {
  render() {
    const { title, description, properties, fullWidth } = this.props;
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <div className={styles.container}>
          <div className={styles.propertyContainer}>
            {properties &&
              properties.map((property, index) => (
                <div key={`property_${index}`} className={styles.property}>
                  <span className={styles.name}>{property.name}</span>
                  <span className={styles.value}>
                    {createPropertyValueComponent({
                      showName: false,
                      ...property,
                    })}
                  </span>
                </div>
              ))}
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>
            <ReactMarkdown skipHtml={true}>{description}</ReactMarkdown>
          </div>
        </div>
      </DataDetailsComponentContainer>
    );
  }
}

DataDetailsInformation.defaultProps = {};

DataDetailsInformation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  properties: PropTypes.array,
  fullWidth: PropTypes.bool,
};

export default DataDetailsInformation;
