import React from "react";
import { PropTypes } from "prop-types";
import FormGroup from "../Form/FormGroup/FormGroup";
import { FormSelectBox, FormTextBox } from "../Form";
import { get, noop } from "lodash";
import { color } from "../../../common/colors";
import FormPopup from "../Form/FormPopup/FormPopup";
import FormDatePicker from "../Form/FormDatePicker/FormDatePicker";
import { getTimeOffsetFormat } from "../../reducers/datePicker";

class EventEditFormDialog extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      visible,
      mode,
      isValid,
      isSaving,
      event,
      eventTypes,
      onFormDataChanged,
      onSave,
      onCancel,
      onDelete,
      onConfirmDelete,
      timeOffset,
    } = this.props;

    if (!visible) return <div />;

    return (
      <div>
        <FormPopup
          visible={mode === "edit" || mode === "new"}
          title={mode === "new" ? "Add new event" : "Edit event"}
          onHide={onCancel}
          onCancel={onCancel}
          onSave={onSave}
          enableSave={isValid && !isSaving}
          enableCancel={!isSaving}
          additionalButtons={[
            {
              label: "Delete",
              action: () => onDelete(event),
              visible: mode === "edit",
              borderColor: color("--red-base"),
            },
          ]}
        >
          <div style={{ width: "60rem" }}>
            <FormGroup>
              <FormTextBox
                label={"Title"}
                name={"title"}
                onChange={onFormDataChanged}
                value={get(event, "title.value")}
                required={get(event, "title.required")}
                disabled={isSaving}
              />
              <FormSelectBox
                name={"eventTypeId"}
                label={"Event type"}
                onChange={onFormDataChanged}
                options={eventTypes}
                optionValKey={"name"}
                selected={get(event, "eventTypeId.value")}
                placeholder={"Please select an event type"}
                required={get(event, "eventTypeId.required")}
                disabled={isSaving}
              />
              <FormDatePicker
                name="startTime"
                label={"Start Time " + getTimeOffsetFormat(timeOffset)}
                value={get(event, "startTime.value")}
                onChange={onFormDataChanged}
                required={get(event, "startTime.required")}
                disabled={isSaving}
              />
              <FormDatePicker
                name="endTime"
                label={"End Time " + getTimeOffsetFormat(timeOffset)}
                value={get(event, "endTime.value")}
                onChange={onFormDataChanged}
                disabled={isSaving}
              />
              <FormTextBox
                label={"Description"}
                name={"description"}
                onChange={onFormDataChanged}
                multiLine={true}
                value={get(event, "description.value")}
                disabled={isSaving}
              />
            </FormGroup>
          </div>
        </FormPopup>
        <FormPopup
          visible={mode === "delete"}
          title={"Delete event"}
          enableSave={!isSaving}
          enableCancel={!isSaving}
          onSave={() => onConfirmDelete(event)}
          onCancel={onCancel}
          onHide={onCancel}
          saveButtonText={"Yes"}
          saveButtonBorderColor={color("--red-base")}
          cancelButtonText={"No"}
        >
          {!isSaving && <p>Do you want to delete this event?</p>}
          {isSaving && <p>Deleting event</p>}
        </FormPopup>
      </div>
    );
  }
}

EventEditFormDialog.defaultProps = {
  event: {},
  eventTypes: [],
  onFormDataChanged: noop,
  onSave: noop,
  onCancel: noop,
  onDelete: noop,
  onConfirmDelete: noop,
};

EventEditFormDialog.propTypes = {
  mode: PropTypes.oneOf(["new", "edit", "delete"]),
  timeOffset: PropTypes.number,
  isValid: PropTypes.bool,
  isSaving: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.object,
    title: PropTypes.object,
    startTime: PropTypes.object,
    endTime: PropTypes.object,
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onFormDataChanged: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};

export default EventEditFormDialog;
