import PropTypes from "prop-types";
import React from "react";
import styles from "./HealthEventStatus.css";

const HealthEventItem = (props) => (
  <div>
    <span>{props.type}:&nbsp;</span>
    <span className={styles.eventCount}>{props.healthEventCount}</span>
  </div>
);

export function HealthEventStatus({ data }) {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.header}>{"Health Events"}</div>
      <div className={styles.container}>
        {data.map((item, key) => (
          <HealthEventItem key={key} {...item} />
        ))}
      </div>
    </div>
  );
}

HealthEventStatus.propTypes = {
  data: PropTypes.array.isRequired,
};
