import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../../common/components/Loader";
import { FormTextBox } from "../../../components/Form";

export default class DataStatusDetailedCellView extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  renderData = ({ name, value }, index) => (
    <FormTextBox key={index} name={name} label={name} value={value} />
  );

  render() {
    const { data, template } = this.props;

    if (!data && !template) {
      return <Loader />;
    }

    return template ? template : <div>{data.map(this.renderData)}</div>;
  }
}

DataStatusDetailedCellView.propTypes = {
  data: PropTypes.array,
  template: PropTypes.object,
};
