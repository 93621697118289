import React from "react";
import styles from "./Operations.css";
import { OperationConsumption } from "../../../types";

interface Props {
  consumptionPerOperation: OperationConsumption[];
  canGoLeftOrRight: boolean;
}

export const OperationsColumn = ({
  consumptionPerOperation,
  canGoLeftOrRight,
}: Props) => {
  return (
    <div
      className={`${styles.operationContainer} ${
        canGoLeftOrRight ? styles.paddingToCompensateForButtonsInHeader : ""
      }`}
    >
      {consumptionPerOperation.map((c) => (
        <div
          className={styles.consumptionContainer}
          title={c.hoverText}
          key={c.operationId}
        >
          <div className={styles.consumptionValue}>
            {c.totalConsumption.toFixed(1)}
          </div>
          <div className={styles.consumptionUnit}>t/h</div>
        </div>
      ))}
    </div>
  );
};
