import { formatDateTimeWithUtc } from "../../../common/dates";
import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { orderBy, maxBy, get, isString, compact } from "lodash";
import { getErrorMessage } from "../../../common/error";

const {
  onboardProcessorConfig: {
    FETCH_DATA,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_ERROR,
    DEPLOY,
    DEPLOY_SUCCESS,
    DEPLOY_ERROR,
    DEPLOY_REQUESTED,
    DOWNLOAD,
    DOWNLOAD_SUCCESS,
    DOWNLOAD_ERROR,
    DOWNLOAD_REQUESTED,
    GENERATE,
    GENERATE_ERROR,
    GENERATE_SUCCESS,
    GENERATE_REQUESTED,
    SELECT_BASELINE,
    SHOW_GENERATE_PANEL,
    HIDE_GENERATE_PANEL,
  },
} = vesselConfig;

export default createReducer(
  {},
  {
    [FETCH_DATA]: (_state, { vesselId }) => ({ vesselId, isLoading: true }),
    [FETCH_DATA_SUCCESS]: (
      _state,
      { vesselId, configs, baselines, processorBuild }
    ) => ({
      vesselId,
      configs,
      baselines,
      processorBuild,
    }),
    [FETCH_DATA_ERROR]: (_state, { vesselId, error }) => ({
      vesselId,
      error,
    }),
    [DOWNLOAD]: (state, { configId }) => ({
      ...state,
      download: { configId, isLoading: true },
    }),
    [DOWNLOAD_SUCCESS]: (state, { configId }) => ({
      ...state,
      download: { configId, isSuccess: true },
    }),
    [DOWNLOAD_ERROR]: (state, { configId, error }) => ({
      ...state,
      download: { configId, error },
    }),
    [DEPLOY]: (state, { configId }) => ({
      ...state,
      deploy: { configId, isLoading: true },
    }),
    [DEPLOY_SUCCESS]: (state, { configId, data }) => ({
      ...state,
      deploy: { configId, isSuccess: true },
      configs: state.configs.map((c) => (c.id === configId ? data : c)),
    }),
    [DEPLOY_ERROR]: (state, { configId, error }) => ({
      ...state,
      deploy: { configId, error },
    }),
    [GENERATE]: (state) => ({
      ...state,
      generate: { ...state.generate, isLoading: true },
    }),
    [GENERATE_SUCCESS]: (state, action) => ({
      ...state,
      generate: null,
      configs: [...state.configs, action.data],
    }),
    [GENERATE_ERROR]: (state, { error }) => ({
      ...state,
      generate: { ...state.generate, error, isLoading: false },
    }),
    [SELECT_BASELINE]: (state, { selected }) => ({
      ...state,
      generate: {
        ...state.generate,
        selectedBaseline: selected,
      },
    }),
    [SHOW_GENERATE_PANEL]: (state) => ({
      ...state,
      generate: {
        ...state.generate,
        isVisible: true,
      },
    }),
    [HIDE_GENERATE_PANEL]: (state) => ({
      ...state,
      generate: {
        ...state.generate,
        isVisible: false,
      },
    }),
  }
);

export function prepState(state) {
  const vesselId = state.vesselId;
  const configs = orderBy(state.configs || [], ["name"], ["desc"]);
  const deployedConfigs = configs.filter((c) => c.deployedOn);
  const deployedConfig = maxBy(deployedConfigs, (c) => c.deployedOn);
  const deploy = state.deploy || {};
  const download = state.download || {};
  const generate = state.generate || {};
  const baselines = orderBy(state.baselines, "version", "desc");
  const selectedBaseline = generate.selectedBaseline;

  const processorBuild = state.processorBuild || {};
  const canGenerate =
    !processorBuild.currentProcessorBuild ||
    processorBuild.currentProcessorBuild > processorBuild.minimumProcessorBuild;

  return {
    isLoading: state.isLoading || !state.configs,
    error: getErrorMessage(state),
    configs: configs.map((c) => ({
      version: c.name,
      deployed: c.deployedOn && formatDateTimeWithUtc(c.deployedOn),
      processorVersion: c.processorVersion,
      baselineVersion: get(
        baselines.find((x) => x.id === c.baselineId),
        "version"
      ),
      isCurrent: c === deployedConfig,
      isDeploying: deploy.isDeploying && deploy.configId === c.id,
      isDownloading: download.isLoading && download.configId === c.id,
      deployError: deploy.configId === c.id && getErrorMessage(deploy),
      downloadError: download.configId === c.id && getErrorMessage(download),
      actions: {
        deploy: {
          type: DEPLOY_REQUESTED,
          configId: c.id,
          vesselId: c.vesselId,
        },
        download: {
          type: DOWNLOAD_REQUESTED,
          configId: c.id,
          vesselId: c.vesselId,
        },
      },
    })),
    generate: {
      ...generate,
      warning: !canGenerate
        ? `WARNING! Minimum required processor build on vessel is ${processorBuild.minimumProcessorBuild}, reported build on vessel is
         ${processorBuild.currentProcessorBuild}. Only continue if you have initiated an installation process with a processor version 
         that at least corresponds to the minimum required processor build, or else the processor might crash at startup.`
        : "",
      errors: compact([...getAllErrorMessages(generate.error)]),
      baselines,
      actions: {
        show: { type: SHOW_GENERATE_PANEL },
        hide: { type: HIDE_GENERATE_PANEL },
        generate: {
          type: GENERATE_REQUESTED,
          vesselId,
          baselineId: selectedBaseline && selectedBaseline.id,
        },
        selectBaseline: { type: SELECT_BASELINE },
      },
    },
  };
}

function getAllErrorMessages(error) {
  if (!error) {
    return [];
  }

  if (error.response.status === 400 && error.response.data) {
    if (isString(error.response.data)) {
      return [error.response.data];
    }
    return Object.values(error.response.data).flat();
  }

  return [error.message];
}
