import React from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { color as c } from "../../colors";
import { SparklineContext } from "./Sparkline";
import { isArray } from "lodash";

const SparklineGrid = ({
  height,
  width,
  scaleX,
  scaleY,
  minX,
  maxX,
  minY,
  maxY,
  xTicks,
  yTicks,
  showX,
  showY,
  color,
  removeOuter,
  yDomainIndex = 0,
  opacityY,
}) => {
  const xTicksArray = isArray(xTicks) ? xTicks : d3.ticks(minX, maxX, xTicks);
  const yTicksArray = isArray(yTicks)
    ? yTicks
    : d3.ticks(minY[yDomainIndex], maxY[yDomainIndex], yTicks);
  const currentScaleY = scaleY[yDomainIndex];

  if (removeOuter) {
    xTicksArray.pop();
    yTicksArray.pop();
  }

  const lineStyle = {
    fill: "none",
    strokeWidth: 1,
    stroke: color || c("--mid-grey"),
    strokeOpacity: 0.5,
    shapeRendering: "crispEdges",
  };

  return (
    <g>
      {showX &&
        xTicksArray.map((t) => (
          <line
            style={lineStyle}
            key={`xLine${t}`}
            x1={scaleX(t)}
            y1={0}
            x2={scaleX(t)}
            y2={height}
          />
        ))}
      {showY &&
        yTicksArray.map((t) => (
          <line
            style={{ ...lineStyle, strokeOpacity: opacityY }}
            key={`yLine${t}`}
            x1={0}
            y1={currentScaleY(t)}
            x2={width}
            y2={currentScaleY(t)}
          />
        ))}
    </g>
  );
};

SparklineGrid.defaultProps = {
  xTicks: 6,
  yTicks: 6,
  showY: true,
  showX: true,
  removeOuter: false,
  opacityY: 0.5,
};

SparklineGrid.propTypes = {
  xTicks: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  yTicks: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  showX: PropTypes.bool,
  showY: PropTypes.bool,
  color: PropTypes.string,
  removeOuter: PropTypes.bool,
  opacityY: PropTypes.number,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineGrid {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
