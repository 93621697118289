import styles from "./DataGridView.css";
import { isNumber } from "lodash";
import React from "react";
import { Column, DataItem } from "./Types";

type FooterProps = {
  data: DataItem[];
  columns: Column[];
};

type FooterData = {
  [key: string]: number | string;
};

export const Footer: React.FC<FooterProps> = ({
  data,
  columns,
}: FooterProps) => {
  const footerData: FooterData = {};
  columns.forEach((c) => {
    footerData[c.field] = c.total
      ? data.reduce(
          (total, item) =>
            total + (isNumber(item[c.field]) ? (item[c.field] as number) : 0),
          0
        )
      : "";
  });

  return (
    <>
      {!!data.length &&
        !!columns.filter((c) => c.total).length &&
        columns.map((c, i) => (
          <div key={c.field} className={styles.footerItem}>
            {isNumber(footerData[c.field])
              ? (footerData[c.field] as number).toFixed(1)
              : ""}
            {i === 0 && <div className={styles.footerItemHead}>Total:</div>}
          </div>
        ))}
    </>
  );
};
