import { createSelector } from "reselect";
import {
  findVesselGroupForVessel,
  getFirstVesselIdInGroup,
  getVesselsInGroup,
  isVesselInGroup,
} from "../reducers/reducer.pageContext";
import { fromPairs, get, isArray, isEqual, isNil } from "lodash";
import {
  clampDateFilter,
  createDateRangeFilter,
  createMonthFilter,
  createYearFilter,
  dateFilterFromJSON,
  defaultDateFilter,
  dateFilterToJSON,
  checkIfTimeOffsetIsValidUtcNumber,
  createTimeOffsetFilter,
} from "../common/dateFilter";
import { parseBoolean } from "../../common/booleans";
import {
  getDefaultMetricIdsForVesselItem,
  getPageContext,
  getUser,
  getVesselItemById,
  vesselContainsPerformanceIndicator,
  vesselItemContainsMetric,
} from "./common";
import { getLimits, unionLimits } from "../common/vessels";
import {
  CURRENT_DAY,
  CURRENT_MONTH,
  CURRENT_YEAR,
  defaultDateRanges,
  fromISOString,
} from "../common/dates";
import { isSupported } from "../reducers/datePicker";
import { DateRange, DatePickerValue } from "../components/DatePicker/Types";
import { IQueryContext } from "../providers/QueryContextProvider";

export const getQueryContext = (_state: any, props: any): any => {
  return get(props, "queryContext", {});
};

export const getTimeOffset = (state: any): any => {
  return get(state, "user.defaultTimeOffset", 0);
};

export const getVesselId = createSelector(
  [getQueryContext],
  (queryContext: IQueryContext): string | undefined => queryContext.vesselId
);

export const getGroupId = createSelector(
  [getQueryContext],
  (queryContext: IQueryContext): string | undefined => queryContext.groupId
);

export const getDate = createSelector(
  [getQueryContext],
  (queryContext: IQueryContext): DatePickerValue | null =>
    dateFilterFromJSON(queryContext.date)
);

export const getLimitsForVesselInQuery = createSelector(
  [getVesselId, getGroupId, getPageContext],
  (
    vesselId: string | undefined,
    groupId: string | undefined,
    pageContext: any
  ): DateRange => {
    if (vesselId) {
      return getLimits(pageContext.vessels[vesselId]);
    }
    return unionLimits(getVesselsInGroup(groupId, pageContext));
  }
);

export type Filter<T> = {
  value?: T;
  defaultValue?: T;
  isValid?: boolean;
  isEmpty?: boolean;
};

export type DateFilter = {
  defaultDateRange?: any;
} & Filter<any>;

export const getVesselFilter = createSelector(
  [getVesselId, getGroupId, getPageContext],
  (
    vesselId: string | undefined,
    groupId: string | undefined,
    pageContext: any
  ): Filter<any> => {
    return {
      value: vesselId,
      defaultValue:
        getFirstVesselIdInGroup(groupId, pageContext) ||
        pageContext.defaultVesselId,
      isValid: Boolean(vesselId && vesselId in pageContext.vessels),
      isEmpty: !vesselId,
    };
  }
);

export const getGroupFilter = createSelector(
  [getGroupId, getVesselId, getPageContext],
  (
    groupId: string | undefined,
    vesselId: string | undefined,
    pageContext: any
  ): Filter<any> => {
    return {
      value: groupId,
      defaultValue:
        findVesselGroupForVessel(vesselId, pageContext) ||
        pageContext.defaultGroupId,
      isValid: Boolean(
        groupId &&
          (!vesselId || isVesselInGroup(vesselId, groupId, pageContext))
      ),
      isEmpty: !groupId,
    };
  }
);

export const getDateFilter = createSelector(
  [getDate, getLimitsForVesselInQuery, getTimeOffset],
  (
    date: DatePickerValue | null,
    limits: any,
    timeOffset: number
  ): Filter<any> => {
    const isEmpty = !date || !date.range;

    const timeOffsetIsValid =
      !isEmpty && checkIfTimeOffsetIsValidUtcNumber(date.timeOffset.toString());
    if (date && !timeOffsetIsValid) {
      date.timeOffset = createTimeOffsetFilter(date, timeOffset);
    }

    const isValid =
      !isEmpty &&
      isEqual(clampDateFilter(date, limits), date) &&
      timeOffsetIsValid;
    const defaultValue = clampDateFilter(
      isEmpty ? defaultDateFilter(timeOffset) : date,
      limits
    );

    return {
      value: date,
      defaultValue: defaultValue,
      isEmpty,
      isValid,
    };
  }
);

/*
  Same as getDateFilter, but allows a custom date range relative to today
 */
export const getDateRangeFilter = createSelector(
  [
    getDateFilter,
    getLimitsForVesselInQuery,
    getUser,
    getTimeOffset,
    getVesselId,
  ],
  (
    date: any,
    limits: any,
    user: any,
    timeOffset: number,
    vesselId: any
  ): DateFilter => {
    if (date.isEmpty && user.defaultDateRange) {
      const dateRange = defaultDateRanges.find(
        (x) => x.id === user.defaultDateRange
      );
      const result: DateFilter = {
        defaultValue: date.defaultValue,
        isEmpty: false,
        isValid: true,
        value: null,
      };
      let value;
      if (!dateRange) {
        return date;
      } else if (dateRange.id === CURRENT_DAY) {
        value = createDateRangeFilter(
          {
            from: new Date(Date.now()),
            to: new Date(Date.now()),
          },
          timeOffset
        );
      } else if (dateRange.id === CURRENT_MONTH) {
        value = createMonthFilter(new Date(Date.now()), timeOffset);
      } else if (dateRange.id === CURRENT_YEAR) {
        value = createYearFilter(new Date(Date.now()), timeOffset);
      } else {
        return date;
      }
      result.defaultDateRange = dateRange.id;
      result.value = clampDateFilter(value, limits);
      return result;
    } else if (!isSupported(date.value, { vesselId })) {
      return {
        value: defaultDateFilter(timeOffset, {
          endTime: get(date, "value.range", []).to,
        }),
        defaultValue: defaultDateFilter(timeOffset),
        isEmpty: false,
        isValid: true,
      };
    } else {
      return date;
    }
  }
);

export const getMonthFilter = createSelector(
  [getDateRangeFilter, getLimitsForVesselInQuery],
  (dateFilter: any, limits: any): Filter<any> => {
    if (!dateFilter.isEmpty && dateFilter.value.type === "month") {
      return dateFilter;
    }

    const startDate =
      dateFilter.isEmpty || !dateFilter.isValid
        ? dateFilter.defaultValue.range.from
        : dateFilter.value.range.from;
    const monthFilter = createMonthFilter(fromISOString(startDate));
    monthFilter.timeOffset = dateFilter.defaultValue.timeOffset;

    return {
      ...dateFilter,
      isValid: false,
      defaultValue: clampDateFilter(monthFilter, limits),
    };
  }
);

export const isVesselItemFilterValid = (
  x: any,
  vesselId: any,
  pageContext: any
): any => {
  const vesselItem = getVesselItemById(x.id, pageContext);
  const metricIds = x.metricIds || [];
  return (
    vesselItem &&
    vesselItem.vesselId === vesselId &&
    metricIds.every((metricId: any) =>
      vesselItemContainsMetric(x.id, metricId, pageContext)
    )
  );
};

export const getVesselItemsFilter = createSelector(
  [getQueryContext, getVesselId, getPageContext],
  (query: any, vesselId: any, pageContext: any): any => {
    let xs = query.items || [];
    if (!isArray(xs)) {
      xs = Object.values(xs);
    }
    const isValid = xs.every((x: any) =>
      isVesselItemFilterValid(x, vesselId, pageContext)
    );

    const defaultValue = !isValid
      ? []
      : xs.map((x: any) => ({
          id: x.id,
          selected: x.selected,
          color: x.color,
          metricIds: getDefaultMetricIdsForVesselItem(x.id, pageContext),
        }));

    return {
      value: xs,
      defaultValue,
      isValid,
      hasVesselItems: xs.length > 0,
      hasSelectedMetrics: xs.some(
        (x: any) => x.metricIds && x.metricIds.length > 0
      ),
    };
  }
);

export const getPerformanceIndicatorsFilter = createSelector(
  [getQueryContext, getVesselId, getPageContext],
  (query: any, vesselId: any, pageContext: any): any => {
    const performanceIndicators = query.kpis || [];
    const disabled = parseBoolean(query.kpisDisabled);
    const isValid =
      performanceIndicators.length === 0 ||
      performanceIndicators.every(
        (id: any) =>
          pageContext.performanceIndicators[id] &&
          vesselContainsPerformanceIndicator(vesselId, id, pageContext)
      );

    return {
      value: performanceIndicators,
      disabled,
      defaultValue: [],
      isValid,
    };
  }
);

export const getShowOperations = createSelector(
  [getQueryContext],
  (query: any): any => query.showOperations
);

export const getShowOperationsFilter = createSelector(
  [getShowOperations],
  (showOperations: any): any => {
    if (showOperations === undefined || showOperations === null) {
      showOperations = true;
    } else {
      showOperations = showOperations === "true";
    }

    return {
      value: showOperations,
    };
  }
);
export const getStateFilter = createSelector(
  [getQueryContext],
  (queryContext: any): any => {
    const states = queryContext.states || [];
    const isValid =
      states.length === 0 || states.every((x: any) => !isNil(x.stateType));
    return {
      value: states,
      isValid,
      defaultValue: [],
    };
  }
);
export const convertFiltersToQueryParams = (filters: {
  vesselFilter?: Filter<any>;
  groupFilter?: Filter<any>;
  dateFilter?: Filter<any>;
  vesselItemsFilter?: Filter<any>;
  performanceIndicatorsFilter?: Filter<any>;
  stateFilter?: Filter<any>;
}): any => {
  const filterParams = fromPairs(
    Object.entries(filters).map(([key, filter]) => [
      key,
      filter.isValid ? filter.value : filter.defaultValue,
    ])
  );

  return {
    vesselId: filterParams.vesselFilter,
    groupId: filterParams.groupFilter,
    date: dateFilterToJSON(filterParams.dateFilter),
    items: filterParams.vesselItemsFilter,
    kpis: filterParams.performanceIndicatorsFilter,
    states: filterParams.stateFilter,
  };
};
