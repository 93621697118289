import T from "prop-types";
import React from "react";
import styles from "./MapFlyout.css";
import OMDescriber from "./OMDescriber";
import MapMarker from "../MapMarker";
import { OPERATION_MODES } from "../../../common/config";
import RadioCheckbox from "../RadioCheck/RadioCheckbox";

export default class MapFlyout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: this.props.showing,
    };
  }

  componentDidMount() {
    this.setState({
      initialCenter: this.props.initialCenter,
    });
  }

  toggleMapFlyout() {
    this.setState({
      showing: !this.state.showing,
    });
  }

  listItems(data, id) {
    let active;
    return (data || []).map((item, index) => {
      active = item.vesselId === id;
      return (
        <tr
          key={index}
          onClick={this.props.vesselListItemClick.bind(this, item)}
          tabIndex={index}
          data-active={active}
        >
          <td>{item.vesselName}</td>
          <td>&nbsp;</td>
        </tr>
      );
    });
  }

  renderOperationModes(data) {
    return data.map((item, i) => {
      let profiles = item.profiles
        .filter((profile) => {
          if (
            this.props.activeOperationalProfiles === undefined ||
            this.props.activeOperationalProfiles === null
          )
            return false;
          return (
            this.props.activeOperationalProfiles.find(
              (activeItem) => activeItem.legend === profile.name
            ) !== undefined
          );
        })
        .map((profile, j) => {
          return (
            <OMDescriber key={j} profile={profile.name} color={profile.color} />
          );
        });

      if (
        profiles === undefined ||
        profiles === null ||
        profiles.length === 0
      ) {
        return <dl key={i} />;
      }

      return (
        <dl key={i} className={styles.operationMode}>
          <dt>{profiles}</dt>
          <dd>{item.name}</dd>
        </dl>
      );
    });
  }

  render() {
    let cls = this.state.showing ? styles.showing : "";
    const showPoi = this.props.showPoi;

    return (
      <div className={[styles.mapFlyout, cls].join(" ")}>
        <div className={styles.simpleList}>
          <h1 className={styles.title}>{this.props.title}</h1>
          <p className={styles.subtitle}>{this.props.subtitle}</p>

          <div className={styles.scrollable}>
            <table>
              <tbody>
                {this.listItems(this.props.data, this.props.activeVesselId)}
              </tbody>
            </table>
          </div>
        </div>

        <div className={styles.descriptionSection}>
          <div className={styles.section}>
            <h2 className={styles.sectionHeading}>Vessel connectivity</h2>
            <dl className={styles.markerDescription}>
              <dt>
                <MapMarker connection={"connected"} efficiency={"baseline"} />
              </dt>
              <dd>Connected</dd>
              <dt>
                <MapMarker
                  connection={"notConnected"}
                  efficiency={"baseline"}
                />
              </dt>
              <dd>Not Connected</dd>
            </dl>
          </div>

          <div className={styles.section}>
            <h2 className={styles.sectionHeading}>Vessel fuel performance</h2>
            <dl className={styles.markerDescription}>
              <dt>
                <MapMarker connection={"notConnected"} efficiency={"below"} />
              </dt>
              <dd>Below</dd>
              <dt>
                <MapMarker
                  connection={"notConnected"}
                  efficiency={"baseline"}
                />
              </dt>
              <dd>Baseline</dd>
              <dt>
                <MapMarker connection={"notConnected"} efficiency={"above"} />
              </dt>
              <dd>Above</dd>
            </dl>
          </div>

          <div className={styles.section}>
            <h2 className={styles.sectionHeading}>Operation modes</h2>
            <div className={styles.operationModes}>
              {this.renderOperationModes(OPERATION_MODES)}

              <div className={styles.vesselSummary}>
                <h2 className={styles.vesselLength}>
                  {this.props.vesselLength}
                </h2>
                <p>Total vessels</p>
              </div>
            </div>
          </div>
          <div className={styles.section}>
            <RadioCheckbox
              label={"Show points of interest"}
              horizontalStretch={true}
              onCheck={() => this.props.togglePoi(showPoi)}
              checked={showPoi}
            />
          </div>

          <button
            className={styles.mapFlyoutToggle}
            title="Open/close sidebar"
            onClick={this.toggleMapFlyout.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 -1 42 42">
              <path d="M25.6 28.4L17.3 20l8.3-8.4.8.8-7.7 7.6 7.7 7.6z" />
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

MapFlyout.propTypes = {
  title: T.string,
  subtitle: T.string,
  data: T.array,
  vesselLength: T.number,
  vesselListItemClick: T.func,
  activeVesselId: T.any,
  activeOperationalProfiles: T.array,
  togglePoi: T.func.isRequired,
  showPoi: T.bool.isRequired,
};
