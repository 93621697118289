import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, isEqual } from "lodash";
import {
  canSelectNext,
  canSelectPrev,
  getNext,
  getPrev,
  formatValue,
  getSelected,
  getSelectedTimeOffset,
  adjustDateWhenTimeOffsetInUrlIsChanged,
} from "../../reducers/datePicker";
import {
  update,
  hide,
  show,
  changeType,
  select,
  getNextOptions,
  getPrevOptions,
  changeDate,
} from "../../actions/datePicker";
import { fetchLegs, fetchVoyages } from "../../actions";
import DatePickerHeader from "./DatePickerHeader";
import DatePicker from "./DatePicker";
import { Portal } from "react-portal";
import { atStartOfMonth, atEndOfMonth } from "../../common/dates";

const getContext = (props) => ({
  limits: props.limits,
  current: props.current,
  vesselId: props.vesselId,
  legs: props.legs[props.vesselId],
  voyages: props.voyages[props.vesselId],
});

class DatePickerContainer extends Component {
  constructor(props) {
    super(props);
    this.apply = this.apply.bind(this);
  }

  componentDidMount() {
    const context = getContext(this.props);
    this.loadCurrentVesselStates(context);
  }

  componentDidUpdate(prevProps) {
    const context = getContext(this.props);
    const prevContext = getContext(prevProps);

    if (
      !isEqual(context.limits, prevContext.limits) ||
      !isEqual(context.legs, prevContext.legs) ||
      !isEqual(context.voyages, prevContext.voyages)
    ) {
      this.props.update(context);
    }
  }

  loadCurrentVesselStates({ current, vesselId }) {
    if (
      (get(current, "type") === "leg" || get(current, "type") === "voyage") &&
      vesselId
    ) {
      const from = get(current, "value.from");
      const to = get(current, "value.to");
      const dateRange = {
        from: atStartOfMonth(from).toISOString(),
        to: atEndOfMonth(to).toISOString(),
      };
      const timeOffset = parseInt(get(current, "timeOffset", 0));
      current.type === "leg" &&
        this.props.fetchLegs(vesselId, dateRange, timeOffset);
      current.type === "voyage" &&
        this.props.fetchVoyages(vesselId, dateRange, timeOffset);
    }
  }

  apply() {
    const { onChange, hide: onHide } = this.props;
    if (onChange) {
      if (this.props.datePicker.type === "timeOffset") {
        onChange(
          getSelectedTimeOffset(this.props.datePicker, this.props.current)
        );
      } else {
        onChange(getSelected(this.props.datePicker, this.props.current));
      }
    }
    if (onHide) {
      onHide();
    }
  }

  render() {
    const {
      datePicker,
      show: onShow,
      hide: onHide,
      changeType: onChangeType,
      select: onSelect,
      getNextOptions: onGetNextOptions,
      getPrevOptions: onGetPrevOptions,
      onChange,
      fetchLegs: onFetchLegs,
      fetchVoyages: onFetchVoyages,
      headerContainerStyle,
      alwaysShowPrevNext,
      placeHolder,
      supportedTypes,
      limits,
    } = this.props;

    const context = getContext(this.props);
    const current = context.current;
    const currentTimeOffset = parseInt(
      get(current, "currentSelectedUtcValue", get(current, "timeOffset", 0))
    );
    adjustDateWhenTimeOffsetInUrlIsChanged(current, context, onChange);

    return (
      <div>
        <DatePickerHeader
          formattedValue={formatValue(current)}
          canSelectNext={canSelectNext(current, context)}
          canSelectPrev={canSelectPrev(current, context)}
          selectNext={() => onChange(getNext(current, context))}
          selectPrev={() => onChange(getPrev(current, context))}
          show={() => onShow(context)}
          containerStyle={headerContainerStyle}
          alwaysShowPrevNext={alwaysShowPrevNext}
          placeHolder={placeHolder}
          supportedTypes={supportedTypes}
        />
        {datePicker.isVisible && (
          <Portal node={document && document.getElementById("popupContainer")}>
            <DatePicker
              {...datePicker}
              changeType={(type) => onChangeType(type, context)}
              hide={onHide}
              select={(option) => onSelect(option, context)}
              apply={this.apply}
              getNextOptions={() => onGetNextOptions(context)}
              getPrevOptions={() => onGetPrevOptions(context)}
              fetchLegs={(dateRange) =>
                onFetchLegs(context.vesselId, dateRange, currentTimeOffset)
              }
              fetchVoyages={(dateRange) =>
                onFetchVoyages(context.vesselId, dateRange, currentTimeOffset)
              }
              supportedTypes={
                supportedTypes ? supportedTypes : datePicker.supportedTypes
              }
              limits={limits}
              changeDate={(dateValues) =>
                this.props.changeDate(dateValues, context)
              }
            />
          </Portal>
        )}
      </div>
    );
  }
}

DatePickerContainer.propTypes = {
  limits: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  }).isRequired,
  current: PropTypes.shape({
    value: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  vesselId: PropTypes.any,
  headerContainerStyle: PropTypes.object,
  allowNoCurrent: PropTypes.bool,
  alwaysShowPrevNext: PropTypes.bool,
  placeHolder: PropTypes.string,
  supportedTypes: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    datePicker: state.datePicker,
    voyages: state.voyages,
    legs: state.legs,
  };
};

const mapDispatchToProps = {
  update,
  show,
  hide,
  changeType,
  select,
  getNextOptions,
  getPrevOptions,
  fetchLegs,
  fetchVoyages,
  changeDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DatePickerContainer);
