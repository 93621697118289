import React, { useEffect, useRef } from "react";
import styles from "./NodeItem.css";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import classNames from "../../../common/classNames";

const NodeItemConnector = ({ left, top, right, bottom, parentId }) => {
  const innerRef = useRef();

  /* eslint-disable-next-line no-unused-vars */
  const [_, dragRef, preview] = useDrag(() => ({
    type: "BOX",
    item: {
      parentId,
    },
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.connectorContainer,
          "nodeConnectorContainer"
        )}
        style={{ top, right, bottom, left }}
      >
        <div>
          <div
            className={styles.connectorContainerDraggable}
            style={{}}
            ref={dragRef}
          >
            <div ref={innerRef} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NodeItemConnector;
