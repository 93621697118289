import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createCompany,
  fetchCompanies,
  fetchCompany,
} from "../../../actions/admin";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import styles from "../Admin.css";
import CompaniesForm from "./CompaniesFormPopup";

class Companies extends Component {
  constructor(props) {
    super(props);

    this.gridConfig = {
      columns: [
        {
          header: "Name",
          field: "name",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Actions",
          align: "left",
          template: this.renderActions.bind(this),
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchCompanies();
  }

  fetchCompanies() {
    this.props.fetchCompanies();
  }

  renderActions({ id }) {
    if (!this.props.canEdit) {
      return null;
    }
    return (
      <Button
        value="Edit"
        clickAction={() => this.props.fetchCompany(id)}
        type="link"
      />
    );
  }

  renderDataGrid() {
    const { isLoading, isLoadingModules, error, data } = this.props.companies;
    if (isLoading || isLoadingModules) {
      return <Loader error={error} />;
    }
    return <DataGrid data={data} {...this.gridConfig} />;
  }

  render() {
    const { canEdit } = this.props;
    return (
      <div>
        <Header title="Admin - Companies" contentDistribution="space-between">
          <div className={styles.headerContainer}>
            {canEdit && (
              <Button
                value="Create Company"
                clickAction={this.props.createCompany}
              />
            )}
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        <CompaniesForm />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companies: state.admin.companies,
  selectedCompanyId: state.admin.selectedCompanyId,
  canEdit: isSystemAdmin(state.user),
});

const mapActionsToProps = {
  fetchCompany,
  fetchCompanies,
  createCompany,
};

export default connect(mapStateToProps, mapActionsToProps)(Companies);
