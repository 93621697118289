export function getOffset(el) {
  let offsetLeft = 0;
  let offsetTop = 0;

  do {
    offsetLeft += el.offsetLeft;
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  } while (el);

  return { left: offsetLeft, top: offsetTop };
}
