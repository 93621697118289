import { CssParam, cssParam } from "./parameters";

export const color = (name: CssParam) => {
  return cssParam(name, "#000000");
};

export const graphColors = [
  color("--blue-base"),
  color("--red-base"),
  color("--yellow-base"),
  color("--orange-base"),
  color("--blue-dark"),
  color("--red-dark"),
  color("--yellow-dark"),
  color("--orange-dark"),
];

export const ansiColors = {
  black: { number: 0, color: color("--c-black") },
  brightblack: { number: 1, color: color("--c-brightblack") },
  red: { number: 2, color: color("--c-red") },
  brightred: { number: 3, color: color("--c-brightred") },
  green: { number: 4, color: color("--c-green") },
  brightgreen: { number: 5, color: color("--c-brightgreen") },
  yellow: { number: 6, color: color("--c-yellow") },
  brightyellow: { number: 7, color: color("--c-brightyellow") },
  blue: { number: 8, color: color("--c-blue") },
  brightblue: { number: 9, color: color("--c-brightblue") },
  purple: { number: 10, color: color("--c-purple") },
  brightpurple: { number: 11, color: color("--c-brightpurple") },
  cyan: { number: 12, color: color("--c-cyan") },
  brightcyan: { number: 13, color: color("--c-brightcyan") },
  white: { number: 14, color: color("--c-white") },
  brightwhite: { number: 15, color: color("--c-brightwhite") },
  orange: { number: 16, color: color("--c-orange") },
  brightorange: { number: 17, color: color("--c-brightorange") },
};

export const ansiGraphColors = [
  ansiColors.brightcyan,
  ansiColors.brightgreen,
  ansiColors.brightyellow,
  ansiColors.brightblue,
  ansiColors.brightred,
  ansiColors.brightpurple,
  ansiColors.brightwhite,
  ansiColors.cyan,
  ansiColors.green,
  ansiColors.yellow,
  ansiColors.blue,
  ansiColors.red,
  ansiColors.purple,
  ansiColors.white,
  ansiColors.brightorange,
  ansiColors.orange,
].map((c) => c.color);

export const ansiColor = (name: keyof typeof ansiColors) =>
  ansiColors[name].color;

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;

  if (col.startsWith("#")) {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};
