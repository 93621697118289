import React, { useState } from "react";
import styles from "../Form.css";

export const FormDurationBox = ({
  seconds,
  onChange,
  width,
  icon,
  label = "Duration",
  testId,
}) => {
  const time = {
    hh: Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0"),
    mm: Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    ss: (seconds % 60).toString().padStart(2, "0"),
  };
  const [input, setInput] = useState("");
  const textBoxStyle = { ...createBorderStyle() };

  const handleChange = (event) => {
    let inputValue = event.target.value.slice(11);
    if (!isNaN(inputValue)) {
      if (input.length < 6) {
        setInput(input + inputValue);
        let modified = (input + inputValue).padStart(6, "0");
        onChange({
          hh: modified.substring(0, 2),
          mm: modified.substring(2, 4),
          ss: modified.substring(4, 6),
        });
      }
    }
    if (inputValue === "") {
      setInput(input.slice(0, input.length - 1));
      let modified = input.slice(0, input.length - 1).padStart(6, "0");
      onChange({
        hh: modified.substring(0, 2),
        mm: modified.substring(2, 4),
        ss: modified.substring(4, 6),
      });
    }
  };
  const renderLabel = (label) => {
    if (label && label !== "") {
      return (
        <div className={styles.labelContainer}>
          <label className={styles.label}>{label}</label>
        </div>
      );
    }
  };
  return (
    <div className={styles.formElementContainer} data-testid={testId}>
      {renderLabel(label)}
      <input
        type="text"
        value={`${time.hh}h ${time.mm}m ${time.ss}s`}
        placeholder="00h 00m 00s"
        onChange={handleChange}
        style={{
          ...textBoxStyle,
          width: width,
          background: `url(${icon}) no-repeat 95% 50%`,
        }}
      />
    </div>
  );
};

export default FormDurationBox;
export const createBorderStyle = (border) => {
  const borderWidth = "2px";
  const borderRadius = "10px";
  const zeroPx = "0px";
  const borderIncludesLeft = border?.includes("left");
  const borderIncludesTop = border?.includes("top");
  const borderIncludesRight = border?.includes("right");
  const borderIncludesBottom = border?.includes("bottom");
  const getBorderWidth = (borderIncludesSide, sideNoRadius) =>
    !border || borderIncludesSide || border.includes(sideNoRadius)
      ? borderWidth
      : zeroPx;
  const getBorderCornerRadius = (
    borderIncludesFirstSide,
    borderIncludesSecondSide
  ) =>
    !border || (borderIncludesFirstSide && borderIncludesSecondSide)
      ? borderRadius
      : zeroPx;

  return {
    borderLeftWidth: getBorderWidth(borderIncludesLeft, "leftNoRadius"),
    borderTopLeftRadius: getBorderCornerRadius(
      borderIncludesLeft,
      borderIncludesTop
    ),
    borderTopWidth: getBorderWidth(borderIncludesTop, "topNoRadius"),
    borderTopRightRadius: getBorderCornerRadius(
      borderIncludesTop,
      borderIncludesRight
    ),
    borderRightWidth: getBorderWidth(borderIncludesRight, "rightNoRadius"),
    borderBottomRightRadius: getBorderCornerRadius(
      borderIncludesRight,
      borderIncludesBottom
    ),
    borderBottomWidth: getBorderWidth(borderIncludesBottom, "bottomNoRadius"),
    borderBottomLeftRadius: getBorderCornerRadius(
      borderIncludesBottom,
      borderIncludesLeft
    ),
  };
};
