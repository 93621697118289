import { isNil, sortBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {
  CHART_VALUE,
  GROUPED_AGGREGATED_VALUES,
  PERFORMANCE_VALUE,
  AGGREGATED_VALUE,
  EEOI_VALUE,
  prepCompareTable,
} from "../../reducers/reducer.compare";
import AddColumn from "./AddColumn/AddColumn";
import styles from "./CompareTable.css";
import ButtonClose from "../ButtonClose";
import { Icon } from "../../../common/components/Icon/Icon";
import classNames from "../../../common/classNames";
import DonutContainer from "../DonutContainer";
import { roundConvert } from "../../../common/numbers";
import Loader from "../../../common/components/Loader";
import { getTimeOffsetFormat } from "../../reducers/datePicker";
import { GetCargoUnit } from "../../config";
import { conformDateRange } from "../../common/dates";

export default class CompareTable extends React.Component {
  static propTypes = {
    pageContext: PropTypes.shape({
      selectedVesselId: PropTypes.any,
      selectedGroupId: PropTypes.any,
    }).isRequired,
    compare: PropTypes.shape({
      data: PropTypes.any,
    }),
    fetchCompare: PropTypes.func.isRequired,
    removeCompareVessel: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.previouslySelectedSelectedGroupId = props.pageContext.selectedGroupId;
    this.previouslySelectedSelectedVesselId =
      props.pageContext.selectedVesselId;

    this.addVessel = this.addVessel.bind(this);
    this.renderCell = this.renderCell.bind(this);
  }

  addVessel({ selectedGroupId, selectedVesselId, selectedDate }) {
    const dateRange = conformDateRange(selectedDate.range);
    this.props.fetchCompare(
      selectedVesselId,
      dateRange,
      selectedDate.type,
      selectedDate.timeOffset
    );
    this.previouslySelectedSelectedGroupId = selectedGroupId;
    this.previouslySelectedSelectedVesselId = selectedVesselId;
    this.previouslySelectedDate = selectedDate;
  }

  selectOperations({ vesselId, settings }, operationIds) {
    this.props.fetchCompare(
      vesselId,
      settings.daterange,
      settings.datetype,
      settings.timeOffset,
      operationIds,
      settings.key
    );
  }

  renderCell(cell) {
    const { value, entry } = cell;
    const type = value ? value.valueType : -1;
    switch (type) {
      case CHART_VALUE:
        return (
          <ChartValue
            value={value}
            onOperationSelect={(ops) => this.selectOperations(entry, ops)}
          />
        );
      case PERFORMANCE_VALUE:
        return <PerformanceValue {...value} />;
      case AGGREGATED_VALUE:
        return <AggregatedValue {...value} />;
      case GROUPED_AGGREGATED_VALUES:
        return <GroupedValues {...value} />;
      case EEOI_VALUE:
        return <EeoiValue {...value} />;
      default:
        return null;
    }
  }

  render() {
    const { removeCompareVessel } = this.props;
    const data = prepCompareTable(this.props);
    const colCount = 1 + data.headers.length + 1;
    const gridCols = "24rem ".repeat(colCount) + "1fr"; // IE11 compat: use "1fr" instead of "auto" for the last column, since auto in IE11 is something entirely different...
    return (
      <>
        <div
          className={styles.tableHeaderGrid}
          style={{
            gridTemplateColumns: gridCols,
            msGridColumns: gridCols,
          }}
        >
          <div
            className={styles.tableHeader}
            style={{
              gridRow: 1,
              msGridRow: 1,
              gridColumn: 1,
              msGridColumn: 1,
            }}
          >
            <h2 className={styles.tableTitle}>Categories</h2>
          </div>
          {data.headers.map((header, i) => (
            <div
              key={header.key}
              className={styles.tableHeader}
              style={{
                gridColumn: i + 2,
                msGridColumn: i + 2,
                gridRow: 1,
                msGridRow: 1,
              }}
            >
              <ButtonClose
                title="Remove column"
                clickAction={() => removeCompareVessel(header.key)}
              />
              <div className={styles.tableColumnHeader}>
                <h2 className={styles.tableColumnName}>{header.name}</h2>
                <div data-test-id="compare-date">
                  {header.description +
                    " " +
                    getTimeOffsetFormat(header.timeOffset)}
                </div>
              </div>
            </div>
          ))}
          {
            <div
              className={styles.tableHeader}
              style={{
                gridRow: 1,
                msGridRow: 1,
                gridColumn: colCount,
                msGridColumn: colCount,
              }}
            />
          }
          <div
            className={styles.tableHeader}
            style={{
              gridRow: 1,
              msGridRow: 1,
              gridColumn: colCount + 1,
              msGridColumn: colCount + 1,
            }}
          />
        </div>

        <div
          className={styles.tableGrid}
          style={{
            gridTemplateColumns: gridCols,
            msGridColumns: gridCols,
          }}
        >
          {data.rows.map((row, i) => (
            <React.Fragment key={row.key}>
              <div
                className={classNames(styles.rowCell, styles.rowHeader)}
                style={{
                  gridRow: i + 1,
                  gridColumn: 1,
                  msGridRow: i + 1,
                  msGridColumn: 1,
                }}
                data-test-id="category-row-title"
              >
                <Category {...row.category} />
              </div>
              {row.cells.map((cell, j) => (
                <div
                  key={cell.key}
                  className={classNames(styles.rowCell)}
                  style={{
                    gridRow: i + 1,
                    gridColumn: j + 2,
                    msGridRow: i + 1,
                    msGridColumn: j + 2,
                  }}
                  data-test-id="compareTable-value"
                >
                  {this.renderCell(cell)}
                </div>
              ))}
            </React.Fragment>
          ))}

          <Loaders {...data} />

          {
            <div
              className={styles.rowCell}
              style={{
                gridRow: `1 / span ${data.rows.length}`,
                msGridRow: 1,
                msGridRowSpan: data.rows.length,
                gridColumn: colCount,
                msGridColumn: colCount,
              }}
            >
              <AddColumn
                pageContext={this.props.pageContext}
                addVessel={this.addVessel}
                initialGroupId={this.previouslySelectedSelectedGroupId}
                initialVesselId={this.previouslySelectedSelectedVesselId}
                initialDate={this.previouslySelectedDate}
              />
            </div>
          }

          <div
            className={styles.rowCell}
            style={{
              gridRow: `1 / span ${data.rows.length}`,
              msGridRow: 1,
              msGridRowSpan: data.rows.length,
              gridColumn: colCount + 1,
              msGridColumn: colCount + 1,
            }}
          />
        </div>
      </>
    );
  }
}

function Loaders({ entries, rows }) {
  const rowCount = rows.length;
  return entries.map((entry, index) => {
    const rowIndex = entry.values.some((v) => v.id === "operation.distribution")
      ? 2
      : 1;
    const { isLoading, error } = entry;
    return (
      (isLoading || error) && (
        <div
          key={`loader${index}`}
          className={styles.loadingCell}
          style={{
            gridRow: `${rowIndex} / span ${rowCount}`,
            gridColumn: index + 2,
            msGridRowSpan: rowCount,
            msGridRow: `${rowIndex} / span ${rowCount}`,
            msGridColumn: index + 2,
          }}
        >
          <Loader isLoading={isLoading} error={error} />
        </div>
      )
    );
  });
}

function Category({ icon, name, description }) {
  return (
    <>
      <Icon icon={icon} size="l" />
      <h2 className={styles.rowHeaderText}>
        <div className={styles.rowHeaderName}>{name}</div>
        <div className={styles.rowHeaderDescription}>{description}</div>
      </h2>
    </>
  );
}

function ChartValue({ value, onOperationSelect }) {
  return (
    <DonutContainer
      donuts={[
        {
          data: {
            title: "Run",
            type: "run",
            unit: "%",
            data: value.points.map((point) => ({
              value: point.value,
              operationalProfile: {
                id: point.id,
                legend: point.label,
                category: point.category,
                profile: point.name,
              },
            })),
          },
        },
      ]}
      onOperationSelect={onOperationSelect}
    />
  );
}

function PerformanceValue({ value, actualValue, unit }) {
  const convertedMainValue = roundConvert(value, "%");
  const convertedSubValue = roundConvert(actualValue, unit);
  return (
    <>
      {!isNil(value) && (
        <div
          className={classNames(
            styles.valueContainer,
            value > 0 && styles.positiveTrend,
            value < 0 && styles.negativeTrend
          )}
        >
          <span className={styles.value}>{convertedMainValue.number}</span>
          <span className={styles.unit}>{convertedMainValue.unit}</span>
        </div>
      )}
      {!isNil(actualValue) && (
        <div className={styles.valueContainer}>
          {convertedSubValue.number} {convertedSubValue.unit}
        </div>
      )}
    </>
  );
}

function AggregatedValue({ value, unit }) {
  let convertedValue = roundConvert(value, unit);
  return (
    <div className={styles.valueContainer}>
      <span className={styles.value}>{convertedValue.number}</span>
      <span className={styles.unit}>{convertedValue.unit}</span>
    </div>
  );
}

function EeoiValue({ value, cargoType }) {
  return (
    <div className={styles.valueContainer}>
      {value && (
        <>
          <div>{value}</div>
          <div className={styles.unit}>{GetCargoUnit(cargoType)}</div>
        </>
      )}
      {!value && "N/A"}
    </div>
  );
}

function GroupedValues({ values }) {
  return (
    <div>
      {sortBy(values, "viewPosition").map((v) => {
        const value = roundConvert(v.value, v.unit);
        return (
          <div key={v.id} className={styles.valueContainer}>
            <div>{value.number}</div>
            <div className={styles.unit}>{value.unit}</div>
            <div className={styles.valueDescription}>({v.name})</div>
          </div>
        );
      })}
    </div>
  );
}
