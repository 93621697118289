import { createIndexedRequestReducer } from "./createRequestReducer";
import { FETCH_GAUGE } from "../actions/action.types";
import { createKey } from "../common/contexts";

export const gauges = createIndexedRequestReducer(
  FETCH_GAUGE,
  ({ performanceIndicatorId, context }) =>
    createKey(performanceIndicatorId, context)
);

export const getGauge = (state, performanceIndicatorId, context) =>
  state[createKey(performanceIndicatorId, context)];
