import fetch from "./action.fetch";
import { FETCH_TREND_FOR_VESSEL_ITEM } from "./action.types";
import { TREND } from "../api";

export const fetchTrendForVesselItem = (
  vesselId,
  vesselItemId,
  metricIds,
  dateRange,
  timeOffset,
  states
) =>
  fetch({
    url: TREND.getTrendForMetrics(
      vesselId,
      metricIds,
      [vesselItemId],
      dateRange,
      timeOffset,
      states
    ),
    actionType: FETCH_TREND_FOR_VESSEL_ITEM,
    actionData: { vesselItemId, metricIds, dateRange, states },
    key: `${FETCH_TREND_FOR_VESSEL_ITEM}_${vesselItemId}`,
  });
