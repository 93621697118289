import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header } from "../../../components/Header";
import DatePickerContainer from "../../../components/DatePicker/DatePickerContainer";
import VesselSelector from "../../../components/VesselSelector/VesselSelector";
import styles from "./MachineryReport.css";
import Grid from "../../../../onboard/components/Grid/Grid";
import GridCell from "../../../../onboard/components/Grid/GridCell";
import HeatMap from "../../../components/HeatMap/HeatMap";
import {
  fetchMachineryStatus,
  fetchVesselPeriodPositions,
  fetchVesselTotalPositions,
  setMachineryStatusMapProperties,
} from "../../../actions/action.machineryReport";
import DataListContainer from "../../../components/DataList/DataListContainer";
import DataListRow from "../../../components/DataList/DataListRow";
import DataListCell from "../../../components/DataList/DataListCell";
import Loader from "../../../../common/components/Loader/Loader";
import { formatDate } from "../../../common/dates";
import { roundNumberInt, formatNumber } from "../../../../common/numbers";
import {
  getLimitsForVesselInQuery,
  getVesselFilter,
  getGroupFilter,
  getDateRangeFilter,
  convertFiltersToQueryParams,
} from "../../../selectors/filters";
import { createSelector } from "reselect";
import { VIEWS } from "../../../config";
import { isEqual, get } from "lodash";
import ExportPopup from "../../Export/ExportPopup";
import { machineryReportExportConfigurations } from "./MachineryReportConfig";

class MachineryReportView extends React.Component {
  constructor(props) {
    super(props);
    this.handleMapChange = this.handleMapChange.bind(this);
    this.handleTotalMapChangeSize = this.handleTotalMapChangeSize.bind(this);
  }

  fetchMachineryReportData(filters, prevFilters = {}) {
    const { vesselFilter, dateFilter } = filters;
    if (
      filters.isValid &&
      (!isEqual(vesselFilter, prevFilters.vesselFilter) ||
        !isEqual(dateFilter, prevFilters.dateFilter))
    ) {
      this.props.fetchMachineryStatus(
        vesselFilter.value,
        dateFilter.value.range,
        parseInt(dateFilter.value.timeOffset)
      );
      this.props.setMachineryStatusMapProperties({
        width: this.totalMap.width,
        height: this.totalMap.height,
        vesselId: vesselFilter.value,
        range: dateFilter.value.range,
        timeOffset: parseInt(dateFilter.value.timeOffset),
      });
      this.props.fetchVesselTotalPositions(vesselFilter.value);
      this.props.fetchVesselPeriodPositions(
        vesselFilter.value,
        dateFilter.value.range,
        parseInt(dateFilter.value.timeOffset)
      );
      this.props.fetchMachineryStatus(
        vesselFilter.value,
        dateFilter.value.range,
        parseInt(dateFilter.value.timeOffset)
      );
    }
  }

  componentDidMount() {
    const { filters } = this.props;
    if (filters.isValid) {
      this.fetchMachineryReportData(filters);
    } else {
      this.props.queryContext.navigate(
        VIEWS.machineryReport.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters.isValid) {
      this.fetchMachineryReportData(filters, prevProps.filters);
    } else {
      this.props.queryContext.navigate(
        VIEWS.machineryReport.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  handleMapChange(args) {
    this.props.setMachineryStatusMapProperties({
      zoom: args.zoom,
      center: args.center,
      vesselId: this.props.filters.vesselFilter.value,
      range: this.props.filters.dateFilter.value.range,
      timeOffset: parseInt(this.props.filters.dateFilter.value.timeOffset),
    });
  }

  handleTotalMapChangeSize(args) {
    this.props.setMachineryStatusMapProperties({
      ...args,
      vesselId: this.props.filters.vesselFilter.value,
      range: this.props.filters.dateFilter.value.range,
      timeOffset: parseInt(this.props.filters.dateFilter.value.timeOffset),
    });
  }

  renderValueCell(value, unit, description) {
    return (
      <DataListCell>
        <div className={styles.valueCellContainer}>
          <div className={styles.value}>
            {formatNumber(value)}
            <span className={styles.unit}> {unit}</span>
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      </DataListCell>
    );
  }

  renderLoadProfile(loadProfile, description) {
    return (
      <DataListCell>
        <div className={styles.profileCellContainer}>
          <div className={styles.profileValues}>
            {loadProfile.values.map((histogramValue) => {
              return (
                <div
                  key={`profile_${histogramValue.key}`}
                  className={styles.profileValue}
                >
                  <div className={styles.range}>
                    {get(histogramValue, "key")}
                  </div>
                  <div className={styles.value}>
                    {roundNumberInt(get(histogramValue, "value", 0))}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      </DataListCell>
    );
  }

  renderItems(title, items, isLoading, icon, error) {
    if (isLoading || error) {
      return <Loader className={styles.loader} error={error} />;
    }
    return (
      <DataListContainer title={title}>
        {(isLoading || error) && (
          <Loader className={styles.loader} error={isLoading ? null : error} />
        )}
        {!isLoading &&
          items.map((x, i) => (
            <DataListRow
              title={x.name}
              description={x.description}
              icon={icon}
              key={`engine_row_${i}`}
            >
              {this.renderValueCell(x.runHourTotal, "h", "Runtime Total")}
              {this.renderLoadProfile(
                x.loadProfileTotal,
                "Load Profile Total (%)"
              )}
              {this.renderValueCell(x.runHourPeriod, "h", "Runtime Period")}
              {this.renderLoadProfile(
                x.loadProfilePeriod,
                "Load Profile Period (%)"
              )}
            </DataListRow>
          ))}
      </DataListContainer>
    );
  }

  render() {
    const {
      appConfig,
      vesselDateLimits,
      machineryReport,
      filters: { vesselFilter, dateFilter, isValid },
      queryContext,
    } = this.props;

    const {
      startTime,
      zoom,
      defaultZoom,
      center,
      defaultCenter,
      isLoading,
      error,
    } = machineryReport;

    const mapsAreLoading =
      machineryReport.totalPositions.isLoading ||
      machineryReport.periodPositions.isLoading;

    return (
      <div className={styles.outerContainer}>
        <Header title="Machinery Status" contentDistribution="space-between">
          <div className={styles.headerContainer}>
            {isValid && <VesselSelector />}
          </div>
          <div className={styles.rightHeaderContainer}>
            <div className={styles.datePickerContainer}>
              {isValid && (
                <DatePickerContainer
                  vesselId={vesselFilter.value}
                  current={dateFilter.value}
                  limits={vesselDateLimits}
                  onChange={queryContext.setDate}
                />
              )}
            </div>{" "}
            {isValid && (
              <ExportPopup
                vesselId={vesselFilter.value}
                dateRange={dateFilter.value.range}
                exportConfigurations={machineryReportExportConfigurations}
              />
            )}
          </div>
        </Header>
        <div className={styles.contentContainer}>
          <Grid
            templateColumn={"1fr 500px"}
            templateRow={"1fr 1fr 30px"}
            gap={"8px"}
            height={"calc(100vh - 5rem - 16px)"}
          >
            <GridCell colStart={1} rowStart={1}>
              {this.renderItems(
                "Engines",
                machineryReport.engines,
                isLoading,
                "engine",
                error
              )}
            </GridCell>
            <GridCell colStart={2} rowStart={1}>
              <HeatMap
                apiKey={appConfig.GOOGLE_MAPS_API_KEY}
                label={"Total"}
                ref={(map) => (this.totalMap = map)}
                zoom={zoom}
                defaultZoom={defaultZoom}
                center={center}
                defaultCenter={defaultCenter}
                data={machineryReport.totalPositions.data}
                onChange={this.handleMapChange}
                onChangeSize={this.handleTotalMapChangeSize}
                isLoading={mapsAreLoading}
              />
            </GridCell>
            <GridCell colStart={1} rowStart={2}>
              {this.renderItems(
                "Propulsion",
                machineryReport.propulsion,
                isLoading,
                "propulsion",
                error
              )}
            </GridCell>
            <GridCell colStart={2} rowStart={2}>
              <HeatMap
                apiKey={appConfig.GOOGLE_MAPS_API_KEY}
                label={"Period"}
                zoom={zoom}
                defaultZoom={defaultZoom}
                center={center}
                defaultCenter={defaultCenter}
                data={machineryReport.periodPositions.data}
                onChange={this.handleMapChange}
                isLoading={mapsAreLoading}
              />
            </GridCell>
            <GridCell colStart={1} rowStart={3} colSpan={2}>
              {startTime && (
                <div className={styles.disclaimer}>
                  Disclaimer: Load Profile Total (%) is calculated from starting
                  date {formatDate(startTime)}
                </div>
              )}
            </GridCell>
          </Grid>
        </div>
      </div>
    );
  }
}

const loadProfilePropType = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

const machineryItemPropType = PropTypes.arrayOf(
  PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    runHourPeriod: PropTypes.number,
    runHourTotal: PropTypes.number,
    viewPosition: PropTypes.number,
    loadProfilePeriod: PropTypes.shape(loadProfilePropType),
    loadProfileTotal: PropTypes.shape(loadProfilePropType),
  })
);

MachineryReportView.propTypes = {
  machineryReport: PropTypes.shape({
    engines: machineryItemPropType,
    propulsion: machineryItemPropType,
  }),
};

const getMachineryReportFilters = createSelector(
  [getVesselFilter, getGroupFilter, getDateRangeFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid:
        vesselFilter.isValid && groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const filters = getMachineryReportFilters(state, props);
  return {
    appConfig: state.appConfig,
    filters,
    queryContext: props.queryContext,
    machineryReport: state.machineryReport,
    vesselDateLimits: getLimitsForVesselInQuery(state, props),
  };
};

const mapDispatchToProps = {
  setMachineryStatusMapProperties,
  fetchVesselTotalPositions,
  fetchVesselPeriodPositions,
  fetchMachineryStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MachineryReportView);
