import React from "react";
import Popup from "../../../components/Popup/Popup";
import FormGroup from "../../../components/Form/FormGroup/FormGroup";
import FormTextBox from "../../../components/Form/FormTextBox/FormTextBox";
import { connect } from "react-redux";
import {
  beginDeleteFile,
  cancelFile,
  saveFile,
  setEditFile,
  setEditFileFolderId,
  setEditFilename,
  setEditPolicyChecked,
  setEditVesselId,
} from "../../../actions/action.documents";
import { FormCheckBox, FormSelectBox } from "../../../components/Form";
import FormFileUpload from "../../../components/Form/FormFileUpload/FormFileUpload";
import styles from "./DocumentEditDialog.css";
import Progress from "../../../../common/components/Progress/Progress";
import Button from "../../../../common/components/Button/Button";
import { getVesselsInCompany } from "../../../reducers/reducer.pageContext";
import { values } from "lodash";

class DocumentEditDialog extends React.Component {
  constructor(props) {
    super(props);

    this.onFilenameChange = this.onFilenameChange.bind(this);
    this.onVesselChange = this.onVesselChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onFolderChange = this.onFolderChange.bind(this);

    this.onHide = this.onHide.bind(this);
    this.onOkClick = this.onOkClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onPolicyChange = this.onPolicyChange.bind(this);
  }

  onFilenameChange(_name, fileName) {
    this.props.setEditFilename(fileName);
  }

  onHide() {
    this.cancel();
  }

  onVesselChange(_name, vessel) {
    this.props.setEditVesselId(vessel ? vessel.id : null);
  }

  onFileChange(_name, file, target) {
    this.props.setEditFile(file, target);
  }

  onFolderChange(_name, folder) {
    this.props.setEditFileFolderId(folder ? folder.id : null);
  }

  onPolicyChange(_name, checked) {
    this.props.setEditPolicyChecked(checked);
  }

  onCancelClick() {
    this.cancel();
  }

  onOkClick() {
    if (!!this.props.editFile && this.props.editFile.saving === true) return;
    this.props.saveFile();
  }

  onDeleteClick(e) {
    const { editFile } = this.props;
    e.preventDefault();
    this.props.beginDeleteFile(editFile.id);
  }

  cancel() {
    if (!!this.props.editFile && this.props.editFile.saving === true) return;
    this.props.cancelFile();
  }

  renderContent() {
    const { editFile, pageContext, folders, selectedCompanyId } = this.props;
    if (editFile.isSaving === true) {
      return (
        <div className={styles.progressContainer}>
          <div className={styles.progressBarContainer}>
            <Progress value={editFile.progress} />
          </div>
          <div className={styles.progressBarText}>Saving...</div>
        </div>
      );
    }
    const vessels = getVesselsInCompany({
      companyId: selectedCompanyId,
      vessels: values(pageContext.vessels),
      vesselGroupVessels: values(pageContext.vesselGroupVessels),
      groups: values(pageContext.groups),
    });
    return (
      <div className={styles.contentContainer}>
        <FormGroup>
          <FormTextBox
            name={"filename"}
            label={"File name"}
            labelPosition={"top"}
            value={editFile.name}
            onChange={this.onFilenameChange}
            required={true}
            maxLength={255}
          />
          <FormSelectBox
            name={"folder"}
            label={"Folder"}
            onChange={this.onFolderChange}
            options={folders}
            optionValKey={"id"}
            optionLabelKey={"name"}
            selected={folders.find(
              (f) => f.id === editFile.vesselDocumentFolderId
            )}
            required={true}
          />
          <FormSelectBox
            name={"vessel"}
            label={"Vessel"}
            options={vessels}
            optionValKey={"id"}
            optionLabelKey={"name"}
            selected={vessels.find((v) => v.id === editFile.vesselId)}
            onChange={this.onVesselChange}
          />
          <FormFileUpload
            name={"file"}
            label={"Upload new file"}
            onChange={this.onFileChange}
          />
          <div className={styles.policyText}>
            <h1>Important:</h1>
            <p>
              You must not share any information, as an attachment or as text,
              which is subject to Security, Export Control, IP or Data
              Protection controls.
            </p>
          </div>
          <FormCheckBox
            name={"policy"}
            label={"I accept"}
            value={editFile.policyChecked}
            onChange={this.onPolicyChange}
          />
        </FormGroup>
        {!editFile.new && (
          <div className={styles.deleteContainer}>
            <Button
              value={"Delete file"}
              clickAction={this.onDeleteClick}
              type={"alert"}
              width={"100%"}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { editFile } = this.props;
    const visible = editFile !== undefined && editFile !== null;
    const isSaving = editFile && editFile.isSaving === true;
    return (
      <Popup
        visible={visible}
        target={"popupContainer"}
        title={editFile && editFile.new === true ? "Add new file" : "Edit file"}
        onHide={this.onHide}
        buttons={[
          {
            label: "Cancel",
            action: this.onCancelClick,
            disabled: isSaving === true,
          },
          {
            label: "Ok",
            action: this.onOkClick,
            disabled:
              (visible && !(editFile.isValid && editFile.hasChanges)) ||
              isSaving === true,
          },
        ]}
      >
        {visible && this.renderContent()}
      </Popup>
    );
  }
}

const mapStateToDispatch = (state) => ({
  editFile: state.documents.editFile,
  folders: state.documents.folders,
  documents: state.documents.documents,
  selectedCompanyId: state.documents.selectedCompanyId,
  pageContext: state.pageContext,
});

const mapPropsToDispatch = {
  cancelFile,
  saveFile,
  setEditFilename,
  setEditVesselId,
  setEditFile,
  setEditFileFolderId,
  setEditPolicyChecked,
  beginDeleteFile,
};

export default connect(
  mapStateToDispatch,
  mapPropsToDispatch
)(DocumentEditDialog);
