import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getNonEmptyVesselGroups,
  getSelectedGroup,
  getSelectedVessel,
  getVesselsInSelectedGroup,
  getFirstVesselInGroup,
  filterVesselMenuAccess,
} from "../../reducers/reducer.pageContext";
import MasterChildSelect from "../MasterChildSelect/MasterChildSelect";
import { useQueryContext } from "../../providers/QueryContextProvider";

const mapStateToProps = (state, { queryContext }) => {
  const groups = getNonEmptyVesselGroups(state.pageContext) || [];
  const vessels = filterVesselMenuAccess(
    getVesselsInSelectedGroup({
      ...state.pageContext,
      selectedGroupId: queryContext.groupId,
    }) || [],
    queryContext.pathname,
    state.user
  );

  return {
    groups,
    vessels,
    pageContext: state.pageContext,
    selectedGroup: getSelectedGroup({
      selectedGroupId: queryContext.groupId,
      groups,
    }),
    selectedVessel: getSelectedVessel({
      selectedVesselId: queryContext.vesselId,
      vessels,
    }),
  };
};

const VesselSelectorChild = connect(
  mapStateToProps,
  {}
)((props) => {
  const {
    groups,
    vessels,
    selectedGroup,
    selectedVessel,
    queryContext,
    pageContext,
  } = props;

  const handleGroupSelected = (group) => {
    const firstVesselInGroup = getFirstVesselInGroup(group.id, pageContext);
    queryContext.setSelectedGroupId(group, firstVesselInGroup.id);
  };

  return (
    <MasterChildSelect
      masterOptions={groups}
      childOptions={vessels}
      selectedMaster={selectedGroup}
      selectedChild={selectedVessel}
      onMasterSelect={handleGroupSelected}
      onSelect={queryContext.setSelectedVesselId}
    />
  );
});

VesselSelectorChild.propTypes = {
  groups: PropTypes.array,
  vessels: PropTypes.array,
  selectedGroup: PropTypes.object,
  selectedVessel: PropTypes.object,
};

const VesselSelector = () => {
  const queryContext = useQueryContext();
  return <VesselSelectorChild queryContext={queryContext} />;
};

export default VesselSelector;
