import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";
import { getOffset } from "../../common/dom";
import { cssParam } from "../../../common/parameters";
import { isNil, isEmpty } from "lodash";

const getOptionBackground = (
  { isFocused, isSelected },
  whenFocused,
  whenSelected,
  defaultValue
) => {
  if (isFocused) return whenFocused;
  return isSelected ? whenSelected : defaultValue;
};

export default class SelectBox extends React.Component {
  components = {
    dropdownIndicator: (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <svg
            style={{
              fill: "white",
              width: "1rem",
              height: "1rem",
            }}
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            enableBackground="new 0 0 16 16"
            xmlSpace="preserve"
          >
            <polygon points="8,12.3 0,4.3 0.5,3.7 8,11.2 15.5,3.7 16,4.3 " />
          </svg>
        </components.DropdownIndicator>
      );
    },
    clearIndicator: (props) => {
      return (
        <components.ClearIndicator {...props}>
          <svg
            style={{
              fill: "white",
              width: "1rem",
              height: "1rem",
            }}
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            enableBackground="new 0 0 16 16"
            xmlSpace="preserve"
          >
            <line
              x1="2"
              y1="2"
              x2="12"
              y2="12"
              style={{ stroke: "rgb(200,200,200)", strokeWidth: "1" }}
            />
            <line
              x1="12"
              y1="2"
              x2="2"
              y2="12"
              style={{ stroke: "rgb(200,200,200)", strokeWidth: "1" }}
            />
          </svg>
        </components.ClearIndicator>
      );
    },
  };

  styles = ({ minHeight }) => ({
    container: (provided, { selectProps }) => ({
      ...provided,
      background: "none",
      fontSize: cssParam("--font-size--m"),
      minWidth: "15rem",
      paddingBottom: "2px",
      paddingTop: "2px",
      opacity: selectProps.isDisabled ? 0.5 : 1,
    }),
    control: (_provided, _, dataInvalid) => ({
      alignItems: "center",
      border: "2px solid",
      borderColor: cssParam("--light-grey"),
      borderRadius: "1.5rem",
      boxShadow: dataInvalid
        ? `inset 0 0 1px 1px ${cssParam("--bright-red")}`
        : "unset",
      display: "flex",
      fontWeight: 400,
      minHeight: minHeight || "48px",
      padding: "0 1rem 0 0.5rem",
      "&:hover": {
        borderColor: "white",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: cssParam("--white"),
    }),
    dropdownIndicator: (_provided, { selectProps }) => ({
      transform: selectProps.menuIsOpen && "rotate(180deg)",
      transition: "transform 250ms",
    }),
    indicatorContainer: () => ({
      flex: "unset !important",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: cssParam("--dark-grey"),
      border: `1px solid ${cssParam("--content-card-bg")}`,
      borderRadius: "0.5rem",
      boxShadow: "0 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.2)",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    multiValue: () => ({
      alignItems: "center",
      background: cssParam("--mid-grey"),
      border: "none",
      borderRadius: "1rem",
      display: "flex",
      margin: "2px 5px 2px 0",
      padding: "0.25rem 0",
    }),
    multiValueLabel: () => ({
      color: "white",
      padding: "0 0.4rem 0 0.5rem",
    }),
    multiValueRemove: () => ({
      background: "none",
      border: "none",
      color: cssParam("--light-grey"),
      display: "inline-flex",
      padding: "0 0.5rem 0 0",
      "&:focus": {
        color: "white",
      },
      "&:hover": {
        color: "white",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: getOptionBackground(
        state,
        "#a7a7a7",
        "rgba(255, 255, 255, 0.02)",
        "unset"
      ),
      borderTop: "1px solid rgba(0, 0, 0, 0.4)",
      color: "white",
      padding: "1rem",
      "&:first-of-type": {
        borderTop: "none",
        borderTopLeftRadius: "0.25rem",
        borderTopRightRadius: "0.25rem",
      },
      "&:last-child": {
        borderBottomLeftRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
      position: "relative",
      margin: 0,
      lineHeight: "1.2rem",
    }),
    valueContainer: (provided, { isMulti }) => ({
      ...provided,
      flexGrow: isMulti ? 2 : 1,
      flexWrap: isMulti ? "wrap" : "nowrap",
      paddingBottom: 0,
      paddingTop: 0,
    }),
  });

  constructor(props) {
    super(props);
    this.state = {};
    this.calcMaxHeight = this.calcMaxHeight.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  calcMaxHeight() {
    if (!this.el) {
      return;
    }
    const { top } = getOffset(this.el);
    const { height } = this.el.getBoundingClientRect();
    const clientHeight = document.documentElement.clientHeight;
    const maxHeight = Math.max(200, clientHeight - top - height);
    this.setState({ menuStyle: { maxHeight: `calc(${maxHeight}px - 2rem)` } });
  }

  onChange(selected) {
    if (this.props.onSelect) {
      // Fix for Select component - get correct value. After upgrading from v4 to v5, plain elements not supportes in multi.
      const value = this.checkMultiNotCompatible()
        ? selected.map((o) => o.value)
        : selected;
      this.props.onSelect(value);
    }
  }

  getSelectedValue = (
    options,
    selected,
    selectedVal,
    selectedValKey,
    isMulti
  ) => {
    if (isNil(selectedValKey)) {
      return selected;
    }

    if (isNil(selectedVal) || isEmpty(options)) {
      return null;
    }

    if (isMulti) {
      return options.filter((o) => o[selectedValKey] === selectedVal) || null;
    }

    return options.find((o) => o[selectedValKey] === selectedVal) || null;
  };

  checkMultiNotCompatible() {
    const { optionValKey, multi } = this.props;

    return multi && !optionValKey;
  }

  render() {
    const dataInvalid = !!this.props.required && !this.props.selected;
    const { selectedVal, selectedValKey, multi } = this.props;

    let { options, optionValKey, optionLabelKey, selected } = this.props;

    // Fix for Select component. After upgrading from v4 to v5, plain elements not supportes in multi.
    if (this.checkMultiNotCompatible() && !isEmpty(options)) {
      options = options.map((o) => ({ value: o, label: o }));
      if (!isEmpty(selected) && typeof selected[0] !== "object")
        selected = selected.map((o) => ({ value: o, label: o }));
      optionValKey = "value";
      optionLabelKey = "label";
    }

    const value = this.getSelectedValue(
      options,
      selected,
      selectedVal,
      selectedValKey,
      multi
    );
    const styles = this.styles(this.props);
    return (
      <div
        className={this.props.className}
        ref={(el) => (this.el = el)}
        data-invalid={!!this.props.required && !this.props.selected}
        data-testid={this.props.testId}
      >
        <Select
          autoload={false}
          cache={false}
          isClearable={this.props.clearable === true}
          components={{
            ClearIndicator: this.components.clearIndicator,
            DropdownIndicator: this.components.dropdownIndicator,
            IndicatorSeparator: () => null,
          }}
          isDisabled={this.props.disabled}
          getOptionLabel={(option) => {
            if (optionLabelKey) {
              return option[optionLabelKey];
            } else if (optionValKey) {
              return option[optionValKey];
            }
            return option;
          }}
          getOptionValue={(option) =>
            optionValKey ? option[optionValKey] : option
          }
          menuPlacement="auto"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuStyle={this.state.menuStyle}
          isMulti={this.props.multi}
          noResultsText={this.props.isLoading ? "Loading..." : "No results"}
          onChange={this.onChange}
          onOpen={this.calcMaxHeight}
          options={options}
          placeholder={this.props.placeholder}
          required={this.props.required}
          styles={{
            container: styles.container,
            control: (provided, state) =>
              styles.control(provided, state, dataInvalid),
            input: styles.input,
            dropdownIndicator: styles.dropdownIndicator,
            indicatorsContainer: styles.indicatorsContainer,
            menu: styles.menu,
            menuPortal: styles.menuPortal,
            multiValue: styles.multiValue,
            multiValueLabel: styles.multiValueLabel,
            multiValueRemove: styles.multiValueRemove,
            option: styles.option,
            singleValue: styles.singleValue,
            valueContainer: styles.valueContainer,
          }}
          value={value}
        />
      </div>
    );
  }
}

SelectBox.propTypes = {
  options: PropTypes.array.isRequired,
  optionLabelKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.any,
  selectedVal: PropTypes.any,
  selectedValKey: PropTypes.string,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  minHeight: PropTypes.string,
  testId: PropTypes.string,
};
