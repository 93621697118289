import {
  takeEvery,
  put,
  fork,
  all,
  takeLeading,
  getContext,
  call,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { get } from "lodash";
import { getPayload } from "../../common/jsonMetadata";
import { ADMIN_FUELTYPES, ADMIN_ITEMTYPES, vesselConfigApi } from "../../api";

///
/// INIT
///

export function* initVesselItems({ vesselId }) {
  yield fork(fetchDataWatcher);
  yield put({ type: vesselConfig.vesselItems.FETCH, vesselId: vesselId });
  yield fork(editVesselItemWatcher);
  yield fork(saveVesselItemWatcher, vesselId);
  yield fork(deleteWatcher);
}

///
/// FETCH
///

function* fetchDataWatcher() {
  yield takeEvery(vesselConfig.vesselItems.FETCH, fetchData);
}

function* fetchData({ vesselId }) {
  try {
    const http = yield getContext("http");
    const [itemTypesRes, vesselItemsRes, fuelTypesRes, vesselDetailsRes] =
      yield all([
        call(http.get, ADMIN_ITEMTYPES.get()),
        call(http.get, vesselConfigApi.vesselItems.get(vesselId)),
        call(http.get, ADMIN_FUELTYPES.get()),
        call(http.get, vesselConfigApi.vesselDetails.get(vesselId)),
      ]);
    yield put({
      type: vesselConfig.vesselItems.FETCH_SUCCESS,
      itemTypes: itemTypesRes.data,
      vesselItems: vesselItemsRes.data,
      fuelTypes: fuelTypesRes.data,
      vesselDetails: vesselDetailsRes.data,
    });
  } catch (err) {
    yield put({
      type: vesselConfig.vesselItems.FETCH_ERROR,
      error: getErrorMessage(err),
    });
  }
}

///
/// EDIT
///

function* editVesselItemWatcher() {
  yield takeEvery(vesselConfig.vesselItems.BEGIN_EDIT, editVesselItemSaga);
}

function* editVesselItemSaga({ id }) {
  try {
    const http = yield getContext("http");
    const [itemRes, metricsRes] = yield all([
      call(http.get, vesselConfigApi.vesselItems.getById(id)),
      call(http.get, vesselConfigApi.vesselItems.getMetrics(id)),
    ]);
    yield put({
      type: vesselConfig.vesselItems.BEGIN_EDIT_SUCCESS,
      id,
      data: itemRes.data,
      metrics: metricsRes.data,
    });
  } catch (error) {
    yield put({
      type: vesselConfig.vesselItems.BEGIN_EDIT_ERROR,
      error: getErrorMessage(error),
      id,
    });
  }
}

///
/// Save
///

function* saveVesselItemWatcher(vesselId) {
  yield takeEvery(vesselConfig.vesselItems.SAVE, saveSaga, vesselId);
}

function* saveSaga(vesselId, { id, form, metrics }) {
  try {
    const isNew = !id;
    const vi = yield call(saveVesselItemSaga, { id, vesselId, form });
    if (!isNew) {
      yield call(saveVesselItemMetricsSaga, { id, vesselId, metrics });
    }
    yield put({
      type: vesselConfig.vesselItems.SAVE_SUCCESS,
      isNew: isNew,
      data: vi,
    });
  } catch (error) {
    yield put({
      type: vesselConfig.vesselItems.SAVE_ERROR,
      error: getErrorMessage(error),
      id,
    });
  }
}

function* saveVesselItemSaga({ id, vesselId, form }) {
  const http = yield getContext("http");
  const data = prepareVesselItemPayload({ ...getPayload(form), vesselId });

  let res;
  if (id) {
    res = yield call(http.put, vesselConfigApi.vesselItems.put(id), data);
  } else {
    res = yield call(http.post, vesselConfigApi.vesselItems.post(), data);
  }

  if (id) {
    return {
      ...data,
      id,
    };
  }
  return {
    ...res.data,
  };
}

function prepareVesselItemPayload(data) {
  const dateFields = ["ehmStartTime"];
  const payload = {
    ...data,
  };
  for (const dateField of dateFields) {
    if (payload[dateField]) {
      payload[dateField] = payload[dateField].toISOString();
    }
  }
  return payload;
}

function* saveVesselItemMetricsSaga({ id, metrics }) {
  const http = yield getContext("http");
  if (!metrics || !metrics.data) {
    return;
  }
  const metricData = metrics.data.map((x) => ({
    metricId: x.metricId,
    vesselItemId: id,
    showOperation: x.showOperation,
    showOperationDetail: x.showOperationDetail,
    showTrend: x.showTrend,
    showCompare: x.showCompare,
  }));

  yield call(http.put, vesselConfigApi.vesselItems.putMetrics(id), metricData);
}

///
/// DELETE
///

function* deleteWatcher() {
  yield takeLeading(vesselConfig.vesselItems.CONFIRM_DELETE, deleteVesselItem);
}

function* deleteVesselItem({ id }) {
  const http = yield getContext("http");

  try {
    yield call(http.delete, vesselConfigApi.vesselItems.delete(id));
    yield put({
      type: vesselConfig.vesselItems.CONFIRM_DELETE_SUCCESS,
      id,
    });
  } catch (error) {
    yield put({
      type: vesselConfig.vesselItems.CONFIRM_DELETE_ERROR,
      id,
      error: getErrorMessage(error) || "Error deleting vessel item",
    });
  }
}

function getErrorMessage(error) {
  return (
    get(error, ["response", "data", "message"]) ||
    get(error, "message") ||
    error
  );
}
