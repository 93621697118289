import React from "react";
import PropTypes from "prop-types";
import styles from "./CheckboxGroup.css";
import RadioCheckbox from "../RadioCheck/RadioCheckbox";

export default class CheckboxGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCheckboxChecked = this.handleCheckboxChecked.bind(this);
  }

  handleCheckboxChecked(item, event) {
    const { onItemChecked } = this.props;
    if (onItemChecked) {
      onItemChecked(item, event);
    }
  }

  static renderCheckboxLabel(label, subLabel) {
    return (
      <span className={styles.checkboxLabel}>
        {label}{" "}
        {subLabel && (
          <span className={styles.checkboxSubLabel}>{subLabel}</span>
        )}
      </span>
    );
  }

  renderCheckboxItems() {
    const { items } = this.props;
    if (!items) return <div />;
    return items.map((item, index) => {
      return (
        <div key={`checkbox_${index}`} className={styles.checkbox}>
          <RadioCheckbox
            horizontalStretch={true}
            label={CheckboxGroup.renderCheckboxLabel(item.label, item.subLabel)}
            checked={item.checked}
            onCheck={(e) => this.handleCheckboxChecked(item, e)}
          />
        </div>
      );
    });
  }

  render() {
    const { label, items } = this.props;
    return (
      <div>
        <span className={styles.mainLabel}>{label}</span>
        {items && items.length > 0 && (
          <div className={styles.checkboxContainer}>
            {this.renderCheckboxItems()}
          </div>
        )}
      </div>
    );
  }
}

CheckboxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      subLabel: PropTypes.string,
      checked: PropTypes.bool.isRequired,
    })
  ),
  onItemChecked: PropTypes.func,
};
