import { convertFieldsToKeyValues } from "./forms";
import getItemTypeMetadata from "./vesselItemJsonMetadata";
import { get, includes, isFinite, isFunction, toNumber } from "lodash";
import {
  isEmail,
  isInteger,
  isNumber,
  isPositiveInteger,
  isPositiveNumber,
} from "./forms.validators";

export const getPayload = (form) => {
  const { fields, metadataFields } = form;
  let jsonMetadata = getJsonMetadata(metadataFields, fields);
  let formFields = convertFieldsToKeyValues(form);
  formFields.jsonMetadata = JSON.stringify(jsonMetadata);
  return formFields;
};

const getFieldValue = (metadataField, fields) => {
  let fieldValue = get(fields, metadataField.name + ".value", "");
  let includeField = fieldValue !== "";
  if (
    includes(
      ["number", "positiveNumber", "integer", "positiveInteger"],
      metadataField.type
    )
  ) {
    fieldValue = fieldValue !== "" && toNumber(fieldValue);
    includeField = isFinite(fieldValue);
  } else if (metadataField.type === "bool") {
    fieldValue =
      get(fields, metadataField.name + ".value", false).toString() === "true";
    includeField = fieldValue;
  }

  return { value: fieldValue, includeField };
};

export const getJsonMetadata = (metadataFields, fields) => {
  return metadataFields
    .map((metadataField) => {
      return {
        name: metadataField.name,
        ...getFieldValue(metadataField, fields),
      };
    })
    .filter((x) => x.includeField === true)
    .reduce((result, item) => {
      result[item.name] = item.value;
      return result;
    }, {});
};

export const getMetadataFields = (itemTypeId, data, fuelTypeUnitKg) => {
  return (getItemTypeMetadata(itemTypeId, fuelTypeUnitKg) || []).map((x) => {
    if (isFunction(x.defaultValue)) {
      return {
        ...x,
        defaultValue: x.defaultValue(data),
        validation: addTypeValidation(x.validation),
      };
    } else {
      return {
        ...x,
        validation: addTypeValidation(x.validation, x.type),
      };
    }
  });
};

export const convertMetadataToFields = (metadataFields, jsonMetadata) => {
  let metadataFormFields =
    metadataFields &&
    metadataFields.reduce((acc, field) => {
      // If stored jsonMetadata has this property, do not get default from metadataFields.
      const hasProperty =
        (jsonMetadata &&
          Object.hasOwnProperty.call(jsonMetadata, field.name)) ||
        false;
      let defaultValue = get(field, "defaultValue");
      let value = get(
        jsonMetadata,
        field.name,
        (!hasProperty && defaultValue) || ""
      );
      const validationProperties = {};
      if ("validation" in field) {
        validationProperties.validation = field.validation;
      }
      if ("validationLabel" in field) {
        validationProperties.validationLabel = field.validationLabel;
      }
      if ("validationMessage" in field) {
        validationProperties.validationMessage = field.validationMessage;
      }
      if ("isValid" in field) {
        validationProperties.isValid = field.isValid;
      }
      return {
        ...acc,
        [field.name]: {
          value,
          ...validationProperties,
        },
      };
    }, {});
  return metadataFormFields || {};
};

export const addTypeValidation = (validation = [], type = undefined) => {
  switch (type) {
    case "email":
      return [...validation, isEmail];
    case "number":
      return [...validation, isNumber];
    case "positiveNumber":
      return [...validation, isPositiveNumber];
    case "integer":
      return [...validation, isInteger];
    case "positiveInteger":
      return [...validation, isPositiveInteger];
    default:
      return validation;
  }
};

export const getUpdatedMetadataFormFields = (
  form,
  itemTypeId,
  metadataFields,
  fuelTypeUnitKg
) => {
  let newMetadataFields =
    metadataFields || getMetadataFields(itemTypeId, fuelTypeUnitKg);
  return {
    ...form,
    fields: {
      ...form.fields,
      ...convertMetadataToFields(newMetadataFields, form.jsonMetadata),
    },
    metadataFields: newMetadataFields,
  };
};
