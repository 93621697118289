import React from "react";
import {
  operationColor,
  OperationId,
  operationLegend,
  operationTextColor,
} from "../../operation/operationId";
import styles from "./OperationCircle.css";
import { color } from "../../colors";

interface Props {
  operationId: OperationId;
}

export const OperationCircle = ({ operationId }: Props) => {
  return (
    <div
      className={styles.circle}
      style={{
        backgroundColor: operationColor[operationId],
        color: operationTextColor[operationId] ?? color("--content-card-bg"),
      }}
    >
      {operationLegend[operationId]}
    </div>
  );
};
