import t from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import DonutContainer from "../components/DonutContainer";
import { getDonuts } from "../reducers/reducer.donuts";
import { fetchDonuts } from "../actions/action.fetchDonuts";
import { isContextChanged, isContextValid } from "../common/contexts";

class ConfiguredDonutContainer extends Component {
  componentDidMount() {
    if (isContextValid(this.props.context)) {
      this.props.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isContextValid(this.props.context) &&
      (isContextChanged(this.props.context, prevProps.context) ||
        this.props.config !== prevProps.config)
    ) {
      this.props.fetch();
    }
  }

  render() {
    return <DonutContainer donuts={this.props.donuts} testId="widget-donuts" />;
  }
}

ConfiguredDonutContainer.propTypes = {
  config: t.shape({
    params: t.shape({
      itemTypeMetricIds: t.array.isRequired,
    }).isRequired,
  }),
  context: t.object.isRequired,
};

export default connect(
  (state, { config, context }) => ({
    donuts: getDonuts(state.donuts, config.params.itemTypeMetricIds, context),
  }),
  (dispatch, { config, context }) => ({
    fetch: () =>
      dispatch(fetchDonuts(config.params.itemTypeMetricIds, context)),
  })
)(ConfiguredDonutContainer);
