import { OPERATIONAL_PROFILE_PLAN_ACTIONS } from "../actions/action.types";
import { prepareDonutData } from "../common/donuts";

const operationalProfile = (
  state = {
    isLoading: true,
  },
  action = {}
) => {
  switch (action.type) {
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_DATA:
      return {
        ...state,
        isLoading: true,
        data: undefined,
      };
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: prepareDonutData(action.data),
      };
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default operationalProfile;
