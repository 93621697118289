import {
  admin,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE,
} from "../../../actions/action.types";
import { checkFormValidation } from "../../../common/forms";

import createReducer from "../../createReducer";

export const defaultTemplate = {
  type: "RemoteAccess",
  name: "Remote Access",
};

export const availableTemplates = [
  defaultTemplate,
  {
    type: "UpdateSystem",
    name: "Update System",
  },
];

const initialState = {
  remoteAccessForm: {
    isSaving: false,
    isValid: false,
    hasChanges: false,
    fields: {
      reason: { isValid: false },
      duration: { isValid: false },
    },
  },
  updateSystemForm: {
    isSaving: false,
    isValid: true,
  },
  currentAction: undefined,
  selectedTemplate: defaultTemplate,
  vessel: {},
};

const actionByType = (actions, selectedTemplate) =>
  actions &&
  selectedTemplate &&
  selectedTemplate.type &&
  actions.find((x) => x.type === selectedTemplate.type);

export const configureRemoteControl = createReducer(initialState, {
  [admin.vessels.remoteControl.CHANGE_REMOTE_CONTROL_TYPE]: (
    state,
    { selectedTemplate }
  ) => {
    return {
      ...state,
      selectedTemplate,
      currentAction: actionByType(state.vessel.actions, selectedTemplate),
    };
  },
  [admin.vessels.remoteControl.remoteAccess.SAVE]: (state) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: true,
    },
  }),

  [admin.vessels.remoteControl.remoteAccess.SAVE_SUCCESS]: (state) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: false,
    },
  }),

  [admin.vessels.remoteControl.remoteAccess.SAVE_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: false,
      error,
    },
    hasError: true,
  }),

  [admin.vessels.remoteControl.updateSystem.SAVE]: (state) => ({
    ...state,
    updateSystemForm: {
      ...state.updateSystemForm,
      isSaving: true,
    },
  }),

  [admin.vessels.remoteControl.updateSystem.SAVE_SUCCESS]: (state) => ({
    ...state,
    updateSystemForm: {
      ...state.updateSystemForm,
      isSaving: false,
    },
  }),

  [admin.vessels.remoteControl.updateSystem.SAVE_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    updateSystemForm: {
      ...state.updateSystemForm,
      isSaving: false,
      error,
    },
    hasError: true,
  }),

  [ADMIN_VESSEL_REMOTECONTROL_CONFIGURE]: (state, { vessel }) => {
    return {
      ...initialState,
      currentAction: actionByType(vessel.actions, defaultTemplate),
      vessel,
    };
  },

  [admin.vessels.remoteControl.remoteAccess.SET_VALUE]: (
    state,
    { name, value, valid, message }
  ) => {
    const field = state.remoteAccessForm.fields[name];
    return {
      ...state,
      remoteAccessForm: checkFormValidation({
        ...state.remoteAccessForm,
        fields: {
          ...state.remoteAccessForm.fields,
          [name]: {
            ...field,
            value: value,
            isValid: valid !== undefined ? valid : true,
            message: message || "",
            hasChanges: true,
          },
        },
      }),
    };
  },

  [admin.vessels.remoteControl.remoteAccess.REVOKE]: (state) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: true,
    },
  }),

  [admin.vessels.remoteControl.remoteAccess.REVOKE_SUCCESS]: (state) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: false,
    },
  }),

  [admin.vessels.remoteControl.remoteAccess.REVOKE_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    remoteAccessForm: {
      ...state.remoteAccessForm,
      isSaving: true,
      error,
    },
    hasError: true,
  }),

  [ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE]: () => initialState,
});
