import React from "react";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import DataDetailsHeaderContainer from "./DataDetailsHeaderContainer";
import SparklineHeatMap from "../../../common/components/Sparkline/SparklineHeatmap";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";

export default class DataDetailsHeatMapChart extends React.PureComponent {
  render() {
    const { series, title, fullWidth } = this.props;
    const s = series && series.length > 0 ? series[0] : {};
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <DataDetailsHeaderContainer contentTitle={title} showHeader={false}>
          {s.values && (
            <AutoSizer>
              {({ width, height }) => (
                <Sparkline
                  xScaleType={"band"}
                  yScaleType={"band"}
                  width={width}
                  height={height}
                  data={s.values}
                  yDomain={s.yDomain ? [s.yDomain] : null}
                  xDomain={s.xDomain}
                >
                  <SparklineHeatMap showValue={true} />
                  <SparklineXAxis
                    label={s.xLabel ? `${s.xLabel} (${s.xUnit})` : s.xUnit}
                  />
                  <SparklineYAxis
                    label={s.yLabel ? `${s.yLabel} (${s.yUnit})` : s.yUnit}
                  />
                </Sparkline>
              )}
            </AutoSizer>
          )}
        </DataDetailsHeaderContainer>
      </DataDetailsComponentContainer>
    );
  }
}
