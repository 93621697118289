import moment from "moment";

export class CalendarMonth {
  constructor(date) {
    this.month = moment.utc(date).month();
    this.year = moment.utc(date).year();
    this.weeks = [];
  }
  addWeek(week) {
    this.weeks.push(week);
  }
}

export class CalendarWeek {
  constructor(date) {
    this.weekNumber = moment.utc(date).format("wo");
    this.days = [];
  }

  addDay(calendarDay) {
    this.days.push(calendarDay);
  }
}

export class CalendarDay {
  constructor(date) {
    let momentDate = moment.utc(date);
    this.date = momentDate.toDate();
    this.dayInWeek = momentDate.format("dddd");
    this.day = momentDate.date();
    this.month = momentDate.month();
    this.monthShort = momentDate.format("MMM");
    this.year = momentDate.year();
    this.weekday = momentDate.isoWeekday();
  }
}

export const createCalendarMonth = (selectedYear, selectedMonth) => {
  let month = moment.utc({
    month: selectedMonth,
    year: selectedYear,
  });

  const calendar = new CalendarMonth(month);
  let startOfMonth = moment.utc(month.startOf("month"));
  let endOfMonth = moment.utc(month.endOf("month"));
  let dayInWeek = startOfMonth.isoWeekday();
  let calendarStartDate = startOfMonth.add(1 - dayInWeek, "day");
  let numberOfWeeks = Math.ceil(endOfMonth.diff(startOfMonth, "week", true));
  for (let weekIdx = 0; weekIdx < numberOfWeeks; weekIdx++) {
    let week = new CalendarWeek(moment.utc(calendarStartDate).startOf("week"));
    for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
      let day = new CalendarDay(calendarStartDate);
      week.addDay(day);
      calendarStartDate = calendarStartDate.add(1, "day");
    }
    calendar.addWeek(week);
  }
  return calendar;
};
