import {
  FETCH_ENGINE_LOAD_SUCCESS,
  FETCH_ENGINE_LOAD_ERROR,
} from "../actions/action.types";

const fetchEngineLoad = (
  state = {
    data: {},
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_ENGINE_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case FETCH_ENGINE_LOAD_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default fetchEngineLoad;
