import React from "react";
import PropTypes from "prop-types";
import SelectBox from "../../SelectBox/SelectBox";
import styles from "../Form.css";
import classNames from "../../../../common/classNames";

class FormSelectBox extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      name,
      label,
      labelPosition,
      onChange,
      style,
      required,
      selected,
      selectedVal,
      children,
      testId,
      customValidation,
      isValid,
      validationMessage,
      validationLabel,
      ...props
    } = this.props;
    return (
      <div
        className={classNames(
          styles.formSelectContainer,
          labelPosition === "left" ? styles.leftAlignedContainer : ""
        )}
      >
        {!!label && (
          <div className={styles.labelContainer} style={style}>
            <label htmlFor={name} className={styles.label}>
              {label}
            </label>
            {!customValidation && !!required && (
              <span className={styles.requiredMessage}>required</span>
            )}
            {customValidation && validationLabel && (
              <span className={styles.requiredMessage}>{validationLabel}</span>
            )}
            {!customValidation && !!required && !selected && !selectedVal && (
              <span className={styles.validationMessage}>
                Please select a value
              </span>
            )}
            {customValidation && !isValid && (
              <span className={styles.validationMessage}>
                {validationMessage}
              </span>
            )}
          </div>
        )}
        <div className={styles.formSelectBox}>
          <SelectBox
            {...props}
            selected={selected}
            selectedVal={selectedVal}
            minHeight={"42px"}
            onSelect={(s) => onChange(name, s)}
            testId={testId}
          />
        </div>
        <div className={styles.formElementChildren}>{children}</div>
      </div>
    );
  }
}

FormSelectBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelPosition: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionValKey: PropTypes.string,
  optionLabelKey: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  selected: PropTypes.any,
  selectedVal: PropTypes.any,
  selectedValKey: PropTypes.string,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  testId: PropTypes.string,
  customValidation: PropTypes.bool,
  isValid: PropTypes.bool,
  validationMessage: PropTypes.string,
  validationLabel: PropTypes.string,
  clearable: PropTypes.bool,
};

export default FormSelectBox;
