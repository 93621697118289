import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { LoginHandler } from "./LoginHandler";
import { ILoginHandler } from "./ILoginHandler";
import { ApiAvailabilityHandler } from "./ApiAvailabilityHandler";
import { IApiAvailabilityHandler } from "./IApiAvailabilityHandler";
import { useRootContext } from "../index";

const AuthCallbackView = () => {
  const { authService, apiClient } = useRootContext();
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const apiAvailabilityHandler: IApiAvailabilityHandler =
    new ApiAvailabilityHandler(apiClient, dispatch);

  const login = () => {
    authService
      .loginCallback()
      .then(() => {
        const loginHandler: ILoginHandler = new LoginHandler(
          authService,
          apiClient,
          dispatch,
          navigateTo,
          apiAvailabilityHandler
        );
        loginHandler.silentLoginToVesselPerformance();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    login();
  }, []);

  return <div />;
};

export default AuthCallbackView;
