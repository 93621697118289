import React from "react";
import PropTypes from "prop-types";
import { latLng2Scaled } from "./utils";

const TILE_SIZE = 256;

const latLng2Tile =
  (ptNW, zoom) =>
  ({ lat, lng }) => {
    const point = latLng2Scaled({ lat, lng }, zoom);
    return {
      x: (point.x - ptNW.x) * TILE_SIZE,
      y: (point.y - ptNW.y) * TILE_SIZE,
    };
  };

class CanvasMapOverlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onMouseMove = this.onMouseMove.bind(this);
    this._renderContext = this._renderContext.bind(this);
  }

  componentDidMount() {
    window.requestAnimationFrame(this._renderContext);
  }

  componentDidUpdate(prevProps) {
    const { bounds, zoom } = this.props;
    if (prevProps.bounds !== bounds || zoom !== prevProps.zoom) {
      this.canvas.style.visibility = "hidden";
    }
    window.requestAnimationFrame(this._renderContext);
  }

  onMouseMove() {}

  _renderContext() {
    const { width, height, doubleBuffer, bounds, zoom } = this.props;
    if (width === 0 || height === 0) return;
    const ctx = this.ctx;

    if (!ctx) return;

    const ptNW = latLng2Scaled(bounds.nw, zoom);
    let doubleBufferCanvas, doubleBufferCtx;
    if (doubleBuffer) {
      doubleBufferCanvas = document.createElement("canvas");
      doubleBufferCanvas.width = width;
      doubleBufferCanvas.height = height;
      doubleBufferCtx = doubleBufferCanvas.getContext("2d");
    } else {
      ctx.transform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, width, height);
    }

    this.renderCanvas({
      ctx: doubleBuffer ? doubleBufferCtx : ctx,
      bounds,
      zoom,
      latLng2Tile: latLng2Tile(ptNW, zoom),
    });

    if (doubleBuffer) {
      ctx.transform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(doubleBufferCanvas, 0, 0);
    }

    this.canvas.style.visibility = "visible";
  }

  render() {
    const { width, height } = this.props;
    return (
      <canvas
        style={{ position: "absolute", transform: "translate(-50%, -50%)" }}
        ref={(el) => {
          this.canvas = el;
          this.ctx = el && el.getContext("2d");
        }}
        onMouseMove={this.onMouseMove}
        width={width}
        height={height}
      />
    );
  }
}

CanvasMapOverlay.defaultProps = {
  doubleBuffer: false,
  zoom: 1,
};

CanvasMapOverlay.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  doubleBuffer: PropTypes.bool,
  zoom: PropTypes.number,
  bounds: PropTypes.object.isRequired,
  centerLat: PropTypes.number.isRequired,
  centerLng: PropTypes.number.isRequired,
};

export default CanvasMapOverlay;
