import { createUrl } from "../common/urls";
import { DateRange } from "./components/DatePicker/Types";
import { Direction } from "./common/direction";

export const CII_SCORES = {
  get: (operationModes: string[], vesselGroup: string) => {
    return createUrl("/api/v2/data/emissions/GetCIIScores/", {
      operationModes,
      vesselGroup,
    });
  },
};

export const EMISSIONS_BY_OPERATION_MODES = {
  get: (from: Date, to: Date | undefined, vesselGroup: string) =>
    createUrl("/api/v2/data/emissions/GetEmissionsByOperationModes", {
      from,
      to,
      vesselGroup,
    }),
};

export const EMISSIONS_LIST = {
  get: (
    from: Date,
    to: Date | undefined,
    vesselGroup: string,
    operationClass: string,
    operationModes: string[],
    numberOfItemsToGet: number,
    sortingOrder: Direction
  ) =>
    createUrl("/api/v2/data/emissions/GetEmissionsPerVessel", {
      from,
      to,
      vesselGroup,
      operationClass,
      operationModes,
      numberOfItemsToGet,
      sortingOrder,
    }),
};

export const FLEET_ACTIVITY = {
  get: (from: Date, to: Date | undefined, vesselGroup: string) =>
    createUrl("/api/v2/data/donut/fleetactivity", { from, to, vesselGroup }),
};

export const VESSEL_STATES = {
  getVesselLegs: (
    vesselId: string,
    { from, to }: DateRange,
    timeOffset: number
  ) => createUrl(`/api/vessel/${vesselId}/legs`, { from, to, timeOffset }),
  getVesselVoyages: (
    vesselId: string,
    { from, to }: DateRange,
    timeOffset: number
  ) => createUrl(`/api/vessel/${vesselId}/voyages`, { from, to, timeOffset }),
};
