import React, { FC } from "react";

interface AddButtonProps {
  addRow: () => void;
}

const AddButton: FC<AddButtonProps> = ({ addRow }) => {
  return (
    <div
      style={{ color: "white", cursor: "pointer" }}
      onClick={addRow}
      data-testid="AddButton"
    >
      + Add
    </div>
  );
};

export default AddButton;
