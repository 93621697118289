import React, { Component } from "react";
import { get } from "lodash";
import { FormCheckBox } from "../../../../../components/Form";
import { METRIC_IDS } from "../../../../../config";
import styles from "./SettingsEditForm.css";
import { getMetrics } from "../../../../../common/pageConfig";

class EnergyBudgetResultEditForm extends Component {
  render() {
    const { params, onChange } = this.props;
    const metricIds = get(params, "metricId", []).map((mi) => {
      return mi.toLowerCase();
    });
    const metrics = getMetrics(metricIds, METRIC_IDS);
    return (
      <div>
        <h2 className={styles.title}>
          Fuel Type{" "}
          <span className={styles.description}>(up to two fuel types)</span>
        </h2>
        <div className={styles.selectedMetrics}>
          {`[${metrics.slice(0, 2).join(", ")}]`}
        </div>
        <div className={styles.field}>
          {METRIC_IDS.map((i) => (
            <FormCheckBox
              key={i.name}
              value={metricIds.includes(i.name)}
              label={i.label}
              name={i.name}
              iconPosition="left"
              disabled={metricIds.length >= 2 && !metricIds.includes(i.name)}
              onChange={onChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default EnergyBudgetResultEditForm;
