import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchVesselGroups,
  fetchVesselGroup,
  fetchCompanies,
  createVesselGroup,
  setSelectedCompany,
} from "../../../actions/admin";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import SelectBox from "../../../components/SelectBox/SelectBox";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import VesselGroupPopup from "./VesselGroupPopup";
import styles from "../Admin.css";
import {
  vesselGroupViewInitialized,
  vesselGroupViewDetached,
  deleteVesselGroup,
  deleteVesselGroupPopupClose,
} from "../../../actions/admin/action.vesselGroups";
import ConfirmDeleteDialog from "../VesselConfig/ConfirmDeleteDialog";

const deleteGroupPopupTitle = "Delete vessel group";
const deleteGroupPopupMessage =
  "Are you sure you want to delete this vessel group? This action cannot be undone.";

class VesselGroups extends Component {
  constructor(props) {
    super(props);

    this.gridConfig = {
      columns: [
        {
          header: "Name",
          field: "name",
          align: "left",
          flexGrow: 2,
        },
        {
          header: "Company Name",
          field: "companyName",
          align: "left",
          flexGrow: 2,
        },
        {
          header: "Actions",
          align: "left",
          template: this.renderActions.bind(this),
        },
      ],
    };
  }

  onEditItemClick(id) {
    this.props.fetchVesselGroup(id);
  }

  componentDidMount() {
    this.props.vesselGroupViewInitialized();
    this.fetchVesselGroups();
    this.props.fetchCompanies();
  }

  componentDidUpdate(prevProps) {
    const { selectedCompanyId } = this.props;
    if (prevProps.selectedCompanyId !== selectedCompanyId) {
      this.fetchVesselGroups();
    }
  }

  componentWillUnmount() {
    this.props.vesselGroupViewDetached();
  }

  fetchVesselGroups() {
    const { selectedCompanyId } = this.props;
    this.props.fetchVesselGroups(selectedCompanyId);
  }

  renderActions({ id }) {
    if (!this.props.canEdit) {
      return null;
    }
    return (
      <Button
        value="Edit"
        clickAction={() => this.onEditItemClick(id)}
        type="link"
      />
    );
  }

  renderDataGrid() {
    const { isLoading, data, error } = this.props.vesselGroups;
    if (isLoading) {
      return <Loader error={error} />;
    }
    return <DataGrid data={data} {...this.gridConfig} />;
  }

  render() {
    const { companies, selectedCompanyId, canEdit, deletePopup } = this.props;
    const selectOptions = [{ id: null, name: "All" }].concat(companies);

    return (
      <div>
        <Header
          title="Admin - Vessel Groups"
          contentDistribution="space-between"
        >
          <div className={styles.headerContainer}>
            <SelectBox
              options={selectOptions}
              optionValKey="id"
              optionLabelKey="name"
              onSelect={this.props.setSelectedCompany}
              selected={selectOptions.find((c) => c.id === selectedCompanyId)}
            />
            {canEdit && (
              <Button
                value="Create Vessel Group"
                clickAction={this.props.createVesselGroup.bind(
                  this,
                  selectedCompanyId
                )}
              />
            )}
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        <VesselGroupPopup />
        {deletePopup.isVisible && (
          <ConfirmDeleteDialog
            title={deleteGroupPopupTitle}
            message={deleteGroupPopupMessage}
            onCancelClick={this.props.deleteVesselGroupPopupClose}
            onConfirmClick={this.props.deleteVesselGroup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vesselGroups: state.admin.vesselGroups,
  companies: state.admin.vesselGroups.companies,
  selectedCompanyId: state.admin.selectedCompanyId,
  deletePopup: state.admin.vesselGroups.deletePopup,
  canEdit: isSystemAdmin(state.user),
});

const mapActionsToProps = {
  fetchVesselGroups,
  fetchVesselGroup,
  fetchCompanies,
  createVesselGroup,
  setSelectedCompany,
  vesselGroupViewInitialized,
  vesselGroupViewDetached,
  deleteVesselGroup,
  deleteVesselGroupPopupClose,
};

export default connect(mapStateToProps, mapActionsToProps)(VesselGroups);
