import React from "react";
import { SparklineContext } from "./Sparkline";
import PropTypes from "prop-types";
import SparklinePanAndZoomHandler from "./SparklinePanAndZoomHandler";
import Transform from "./Transform";

class SparklineZoom extends React.PureComponent {
  state = {
    scaleX: null,
    xDomain: null,
    transform: Transform.identityTransform(),
  };

  constructor(props) {
    super(props);
  }

  handlePan = (e) => {
    const { translateExtent } = this.props;
    const transform = this.state.transform.translateX(e.x, translateExtent);
    const scaleX = transform.rescaleX(this.props.context.scaleX);
    this.setState({
      scaleX,
      xDomain: scaleX.domain(),
      transform,
    });
  };

  handleZoom = (e) => {
    const { scale, x } = e;
    const { translateExtent, scaleExtent } = this.props;
    const transform = this.state.transform.scaleXToPoint(
      scale,
      x,
      this.props.context.width,
      scaleExtent,
      translateExtent
    );
    const scaleX = transform.rescaleX(this.props.context.scaleX);
    this.setState({
      scaleX,
      xDomain: scaleX.domain(),
      transform,
    });
  };

  render() {
    const { children, context } = this.props;
    return (
      <SparklinePanAndZoomHandler
        onPan={this.handlePan}
        onZoom={this.handleZoom}
      >
        <g>
          <SparklineContext.Provider
            value={{
              ...context,
              xDomain: this.state.xDomain || context.xDomain,
              scaleX: this.state.scaleX || context.scaleX,
            }}
          >
            {children}
          </SparklineContext.Provider>
        </g>
      </SparklinePanAndZoomHandler>
    );
  }
}

SparklineZoom.propTypes = {
  translateExtent: PropTypes.array,
  scaleExtent: PropTypes.array,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineZoom context={context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
