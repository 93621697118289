import React from "react";
import PropTypes from "prop-types";
import classNames from "../../classNames";
import { isNullOrUndefined } from "../../objects";
import { getIconGraphics } from "../Icon/Icon";
import styles from "./Gauge.css";
import { formatNumber } from "../../numbers";
import GaugeBase from "./GaugeBase";
import AutoSizer from "../AutoSizer/AutoSizer";
import { Animate } from "react-move";
import { easeExpOut } from "d3";

export default class Gauge extends React.PureComponent {
  isGreen() {
    return "performanceValue" in this.props && this.props.performanceValue > 0;
  }

  isRed() {
    return "performanceValue" in this.props && this.props.performanceValue < 0;
  }

  renderGaugeContent({ width, height }) {
    const { icon, value, performanceValue, formatValue, unit } = this.props;

    return (
      <svg
        height={height}
        width={width}
        viewBox="0 0 200 200"
        preserveAspectRatio="xMidYMid meet"
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 64 64"
          x="82"
          y="40"
          fill="currentColor"
        >
          {getIconGraphics(icon)}
        </svg>

        <g>
          <text
            x="100"
            y="110"
            fontSize="36"
            textAnchor="middle"
            className={styles.gaugeValue}
          >
            {formatValue(value)}
          </text>
          <text
            x="100"
            y="130"
            fontSize="16"
            textAnchor="middle"
            className={styles.gaugeUnit}
          >
            {unit}
          </text>
          {!isNullOrUndefined(performanceValue) && (
            <text
              x="100"
              y="155"
              fontSize="16"
              textAnchor="middle"
              className={styles.gaugePerformanceValue}
            >
              {this.isGreen() ? "+" : ""}
              {formatValue(performanceValue)}%
            </text>
          )}
        </g>
      </svg>
    );
  }

  render() {
    const {
      minValue,
      maxValue,
      value,
      performanceValue,
      performanceMinValue,
      performanceMaxValue,
    } = this.props;

    const gaugeData = isNullOrUndefined(performanceValue)
      ? { value: value || minValue || 0, minValue, maxValue }
      : {
          value: performanceValue || minValue || 0,
          minValue: performanceMinValue,
          maxValue: performanceMaxValue,
          trend: true,
        };

    return (
      <div className={styles.gaugeContainer}>
        <AutoSizer>
          {({ width, height }) => [
            <div className={styles.gaugeBaseContainer} key="meter">
              <Animate
                start={{ value: 0 }}
                update={{
                  value: [gaugeData.value],
                  timing: { duration: 500, ease: easeExpOut },
                }}
              >
                {(state) => {
                  return (
                    <GaugeBase
                      {...gaugeData}
                      value={state.value}
                      width={width}
                      height={height}
                    />
                  );
                }}
              </Animate>
            </div>,
            <div
              key="content"
              className={classNames(
                styles.gaugeContent,
                this.isGreen() && styles.green,
                this.isRed() && styles.red
              )}
            >
              {this.renderGaugeContent({ width, height })}
            </div>,
          ]}
        </AutoSizer>
      </div>
    );
  }
}

Gauge.defaultProps = {
  minValue: 0,
  maxValue: 0,
  formatValue: formatNumber,
  performanceMinValue: -20,
  performanceMaxValue: 20,
};

Gauge.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  performanceValue: PropTypes.number,
  performanceMinValue: PropTypes.number,
  performanceMaxValue: PropTypes.number,
  formatValue: PropTypes.func,
  unit: PropTypes.string,
  icon: PropTypes.string,
};
