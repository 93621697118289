import React from "react";
import { formatDate, formatDateTime } from "../../common/dates";
import {
  roundConvert,
  extractTypeFromUnitValueString,
} from "../../../common/numbers";

const parseValue = (type, value, unit) => {
  switch (type) {
    case "Decimal":
    case "Float": {
      const convertedValue = roundConvert(value, unit);
      return {
        value: convertedValue.number,
        unit: convertedValue.unit,
      };
    }
    case "DateTime":
      return {
        value: formatDateTime(new Date(value)),
      };
    default:
      return { value };
  }
};

const DefaultPropertyValue = ({
  value: valueObj,
  name,
  showName = true,
  valueType,
}) => {
  let value, unit, dateTime;
  if (valueType && valueType.startsWith("UnitValue")) {
    const type = extractTypeFromUnitValueString(valueType);
    const parsedValue = parseValue(type, valueObj.value, valueObj.unit);
    value = parsedValue.value;
    unit = parsedValue.unit || valueObj.unit;
    dateTime = valueObj.dateTime && formatDate(new Date(valueObj.dateTime));
  } else {
    value = parseValue(valueType, valueObj).value;
  }

  return (
    <div className={"propertyContainer"}>
      <span className={"propertyValue"}>{value}</span>
      {unit && <span className={"propertyUnit"}>{unit}</span>}
      {dateTime && <span className={"propertyDateTime"}>{dateTime}</span>}
      {showName && <span className={"propertyName"}>{name}</span>}
    </div>
  );
};

export default DefaultPropertyValue;
