import React, { FC } from "react";
import Button from "../../../common/components/Button";
import { Operation } from "./Types";
import { PROFILES } from "../../../common/config";

export const getLegendColor = (legend: string): string =>
  PROFILES[legend]?.color || "";

export const getLegendFontSize = (legend: string): number => {
  if (legend.length > 3) return 6;
  if (legend.length > 2) return 9;
  return 12;
};

interface OperationOptionProps {
  operation: Operation;
}

const OperationOption: FC<OperationOptionProps> = ({ operation }) => {
  const fontSize = getLegendFontSize(operation.legend);

  return (
    <div
      key={operation.legend}
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <Button
        circle
        width="24"
        padding="1px"
        borderColor={getLegendColor(operation.legend)}
        color={getLegendColor(operation.legend)}
        value={<p style={{ fontSize: `${fontSize}px` }}>{operation.legend}</p>}
        type="smallButton"
      />
      {operation.name}
    </div>
  );
};

export default OperationOption;
