import React from "react";
import styles from "./DataDetailsLoadProfile.css";
import DataDetailsValueTable from "../DataDetails/DataDetailsValueTable";
import DataDetailsBarChart from "../DataDetails/DataDetailsBarChart";
import { color } from "../../../common/colors";
import { find, get } from "lodash";
import memoize from "memoize-one";
import PropTypes from "prop-types";

class DataDetailsLoadProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.parseLoadProfile = memoize((loadProfile) => {
      if (!loadProfile) return;
      if (loadProfile.values.filter((v) => v.value !== 0).length === 0) {
        return;
      }
      return [
        {
          yUnit: get(loadProfile, "yUnit"),
          xUnit: get(loadProfile, "xUnit"),
          legend: get(loadProfile, "yUnit"),
          values: get(loadProfile, "values", []).map((v) => ({
            x: v.key,
            y: v.value,
          })),
        },
      ];
    });

    this.parseOperationLoadProfiles = memoize(
      (operationLoadProfiles, metrics) => {
        if (!operationLoadProfiles || !metrics) return {};
        const columns = [{ name: "Operation" }];
        const footer = ["Agg."];
        const values = [];
        for (let m of metrics) {
          columns.push({
            name: m.name,
            unit: m.unit,
          });
          footer.push(m.value);
        }
        for (let o of operationLoadProfiles) {
          const row = [o.operationName];
          if (o.metrics) {
            for (let i = 1; i < columns.length; i++) {
              const col = columns[i];
              const metric = find(o.metrics, { name: col.name });
              row.push(metric ? metric.value : null);
            }
          }
          values.push(row);
        }
        return {
          columns,
          footer,
          values,
        };
      }
    );
  }

  render() {
    const { loadProfile, operationLoadProfiles, metrics } = this.props;
    const barSeries = this.parseLoadProfile(loadProfile);

    return (
      <div className={styles.container}>
        <div className={styles.bottomContainer}>
          {barSeries && (
            <div className={styles.chartContainer}>
              <DataDetailsBarChart
                customSparkLineProps={{
                  onYDomainCreated: (yDomain) =>
                    yDomain.map((d) => [d[0], d[1] * 1.1]),
                }}
                customSparkLineBarProps={{
                  barStyle: "ball",
                  colors: [color("--blue-base")],
                }}
                height={500}
                title={loadProfile.name}
                series={barSeries}
                useUniqueValuesForBand={true}
              />
            </div>
          )}
          <div className={styles.propertyContainer}>
            <DataDetailsValueTable
              firstColumnIsTitle={true}
              height={"100%"}
              {...this.parseOperationLoadProfiles(
                operationLoadProfiles,
                metrics
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const metricPropType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.number,
  viewPosition: PropTypes.number,
};

DataDetailsLoadProfile.propTypes = {
  dataType: PropTypes.string,
  fullWidth: PropTypes.bool,
  loadProfile: PropTypes.shape({
    itemName: PropTypes.string.isRequired,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    name: PropTypes.string.isRequired,
    xUnit: PropTypes.string.isRequired,
    yUnit: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        key: PropTypes.string.isRequired,
        value: PropTypes.number,
      })
    ),
  }),
  operationLoadProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      hours: PropTypes.number.isRequired,
      operationId: PropTypes.string.isRequired,
      operationName: PropTypes.string.isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({
          ...metricPropType,
        })
      ),
    })
  ),
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      ...metricPropType,
    })
  ),
};

export default DataDetailsLoadProfile;
