import { IOperationClass } from "./IOperationClass";
import { Transit } from "./Transit";
import { DP } from "./DP";
import { OperationClassID } from "../operation/operationClassID";
import { AHTS } from "./AHTS";
import { Fishing } from "./Fishing";
import { Passive } from "./Passive";
import { General } from "./General";

export function createOperationClass(
  operationClass: OperationClassID
): IOperationClass {
  const idClassMapping: Record<OperationClassID, () => IOperationClass> = {
    [OperationClassID.Transit]: () => new Transit(),
    [OperationClassID.DP]: () => new DP(),
    [OperationClassID.AHTS]: () => new AHTS(),
    [OperationClassID.Fishing]: () => new Fishing(),
    [OperationClassID.Passive]: () => new Passive(),
    [OperationClassID.General]: () => new General(),
  };

  return idClassMapping[operationClass]();
}
