import { components } from "./action.types";
import fetch from "./action.fetch";
import { EQUINOR } from "../api";

export const fetchEquinorVesselReport = ({ vesselId, dateRange }) => {
  return fetch({
    url: EQUINOR.fetchVesselReport(vesselId, dateRange),
    actionType: components.equinor.FETCH_VESSEL_REPORT,
  });
};
