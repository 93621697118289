import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import DataDetailsHeaderContainer from "./DataDetailsHeaderContainer";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineBars from "../../../common/components/Sparkline/SparklineBars";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineTooltip from "../../../common/components/Sparkline/SparklineTooltip";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import SparklineLegends from "../../../common/components/Sparkline/SparklineLegends";
import { color } from "../../../common/colors";
import { isFinite } from "lodash";
import { getYAxisConfig } from "./Helpers";
import { formatNumber } from "../../../common/numbers";

const oneDomainColors = (domainIndex, seriesIndex) => {
  return oneDomainLineColors[seriesIndex];
};

class DataDetailsBarChart extends React.Component {
  render() {
    const {
      children,
      series,
      headerData,
      title,
      height,
      customSparkLineProps,
      customSparkLineBarProps,
      fullWidth,
      colors,
      legendTilting,
      xAxisTilting,
      componentDescription,
      yAxisConfig,
    } = this.props;

    let legends = [];
    let yUnit = "";
    let xUnit = "";
    const data =
      series &&
      series.reduce((acc, s, seriesIndex) => {
        legends.push({
          legend: s.legend,
          seriesIndex: seriesIndex - 1,
        });
        yUnit = s.yUnit;
        xUnit = s.xUnit;
        if (s.values) {
          acc.push(
            s.values.map((v) => ({
              x: v.x,
              y: v.y,
            }))
          );
        }
        return acc;
      }, []);

    const colorFunction = oneDomainColors;
    const yDomain = this.getYDomain(series);
    const intervals = this.getIntervals(colors);

    let intervalColors;
    const legendsData = [];
    if (intervals?.length) {
      intervalColors = {
        colorByAxis: colors.colorByAxis,
        colors: intervals,
      };
      legendsData.push(
        ...intervals.map((s) => ({
          text: s.legend || `from ${s.from} to ${s.to}`,
          color: s.color,
        }))
      );
    } else {
      legendsData.push(
        ...legends.map((s) => ({
          text: s.legend,
          color: colorFunction(0, s.seriesIndex + 1),
        }))
      );
    }

    const xAxisTiltingLimit = data.length ? +data[0].length : 0;
    const rotation =
      xAxisTilting &&
      xAxisTilting.number &&
      xAxisTilting.number <= xAxisTiltingLimit
        ? xAxisTilting.angle || 8
        : 0;

    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <DataDetailsHeaderContainer
          contentTitle={title}
          componentDescription={componentDescription}
          showHeader={!!headerData}
          headerData={headerData}
          height={height}
        >
          <AutoSizer>
            {({ width, height: sparklineHeight }) => (
              <Sparkline
                width={width}
                height={sparklineHeight}
                xScaleType={"band"}
                yDomain={yDomain}
                data={data}
                xScalePadding={0.25}
                {...getYAxisConfig(yAxisConfig)}
                {...customSparkLineProps}
              >
                <SparklineXAxis label={xUnit} rotation={rotation} />
                <SparklineYAxis label={yUnit} />
                <SparklineGrid showX={false} />
                <SparklineHover useBisect={false}>
                  <SparklineBars
                    barStyle="box"
                    {...customSparkLineBarProps}
                    colors={oneDomainLineColors}
                    intervalColors={intervalColors}
                  />
                  <SparklineTooltip
                    tooltips={this.getTooltips(series, colorFunction)}
                    showBorders
                  />
                  {children}
                </SparklineHover>
                <SparklineLegends
                  legends={legendsData}
                  tilting={legendTilting}
                  legendYPosition={
                    rotation === 90 ? sparklineHeight - 24 : undefined
                  }
                />
              </Sparkline>
            )}
          </AutoSizer>
        </DataDetailsHeaderContainer>
      </DataDetailsComponentContainer>
    );
  }

  getTooltips(series, colorFunction) {
    return series.map((s, index) => ({
      color: colorFunction(0, index),
      format: ({ y }) => formatNumber(y) + (s.yUnit ? ` ${s.yUnit}` : ""),
      dataSetIndex: index,
    }));
  }

  getIntervals(colors) {
    return colors?.intervals?.map((x) => ({
      from: x.from === undefined ? -Infinity : x.from,
      to: x.to === undefined ? Infinity : x.to,
      color: color(`--${x.color}`),
      legend: x.legend,
    }));
  }

  getYDomain(series) {
    let yDomain = [
      d3.min(series, (s) => (s.yDomain ? d3.min(s.yDomain) : undefined)),
      d3.max(series, (s) => (s.yDomain ? d3.max(s.yDomain) : undefined)),
    ];

    if (!(isFinite(yDomain[0]) && isFinite(yDomain[1]))) {
      yDomain = [
        d3.min(series, (s) => d3.min(s.values, (v) => v.y)),
        d3.max(series, (s) => d3.max(s.values, (v) => v.y)),
      ];
    }
    return yDomain;
  }
}

const oneDomainLineColors = [
  color("--blue-dark"),
  color("--yellow-dark"),
  color("--orange-dark"),
  color("--red-dark"),
  color("--blue-base"),
  color("--yellow-base"),
  color("--orange-base"),
  color("--red-base"),
  color("--blue-bright"),
  color("--yellow-bright"),
  color("--orange-bright"),
  color("--red-bright"),
  color("--blue-darkest"),
  color("--yellow-darkest"),
  color("--orange-darkest"),
  color("--red-darkest"),
  color("--blue-brightest"),
  color("--yellow-brightest"),
  color("--orange-brightest"),
  color("--red-brightest"),
];

DataDetailsBarChart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.number,
  fullWidth: PropTypes.bool,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      legend: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any,
          y: PropTypes.any,
        })
      ),
    })
  ).isRequired,
  customSparkLineProps: PropTypes.shape(),
  customSparkLineBarProps: PropTypes.shape(),
};

export default DataDetailsBarChart;
