import React from "react";
import PropTypes from "prop-types";
import InlinePanel from "../InlinePanel/InlinePanel";
import FormSelectBox from "../../../../components/Form/FormSelectBox/FormSelectBox";
import FormTextBox from "../../../../components/Form/FormTextBox/FormTextBox";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import { get, matchesProperty, compact, isEmpty } from "lodash";
import styles from "./VesselOperationForm.css";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";

class VesselOperationForm extends React.PureComponent {
  getButtons() {
    const { hasChanges, isLoading, isValid } = this.props.form;
    return compact([
      this.props.rowId && {
        label: "Delete",
        action: this.onDelete,
        disabled: isLoading,
      },
      {
        label: "Cancel",
        action: this.onCancel,
        disabled: isLoading,
      },
      {
        label: "Save",
        action: this.onSave,
        disabled: !hasChanges || !isValid || isLoading,
      },
    ]);
  }

  onCancel = () => this.props.cancelAction(this.props.rowId);

  onSave = () =>
    this.props.saveAction({
      id: this.props.rowId,
      vesselId: this.props.vesselId,
      form: this.props.form,
    });

  onDelete = () => this.props.deleteAction(this.props.rowId);

  onCancelDelete = () => this.props.cancelDeleteAction(this.props.rowId);

  onConfirmDelete = () => this.props.confirmDeleteAction(this.props.rowId);

  onInputChanged = (name, value, valid, message) =>
    this.props.inputChanged(this.props.rowId, name, value, valid, message);

  onSelectChanged = (name, value, valid, message) => {
    if (!this.props.form.isNew) {
      value = [value];
    }
    return this.props.inputChanged(
      this.props.rowId,
      name,
      value,
      valid,
      message
    );
  };

  renderForm() {
    const { form, operations, showMinMaxEngines } = this.props;
    const isNew = form.isNew;
    const selectedOperation = operations.find(
      matchesProperty("id", get(form, "fields.operations.value[0].operationId"))
    );
    let selectedOperations;
    let vesselOperationIds = get(form, "fields.operations.value")?.map(
      (x) => x.operationId
    );
    if (!isEmpty(vesselOperationIds)) {
      selectedOperations = operations.filter((o) => {
        return vesselOperationIds.includes(o.id);
      });
    }

    return (
      <FormGroup>
        <div className={styles.container}>
          <div className={styles.column}>
            <FormSelectBox
              name="operations"
              label="Operation"
              selected={isNew ? selectedOperations : selectedOperation}
              options={operations}
              optionValKey="id"
              optionLabelKey="name"
              onChange={this.onSelectChanged}
              required
              multi={isNew}
              testId={"operationIdSelect"}
            />
          </div>
          {form.showSpeed && (
            <div className={styles.column}>
              <FormTextBox
                name="minSpeed"
                label="Min speed"
                value={get(form, "fields.minSpeed.value")}
                onChange={this.onInputChanged}
                type={"number"}
              />
              <FormTextBox
                name="maxSpeed"
                label="Max speed"
                value={get(form, "fields.maxSpeed.value")}
                onChange={this.onInputChanged}
                type={"number"}
              />
            </div>
          )}
          {showMinMaxEngines && form.showEngines && (
            <div className={styles.column}>
              <FormTextBox
                name="minEngines"
                label="Min engines running"
                value={get(form, "fields.minEngines.value")}
                onChange={this.onInputChanged}
                type={"integer"}
              />
              <FormTextBox
                name="maxEngines"
                label="Max engines running"
                value={get(form, "fields.maxEngines.value")}
                onChange={this.onInputChanged}
                type={"integer"}
              />
            </div>
          )}
          {form.showTension && (
            <div className={styles.column}>
              <FormTextBox
                name="maxTension"
                label="Max tension"
                value={get(form, "fields.maxTension.value")}
                onChange={this.onInputChanged}
                type={"number"}
              />
            </div>
          )}
          {this.props.form.isDeleting && (
            <ConfirmDeleteDialog
              onCancelClick={this.onCancelDelete}
              onConfirmClick={this.onConfirmDelete}
              title={"Delete operation"}
              message="Are you sure you want to delete this operation? This action can not be undone."
            />
          )}
        </div>
      </FormGroup>
    );
  }

  render() {
    const { form } = this.props;
    const buttons = this.getButtons();

    return (
      <InlinePanel
        title={this.props.title}
        buttons={buttons}
        isLoading={form.isLoading}
        error={form.error}
      >
        {this.renderForm()}
      </InlinePanel>
    );
  }
}

VesselOperationForm.propTypes = {
  operations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  form: PropTypes.shape({
    fields: PropTypes.shape({
      operations: PropTypes.object.isRequired,
      minSpeed: PropTypes.object,
      maxSpeed: PropTypes.object,
      minEngines: PropTypes.object,
      maxEngines: PropTypes.object,
      maxTension: PropTypes.object,
    }),
    isLoading: PropTypes.bool,
    isVisible: PropTypes.bool,
    isDeleting: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  rowId: PropTypes.string,
  inputChanged: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  cancelDeleteAction: PropTypes.func.isRequired,
  confirmDeleteAction: PropTypes.func.isRequired,
};

export default VesselOperationForm;
