import React from "react";
import { connect } from "react-redux";
import styles from "./AnalysisPlot.css";
import AutoSizer from "../../../../common/components/AutoSizer/AutoSizer";
import { PlotChart } from "../../../components/PlotChart/PlotChart";

class AnalysisPlot extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { plot, xAxisType, yAxisType, zAxisType, zDomain } = this.props;
    return (
      <div className={styles.plotContainer}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <PlotChart
                data={plot.data && plot.data.data ? plot.data.data : []}
                isLoading={plot.isLoading}
                width={width}
                height={height}
                xLabel={xAxisType.label}
                xUnit={xAxisType.unit}
                yLabel={yAxisType.label}
                yUnit={yAxisType.unit}
                zUnit={zAxisType.unit}
                zDomain={{
                  min: zDomain.min || 0,
                  max: zDomain.max || 0,
                }}
              />
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plot: state.analysis.plot,
  xAxisType: state.analysis.xAxisType,
  yAxisType: state.analysis.yAxisType,
  zAxisType: state.analysis.zAxisType,
  zDomain: state.analysis.zDomain,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisPlot);
