import React from "react";
import PropTypes from "prop-types";
import DataDetailsHeaderContainer from "./DataDetailsHeaderContainer";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineBars from "../../../common/components/Sparkline/SparklineBars";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import { color } from "../../../common/colors";
import * as d3 from "d3";
import { COSMETIC_CONSTS } from "../../config";

class DataDetailsRULChart extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  customBarRenderer = (props, values, x1) => {
    const { colorDomain } = this.props;
    const colorScale = d3
      .scaleThreshold()
      .domain(colorDomain || [25, 50])
      .range([
        color("--red-base"),
        color("--orange-base"),
        color("--normal-green"),
      ]);
    const { scaleY, height } = props;
    return values.map((value, index) => {
      const bandWidth = x1.bandwidth();
      const y = scaleY[0](value.value);
      const maxWidth = 30;
      const barWidth = Math.min(bandWidth, maxWidth);
      const barHeight = Math.max(height - y, 0);

      const backgroundStyle = {
        fill: color("--dark-mid-grey"),
      };

      const rectStyle = {
        fill: colorScale(value.value),
      };
      const shadowStyle = {
        fill: "#000",
        fillOpacity: 0.5,
      };
      const labelStyle = {
        fontFamily: COSMETIC_CONSTS.fontFamily,
        fontSize: 12,
        textAnchor: "middle",
        alignmentBaseline: "central",
        fill: color("--light-grey"),
      };

      return (
        <g key={`bar_${index}`} transform={`translate(${x1(index)})`}>
          <text
            style={labelStyle}
            x={(bandWidth - barWidth) / 2 + barWidth / 2}
            y={-5}
          >
            {value.title}
          </text>
          <rect
            x={(bandWidth - barWidth) / 2}
            y={0}
            width={barWidth}
            height={height}
            style={backgroundStyle}
          />
          <rect
            x={(bandWidth - barWidth) / 2}
            y={scaleY[0](value.value)}
            width={barWidth}
            height={barHeight}
            style={rectStyle}
          />
          {barHeight > 0 && (
            <rect
              x={barWidth - 5 + (bandWidth - barWidth) / 2}
              y={scaleY[0](value.value)}
              width={5}
              height={barHeight}
              style={shadowStyle}
            />
          )}
        </g>
      );
    });
  };

  render() {
    const {
      children,
      values,
      unit,
      valueDomain,
      headerData,
      title,
      height,
      fullWidth,
      componentDescription,
    } = this.props;

    const data = values.map((v) => ({
      x: v.name,
      value: v.value,
      title: v.title,
    }));
    const yDomain = valueDomain || [0, 100];

    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <DataDetailsHeaderContainer
          contentTitle={title}
          componentDescription={componentDescription}
          showHeader={!!headerData}
          headerData={headerData}
          height={height}
        >
          <AutoSizer>
            {({ width, height: sparklineHeight }) => (
              <Sparkline
                width={width}
                height={sparklineHeight}
                xScaleType={"band"}
                yDomain={yDomain}
                data={data}
                xScalePadding={0.25}
                margin={{ top: 30 }}
              >
                <SparklineXAxis />
                <SparklineYAxis label={unit} />
                <SparklineGrid showX={false} />
                <SparklineHover useBisect={false}>
                  <SparklineBars
                    barStyle="custom"
                    customBarRenderer={this.customBarRenderer}
                  />
                  {children}
                </SparklineHover>
              </Sparkline>
            )}
          </AutoSizer>
        </DataDetailsHeaderContainer>
      </DataDetailsComponentContainer>
    );
  }
}

DataDetailsRULChart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.string,
  valueDomain: PropTypes.array,
  colorDomain: PropTypes.array,
  unit: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  fullWidth: PropTypes.bool,
};

export default DataDetailsRULChart;
