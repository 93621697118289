export const getMetersInPixelsForZoomLevel = (meters, latitude, zoom) => {
  // Formula converting meters to pixels for a latitude in given zoom level found at
  // https://groups.google.com/forum/#!msg/google-maps-js-api-v3/hDRO4oHVSeM/osOYQYXg2oUJ
  const metersPerPixel =
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom);
  return meters / metersPerPixel;
};

export function extendBounds(bounds, { lat, lng }) {
  if (!bounds || !bounds.nw || !bounds.se) {
    return { nw: { lat, lng }, se: { lat, lng } };
  }
  return {
    nw: {
      lat: Math.max(bounds.nw.lat, lat),
      lng: Math.min(bounds.nw.lng, lng),
    },
    se: {
      lat: Math.min(bounds.se.lat, lat),
      lng: Math.max(bounds.se.lng, lng),
    },
  };
}
