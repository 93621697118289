import { IPoint } from "../commonTypes";
import React from "react";
import Sparkline from "../../../../common/components/Sparkline/Sparkline";
import SparklineXAxis from "../../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../../common/components/Sparkline/SparklineYAxis";
import SparklineGrid from "../../../../common/components/Sparkline/SparklineGrid";
import SparklineBars from "../../../../common/components/Sparkline/SparklineBars";
import SparklineLegends from "../../../../common/components/Sparkline/SparklineLegends";

const colors = ["#004373", "#1171AC"];

type EmissionsGraphProps = {
  width: number;
  height: number;
  data: IPoint[][];
};

export const EmissionsGraph: React.FC<EmissionsGraphProps> = ({
  width,
  height,
  data,
}: EmissionsGraphProps) => {
  return (
    <Sparkline
      width={width}
      height={height}
      data={data}
      xScaleType={"band"}
      xScalePadding={0.5}
      yDomain={[0, 200]}
    >
      <SparklineXAxis />
      <SparklineYAxis ticks={5} />
      <SparklineGrid showX={false} />
      <SparklineBars barStyle={"box"} colors={colors} />
      <SparklineLegends
        legends={[
          { text: "CO2", dataSetIndex: 0, color: colors[0] },
          { text: "NOx", dataSetIndex: 1, color: colors[1] },
        ]}
      />
    </Sparkline>
  );
};
