import React from "react";
import PropTypes from "prop-types";
import { FormTextBox } from "../../../components/Form";
import styles from "./ErrorItemTypePopup.css";

export const EditItemForm = (props) => {
  const { fields, error, onChange } = props;
  return (
    <div>
      {fields && (
        <FormTextBox
          name="configJson"
          label="Config"
          value={fields.configJson.value}
          onChange={onChange}
          multiLine
          type="json"
          rows={10}
        />
      )}
      {error && <ErrorMessages error={error} />}
    </div>
  );
};

function ErrorMessages({ error }) {
  if (error.data && error.data.message) {
    return <div className={styles.errorMessage}>{error.data.message}</div>;
  }
  if (error.data) {
    return (
      <div>
        {Object.entries(error.data).map(([field, messages]) => (
          <div key={field} className={styles.errorMessage}>
            <strong>{field}</strong>
            <br />
            {messages.map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </div>
        ))}
      </div>
    );
  }
  if (error.message) {
    return <div className={styles.errorMessage}>{error.message}</div>;
  }
  return <div className={styles.errorMessage}>Error</div>;
}

EditItemForm.propTypes = {
  fields: PropTypes.shape({
    configJson: PropTypes.shape({ value: PropTypes.string }).isRequired,
  }),
  onChange: PropTypes.func.isRequired,
};
