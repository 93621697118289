import qs from "qs";

export function parseQueryString(
  queryString: string | Record<string, string> = ""
): any {
  return qs.parse(queryString, { allowDots: true, ignoreQueryPrefix: true });
}

export function serializeQueryString(
  params: string | Record<string, string> = {}
): string {
  return qs.stringify(params, {
    allowDots: true,
    skipNulls: true,
    encodeValuesOnly: true,
  });
}
