import React, { Component } from "react";
import { get } from "lodash";
import { FormSelectBox } from "../../../../../components/Form";
import { PERFORMANCE_INDICATORS } from "../../../../../config";

class GaugeEditForm extends Component {
  render() {
    const { params, onChange } = this.props;
    const performanceIndicatorId = get(
      params,
      "performanceIndicatorId",
      ""
    ).toLowerCase();
    const selected = PERFORMANCE_INDICATORS.find(
      (pi) => pi.id === performanceIndicatorId
    );
    return (
      <FormSelectBox
        name="performanceIndicatorId"
        label="Performance Indicator"
        selected={selected}
        options={PERFORMANCE_INDICATORS}
        optionValKey="id"
        optionLabelKey="name"
        onChange={onChange}
      />
    );
  }
}

export default GaugeEditForm;
