import React from "react";
import PropTypes from "prop-types";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import { formatShortMonth } from "../../common/dates";
import { GroupedBarGraph } from "../SimpleGraph";
import styles from "./EnergyBudgetResult.css";

const formatMonth = ({ date }) => `${formatShortMonth(date)}`;
const formatYear = ({ date }) => date.substr(0, 4);
const getFormatFn = (groupBy) =>
  groupBy === "month" ? formatMonth : formatYear;

export default function EnergyBudgetOverview(props) {
  const { data } = props;
  const { fuelConsumptions, groupBy } = data;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>FUEL CONSUMPTION</h2>
      </div>
      <div className={styles.graph}>
        <AutoSizer>
          {({ width, height }) => (
            <GroupedBarGraph
              width={width}
              height={height}
              groupKeyFn={getFormatFn(groupBy)}
              data={fuelConsumptions}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
}

EnergyBudgetOverview.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      groupBy: PropTypes.string.isRequired,
      fuelConsumptions: PropTypes.arrayOf(
        PropTypes.shape({
          metricName: PropTypes.string.isRequired,
          metricUnit: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              date: PropTypes.string.isRequired,
              budget: PropTypes.number.isRequired,
              actual: PropTypes.number.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
