import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { get } from "lodash";

export const getWindConfigExampleData = () => {
  return `WindDirectionRelative,WindResistanceCoefRelative
  0,-0.398543
  10,-0.451294767
  20,-0.403095672
  180,0.924283146`;
};

const initialState = {
  hasChanges: false,
  fields: {
    name: {
      value: "",
      required: true,
      isValid: false,
    },
    imo: {
      value: "",
      required: true,
      isValid: false,
    },
    company: {
      value: null,
      required: true,
      isValid: false,
    },
    vesselGroupIds: {
      value: null,
    },
    vesselGroups: {
      value: null,
      added: [],
      removed: [],
    },
    startTime: {
      value: null,
      required: true,
      isValid: false,
    },
    remoteAccessPort: {
      value: "",
    },
    edgeGatewayId: {
      value: "",
    },
    vesselImage: {},
    mdoUnitKgPerHour: {
      value: false,
      required: false,
    },
    hfoUnitKgPerHour: {
      value: false,
      required: false,
    },
    mgoUnitKgPerHour: {
      value: false,
      required: false,
    },
    methanolUnitKgPerHour: {
      value: false,
      required: false,
    },
    ulsfoUnitKgPerHour: {
      value: false,
      required: false,
    },
    vlsfoUnitKgPerHour: {
      value: false,
      required: false,
    },
    cargoType: {
      value: "",
      required: false,
    },
    subscriptionLevel: {
      value: "basic",
    },
    useManualFuelDataOnly: {
      value: false,
      required: false,
    },
  },
  accessToken: {},
  windConfig: {
    exampleData: getWindConfigExampleData(),
    visible: false,
    isLoading: false,
    hasChanges: false,
  },
  copyFromVessel: null,
};

export default createReducer(initialState, {
  [vesselConfig.vesselDetails.INITIALIZE]: (_state, { isNew }) => ({
    ...initialState,
    isNew,
  }),
  [vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA_SUCCESS]: (
    state,
    { companies, vesselGroups }
  ) => ({
    ...state,
    companies,
    vesselGroups,
    isLoading: false,
  }),
  [vesselConfig.vesselDetails.FETCH_NEW_VESSEL_DATA_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    isLoading: false,
    error,
  }),
  [vesselConfig.vesselDetails.FETCH_DETAILS]: () => ({
    ...initialState,
    isLoading: true,
  }),
  [vesselConfig.vesselDetails.FETCH_DETAILS_SUCCESS]: (
    state,
    { vesselDetails, vesselGroups, companies }
  ) => {
    const fields = {
      ...parseFieldValues(initialState.fields, vesselDetails, {
        imo: (v) => v.imo,
        name: (v) => v.name,
        startTime: (v) => v.startTime && new Date(v.startTime),
        company: (v) => v.companyId,
        remoteAccessPort: (v) => v.remoteAccessPort,
        edgeGatewayId: (v) => v.edgeGatewayId,
        vesselGroupIds: (v) => v.vesselGroups,
        vesselGroups: (v) => v.vesselGroups,
        mdoUnitKgPerHour: (v) => v.mdoUnitKgPerHour,
        hfoUnitKgPerHour: (v) => v.hfoUnitKgPerHour,
        mgoUnitKgPerHour: (v) => v.mgoUnitKgPerHour,
        methanolUnitKgPerHour: (v) => v.methanolUnitKgPerHour,
        ulsfoUnitKgPerHour: (v) => v.ulsfoUnitKgPerHour,
        vlsfoUnitKgPerHour: (v) => v.vlsfoUnitKgPerHour,
        windCoefficients: (v) => get(v, "windCoefficients", ""),
        cargoType: (v) => get(v, "cargoType"),
        useManualFuelDataOnly: (v) => v.useManualFuelDataOnly,
      }),
      vesselImage: { src: vesselDetails.imageUrl },
    };

    return {
      ...initialState,
      fields,
      hasAccessToken: vesselDetails.hasAccessToken,
      companies,
      vesselGroups,
      windConfig: {
        ...state.windConfig,
        value: vesselDetails.windCoefficients,
      },
    };
  },
  [vesselConfig.vesselDetails.FETCH_DETAILS_ERROR]: (_state, error) => {
    return {
      ...initialState,
      error,
    };
  },
  [vesselConfig.vesselDetails.INPUT_CHANGED]: (
    state,
    { field, value, isValid }
  ) => ({
    ...state,
    hasChanges: true,
    fields: {
      ...state.fields,
      [field]: {
        ...state.fields[field],
        value,
        isValid,
        hasChanges: true,
      },
    },
  }),
  [vesselConfig.vesselDetails.COMPANY_CHANGED]: (
    state,
    { value, isValid }
  ) => ({
    ...state,
    hasChanges: true,
    fields: {
      ...state.fields,
      company: {
        ...state.fields.company,
        value,
        isValid,
      },
      vesselGroups: {
        ...state.fields.vesselGroups,
        value: initialState.fields.vesselGroups.value,
      },
    },
  }),
  [vesselConfig.vesselDetails.VESSEL_GROUP_CHANGED]: (
    state,
    { value, isValid }
  ) => {
    return {
      ...state,
      hasChanges: true,
      fields: {
        ...state.fields,
        vesselGroups: {
          ...state.fields.vesselGroups,
          value: value,
          isValid,
          hasChanges: true,
        },
      },
    };
  },
  [vesselConfig.vesselDetails.VESSEL_IMAGE_CHANGED]: (state, { file }) => ({
    ...state,
    hasChanges: true,
    fields: {
      ...state.fields,
      vesselImage: {
        file,
        fileName: file.name,
        hasChanges: true,
      },
    },
  }),
  [vesselConfig.vesselDetails.WIND_CONFIG_CHANGED]: (state, { file }) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      file,
      hasChanges: true,
    },
  }),
  [vesselConfig.vesselDetails.SHOW_GENERATE_API_TOKEN_DIALOG]: (state) => ({
    ...state,
    accessToken: {
      isVisible: true,
    },
  }),
  [vesselConfig.vesselDetails.CLOSE_GENERATE_API_TOKEN_DIALOG]: (state) => ({
    ...state,
    accessToken: initialState.accessToken,
  }),
  [vesselConfig.vesselDetails.GENERATE_API_TOKEN]: (state) => ({
    ...state,
    accessToken: {
      ...state.accessToken,
      isConfirmed: true,
      isLoading: true,
    },
  }),
  [vesselConfig.vesselDetails.GENERATE_API_TOKEN_SUCCESS]: (
    state,
    { data }
  ) => ({
    ...state,
    hasAccessToken: true,
    accessToken: {
      ...state.accessToken,
      data,
      isLoading: false,
    },
  }),
  [vesselConfig.vesselDetails.GENERATE_API_TOKEN_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    accessToken: {
      ...state.accessToken,
      error,
      isLoading: false,
    },
  }),
  [vesselConfig.vesselDetails.SHOW_WINDCONFIG_POPUP]: (state) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      isVisible: true,
    },
  }),
  [vesselConfig.vesselDetails.HIDE_WINDCONFIG_POPUP]: (state) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      file: null,
      hasChanges: false,
      isVisible: false,
    },
  }),
  [vesselConfig.vesselDetails.DOWNLOAD_WINDCONFIG_ERROR]: (
    state,
    { error }
  ) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      isLoading: false,
      error,
    },
  }),
  [vesselConfig.vesselDetails.SAVE_WINDCONFIG]: (state) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      isLoading: true,
    },
  }),
  [vesselConfig.vesselDetails.SAVE_WINDCONFIG_SUCCESS]: (state) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      hasChanges: false,
      isLoading: false,
      isVisible: false,
      file: null,
    },
  }),
  [vesselConfig.vesselDetails.SAVE_WINDCONFIG_ERROR]: (state, { error }) => ({
    ...state,
    windConfig: {
      ...state.windConfig,
      isLoading: false,
      error,
    },
  }),
  [vesselConfig.vesselDetails.SET_PROGRESS_MESSAGE]: (state, { message }) => ({
    ...state,
    progressMessage: message,
    error: undefined,
  }),
  [vesselConfig.vesselDetails.SAVE_VESSEL]: (state) => ({
    ...state,
    isLoading: true,
    showConfirm: false,
  }),
  [vesselConfig.vesselDetails.SAVE_VESSEL_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
    progressMessage: null,
    error: undefined,
  }),
  [vesselConfig.vesselDetails.SAVE_VESSEL_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    progressMessage: null,
    error,
    showConfirm: false,
  }),
  [vesselConfig.vesselDetails.COPY_FROM_VESSEL_CHANGED]: (
    state,
    { copyFromVessel }
  ) => ({
    ...state,
    hasChanges: true,
    copyFromVessel,
  }),
  [vesselConfig.vesselDetails.SHOW_SAVE_DIALOG]: (state) => ({
    ...state,
    showConfirm: true,
  }),
  [vesselConfig.vesselDetails.HIDE_SAVE_DIALOG]: (state) => ({
    ...state,
    showConfirm: false,
  }),
});

const parseFieldValues = (initialValues, data, mappings) => {
  return Object.fromEntries(
    Object.entries(initialValues).map(([fieldKey, field]) => {
      const mapping = mappings[fieldKey];
      const value = mapping ? mapping(data) : field.value;
      const isValid = !field.required || Boolean(value);
      return [
        fieldKey,
        {
          ...field,
          value,
          isValid,
        },
      ];
    })
  );
};

export function areFieldsValid({ fields }) {
  return Object.values(fields).every((x) => x.isValid !== false);
}
