import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleVesselItemDetails } from "../../actions/action.toggleVesselItemDetails";
import {
  getMaxColumnCount,
  prepareVesselItemCategories,
  prepareVesselItemDetails,
} from "../../reducers/reducer.itemTableData";
import MetricsList from "../MetricsList/MetricsList";
import Loader from "../../../common/components/Loader/Loader";
import styles from "./VesselItemsTable.css";
import { getVesselItemsWithMetrics } from "../../selectors/common";

class VesselItemsTable extends PureComponent {
  render() {
    const { data, error } = this.props.itemsTable;

    if (!data) {
      return (
        <div className={styles.loaderContainer}>
          <Loader text={"Loading metric data"} error={error} expand={true} />
        </div>
      );
    }

    return (
      <div className={styles.tableContainer}>
        {data.map((category) => {
          return (
            <MetricsList
              key={category.itemCategoryId}
              columnCount={getMaxColumnCount([{ data: category.data }])}
              title={category.title}
              vesselItemData={category.data}
              vesselItemDetails={this.props.vesselItemDetails}
              toggleVesselItemDetails={this.props.toggleVesselItemDetails}
              toggleTrendVesselItem={this.props.toggleTrendVesselItem}
              dateRange={this.props.date.range}
              timeOffset={parseInt(this.props.date.timeOffset)}
              states={this.props.states}
            />
          );
        })}
      </div>
    );
  }
}

VesselItemsTable.propTypes = {
  date: PropTypes.shape({
    range: PropTypes.any.isRequired,
  }).isRequired,
  toggleTrendVesselItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const vesselItemsMetrics =
    getVesselItemsWithMetrics(props.vesselId, state.pageContext) || [];
  const data = prepareVesselItemCategories(
    state.itemTableData,
    vesselItemsMetrics
  );
  const vesselItemDetails = prepareVesselItemDetails(
    state.fetchVesselItemDetails,
    vesselItemsMetrics
  );
  return {
    vesselItemDetails: vesselItemDetails,
    itemsTable: {
      ...state.itemTableData,
      data,
    },
  };
};

const mapDispatchToProps = {
  toggleVesselItemDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(VesselItemsTable);
