import React from "react";
import { DirectionPickerProps } from "./Types";
import styles from "./DirectionPicker.css";
import { Direction } from "../../common/direction";

const DirectionPicker: React.FC<DirectionPickerProps> = ({
  onClick,
  direction,
}: DirectionPickerProps) => {
  function getDirectionString(direction: Direction): string {
    let result;
    switch (direction) {
      case Direction.HighToLow:
        result = "Highest";
        break;
      case Direction.LowToHigh:
        result = "Lowest";
        break;
      default:
        throw new Error(`Unknown direction ${direction}`);
    }
    return result;
  }

  return (
    <div
      onClick={onClick}
      className={styles.directionPicker}
      data-test-id="directionPicker"
    >
      Show: {getDirectionString(direction)} ⥯
    </div>
  );
};

export default DirectionPicker;
