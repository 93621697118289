import React from "react";
import styles from "./VesselColumn.css";
import { VesselIcon } from "./VesselIcon";

interface Props {
  name: string;
  imo: number;
}

export const VesselColumn = ({ name, imo }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.illustration}>
        <VesselIcon />
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.imo}>IMO - {imo}</div>
    </div>
  );
};
