import React from "react";
import PropTypes from "prop-types";
import styles from "./DataList.css";
import classNames from "../../../common/classNames";
import { Icon } from "../../../common/components/Icon/Icon";

const convertToIconSize = (size) => (size === "large" ? "l" : null);

class DataListRow extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(evt) {
    const { onClick, tag } = this.props;
    if (onClick) {
      onClick(tag, evt);
    }
  }

  renderRowTitle(title, description, icon, size) {
    return (
      <div className={styles.dataListRowTitleContainer} data-type={size}>
        <Icon icon={icon} size={convertToIconSize(size)} />
        <div className={styles.textContainer}>
          <h2 className={styles.categoryTitle}>{title}</h2>
          <p className={styles.categoryDescription}>{description}</p>
        </div>
      </div>
    );
  }

  render() {
    const {
      title,
      description,
      icon,
      size,
      children,
      tag,
      showDetails,
      renderDetails,
      clickable,
    } = this.props;
    let childArray = React.Children.toArray(children);
    let flexBasis = `${100 / (childArray.length + 1)}%`;
    return (
      <div>
        <div
          className={classNames(
            styles.dataListRow,
            clickable ? styles.clickable : ""
          )}
          data-type={size}
          onClick={this.onClick}
        >
          <div
            className={styles.dataListCellContainer}
            style={{ flexBasis: flexBasis }}
          >
            {this.renderRowTitle(title, description, icon, size)}
          </div>
          {childArray.map((child, index) => {
            return (
              <div
                className={styles.dataListCellContainer}
                key={index}
                style={{ flexBasis: flexBasis }}
              >
                {child}
              </div>
            );
          })}
        </div>
        {showDetails && renderDetails && (
          <div className={styles.dataListRowDetailsContainer}>
            {renderDetails(tag)}
          </div>
        )}
      </div>
    );
  }
}

DataListRow.defaultProps = {
  size: "large",
  showDetails: false,
  clickable: false,
};

DataListRow.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  showDetails: PropTypes.bool,
  renderDetails: PropTypes.func,
  tag: PropTypes.any,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DataListRow;
