import {
  FETCH_USER_DATA_SUCCESS,
  SAVE_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_USER_SETTING,
} from "../actions/action.types";
import { convertObjToFields, checkFormValidation } from "../common/forms";

const userProfile = (
  state = {
    userSettings: {
      fields: {},
      isValid: true,
      hasChanges: false,
    },
    restrictedViews: [],
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_USER_DATA_SUCCESS: {
      return {
        ...state,
        userSettings: {
          fields: convertObjToFields(action.data.userDetails),
          hasChanges: false,
          isValid: true,
        },
        restrictedViews: action.data.restrictedViews || [],
      };
    }
    case SAVE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userSettings: {
          fields: convertObjToFields(action.data),
          hasChanges: false,
          isValid: true,
        },
      };
    }
    case SET_USER_PROFILE_USER_SETTING: {
      let field = state.userSettings[action.name];
      return {
        ...state,
        userSettings: checkFormValidation({
          ...state.userSettings,
          fields: {
            ...state.userSettings.fields,
            [action.name]: {
              ...field,
              value: action.value,
              isValid: action.valid !== undefined ? action.valid : true,
              message: action.message || "",
              hasChanges: true,
            },
          },
        }),
      };
    }
    default:
      return state;
  }
};

export default userProfile;
