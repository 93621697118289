import React, { useEffect, useState } from "react";
import { userHasAccess } from "../../common/hasAccess";
import { Header } from "../Header";
import VesselSelector from "../VesselSelector/VesselSelector";
import NoAccess from "../../views/Auth/NoAccess/NoAccess";
import { get } from "lodash";
import { getViewsByPath } from "../../common/views";
import { useQueryContext } from "../../providers/QueryContextProvider";
import { useAppSelector } from "../../store/rootState";

interface Props {
  children: React.ReactNode;
}

const EnsureAccess = ({ children }: Props) => {
  const { pathname, vesselId } = useQueryContext();
  const vessels = useAppSelector((state) => state.pageContext.vessels);
  const user = useAppSelector((state) => state.user);
  const [hasAccess, setHasAccess] = useState(
    userHasAccess(vessels, vesselId, pathname, user)
  );

  useEffect(() => {
    setHasAccess(userHasAccess(vessels, vesselId, pathname, user));
  });

  if (!hasAccess) {
    const title = get(getViewsByPath(), [pathname, "name"], "");
    return (
      <div>
        <Header title={title} contentDistribution="start">
          <div>
            <VesselSelector />
          </div>
        </Header>
        <NoAccess />
      </div>
    );
  }
  return <>{children}</>;
};

export default EnsureAccess;
