import { FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS } from "./action.types";
import { HEALTH_MANAGEMENT_EVENT_DETAILS } from "../api";
import fetch from "./action.fetch";

export const fetchHealthEventDetails = (eventId) =>
  fetch({
    url: HEALTH_MANAGEMENT_EVENT_DETAILS.get(eventId),
    actionType: FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS,
    actionData: {
      eventId,
    },
  });
