import React from "react";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import styles from "./DataDetailsList.css";
import DataDetailsListItem from "./DataDetailsListItem";

class DataDetailsList extends React.PureComponent {
  renderListItems(itemHandlers, itemDataProvider) {
    const { items, ...props } = this.props;
    if (!items) return;

    return items.map((item, i) => {
      const itemHandler = itemHandlers[item.listItemType];
      return (
        <DataDetailsListItem
          key={`list_item_${i}`}
          {...item}
          {...props}
          {...itemHandler}
          dataProvider={itemDataProvider}
        />
      );
    });
  }

  render() {
    const { title } = this.props;
    return (
      <DataDetailsComponentContainer style={{ minWidth: "100%", padding: "0" }}>
        <div className={styles.listHeader}>{title}</div>
        <DataDetailsListContext.Consumer>
          {({ itemHandlers, itemDataProvider }) => (
            <div className={styles.listContent}>
              {this.renderListItems(itemHandlers, itemDataProvider)}
            </div>
          )}
        </DataDetailsListContext.Consumer>
      </DataDetailsComponentContainer>
    );
  }
}

export const DataDetailsListContext = React.createContext({
  itemHandlers: {},
});

export default DataDetailsList;
