import React from "react";
import PropTypes from "prop-types";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import Loader from "../../../common/components/Loader/Loader";
import styles from "./EnergyBudgetResult.css";
import { isFinite } from "lodash";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import SparklineBars from "../../../common/components/Sparkline/SparklineBars";
import SparklineLegends from "../../../common/components/Sparkline/SparklineLegends";
import { color } from "../../../common/colors";

const colors = [
  color("--blue-dark"),
  color("--blue-bright"),
  color("--yellow-dark"),
  color("--yellow-base"),
];

const maxOf = (data) => Math.max(data.budgetValue, data.actualValue);

const withHeadroom = (val) => Math.round(val * 1.1);

const margin = {
  top: 20,
  right: 20,
  bottom: 70,
  left: 20,
};

export default function EnergyBudgetResult({ data, error }) {
  if (!data) {
    return <Loader text="Loading energy budget" error={error} expand={true} />;
  }

  let scaleCoeff;
  const metricsData = data.slice(0, 2);
  const firstMetricData = metricsData[0];
  const secondMetricData = metricsData[1];
  const barGraphData = [];

  metricsData.forEach((d) => {
    if (d.budgetValue > 0 || d.actualValue > 0) {
      barGraphData.push(
        [{ x: 0, y: d.budgetValue }],
        [{ x: 0, y: d.actualValue }]
      );
    }
  });

  if (secondMetricData) {
    const leftYAxis = maxOf(firstMetricData);
    const rightYAxis = maxOf(secondMetricData);
    scaleCoeff = leftYAxis / rightYAxis;
  }

  margin.bottom = hasData(secondMetricData) ? 5 : 70;

  function getFuelType(metricName) {
    const startIndex = metricName.indexOf("(");
    const endIndex = metricName.indexOf(")");
    return metricName.substring(startIndex + 1, endIndex);
  }

  function hasData(metricData) {
    return (
      metricData &&
      isFinite(metricData.budgetValue) &&
      isFinite(metricData.actualValue) &&
      (metricData.budgetValue > 0 || metricData.actualValue > 0)
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>FUEL CONSUMPTION</h2>
      </div>

      <div className={styles.graph}>
        {!hasData(firstMetricData) && !hasData(secondMetricData) ? (
          <div className={styles.emptyContent}>No data</div>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <Sparkline
                width={width}
                height={height}
                data={barGraphData}
                xScaleType="band"
                xScalePadding={!hasData(secondMetricData) ? 0.5 : 0}
                yDomain={
                  hasData(secondMetricData)
                    ? [
                        [0, withHeadroom(maxOf(firstMetricData))],
                        [0, withHeadroom(maxOf(firstMetricData))],
                      ]
                    : [[0, withHeadroom(maxOf(firstMetricData))]]
                }
                margin={margin}
              >
                <SparklineXAxis showTickValues={false} />
                <SparklineYAxis
                  labelYPosition={-10}
                  showTickValues={true}
                  label={`${firstMetricData.metricUnit} ${getFuelType(
                    firstMetricData.metricName
                  )}`}
                />
                {hasData(secondMetricData) ? (
                  <SparklineYAxis
                    yScaleCoeff={scaleCoeff}
                    labelYPosition={-10}
                    showTickValues={true}
                    label={`${getFuelType(secondMetricData.metricName)} ${
                      secondMetricData.metricUnit
                    }`}
                    position={"right"}
                    yDomainIndex={1}
                  />
                ) : null}
                <SparklineGrid showX={false} />
                <SparklineBars
                  barStyle="box"
                  colors={colors}
                  yScaleCoeff={scaleCoeff}
                />
                {!hasData(secondMetricData) ? (
                  <SparklineLegends
                    legends={[
                      {
                        text: `${getFuelType(
                          firstMetricData.metricName
                        )} Budget`,
                        color: colors[0],
                      },
                      {
                        text: `${getFuelType(
                          firstMetricData.metricName
                        )} Actual`,
                        color: colors[1],
                      },
                    ]}
                  />
                ) : (
                  <>
                    <SparklineLegends
                      horizontalPosition={"left"}
                      legends={[
                        {
                          text: `${getFuelType(
                            firstMetricData.metricName
                          )} Budget`,
                          color: colors[0],
                        },
                      ]}
                    />
                    <SparklineLegends
                      legendYOffset={20}
                      horizontalPosition={"left"}
                      legends={[
                        {
                          text: `${getFuelType(
                            firstMetricData.metricName
                          )} Actual`,
                          color: colors[1],
                        },
                      ]}
                    />
                    <SparklineLegends
                      legendXOffset={width / 2 - margin.left - margin.right}
                      horizontalPosition={"left"}
                      legends={[
                        {
                          text: `${getFuelType(
                            secondMetricData.metricName
                          )} Budget`,
                          color: colors[2],
                        },
                      ]}
                    />
                    <SparklineLegends
                      legendYOffset={20}
                      legendXOffset={width / 2 - margin.left - margin.right}
                      horizontalPosition={"left"}
                      legends={[
                        {
                          text: `${getFuelType(
                            secondMetricData.metricName
                          )} Actual`,
                          color: colors[3],
                        },
                      ]}
                    />
                  </>
                )}
              </Sparkline>
            )}
          </AutoSizer>
        )}
      </div>
    </div>
  );
}

EnergyBudgetResult.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      budgetValue: PropTypes.number.isRequired,
      actualValue: PropTypes.number.isRequired,
      metricUnit: PropTypes.string.isRequired,
      metricName: PropTypes.string.isRequired,
    })
  ),
  error: PropTypes.any,
};
