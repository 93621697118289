import { isEqual } from "lodash";
import { isDateRangeDifferent, isTimeOffsetDifferent } from "./dateFilter";

export const createKey = (id, context) => {
  if (context.groupId) {
    return `${context.groupId}_${id}`;
  } else if (context.vesselId) {
    return `${context.vesselId}_${id}`;
  }
  return id;
};

export const isContextValid = ({ groupId, vesselId, dateRange }) =>
  (groupId || vesselId) && dateRange;

export const isContextChanged = (context, prevContext) =>
  context.groupId !== prevContext.groupId ||
  context.vesselId !== prevContext.vesselId ||
  !isEqual(context.states, prevContext.states) ||
  isDateRangeDifferent(context.dateRange, prevContext.dateRange) ||
  isTimeOffsetDifferent(context.timeOffset, prevContext.timeOffset);
