import React from "react";
import PropTypes from "prop-types";
import styles from "./MetadataConfig.css";
import { MetadataConfigField } from "./MetadataConfigField";
import { get } from "lodash";

export const MetadataConfig = (props) => {
  const { metadataFields, fields, title, onChange } = props;
  if (!metadataFields || metadataFields.length === 0) {
    return <div />;
  }

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      <div>{renderFields(metadataFields, fields, onChange)}</div>
    </div>
  );
};

const renderFields = (metadataFields, fields, onChange) => {
  return metadataFields.map((m) => {
    return (
      <MetadataConfigField
        key={m.name}
        label={m.label}
        name={m.name}
        type={m.type}
        unit={m.unit}
        required={m.required}
        options={m.options}
        integerRange={m.integerRange}
        value={get(fields, m.name + ".value")}
        onChange={onChange}
        isValid={get(fields, m.name + ".isValid")}
        validationLabel={get(fields, m.name + ".validationLabel")}
        validationMessage={get(fields, m.name + ".validationMessage")}
      />
    );
  });
};

MetadataConfig.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  fields: PropTypes.object.isRequired,
  metadataFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
      unit: PropTypes.string,
      value: PropTypes.oneOf([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
      isValid: PropTypes.bool,
      validationLabel: PropTypes.string,
      validationMessage: PropTypes.string,
    })
  ),
};

export default MetadataConfig;
