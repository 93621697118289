import {
  operationClassColor,
  OperationClassID,
  operationClassLegend,
  operationClassTitle,
} from "../operation/operationClassID";
import { IOperationClass } from "./IOperationClass";
import {
  OperationId,
  operationClassToOperations,
} from "../operation/operationId";

export abstract class OperationClass implements IOperationClass {
  id: OperationClassID;
  abbreviation: string;
  color: string;
  title: string;

  getOperationModes(): OperationId[] {
    return operationClassToOperations[this.id];
  }

  protected constructor(id: OperationClassID) {
    this.id = id;
    this.abbreviation = operationClassLegend[id];
    this.color = operationClassColor[id];
    this.title = operationClassTitle[id];
  }
}
