import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { COSMETIC_CONSTS } from "../../../onshore/config";
import { color as c } from "../../colors";
import { SparklineContext } from "./Sparkline";

class SparklineYAxis extends React.PureComponent {
  componentDidMount() {
    if (this.props.position === "left") {
      this.props.addMarginProvider(this);
    }
  }

  getMargins() {
    const bb = this.containerElement.getBoundingClientRect();
    const position = this.props.position === "right" ? "right" : "left";
    return {
      [position]: bb.width + 10,
    };
  }

  componentWillUnmount() {
    if (this.props.position === "left") {
      this.props.removeMarginProvider(this);
    }
  }

  roundScaleValue(t, yScaleCoeff) {
    let value = t / yScaleCoeff;
    if (value > -1 && value < 1 && value !== 0) {
      value = value.toFixed(1);
    } else {
      value = value.toFixed(0);
    }
    return value;
  }

  getYTicks() {
    const {
      minY,
      maxY,
      ticks,
      yScaleType,
      yDomain,
      yDomainIndex,
      uniqueYValues,
      useUniqueValuesForBand,
    } = this.props;

    if (yScaleType === "band") {
      return useUniqueValuesForBand ? uniqueYValues : yDomain[yDomainIndex];
    }
    return d3.ticks(minY[yDomainIndex], maxY[yDomainIndex], ticks);
  }

  getStyles(positionLeft) {
    const { color, opacity } = this.props;

    const lineStyle = {
      fill: "none",
      strokeWidth: 2,
      stroke: color,
      strokeOpacity: opacity,
    };

    const tickStyle = {
      fill: "none",
      strokeWidth: 2,
      stroke: color,
      strokeOpacity: opacity,
      shapeRendering: "crispEdges",
    };

    const textStyle = {
      fill: color,
      fillOpacity: opacity,
      stroke: "none",
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 12,
      textAnchor: "end",
      alignmentBaseline: "central",
    };

    const labelStyle = {
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 12,
      textAnchor: positionLeft ? "start" : "end",
      alignmentBaseline: "central",
      fill: color,
      fillOpacity: opacity,
    };

    return { lineStyle, tickStyle, textStyle, labelStyle };
  }

  render() {
    const {
      width,
      height,
      scaleY,
      label,
      labelYPosition,
      yScaleCoeff,
      showTickValues,
      position,
      yOffset,
      yDomainIndex,
      axisWidth,
    } = this.props;

    const yTicks = this.getYTicks();
    const positionLeft = position === "left";
    const currentScaleY = scaleY[yDomainIndex];
    const { lineStyle, tickStyle, textStyle, labelStyle } =
      this.getStyles(positionLeft);

    return (
      <g>
        <line
          style={lineStyle}
          x1={positionLeft ? 0 : width}
          y1={0}
          x2={positionLeft ? 0 : width}
          y2={height}
        />
        <g
          transform={`translate(0 ${yOffset[yDomainIndex]})`}
          ref={(el) => (this.containerElement = el)}
        >
          {yTicks.map((t) => (
            <line
              style={tickStyle}
              key={`xLine${t}`}
              x1={positionLeft ? 0 : width}
              y1={currentScaleY(t)}
              x2={positionLeft ? -3 : width - 3}
              y2={currentScaleY(t)}
            />
          ))}
          {showTickValues &&
            yTicks.map((t) => (
              <text
                style={textStyle}
                key={`xAxisLabel${t}`}
                x={positionLeft ? -5 : width - 5}
                y={currentScaleY(t)}
              >
                {yScaleCoeff ? this.roundScaleValue(t, yScaleCoeff) : t}
              </text>
            ))}
          {axisWidth && (
            <line
              x1={axisWidth * -1}
              x2={0}
              y1={10}
              y2={0}
              style={{ ...lineStyle, opacity: 0 }}
            />
          )}
        </g>
        {label && (
          <text
            style={labelStyle}
            x={positionLeft ? 5 : width - 5}
            y={labelYPosition}
          >
            {" "}
            {/*TODO: Moved label temporarily. Should be parameterized, instead.*/}
            {label}
          </text>
        )}
      </g>
    );
  }
}

SparklineYAxis.defaultProps = {
  ticks: 6,
  width: 30,
  showTickValues: true,
  position: "left",
  color: c("--light-grey"),
  opacity: 1,
  type: "SparklineYAxis",
  labelYPosition: 0,
  yDomainIndex: 0,
};

SparklineYAxis.propTypes = {
  ticks: PropTypes.number,
  width: PropTypes.number,
  showTickValues: PropTypes.bool,
  position: PropTypes.oneOf(["left", "right"]),
  color: PropTypes.string,
  label: PropTypes.string,
  axisWidth: PropTypes.number,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineYAxis {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
