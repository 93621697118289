import React, { useEffect } from "react";
import { useRootContext } from "../index";

const SilentLoginView = () => {
  const { authService } = useRootContext();
  useEffect(() => {
    authService.silentLoginCallback().catch(() => {
      console.log("Unable to log in silently");
    });
  }, []);

  return <div />;
};

export default SilentLoginView;
