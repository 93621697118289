import { baseApi } from "../../store/baseApi";
import { FleetPerformanceRequest, FleetPerformanceResponse } from "./types";

const fleetPerformanceApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFleetPerformance: build.query<
      FleetPerformanceResponse,
      FleetPerformanceRequest
    >({
      query: (request) => ({
        url: `/api/v2/fleet-performance/fleet-performance-ordered-by-${request.sorting.type}`,
        params: {
          pageNumber: request.pageNumber,
          pageSize: request.pageSize,
          sorting: request.sorting.sorting,
          sortingOrder: request.sortingOrder,
          groupId: request.groupId,
          from: request.from,
          to: request.to,
          operationIds: request.operationIds,
        },
      }),
    }),
  }),
});

export const { useGetFleetPerformanceQuery } = fleetPerformanceApi;
