import PropTypes from "prop-types";
import React from "react";
import { debounce } from "lodash";

export default class AutoSizer extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    window.addEventListener("resize", this.debouncedMeasure);
    this.measure();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedMeasure);
  }

  measure = () => {
    const parent = this.ref && this.ref.parentNode;
    if (parent) {
      const width = parent.offsetWidth || 0;
      const height = parent.offsetHeight || 0;
      this.setState({ width, height });
    }
  };

  debouncedMeasure = debounce(this.measure, 250);

  render() {
    const { width, height } = this.state;
    return (
      <div
        ref={(ref) => (this.ref = ref)}
        style={{ height: 0, width: 0, overflow: "visible" }}
      >
        {this.props.children({ width, height })}
      </div>
    );
  }
}
