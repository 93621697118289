import PropTypes from "prop-types";
import React from "react";
import styles from "./PrevNextArrow.css";

export default class PrevNextArrow extends React.Component {
  directionStyle() {
    if (this.props.dir === "prev") {
      return {
        transform: "rotate(90deg)",
      };
    } else {
      return {
        transform: "rotate(-90deg)",
      };
    }
  }

  visible() {
    if (this.props.visible === false) {
      return {
        visibility: "hidden",
      };
    } else {
      return {
        display: "block",
      };
    }
  }

  render() {
    return (
      <div className={styles.prevNextArrowContainer} style={this.visible()}>
        <button
          className={styles.button}
          data-disabled={this.props.disabled}
          onClick={this.props.clickAction}
          data-test-id={this.props.testId}
        >
          <svg
            style={this.directionStyle()}
            className={styles.arrowSvg}
            x="0px"
            y="0px"
            viewBox="0 0 16 16"
            enableBackground="new 0 0 16 16"
            xmlSpace="preserve"
          >
            <polygon points="8,12.3 0,4.3 0.5,3.7 8,11.2 15.5,3.7 16,4.3 " />
          </svg>
        </button>
      </div>
    );
  }
}

PrevNextArrow.propTypes = {
  dir: PropTypes.string,
  visible: PropTypes.bool,
  clickAction: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};
