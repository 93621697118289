import PropTypes from "prop-types";
import React from "react";
import styles from "./DatePicker.css";
import centeredModal from "../../../style/css/CenteredModal.css";
import YearPicker from "./YearPicker";
import QuarterPicker from "./QuarterPicker";
import MonthPicker from "./MonthPicker";
import DateRangePicker from "./DateRangePicker";
import TimeOffsetPicker from "./TimeOffsetPicker";
import { VesselStatePicker } from "./VesselStatePicker";

const titles = {
  year: "Year",
  quarter: "Quarter",
  month: "Month",
  dateRange: "Custom",
  timeOffset: "Time offset",
  leg: "Leg",
  voyage: "Voyage",
};

const createVesselStatePicker = (fetchProp) => (props) =>
  <VesselStatePicker fetch={props[fetchProp]} {...props} />;

const components = {
  year: YearPicker,
  quarter: QuarterPicker,
  month: MonthPicker,
  timeOffset: TimeOffsetPicker,
  dateRange: DateRangePicker,
  leg: createVesselStatePicker("fetchLegs"),
  voyage: createVesselStatePicker("fetchVoyages"),
};

const DatePicker = (props) => {
  const { supportedTypes, type, hide, changeType } = props;

  return (
    <div className={centeredModal.container}>
      <div onClick={hide} className={centeredModal.overlay} />

      <div className={centeredModal.boxContainer}>
        <div className={centeredModal.content}>
          <div className={centeredModal.header}>
            <div className={styles.tabTogglers}>
              {supportedTypes.map((t) => (
                <button
                  key={t}
                  data-active={t === type}
                  className={styles.tabToggler}
                  onClick={() => changeType(t)}
                  data-test-id="pickerModal-tabs"
                >
                  {titles[t]}
                </button>
              ))}
            </div>
            <button className={centeredModal.resetBtn} onClick={hide}>
              ×
            </button>
          </div>
          <div>{React.createElement(components[type], props)}</div>
        </div>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  supportedTypes: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  hide: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
};

export default DatePicker;
