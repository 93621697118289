import classNames from "../../classNames";
import styles from "./Loader.css";
import t from "prop-types";
import React from "react";
import { compact, isString } from "lodash";
import Button from "../Button";

export default class Loader extends React.Component {
  render() {
    const getError = () =>
      isString(this.props.error) ? this.props.error : "Error";
    const text = this.props.error ? getError() : this.props.text;
    const showSpinner = !this.props.error;
    const textClass = this.props.error ? styles.error : styles.text;
    const compactedClassNames = compact([
      styles.loaderContainer,
      this.props.expand && styles.expand,
      this.props.className,
    ]);
    return (
      <div className={compactedClassNames.join(" ")}>
        {showSpinner && <div className={styles.spinner}>|</div>}
        <h3 className={textClass}>{text}</h3>
        {!showSpinner && this.props.resetAction && (
          <div className={styles.resetButton}>
            <Button value="Reset" clickAction={this.props.resetAction} />
          </div>
        )}
      </div>
    );
  }
}

export class InlineSpinner extends React.PureComponent {
  render() {
    return (
      <div className={classNames(styles.inline, styles.loaderContainer)}>
        <div className={styles.spinner}>|</div>
      </div>
    );
  }
}

Loader.propTypes = {
  text: t.string,
  error: t.any,
  expand: t.bool,
  className: t.string,
  resetAction: t.func,
};

Loader.defaultProps = {
  text: "Loading",
};
