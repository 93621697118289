import React from "react";
import styles from "./FuelTarget.css";
import DataGridView from "../DataGridView/DataGridView";
import { IFuelAndTargetItem, IFuelAndTargetState } from "../commonTypes";
import Loader from "../../../../common/components/Loader";
import AutoSizer from "../../../../common/components/AutoSizer/AutoSizer";
import editIcon from "../../../../style/img/edit.svg";
import { useDispatch } from "react-redux";
import { setTargetFuelDataOpen } from "../../../actions/action.fuelEmissions";
import { DataItem } from "../DataGridView/Types";
import { generateUUID } from "../../../../common/id";

const colors = {
  red: "#fc406a",
  green: "#39bf65",
};

interface IProps {
  data: IFuelAndTargetState;
}

function FuelTarget({ data }: IProps): JSX.Element {
  const dispatch = useDispatch();
  const setTargetFuelDetailsClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(setTargetFuelDataOpen());
  };

  function getGridData(data: IFuelAndTargetItem[]): DataItem[] {
    if (data === undefined) {
      return [];
    }

    return data.map((item) => {
      const gridItem: DataItem = { id: generateUUID() };

      Object.entries(item).forEach(([key, value]) => {
        gridItem[key] = value;
      });

      return gridItem;
    });
  }

  return data.isLoading ? (
    <Loader text="Loading Fuel & Target" />
  ) : (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <div className={styles.fuelTargetContainer} style={{ height, width }}>
          <DataGridView
            data={getGridData(data.filteredData)}
            columns={columns(setTargetFuelDetailsClick)}
          />
        </div>
      )}
    </AutoSizer>
  );
}

const columns = (
  action: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
) => [
  {
    field: "operationMode",
    unit: "",
  },
  {
    name: "Run time",
    field: "runTime",
    unit: "h",
  },
  {
    name: "Actual Fuel Use",
    field: "actualFuelUse",
    unit: "t/h",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customStyleFn: (item: IFuelAndTargetItem) =>
      getColor(item.actualFuelUse, item.targetFuelUse),
  },
  {
    name: "Target Fuel Use",
    field: "targetFuelUse",
    unit: "t/h",
    link: {
      title: "",
      action,
      icon: editIcon,
    },
  },
];

const getColor = (
  actual: number | string,
  target: number | string
): React.CSSProperties => {
  if (!+target) {
    return {};
  }

  const diff = +actual - +target;
  if (diff > 0) {
    return { color: colors.red };
  } else if (diff < 0) {
    return { color: colors.green };
  } else {
    return {};
  }
};

export default FuelTarget;
