import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { some } from "lodash";
import Loader from "../../../../../common/components/Loader/Loader";
import styles from "./HealthManagementEventEdit.css";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import FormTextBox from "../../../../components/Form/FormTextBox/FormTextBox";
import FormSelectBox from "../../../../components/Form/FormSelectBox/FormSelectBox";
import { formatDateTimeWithUtc } from "../../../../common/dates";
import { approvalStatuses } from "../../../../reducers/admin/reducer.healthManagementEvent";
import ReactMarkdown from "react-markdown";
import Button from "../../../../../common/components/Button";
import { FormCheckBox } from "../../../../components/Form";
import Popup from "../../../../components/Popup/Popup";

const formatDateTimeString = (dateTimeString) => {
  if (dateTimeString) return formatDateTimeWithUtc(new Date(dateTimeString));
  return "-";
};

const textboxBorders = {
  left: ["left", "top", "bottom", "rightNoRadius"],
  right: ["top", "bottom", "right"],
};

export default class HealthManagementEventEditDetails extends React.PureComponent {
  handleFieldChange = (field, value) => {
    this.props.onFieldChange(field, value);
  };

  handleStatusChange = (_, value) => {
    this.props.onFieldChange("approvalStatus", value.id);
  };

  handleShowStandardTextsPopup = () => {
    this.props.onFieldChange("showStandardTexts", true);
  };

  handleCloseStandardTextsPopup = () => {
    this.props.onFieldChange("showStandardTexts", false);
  };

  handleSelectStandardText = ({
    approvalCommentReason,
    approvalCommentDetails,
  }) => {
    this.props.onFieldChange("approvalCommentReason", approvalCommentReason);
    this.props.onFieldChange("approvalCommentDetails", approvalCommentDetails);
    this.handleCloseStandardTextsPopup();
  };

  static linkRenderer(props) {
    return (
      <a href={props.href} target={"_blank"} rel={"noopener noreferrer"}>
        {props.children}
      </a>
    );
  }

  render() {
    const { canEdit, standardTexts } = this.props;
    const { loading, data } = this.props.event;
    if (loading) {
      return <Loader text={"Loading event"} />;
    }
    if (!data) {
      return <div />;
    }
    const {
      vesselName,
      vesselItemName,
      alarm,
      componentName,
      type,
      status,
      startTime,
      endTime,
      approvalCommentReason,
      approvalCommentDetails,
      approvalStatus,
      informationUrl,
      supportingDetails,
      markAsStandardText,
      showStandardTexts,
    } = data;
    return (
      <Fragment>
        <div className={styles.dataContainer}>
          <div className={styles.header}>Event</div>
          <div className={styles.content}>
            <div className={styles.eventFormsContainer}>
              <FormGroup title={"Event details"}>
                <div className={styles.horizontalGroup}>
                  <FormTextBox
                    name={"vessel"}
                    label={"Vessel"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    border={textboxBorders.left}
                    value={vesselName}
                  />
                  <FormTextBox
                    name={"vesselItem"}
                    label={"Vessel Item"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    value={vesselItemName}
                    border={textboxBorders.right}
                  />
                </div>
                <FormTextBox
                  name={"eventType"}
                  label={"Event type"}
                  readOnly={true}
                  disabled={true}
                  inputClassName={styles.disabledInput}
                  value={type}
                />
                <FormTextBox
                  name={"alarm"}
                  label={"Alarm"}
                  readOnly={true}
                  disabled={true}
                  inputClassName={styles.disabledInput}
                  value={alarm}
                />
                <div className={styles.horizontalGroup}>
                  <FormTextBox
                    name={"componentName"}
                    label={"Component"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    border={textboxBorders.left}
                    value={componentName}
                  />
                  <FormTextBox
                    name={"alarmStatus"}
                    label={"Alarm Level"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    border={textboxBorders.right}
                    value={status}
                  />
                </div>
                <div className={styles.horizontalGroup}>
                  <FormTextBox
                    name={"startTime"}
                    label={"Alarm Start Time"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    border={textboxBorders.left}
                    value={startTime}
                    formatValue={formatDateTimeString}
                  />
                  <FormTextBox
                    name={"endTime"}
                    label={"Alarm End Time"}
                    readOnly={true}
                    disabled={true}
                    inputClassName={styles.disabledInput}
                    border={textboxBorders.right}
                    value={endTime}
                    formatValue={formatDateTimeString}
                  />
                </div>
                <div className={styles.horizontalGroup}>
                  <FormTextBox
                    name={"informationUrl"}
                    label={"Detailed Information"}
                    readOnly={true}
                    disabled={true}
                    url={true}
                    inputClassName={styles.disabledInput}
                    value={informationUrl}
                  />
                </div>
              </FormGroup>
              <FormGroup title={"Event Approval"}>
                <div className={styles.standardTextsControlsContainer}>
                  {some(standardTexts) ? (
                    <Button
                      value="Standard texts"
                      type="link"
                      clickAction={this.handleShowStandardTextsPopup}
                    />
                  ) : (
                    <div />
                  )}
                  <FormCheckBox
                    label="Mark as standard text"
                    name="markAsStandardText"
                    value={Boolean(markAsStandardText)}
                    onChange={this.handleFieldChange}
                  />
                </div>
                <FormTextBox
                  label={"Reason for occurrence (Markdown)"}
                  name={"approvalCommentReason"}
                  readOnly={!canEdit}
                  multiLine={true}
                  rows={6}
                  value={approvalCommentReason}
                  onChange={this.handleFieldChange}
                />
                <FormTextBox
                  label={"Complementary information (Markdown)"}
                  name={"approvalCommentDetails"}
                  readOnly={!canEdit}
                  multiLine={true}
                  rows={6}
                  onChange={this.handleFieldChange}
                  value={approvalCommentDetails}
                />
                <FormSelectBox
                  label={"Approval Status"}
                  name={"approvalStatus"}
                  disabled={!canEdit}
                  multi={false}
                  selected={
                    approvalStatuses &&
                    approvalStatuses.find((x) => x.id === approvalStatus)
                  }
                  onChange={this.handleStatusChange}
                  options={
                    approvalStatuses &&
                    approvalStatuses.filter((x) => x.allowSet === true)
                  }
                  optionValKey={"id"}
                  optionLabelKey={"name"}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        {supportingDetails && (
          <div>
            <div className={styles.dataContainer}>
              <div className={styles.header}>Event Supporting Details</div>
              <div className={styles.content}>
                <ReactMarkdown
                  className={styles.supportingDetails}
                  allowedElements={[
                    "root",
                    "text",
                    "break",
                    "paragraph",
                    "emphasis",
                    "strong",
                    "thematicBreak",
                    "blockquote",
                    "delete",
                    "link",
                    "table",
                    "tableHead",
                    "tableBody",
                    "tableRow",
                    "tableCell",
                    "list",
                    "listItem",
                    "heading",
                    "inlineCode",
                    "code",
                  ]}
                  skipHtml={true}
                  components={{
                    link: HealthManagementEventEditDetails.linkRenderer,
                  }}
                >
                  {supportingDetails}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        )}
        {showStandardTexts && (
          <Popup
            title="Select a text"
            visible
            onHide={this.handleCloseStandardTextsPopup}
          >
            <div className={styles.standardTextContainer}>
              {(standardTexts || []).map((s) => (
                <div
                  key={s.id}
                  className={styles.standardText}
                  onClick={() => this.handleSelectStandardText(s)}
                >
                  <div>{s.approvalCommentReason}</div>
                  <div>{s.approvalCommentDetails}</div>
                </div>
              ))}
            </div>
          </Popup>
        )}
      </Fragment>
    );
  }
}

HealthManagementEventEditDetails.propTypes = {
  canEdit: PropTypes.bool,
  event: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      vesselName: PropTypes.string,
      vesselItemName: PropTypes.string,
      alarm: PropTypes.string,
      componentName: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      approvalCommentReason: PropTypes.string,
      approvalCommentDetails: PropTypes.string,
      approvalStatus: PropTypes.string,
      informationUrl: PropTypes.string,
      markAsStandardText: PropTypes.bool,
      showStandardTexts: PropTypes.bool,
    }),
  }).isRequired,
  standardTexts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      approvalCommentReason: PropTypes.string,
      approvalCommentDetails: PropTypes.string,
    })
  ),
  onFieldChange: PropTypes.func.isRequired,
};
