import styles from "./../Grid.css";
import React from "react";
import PropTypes from "prop-types";

export default class GridCell extends React.Component {
  render() {
    return (
      <div className={styles.gridCell} data-cell-span={this.props.cellSpan}>
        <div className={styles.inner}>{this.props.children}</div>
      </div>
    );
  }
}

GridCell.propTypes = {
  cellSpan: PropTypes.number,
};
