import React from "react";
import styles from "./FuelConsumption.css";
import FuelConsumptionRow from "./FuelConsumptionRow";
import Loader from "../../../../common/components/Loader";
import { IFuelConsumptionState } from "../commonTypes";

interface Props {
  fuelConsumption: IFuelConsumptionState;
}

export function FuelConsumption({ fuelConsumption }: Props): JSX.Element {
  const consumptions = fuelConsumption.selected || [];
  return fuelConsumption.isLoading ? (
    <Loader text="Loading fuel consumption details" />
  ) : (
    <div className={styles.fuelConsumptionContainer}>
      {consumptions.map((data) => (
        <FuelConsumptionRow fuelConsumption={data} key={data.operationsGroup} />
      ))}
    </div>
  );
}

export default FuelConsumption;
