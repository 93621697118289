import { admin } from "../../actions/action.types";
import createReducer from "../createReducer";

import { sortItems } from "../../common/sort";

const initialState = {
  vessels: [],
  hasError: false,
  isLoading: true,
  isUpdating: false,
  includeNotConnectedVessels: false,
  sortBy: "totalLastFiveDays",
  sortDirection: "asc",
  dataPage: 4,
  isLoadingDataPage: false,
  isUpdated: false,
  vesselPage: 1,
};

const getAmountOfIncreasedSeconds = (state, data) => {
  if (state.vessels.length && data.length) {
    return data.map((d) => {
      const vessel = state.vessels.find((v) => v.vesselId === d.vesselId);
      if (vessel) {
        return {
          ...d,
          todayDiff: d.today - vessel.today,
          oneDayAgoDiff: d.oneDayAgo - vessel.oneDayAgo,
          twoDaysAgoDiff: d.twoDaysAgo - vessel.twoDaysAgo,
          threeDaysAgoDiff: d.threeDaysAgo - vessel.threeDaysAgo,
          fourDaysAgoDiff: d.fourDaysAgo - vessel.fourDaysAgo,
          fiveDaysAgoDiff: d.fiveDaysAgo - vessel.fiveDaysAgo,
          totalLastFiveDaysDiff: d.totalLastFiveDays - vessel.totalLastFiveDays,
          missingSecondsDiff: d.missingSeconds - vessel.missingSeconds,
        };
      }
      return d;
    });
  }
  return data;
};

export default createReducer(initialState, {
  [admin.dataStatus.FETCH]: (state) => ({
    ...state,
    isLoading: state.vessels.length === 0,
    isUpdating: state.vessels.length > 0,
    isUpdated: false,
  }),

  [admin.dataStatus.FETCH_SUCCESS]: (state, { data }) => {
    const items = getAmountOfIncreasedSeconds(state, data);
    return {
      ...state,
      vessels: sortItems(state, items),
      isLoading: false,
      isUpdating: false,
      isLoadingDataPage: false,
      hasError: false,
      error: null,
    };
  },
  [admin.dataStatus.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    isUpdating: false,
    isLoadingDataPage: false,
    hasError: true,
    error,
  }),
  [admin.dataStatus.SORT_VESSELS]: (state, { sortBy, sortDirection }) => ({
    ...state,
    sortBy,
    sortDirection,
    vessels: sortItems({ sortBy, sortDirection }, state.vessels),
  }),
  [admin.dataStatus.SET_CHECKED_ALL_VESSELS]: (state, { checked }) => ({
    ...state,
    includeNotConnectedVessels: checked,
    vessels: [],
  }),
  [admin.dataStatus.CHANGE_DATA_PAGE]: (state, { page }) => ({
    ...state,
    vessels: [],
    dataPage: page,
    vesselPage: 1,
    isLoadingDataPage: true,
  }),
  [admin.dataStatus.CHANGE_UPDATE_STATUS]: (state, { updated }) => ({
    ...state,
    isUpdated: updated,
  }),
  [admin.dataStatus.CHANGE_VESSEL_PAGE]: (state, { page }) => ({
    ...state,
    vesselPage: page,
  }),
});
