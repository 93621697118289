import React from "react";
import styles from "./DataList.css";
import DataListRow from "./DataListRow";
import PropTypes from "prop-types";
import Loader from "../../../common/components/Loader/Loader";

const DataListContainer = ({ title, style, showTitle, children }) => {
  return (
    <div className={styles.dataListContainer} style={style}>
      {showTitle && (
        <div className={styles.dataListHeader}>
          <h1>{title}</h1>
        </div>
      )}
      <div className={styles.dataListContentContainer}>{children}</div>
    </div>
  );
};

DataListContainer.defaultProps = {
  showTitle: true,
};

DataListContainer.propTypes = {
  title: PropTypes.string,
  margin: PropTypes.string,
  showTitle: PropTypes.bool,
  children: (props, propName, componentName) => {
    const prop = props[propName];
    return (
      !React.Children.toArray(prop).every(
        (child) => child.type === DataListRow || child.type === Loader
      ) &&
      new Error(
        `${componentName} only accepts "<DataListRow />" or "<Loader />" elements`
      )
    );
  },
};

export default DataListContainer;
