import {
  admin,
  CLOSE_ADMIN_COMPANY_POPUP,
  CREATE_ADMIN_COMPANY,
  FETCH_ADMIN_COMPANIES,
  FETCH_ADMIN_COMPANIES_ERROR,
  FETCH_ADMIN_COMPANIES_SUCCESS,
  FETCH_ADMIN_COMPANY_ERROR,
  FETCH_ADMIN_COMPANY_SUCCESS,
  SAVE_ADMIN_COMPANY,
  SAVE_ADMIN_COMPANY_ERROR,
  SAVE_ADMIN_COMPANY_SUCCESS,
  SET_ADMIN_COMPANY_VALUE,
} from "../../actions/action.types";
import { convertObjToFields, checkFormValidation } from "../../common/forms";
import createReducer from "../createReducer";

const initialState = {
  data: [],
  modules: [],
  isLoading: false,
  isLoadingModules: false,
  showPopup: false,
  form: {
    fields: {},
    isSaving: false,
    isValid: false,
    hasChanges: false,
    isUpdate: false,
  },
};

export default createReducer(initialState, {
  [admin.modules.FETCH]: (state) => {
    return {
      ...state,
      isLoadingModules: true,
    };
  },
  [admin.modules.FETCH_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoadingModules: false,
      modules: data,
    };
  },
  [admin.modules.FETCH_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoadingModules: false,
      hasError: true,
      error,
    };
  },
  [FETCH_ADMIN_COMPANIES]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_ADMIN_COMPANIES_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      data,
    };
  },
  [FETCH_ADMIN_COMPANIES_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [FETCH_ADMIN_COMPANY_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      showPopup: true,
      form: {
        ...state.form,
        fields: convertObjToFields(data),
        isValid: true,
        hasChanges: false,
        isUpdate: true,
        isSaving: false,
      },
    };
  },
  [FETCH_ADMIN_COMPANY_ERROR]: (state, { error }) => {
    return {
      ...state,
      hasError: true,
      form: {
        ...state.form,
        fields: {},
      },
      error,
    };
  },
  [CREATE_ADMIN_COMPANY]: (state) => {
    return {
      ...state,
      showPopup: true,
      form: {
        fields: {
          name: { isValid: false },
        },
        isSaving: false,
        isValid: false,
        hasChanges: false,
        isUpdate: false,
      },
    };
  },
  [CLOSE_ADMIN_COMPANY_POPUP]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        fields: {},
      },
    };
  },
  [SAVE_ADMIN_COMPANY]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        isSaving: true,
      },
    };
  },
  [SAVE_ADMIN_COMPANY_SUCCESS]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
    };
  },
  [SAVE_ADMIN_COMPANY_ERROR]: (state, { error }) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
      hasError: true,
      error,
    };
  },
  [SET_ADMIN_COMPANY_VALUE]: (state, { name, value, valid, message }) => {
    const field = state.form[name];
    return {
      ...state,
      form: checkFormValidation({
        ...state.form,
        fields: {
          ...state.form.fields,
          [name]: {
            ...field,
            value: value,
            isValid: valid !== undefined ? valid : true,
            message: message || "",
            hasChanges: true,
          },
        },
      }),
    };
  },
});

export const selectAllCompanies = (state) => state.data || [];

export const selectCompanyById = (companyId, state) =>
  selectAllCompanies(state).find((x) => x.id === companyId);
