import { api, API_ALIVE, USER, TENANTUSER } from "../api";
import { AxiosError, AxiosInstance, AxiosResponse, AxiosStatic } from "axios";
import IApiClient from "./IApiClient";
import { UserData } from "../authentication/UserData";
import { IsAlive } from "../authentication/IsAlive";

export class ApiClient implements IApiClient {
  private _httpClient: AxiosStatic;
  private _publicHttpClient: AxiosInstance;

  constructor(httpClient: AxiosStatic, publicHttpClient: AxiosInstance) {
    this._httpClient = httpClient;
    this._publicHttpClient = publicHttpClient;
  }

  private getStatusCode(err: AxiosError): number | undefined {
    return err.response && err.response.status;
  }

  private forbidden(err: AxiosError): boolean {
    return this.getStatusCode(err) === 403;
  }

  private notAuthenticated(err: AxiosError): boolean {
    return this.getStatusCode(err) === 401;
  }

  private notFound(err: AxiosError): boolean {
    return this.getStatusCode(err) === 404;
  }

  private getData<T>(res: AxiosResponse<T>): T {
    return res.data;
  }

  private handleError(err: AxiosError): Promise<never> {
    if (this.notAuthenticated(err)) {
      return Promise.reject({
        notAuthenticated: true,
        message: "Not authenticated",
      });
    }
    if (this.forbidden(err)) {
      return Promise.reject({ forbidden: true, message: "Forbidden" });
    }
    if (this.notFound(err)) {
      return Promise.reject({ notFound: true, message: "Not found" });
    }
    return Promise.reject(err);
  }

  private async get<T>(url: string): Promise<T> {
    try {
      const response = await this._httpClient.get<T>(url);
      return this.getData(response);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  private async getFromPublic<T>(url: string): Promise<T> {
    try {
      const response = await this._publicHttpClient.get<T>(url);
      return this.getData(response);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  private async post(url: string): Promise<void> {
    try {
      await this._httpClient.post<never>(url);
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  async checkApiAlive(): Promise<IsAlive> {
    return await this.get<IsAlive>(API_ALIVE.get());
  }

  async fetchUserData(): Promise<UserData> {
    return await this.get<UserData>(USER.get());
  }

  async postPageLoad(): Promise<void> {
    return await this.post(api.me.onPageLoad());
  }

  async getTenantId(email: string): Promise<string> {
    return await this.getFromPublic(TENANTUSER.get(email));
  }
}
