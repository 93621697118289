import React from "react";
import PropTypes from "prop-types";
import { FormTextBox } from "../../../onshore/components/Form";
import RadioCheckbox from "../RadioCheck/RadioCheckbox";
import FormSelectBox from "../Form/FormSelectBox/FormSelectBox";
import { get } from "lodash";

export const MetadataConfigField = (props) => {
  if (props.type === "bool") {
    const isChecked = props.value ? props.value.toString() === "true" : false;
    return (
      <RadioCheckbox
        label={props.label}
        name={props.name}
        checked={isChecked}
        onCheck={() => {
          props.onChange(props.name, (!isChecked).toString());
        }}
      />
    );
  } else if (props.type === "options") {
    return (
      <FormSelectBox
        testId={props.name}
        name={props.name}
        options={props.options}
        selectedVal={props.value}
        selectedValKey={"value"}
        label={props.label}
        onChange={(_, value) => props.onChange(props.name, get(value, "value"))}
        optionLabelKey={"text"}
        optionValKey={"value"}
        customValidation={true}
        isValid={props.isValid}
        validationLabel={props.validationLabel}
        validationMessage={props.validationMessage}
      />
    );
  } else {
    return (
      <FormTextBox {...props} customValidation={true} labelTag={props.unit} />
    );
  }
};

MetadataConfigField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "positiveNumber",
    "integer",
    "positiveInteger",
    "bool",
    "options",
  ]),
  unit: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  integerRange: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  isValid: PropTypes.bool,
  validationLabel: PropTypes.string,
  validationMessage: PropTypes.string,
};
