import React from "react";
import { connect } from "react-redux";
import classNames from "../../../../../common/classNames";
import Button from "../../../../../common/components/Button";
import Loader from "../../../../../common/components/Loader";
import { FormSelectBox } from "../../../../components/Form";
import { prepState } from "../../../../reducers/admin/vesselConfig/reducer.onboardProcessorConfig";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import styles from "./OnboardProcessorConfigController.css";

function ActionLink({ text, error, isLoading, onClick }) {
  return (
    <div className={styles.action}>
      {error && <span className={styles.actionError} title={error} />}
      <Button
        type="link"
        value={text}
        clickAction={onClick}
        isLoading={isLoading}
      />
    </div>
  );
}

function ConfigTable({ configs, dispatch }) {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th />
          <th>Version</th>
          <th title="Time of deployment">Deployed</th>
          <th title="Version of the processor docker image that the config is deployed to">
            Processor image
          </th>
          <th title="The baseline to be installed alongside the processor image">
            Baseline version
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {configs.map((c) => (
          <tr
            key={c.version}
            className={classNames(c.isCurrent && styles.current)}
          >
            <td>
              {c.isCurrent ? (
                <span className={styles.checkmark}>{"\u2714"}</span>
              ) : (
                ""
              )}
            </td>
            <td>{c.version}</td>
            <td>
              <span className={styles.nowrap}>{c.deployed}</span>
            </td>
            <td>{c.processorVersion}</td>
            <td>{c.baselineVersion}</td>
            <td>
              <div className={styles.actions}>
                <ActionLink
                  text="Deploy"
                  error={c.deployError}
                  isLoading={c.isDeploying}
                  onClick={() => dispatch(c.actions.deploy)}
                />
                <ActionLink
                  text="Download"
                  error={c.downloadError}
                  isLoading={c.isDownloading}
                  onClick={() => dispatch(c.actions.download)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function GenerateConfigPanel({
  isLoading,
  warning,
  errors,
  baselines,
  selectedBaseline,
  actions,
  dispatch,
}) {
  return (
    <>
      <div className={styles.formTitle}>Generate new config</div>
      <div className={styles.formContent}>
        <div className={styles.block}>
          <FormSelectBox
            name="baseline"
            label="Baseline"
            options={baselines}
            optionValKey="id"
            optionLabelKey="version"
            selected={selectedBaseline}
            onChange={(_name, selected) =>
              dispatch({ ...actions.selectBaseline, selected })
            }
            testId="baselineSelect"
          />
        </div>
        <div className={classNames(styles.block, styles.related)}>
          <div className={styles.item}>
            <Button
              value="Generate"
              isLoading={isLoading}
              clickAction={() => dispatch(actions.generate)}
              testId={"generateOnboardProcessorConfig"}
            />
          </div>
          <div className={styles.item}>
            <Button value="Cancel" clickAction={() => dispatch(actions.hide)} />
          </div>
        </div>
        {warning && <WarningMessage warning={warning} />}
        {errors && <ErrorMessages errors={errors} />}
      </div>
    </>
  );
}

function WarningMessage({ warning }) {
  return (
    <div className={styles.block}>
      <span className={styles.actionWarning} /> {warning}
    </div>
  );
}

function ErrorMessages({ errors }) {
  return (
    <ul className={styles.block}>
      {errors.map((e, i) => (
        <div key={i} className={styles.errorMessage}>
          <span className={styles.actionError} /> {e}
        </div>
      ))}
    </ul>
  );
}

function OnboardProcessorController({
  isLoading,
  error,
  configs,
  generate,
  onToggleClick,
  isOpen,
  enabled,
  dispatch,
}) {
  return (
    <CollapsePanel
      enabled={enabled}
      isOpen={isOpen}
      onToggleClick={onToggleClick}
      title="Onboard Processor Config"
      subTitle="Generate and deploy configurations for the processor application"
      testId="onboardProcessorConfigController"
      buttons={[
        {
          label: "New",
          disabled: !enabled || generate.isVisible,
          isVisible: isOpen,
          action: () => dispatch(generate.actions.show),
        },
      ]}
    >
      {isLoading || error ? (
        <Loader expand={true} error={error} />
      ) : (
        <>
          {generate.isVisible && (
            <GenerateConfigPanel {...generate} dispatch={dispatch} />
          )}
          <ConfigTable configs={configs} dispatch={dispatch} />
        </>
      )}
    </CollapsePanel>
  );
}

export default connect((state) =>
  prepState(state.admin.onboardProcessorConfig)
)(OnboardProcessorController);
