import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {
  FormPopup,
  FormSelectBox,
  FormTextBox,
} from "../../../components/Form";
import { get } from "lodash";
import styles from "../Admin.css";
import {
  closeCompanyPopup,
  fetchModules,
  saveCompany,
  setCompanyValue,
  setSelectedCompany,
} from "../../../actions/admin";

class CompaniesFormPopup extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  componentDidMount() {
    this.props.fetchModules();
  }

  onRestrictedModulesChange = (name, selected, valid, message) => {
    this.props.setCompanyValue(
      name,
      (selected || []).map((x) => x.id),
      valid,
      message
    );
  };

  onPopupFormHide = () => {
    this.props.closeCompanyPopup();
  };

  onPopupFormSave = () => {
    this.props.saveCompany();
  };

  onPopupFormCancel = () => {
    this.props.closeCompanyPopup();
  };

  onPopupFormInputChange = (name, value, valid, message) => {
    this.props.setCompanyValue(name, value, valid, message);
  };

  renderForm() {
    const { form, showPopup, modules, companyModules } = this.props;

    if (!showPopup) {
      return <div />;
    }
    const initialModuleIds = modules
      .filter(
        (x) =>
          x.menuItemName === "fleetPerformance" ||
          x.menuItemName === "mainDashboard" ||
          x.menuItemName === "fuelEmissions"
      )
      .map((x) => x.id);

    const existingModulesIds =
      form.isUpdate && companyModules
        ? companyModules.value.map((x) => x.id)
        : initialModuleIds;

    const existingModules = modules.filter((x) =>
      existingModulesIds.includes(x.id)
    );
    const selectedIds = get(form.fields, "restrictedModules.value");

    const selectedRestrictedModules = selectedIds
      ? modules.filter((x) => selectedIds.includes(x.id))
      : existingModules;

    return (
      <div className={styles.vesselActionForm} data-testid={"companiesForm"}>
        <FormTextBox
          name="name"
          label="Name"
          value={get(form, "fields.name.value")}
          onChange={this.onPopupFormInputChange}
          required
        />
        <FormTextBox
          name="tenantId"
          label="Tenant Id (guid)"
          value={get(form, "fields.tenantId.value")}
          onChange={this.onPopupFormInputChange}
        />
        <FormSelectBox
          name="restrictedModules"
          label="Hidden features"
          selected={selectedRestrictedModules}
          onChange={this.onRestrictedModulesChange}
          optionValKey="id"
          optionLabelKey="fullname"
          options={modules}
          testId={"restrictedModulesSelectBox"}
          multi
        />
      </div>
    );
  }

  render() {
    const { form, showPopup } = this.props;
    const { isSaving } = form;
    const enableSave =
      (!form.isUpdate || form.hasChanges) && form.isValid && !isSaving;
    return (
      <FormPopup
        title={form.isUpdate ? "Edit Company" : "Create new Company"}
        visible={showPopup}
        isSaving={isSaving}
        enableSave={enableSave}
        onHide={this.onPopupFormHide}
        onSave={this.onPopupFormSave}
        onCancel={this.onPopupFormCancel}
        testId={"companiesPopup"}
      >
        {this.renderForm()}
      </FormPopup>
    );
  }
}

CompaniesFormPopup.defaultProps = {
  visible: false,
};

CompaniesFormPopup.propTypes = {
  visible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  form: state.admin.companies.form,
  showPopup: state.admin.companies.showPopup,
  modules: state.admin.companies.modules,
  companyModules: state.admin.companies.form.fields.companyModules,
});

const mapDispatchToProps = {
  closeCompanyPopup,
  saveCompany,
  setSelectedCompany,
  setCompanyValue,
  fetchModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesFormPopup);
