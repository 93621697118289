import * as d3 from "d3";
import { debounce, throttle, values, sortBy } from "lodash";
import memoize from "memoize-one";
import PropTypes from "prop-types";
import React from "react";
import { SparklineContext } from "./Sparkline";

const contextMap = new Map();

class SparklineHover extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hoveredXValue: null,
    };

    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 60);
    this.handleMouseLeave = debounce(this.handleMouseLeave.bind(this), 100);

    this.uniqueXValues = memoize((data) => {
      const set = {};
      for (let ds of data)
        for (let d of ds) {
          const val = d.x || d;
          if (val) {
            set[val] = val;
          }
        }
      return sortBy(values(set), (x) => x);
    });

    if (props.context) {
      if (!contextMap.has(props.context)) {
        contextMap.set(props.context, new Set([this]));
      } else {
        contextMap.get(props.context).add(this);
      }
    }
  }

  componentWillUnmount() {
    if (contextMap.has(this.props.context)) {
      contextMap.get(this.props.context).delete(this);
    }
  }

  handleMouseLeave(evt) {
    evt.persist();
    this.updateHoveredXValue(null);
  }

  componentDidUpdate(_, prevState) {
    if (prevState.hoveredXValue !== this.state.hoveredXValue) {
      if (this.props.onHoveredXValueChanged) {
        this.props.onHoveredXValueChanged(this.state.hoveredXValue);
      }
    }
  }

  handleMouseMove(evt, scaleX) {
    const { data, useBisect, bisectorComparator, customValueFunction } =
      this.props;
    const pt = this.svgElement.createSVGPoint();
    pt.x = evt.nativeEvent.clientX;
    pt.y = evt.nativeEvent.clientY;
    const loc = pt.matrixTransform(
      this.svgGraphicsElement.getScreenCTM().inverse()
    );
    const xValues = this.uniqueXValues(this.props.data);
    const mVal = scaleX.invert(loc.x);

    let hoveredXValue = null;

    if (customValueFunction) {
      hoveredXValue = customValueFunction({ xValues, mVal, x: pt.x, y: pt.y });
    } else if (!useBisect) {
      hoveredXValue = mVal;
    } else {
      const xBisector = d3.bisector(bisectorComparator).left;

      if (data !== undefined && data !== null && data.length > 0) {
        const index = xBisector(xValues, mVal);

        if (index >= 0 && index < xValues.length) {
          hoveredXValue = xValues[index];
        }
      }
    }

    if (hoveredXValue !== null) {
      this.updateHoveredXValue(hoveredXValue);
    }
  }

  updateHoveredXValue(hoveredXValue) {
    this.setState({ hoveredXValue });
    if (contextMap.has(this.props.context)) {
      for (const instance of contextMap.get(this.props.context)) {
        if (instance !== this) {
          instance.onContextChanged({ hoveredXValue });
        }
      }
    }
  }

  onContextChanged(state) {
    this.setState(state);
  }

  render() {
    const { scaleX, margins, height, width } = this.props;
    const hoveredXValue =
      "hoveredXValue" in this.props
        ? this.props.hoveredXValue
        : this.state.hoveredXValue;
    const svgOpts = {
      x: 0 - margins.left,
      y: 0 - margins.top,
      onMouseLeave: this.handleMouseLeave,
      onMouseMove: (evt) => {
        evt.persist();
        this.handleMouseMove(evt, scaleX);
      },
    };
    const { children, ...props } = this.props;
    const childElements = [];
    React.Children.forEach(children, (c) => {
      if (c) {
        childElements.push(c);
      }
    });
    return (
      <svg
        {...svgOpts}
        pointerEvents={"all"}
        ref={(el) => (this.svgElement = el)}
      >
        <g
          ref={(el) => (this.svgGraphicsElement = el)}
          transform={`translate(${margins.left} ${margins.top})`}
        >
          <rect
            fillOpacity={0}
            fill={"#00000"}
            height={Math.max(0, height)}
            width={Math.max(0, width)}
          />
          {React.Children.map(childElements, (child, index) => {
            return React.cloneElement(child, {
              key: index,
              ...props,
              hoveredXValue,
            });
          })}
        </g>
      </svg>
    );
  }
}

SparklineHover.defaultProps = {
  useBisect: true,
  bisectorComparator: (x) => x,
};

SparklineHover.propTypes = {
  useBisect: PropTypes.bool,
  bisectorComparator: PropTypes.func,
  context: PropTypes.string,
  customValueFunction: PropTypes.func,
  onHoveredXValueChanged: PropTypes.func,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineHover {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
