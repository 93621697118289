import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import ButtonToggle from "../ButtonToggle";
import React from "react";
import styles from "./ManualFuelDataIndicator.css";
import { color } from "../../../common/colors";

export const ManualFuelDataIndicator = () => {
  const buttonStyle = {
    position: "absolute !important",
    right: "1px",
    top: "0px",
    height: "46px",
    width: "46px",
    borderRadius: "50%",
    marginTop: "-47px",
    opacity: "0",
  };
  return (
    <div className={styles.indicatorContainer}>
      <ButtonToggle
        active={true}
        value={"MF"}
        width="48px"
        lineColor={color("--magenta-normal")}
        circle
      />
      <ComponentInfo
        width={260}
        modalTargetId={"popupContainer"}
        buttonStyle={buttonStyle}
        template={
          <p>
            Disclaimer: Manually reported fuel data are included in the data
            shown. Any configured UTC offset is therefore disregarded.
          </p>
        }
      >
        {" "}
      </ComponentInfo>
    </div>
  );
};
