import { createRequestReducer } from "./createRequestReducer";
import {
  FETCH_FUEL_EFFICIENCY_TODAY,
  FETCH_FUEL_EFFICIENCY_YESTERDAY,
} from "../actions/action.types";

export const fuelEfficiencyToday = createRequestReducer(
  FETCH_FUEL_EFFICIENCY_TODAY
);
export const fuelEfficiencyYesterday = createRequestReducer(
  FETCH_FUEL_EFFICIENCY_YESTERDAY
);
