import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/Header/Header";
import DataGrid from "../../../components/DataGrid/DataGrid";
import {
  fetchEvents,
  setStatusFilter,
  sortItems,
  selectVessel,
} from "../../../actions/admin/action.healthManagemenEvent";

import { formatDateTime } from "../../../common/dates";
import {
  isHmProductExpert,
  isSystemAdmin,
} from "../../../reducers/reducer.user";
import styles from "./HealthManagement.css";
import Button from "../../../../common/components/Button/Button";
import {
  approvalStatuses,
  getVesselOptions,
} from "../../../reducers/admin/reducer.healthManagementEvent";
import SelectBox from "../../../components/SelectBox";
import adminStyles from "../Admin.css";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useThunkDispatch } from "../../../store/rootState";

const HealthManagement = () => {
  const selectedApprovalStatus = useAppSelector(
    (state) =>
      state.admin.healthManagementEvent.eventList.selectedApprovalStatus
  );

  const selectedVessel = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.selectedVessel
  );
  const events = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.events
  );
  const vesselOptions = useMemo(() => getVesselOptions(events), [events]);

  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  useEffect(() => {
    thunkDispatch(fetchEvents());
  }, []);

  const onStatusChanged = (newValue: any) => {
    thunkDispatch(setStatusFilter(newValue.id));
  };

  return (
    <div>
      <Header
        title={"Admin - Health Management Event"}
        contentDistribution={"space-between"}
      >
        <div className={adminStyles.headerContainer}>
          <SelectBox
            className={adminStyles.headerContainerItem}
            placeholder="Select vessel"
            options={!vesselOptions ? [] : Object.values(vesselOptions)}
            optionValKey="id"
            optionLabelKey="name"
            selected={selectedVessel}
            onSelect={(v: any) => dispatch(selectVessel(v))}
            clearable={true}
          />

          <SelectBox
            className={adminStyles.headerContainerItem}
            options={approvalStatuses}
            selected={approvalStatuses?.find(
              (x) => x.id === selectedApprovalStatus
            )}
            optionValKey={"id"}
            onSelect={onStatusChanged}
            optionLabelKey={"name"}
          />
        </div>
      </Header>
      <div className={styles.dataContainer}>
        <Events />
      </div>
    </div>
  );
};

export default HealthManagement;

interface ActionCellProps {
  id: string;
}
const ActionCell = ({ id }: ActionCellProps) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const canEdit = useMemo(
    () => isSystemAdmin(user) || isHmProductExpert(user),
    [user]
  );
  return (
    <Button
      value={canEdit ? "Edit" : "View"}
      type={"link"}
      clickAction={() => navigate(`/admin/healthmanagement/${id}`)}
    />
  );
};

const Events = () => {
  const events = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.events
  );
  const selectedVessel = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.selectedVessel
  );
  const sortBy = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.sortBy
  );
  const sortDirection = useAppSelector(
    (state) => state.admin.healthManagementEvent.eventList.sortDirection
  );

  const gridConfig = {
    columns: [
      {
        header: "Date",
        field: "startTime",
        format: (v: string) => formatDateTime(new Date(v)),
      },
      {
        header: "Vessel",
        field: "vesselName",
      },
      {
        header: "Approval Status",
        field: "approvalStatus",
        format: (v: string) => {
          const approvalStatus = approvalStatuses.find((s) => s.id === v);
          if (approvalStatus) {
            return approvalStatus.name;
          }
          return v;
        },
      },
      {
        header: "Event Type",
        field: "type",
      },
      {
        header: "Component",
        field: "componentName",
      },
      {
        header: "Vessel Item",
        field: "vesselItemName",
      },
      {
        header: "Alarm",
        field: "alarm",
      },
      {
        header: "Alarm Status",
        field: "status",
      },
      {
        header: "Actions",
        template: createActionCell,
      },
    ],
  };

  const dispatch = useDispatch();
  const onSort = (o: any) => dispatch(sortItems(o));

  // Filter?
  if (!selectedVessel) {
    return (
      <DataGrid
        {...gridConfig}
        data={events}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
      />
    );
  } else {
    return (
      <DataGrid
        {...gridConfig}
        data={events.filter((v: any) => v.vesselId === selectedVessel.id)}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
      />
    );
  }
};

const createActionCell = (data: any) => <ActionCell id={data.id} />;
