import { UserDataMockResponse } from "../components/api.MockUserData";
import { faker } from "@faker-js/faker";
import { uppercaseFirstChar } from "../../../common/strings";

const randomGroup = () => ({
  id: faker.string.uuid(),
  name: `${uppercaseFirstChar(faker.word.adjective())} ${uppercaseFirstChar(
    faker.word.noun()
  )}`,
  companyId: faker.string.uuid(),
  companyName: `${uppercaseFirstChar(
    faker.word.adjective()
  )} ${uppercaseFirstChar(faker.word.noun())}`,
});

const groups = faker.helpers.multiple(randomGroup, { count: 5 });

const randomVessel = () => {
  const group = faker.helpers.arrayElement(groups);
  return {
    id: faker.string.uuid(),
    name: `${uppercaseFirstChar(faker.word.adjective())} ${uppercaseFirstChar(
      faker.word.noun()
    )}`,
    companyId: group.companyId,
    companyName: group.companyName,
    imo: faker.number.int({ min: 11111111, max: 99999999 }),
    startTime: faker.date.between({
      from: new Date(2017, 0, 1),
      to: new Date(2023, 0, 1),
    }),

    menuItems: [
      {
        name: "dashboard",
        visible: true,
      },
      {
        name: "fleet",
        visible: true,
      },
      {
        name: "vessel",
        visible: true,
      },
      {
        name: "trend",
        visible: true,
      },
      {
        name: "map",
        visible: true,
      },
      {
        name: "compare",
        visible: true,
      },
      {
        name: "calendar",
        visible: true,
      },
      {
        name: "analysis",
        visible: true,
      },
      {
        name: "documents",
        visible: true,
      },
      {
        name: "machineryReport",
        visible: true,
      },
      {
        name: "hullPerformance",
        visible: true,
      },
    ],
  };
};

const vessels = faker.helpers.multiple(randomVessel, { count: 31 });

const vesselGroupVessels = vessels.map((vessel) => ({
  id: faker.string.uuid(),
  vesselId: vessel.id,
  groupId: faker.helpers.arrayElement(groups).id,
}));

const randomUserData = (): UserDataMockResponse => ({
  groups,
  pageConfigs: {},
  userDetails: {},
  vessels,
  vesselGroupVessels,
  menu: {},
  performanceIndicators: [],
  vesselPerformanceIndicators: [],
  units: [],
  vesselItems: [],
  vesselItemMetrics: [],
  itemTypes: [],
  metrics: [],
  itemTypeMetrics: [],
  evenTypes: [],
  operations: [],
  itemTypeCategories: [],
});

export const userDataMock = randomUserData();
