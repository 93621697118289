import { get, merge } from "lodash";
import { getTimeOffsetFormat, removeCurrentSelectedValues } from ".";
import {
  addMonths,
  atStartOfMonth,
  formatDate,
  formatDateTime,
  formatTime,
  now,
  toYearMonthISOString,
  from as parseDateFrom,
  addTimeOffset,
} from "../../common/dates";
import monthPicker from "./monthPicker";

const oneMin = 60 * 1000;
const oneHour = 60 * oneMin;
const oneDay = 24 * oneHour;

export const formatDuration = ({ from, to }) => {
  const diff = Math.abs(to - from);
  if (diff < oneHour) {
    return `${Math.round(diff / oneMin)} mins`;
  }
  if (diff < oneDay) {
    return `${Math.round(diff / oneHour)} hrs`;
  }
  return `${Math.round(diff / oneDay)} days`;
};

export const optionData = ({ value }, { selectedValue, timeOffset }) => {
  const from = addTimeOffset(timeOffset, new Date(value.from));
  const to = addTimeOffset(timeOffset, new Date(value.to));
  return {
    id: value.id,
    date: formatDate(from),
    time: formatTime(from),
    name: value.name,
    duration: formatDuration({ from, to }),
    isActive: get(value, "id") === get(selectedValue, "id"),
    timeOffsetFormat: getTimeOffsetFormat(timeOffset),
  };
};

export const getVesselStateMonthRange = monthPicker.toJSON;

const indexOfVesselState = (vesselState, vesselStates) =>
  vesselStates.findIndex((x) => x.id === vesselState.id);

export const createVesselStatePicker = ({ vesselStatesProp, titlePrefix }) => {
  const shouldFetchMonth = (month, context, timeOffset) => {
    return (
      get(context, [vesselStatesProp, "month"]) !==
        toYearMonthISOString(month) ||
      get(context, [vesselStatesProp, "timeOffset"]) !== timeOffset
    );
  };

  const isLoadingMonth = (month, context) =>
    get(context, [vesselStatesProp, "isLoading"]) &&
    get(context, [vesselStatesProp, "month"]) === toYearMonthISOString(month);

  const getEntriesForMonth = (month, context) => {
    return (
      (get(context, [vesselStatesProp, "month"]) ===
        toYearMonthISOString(month) &&
        get(context, [vesselStatesProp, "data"])) ||
      []
    );
  };

  const withVesselStates = (fn) => (vesselState, context) => {
    return fn(
      vesselState,
      merge(
        getEntriesForMonth(new Date(vesselState.from), context),
        getEntriesForMonth(new Date(vesselState.to), context)
      ),
      context
    );
  };

  const getVesselStateMonth = (vesselState) =>
    vesselState &&
    vesselState.from &&
    atStartOfMonth(new Date(vesselState.from));

  const createOption = (vesselState) => ({
    value: {
      ...vesselState,
      from: vesselState.from && new Date(vesselState.from),
      to: vesselState.to && new Date(vesselState.to),
    },
  });

  const createOptions = (month, selectedValue, context) => {
    const current = context.current;
    const timeOffset = parseInt(
      get(current, "currentSelectedUtcValue", get(current, "timeOffset", 0))
    );
    const toLimit = atStartOfMonth(
      (context.limits && context.limits.to) || now()
    );
    const fromLimit = atStartOfMonth(
      (context.limits && context.limits.from) || now()
    );
    month = month || toLimit;
    return {
      selectedValue,
      month,
      title: monthPicker.format(month),
      items: getEntriesForMonth(month, context).map(createOption),
      hasNext: month < toLimit,
      hasPrev: month > fromLimit,
      shouldFetch: shouldFetchMonth(month, context, timeOffset),
      isLoading: isLoadingMonth(month, context),
      timeOffset,
    };
  };

  return {
    canSelectNext: withVesselStates(
      (vesselState, vesselStates) =>
        indexOfVesselState(vesselState, vesselStates) < vesselStates.length - 1
    ),
    canSelectPrev: withVesselStates(
      (vesselState, vesselStates) =>
        indexOfVesselState(vesselState, vesselStates) > 0
    ),
    next: withVesselStates(
      (vesselState, vesselStates) =>
        vesselStates[indexOfVesselState(vesselState, vesselStates) + 1]
    ),
    prev: withVesselStates(
      (vesselState, vesselStates) =>
        vesselStates[indexOfVesselState(vesselState, vesselStates) - 1]
    ),
    defaultValue: ({ current, limits }) => {
      const currentValue =
        current && (current.currentSelectedValue || current.value);
      if (currentValue) {
        return getCurrentValue(currentValue, current, limits);
      }
      return {
        from: (limits && atStartOfMonth(limits.to)) || atStartOfMonth(now()),
      };
    },
    clamp: withVesselStates((vesselState, vesselStates, { current }) => {
      removeCurrentSelectedValues(current);
      if (vesselStates.length > 0) {
        return indexOfVesselState(vesselState, vesselStates) >= 0
          ? vesselState
          : null;
      }
      return vesselState;
    }),
    format: (vesselState, timeOffsetFormat) => {
      return (
        (vesselState &&
          `${titlePrefix}: ${formatDateTime(vesselState.from)} ` +
            `${timeOffsetFormat ? timeOffsetFormat : ""}`) ||
        ""
      );
    },
    toJSON: ({ from, to }) => ({
      from: from && new Date(from).toISOString(),
      to: to && new Date(to).toISOString(),
    }),
    options: (selectedValue, context) => {
      return createOptions(
        getVesselStateMonth(selectedValue),
        selectedValue,
        context
      );
    },
    updateOptions: (currentOptions, context) => {
      return createOptions(
        currentOptions.month,
        currentOptions.selectedValue,
        context
      );
    },
    selectOption: (option, options, { current }, type) => {
      if (current) {
        current.currentSelectedValue = {};
        current.currentSelectedValue.from = option.value.from;
        current.currentSelectedValue.to = option.value.to || option.value.from;
        current.currentSelectedValue.id = option.value.id;
        current.currentSelectedValueType = type;
      }
      return {
        ...options,
        selectedValue: option.value,
      };
    },
    nextOptions: (currentOptions, context) => {
      return createOptions(
        addMonths(1, currentOptions.month),
        currentOptions.selectedValue,
        context
      );
    },
    prevOptions: (currentOptions, context) => {
      return createOptions(
        addMonths(-1, currentOptions.month),
        currentOptions.selectedValue,
        context
      );
    },
  };
};

const getCurrentValue = (currentValue, current, limits) => {
  if (Object.prototype.hasOwnProperty.call(currentValue, "id")) {
    return currentValue;
  }
  if (currentValue.to) {
    return {
      from: atStartOfMonth(currentValue.to),
    };
  }
  if (!current.currentSelectedValue && current.range && limits && limits.to) {
    const rangeTo = parseDateFrom(current.range.to);
    return {
      from: atStartOfMonth(rangeTo < limits.to ? rangeTo : limits.to),
    };
  }
  if (limits && limits.from) {
    return {
      from: atStartOfMonth(
        currentValue > limits.from ? currentValue : limits.from
      ),
    };
  }
  return {
    from: atStartOfMonth(currentValue),
  };
};

export const legPicker = createVesselStatePicker({
  titlePrefix: "Leg",
  vesselStatesProp: "legs",
});

export const voyagePicker = createVesselStatePicker({
  titlePrefix: "Voyage",
  vesselStatesProp: "voyages",
});
