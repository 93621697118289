import { vesselConfig } from "../action.types";

export const inputChanged = (field, value) => ({
  type: vesselConfig.onboardFrontendConfig.INPUT_CHANGED,
  field,
  value,
});

export const dashboardChanged = (row, column, _, component) => ({
  type: vesselConfig.onboardFrontendConfig.DASHBOARD_INPUT_CHANGED,
  row,
  column,
  component,
});

export const save = (config) => ({
  type: vesselConfig.onboardFrontendConfig.SAVE,
  config,
});

export const download = () => ({
  type: vesselConfig.onboardFrontendConfig.DOWNLOAD,
});

export const deploy = () => ({
  type: vesselConfig.onboardFrontendConfig.DEPLOY,
});

export const vesselImageChanged = (name, file) => ({
  type: vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED,
  name,
  file,
});

export const vesselImageChangedError = (errors) => ({
  type: vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED_ERROR,
  errors,
});

export const showVesselImagePreview = () => ({
  type: vesselConfig.onboardFrontendConfig.SHOW_VESSEL_IMAGE_PREVIEW,
});

export const hideVesselImagePreview = () => ({
  type: vesselConfig.onboardFrontendConfig.HIDE_VESSEL_IMAGE_PREVIEW,
});
