import { ADMIN_VESSEL_REMOTEACCESS, ADMIN_VESSELS, adminApi } from "../../api";
import fetch from "../action.fetch";
import { convertFieldsToKeyValues } from "../../common/forms";
import {
  admin,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE,
  ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE,
  FETCH_ADMIN_VESSELS,
} from "../action.types";
import { downloadFile } from "../../common/downloads";

//
// Vessel list actions
//

export const configureSensorReplicationVessel = (vessel) => ({
  type: admin.vessels.sensorReplication.CONFIGURE,
  vessel,
});

export const fetchVessels = (companyId) =>
  fetch({
    url: ADMIN_VESSELS.get(companyId),
    actionType: FETCH_ADMIN_VESSELS,
    actionData: {
      companyId,
    },
  });

//
// Vessel remote control actions
//

export const acknowledgeActionFailure =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const vesselId = state.admin.vessels.configureRemoteControl.vessel.id;
    const actionId =
      state.admin.vessels.configureRemoteControl.currentAction.id;

    dispatch({
      type: admin.vessels.remoteControl.acknowledgeActionFailure.SAVE,
    });

    http({
      method: "post",
      url: adminApi.vessels.remoteControl.acknowledgeActionFailure(
        vesselId,
        actionId
      ),
    })
      .then(() => {
        dispatch({
          type: admin.vessels.remoteControl.acknowledgeActionFailure
            .SAVE_SUCCESS,
        });
        dispatch(fetchVessels(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({
          type: admin.vessels.remoteControl.acknowledgeActionFailure.SAVE_ERROR,
          error,
        });
      });
  };

export const closeRemoteControlVesselPopup = () => ({
  type: ADMIN_VESSEL_REMOTECONTROL_CONFIGURE_CLOSE,
});

export const configureRemoteControlVessel = (vessel) => ({
  type: ADMIN_VESSEL_REMOTECONTROL_CONFIGURE,
  vessel,
});

export const fetchVesselUpdateTargets = () =>
  fetch({
    url: adminApi.vessels.remoteControl.updateTargets(),
    actionType: admin.vessels.remoteControl.updateTargets.FETCH,
  });

export const postUpdateSystem =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { vessel } = state.admin.vessels.configureRemoteControl;

    const httpRequest = {
      method: "post",
      url: adminApi.vessels.remoteControl.updateSystem(vessel.id),
    };
    dispatch({ type: admin.vessels.remoteControl.updateSystem.SAVE });
    http(httpRequest)
      .then(() => {
        dispatch({
          type: admin.vessels.remoteControl.updateSystem.SAVE_SUCCESS,
        });
        dispatch(fetchVessels(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({
          type: admin.vessels.remoteControl.updateSystem.SAVE_ERROR,
          error,
        });
      });
  };

export const revokeRemoteAccessConfiguration =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { vessel } = state.admin.vessels.configureRemoteControl;

    const httpRequest = {
      method: "put",
      url: ADMIN_VESSEL_REMOTEACCESS.put(vessel.id),
      data: {
        duration: 0,
        reason: "Revoked remote access from the web app.",
      },
    };
    dispatch({ type: admin.vessels.remoteControl.remoteAccess.REVOKE });
    http(httpRequest)
      .then(() => {
        dispatch({
          type: admin.vessels.remoteControl.remoteAccess.REVOKE_SUCCESS,
        });
        dispatch(fetchVessels(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({
          type: admin.vessels.remoteControl.remoteAccess.REVOKE_ERROR,
          error,
        });
      });
  };

export const saveRemoteAccessConfiguration =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { remoteAccessForm, vessel } =
      state.admin.vessels.configureRemoteControl;
    const payload = convertFieldsToKeyValues(remoteAccessForm);

    const httpRequest = {
      method: "put",
      url: ADMIN_VESSEL_REMOTEACCESS.put(vessel.id),
      data: payload,
    };
    dispatch({ type: admin.vessels.remoteControl.remoteAccess.SAVE });
    http(httpRequest)
      .then(() => {
        dispatch({
          type: admin.vessels.remoteControl.remoteAccess.SAVE_SUCCESS,
        });
        dispatch(fetchVessels(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({
          type: admin.vessels.remoteControl.remoteAccess.SAVE_ERROR,
          error,
        });
      });
  };

export const setRemoteAccessValue = (name, value, valid, message) => {
  return {
    type: admin.vessels.remoteControl.remoteAccess.SET_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const setRemoteControlType = (_name, selectedTemplate) => ({
  type: admin.vessels.remoteControl.CHANGE_REMOTE_CONTROL_TYPE,
  selectedTemplate,
});

//
// Vessel sensor replication actions
//

export const closeSensorReplicationVessel = () => ({
  type: admin.vessels.sensorReplication.CONFIGURE_CLOSE,
});

export const saveSensorReplicationVessel =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    let form = state.admin.vessels.configureSensorReplication;
    const payload = convertFieldsToKeyValues(form);

    dispatch({ type: admin.vessels.sensorReplication.SAVE });

    http({
      method: "post",
      url: ADMIN_VESSELS.postSensorUpdateRequest(form.vesselId),
      data: payload,
    })
      .then((result) => {
        downloadFile(result);
        dispatch({ type: admin.vessels.sensorReplication.SAVE_SUCCESS });
        dispatch(fetchVessels(state.admin.selectedCompanyId));
      })
      .catch((error) => {
        dispatch({ type: admin.vessels.sensorReplication.SAVE_ERROR, error });
      });
  };

export const setVesselSensorReplicationValue = (name, value) => ({
  type: admin.vessels.sensorReplication.SET_VALUE,
  name,
  value,
});
