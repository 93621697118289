import { useSearchParams } from "react-router-dom";

export const pageKey = "page";
export const pageDefault = "1";

export const usePage = (): [page: number, setPage: (page: number) => void] => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();

  const page = Number(urlSearchParams.get(pageKey) ?? pageDefault);
  if (!isFinite(page)) {
    throw new Error("Invalid value in search parameter page");
  }

  const setPage = (page: number) => {
    setUrlSearchParams((prev) => {
      prev.set(pageKey, `${page}`);
      return prev;
    });
  };

  return [page, setPage];
};
