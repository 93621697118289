import React from "react";
import PropTypes from "prop-types";
import Popup from "../../../../components/Popup/Popup";
import styles from "./HullPerformanceConfigPopup.css";
import { formatDateTimeWithSeconds } from "../../../../common/dates";
import Loader from "../../../../../common/components/Loader";

const StatusText = ({ isValid }) => {
  if (isValid) {
    return (
      <span>
        Vessel configuration is{" "}
        <span className={styles.configValid}>valid</span>.
      </span>
    );
  } else {
    return (
      <span>
        Vessel configuration is{" "}
        <span className={styles.configInvalid}>not valid</span>.
      </span>
    );
  }
};

const CalculationStatusClassName = ({ calculationStatus }) => {
  switch (calculationStatus) {
    case "idle":
      return styles.calculationStatusIdle;
    case "failed":
      return styles.calculationStatusFailed;
    default:
      return styles.calculationStatusOther;
  }
};

const ValidationResult = ({ isValid, errors }) => {
  return (
    !isValid && (
      <ul className={styles.validationMessages}>
        {errors.map((x) => (
          <li key={x}>{x}</li>
        ))}
      </ul>
    )
  );
};

const CalculationStatus = ({
  calculationStatus,
  exceptionMessage,
  exceptionTime,
}) => {
  return (
    <div className={styles.calculationStatus}>
      <span>
        Calculation status is{" "}
        <span
          className={CalculationStatusClassName({
            calculationStatus: calculationStatus,
          })}
        >
          {calculationStatus}
        </span>
        .
      </span>
      {exceptionMessage && (
        <ul className={styles.exceptionList}>
          <li>Exception time: {formatDateTimeWithSeconds(exceptionTime)}</li>
          <li>Exception message: {exceptionMessage}</li>
        </ul>
      )}
    </div>
  );
};

const CalculationResult = ({ calculationResult, isLoading }) => {
  if (isLoading) {
    return <Loader expand={true} />;
  }
  if (!calculationResult) {
    return <div />;
  }
  return (
    <>
      <CalculationStatus {...calculationResult} />
      <div className={styles.calculationSummary}>
        <h3>Summary of last successful calculation</h3>
        <table>
          <tbody>
            <tr>
              <th>Iso compliant mode</th>
              <td>{calculationResult.isIsoCompliant?.toString()}</td>
            </tr>
            <tr>
              <th>Raw data points</th>
              <td>{calculationResult.rawDataCount}</td>
            </tr>
            <tr>
              <th>Filtered data points</th>
              <td>{calculationResult.filteredDataCount}</td>
            </tr>
            <tr>
              <th>Calculated data points</th>
              <td>{calculationResult.preparedDataCount}</td>
            </tr>
            <tr>
              <th>Downsampled data points</th>
              <td>{calculationResult.preparedDownsampledDataCount}</td>
            </tr>
            <tr>
              <th>Dry docking events</th>
              <td>{calculationResult.dryDockingEventsCount}</td>
            </tr>
            <tr>
              <th>Performance indicators</th>
              <td>{calculationResult.performanceIndicatorsCount}</td>
            </tr>
            <tr>
              <th>Raw data start time</th>
              <td>
                {calculationResult.rawDataStartTime &&
                  formatDateTimeWithSeconds(calculationResult.rawDataStartTime)}
              </td>
            </tr>
            <tr>
              <th>Filtered data start time</th>
              <td>
                {calculationResult.filteredDataStartTime &&
                  formatDateTimeWithSeconds(
                    calculationResult.filteredDataStartTime
                  )}
              </td>
            </tr>
            <tr>
              <th>Calculated data start time</th>
              <td>
                {calculationResult.preparedDataStartTime &&
                  formatDateTimeWithSeconds(
                    calculationResult.preparedDataStartTime
                  )}
              </td>
            </tr>
            <tr>
              <th>Downsampled data start time</th>
              <td>
                {calculationResult.preparedDownsampledDataStartTime &&
                  formatDateTimeWithSeconds(
                    calculationResult.preparedDownsampledDataStartTime
                  )}
              </td>
            </tr>
            <tr>
              <th>Calculation start</th>
              <td>
                {calculationResult.calculationJobStartTime &&
                  formatDateTimeWithSeconds(
                    calculationResult.calculationJobStartTime
                  )}
              </td>
            </tr>
            <tr>
              <th>Calculation end</th>
              <td>
                {calculationResult.calculationJobEndTime &&
                  formatDateTimeWithSeconds(
                    calculationResult.calculationJobEndTime
                  )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const HullPerformanceConfigPopup = (props) => {
  const {
    requestCalculation,
    recalculate,
    getCalculationResult,
    cancel,
    visible,
    calculationResult,
    isValid,
  } = props;

  const buttons = [
    {
      label: "Request Calculation",
      action: requestCalculation,
      disabled:
        !isValid ||
        calculationResult?.calculationStatus === "enqueued" ||
        calculationResult?.calculationStatus === "requested",
    },
    {
      label: "Delete Data and Recalculate",
      action: recalculate,
      disabled:
        !isValid ||
        calculationResult?.calculationStatus === "enqueued" ||
        calculationResult?.calculationStatus === "requested",
    },
    {
      label: "Update Calculation Status",
      action: getCalculationResult,
      disabled: false,
    },
    {
      label: "Cancel",
      action: cancel,
      disabled: false,
    },
  ];
  return (
    <Popup
      title={"Hull Performance validation and calculation"}
      buttons={buttons}
      visible={visible}
      onHide={cancel}
    >
      <div className={styles.popupContainer}>
        <p>
          <StatusText isValid={props.isValid} />
        </p>
        <ValidationResult {...props} />
        <CalculationResult {...props} />
      </div>
    </Popup>
  );
};

HullPerformanceConfigPopup.defaultProps = {
  errors: [],
};

HullPerformanceConfigPopup.propTypes = {
  visible: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  requestCalculation: PropTypes.func.isRequired,
  recalculate: PropTypes.func.isRequired,
  getCalculationResult: PropTypes.func.isRequired,
  errors: PropTypes.array,
  isValid: PropTypes.bool,
  calculationResult: PropTypes.shape({
    vesselId: PropTypes.string,
    isIsoCompliant: PropTypes.bool,
    vesselStartTime: PropTypes.string,
    rawDataCount: PropTypes.number,
    rawDataStartTime: PropTypes.string,
    filteredDataCount: PropTypes.number,
    filteredDataStartTime: PropTypes.string,
    preparedDataCount: PropTypes.number,
    preparedDataStartTime: PropTypes.string,
    preparedDownsampledDataCount: PropTypes.number,
    preparedDownsampledDataStartTime: PropTypes.string,
    dryDockingEventsCount: PropTypes.number,
    performanceIndicatorsCount: PropTypes.number,
    calculationStatus: PropTypes.string.isRequired,
    calculationJobStartTime: PropTypes.string,
    calculationJobEndTime: PropTypes.string,
    exceptionMessage: PropTypes.string,
    exceptionTime: PropTypes.string,
  }),
  isCalculating: PropTypes.bool,
};

export default HullPerformanceConfigPopup;
