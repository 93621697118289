import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { startCase, toLower } from "lodash";
import classNames from "../../../common/classNames";
import styles from "./GridConfigFlyout.css";
import Button from "../../../common/components/Button";
import { isComponentAlreadyAdded } from "../../common/pageConfig";

class GridConfigFlyout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: this.props.showing,
    };
  }

  toggleGridFlyout() {
    this.setState((state) => ({
      showing: !state.showing,
    }));
  }

  renderButtons() {
    const { buttons } = this.props;
    if (!buttons) return;
    return (
      <div className={styles.componentsListButtons}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            type={button.type}
            clickAction={button.action}
            disabled={button.disabled}
            value={button.label}
          ></Button>
        ))}
      </div>
    );
  }

  render() {
    const showing = this.state.showing ? styles.showing : "";
    const {
      componentsList,
      title,
      onSave,
      onCancel,
      saveButtonValue,
      cancelButtonValue,
      saveButtonType,
      cancelButtonType,
      cancelButtonPadding,
      saveButtonDisabled,
      subtitle,
      disableList,
      rowsConfig,
      errorMessage,
    } = this.props;

    return (
      <div className={classNames(styles.gridFlyout, showing)}>
        <div className={styles.componentsList}>
          <h1 className={styles.title}>{title}</h1>
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          <p className={styles.subtitle}>{subtitle}</p>
          {componentsList.sort().map((c) => {
            return (
              <span
                key={c}
                className={classNames(
                  styles.item,
                  disableList || isComponentAlreadyAdded(c, rowsConfig)
                    ? styles.disabled
                    : ""
                )}
                onClick={() => this.props.onClick(c)}
              >
                {startCase(toLower(c))}
              </span>
            );
          })}
          {this.renderButtons()}
        </div>
        <div className={styles.actionContainer}>
          <Button
            type={saveButtonType}
            clickAction={onSave}
            disabled={saveButtonDisabled}
          >
            <span>{saveButtonValue}</span>
          </Button>
          <Button
            type={cancelButtonType}
            clickAction={onCancel}
            padding={cancelButtonPadding}
          >
            <span>{cancelButtonValue}</span>
          </Button>
        </div>

        <button
          className={styles.gridFlyoutToggle}
          title="Open/close sidebar"
          onClick={this.toggleGridFlyout.bind(this)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 -1 42 42">
            <path d="M25.6 28.4L17.3 20l8.3-8.4.8.8-7.7 7.6 7.7 7.6z" />
          </svg>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const rowsConfig = state.admin.pageLayoutConfiguration.rowsConfig;
  return {
    rowsConfig,
  };
};

GridConfigFlyout.propTypes = {
  componentsList: PropTypes.array,
  showing: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  errorMessage: PropTypes.string,
  disableList: PropTypes.bool,
  onClick: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveButtonValue: PropTypes.string,
  cancelButtonValue: PropTypes.string,
  saveButtonType: PropTypes.string,
  cancelButtonType: PropTypes.string,
  cancelButtonPadding: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      type: PropTypes.string,
    })
  ),
};

export default connect(mapStateToProps)(GridConfigFlyout);
