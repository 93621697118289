import { FETCH_MAP_DATA } from "./action.types";
import { MAP_DATA } from "../api";
import fetch from "./action.fetch";

export const fetchMapData = () =>
  fetch({
    url: MAP_DATA.get(),
    actionType: FETCH_MAP_DATA,
  });

export const fetchMapDataVessel = ({
  vesselId,
  dateRange,
  timeOffset,
  states,
}) =>
  fetch({
    url: MAP_DATA.getVessel(vesselId, dateRange, timeOffset, states),
    actionType: FETCH_MAP_DATA,
  });
