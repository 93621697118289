import CanvasMapOverlay from "../../VesselMap/CanvasMapOverlay/CanvasMapOverlay";
import simpleHeat from "simpleheat";
import { color } from "../../../../common/colors";

class HeatMapOverlay extends CanvasMapOverlay {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    this.heat = simpleHeat(this.canvas);
    this.heat.gradient({
      0.4: color("--blue-darkest"),
      0.6: color("--blue-dark"),
      0.8: color("--blue-base"),
      1: color("--white"),
    });
  }

  renderCanvas({ latLng2Tile, zoom, bounds }) {
    this.heat.clear();
    const heatSetting = { ...DEFAULT_HEAT_SETTING };
    let { data } = this.props;
    let maxValue = 0;
    let dataArray =
      data && data.length > 0
        ? data.reduce((acc, d) => {
            const point = latLng2Tile(d);
            maxValue = Math.max(maxValue, d.value);
            if (checkIfPointInsideBounds(bounds, d)) {
              acc.push([point.x, point.y, d.value]);
            }
            return acc;
          }, [])
        : [];

    heatSetting.maxValue = maxValue;
    heatSetting.minOpacity *= Math.min(
      Math.max(Math.pow(2, zoom) / 1250, 0.02),
      0.3
    );
    this.heat.radius(heatSetting.radius, heatSetting.blur);
    this.heat.resize();
    this.heat.max(heatSetting.maxValue);
    this.heat.data(dataArray);
    this.heat.draw(heatSetting.minOpacity);
  }
}

const DEFAULT_HEAT_SETTING = {
  radius: 5,
  blur: 10,
  minOpacity: 1,
  maxValue: 50,
};

const checkIfPointInsideBounds = (bounds, p) => {
  const { sw, ne } = bounds;
  const isLngInRange =
    ne.lng < sw.lng
      ? p.lng >= sw.lng || p.lng <= ne.lng
      : p.lng >= sw.lng && p.lng <= ne.lng;
  return p.lat >= sw.lat && p.lat <= ne.lat && isLngInRange;
};

export default HeatMapOverlay;
