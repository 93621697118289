import { createIndexedRequestReducer } from "./createRequestReducer";
import { FETCH_DONUT } from "../actions/action.types";
import { prepareDonutData } from "../common/donuts";
import { createKey } from "../common/contexts";

export const donuts = createIndexedRequestReducer(
  FETCH_DONUT,
  ({ itemTypeMetricId, context }) => createKey(itemTypeMetricId, context),
  prepareDonutData
);

export const getDonuts = (state, itemTypeMetricIds = [], context = {}) =>
  itemTypeMetricIds.map((id) => state[createKey(id, context)]);
