export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

export const getGradientTransform = (startAngle, endAngle) => {
  const angleDiff = endAngle - startAngle;
  startAngle = startAngle % 360;
  if (startAngle < 0) {
    startAngle += 360;
  }
  let midAngle = startAngle + angleDiff / 2;
  midAngle = midAngle % 360;
  if (midAngle < 0) {
    midAngle += 360;
  }
  return `rotate(${midAngle}, 0.5, 0.5)`;
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  let angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};
