import React from "react";
import { Route, Routes } from "react-router-dom";
import DevOps from "./DevOps/DevOps";
import DataStatus from "./DataStatus/DataStatus";
import ServiceDashboard from "../ServiceDashboard/ServiceDashboard";
import ServiceHistory from "../ServiceHistory/ServiceHistory";
import Companies from "./Companies/Companies";
import ItemConfig from "./ItemConfig/ItemConfigView";
import Users from "./Users/Users";
import Vessels from "./Vessels/Vessels";
import VesselGroups from "./VesselGroups/VesselGroups";
import EnergyBudget from "./EnergyBudget/EnergyBudget";
import PageConfigs from "./PageConfig/PageConfigs/PageConfigs";
import DataMaintenance from "./DataMaintenance/DataMaintenance";
import HealthManagement from "./HealthManagement/HealthManagement";
import HealthManagementEventEdit from "./HealthManagement/Edit/HealthManagementEventEdit";
import TelemetryView from "./Telemetry/TelemetryView";
import CargoConfig from "./CargoConfig/CargoConfig";
import Admin from "./Admin";
import VesselConfigView from "./VesselConfig/VesselConfigView";
import PageLayoutConfiguration from "./PageConfig/PageLayoutConfiguration/PageLayoutConfiguration";

export default ({ queryContext }) => (
  <Routes>
    <Route path="/" element={<Admin />} />
    <Route path="/devops" element={<DevOps />} />
    <Route path="/datastatus" element={<DataStatus />} />
    <Route path="/service" element={<ServiceDashboard />} />
    <Route
      path="/servicehistory"
      element={<ServiceHistory queryContext={queryContext} />}
    />
    <Route path="/companies" element={<Companies />} />
    <Route path="/itemconfig" element={<ItemConfig />} />
    <Route path="/users" element={<Users />} />
    <Route path="/vessels/new" element={<VesselConfigView isNew={true} />} />
    <Route
      path="/vessels/:vesselId"
      element={<VesselConfigView isNew={false} />}
    />
    <Route path="/vessels" element={<Vessels />} />
    <Route path="/vesselgroups" element={<VesselGroups />} />
    <Route path="/energybudget" element={<EnergyBudget />} />
    <Route path="/pageconfigs" element={<PageConfigs />} />
    <Route
      path="/pageLayoutConfiguration"
      element={<PageLayoutConfiguration />}
    />
    <Route path="/datamaintenance" element={<DataMaintenance />} />
    <Route path="/cargoconfig" element={<CargoConfig />} />
    <Route path="/healthmanagement" element={<HealthManagement />} />
    <Route path="/telemetry" element={<TelemetryView />} />
    <Route
      path="/healthmanagement/:eventId"
      element={<HealthManagementEventEdit />}
    />
  </Routes>
);
