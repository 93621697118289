import { adminApi, vesselConfigApi } from "../../api";
import { admin } from "../action.types";
import fetch from "../action.fetch";

export const fetchApplicationVersions = () =>
  fetch({
    url: adminApi.devOps.application.version.get(),
    actionType: admin.devOps.application.version.FETCH,
  });

export const fetchVesselStatuses = (companyId) =>
  fetch({
    url: adminApi.devOps.get(companyId),
    actionType: admin.devOps.FETCH,
  });

export const fetchMinimumProcessorBuild = () =>
  fetch({
    url: vesselConfigApi.onboardProcessorConfig.getMinimumProcessorBuild(),
    actionType: admin.devOps.FETCH_MINIMUM_PROCESSOR_BUILD,
  });

export const sortVessels = ({ sortBy, sortDirection }) => ({
  type: admin.devOps.SORT_VESSELS,
  sortBy,
  sortDirection,
});

export const setSelectedCompanyForDevOps = (companyId) => ({
  type: admin.devOps.SET_DEVOPS_COMPANY_ID,
  companyId,
});

export const onToggleVesselInsightVessels = (showVesselInsightVessels) => ({
  type: admin.devOps.TOGGLE_VESSEL_INSIGHT_VESSELS,
  showVesselInsightVessels,
});
