import styles from "./HmTooltip.css";
import React from "react";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import { formatDate } from "../../common/dates";

const HmTooltip = ({ value }) => {
  const template = (
    <div>
      <div className={styles.title}>Workflow</div>
      <div className={styles.workflow}>
        <ol>
          <li>
            After an event has been approved it is in a state of "Pending
            Action". This means that some type of action is expected from the
            user.
          </li>
          <li>
            After seeing an event, you can acknowledge it. This means you are
            aware of the problem and are working on fixing it.
          </li>
          <li>
            When the problem is fixed you can then put the event in a "Resolved"
            state.
          </li>
        </ol>
      </div>
      <div className={styles.title}>Event history</div>
      <div className={styles.timestamps}>
        <ul>
          <li>The event appeared as: {value.status}</li>
          <li>
            The event{" "}
            {value.acknowledgedOn === null || value.acknowledgedOn === undefined
              ? "has not been akcnowledged yet"
              : `was acknowledged on ${formatDate(value.acknowledgedOn)}`}
          </li>
          <li>
            The event{" "}
            {value.resolvedOn === null || value.resolvedOn === undefined
              ? "has not been resolved yet"
              : `was resolved on ${formatDate(value.resolvedOn)}`}
          </li>
        </ul>
      </div>
    </div>
  );
  return (
    <div className={styles.tooltip}>
      <ComponentInfo
        modalTargetId={"popupContainer"}
        template={template}
        width={800}
      >
        {" "}
      </ComponentInfo>
    </div>
  );
};

export default HmTooltip;
