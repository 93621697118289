import {
  ACKNOWLEDGE_EVENT,
  ACKNOWLEDGE_EVENT_SUCCESS,
  FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS_SUCCESS,
  FETCH_ITEM_TYPE_DATA,
  FETCH_VESSEL_ITEM_DETAILS,
  FETCH_VESSEL_ITEM_DETAILS_ERROR,
  FETCH_VESSEL_ITEM_DETAILS_SUCCESS,
  RESOLVE_EVENT_SUCCESS,
  RESOLVE_EVENT,
  TOGGLE_VESSEL_ITEM_DETAILS,
  PENDING_ACTION_EVENT,
  PENDING_ACTION_EVENT_SUCCESS,
} from "../actions/action.types";
import createReducer from "./createReducer";

const isExpanded = (id, state) => Boolean((state[id] || {}).isExpanded);

const initialState = {
  dataDetails: {},
};

export const fetchVesselItemDetails = createReducer(initialState, {
  [FETCH_VESSEL_ITEM_DETAILS]: (state, { vesselItemId }) => ({
    ...state,
    [vesselItemId]: {
      isExpanded: isExpanded(vesselItemId, state),
      data: { vesselItemId, data: [], children: [] },
      isLoading: true,
    },
  }),

  [FETCH_VESSEL_ITEM_DETAILS_SUCCESS]: (state, { vesselItemId, data }) => ({
    ...state,
    [vesselItemId]: {
      isExpanded: isExpanded(vesselItemId, state),
      data,
    },
  }),

  [FETCH_VESSEL_ITEM_DETAILS_ERROR]: (state, { vesselItemId, error }) => ({
    ...state,
    [vesselItemId]: {
      isExpanded: isExpanded(vesselItemId, state),
      error,
    },
  }),

  [TOGGLE_VESSEL_ITEM_DETAILS]: (state, { vesselItemId }) => ({
    ...state,
    [vesselItemId]: {
      ...state[vesselItemId],
      isExpanded: !isExpanded(vesselItemId, state),
    },
  }),

  [FETCH_HEALTH_MANAGEMENT_EVENT_DETAILS_SUCCESS]: (
    state,
    { data, eventId }
  ) => ({
    ...state,
    dataDetails: {
      ...state.dataDetails,
      [eventId]: data,
    },
  }),
  [ACKNOWLEDGE_EVENT]: (state, { vesselItemId, eventId }) =>
    createState(state, vesselItemId, eventId),

  [ACKNOWLEDGE_EVENT_SUCCESS]: (state, { data, vesselItemId }) =>
    createStateSuccess(state, vesselItemId, data, "Acknowledged"),

  [RESOLVE_EVENT]: (state, { vesselItemId, eventId }) =>
    createState(state, vesselItemId, eventId),

  [RESOLVE_EVENT_SUCCESS]: (state, { data, vesselItemId }) =>
    createStateSuccess(state, vesselItemId, data, "Resolved"),

  [PENDING_ACTION_EVENT]: (state, { vesselItemId, eventId }) =>
    createState(state, vesselItemId, eventId),

  [PENDING_ACTION_EVENT_SUCCESS]: (state, { data, vesselItemId }) =>
    createStateSuccess(state, vesselItemId, data, "Pending Action"),

  [FETCH_ITEM_TYPE_DATA]: () => initialState,
});

function createStateSuccess(state, vesselItemId, response, resolution) {
  let vesselItemData = state[vesselItemId].data.data;
  let indexOfAlerts = vesselItemData.indexOf(
    vesselItemData.find((items) => items.title === "Alerts")
  );

  let events = state[vesselItemId].data.data[indexOfAlerts].items;

  let indexOfEvent = events.indexOf(
    events.find((event) => event.id === response.id)
  );

  let propertiesList =
    state[vesselItemId].data.data[indexOfAlerts].items[indexOfEvent].properties;

  let indexOfResolutionProperty = propertiesList.indexOf(
    propertiesList.find((property) => property.name === "Resolution Status")
  );

  let indexOfStatus = propertiesList.indexOf(
    propertiesList.find((property) => property.name === "Status")
  );

  let indexOfTooltip = propertiesList.indexOf(
    propertiesList.find((property) => property.name === "Tooltip")
  );

  const updateValueProperties = (valueProperties, response, resolution) => {
    const { acknowledgedOn, resolvedOn } = response;

    return [
      ...valueProperties.slice(0, indexOfTooltip),
      {
        ...valueProperties[indexOfTooltip],
        value: {
          ...valueProperties[indexOfTooltip].value,
          acknowledgedOn,
          resolvedOn,
        },
      },
      ...valueProperties.slice(indexOfTooltip + 1, indexOfStatus),
      {
        ...valueProperties[indexOfStatus],
        value: {
          ...valueProperties[indexOfStatus].value,
          resolution: resolution,
        },
      },
      ...valueProperties.slice(indexOfStatus + 1, indexOfResolutionProperty),
      {
        ...valueProperties[indexOfResolutionProperty],
        value: resolution,
        isDisabled: false,
      },
      ...valueProperties.slice(indexOfResolutionProperty + 1),
    ];
  };

  const updateEventItems = (eventItems, response, resolution) => {
    return eventItems.map((eventItem, index) => {
      if (index !== indexOfEvent) return eventItem;
      return {
        ...eventItem,
        properties: updateValueProperties(
          eventItem.properties,
          response,
          resolution
        ),
      };
    });
  };

  const updateAlertData = (alertData, response, resolution) => {
    return alertData.map((alert, index) => {
      if (index !== indexOfAlerts) return alert;
      return {
        ...alert,
        items: updateEventItems(alert.items, response, resolution),
      };
    });
  };

  const updateVesselItemData = (vesselItemData, response, resolution) => {
    return {
      ...vesselItemData,
      data: updateAlertData(vesselItemData.data, response, resolution),
    };
  };

  const updateState = (state, vesselItemId, response, resolution) => {
    return {
      ...state,
      [vesselItemId]: {
        ...state[vesselItemId],
        data: updateVesselItemData(
          state[vesselItemId].data,
          response,
          resolution
        ),
      },
    };
  };

  return updateState(state, vesselItemId, response, resolution);
}

function createState(state, vesselItemId, eventId) {
  let vesselItemData = state[vesselItemId].data.data;
  let indexOfAlerts = vesselItemData.indexOf(
    vesselItemData.find((items) => items.title === "Alerts")
  );

  let events = state[vesselItemId].data.data[indexOfAlerts].items;
  let indexOfEvent = events.indexOf(
    events.find((event) => event.id === eventId)
  );

  let propertiesList =
    state[vesselItemId].data.data[indexOfAlerts].items[indexOfEvent].properties;
  let indexOfResolutionProperty = propertiesList.indexOf(
    propertiesList.find((property) => property.name === "Resolution Status")
  );

  const updateValueProperties = (valueProperties) => {
    return [
      ...valueProperties.slice(0, indexOfResolutionProperty),
      {
        ...valueProperties[indexOfResolutionProperty],
        isDisabled: true,
      },
      ...valueProperties.slice(indexOfResolutionProperty + 1),
    ];
  };

  const updateEventItems = (eventItems) => {
    return eventItems.map((eventItem, index) => {
      if (index !== indexOfEvent) return eventItem;
      return {
        ...eventItem,
        properties: updateValueProperties(eventItem.properties),
      };
    });
  };

  const updateAlertData = (alertData) => {
    return alertData.map((alert, index) => {
      if (index !== indexOfAlerts) return alert;
      return {
        ...alert,
        items: updateEventItems(alert.items),
      };
    });
  };

  const updateVesselItemData = (vesselItemData) => {
    return {
      ...vesselItemData,
      data: updateAlertData(vesselItemData.data),
    };
  };

  const updateState = (state, vesselItemId) => {
    return {
      ...state,
      [vesselItemId]: {
        ...state[vesselItemId],
        data: updateVesselItemData(state[vesselItemId].data),
      },
    };
  };

  return updateState(state, vesselItemId);
}
