import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./SubHeader.css";
import notifySubHeaderVisible from "../../actions/action.notifySubHeaderVisible";
import notifySubHeaderHidden from "../../actions/action.notifySubHeaderHidden";

class SubHeader extends Component {
  componentDidMount() {
    this.props.notifySubHeaderVisible();
  }

  componentWillUnmount() {
    this.props.notifySubHeaderHidden();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>{this.props.children}</div>
      </div>
    );
  }
}

export default connect(null, { notifySubHeaderVisible, notifySubHeaderHidden })(
  SubHeader
);
