import React from "react";
import styles from "./EhmStatusPropertyValue.css";
import classNames from "../../../common/classNames";
import { isNullOrUndefined } from "../../../../src/common/objects";

const EhmStatusPropertyValue = ({ value, name, showName = true }) => {
  let displayStatus;

  if (
    !isNullOrUndefined(value.resolution) &&
    value.resolution !== "Pending Action"
  ) {
    displayStatus = value.resolution;
  } else {
    displayStatus = value.status || value;
  }

  return (
    <div className={"propertyContainer"}>
      <span
        className={classNames("propertyValue", styles.status)}
        data-type={displayStatus}
      >
        {displayStatus}
      </span>
      {showName && <span className={"propertyName"}>{name}</span>}
    </div>
  );
};

export default EhmStatusPropertyValue;
