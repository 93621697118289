import createReducer from "./createReducer";
import { SET_SELECTED_DASHBOARD } from "../actions/action.types";

const initialState = {
  selectedDashboardId: null,
};

export default createReducer(initialState, {
  [SET_SELECTED_DASHBOARD]: (_state, dashboard) => {
    return {
      selectedDashboardId: dashboard.id,
    };
  },
});
