import { isInRange } from "../../common/numbers";
import moment from "moment";

export const isRequired = (value) => {
  if (!value) {
    return {
      isValid: false,
      validationMessage: "Please enter a value",
      validationLabel: "required",
    };
  }
  return {
    isValid: true,
    validationLabel: "required",
  };
};

export const isIntegerRange = (value, min, max) => {
  if (value && !isInRange(value, min, max)) {
    return {
      isValid: isInRange(value, min, max),
      validationMessage: `Integer must be within ${min} and ${max}`,
    };
  }
  return {
    isValid: true,
  };
};

export const isEmail = (value) => {
  if (value) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid email address",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isNumber = (value) => {
  if (value) {
    const numberRegex = /^-?\d*\.?\d*$/;
    if (!numberRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid number",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isPositiveNumber = (value) => {
  if (value) {
    const numberRegex = /^((0|[1-9]\d*)|(\d+([.]\d+)))$/; //based on https://stackoverflow.com/questions/7708333
    if (!numberRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid positive number",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isInteger = (value) => {
  if (value) {
    const numberRegex = /^-?\d*$/;
    if (!numberRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid integer",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isGuid = (value) => {
  if (value) {
    // Define a regular expression pattern for a valid GUID
    const guidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    if (!guidRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid GUID",
      };
    }
  }

  return {
    isValid: true,
  };
};
export const isPositiveInteger = (value) => {
  if (value) {
    const numberRegex = /^(0|[1-9]\d*)$/; //based on https://stackoverflow.com/questions/7036324
    if (!numberRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid positive integer",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isNotZero = (value) => {
  if (value === "0") {
    return { isValid: false, validationMessage: "Value cannot be 0" };
  }
  return { isValid: true };
};

export const isDateTime = (value) => {
  if (value) {
    const parsed = moment.utc(value, "YYYY-MM-DD HH:mm:ss", true);
    if (!parsed.isValid()) {
      return {
        isValid: false,
        validationMessage: "Not a valid datetime",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isDate = (value) => {
  if (value) {
    const partialDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const parsedRelaxed = moment.utc(value, "YYYY-MM-DD HH:mm:ss", false);
    if (!parsedRelaxed.isValid() || !partialDateRegex.test(value)) {
      return {
        isValid: false,
        validationMessage: "Not a valid date",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const isJson = (value) => {
  if (value) {
    try {
      JSON.parse(value);
    } catch (e) {
      return {
        isValid: false,
        validationMessage: "Not valid JSON",
      };
    }
  }
  return {
    isValid: true,
  };
};
