import {
  BEGIN_DOCUMENTS_EDIT_FOLDER,
  BEGIN_DOCUMENTS_ADD_FILE,
  BEGIN_DOCUMENTS_EDIT_FILE,
  CANCEL_DOCUMENTS_EDIT_FOLDER,
  CANCEL_DOCUMENTS_FILE,
  CLEAR_DOCUMENTS_FILTERS,
  SAVE_DOCUMENTS_EDIT_FOLDER,
  SAVE_DOCUMENTS_FILE,
  SET_DOCUMENTS_EDIT_FILE,
  SET_DOCUMENTS_EDIT_FILENAME,
  SET_DOCUMENTS_EDIT_VESSEL_ID,
  SET_DOCUMENTS_FILE_TYPE_FILTER,
  SET_DOCUMENTS_MODIFIED_FILTER,
  SET_DOCUMENTS_SEARCH_STRING,
  SET_DOCUMENTS_VESSEL_FILTER,
  SET_DOCUMENTS_VESSEL_GROUP_FILTER,
  TOGGLE_DOCUMENT_FOLDER_OPEN,
  FETCH_DOCUMENTS_FILE_TYPES_ERROR,
  FETCH_DOCUMENTS_FILE_TYPES,
  FETCH_DOCUMENTS_FILE_TYPES_SUCCESS,
  FETCH_DOCUMENTS_DATE_RANGE,
  FETCH_DOCUMENTS_DATE_RANGE_SUCCESS,
  FETCH_DOCUMENTS_DATE_RANGE_ERROR,
  SAVE_DOCUMENTS_FILE_SUCCESS,
  SAVE_DOCUMENTS_FILE_ERROR,
  SAVE_DOCUMENTS_FILE_PROGRESS,
  SET_DOCUMENTS_EDIT_FILE_FOLDER_ID,
  BEGIN_DOCUMENTS_ADD_FOLDER,
  SET_DOCUMENTS_COMPANY_ID,
  SET_DOCUMENTS_EDIT_FOLDER_NAME,
  FETCH_DOCUMENTS_FILES,
  FETCH_DOCUMENTS_FOLDERS_ERROR,
  FETCH_DOCUMENTS_FOLDERS_SUCCESS,
  SET_DOCUMENTS_EDIT_FOLDER_DESC,
  FETCH_DOCUMENTS_FOLDERS,
  SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS,
  SAVE_DOCUMENTS_EDIT_FOLDER_ERROR,
  SET_DOCUMENTS_EDIT_CHECKED,
  BEGIN_DOCUMENTS_DELETE_FILE,
  CANCEL_DOCUMENTS_DELETE_FILE,
  DELETE_DOCUMENTS_FILE,
  DELETE_DOCUMENTS_FILE_SUCCESS,
  DELETE_DOCUMENTS_FILE_ERROR,
  FETCH_DOCUMENTS_FILE_DOWNLOAD_URL,
  FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_ERROR,
  FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_SUCCESS,
  LOAD_DOCUMENTS_FILTERS_ERROR,
  LOAD_DOCUMENTS_FILTERS_SUCCESS,
  LOAD_DOCUMENTS_FILTERS,
  CLEAR_DOCUMENTS_ERRORS,
} from "./action.types";
import { DOCUMENTS } from "../api";
import { get, debounce } from "lodash";
import fetch from "../actions/action.fetch";

const parseError = (error, prefix = "") => {
  return {
    error: `${prefix}${error.message}`,
  };
};

export const loadDocumentFilters =
  () =>
  (dispatch, getState, { http }) => {
    const { documents } = getState();
    const { selectedCompanyId } = documents;

    dispatch({
      type: LOAD_DOCUMENTS_FILTERS,
    });

    const fetchFileTypesPromise = new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_DOCUMENTS_FILE_TYPES,
      });
      http
        .get(DOCUMENTS.fetchFileTypes(selectedCompanyId))
        .then((response) => {
          dispatch({
            type: FETCH_DOCUMENTS_FILE_TYPES_SUCCESS,
            data: response.data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_DOCUMENTS_FILE_TYPES_ERROR,
            ...parseError(error),
          });
          reject(error);
        });
    });

    const fetchDateRangePromise = new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_DOCUMENTS_DATE_RANGE,
      });
      http
        .get(DOCUMENTS.fetchDateRange(selectedCompanyId))
        .then((response) => {
          dispatch({
            type: FETCH_DOCUMENTS_DATE_RANGE_SUCCESS,
            data: response.data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_DOCUMENTS_DATE_RANGE_ERROR,
            ...parseError(error),
          });
          reject(error);
        });
    });

    const fetchFolderPromise = dispatch(fetchFolders(selectedCompanyId));

    Promise.all([
      fetchFileTypesPromise,
      fetchDateRangePromise,
      fetchFolderPromise,
    ])
      .then(() => {
        dispatch({
          type: LOAD_DOCUMENTS_FILTERS_SUCCESS,
        });
      })
      .catch((response) => {
        dispatch({
          type: LOAD_DOCUMENTS_FILTERS_ERROR,
          ...parseError(response, "An error occurred while loading filters. "),
        });
      });
  };

export const setSearchString = (searchString) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_SEARCH_STRING,
    searchString,
  });
  dispatch(fetchFiles());
};

export const clearFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_DOCUMENTS_FILTERS });
  dispatch(fetchFiles());
};

export const setFilterVesselGroup = (group) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_VESSEL_GROUP_FILTER,
    group,
  });
  dispatch(fetchFiles());
};

export const setFilterVessel = (vessel) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_VESSEL_FILTER,
    vessel,
  });
  dispatch(fetchFiles());
};

export const setFilterModified = (date) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_MODIFIED_FILTER,
    date,
  });
  dispatch(fetchFiles());
};

export const setFilterFileType = (fileType) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTS_FILE_TYPE_FILTER,
    fileType,
  });
  dispatch(fetchFiles());
};

export const toggleFolderOpen = (folderId) => ({
  type: TOGGLE_DOCUMENT_FOLDER_OPEN,
  folderId,
});

export const beginEditFile = (file) => ({
  type: BEGIN_DOCUMENTS_EDIT_FILE,
  file,
});

export const cancelFile = () => ({
  type: CANCEL_DOCUMENTS_FILE,
});

export const setEditFilename = (filename) => ({
  type: SET_DOCUMENTS_EDIT_FILENAME,
  filename,
});

export const setEditVesselId = (vesselId) => ({
  type: SET_DOCUMENTS_EDIT_VESSEL_ID,
  vesselId,
});

export const setEditFile = (file, target) => ({
  type: SET_DOCUMENTS_EDIT_FILE,
  file,
  target,
});

export const setEditFileFolderId = (folderId) => ({
  type: SET_DOCUMENTS_EDIT_FILE_FOLDER_ID,
  folderId,
});

export const setEditPolicyChecked = (policyChecked) => ({
  type: SET_DOCUMENTS_EDIT_CHECKED,
  policyChecked,
});

export const beginAddFile = (folder) => ({
  type: BEGIN_DOCUMENTS_ADD_FILE,
  folder,
});

export const saveFile =
  () =>
  (dispatch, getState, { http }) => {
    const { documents } = getState();
    const { editFile } = documents;
    dispatch({
      type: SAVE_DOCUMENTS_FILE,
    });
    const formData = new FormData();
    const isNew = editFile.new || false;

    const file = get(editFile, "target.files[0]", "");
    if (file) {
      formData.append("file", file);
    }
    if (editFile.vesselId) {
      formData.append("vesselId", editFile.vesselId);
    }
    formData.append("name", editFile.name);
    formData.append("folderId", editFile.vesselDocumentFolderId);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: SAVE_DOCUMENTS_FILE_PROGRESS,
          progress: percentCompleted,
        });
      },
    };

    const url =
      isNew === true ? DOCUMENTS.addFile() : DOCUMENTS.updateFile(editFile.id);

    http
      .post(url, formData, config)
      .then(() => {
        dispatch({
          type: SAVE_DOCUMENTS_FILE_SUCCESS,
        });
        dispatch(fetchFiles());
        dispatch(loadDocumentFilters());
      })
      .catch((error) => {
        dispatch({
          type: SAVE_DOCUMENTS_FILE_ERROR,
          ...parseError(error),
        });
        dispatch(fetchFiles());
      });
  };

export const beginDeleteFile = (fileId) => ({
  type: BEGIN_DOCUMENTS_DELETE_FILE,
  fileId,
});

export const cancelDeleteFile = () => ({
  type: CANCEL_DOCUMENTS_DELETE_FILE,
});

export const deleteFile =
  () =>
  (dispatch, getState, { http }) => {
    dispatch({
      type: DELETE_DOCUMENTS_FILE,
    });
    const state = getState();
    const fileId = get(state, "documents.deleteFile.fileId");
    http
      .post(DOCUMENTS.deleteFile(fileId))
      .then(() => {
        dispatch({
          type: DELETE_DOCUMENTS_FILE_SUCCESS,
        });
        dispatch(fetchFiles());
        dispatch(loadDocumentFilters());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENTS_FILE_ERROR,
          ...parseError(error),
        });
      });
  };

export const beginEditFolder = (folder) => ({
  type: BEGIN_DOCUMENTS_EDIT_FOLDER,
  folder,
});

export const beginAddFolder = () => ({
  type: BEGIN_DOCUMENTS_ADD_FOLDER,
});

export const cancelEditFolder = () => ({
  type: CANCEL_DOCUMENTS_EDIT_FOLDER,
});

export const saveEditFolder =
  () =>
  (dispatch, getState, { http }) => {
    const { documents } = getState();
    const { editFolder } = documents;
    dispatch({
      type: SAVE_DOCUMENTS_EDIT_FOLDER,
    });

    const url =
      editFolder.new === true
        ? DOCUMENTS.addFolder()
        : DOCUMENTS.updateFolder(editFolder.id);

    http
      .post(url, {
        name: editFolder.name,
        description: editFolder.description,
        companyId: documents.selectedCompanyId,
      })
      .then(() => {
        dispatch({
          type: SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS,
        });
        dispatch(fetchFolders(documents.selectedCompanyId));
        dispatch(fetchFiles());
      })
      .catch((error) => {
        dispatch({
          type: SAVE_DOCUMENTS_EDIT_FOLDER_ERROR,
          ...parseError(error),
        });
        dispatch(fetchFolders(documents.selectedCompanyId));
        dispatch(fetchFiles());
      });
  };

export const setFolderName = (folderName) => ({
  type: SET_DOCUMENTS_EDIT_FOLDER_NAME,
  folderName,
});

export const setFolderDescription = (description) => ({
  type: SET_DOCUMENTS_EDIT_FOLDER_DESC,
  description,
});

export const setCompanyId = (companyId) => (dispatch) => {
  dispatch(fetchFolders(companyId));
  dispatch({
    type: SET_DOCUMENTS_COMPANY_ID,
    companyId,
  });
  dispatch(clearFilters());
  dispatch(loadDocumentFilters());
};

export const fetchFolders =
  (companyId) =>
  (dispatch, _getState, { http }) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_DOCUMENTS_FOLDERS,
        companyId,
      });
      http
        .get(DOCUMENTS.getFolders(companyId))
        .then((response) => {
          dispatch({
            type: FETCH_DOCUMENTS_FOLDERS_SUCCESS,
            folders: response.data,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_DOCUMENTS_FOLDERS_ERROR,
            ...parseError(error),
          });
          reject();
        });
    });
  };

const fetchFilesDebounced = debounce((dispatch, getState) => {
  const { documents } = getState();

  const {
    searchString,
    selectedCompanyId,
    filterGroup,
    filterVessel,
    filterFileType,
    filterModified,
  } = documents;
  dispatch(
    fetch({
      url: DOCUMENTS.getFiles({
        search: searchString,
        companyId: selectedCompanyId,
        vesselGroupId: get(filterGroup, "id"),
        vesselId: get(filterVessel, "id"),
        fileTypes: get(filterFileType, "fileType"),
        from: get(filterModified, "range.from"),
        to: get(filterModified, "range.to"),
        timeOffset: get(filterModified, "timeOffset"),
      }),
      actionType: FETCH_DOCUMENTS_FILES,
    })
  );
}, 400);

export const fetchFiles = () => fetchFilesDebounced;

export const downloadFile =
  (fileId) =>
  (dispatch, _getState, { http }) => {
    dispatch({
      type: FETCH_DOCUMENTS_FILE_DOWNLOAD_URL,
    });
    http
      .get(DOCUMENTS.getDownloadUrl(fileId))
      .then((response) => {
        dispatch({
          type: FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_SUCCESS,
          data: response.data,
        });
        window.location = response.data;
      })
      .catch((error) => {
        dispatch({
          type: FETCH_DOCUMENTS_FILE_DOWNLOAD_URL_ERROR,
          ...parseError(error),
        });
      });
  };

export const clearErrors = () => ({
  type: CLEAR_DOCUMENTS_ERRORS,
});
