import { userDataMock } from "./userDataMock";
import { http, HttpResponse } from "msw";
import { mockBaseUrl } from "../config";
import { USER } from "../../api";

export const userDataEndpoints = [
  http.get(`${mockBaseUrl}${USER.get()}`, () =>
    HttpResponse.json(userDataMock)
  ),
];
