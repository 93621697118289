import moment from "moment";
import { roundNumberInt } from "../../common/numbers";
import { formatDurationSec } from "../../common/durations";
import { formatDateTime, secondsToDate } from "./dates";

const _1_hour = 3600;
const _12_hours = 43200;
const _6_hours = 21600;
const _24_hours = 86400;

export const formatCpuLoadAvg = (cpuLoadAvg) => {
  return cpuLoadAvg ? `${roundNumberInt(cpuLoadAvg)} %` : undefined;
};

export const formatDiskUsage = (diskUsage) => {
  return diskUsage ? `${roundNumberInt(diskUsage)} %` : undefined;
};

export const formatLastPing = (lastPing) => {
  return lastPing !== undefined
    ? `${moment.duration(lastPing, "seconds").humanize()} ago`
    : undefined;
};

export const formatLastPingDetailed = (lastPing) => {
  return lastPing && formatDurationSec(lastPing);
};

export const formatSystem = (lastPing) => {
  return lastPing !== undefined ? null : undefined;
};

export const formatUptime = (uptime) => {
  return uptime && moment.duration(uptime, "seconds").humanize();
};

export const formatUptimeDetailed = (uptime) => {
  return uptime && formatDurationSec(uptime);
};

export const formatLastDataToShore = (lastDataToShore) => {
  if (!lastDataToShore && lastDataToShore !== 0) {
    return "> a month ago";
  }
  return `${moment.duration(lastDataToShore, "seconds").humanize()} ago`;
};

export const formatLastDataToShoreDetailed = (lastDataToShore) => {
  if (!lastDataToShore && lastDataToShore !== 0) {
    return "More than a month ago";
  }
  return formatDateTime(secondsToDate(-lastDataToShore));
};

export const formatVersion = (versionsOutdated) =>
  versionsOutdated === undefined || versionsOutdated !== 0 ? undefined : null;

export const getActionsStatus = ({
  actions,
  actionsFailed,
  actionsPending,
  actionsScheduled,
}) => ({
  isError: actions && (actionsPending > 0 || actionsFailed > 0),
  isWarning: actions && actionsScheduled > 0,
});

/**
 *
 * @param cpuLoadAvg Average CPU load
 * @returns {{isError: boolean}}
 *          isError: true when CPU average is above 75%
 */
export const getCpuLoadAvgStatus = ({ cpuLoadAvg }) => ({
  isError: cpuLoadAvg > 75,
});

export const getProcessorBuildStatus = (
  { processorBuild },
  minimumProcessorBuild
) => {
  return {
    isDisabled: !minimumProcessorBuild,
    isWarning: processorBuild < minimumProcessorBuild,
  };
};

/**
 * Format 'db write KPI'.
 *
 * @param writeOkDiff   writeOk KPI
 * @param writeError    Number of write errors
 * @returns   {{isError: boolean, isWarning: boolean}}
 *            isError: true when writeOkDiff is zero or write errors exist
 *            isWarning : true when writeOkDiff is between 0 and 60
 */
export const getDbStatus = ({ writeOkDiff, writeError }) => ({
  isWarning: writeOkDiff < 60,
  isError: writeOkDiff === 0 || writeError > 0,
});

/**
 * Returns a graded background color based on the system wide normal-red color.
 *
 * @param writeOkDiff
 * @returns   {{background: string}}
 *            As writeOkDiff becomes lower and lower the background
 *            color will gradually increase in opacity.
 */
export const getDbWarningStyle = ({ writeOkDiff }) => {
  const getWriteOkDiffClause = () =>
    writeOkDiff !== 0 ? writeOkDiff / 100 : 0;
  const opacity = writeOkDiff < 60 ? 1 - getWriteOkDiffClause() : 1;

  return {
    background: `rgba(232, 69, 105, ${opacity})`,
  };
};

/**
 * Format disk status
 * @param diskStatus    Disk status from S.M.A.R.T. where 0 is OK, 1 is warning
 *                      and 2 is error
 * @param diskSize      Used space in percentage
 * @returns {{isError: boolean, isWarning: boolean}}
 *          isError: true when used disk space is > 70 or SMART reports error
 *          isWarning: true when used disk space is > 40 or SMART reports warning
 */
export const getDiskStatus = ({ diskStatus, diskSize }) => ({
  isWarning: diskSize > 40 || diskStatus === 1,
  isError: diskSize > 70 || diskStatus > 1,
});

/**
 * @param lastPing    duration since last ping, in seconds.
 * @returns   {{isDisabled: boolean, isError: boolean, isWarning: boolean}}
 *            isDisabled: true when startTime of vessel is later than current time
 *            isError: true when last ping is more than 12 hours ago
 *            isWarning: true when last ping is between 1 and 12 hours ago
 */
export const getLastPingStatus = ({ startTime, lastPing }) => ({
  isDisabled: moment(startTime).isAfter(),
  isError: lastPing > _12_hours,
  isWarning: lastPing > _1_hour && lastPing <= _12_hours,
});

/**
 * Format 'running processes'. Display error if the difference in running
 processes is a negative
 * @param processRun          Number of current running processes
 * @param processRunDiff      Number of maximum running processes within
 *                            a timespan.
 * @returns {{isError: boolean}}
 */
export const getProcessStatus = ({ processRunDiff }) => ({
  isError: processRunDiff < 0,
});

/**
 * Format security alerts. Display error if any security alerts exist
 * @param securityAlerts    Number of active security alerts
 *
 * @returns {{isError: boolean}}
 */
export const getSecurityStatus = ({ securityAlerts }) => ({
  isError: securityAlerts > 0,
});

/**
 * Format system messages
 * @param systemVerification
 * @returns {{isError: boolean, isWarning: boolean}}
 *          isWarning: true when systemVerification is set
 *          isError: true when systemVerification starts with "error" (case insensitive)
 */
export const getSystemStatus = ({ systemVerification }) => ({
  isWarning: systemVerification && systemVerification.length > 0,
  isError:
    systemVerification && systemVerification.toLowerCase().startsWith("error"),
});

/**
 * Format uptime
 * @param uptime
 * @returns {{isError: boolean, isWarning: boolean}}
 *            isError: true when uptime is less than one day
 *            isWarning: true when uptime is less than three days
 */
export const getUptimeStatus = ({ uptime }) => ({
  value: uptime,
  isError: uptime < _24_hours,
  isWarning: uptime >= _24_hours && uptime < _24_hours * 3,
});
/**
 * Format dataToShore
 * @param dataToShore
 * @returns {{isError: boolean, isWarning: boolean}}
 *            isError: true when dataToShore is greater than 24 hours
 *            isWarning: true when dataToShore is greater than 6 hours
 */
export const getLastDataToShoreStatus = ({ dataToShore }) => ({
  value: dataToShore,
  isWarning: dataToShore > _6_hours && dataToShore <= _24_hours,
  isError: dataToShore > _24_hours || dataToShore === undefined,
});

/**
 * Format number of outdated versions
 * @param versionsOutdated
 * @returns {{isWarning: boolean}}
 *          isWarning: true when one or more versions are outdated
 */
export const getVersionStatus = ({ versionsOutdated }) => ({
  isWarning: versionsOutdated > 0,
});
