import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createDate, today } from "../../../common/dates";
import DatePickerContainer from "../../../components/DatePicker/DatePickerContainer";
import { Header } from "../../../components/Header";
import styles from "./TelemetryView.css";
import { createSelector } from "reselect";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
} from "../../../selectors/filters";
import { VIEWS } from "../../../config";
import {
  fetchTelemetryData,
  sortTelemetry,
} from "../../../actions/admin/action.telemetry";
import { get, isEqual } from "lodash";
import TelemetryTable from "./TelemetryTable";

class TelemetryView extends PureComponent {
  componentDidMount() {
    const { filters } = this.props;
    if (!filters.isValid) {
      this.adjustFilters();
    } else {
      this.props.fetchTelemetryData(filters.dateFilter.value);
    }
  }
  componentDidUpdate(oldProps) {
    const { filters } = this.props;
    const oldFilters = oldProps.filters;

    if (!filters.isValid) {
      this.adjustFilters();
    } else if (!isEqual(filters.dateFilter, oldFilters.dateFilter)) {
      this.props.fetchTelemetryData(filters.dateFilter.value);
    }
  }

  adjustFilters() {
    this.props.navigate(
      VIEWS.telemetry.url,
      convertFiltersToQueryParams(this.props.filters),
      { replace: true }
    );
  }

  render() {
    const { filters, telemetry, sortTelemetry: onSort } = this.props;
    const { data, sortBy, sortDirection, isLoading, error } = telemetry;
    return (
      <div>
        <Header title="Admin - Telemetry">
          <div className={styles.datepickerContainer}>
            <DatePickerContainer
              current={filters.dateFilter.value}
              limits={this.props.limits}
              onChange={this.props.setDate}
              supportedTypes={["year", "quarter", "month", "custom"]}
            />
          </div>
        </Header>
        <div className={styles.dataGridContainer}>
          <TelemetryTable
            data={data}
            isLoading={isLoading}
            error={error}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={onSort}
          />
        </div>
      </div>
    );
  }
}

const getTelemetryFilters = createSelector(
  [getDateRangeFilter],
  (dateFilter) => {
    return {
      isValid: dateFilter.isValid,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const filters = getTelemetryFilters(state, props);
  return {
    limits: {
      from: createDate(2010, 1, 1),
      to: today(),
    },
    filters,
    navigate: props.queryContext.navigate,
    setDate: props.queryContext.setDate,
    telemetry: get(state, "admin.telemetry"),
  };
};

const mapActionsToProps = {
  fetchTelemetryData,
  sortTelemetry,
};

export default connect(mapStateToProps, mapActionsToProps)(TelemetryView);
