import * as d3 from "d3";
import { isNil, get, isDate } from "lodash";
import memoize from "memoize-one";
import React from "react";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import { color } from "../../../common/colors";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineCustomElement from "../../../common/components/Sparkline/SparklineCustomElement";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineLine from "../../../common/components/Sparkline/SparklineLine";
import SparklineTooltip from "../../../common/components/Sparkline/SparklineTooltip";
import {
  createTooltipFormatter,
  prepareYDomain,
  prepareGraphData,
} from "../../reducers/reducer.trend";
import Loader from "../../../common/components/Loader/Loader";
import styles from "./TrendGraphView.css";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";

const getCustomValueFunction = (data) => {
  const xBisector = d3.bisector((x) => x).left;
  const xValues = this.filterValidXValues(data.xValues);
  const index = xBisector(xValues, data.mVal);
  if (index >= 0 && index < xValues.length) {
    const d0 = xValues[index - 1];
    const d1 = xValues[index];
    return data.mVal - d0 > d1 - data.mVal ? d1 : d0;
  }
  return null;
};

export default class TrendLineGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.prepareGraphData = memoize(prepareGraphData);
    this.prepareYDomain = memoize(prepareYDomain);
    this.filterValidXValues = memoize((data) => {
      return data.filter((d) => isDate(d));
    });
  }

  componentDidUpdate() {
    if (this.autoSizer) {
      this.autoSizer.measure();
    }
  }

  render() {
    const { xDomain, tickData, graphItem, minWidth, hoverContext, timeOffset } =
      this.props;
    if (
      graphItem.isLoading === true ||
      get(graphItem, "items", []).some((x) => x.isLoading === true)
    ) {
      return (
        <div className={styles.trendGraph}>
          <div>{graphItem.description}</div>
          <div className={styles.trendGraphContent}>
            <Loader expand />
          </div>
        </div>
      );
    }

    const preparedData = this.prepareGraphData(graphItem, timeOffset);
    const yDomain = this.prepareYDomain(preparedData);

    const lineBreakOptions = {
      continuous: false,
      minimumBreakMinutes: graphItem.interval,
    };
    return (
      <div className={styles.trendGraph}>
        <div>{graphItem.description}</div>
        <div className={styles.trendGraphContent}>
          <AutoSizer ref={(el) => (this.autoSizer = el)}>
            {({ width, height }) => {
              const viewWidth = Math.max(minWidth, width);
              return (
                <Sparkline
                  width={viewWidth}
                  height={height}
                  data={preparedData}
                  xDomain={xDomain}
                  yDomain={yDomain}
                  scaleX={tickData.scaleX.range([0, viewWidth])}
                  margin={{ bottom: 10 }}
                >
                  <SparklineGrid
                    xTicks={tickData.xTicks}
                    showY={true}
                    opacityY={0.2}
                  />

                  {graphItem.items.map((item, i) => (
                    <SparklineLine
                      key={item.id}
                      color={item.color}
                      dataSetIndex={i}
                      lineBreakOptions={lineBreakOptions}
                    />
                  ))}

                  <SparklineYAxis axisWidth={32} />

                  <SparklineCustomElement>
                    {({ scaleX, scaleY }) => {
                      const x1 = scaleX(xDomain[0]),
                        x2 = scaleX(xDomain[1]),
                        y = scaleY[0](0);
                      if (x1 && x2 && y) {
                        return (
                          <line
                            x1={x1}
                            x2={x2}
                            y1={y}
                            y2={y}
                            style={{
                              fill: "none",
                              stroke: color("--light-grey"),
                              strokeWidth: 1,
                            }}
                          />
                        );
                      }
                    }}
                  </SparklineCustomElement>

                  <SparklineHover
                    context={hoverContext}
                    customValueFunction={getCustomValueFunction}
                  >
                    <SparklineCustomElement>
                      {({ scaleX, scaleY, hoveredXValue }) => {
                        if (isNil(hoveredXValue)) {
                          return null;
                        }
                        const x = scaleX(hoveredXValue),
                          y1 = scaleY[0](yDomain[0]),
                          y2 = scaleY[0](yDomain[1]);
                        if (x && y1 && y2) {
                          return (
                            <g>
                              <line
                                style={{
                                  fill: "none",
                                  stroke: color("--blue-base"),
                                  strokeWidth: 1,
                                }}
                                x1={x}
                                y1={y1}
                                x2={x}
                                y2={y2}
                              />
                            </g>
                          );
                        }
                      }}
                    </SparklineCustomElement>

                    <SparklineTooltip
                      size="xl"
                      filter={(val) => {
                        return val && val.hideTooltip !== true;
                      }}
                      tooltips={graphItem.items.map((valueItem, i) => ({
                        dataSetIndex: i,
                        color: valueItem.color,
                        format: createTooltipFormatter(valueItem),
                      }))}
                    />
                  </SparklineHover>
                </Sparkline>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}
