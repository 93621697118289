import {
  takeLeading,
  takeLatest,
  call,
  getContext,
  put,
  fork,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import { get } from "lodash";
import { roundNumberInt } from "../../../common/numbers";

const actions = vesselConfig.energyFlow;
const api = vesselConfigApi.energyFlow;

export function* initEnergyFlow({ vesselId }) {
  yield call(loadData, vesselId);
  yield fork(saveWatcher, vesselId);
  yield fork(resetWatcher, vesselId);
}

function* loadData(vesselId) {
  yield put({ type: actions.INITIALIZE, vesselId });
  yield call(fetchVesselItems, vesselId);
  yield call(fetchLayout, vesselId);
  yield call(fetchRelations, vesselId);
}

function* saveWatcher(vesselId) {
  yield takeLeading(actions.SAVE, saveEnergyFlow, vesselId);
}

function* resetWatcher(vesselId) {
  yield takeLatest(actions.RESET, loadData, vesselId);
}

function* saveEnergyFlow(vesselId, { layout, relations }) {
  const http = yield getContext("http");

  const newLayout = layout.map((l) => ({
    vesselItemId: l.vesselItemId,
    x: roundNumberInt(l.x + get(l, "transformX", 0)),
    y: roundNumberInt(l.y + get(l, "transformY", 0)),
  }));

  try {
    yield call(http.post, api.postRelations(vesselId), relations);
    yield call(http.post, api.postLayout(vesselId), newLayout);

    yield put({ type: actions.SAVE_SUCCESS });

    yield call(fetchLayout, vesselId);
    yield call(fetchRelations, vesselId);
  } catch (err) {
    yield put({ type: actions.SAVE_ERROR, error: err });
  }
}

function* fetchVesselItems(vesselId) {
  const http = yield getContext("http");

  try {
    yield put({
      type: actions.FETCH_ITEMS,
    });
    const res = yield call(http.get, vesselConfigApi.vesselItems.get(vesselId));
    yield put({
      type: actions.FETCH_ITEMS_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ITEMS_ERROR,
      error,
    });
  }
}

function* fetchLayout(vesselId) {
  const http = yield getContext("http");

  try {
    yield put({
      type: actions.FETCH_LAYOUT,
    });
    const res = yield call(http.get, api.getLayout(vesselId));
    yield put({
      type: actions.FETCH_LAYOUT_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_LAYOUT_ERROR,
      error,
    });
  }
}

function* fetchRelations(vesselId) {
  const http = yield getContext("http");

  try {
    yield put({
      type: actions.FETCH_RELATIONS,
    });
    const res = yield call(http.get, api.getRelations(vesselId));
    yield put({
      type: actions.FETCH_RELATIONS_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_RELATIONS_ERROR,
      error,
    });
  }
}
