import PropTypes from "prop-types";
import React from "react";
import GoogleMapReact from "google-map-react";
import { MAP_CONFIG } from "../../config";
import styles from "./Map.css";
import { latLng } from "../../common/customPropTypes";
import HeatMapOverlay from "./HeatMapOverlay/HeatMapOverlay";
import Loader from "../../../common/components/Loader/Loader";

const createMapOptions = () => ({
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  scrollwheel: true,
  backgroundColor: "none",
  styles: [
    {
      featureType: "all",
      stylers: [{ visibility: "off" }, { color: "#2b313f" }],
    },
    {
      featureType: "landscape.natural",
      stylers: [{ visibility: "on" }, { color: MAP_CONFIG.landscapeColor }],
    },
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        { visibility: "on" },
        { color: MAP_CONFIG.countryBorderColor },
        { weight: 2 },
      ],
    },
    {
      featureType: "water",
      stylers: [{ visibility: "on" }, { color: MAP_CONFIG.defaultWaterColor }],
    },
  ],
});

export default class HeatMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: props.center,
      zoom: props.zoom,
      width: 0,
      height: 0,
      bounds: {},
    };

    this.onMapChange = this.onMapChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.vessels !== nextProps.vessels ||
      this.props.zoom !== nextProps.zoom ||
      this.state.zoom !== nextState.zoom ||
      this.state.bounds !== nextState.bounds ||
      this.props.center !== nextProps.center ||
      this.state.center !== nextState.center ||
      this.props.data !== nextProps.data ||
      this.state.width !== nextState.width ||
      this.state.height !== nextState.height ||
      this.props.isLoading !== nextProps.isLoading
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.width !== this.state.width ||
      prevState.height !== this.state.height
    ) {
      if (this.props.onChangeSize) {
        this.props.onChangeSize({
          width: this.state.width,
          height: this.state.height,
        });
      }
    }

    if (
      this.props.zoom !== prevProps.zoom ||
      this.props.center !== prevProps.center
    ) {
      this.setState({
        zoom: this.props.zoom,
        center: this.props.center,
      });
    }
  }

  onMapChange(args) {
    let { size } = args;
    this.setState({
      width: size.width,
      height: size.height,
      zoom: args.zoom,
      bounds: args.bounds,
      center: args.center,
    });
    if (this.props.onChange) this.props.onChange(args);
  }

  renderMap() {
    const { apiKey, defaultCenter, defaultZoom, data, isLoading } = this.props;
    const { width, height, bounds, zoom, center } = this.state;
    const showHeatMap = data && data.length > 0 && center;

    if (isLoading) {
      return <Loader text="Loading map data" className={styles.loader} />;
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        options={createMapOptions}
        onChange={this.onMapChange}
      >
        {showHeatMap && (
          <HeatMapOverlay
            data={data}
            width={width}
            height={height}
            centerLat={center.lat}
            centerLng={center.lng}
            zoom={zoom}
            bounds={bounds}
          />
        )}
      </GoogleMapReact>
    );
  }

  render() {
    const { label } = this.props;
    return (
      <div className={styles.outerContainer}>
        <div className={styles.container}>
          {this.renderMap()}
          <div className={styles.label}>{label}</div>
        </div>
      </div>
    );
  }
}

HeatMap.propTypes = {
  apiKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultCenter: latLng.isRequired,
  defaultZoom: PropTypes.number.isRequired,
  center: latLng,
  zoom: PropTypes.number,
  onChange: PropTypes.func,
  onChangeSize: PropTypes.func,
  isLoading: PropTypes.bool,
};

HeatMap.defaultProps = {
  isLoading: false,
};
