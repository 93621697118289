import { fork } from "redux-saga/effects";
import { vesselConfigSagas } from "./admin/vesselConfig.saga";
import { cargoConfigSaga } from "./admin/cargoConfig.saga";
import { hullPerformanceSagas } from "../sagas/hullPerformance.saga";
import { analysisSaga } from "../sagas/analysis.saga";
import { dataMaintenanceSaga } from "./admin/dataMaintenance.saga";
import { usersSaga } from "./admin/users.saga";

export function* rootSaga() {
  yield fork(vesselConfigSagas);
  yield fork(hullPerformanceSagas);
  yield fork(analysisSaga);
  yield fork(cargoConfigSaga);
  yield fork(dataMaintenanceSaga);
  yield fork(usersSaga);
}
