import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import Button from "../../../../../common/components/Button";
import {
  inputChanged,
  save,
} from "../../../../actions/admin/action.vesselconfig.onshoreFrontendConfig";
import { noop } from "lodash";
import Loader from "../../../../../common/components/Loader";
import { formatDateTime } from "../../../../common/dates";
import { prepState } from "../../../../reducers/admin/vesselConfig/reducer.onshoreFrontendConfig";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import styles from "./OnshoreFrontendConfigController.css";
import { FormCheckBox } from "../../../../components/Form";
import classNames from "../../../../../common/classNames";

class OnshoreFrontendConfigController extends React.PureComponent {
  renderContent() {
    const activeMenuItems = [
      "calendar",
      "analysis",
      "machineryReport",
      "hullPerformance",
    ];
    if (this.props.isLoading || this.props.error) {
      return (
        <div className={styles.contentContainer}>
          <Loader expand={true} error={this.props.error} />
        </div>
      );
    }
    return (
      <div className={styles.contentContainer}>
        <div className={styles.menuConfigContainer}>
          {this.props.fields.map((f) => (
            <div
              className={classNames(
                styles.menuConfigItem,
                !activeMenuItems.includes(f.name) &&
                  styles.menuConfigItemDisabled
              )}
              key={f.name}
            >
              <FormCheckBox
                value={f.value}
                label={f.label}
                name={f.name}
                disabled={activeMenuItems.includes(f.name) ? false : true}
                iconPosition="left"
                onChange={this.props.inputChanged}
              />
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            value="Save"
            clickAction={() => this.props.save(this.props.fields)}
            disabled={!this.props.canSave}
          />
          {Boolean(this.props.savedOn) && (
            <span>Last saved {formatDateTime(this.props.savedOn)}</span>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, enabled, onToggleClick } = this.props;

    return (
      <CollapsePanel
        title={"Onshore Frontend Config"}
        subTitle={"Toggle available menu items for onshore portal."}
        isOpen={isOpen}
        testId={"onshoreFrontendConfigController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
      >
        {this.renderContent()}
      </CollapsePanel>
    );
  }
}

OnshoreFrontendConfigController.defaultProps = {
  isOpen: false,
  enabled: false,
  onToggleClick: noop,
  fields: [],
};

OnshoreFrontendConfigController.propTypes = {
  isOpen: PropTypes.bool,
  enabled: PropTypes.bool,
  onToggleClick: PropTypes.func,
  isLoading: PropTypes.bool,
  canSave: PropTypes.bool,
  savedOn: PropTypes.string,
  error: PropTypes.any,
  fields: PropTypes.array,
  inputChanged: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = createSelector(
  (state) => state.admin.onshoreFrontendConfig,
  prepState
);

const mapDispatchToProps = {
  inputChanged,
  save,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnshoreFrontendConfigController);
