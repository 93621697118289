import React, { Component } from "react";
import { get, startCase, toLower } from "lodash";
import { connect } from "react-redux";
import { FormPopup } from "../../../../components/Form";
import DonutsEditForm from "./SettingsForms/DonutsEditForm";
import { ITEM_TYPE_METRIC_MAPPINGS } from "../PageConfigurationGrid/pageConfigurationGridData";
import { updateRowsConfig } from "../../../../actions/admin";
import GaugeEditForm from "./SettingsForms/GaugeEditForm";
import EquinorReportEditForm from "./SettingsForms/EquinorReportEditForm";
import EnergyBudgetOverviewEditForm from "./SettingsForms/EnergyBudgetOverviewEditForm";
import EnergyBudgetResultEditForm from "./SettingsForms/EnergyBudgetResultEditForm";
import MonthlyFuelUsageEditForm from "./SettingsForms/MonthlyFuelUsageEditForm";
import { convertToArray } from "../../../../common/pageConfig";

class SettingsPopup extends Component {
  constructor(props) {
    super(props);
    const params = this.getComponentParams();
    this.state = {
      defaultParams: { ...params },
      params,
    };

    this.editFormCheckBoxChanged = this.editFormCheckBoxChanged.bind(this);
    this.editFormSelectBoxChanged = this.editFormSelectBoxChanged.bind(this);
    this.addMissingRunInDonuts = this.addMissingRunInDonuts.bind(this);
    this.hasChanges = this.hasChanges.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentForm = (type) => {
    switch (type) {
      case "DONUTS":
        return (
          <DonutsEditForm
            params={this.state.params}
            onChange={this.editFormCheckBoxChanged}
            addRun={this.addMissingRunInDonuts}
          />
        );
      case "GAUGE":
        return (
          <GaugeEditForm
            params={this.state.params}
            onChange={this.editFormSelectBoxChanged}
          />
        );
      case "ENERGY_BUDGET_RESULT":
        return (
          <EnergyBudgetResultEditForm
            params={this.state.params}
            onChange={this.editFormCheckBoxChanged}
          />
        );
      case "ENERGY_BUDGET_OVERVIEW":
        return (
          <EnergyBudgetOverviewEditForm
            params={this.state.params}
            onChangeCheckBox={this.editFormCheckBoxChanged}
            onChangeSelectBox={this.editFormSelectBoxChanged}
          />
        );
      case "MONTHLY_FUEL_USAGE":
        return (
          <MonthlyFuelUsageEditForm
            params={this.state.params}
            onChange={this.editFormSelectBoxChanged}
          />
        );
      case "EQUINOR_VESSEL_REPORT":
        return (
          <EquinorReportEditForm
            params={this.state.params}
            onChange={this.editFormSelectBoxChanged}
          />
        );
      default:
        return null;
    }
  };

  onSave() {
    const { rowsConfig, component } = this.props;
    const updatedRowsConfig = rowsConfig.map((c) => {
      return {
        ...c,
        params: component.id === c.id ? this.state.params : c.params,
      };
    });
    this.props.updateRowsConfig(updatedRowsConfig);
  }

  hasChanges() {
    return (
      JSON.stringify(this.state.defaultParams) !==
      JSON.stringify(this.state.params)
    );
  }

  getComponentParams() {
    const params = get(this.props.component, "params", {});
    if (params.itemTypeMetricIds) {
      return {
        ...params,
        itemTypeMetricIds: params.itemTypeMetricIds.map((i) => {
          return ITEM_TYPE_METRIC_MAPPINGS[i.toLowerCase()] || i.toLowerCase();
        }),
      };
    }
    if (params.metricId) {
      const metricId = convertToArray(params.metricId).map((mi) =>
        mi.toLowerCase()
      );
      return {
        ...params,
        metricId,
      };
    }
    return params;
  }

  editFormSelectBoxChanged(name, selected) {
    this.setState((state) => ({
      ...state,
      params: {
        ...state.params,
        [name]: selected.id,
      },
    }));
  }

  editFormCheckBoxChanged(value) {
    const params = this.state.params;
    const { type, metricIds } = this.getMetricIds(params);
    if (!type) {
      return;
    }
    const index = metricIds.indexOf(value);
    const updatedMetricIds = metricIds.slice();
    if (index !== -1) {
      updatedMetricIds.splice(index, 1);
    } else {
      updatedMetricIds.push(value);
    }
    this.setState((state) => ({
      ...state,
      params: {
        ...state.params,
        [type]: updatedMetricIds,
      },
    }));
  }

  getMetricIds(params) {
    if (Object.hasOwnProperty.call(params, "itemTypeMetricIds")) {
      return {
        type: "itemTypeMetricIds",
        metricIds: params.itemTypeMetricIds,
      };
    }
    if (Object.hasOwnProperty.call(params, "metricIds")) {
      return {
        type: "metricIds",
        metricIds: params.metricIds,
      };
    }
    if (Object.hasOwnProperty.call(params, "metricId")) {
      return {
        type: "metricId",
        metricIds: params.metricId,
      };
    }
    return {
      type: "",
      metricIds: [],
    };
  }

  addMissingRunInDonuts(id) {
    const params = this.state.params;
    const itemTypeMetricIds = get(params, "itemTypeMetricIds", []).slice();
    itemTypeMetricIds.unshift(id);
    this.setState((state) => ({
      ...state,
      params: {
        ...state.params,
        itemTypeMetricIds,
      },
    }));
  }

  render() {
    const { onCancel, component } = this.props;
    return (
      <FormPopup
        title={`${startCase(toLower(component.type))} Settings`}
        visible
        onHide={onCancel}
        onCancel={onCancel}
        onSave={this.onSave}
        enableSave={this.hasChanges()}
      >
        {this.componentForm(component.type)}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => {
  const componentId =
    state.admin.pageLayoutConfiguration.settingsDialog.selectedId;
  const rowsConfig = state.admin.pageLayoutConfiguration.rowsConfig;
  const component = rowsConfig.find((c) => c.id === componentId);
  return {
    component,
    rowsConfig,
  };
};

const mapDispatchToProps = {
  updateRowsConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPopup);
