import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import memoize from "memoize-one";
import { color } from "../../colors";
import { SparklineContext } from "./Sparkline";

class SparklineHeatMap extends React.PureComponent {
  constructor(props) {
    super(props);

    this.memoizedScaleZ = memoize((data, dataSetIndex, colors) => {
      const minValue = d3.min(data[dataSetIndex], (d) => d.z);
      const maxValue = d3.max(data[dataSetIndex], (d) => d.z);
      return d3
        .scaleLinear()
        .interpolate(d3.interpolateHcl)
        .range(colors)
        .domain([minValue, maxValue]);
    });

    this.textStyle = {
      fontSize: color("--font-size-ms"),
      fontWeight: "500",
      fontFamily: color("--primary-font"),
    };
  }

  getScaleZ() {
    const { data, dataSetIndex = 0, colors } = this.props;
    return this.memoizedScaleZ(data, dataSetIndex, colors);
  }

  renderGrid() {
    const {
      data,
      scaleY,
      scaleX,
      dataSetIndex = 0,
      yDomainIndex = 0,
      showValue,
      textColor,
      noValueColor,
    } = this.props;
    const currentScaleY = scaleY[yDomainIndex];
    const rectHeight = scaleY[yDomainIndex].bandwidth();
    const rectWidth = scaleX.bandwidth();
    const xDomain = scaleX.domain();
    const yDomain = currentScaleY.domain();
    const valueSet = new Map();
    const scaleZ = this.getScaleZ();
    const domainZ = scaleZ.domain();
    for (let d of data[dataSetIndex]) {
      valueSet.set(`${d.x}#${d.y}`, d.z);
    }
    return (
      <g>
        {xDomain.map((dx, xi) =>
          yDomain.map((dy, yi) => {
            const key = `${dx}#${dy}`;
            let value = domainZ[0],
              hasValue = false;
            if (valueSet.has(key)) {
              value = valueSet.get(key);
              hasValue = true;
            }
            return (
              <g key={`heatRect_${xi}_${yi}`}>
                <rect
                  width={rectWidth}
                  height={rectHeight}
                  fill={hasValue ? scaleZ(value) : noValueColor}
                  x={scaleX(dx)}
                  y={currentScaleY(dy)}
                />
                {hasValue && showValue && (
                  <text
                    x={scaleX(dx) + rectWidth / 2}
                    y={currentScaleY(dy) + rectHeight / 2}
                    fill={textColor}
                    dominantBaseline={"middle"}
                    textAnchor={"middle"}
                    style={this.textStyle}
                  >
                    {value}
                  </text>
                )}
              </g>
            );
          })
        )}
      </g>
    );
  }

  render() {
    const { xScaleType, yScaleType } = this.props;
    if (xScaleType !== "band" || yScaleType !== "band")
      throw new Error("X and Y scale type must be band");

    return <g>{this.renderGrid()}</g>;
  }
}

SparklineHeatMap.defaultProps = {
  colors: [color("--blue-darkest"), color("--blue-brightest")],
  textColor: [color("--content-card-bg")],
  noValueColor: color("--dark-mid-grey"),
  showValue: false,
};

SparklineHeatMap.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  noValueColor: PropTypes.string,
  textColor: PropTypes.string,
  showValue: PropTypes.bool,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineHeatMap {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
