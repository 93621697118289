import React from "react";
import { Header } from "../../components/Header";
import styles from "./DocumentView.css";
import DataListContainer from "../../components/DataList/DataListContainer";
import DataListRow from "../../components/DataList/DataListRow";
import { FormSelectBox, FormTextBox } from "../../components/Form";
import Button from "../../../common/components/Button/Button";
import { connect } from "react-redux";
import {
  beginAddFile,
  beginAddFolder,
  beginEditFile,
  beginEditFolder,
  clearErrors,
  clearFilters,
  downloadFile,
  fetchFiles,
  loadDocumentFilters,
  setCompanyId,
  setFilterFileType,
  setFilterModified,
  setFilterVessel,
  setFilterVesselGroup,
  setSearchString,
  toggleFolderOpen,
} from "../../actions/action.documents";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import Label from "../../components/Label/Label";
import Loader from "../../../common/components/Loader/Loader";
import classNames from "../../../common/classNames";
import DocumentEditDialog from "./DocumentEditDialog/DocumentEditDialog";
import DataListCell from "../../components/DataList/DataListCell";
import FolderEditDialog from "./FolderEditDialog/FolderEditDialog";
import SelectBox from "../../components/SelectBox/SelectBox";
import { formatNumber } from "../../../common/numbers";
import { get } from "lodash";
import DocumentDeleteDialog from "./DocumentDeleteDialog/DocumentDeleteDialog";
import { formatDateTimeWithUtcTimeOffset } from "../../common/dates";
import ErrorDialog from "../../components/ErrorDialog/ErrorDialog";
import { Icon } from "../../../common/components/Icon/Icon";
import { getVesselGroupsInCompany } from "../../reducers/reducer.pageContext";

class DocumentsView extends React.Component {
  constructor(props) {
    super(props);

    this.renderFolderDetails = this.renderFolderDetails.bind(this);
    this.onSearchTextChanged = this.onSearchTextChanged.bind(this);
    this.onClearFiltersClick = this.onClearFiltersClick.bind(this);
    this.onFolderClick = this.onFolderClick.bind(this);
    this.onVesselGroupSelected = this.onVesselGroupSelected.bind(this);
    this.onVesselSelected = this.onVesselSelected.bind(this);
    this.onModifiedChanged = this.onModifiedChanged.bind(this);
    this.onFileTypeChanged = this.onFileTypeChanged.bind(this);
    this.onEditFolderClick = this.onEditFolderClick.bind(this);
    this.onAddFolderClick = this.onAddFolderClick.bind(this);
    this.onUploadFileClick = this.onUploadFileClick.bind(this);
    this.onCompanySelected = this.onCompanySelected.bind(this);
    this.onFileClick = this.onFileClick.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.onEditFileClick = this.onEditFileClick.bind(this);
  }

  componentDidMount() {
    this.props.loadDocumentFilters();
    this.props.fetchFiles();
  }

  onSearchTextChanged(_name, value) {
    this.props.setSearchString(value);
  }

  onClearFiltersClick() {
    this.props.clearFilters();
  }

  onFolderClick(folder) {
    this.props.toggleFolderOpen(folder.id);
  }

  onFileClick(fileId) {
    this.props.downloadFile(fileId);
  }

  onVesselGroupSelected(_name, vesselGroups) {
    this.props.setFilterVesselGroup(vesselGroups);
  }

  onVesselSelected(_name, vessel) {
    this.props.setFilterVessel(vessel);
  }

  onModifiedChanged(date) {
    this.props.setFilterModified(date);
  }

  onFileTypeChanged(_name, fileType) {
    this.props.setFilterFileType(fileType);
  }

  onEditFolderClick(event, folder) {
    this.props.beginEditFolder(folder);
    event.preventDefault();
    event.stopPropagation();
  }

  onAddFolderClick() {
    this.props.beginAddFolder();
  }

  onUploadFileClick() {
    this.props.beginAddFile();
  }

  onCompanySelected(company) {
    this.props.setCompanyId(company ? company.id : null);
  }

  clearErrors() {
    this.props.clearErrors();
  }

  onEditFileClick(event, file) {
    event.preventDefault();
    event.stopPropagation();
    this.props.beginEditFile(file);
  }

  renderFilterBar() {
    const {
      fileTypes,
      filteredVesselGroups,
      vesselsForSelectedGroup,
      dateLimits,
      filterGroup,
      filterVessel,
      filterModified,
      filterFileType,
      canSelectGroup,
    } = this.props.documents;
    return (
      <div className={styles.filterContainer}>
        <div className={styles.search}>
          <Icon
            icon={"search"}
            style={{ marginRight: "8px", color: "#8395ac" }}
          />
          <FormTextBox
            name={"search"}
            label={"Search"}
            width={"316px"}
            value={this.props.documents.searchString}
            onChange={this.onSearchTextChanged}
          />
        </div>
        <div className={styles.filters}>
          {canSelectGroup && (
            <div className={styles.filter}>
              <FormSelectBox
                options={filteredVesselGroups}
                optionValKey={"id"}
                optionLabelKey={"name"}
                onChange={this.onVesselGroupSelected}
                selected={filterGroup}
                name={"vesselGroupFilter"}
                label={"Vessel group"}
              />
            </div>
          )}
          <div className={styles.filter}>
            <FormSelectBox
              options={vesselsForSelectedGroup}
              optionValKey={"id"}
              optionLabelKey={"name"}
              onChange={this.onVesselSelected}
              selected={filterVessel}
              name={"vesselFilter"}
              label={"Vessel"}
              disabled={!filterGroup}
            />
          </div>
          <div className={styles.filter}>
            <div className={styles.filter}>
              <Label label={"Modified date"} style={{ marginLeft: "75px" }} />
              <DatePickerContainer
                placeHolder={"Select..."}
                allowNoCurrent={true}
                alwaysShowPrevNext={true}
                current={filterModified}
                limits={dateLimits}
                onChange={this.onModifiedChanged}
                headerContainerStyle={{ marginBottom: "16px" }}
              />
            </div>
          </div>
          <div className={styles.filter}>
            <FormSelectBox
              name={"fileTypeFilter"}
              label={"File Type"}
              options={fileTypes}
              selected={filterFileType}
              onChange={this.onFileTypeChanged}
              optionValKey={"fileType"}
              optionLabelKey={"fileType"}
            />
          </div>
        </div>
        <div className={styles.clearButton}>
          <Button
            type={"smallButton"}
            value={"Clear filters"}
            clickAction={this.onClearFiltersClick}
          />
        </div>
      </div>
    );
  }

  renderFolderDetails(folder, vesselsWithWriterRole, groupsWithRoleInCompany) {
    const { vessels } = this.props;
    const { filterModified } = this.props.documents;
    const timeOffset = filterModified ? filterModified.timeOffset : 0;
    return (
      <div className={styles.detailsContainer}>
        <table>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              <th>Vessel</th>
              <th>Modified</th>
              <th>Type</th>
              <th>Size</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {folder.files &&
              folder.files.map((file) => (
                <tr key={file.id} onClick={() => this.onFileClick(file.id)}>
                  <td className={styles.detailsIcon}>
                    <Icon icon={"document"} />
                  </td>
                  <td>{file.name}</td>
                  <td>{get(vessels, `${file.vesselId}.name`)}</td>
                  <td>
                    {formatDateTimeWithUtcTimeOffset(
                      new Date(file.modifiedOn),
                      timeOffset
                    )}
                  </td>
                  <td>{file.fileType}</td>
                  <td>{formatNumber(file.sizeInBytes / 1000)} kB</td>
                  <td>
                    {(vesselsWithWriterRole.some(
                      (x) => x.id === file.vesselId
                    ) ||
                      (!file.vesselId &&
                        !!groupsWithRoleInCompany &&
                        groupsWithRoleInCompany.length > 0)) && (
                      <Button
                        type={"link"}
                        value={"Edit"}
                        clickAction={(event) =>
                          this.onEditFileClick(event, file)
                        }
                      />
                    )}
                    {"   "}
                    <Button
                      type={"link"}
                      value={"Download"}
                      clickAction={() => this.onFileClick(file.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  renderFolderRows(vesselsWithWriterRole, groupsWithWriterRole) {
    const { documents } = this.props.documents;
    return documents.map((folder) => {
      const groupsWithRoleInCompany = getVesselGroupsInCompany({
        companyId: folder.companyId,
        groups: groupsWithWriterRole,
      });
      return (
        <DataListRow
          key={`folder_${folder.id}`}
          title={folder.name}
          size={"small"}
          description={folder.description}
          icon={folder.expanded ? "folder_open" : "folder"}
          showDetails={folder.expanded}
          renderDetails={(row) =>
            this.renderFolderDetails(
              row,
              vesselsWithWriterRole,
              groupsWithRoleInCompany
            )
          }
          tag={folder}
          clickable={true}
          onClick={this.onFolderClick}
        >
          <DataListCell style={{ justifyContent: "flex-end" }}>
            {!!groupsWithRoleInCompany && groupsWithRoleInCompany.length > 0 && (
              <div className={styles.addFileContainer}>
                <Button
                  type="smallButton"
                  value={"Edit"}
                  clickAction={(e) => this.onEditFolderClick(e, folder)}
                />
              </div>
            )}
          </DataListCell>
        </DataListRow>
      );
    });
  }

  renderFolders(vesselsWithWriterRole, groupsWithWriterRole) {
    const { documents, isLoadingDocuments } = this.props.documents;
    if (isLoadingDocuments) {
      return (
        <div className={styles.folderPlaceholder}>
          <Loader text={"Loading documents"} />
        </div>
      );
    } else if (documents && documents.length > 0) {
      return (
        <DataListContainer showTitle={false}>
          {this.renderFolderRows(vesselsWithWriterRole, groupsWithWriterRole)}
        </DataListContainer>
      );
    } else {
      return (
        <div
          className={classNames(styles.folderPlaceholder, styles.noDocuments)}
        >
          <Icon icon={"document"} size={"m"} />
          <span>No documents</span>
        </div>
      );
    }
  }

  render() {
    let { documents, pageContext } = this.props;
    let { companies, selectedCompanyId, error } = documents;

    const vessels = Object.values(pageContext.vessels);
    const groups = Object.values(pageContext.groups);

    const vesselGroupsInCompany = getVesselGroupsInCompany({
      companyId: selectedCompanyId,
      groups: groups,
    });

    return (
      <div className={styles.outerContainer}>
        <Header title={"Documents"} contentDistribution={"space-between"}>
          {companies && companies.length > 0 && (
            <SelectBox
              options={companies}
              selected={companies.find((x) => x.id === selectedCompanyId)}
              optionValKey={"id"}
              optionLabelKey={"name"}
              onSelect={this.onCompanySelected}
            />
          )}
          <div className={styles.headerButtonContainer}>
            <Button
              type="smallButton"
              value={"Upload file"}
              clickAction={this.onUploadFileClick}
              margin={"0 10px 0 0"}
              disabled={
                !vesselGroupsInCompany || vesselGroupsInCompany.length === 0
              }
            />
            <Button
              type="smallButton"
              value={"Add folder"}
              clickAction={this.onAddFolderClick}
              disabled={
                !vesselGroupsInCompany || vesselGroupsInCompany.length === 0
              }
            />
          </div>
        </Header>
        <div className={styles.contentContainer}>
          {this.renderFilterBar()}
          {this.renderFolders(vessels, groups)}
        </div>
        <DocumentEditDialog />
        <DocumentDeleteDialog />
        <FolderEditDialog />
        {!!error && (
          <ErrorDialog
            title={"An error occurred"}
            message={error}
            onOkClick={this.clearErrors}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vessels: state.pageContext.vessels,
  documents: state.documents,
  pageContext: state.pageContext,
});

const mapDispatchToProps = {
  loadDocumentFilters,
  setSearchString,
  clearFilters,
  toggleFolderOpen,
  setFilterVesselGroup,
  setFilterVessel,
  setFilterModified,
  setFilterFileType,
  beginEditFile,
  beginAddFile,
  beginEditFolder,
  beginAddFolder,
  setCompanyId,
  fetchFiles,
  downloadFile,
  clearErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsView);
