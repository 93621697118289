import {
  takeLatest,
  takeEvery,
  call,
  getContext,
  put,
  fork,
  select,
} from "redux-saga/effects";
import { dataMaintenanceApi } from "../../api";
import { admin } from "../../actions/action.types";
import {
  fetchVesselSuccess,
  fetchVesselError,
  fetchDataCountSuccess,
  fetchDataCountError,
  deleteDataSuccess,
  deleteDataError,
} from "../../actions/admin/action.dataMaintenance";

export function* dataMaintenanceSaga() {
  yield fork(initWatchers);
}

function* initWatchers() {
  yield takeEvery([admin.dataMaintenance.FETCH_VESSELS], fetchVesselsSaga);
  yield takeLatest(
    [admin.dataMaintenance.FETCH_DATA_COUNT],
    fetchDataCountSaga
  );
  yield takeEvery([admin.dataMaintenance.DELETE_DATA], deleteDataSaga);
}

function* fetchVesselsSaga() {
  try {
    const { vessels } = yield select((s) => s.pageContext);
    const data = Object.keys(vessels || {}).map((x) => ({
      id: vessels[x].id,
      name: vessels[x].name,
    }));
    yield put(fetchVesselSuccess(data));
  } catch (err) {
    yield put(fetchVesselError(err));
  }
}

function* fetchDataCountSaga(args) {
  const { dateRange, vesselId } = args.payload;
  try {
    const data = yield call(getDataCount, vesselId, dateRange);
    yield put(fetchDataCountSuccess(data));
  } catch (err) {
    yield put(fetchDataCountError(err));
  }
}

function* getDataCount(vesselId, dateRange) {
  const http = yield getContext("http");
  const res = yield call(
    http.get,
    dataMaintenanceApi.getDataCount(vesselId, ...dateRange)
  );

  return res.data;
}

function* deleteDataSaga(args) {
  try {
    const { vesselId, dateRange } = args.payload;
    const http = yield getContext("http");
    yield call(
      http.delete,
      dataMaintenanceApi.deleteData(vesselId, ...dateRange)
    );
    yield put(deleteDataSuccess());
  } catch (err) {
    yield put(deleteDataError(err));
  }
}
