import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styling/styles.css";
import backgroundImageSrc from "./styling/background.jpg";
import VpLogo from "./VpLogo";
import LoginHeader from "./LoginHeader";
import IAuthService from "./IAuthService";
import IApiClient from "../services/IApiClient";
import { PrivacyContainer } from "./PrivacyContainer";

type SubmitHandler = (event: React.FormEvent<HTMLFormElement>) => void;
type EmailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;

function LoginForm({
  email,
  handleEmailChange,
  handleSubmit,
}: {
  email: string;
  handleEmailChange: EmailChangeHandler;
  handleSubmit: SubmitHandler;
}) {
  return (
    <form
      onSubmit={handleSubmit}
      data-testid="login-form"
      className={styles.loginForm}
    >
      <div className={styles.formGroup}>
        <input
          id="email-input"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          required
          data-testid="login-form-email"
        />
      </div>
      <div className={styles.formGroup}>
        <button
          type="submit"
          className={styles.loginBtn}
          data-testid="login-form-submit"
        >
          Log in
        </button>
      </div>
    </form>
  );
}

interface Props {
  authService: IAuthService;
  apiClient: IApiClient;
}

export const PreliminaryLoginView = ({ authService, apiClient }: Props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault(); // Prevents the default form submission behavior
    handleLogin(email);
  }

  function handleLogin(email: string) {
    apiClient.getTenantId(email).then(
      (response) => {
        authService.reinitialize(response);
        void authService.login(email);
      },
      () => {
        navigate("/unauthorized");
      }
    );
  }

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${backgroundImageSrc})` }}
        className={styles.mainContainer}
      >
        <div className={styles.mainContainerOverlay}>
          <LoginHeader />
          <div className={styles.innerContainer}>
            <div />
            <VpLogo />
            <LoginForm
              email={email}
              handleEmailChange={handleEmailChange}
              handleSubmit={handleSubmit}
            />
            <PrivacyContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
