import React, { useEffect } from "react";
import { FleetActivityProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import DonutContainer from "../../components/DonutContainer";
import { fetchFleetActivity } from "../../actions/action.fetchFleetActivity";
import commonStyles from "../MainDashboard/MainDashboard.css";
import styles from "./FleetActivity.css";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";

const FleetActivity: React.FC<FleetActivityProps> = ({
  interval,
  group,
}: FleetActivityProps) => {
  const dispatch = useDispatch();
  const donuts = useSelector((state: any) => [state.fleetActivity]);
  const toolTipHeader = "Fleet activity";
  const toolTip =
    "Shows the distribution of time spent in each operation mode in the given interval, as well as the number of active vessels.";

  useEffect(() => {
    dispatch(fetchFleetActivity(interval, group));
  }, [interval, group]);

  return (
    <ComponentInfo
      modalTargetId="popupContainer"
      title={toolTipHeader}
      text={toolTip}
    >
      <div className={styles.fleetActivity}>
        <div className={commonStyles.componentHeader}>Fleet activity</div>
        <DonutContainer donuts={donuts} testId="widget-vesselCount" />
      </div>
    </ComponentInfo>
  );
};

export default FleetActivity;
