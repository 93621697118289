import React, { Component } from "react";
import { get } from "lodash";
import { FormCheckBox } from "../../../../../components/Form";
import styles from "./SettingsEditForm.css";
import { ITEM_TYPE_METRICS } from "../../../../../config";
import { connect } from "react-redux";
import { getMetrics } from "../../../../../common/pageConfig";

const runId = "5258c5b3-d3c5-4cf9-a107-fa9a1338353d";

class DonutsEditForm extends Component {
  render() {
    const { params, onChange, itemTypeMetrics, addRun } = this.props;
    const itemTypeMetricIds = get(params, "itemTypeMetricIds", []);
    if (!itemTypeMetricIds.includes(runId)) {
      addRun(runId);
    }
    const availableItemTypeMetrics = ITEM_TYPE_METRICS.filter((i) =>
      Object.hasOwnProperty.call(itemTypeMetrics, i.name)
    );
    const metrics = getMetrics(itemTypeMetricIds, ITEM_TYPE_METRICS);
    return (
      <div>
        <h2 className={styles.title}>Item Type Metrics</h2>
        <div className={styles.selectedMetrics}>
          {`[${metrics.join(", ")}]`}
        </div>
        <div className={styles.field}>
          {availableItemTypeMetrics.map((i) => (
            <FormCheckBox
              key={i.name}
              value={itemTypeMetricIds.includes(i.name)}
              label={i.label}
              name={i.name}
              disabled={i.label.toLowerCase() === "run"}
              iconPosition="left"
              onChange={onChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const pageContext = state.pageContext;
  const itemTypeMetrics = get(pageContext, "itemTypeMetrics");
  return {
    itemTypeMetrics,
  };
};

export default connect(mapStateToProps)(DonutsEditForm);
