import React from "react";
import PropTypes from "prop-types";
import InlinePanel from "../InlinePanel/InlinePanel";
import {
  FormDatePicker,
  FormGroup,
  FormTextBox,
  FormSelectBox,
} from "../../../../components/Form/index";
import { get, matchesProperty, compact } from "lodash";
import styles from "./VesselItemForm.css";
import MetadataConfig from "../../../../components/MetadataConfig/MetadataConfig";
import VesselItemMetrics from "./VesselItemMetrics";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";
import { addCustomValidationProperties } from "../../../../common/forms";
import {
  FUEL_TYPE_NAMES,
  ITEM_TYPES,
  VESSEL_ITEM_CATEGORY,
} from "../../../../config";

class VesselItemForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onInputChanged = this.onInputChanged.bind(this);
    this.onSelectChanged = this.onSelectChanged.bind(this);
    this.onFuelTypesChanged = this.onFuelTypesChanged.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onMetricChanged = this.onMetricChanged.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  getAssetNamePath(itemTypeId, category) {
    if (itemTypeId === ITEM_TYPES.ENGINE)
      return category === VESSEL_ITEM_CATEGORY.ENGINE_AUX
        ? "/Aux_Engines"
        : "/Engines/Main";
    if (itemTypeId === ITEM_TYPES.SHORE_CONNECTION)
      return "/Power_Management/Shore_Connection";
    if (itemTypeId === ITEM_TYPES.CONSUMER)
      return "/Power_Management/Electrical_Consumer";
    if (itemTypeId === ITEM_TYPES.BATTERY) return "/Power_Management/Battery";
    if (itemTypeId === ITEM_TYPES.BOILER) return "/Aux_Boilers";
    if (itemTypeId === ITEM_TYPES.THRUSTER) return "/Propulsor/Thruster";
    if (itemTypeId === ITEM_TYPES.PROPULSOR) return "/Propulsor/Main";
  }

  getButtons(form, metrics) {
    return compact([
      this.props.rowId && {
        label: "Delete",
        action: this.onDelete,
        disabled: form.isLoading,
      },
      {
        label: "Cancel",
        action: this.onCancel,
        disabled: form.isLoading,
      },
      {
        label: "Save",
        action: this.onSave,
        disabled:
          (!form.hasChanges && !metrics.hasChanges) ||
          form.isValid === false ||
          form.isLoading,
      },
    ]);
  }

  onCancel() {
    this.props.cancelAction(this.props.rowId);
  }
  onSave() {
    this.props.saveAction({
      id: this.props.rowId,
      form: this.props.form,
      metrics: this.props.metrics,
    });
  }
  onDelete() {
    this.props.deleteAction(this.props.rowId);
  }
  onCancelDelete() {
    this.props.cancelDeleteAction(this.props.rowId);
  }
  onConfirmDelete() {
    this.props.confirmDeleteAction(this.props.rowId);
  }

  onInputChanged(name, value, valid, message) {
    this.props.inputChanged(this.props.rowId, name, value, valid, message);
  }
  onMetricChanged(name, value) {
    const metricId = name.split("_")[0];
    const setting = name.split("_")[1];
    let field = "";
    switch (setting) {
      case "T":
        field = "showTrend";
        break;
      case "O":
        field = "showOperation";
        break;
      case "D":
        field = "showOperationDetail";
        break;
      case "C":
        field = "showCompare";
        break;
    }
    this.props.metricChanged(this.props.rowId, metricId, field, value);
  }

  onSelectChanged(name, selected, valid, message) {
    this.props.inputChanged(
      this.props.rowId,
      name,
      selected.id,
      valid,
      message
    );
  }

  onFuelTypesChanged(_name, selected, valid, message) {
    this.props.fuelTypesChanged(
      this.props.rowId,
      selected.map((x) => x.id),
      valid,
      message
    );
  }

  renderMetadataFields(fields, metadataFields) {
    return (
      metadataFields.length > 0 && (
        <div className={styles.column}>
          <h3>Onboard processor config</h3>
          <MetadataConfig
            fields={fields}
            metadataFields={metadataFields}
            onChange={this.onInputChanged}
          />
        </div>
      )
    );
  }

  renderVesselItemMetrics(metrics) {
    return (
      metrics.data.length > 0 && (
        <div className={styles.column}>
          <h3>Metrics</h3>
          <VesselItemMetrics
            metrics={metrics.data.filter(
              (metric) =>
                metric.isVisible ||
                metric.showCompare ||
                metric.showOperation ||
                metric.showOperationDetail ||
                metric.showTrend
            )}
            onChange={this.onMetricChanged}
          />
        </div>
      )
    );
  }

  render() {
    const { form, itemTypes, metrics, parentVesselItems, fuelTypes } =
      this.props;
    const { fields, metadataFields } = form;

    const isNew = !fields.id.value;
    const isNewAndItemTypeIdNotSelected = isNew && !fields.itemTypeId.value;

    const parentVesselItemOptions = [{ id: null, name: "-" }].concat(
      parentVesselItems
    );

    const fuelTypeOptions = fuelTypes.map((x) => ({
      id: x,
      name: FUEL_TYPE_NAMES[x],
    }));

    const categoryOptions = [
      {
        name: "Main",
        id: "engineMain",
      },
      {
        name: "Aux",
        id: "engineAux",
      },
    ];

    const selectedFuelType = fuelTypeOptions.filter(
      (x) => fields.fuelTypes.value?.includes(x.id) ?? false
    );

    const selectedItemType = itemTypes.find(
      matchesProperty("id", get(form, "fields.itemTypeId.value"))
    );

    const selectedCategory = categoryOptions.find(
      (categoryOption) => categoryOption.id === fields.category?.value
    );

    const selectedParentVesselItem = parentVesselItems.find(
      matchesProperty("id", get(fields, "parentVesselItemId.value"))
    );

    const buttons = this.getButtons(form, metrics);
    return (
      <InlinePanel
        title={this.props.title}
        buttons={buttons}
        isLoading={form.isLoading}
        error={form.error}
      >
        <FormGroup>
          <div className={styles.container}>
            <div className={styles.column}>
              <h3>Vessel item</h3>
              <FormTextBox
                name="name"
                autofocus
                label="Name"
                value={get(fields, "name.value")}
                onChange={this.onInputChanged}
                {...addCustomValidationProperties(get(fields, "name"))}
              />
              <FormTextBox
                name={"description"}
                label={"Description"}
                value={get(fields, "description.value")}
                onChange={this.onInputChanged}
                {...addCustomValidationProperties(get(fields, "description"))}
              />
              <FormSelectBox
                name="itemTypeId"
                label="Type"
                selected={selectedItemType}
                options={itemTypes}
                optionValKey="id"
                optionLabelKey="name"
                onChange={this.onSelectChanged}
                testId={"itemTypeId"}
                disabled={!isNewAndItemTypeIdNotSelected}
                {...addCustomValidationProperties(get(fields, "itemTypeId"))}
              />
              {fields.itemTypeId.value === ITEM_TYPES.ENGINE && (
                <FormSelectBox
                  name="category"
                  label="Category"
                  optionValKey="id"
                  optionLabelKey="name"
                  onChange={this.onSelectChanged}
                  options={categoryOptions}
                  selected={selectedCategory}
                  {...addCustomValidationProperties(get(fields, "category"))}
                />
              )}
              {[ITEM_TYPES.ENGINE, ITEM_TYPES.BOILER].includes(
                fields.itemTypeId.value
              ) && (
                <FormSelectBox
                  name="fuelTypes"
                  label="Fuel Types"
                  optionValKey="id"
                  optionLabelKey="name"
                  onChange={this.onFuelTypesChanged}
                  options={fuelTypeOptions}
                  selected={selectedFuelType}
                  multi
                />
              )}
              <FormSelectBox
                name="parentVesselItemId"
                label="Parent Vessel Item"
                selected={selectedParentVesselItem}
                options={parentVesselItemOptions}
                optionValKey="id"
                optionLabelKey="name"
                onChange={this.onSelectChanged}
              />
              <FormTextBox
                name="remoteItemName"
                label="Remote Item Name (max length 20)"
                maxLength={20}
                value={get(fields, "remoteItemName.value")}
                onChange={this.onInputChanged}
                {...addCustomValidationProperties(
                  get(fields, "remoteItemName")
                )}
                disabled={!isNew}
              />
              {Boolean(fields.assetNameIndex) && (
                <FormTextBox
                  type="positiveInteger"
                  name="assetNameIndex"
                  label={`Asset Name Index (${this.getAssetNamePath(
                    fields.itemTypeId.value,
                    fields?.category?.value
                  )}/${fields.assetNameIndex.value})`}
                  value={fields.assetNameIndex.value}
                  onChange={this.onInputChanged}
                  labelPosition={"top"}
                  {...addCustomValidationProperties(fields.assetNameIndex)}
                  disabled={!isNew}
                />
              )}
              <FormDatePicker
                label={"Ehm Start Time"}
                name={"ehmStartTime"}
                showTimeSelect={false}
                value={get(fields, "ehmStartTime.value")}
                onChange={this.onInputChanged}
                labelPosition={"top"}
                testId={"ehmStartTime"}
              />

              <FormTextBox
                type="positiveInteger"
                name="viewPosition"
                label="View Position"
                value={get(fields, "viewPosition.value")}
                onChange={this.onInputChanged}
                {...addCustomValidationProperties(get(fields, "viewPosition"))}
              />
              <FormTextBox
                type="positiveNumber"
                name="initialRunningHours"
                label="Initial Running Hours"
                value={get(fields, "initialRunningHours.value")}
                onChange={this.onInputChanged}
                {...addCustomValidationProperties(
                  get(fields, "initialRunningHours")
                )}
              />
            </div>
            {this.renderMetadataFields(fields, metadataFields)}
            {this.renderVesselItemMetrics(metrics)}
            {this.props.form.isDeleting && (
              <ConfirmDeleteDialog
                onCancelClick={this.onCancelDelete}
                onConfirmClick={this.onConfirmDelete}
                title={"Delete item"}
                message="Are you sure you want to delete this Item? This action can not be undone."
              />
            )}
          </div>
        </FormGroup>
      </InlinePanel>
    );
  }
}

VesselItemForm.propTypes = {
  itemTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  fuelTypes: PropTypes.arrayOf(PropTypes.number),
  parentVesselItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  form: PropTypes.shape({
    fields: PropTypes.shape({
      id: PropTypes.object,
      itemTypeId: PropTypes.object,
      itemId: PropTypes.object,
      remoteItemName: PropTypes.object,
      name: PropTypes.object,
      description: PropTypes.object,
      viewPosition: PropTypes.object,
      initialRunningHours: PropTypes.object,
      maximumContinuousRating: PropTypes.object,
      jsonMetadata: PropTypes.object,
      ehmStartTime: PropTypes.object,
      fuelTypes: PropTypes.object,
    }),
    isLoading: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool,
    isDeleting: PropTypes.bool,
    error: PropTypes.string,
  }),
  metrics: PropTypes.shape({
    isLoading: PropTypes.bool,
    hasChanges: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        metricId: PropTypes.string.isRequired,
        metric: PropTypes.string.isRequired,
        showOperation: PropTypes.bool.isRequired,
        showOperationDetail: PropTypes.bool.isRequired,
        showTrend: PropTypes.bool.isRequired,
        showCompare: PropTypes.bool.isRequired,
      })
    ),
  }),
  rowId: PropTypes.string,
  inputChanged: PropTypes.func.isRequired,
  fuelTypesChanged: PropTypes.func.isRequired,
  metricChanged: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  cancelDeleteAction: PropTypes.func.isRequired,
  confirmDeleteAction: PropTypes.func.isRequired,
};

export default VesselItemForm;
