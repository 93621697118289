import {
  FETCH_ADMIN_PAGE_CONFIGS,
  FETCH_ADMIN_PAGE_CONFIGS_SUCCESS,
  FETCH_ADMIN_PAGE_CONFIGS_ERROR,
  FETCH_ADMIN_PAGE_CONFIG_SUCCESS,
  FETCH_ADMIN_PAGE_CONFIG_ERROR,
  CREATE_ADMIN_PAGE_CONFIG,
  CLOSE_ADMIN_PAGE_CONFIG_POPUP,
  SAVE_ADMIN_PAGE_CONFIG,
  SAVE_ADMIN_PAGE_CONFIG_SUCCESS,
  SAVE_ADMIN_PAGE_CONFIG_ERROR,
  SET_ADMIN_PAGE_CONFIG_VALUE,
  FETCH_ADMIN_VESSELS_SUCCESS,
  FETCH_ADMIN_COMPANIES_SUCCESS,
  OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP,
  DELETE_ADMIN_PAGE_CONFIG,
  DELETE_ADMIN_PAGE_CONFIG_ERROR,
  DELETE_ADMIN_PAGE_CONFIG_SUCCESS,
  SET_ADMIN_PAGE_JSON_CONFIG,
  CLEAR_ADMIN_PAGE_JSON_CONFIG,
  FETCH_ADMIN_VESSEL_GROUPS_SUCCESS,
  CLEAR_ADMIN_PAGE_CONFIG_ROWS,
  CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID,
  CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID,
} from "../../actions/action.types";
import { checkFormValidation, convertObjToFields } from "../../common/forms";
import createReducer from "../createReducer";

const initialState = {
  data: [],
  isLoading: false,
  showPopup: false,
  showDeletePopup: false,
  companies: [],
  vessels: [],
  vesselGroups: [],
  form: {
    fields: {},
    isSaving: false,
    isValid: false,
    hasChanges: false,
    isUpdate: false,
  },
  deleteForm: {
    fields: {},
    isSaving: false,
    isValid: true,
    hasChanges: true,
    isUpdate: true,
    failed: false,
  },
};

export default createReducer(initialState, {
  [FETCH_ADMIN_VESSELS_SUCCESS]: (state, { data }) => {
    let vessels = [];

    if (data) {
      vessels = data.map((x) => ({
        id: x.id,
        name: x.name,
        companyId: x.companyId,
      }));
    }

    return {
      ...state,
      vessels,
    };
  },
  [FETCH_ADMIN_COMPANIES_SUCCESS]: (state, { data }) => {
    let companies = [];

    if (data) {
      companies = data.map((x) => ({ id: x.id, name: x.name }));
    }

    return {
      ...state,
      companies,
    };
  },
  [FETCH_ADMIN_VESSEL_GROUPS_SUCCESS]: (state, { data }) => {
    let vesselGroups = [];

    if (data) {
      vesselGroups = data.map((x) => ({
        id: x.id,
        name: x.name,
        companyId: x.companyId,
      }));
    }

    return {
      ...state,
      vesselGroups,
    };
  },
  [FETCH_ADMIN_PAGE_CONFIGS]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_ADMIN_PAGE_CONFIGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      data,
    };
  },
  [FETCH_ADMIN_PAGE_CONFIGS_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [FETCH_ADMIN_PAGE_CONFIG_SUCCESS]: (state, { data }) => {
    let fields = convertObjToFields(data);
    fields.rows.value = JSON.stringify(fields.rows.value, null, 1);
    return {
      ...state,
      showPopup: true,
      form: {
        ...state.form,
        fields: fields,
        isValid: true,
        hasChanges: false,
        isUpdate: true,
        isSaving: false,
      },
    };
  },
  [FETCH_ADMIN_PAGE_CONFIG_ERROR]: (state, { error }) => {
    return {
      ...state,
      hasError: true,
      form: {
        ...state.form,
        fields: {},
      },
      error,
    };
  },
  [CREATE_ADMIN_PAGE_CONFIG]: (state) => {
    return {
      ...state,
      showPopup: true,
      form: {
        fields: {
          name: { isValid: false },
          pageName: { isValid: true, value: "vessel" },
          rows: { isValid: false },
        },
        isSaving: false,
        isValid: false,
        hasChanges: false,
        isUpdate: false,
      },
    };
  },
  [CLOSE_ADMIN_PAGE_CONFIG_POPUP]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        fields: {},
      },
    };
  },
  [SAVE_ADMIN_PAGE_CONFIG]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        isSaving: true,
      },
    };
  },
  [SAVE_ADMIN_PAGE_CONFIG_SUCCESS]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
    };
  },
  [SAVE_ADMIN_PAGE_CONFIG_ERROR]: (state, { error }) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
      hasError: true,
      error,
    };
  },
  [SET_ADMIN_PAGE_CONFIG_VALUE]: (state, { name, value, valid, message }) => {
    const field = state.form[name];
    return {
      ...state,
      form: checkFormValidation({
        ...state.form,
        fields: {
          ...state.form.fields,
          [name]: {
            ...field,
            value: value,
            isValid: valid !== undefined ? valid : true,
            message: message || "",
            hasChanges: true,
          },
        },
      }),
    };
  },
  [OPEN_ADMIN_PAGE_CONFIG_DELETE_POPUP]: (state, { pageConfig }) => {
    return {
      ...state,
      showDeletePopup: true,
      deleteForm: {
        ...state.deleteForm,
        failed: false,
        fields: pageConfig,
      },
    };
  },
  [CLOSE_ADMIN_PAGE_CONFIG_DELETE_POPUP]: (state) => {
    return {
      ...state,
      showDeletePopup: false,
      deleteForm: {
        ...state.deleteForm,
        failed: false,
        fields: {},
      },
    };
  },
  [DELETE_ADMIN_PAGE_CONFIG]: (state) => {
    return {
      ...state,
      deleteForm: {
        ...state.deleteForm,
        isSaving: true,
      },
    };
  },
  [DELETE_ADMIN_PAGE_CONFIG_SUCCESS]: (state) => {
    return {
      ...state,
      showDeletePopup: false,
      deleteForm: {
        ...state.deleteForm,
        isSaving: false,
      },
    };
  },
  [DELETE_ADMIN_PAGE_CONFIG_ERROR]: (state) => {
    return {
      ...state,
      showDeletePopup: true,
      deleteForm: {
        ...state.deleteForm,
        failed: true,
        isSaving: false,
      },
    };
  },
  [SET_ADMIN_PAGE_JSON_CONFIG]: (state, { jsonConfig }) => {
    return {
      ...state,
      form: {
        ...state.form,
        fields: {
          ...state.form.fields,
          jsonConfig,
        },
      },
    };
  },
  [CLEAR_ADMIN_PAGE_JSON_CONFIG]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        fields: {
          ...state.form.fields,
          jsonConfig: {
            value: [],
            id: "",
            lastCopiedId: "",
          },
        },
      },
    };
  },
  [CLEAR_ADMIN_PAGE_CONFIG_ROWS]: (state) => {
    return {
      ...state,
      form: checkFormValidation({
        ...state.form,
        fields: {
          ...state.form.fields,
          rows: {
            value: "",
            isValid: false,
            hasChanges: true,
          },
        },
      }),
    };
  },
  [CLEAR_ADMIN_PAGE_CONFIG_VESSEL_GROUP_ID]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        fields: {
          ...state.form.fields,
          vesselGroupId: {
            value: "",
          },
        },
      },
    };
  },
  [CLEAR_ADMIN_PAGE_CONFIG_VESSEL_ID]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        fields: {
          ...state.form.fields,
          vesselId: {
            value: "",
          },
        },
      },
    };
  },
});
