import React from "react";
import PropTypes from "prop-types";
import DataDetails from "./DataDetails";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import styles from "./DataDetailsGroup.css";

class DataDetailsGroup extends React.PureComponent {
  render() {
    const { data, title, children, ...props } = this.props;
    return (
      <DataDetailsComponentContainer style={{ minWidth: "100%", padding: "0" }}>
        {title && <div className={styles.groupHeader}>{title}</div>}
        <div className={styles.groupContent} data-test-id="vessel-dataDetails">
          {children ? children : <DataDetails dataDetails={data} {...props} />}
        </div>
      </DataDetailsComponentContainer>
    );
  }
}

DataDetailsGroup.propTypes = {
  data: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default DataDetailsGroup;
