import moment from "moment";
import { formatDurationSec } from "../../common/durations";
import { formatDateTime, getShortMonthName, secondsToDate } from "./dates";

const _20_minutes = 1200;
const _30_minutes = 1800;
const _1_hour = 3600;
const _2_hour = 7200;
const _12_hours = 43200;
const _6_hours = 21600;
const _24_hours = 86400;
const _5_days = 432000;
const _4_days = 345600;

export const formatLastPing = (lastPing) => {
  return lastPing !== undefined
    ? `${moment.duration(lastPing, "seconds").humanize()} ago`
    : undefined;
};

export const formatLastPingDetailed = (lastPing) => {
  return lastPing && formatDurationSec(lastPing);
};

export const formatSingleDayDataStatus = (singleDayData) => {
  if (!singleDayData && singleDayData !== 0) {
    return "No data found";
  }
  return singleDayData;
};

export const formatMissingSeconds = (missingSeconds) => {
  if (missingSeconds === undefined) {
    return "No data found";
  }
  return missingSeconds;
};

export const formatSingleDayMissingSeconds = (singleDayData) => {
  if (!singleDayData && singleDayData !== 0) {
    return "No data found";
  }
  return `${singleDayData - _24_hours}`;
};

export const formatLastFiveDaysMissingSeconds = (lastFiveDaysData) => {
  if (!lastFiveDaysData && lastFiveDaysData !== 0) {
    return "No data found";
  }
  return `${lastFiveDaysData - _5_days}`;
};

export const formatLastDataToShore = (lastDataToShore) => {
  if (!lastDataToShore && lastDataToShore !== 0) {
    return "> a month ago";
  }
  return `${moment.duration(lastDataToShore, "seconds").humanize()} ago`;
};

export const formatLastDataToShoreDetailed = (lastDataToShore) => {
  if (!lastDataToShore && lastDataToShore !== 0) {
    return "More than a month ago";
  }
  return formatDateTime(secondsToDate(-lastDataToShore));
};

/**
 * @param lastPing    duration since last ping, in seconds.
 * @returns   {{isDisabled: boolean, isError: boolean, isWarning: boolean}}
 *            isDisabled: true when startTime of vessel is later than current time
 *            isError: true when last ping is more than 12 hours ago
 *            isWarning: true when last ping is between 1 and 12 hours ago
 */
export const getLastPingStatus = ({ startTime, lastPing }) => ({
  isDisabled: moment(startTime).isAfter(),
  isError: lastPing > _12_hours,
  isWarning: lastPing > _1_hour && lastPing <= _12_hours,
});

/**
 * Format dataToShore
 * @param dataToShore
 * @returns {{isError: boolean, isWarning: boolean}}
 *            isError: true when dataToShore is greater than 24 hours
 *            isWarning: true when dataToShore is greater than 6 hours
 */
export const getLastDataToShoreStatus = ({ dataToShore }) => ({
  value: dataToShore,
  isWarning: dataToShore > _6_hours && dataToShore <= _24_hours,
  isError: dataToShore > _24_hours || dataToShore === undefined,
});

export const getTodayDataStatus = (todayData) => ({
  value: todayData,
  isWarning:
    todayData < getSecondsSinceMidnightUTC() - _1_hour &&
    todayData > getSecondsSinceMidnightUTC() - _2_hour,
  isError:
    todayData < getSecondsSinceMidnightUTC() - _2_hour ||
    todayData === undefined,
});

export function getSecondsSinceMidnightUTC() {
  let d = new Date();
  let e = new Date(d);
  const hours = -(d.getTimezoneOffset() / 60);
  return (d - e.setHours(hours, 0, 0, 0)) / 1000;
}

export const getSingleDayDataStatus = (singleDayData) => ({
  value: singleDayData,
  isWarning:
    singleDayData < _24_hours && singleDayData >= _24_hours - _20_minutes,
  isError:
    singleDayData < _24_hours - _20_minutes || singleDayData === undefined,
});

export const getFiveDaysDataStatus = (fiveDayData) => ({
  value: fiveDayData,
  isWarning: fiveDayData < _5_days && fiveDayData >= _4_days,
  isError: fiveDayData < _4_days || fiveDayData === undefined,
});

export const getMissingSecondsStatus = (missingSeconds) => ({
  value: missingSeconds,
  isWarning: missingSeconds < 0 && missingSeconds >= -_30_minutes,
  isError: missingSeconds < -_30_minutes || missingSeconds === undefined,
});

export const formatDate = (dataPage, daysToAdd = 0) => {
  const today = new Date();
  const daysInPast = reverseDataStatusPage(dataPage) * 5;
  const days = daysToAdd - daysInPast;
  const date = new Date(today.setDate(today.getDate() + days));
  return `${date.getDate()} ${getShortMonthName(date.getMonth() + 1)}`;
};

export const reverseDataStatusPage = (page) => {
  return Math.abs(page - 4);
};
