import { ADMIN_VESSEL_GROUPS } from "../../api";
import fetch from "../action.fetch";
import { convertFieldsToKeyValues } from "../../common/forms";
import {
  FETCH_ADMIN_VESSEL_GROUPS,
  FETCH_ADMIN_VESSEL_GROUP,
  CREATE_ADMIN_VESSEL_GROUP,
  CLOSE_ADMIN_VESSEL_GROUP_POPUP,
  SAVE_ADMIN_VESSEL_GROUP,
  SAVE_ADMIN_VESSEL_GROUP_ERROR,
  SAVE_ADMIN_VESSEL_GROUP_SUCCESS,
  SET_ADMIN_VESSEL_GROUP_VALUE,
  ADMIN_VESSEL_GROUP_INITIALIZED,
  ADMIN_VESSEL_GROUP_DETACHED,
  DELETE_ADMIN_VESSEL_GROUP,
  DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN,
  DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE,
  DELETE_ADMIN_VESSEL_GROUP_SUCCESS,
  DELETE_ADMIN_VESSEL_GROUP_ERROR,
} from "../action.types";

export const vesselGroupViewInitialized = () => ({
  type: ADMIN_VESSEL_GROUP_INITIALIZED,
});

export const vesselGroupViewDetached = () => ({
  type: ADMIN_VESSEL_GROUP_DETACHED,
});

export const fetchVesselGroups = (companyId) =>
  fetch({
    url: ADMIN_VESSEL_GROUPS.get(companyId),
    actionType: FETCH_ADMIN_VESSEL_GROUPS,
    actionData: {
      companyId,
    },
  });

export const fetchVesselGroup = (id) =>
  fetch({
    url: ADMIN_VESSEL_GROUPS.getById(id),
    actionType: FETCH_ADMIN_VESSEL_GROUP,
    actionData: {
      id,
    },
  });

export const createVesselGroup = (companyId) => ({
  type: CREATE_ADMIN_VESSEL_GROUP,
  companyId,
});

export const closeVesselGroupPopup = () => ({
  type: CLOSE_ADMIN_VESSEL_GROUP_POPUP,
});

export const setVesselGroupValue = (name, value, valid, message) => {
  return {
    type: SET_ADMIN_VESSEL_GROUP_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const deleteVesselGroup =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { form } = state.admin.vesselGroups;
    const companyId = state.admin.selectedCompanyId;
    const payload = convertFieldsToKeyValues(form);

    dispatch({ type: DELETE_ADMIN_VESSEL_GROUP });
    http({
      method: "delete",
      url: ADMIN_VESSEL_GROUPS.delete(payload.id),
    })
      .then(() => {
        dispatch({
          type: DELETE_ADMIN_VESSEL_GROUP_SUCCESS,
        });
        dispatch(fetchVesselGroups(companyId));
      })
      .catch((error) => {
        dispatch({ type: DELETE_ADMIN_VESSEL_GROUP_ERROR, error });
      });
  };

export const deleteVesselGroupPopupOpen = () => ({
  type: DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN,
});

export const deleteVesselGroupPopupClose = () => ({
  type: DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE,
});

export const saveVesselGroup =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { form } = state.admin.vesselGroups;
    const companyId = state.admin.selectedCompanyId;
    const payload = convertFieldsToKeyValues(form);
    const isUpdate = form.isUpdate;

    dispatch({ type: SAVE_ADMIN_VESSEL_GROUP });

    http({
      method: isUpdate ? "put" : "post",
      url: isUpdate
        ? ADMIN_VESSEL_GROUPS.put(payload.id)
        : ADMIN_VESSEL_GROUPS.post(),
      data: payload,
    })
      .then((response) => {
        dispatch({
          type: SAVE_ADMIN_VESSEL_GROUP_SUCCESS,
          data: response.data,
        });
        dispatch(fetchVesselGroups(companyId));
      })
      .catch((error) => {
        dispatch({ type: SAVE_ADMIN_VESSEL_GROUP_ERROR, error });
      });
  };
