import React from "react";

export const VesselIcon = () => (
  <svg
    width="45"
    height="41"
    viewBox="0 0 45 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5458 0.0530289C16.0683 0.190375 16.0156 0.391161 16.0156 2.43173V4.47229H22.7517H29.4694L29.4167 2.40687C29.3816 0.929425 29.293 0.303522 29.1341 0.191029C28.9577 0.0661101 27.5427 0.0281753 22.858 0.00332224C19.5338 -0.00910428 16.6871 0.0157494 16.5458 0.0530289Z"
      fill="#58677A"
    />
    <path
      d="M16.0164 6.9757V8.60292H11.2605C6.52227 8.60292 6.48717 8.60292 6.22209 8.87827C5.97455 9.12876 5.93945 9.90443 5.93945 14.8744C5.93945 18.0039 5.97455 20.6082 6.02812 20.6455C6.08077 20.6828 9.91757 19.5441 14.5321 18.104L22.928 15.5009L31.2195 18.3551C35.7804 19.9326 39.5637 21.1844 39.6172 21.1465C39.7937 21.0216 39.7059 9.25368 39.5286 8.92863L39.3522 8.60292H34.4901H29.6281V6.9757V5.34848H22.8218H16.0155V6.9757H16.0164ZM15.7153 11.2825C16.493 11.5081 17.3944 12.2589 17.5367 12.7972C17.7316 13.548 17.4656 14.1242 16.6528 14.6873C15.84 15.2635 15.0438 15.4512 13.9474 15.3008C13.0635 15.1883 11.8434 14.3243 11.6845 13.6984C11.4896 13.0098 11.7196 12.4087 12.356 11.8959C13.2935 11.1445 14.5136 10.9188 15.7153 11.2825ZM24.2719 11.3446C24.7318 11.495 25.1909 11.7828 25.492 12.0954C25.8984 12.5336 25.952 12.6965 25.8809 13.3348C25.7922 14.2236 25.2796 14.7998 24.2543 15.1249C23.3529 15.4126 22.7691 15.4251 21.8325 15.1622C19.517 14.499 19.4634 12.0823 21.7263 11.3439C22.6629 11.0444 23.3353 11.0444 24.2719 11.3446ZM33.1822 11.3073C35.5338 11.9836 35.5338 14.4872 33.1822 15.1628C32.7758 15.2753 32.2632 15.3629 32.0157 15.3629C31.1844 15.3629 30.1416 14.962 29.6114 14.4493C28.7802 13.6481 28.939 12.3459 29.9116 11.7076C30.8842 11.0941 31.9446 10.9692 33.1822 11.3073Z"
      fill="#58677A"
    />
    <path
      d="M13.7524 12.1962C13.4337 12.2838 13.0098 12.9098 13.0098 13.2976C13.0098 13.7731 13.8937 14.3617 14.6012 14.3617C15.9451 14.3617 16.7053 13.085 15.7502 12.4212C15.3429 12.121 14.3888 12.0085 13.7524 12.1962Z"
      fill="#58677A"
    />
    <path
      d="M21.8672 12.4342C21.0008 13.223 21.6723 14.3623 22.9986 14.3623C24.4303 14.3623 25.2265 13.1105 24.2363 12.4218C23.6175 11.9705 22.3798 11.983 21.8672 12.4342Z"
      fill="#58677A"
    />
    <path
      d="M31.6613 12.0837C31.1487 12.1589 30.8485 12.3715 30.5649 12.8221C30.3701 13.1727 30.3701 13.2976 30.5825 13.6606C30.8651 14.1616 31.3251 14.3617 32.1028 14.3617C33.7469 14.3617 34.0831 12.5088 32.5092 12.1458C32.2275 12.0837 31.8553 12.0582 31.6613 12.0837Z"
      fill="#58677A"
    />
    <path
      d="M13.8941 19.3068C9.56217 20.6463 5.79649 21.8353 5.4963 21.9733C5.21367 22.1113 3.99354 22.5619 2.80943 22.987C1.62532 23.4004 0.617628 23.8006 0.564057 23.8634C0.42274 24.0132 6.23891 39.7622 6.504 39.9872C6.64531 40.1121 10.1284 40.15 22.8395 40.15H38.9986L39.2462 39.8623C39.5999 39.4744 45.0097 24.7902 44.8859 24.5645C44.691 24.2016 39.14 22.1113 31.4322 19.5069C27.1363 18.055 23.5831 16.8653 23.5295 16.8653C23.4759 16.8653 23.4408 21.9857 23.4408 28.2448C23.4408 38.4724 23.4057 39.6366 23.1582 39.7864C22.9633 39.8989 22.8044 39.9113 22.592 39.8112C22.3094 39.6987 22.2918 38.7596 22.2382 28.269C22.2031 19.2682 22.1496 16.8647 21.9731 16.8771C21.8669 16.8778 18.225 17.9798 13.8941 19.3068ZM14.6718 23.9883C15.3793 24.3265 15.6444 24.7019 15.6619 25.3782C15.6619 26.0917 15.2019 26.6424 14.354 26.9308L13.7176 27.1434V29.3219C13.7176 30.5737 13.7888 31.5254 13.8941 31.5751C14.124 31.6751 15.1317 31.3625 15.8032 30.9492C16.5283 30.511 16.9171 30.01 17.1647 29.1467C17.3947 28.3834 17.766 28.1074 18.2786 28.333C18.685 28.5332 18.6323 29.422 18.1724 30.1859C17.766 30.8242 16.3694 31.9387 15.6268 32.2141C15.3091 32.339 14.5304 32.3893 13.2226 32.3893C11.3836 32.3893 11.2598 32.3769 10.5172 32.0139C9.24444 31.4253 8.2543 30.2991 7.97166 29.1597C7.83035 28.5338 8.11298 28.0956 8.6256 28.1584C9.06803 28.2081 9.20934 28.3586 9.56217 29.2977C9.93348 30.2742 11.4011 31.388 12.3026 31.388C12.4088 31.388 12.479 30.6248 12.479 29.1473V26.9066L12.0366 26.7065C11.4178 26.4187 10.8876 25.7804 10.8876 25.3167C10.8885 24.1263 13.2216 23.3127 14.6718 23.9883Z"
      fill="#58677A"
    />
    <path
      d="M12.4436 25.0773C12.2672 25.3402 12.2847 25.4651 12.4962 25.7281C13.0264 26.4043 14.4229 26.129 14.4229 25.3651C14.4229 24.9517 14.0165 24.7516 13.1853 24.7516C12.7798 24.7522 12.6024 24.8275 12.4436 25.0773Z"
      fill="#58677A"
    />
  </svg>
);
