import React from "react";
import { ColumnSortingOptions } from "../types";
import { RootState, useAppSelector } from "../../../store/rootState";
import { Dispatch } from "redux";
import { Direction } from "../../../common/direction";
import styles from "./HeaderCell.css";
import {
  sortByConsumption,
  sortByTrend,
  sortByVesselName,
} from "../reducer.fleetPerformance";
import { useDispatch } from "react-redux";
import { Triangle } from "./Triangle";

type HeaderText = "Vessel" | "Consumption" | "Trend";

interface Props {
  headerText: HeaderText;
}

export const HeaderCell = ({ headerText }: Props) => {
  const dispatch = useDispatch();
  const sortingOrder = useAppSelector(
    (state) => state.fleetPerformance.sortingOrder
  );
  const isActive = useAppSelector(isActiveSelector[headerText]);
  const onClick = () =>
    action[headerText](
      dispatch,
      isActive && sortingOrder === Direction.LowToHigh
        ? Direction.HighToLow
        : Direction.LowToHigh
    );

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={onClick}>
        <div className={styles.text}>{headerText}</div>
        {isActive && <Triangle direction={sortingOrder} />}
      </button>
    </div>
  );
};

const isActiveSelector: Record<HeaderText, (state: RootState) => boolean> = {
  Consumption: (state) =>
    state.fleetPerformance.sorting.type === "column" &&
    state.fleetPerformance.sorting.sorting === ColumnSortingOptions.Consumption,
  Trend: (state) =>
    state.fleetPerformance.sorting.type === "column" &&
    state.fleetPerformance.sorting.sorting === ColumnSortingOptions.Trend,
  Vessel: (state) =>
    state.fleetPerformance.sorting.type === "column" &&
    state.fleetPerformance.sorting.sorting === ColumnSortingOptions.Name,
};

const action: Record<
  HeaderText,
  (dispatch: Dispatch, sortingOrder: Direction) => void
> = {
  Consumption: (dispatch, sortingOrder) =>
    dispatch(sortByConsumption(sortingOrder)),
  Trend: (dispatch, sortingOrder) => dispatch(sortByTrend(sortingOrder)),
  Vessel: (dispatch, sortingOrder) => dispatch(sortByVesselName(sortingOrder)),
};
