import { get } from "lodash";
export const getError = ({ error }) => {
  if (!error) return;
  return {
    error,
    message: get(error, ["response", "data", "message"]) || error.message,
  };
};
// Get only message part from Error
export const getErrorMessage = ({ error }) => {
  return get(getError({ error }), "message");
};
