import { compact, get } from "lodash";
import createReducer from "../createReducer";
import { addTimeOffset, atStartOfYear, now } from "../../common/dates";
import {
  DATEPICKER_UPDATE,
  DATEPICKER_SHOW,
  DATEPICKER_HIDE,
  DATEPICKER_SELECT,
  DATEPICKER_CHANGE_TYPE,
  DATEPICKER_NEXT_OPTIONS,
  DATEPICKER_PREV_OPTIONS,
  DATEPICKER_CHANGE_DATE,
} from "../../actions/action.types";
import dateRangePicker from "./dateRangePicker";
import monthPicker from "./monthPicker";
import quarterPicker from "./quarterPicker";
import yearPicker from "./yearPicker";
import timeOffsetPicker from "./timeOffsetPicker";
import { legPicker, voyagePicker } from "./vesselStatePicker";

const handlers = {
  dateRange: dateRangePicker,
  month: monthPicker,
  quarter: quarterPicker,
  year: yearPicker,
  timeOffset: timeOffsetPicker,
  leg: legPicker,
  voyage: voyagePicker,
};

const ensureDate = (date) => {
  // Check if the date is already a Date object
  if (date instanceof Date) {
    return date;
  }
  // If the date is a string, convert it to a Date object
  return new Date(date);
};

const withLimits = (context) => {
  const limits = context.limits || {};
  return {
    ...context,
    limits: {
      from: limits.from ? ensureDate(limits.from) : now(),
      to: limits.to ? ensureDate(limits.to) : now(),
    },
  };
};

export const getTimeOffsetFormat = (timeOffset) => {
  if (timeOffset || timeOffset === 0) {
    if (timeOffset === 0) {
      return "UTC";
    }
    if (timeOffset > 0) {
      return `UTC+${timeOffset}`;
    }
    return `UTC${timeOffset}`;
  }
  return "";
};

const defaultValue = (type, context, { defaultDate }) => {
  return handlers[type].defaultValue(withLimits(context), defaultDate);
};

const nextValue = ({ type, value }, context) =>
  handlers[type].next(value, context);
const prevValue = ({ type, value }, context) =>
  handlers[type].prev(value, context);
const toJSON = ({ type, value }) => {
  return value && handlers[type].toJSON(value);
};
const clampValue = (current, context) => {
  const { type, value } = current || {};
  const handler = handlers[type];
  if (!handler || !value) return null;
  return handlers[type].clamp(value, withLimits(context));
};
const initOptions = ({ type, value }, context) =>
  handlers[type].options(value, withLimits(context));
const updateOptions = ({ type, options }, context) => {
  if (!handlers[type].updateOptions) {
    return options;
  }
  return handlers[type].updateOptions(options, withLimits(context));
};
const selectOption = (option, { type, options, defaultDate }, context) =>
  handlers[type].selectOption(
    option,
    options,
    withLimits(context),
    type,
    defaultDate
  );
const nextOptions = ({ type, options }, context) =>
  handlers[type].nextOptions(options, withLimits(context));
const prevOptions = ({ type, options }, context) =>
  handlers[type].prevOptions(options, withLimits(context));

export const getDefault = (context, state) => {
  const type = "year";
  const value = defaultValue(type, context, state);
  const timeOffset = 0;
  return {
    type,
    value,
    range: toJSON({ type, value }),
    timeOffset,
  };
};

export const getNext = (current, context) => {
  const value = nextValue(current, context);
  const type = current.type;
  return {
    type,
    value,
    range: toJSON({ type, value }),
    timeOffset: current.timeOffset,
  };
};

export const getPrev = (current, context) => {
  const value = prevValue(current, context);
  const type = current.type;
  return {
    type,
    value,
    range: toJSON({ type, value }),
    timeOffset: current.timeOffset,
  };
};

export const getClamped = (current, context, state) => {
  const value = clampValue(current, context);
  const type = get(current, "type");
  const timeOffset = get(current, "timeOffset");

  if (!value) {
    return getDefault(context, state);
  }

  return {
    type,
    value,
    range: toJSON({ type, value }),
    timeOffset,
  };
};

export const getSelected = (state, current) => {
  let value = state.options.selectedValue;
  const timeOffset = getTimeOffset(state, current);
  value = adjustTimeOffsetForLegAndVoyage(
    value,
    timeOffset,
    current,
    state.type
  );
  return {
    type: state.type,
    value,
    range: handlers[state.type].toJSON(value),
    timeOffset,
  };
};

export const getSelectedTimeOffset = (state, current) => {
  const type = current
    ? current.currentSelectedValueType || current.type
    : state.defaultDate.type;
  let value = current
    ? current.currentSelectedValue || current.value
    : state.defaultDate.value;
  const timeOffset = state.options.selectedValue;
  value = adjustTimeOffsetForLegAndVoyage(value, timeOffset, current, type);
  return {
    type,
    value,
    range: handlers[type].toJSON(value),
    timeOffset,
  };
};

export const getCurrentChanges = (current) => {
  return {
    type: current.type,
    value: current.value,
    range: handlers[current.type].toJSON(current.value),
    timeOffset: parseInt(current.timeOffset),
  };
};

export const canSelectNext = (current, context) => {
  const { type, value } = current || {};
  const handler = handlers[type];
  if (!handler || !value) return false;
  return handlers[type].canSelectNext(value, withLimits(context));
};

export const canSelectPrev = (current, context) => {
  const { type, value } = current || {};
  const handler = handlers[type];
  if (!handler || !value) return false;
  return handlers[type].canSelectPrev(value, withLimits(context));
};

export const formatValue = (current = {}) => {
  const { type, value } = current || {};
  const handler = handlers[type];
  const timeOffset = parseInt(get(current, "timeOffset", 0));
  if (!handler || !value) return null;
  return handlers[type].format(value, getTimeOffsetFormat(timeOffset));
};

export const supportedTypes = ({ vesselId }) =>
  compact([
    "year",
    "quarter",
    "month",
    "dateRange",
    "timeOffset",
    vesselId && "voyage",
    vesselId && "leg",
  ]);

export const isSupported = (current, context) => {
  if (!current) {
    return false;
  }
  return supportedTypes(context).indexOf(current.type) !== -1;
};

export const removeCurrentSelectedValues = (current) => {
  if (
    current &&
    (current.currentSelectedValue ||
      current.currentSelectedUtcValue ||
      current.currentSelectedUtcValue === 0)
  ) {
    current.currentSelectedValue = undefined;
    current.currentSelectedValueType = undefined;
    current.currentSelectedUtcValue = undefined;
  }
};

export const adjustDateWhenTimeOffsetInUrlIsChanged = (
  current,
  context,
  onChange
) => {
  if (current && (current.type === "leg" || current.type === "voyage")) {
    const data =
      current.type === "leg"
        ? get(context, "legs.data", [])
        : get(context, "voyages.data", []);
    const vesselState = data && data.find((d) => d.id === current.value.id);
    if (vesselState) {
      const from = addTimeOffset(
        parseInt(current.timeOffset),
        new Date(vesselState.from)
      );
      const to = addTimeOffset(
        parseInt(current.timeOffset),
        new Date(vesselState.to)
      );
      if (current.value.from.getTime() !== from.getTime()) {
        current.value.from = from;
        current.value.to = to;
        if (onChange) {
          onChange(getCurrentChanges(current));
        }
      }
    }
  }
};

const adjustTimeOffsetForLegAndVoyage = (value, timeOffset, current, type) => {
  if ((type === "leg" || type === "voyage") && current) {
    if (current.currentSelectedValue) {
      value.from = addTimeOffset(timeOffset, current.currentSelectedValue.from);
      value.to = addTimeOffset(timeOffset, current.currentSelectedValue.to);
    } else {
      value.from = addTimeOffset(
        timeOffset - parseInt(current.timeOffset),
        value.from
      );
      value.to = addTimeOffset(
        timeOffset - parseInt(current.timeOffset),
        value.to
      );
    }
  }
  return value;
};

const createDefaultDate = () => {
  return {
    timeOffset: 0,
    value: atStartOfYear(now()),
    type: "year",
  };
};

const getTimeOffset = (state, current) => {
  if (current) {
    if (
      current.currentSelectedUtcValue ||
      current.currentSelectedUtcValue === 0
    ) {
      return current.currentSelectedUtcValue;
    }
    return parseInt(current.timeOffset);
  }
  return state.defaultDate.timeOffset;
};

export default createReducer(
  {},
  {
    [DATEPICKER_SHOW]: (state, { context }) => {
      const current = context.current;
      const selected = isSupported(current, context)
        ? getClamped(current, context, state)
        : getDefault(context, state);
      return {
        defaultDate: createDefaultDate(),
        isVisible: true,
        type: selected.type,
        supportedTypes: supportedTypes(context),
        options: initOptions(selected, context),
        timeOffset: selected.timeOffset,
      };
    },

    [DATEPICKER_UPDATE]: (state, { context }) => {
      if (!state.isVisible) {
        return state;
      }
      return {
        ...state,
        options: updateOptions(state, context),
      };
    },

    [DATEPICKER_SELECT]: (state, { option, context }) => {
      return {
        ...state,
        options: selectOption(option, state, context),
      };
    },

    [DATEPICKER_NEXT_OPTIONS]: (state, { context }) => ({
      ...state,
      options: nextOptions(state, context),
    }),

    [DATEPICKER_PREV_OPTIONS]: (state, { context }) => ({
      ...state,
      options: prevOptions(state, context),
    }),

    [DATEPICKER_CHANGE_TYPE]: (state, { dateType, context }) => {
      const next = {
        type: dateType,
        value: defaultValue(dateType, context, state),
      };
      return {
        ...state,
        type: dateType,
        options: initOptions(next, {
          ...context,
          value: {
            ...next,
          },
        }),
      };
    },
    [DATEPICKER_CHANGE_DATE]: (state, { dateValues, context }) => {
      return {
        ...state,
        options: updateOptions(dateValues, context),
      };
    },

    [DATEPICKER_HIDE]: () => ({ isVisible: false }),
  }
);
