import React, { useContext, useEffect, useState } from "react";
import NodeItem from "./NodeItem";
import Xarrow, { Xwrapper } from "react-xarrows";
import { NodeRelationContext } from "./NodeRelation";
import { get } from "lodash";
import { ansiColor } from "../../../common/colors";

const DRAG_DELETE_DISTANCE = 30;

const renderNodes = (relations, nodes = []) => {
  return nodes.map((n) => {
    return (
      <NodeItem
        key={`node_${n.id}`}
        id={n.id}
        left={n.x}
        top={n.y}
        relations={relations}
        title={n.title}
        subTitle={n.subTitle}
      />
    );
  });
};

const NodeRelation = ({ relation }) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [mouseDownPosition, setMouseDownPosition] = useState();
  const [initialDownPosition, setInitialDownPosition] = useState();
  const { onRelationRemoved } = useContext(NodeRelationContext);

  useEffect(() => {
    const onWindowMouseMove = (e) => {
      if (!isMouseDown) return;
      e.srcElement.nodeName !== "path" &&
        setMouseDownPosition({ x: e.layerX, y: e.layerY });
    };
    window.addEventListener("mousemove", onWindowMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mousemove", onWindowMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isMouseDown]);

  useEffect(() => {
    if (initialDownPosition && mouseDownPosition) {
      const a = initialDownPosition.x - mouseDownPosition.x;
      const b = initialDownPosition.y - mouseDownPosition.y;
      const distance = Math.sqrt(a * a + b * b);

      if (distance > DRAG_DELETE_DISTANCE) {
        setIsMouseDown(false);
        setInitialDownPosition(undefined);
        setMouseDownPosition(undefined);
        if (onRelationRemoved) {
          onRelationRemoved(relation);
        }
      }
    }
  }, [initialDownPosition, mouseDownPosition]);

  const handleMouseDown = (e) => {
    let currentTargetRect = get(
      e,
      "currentTarget.parentNode.parentNode"
    ).getBoundingClientRect();
    const event_offsetX = e.clientX - currentTargetRect.left;
    const event_offsetY = e.clientY - currentTargetRect.top;

    setInitialDownPosition({ x: event_offsetX, y: event_offsetY });
    setMouseDownPosition({ x: event_offsetX, y: event_offsetY });
    setIsMouseDown(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseUp = (e) => {
    setIsMouseDown(false);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <React.Fragment>
      {isMouseDown && mouseDownPosition && (
        <div style={{ position: "absolute" }}>
          <div
            id={"mouse_down_div"}
            style={{
              position: "absolute",
              top: mouseDownPosition.y,
              left: mouseDownPosition.x,
              width: "1px",
              height: "1px",
              opacity: 0,
            }}
          />
        </div>
      )}
      <Xarrow
        color={ansiColor("blue")}
        key={`node_relation_${relation.sourceId}_${relation.destinationId}`}
        start={`nodeContainer${relation.sourceId}`}
        end={
          isMouseDown && mouseDownPosition
            ? "mouse_down_div"
            : `nodeContainer${relation.destinationId}`
        }
        strokeWidth={3}
        headSize={5}
        arrowHeadProps={{
          onMouseDown: handleMouseDown,
          onMouseUp: handleMouseUp,
        }}
      />
    </React.Fragment>
  );
};

const renderRelations = (relations = []) => {
  return relations.map((r) => {
    return (
      <NodeRelation
        key={`node_${r.sourceId}_${r.destinationId}`}
        relation={r}
      />
    );
  });
};

const NodeItemContainer = ({ nodes, relations }) => {
  return (
    <div>
      <Xwrapper>
        {renderRelations(relations)}
        {renderNodes(relations, nodes)}
      </Xwrapper>
    </div>
  );
};

export default NodeItemContainer;
