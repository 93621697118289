import { FETCH_DONUT } from "./action.types";
import fetch from "./action.fetch";
import { createDonutUrl } from "../common/donuts";

const createAction = (context) => (itemTypeMetricId) => {
  return fetch({
    url: createDonutUrl(itemTypeMetricId, context),
    key: `${FETCH_DONUT}_${itemTypeMetricId}`,
    actionType: FETCH_DONUT,
    actionData: { itemTypeMetricId, context },
  });
};

const toActions = (itemMetricIds, context) =>
  itemMetricIds.map(createAction(context));

export const fetchDonuts = (itemTypeMetricIds, context) => (dispatch) =>
  toActions(itemTypeMetricIds, context).forEach((action) => dispatch(action));
