import React from "react";
import styles from "./PerformanceIndicator.css";

interface Props {
  performance: number;
}

export function PerformanceIndicator({ performance }: Props): JSX.Element {
  const performanceColor = performance > 0 ? "positive" : "negative";

  return (
    <div
      className={styles.fuelConsumptionPercentContainer}
      data-type={performanceColor}
    >
      {performance !== 0 && (
        <div className={styles.arrow} data-type={performanceColor}></div>
      )}
      {performance}%
    </div>
  );
}
