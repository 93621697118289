import PropTypes from "prop-types";
import React from "react";
import styles from "./ContextBrowser.css";

export default class PrevNextBtn extends React.Component {
  icon(dir) {
    let rotation = {
      transform: "rotate(-90deg)",
    };

    if (dir === "prev") {
      rotation.transform = "rotate(90deg)";
    }
    return (
      <span className={styles.icon}>
        <svg
          style={rotation}
          className={styles.svg}
          x="0px"
          y="0px"
          viewBox="0 0 16 16"
          enableBackground="new 0 0 16 16"
          xmlSpace="preserve"
        >
          <polygon points="8,12.3 0,4.3 0.5,3.7 8,11.2 15.5,3.7 16,4.3 " />
        </svg>
      </span>
    );
  }

  render() {
    const compClass = styles.prevNextBtn,
      clickAction = this.props.clickAction,
      title = this.props.title,
      direction = this.props.direction,
      enabled = this.props.enabled;

    return (
      <button
        disabled={!enabled}
        className={compClass}
        onClick={clickAction}
        title={title}
      >
        <span className={styles.text}>{title}</span>
        {this.icon(direction)}
      </button>
    );
  }
}

PrevNextBtn.propTypes = {
  title: PropTypes.string,
  clickAction: PropTypes.func,
  enabled: PropTypes.bool,
  direction: PropTypes.string,
};
