import { GAUGE } from "../api";
import { PERFORMANCE_INDICATOR_ICONS } from "../config";
import { formatNumber } from "../../common/numbers";

export const createGaugeUrl = (performanceIndicatorId, context) => {
  if (!context.dateRange) {
    throw new Error("context.dateRange is required");
  }
  if (context.groupId) {
    return GAUGE.getGroup(
      performanceIndicatorId,
      context.groupId,
      context.dateRange,
      context.timeOffset
    );
  } else if (context.vesselId) {
    return GAUGE.getVessel(
      performanceIndicatorId,
      context.vesselId,
      context.dateRange,
      context.timeOffset,
      context.states
    );
  }
  throw new Error("One of context.groupId and context.vesselId is required");
};

export const prepareGaugeData = (data) => {
  return {
    performanceIndicatorId: data.id,
    value: data.actualValue,
    minValue: data.minValue,
    maxValue: data.maxValue,
    performanceValue: data.performanceValue,
    performanceMaxValue: data.performanceMaxValue,
    performanceMinValue: data.performanceMinValue,
    formatValue: formatNumber,
    unit: data.unit,
    header: data.label,
    icon: PERFORMANCE_INDICATOR_ICONS[String(data.label).toLocaleLowerCase()],
    sparklineData: data.sparklineData,
  };
};
