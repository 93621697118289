import { vesselConfig } from "../action.types";

export const editVesselOperation = ({ id }) => {
  return {
    type: vesselConfig.vesselOperations.EDIT,
    id,
  };
};

export const cancelEditVesselOperation = (id) => {
  return {
    type: vesselConfig.vesselOperations.CANCEL_EDIT,
    id,
  };
};

export const inputChanged = (id, name, value, valid) => ({
  type: vesselConfig.vesselOperations.INPUT_CHANGED,
  id,
  name,
  value,
  valid,
});

export const saveVesselOperation = ({ vesselId, id, form }) => ({
  type: vesselConfig.vesselOperations.SAVE,
  vesselId,
  id,
  form,
});

export const newVesselOperation = () => ({
  type: vesselConfig.vesselOperations.NEW,
});
export const deleteVesselOperation = (id) => ({
  type: vesselConfig.vesselOperations.DELETE,
  id,
});
export const cancelDeleteVesselOperation = (id) => ({
  type: vesselConfig.vesselOperations.CANCEL_DELETE,
  id,
});
export const confirmDeleteVesselOperation = (id) => ({
  type: vesselConfig.vesselOperations.CONFIRM_DELETE,
  id,
});
