import { serializeQueryString } from "./queryStrings";

/**
 * @param {String} baseUrl
 * @param {Object} queryObject
 * @returns {String} an url from the given `baseUrl` and `queryObject`,
 * serializing the latter to a query string
 */
export function createUrl(baseUrl, queryObject = null) {
  const queryString = serializeQueryString(queryObject);
  return (baseUrl || "") + (queryString ? "?" + queryString : "");
}
