import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useGetMockUserDataQuery } from "./api.MockUserData";
import { FETCH_USER_DATA_SUCCESS } from "../../actions/action.types";
import Loader from "../../../common/components/Loader";

interface Props {
  children: React.ReactNode;
}

/**
 * Component to fetch userData from mock server when auth is disabled
 * @param children
 * @constructor
 */
export const MockUserData = ({ children }: Props) => {
  const dispatch = useDispatch();

  const userData = useGetMockUserDataQuery(undefined);

  useEffect(() => {
    if (!userData.isLoading && !userData.error)
      dispatch({ type: FETCH_USER_DATA_SUCCESS, data: userData.data });
  }, [userData.data]);

  if (userData.isLoading) {
    return <Loader />;
  }
  if (userData.error) {
    return <p>OH NO!</p>;
  }
  return <>{children}</>;
};
