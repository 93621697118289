import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { get, sortBy, omit, pick, some, isEmpty } from "lodash";
import { checkFormValidation, convertObjToFields } from "../../../common/forms";
import { OPERATIONS } from "../../../config";

const defaultFields = {
  operations: undefined,
  minSpeed: undefined,
  maxSpeed: undefined,
  minEngines: undefined,
  maxEngines: undefined,
  maxTension: undefined,
};

const initialState = {
  data: [],
  columns: [],
};

export default createReducer(initialState, {
  [vesselConfig.vesselOperations.FETCH]: (_state, { vesselId }) => {
    return { ...initialState, isLoading: true, vesselId };
  },
  [vesselConfig.vesselOperations.FETCH_SUCCESS]: (
    state,
    { operations, vesselOperations }
  ) => {
    return {
      ...state,
      data: sortBy(vesselOperations, "operationName"),
      operations,
      isLoading: false,
    };
  },
  [vesselConfig.vesselOperations.FETCH_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      error,
    };
  },
  [vesselConfig.vesselOperations.INPUT_CHANGED]: (
    state,
    { id, name, value, valid, message }
  ) => {
    return {
      ...state,
      data: state.data.map((vo) => {
        if (vo.id === id) {
          const isOperationChanged = name === "operations";
          const field = get(vo.form.fields, name);
          const minMaxSpeed =
            isOperationChanged &&
            getDefaultMinMaxSpeed(value, state.operations);

          let fields = {
            ...vo.form.fields,
            [name]: {
              ...field,
              isValid: valid !== undefined ? valid : !isEmpty(value),
              message: message || "",
              hasChanges: true,
            },
            ...minMaxSpeed,
          };

          if (isOperationChanged) {
            fields.operations.value = value.map((o) => ({
              operationId: o.id,
              minSpeed: o.minSpeed,
              maxSpeed: o.maxSpeed,
            }));
          } else {
            fields[name].value = value;
          }

          const operationId =
            fields.operations.value.length === 1
              ? fields.operations.value[0].operationId
              : undefined;

          return {
            ...vo,
            form: checkFormValidation({
              ...vo.form,
              fields,
              showSpeed: showSpeedFields(operationId),
              showEngines: showEngineFields(operationId),
              showTension: showTensionField(operationId),
            }),
          };
        } else {
          return vo;
        }
      }),
    };
  },
  [vesselConfig.vesselOperations.EDIT]: (state, { id }) => {
    return {
      ...state,
      data: state.data.map((vo) => {
        if (vo.id === id) {
          const operations = [
            {
              operationId: vo.operationId,
              minSpeed: vo.minSpeed,
              maxSpeed: vo.maxSpeed,
            },
          ];
          return {
            ...vo,
            operations,
            form: {
              fields: convertObjToFields(
                pick({ ...vo, operations }, Object.keys(defaultFields))
              ),
              isVisible: true,
              showSpeed: showSpeedFields(vo.operationId),
              showEngines: showEngineFields(vo.operationId),
              showTension: showTensionField(vo.operationId),
            },
          };
        } else {
          return vo;
        }
      }),
    };
  },
  [vesselConfig.vesselOperations.CANCEL_EDIT]: (state, { id }) => {
    return {
      ...state,
      data: state.data
        .filter((x) => x.id)
        .map((x) => (x.id === id ? omit(x, "form") : x)),
    };
  },
  [vesselConfig.vesselOperations.SAVE_NEW_SUCCESS]: (state, { data }) => {
    const updatedList = state.data.flatMap((x) => (!x.id ? data : x));
    return {
      ...state,
      data: sortBy(updatedList, "operationName"),
    };
  },
  [vesselConfig.vesselOperations.SAVE_NEW_ERROR]: (state, { error }) => {
    return {
      ...state,
      data: state.data.map((x) =>
        !x.id ? { ...x, form: { ...x.form, error } } : x
      ),
    };
  },
  [vesselConfig.vesselOperations.UPDATE_EXISTING_SUCCESS]: (
    state,
    { id, data }
  ) => {
    const updatedList = state.data.map((x) => (x.id === id ? data : x));
    return {
      ...state,
      data: sortBy(updatedList, "operationName"),
    };
  },
  [vesselConfig.vesselOperations.UPDATE_EXISTING_ERROR]: (
    state,
    { id, error }
  ) => {
    return {
      ...state,
      data: state.data.map((vo) =>
        vo.id === id ? { ...vo, form: { ...vo.form, error } } : vo
      ),
    };
  },
  [vesselConfig.vesselOperations.NEW]: (state) => {
    return {
      ...state,
      data: [
        {
          ...defaultFields,
          form: {
            fields: convertObjToFields(defaultFields),
            isVisible: true,
            showSpeed: false,
            showEngines: false,
            showTension: false,
            isNew: true,
          },
        },
        ...state.data,
      ],
    };
  },
  [vesselConfig.vesselOperations.DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vo) => {
        if (vo.id === id) {
          return {
            ...vo,
            form: {
              ...vo.form,
              isDeleting: true,
            },
          };
        } else {
          return vo;
        }
      }),
    ],
  }),
  [vesselConfig.vesselOperations.CANCEL_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vo) => {
        if (vo.id === id) {
          return {
            ...vo,
            form: {
              ...vo.form,
              isDeleting: false,
            },
          };
        } else {
          return vo;
        }
      }),
    ],
  }),
  [vesselConfig.vesselOperations.CONFIRM_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vo) => {
        if (vo.id === id) {
          return {
            ...vo,
            form: {
              ...vo.form,
              isLoading: true,
            },
          };
        } else {
          return vo;
        }
      }),
    ],
  }),
  [vesselConfig.vesselOperations.CONFIRM_DELETE_SUCCESS]: (state, { id }) => ({
    ...state,
    data: [...state.data.filter((vo) => vo.id !== id)],
  }),
  [vesselConfig.vesselOperations.CONFIRM_DELETE_ERROR]: (
    state,
    { id, error }
  ) => ({
    ...state,
    data: [
      ...state.data.map((vo) => {
        if (vo.id === id) {
          return {
            ...vo,
            form: {
              ...vo.form,
              isLoading: false,
              error,
            },
          };
        } else {
          return vo;
        }
      }),
    ],
  }),
});

export const getDefaultMinMaxSpeed = (selectedOperations, operations) => {
  const operation = operations.find((x) => x.id === selectedOperations[0]?.id);
  const minSpeed =
    selectedOperations.length === 1 ? get(operation, "minSpeed") : undefined;
  const maxSpeed =
    selectedOperations.length === 1 ? get(operation, "maxSpeed") : undefined;

  return {
    minSpeed: {
      value: minSpeed,
      hasChanges: true,
      isValid: true,
    },
    maxSpeed: {
      value: maxSpeed,
      hasChanges: true,
      isValid: true,
    },
  };
};

export const showEngineFields = (operationId) => {
  if (operationId === undefined) {
    return false;
  }
  return OPERATIONS_ENGINES.map((o) => o.toUpperCase()).includes(
    operationId.toUpperCase()
  );
};
export const showSpeedFields = (operationId) => {
  if (operationId === undefined) {
    return false;
  }
  return OPERATIONS_SPEED.map((o) => o.toUpperCase()).includes(
    operationId.toUpperCase()
  );
};
export const showTensionField = (operationId) => {
  if (operationId === undefined) {
    return false;
  }
  return OPERATIONS_TENSION.map((o) => o.toUpperCase()).includes(
    operationId.toUpperCase()
  );
};
export const getShowMinMaxEngines = (vesselOperations) => {
  return !!(
    vesselOperations &&
    vesselOperations.length > 0 &&
    some(
      vesselOperations,
      (vo) => vo.minEngines !== undefined && vo.maxEngines !== undefined
    ) &&
    some(
      vesselOperations.map((x) => x.operationId),
      showEngineFields
    )
  );
};
export const getShowMaxTension = (vesselOperations) => {
  return !!(
    vesselOperations &&
    vesselOperations.length > 0 &&
    some(
      vesselOperations.map((x) => x.operationId),
      showTensionField
    )
  );
};

export const getColumns = (showMinMaxEngines, showMaxTension) => {
  let columns = [
    {
      header: "Operation",
      field: "operationName",
    },
    {
      header: "Min speed",
      field: "minSpeed",
    },
    {
      header: "Max speed",
      field: "maxSpeed",
    },
  ];
  if (showMinMaxEngines) {
    columns.push(
      {
        header: "Min engines",
        field: "minEngines",
      },
      {
        header: "Max engines",
        field: "maxEngines",
      }
    );
  }
  if (showMaxTension) {
    columns.push({
      header: "Max tension",
      field: "maxTension",
    });
  }
  return columns;
};

const OPERATIONS_TENSION = [OPERATIONS.AH_TOWING];

const OPERATIONS_ENGINES = [
  OPERATIONS.FAST_TRANSIT,
  OPERATIONS.TRANSIT,
  OPERATIONS.ECO_TRANSIT,
  OPERATIONS.MANEUVRE,
];

const OPERATIONS_SPEED = [
  OPERATIONS.FAST_TRANSIT,
  OPERATIONS.TRANSIT,
  OPERATIONS.ECO_TRANSIT,
  OPERATIONS.MANEUVRE,
  OPERATIONS.STANDBY,
  OPERATIONS.PORT, // Port shoreconnection and Port battery will get maxSpeed from Port
];
