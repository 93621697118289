import React from "react";
import styles from "./Header.css";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import RadioButton, { Option } from "../../../common/components/RadioButton";

interface IProps {
  title: string;
  info?: string;
  headerSelectOptions?: IHeaderSelectOptions;
}

export interface IHeaderSelectOptions {
  options: Option[];
  onChange?(o: Option): unknown;
}

export function Header({
  title,
  info,
  headerSelectOptions,
}: IProps): JSX.Element {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      <div className={styles.options}>
        {headerSelectOptions && (
          <RadioButton
            options={headerSelectOptions.options}
            onChange={headerSelectOptions.onChange}
          />
        )}
        <div className={styles.componentInfo}>
          <ComponentInfo
            modalTargetId={"popupContainer"}
            title={title}
            text={info}
          >
            {" "}
          </ComponentInfo>
        </div>
      </div>
    </div>
  );
}

export default Header;
