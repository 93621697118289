import React from "react";
import { connect } from "react-redux";
import { getLimits } from "../../../common/vessels";
import {
  getVesselsInGroup,
  getFirstVesselInGroup,
} from "../../../reducers/reducer.pageContext";
import styles from "./AddColumn.css";
import Button from "../../../../common/components/Button";
import DatePickerContainer from "../../DatePicker/DatePickerContainer";
import { clampDateFilter, defaultDateFilter } from "../../../common/dateFilter";
import MasterChildSelect from "../../MasterChildSelect/MasterChildSelect";

function getValidDateFilter(dateFilter, timeOffset, vessel) {
  return clampDateFilter(
    dateFilter || defaultDateFilter(timeOffset, vessel),
    getLimits(vessel)
  );
}

class AddColumn extends React.Component {
  constructor(props) {
    super(props);
    const vessel = props.pageContext.vessels[props.initialVesselId];
    this.state = {
      selectedGroupId: props.initialGroupId,
      selectedVesselId: props.initialVesselId,
      selectedDate: getValidDateFilter(
        props.initialDate,
        props.timeOffset,
        vessel
      ),
    };
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onVesselSelect = this.onVesselSelect.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.addVessel = this.addVessel.bind(this);
  }

  onGroupSelect({ id }) {
    const vessel = getFirstVesselInGroup(id, this.props.pageContext);
    this.setState({
      selectedGroupId: id,
      selectedVesselId: vessel.id,
      selectedDate: getValidDateFilter(
        this.state.selectedDate,
        this.props.timeOffset,
        vessel
      ),
    });
  }

  onVesselSelect({ id }) {
    this.setState({
      selectedVesselId: id,
      selectedDate: getValidDateFilter(
        this.state.selectedDate,
        this.props.timeOffset,
        this.props.pageContext.vessels[id]
      ),
    });
  }

  onDateSelect(selectedDate) {
    this.setState({ selectedDate });
  }

  addVessel() {
    this.props.addVessel(this.state);
  }

  render() {
    const { pageContext } = this.props;
    const { selectedVesselId, selectedGroupId, selectedDate } = this.state;

    return (
      <div className={styles.addVesselOptionsContainer}>
        <div className={styles.addVesselOption}>
          <MasterChildSelect
            masterOptions={Object.values(pageContext.groups)}
            childOptions={getVesselsInGroup(selectedGroupId, pageContext)}
            selectedMaster={pageContext.groups[selectedGroupId]}
            selectedChild={pageContext.vessels[selectedVesselId]}
            onMasterSelect={this.onGroupSelect}
            onSelect={this.onVesselSelect}
            direction={"column"}
            maxWidth={"20rem"}
          />
        </div>
        <div className={styles.addVesselOption}>
          <DatePickerContainer
            vesselId={selectedVesselId}
            limits={getLimits(pageContext.vessels[selectedVesselId])}
            current={selectedDate}
            onChange={this.onDateSelect}
          />
        </div>
        <div className={styles.addVesselOption}>
          <Button clickAction={this.addVessel} value="Add" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timeOffset: state.user.defaultTimeOffset,
  };
};

export default connect(mapStateToProps)(AddColumn);
