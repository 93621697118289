import React, { FC } from "react";
import FormDurationBox from "../Form/FormDurationBox/FormDurationBox";
import clockIcon from "../../../style/img/clock.svg";
import { FormSelectBox } from "../Form";
import { FuelDataType, Operation } from "./Types";
import OperationOption from "./OperationOption";

interface RowHeaderProps {
  rowNumber: number;
  disabled: boolean;
  data: FuelDataType[];
  onFormDataChanged: (
    field: string,
    value: any,
    index: number,
    meta?: any
  ) => void;
}

const getOperationOptions = (operations: Operation[]) => {
  return operations
    ? operations.map((mode) => {
        return {
          id: mode.legend,
          name: <OperationOption operation={mode} />,
        };
      })
    : [];
};

const RowHeader: FC<RowHeaderProps> = ({
  rowNumber,
  disabled: disabled,
  data,
  onFormDataChanged,
}) => {
  const modeOptions = getOperationOptions(data[rowNumber].availableOperations);

  return (
    <div style={{ display: "flex", gap: "16px", marginRight: "50px" }}>
      <FormSelectBox
        name={"operation"}
        label={rowNumber === 0 ? "Mode" : ""}
        onChange={(field: string, value: any) =>
          onFormDataChanged(field, value, rowNumber)
        }
        options={modeOptions}
        optionValKey={"name"}
        disabled={disabled}
        width={200}
        selected={modeOptions.find(
          (mode) => mode.id === data[rowNumber]?.operationLegend
        )}
        testId={`Mode-${rowNumber}`}
      />
      <FormDurationBox
        seconds={data[rowNumber] ? data[rowNumber].duration : 0}
        onChange={(time: any) => onFormDataChanged("duration", time, rowNumber)}
        width={"170px"}
        icon={clockIcon}
        label={rowNumber === 0 ? "Duration" : ""}
        testId={`Duration-${rowNumber}`}
      />
    </div>
  );
};

export default RowHeader;
