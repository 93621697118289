import createReducer from "./createReducer";
import {
  BEGIN_DOCUMENTS_EDIT_FOLDER,
  BEGIN_DOCUMENTS_ADD_FILE,
  BEGIN_DOCUMENTS_EDIT_FILE,
  CANCEL_DOCUMENTS_EDIT_FOLDER,
  CANCEL_DOCUMENTS_FILE,
  CLEAR_DOCUMENTS_FILTERS,
  FETCH_USER_DATA_SUCCESS,
  SAVE_DOCUMENTS_FILE,
  SET_DOCUMENTS_EDIT_FILE,
  SET_DOCUMENTS_EDIT_FILENAME,
  SET_DOCUMENTS_EDIT_VESSEL_ID,
  SET_DOCUMENTS_FILE_TYPE_FILTER,
  SET_DOCUMENTS_MODIFIED_FILTER,
  SET_DOCUMENTS_SEARCH_STRING,
  SET_DOCUMENTS_VESSEL_FILTER,
  SET_DOCUMENTS_VESSEL_GROUP_FILTER,
  TOGGLE_DOCUMENT_FOLDER_OPEN,
  SAVE_DOCUMENTS_FILE_SUCCESS,
  SAVE_DOCUMENTS_FILE_PROGRESS,
  SET_DOCUMENTS_EDIT_FILE_FOLDER_ID,
  BEGIN_DOCUMENTS_ADD_FOLDER,
  SAVE_DOCUMENTS_FILE_ERROR,
  SET_DOCUMENTS_COMPANY_ID,
  SET_DOCUMENTS_EDIT_FOLDER_NAME,
  SAVE_DOCUMENTS_EDIT_FOLDER,
  FETCH_DOCUMENTS_FILES,
  FETCH_DOCUMENTS_FILES_SUCCESS,
  FETCH_DOCUMENTS_FILES_ERROR,
  SET_DOCUMENTS_EDIT_FOLDER_DESC,
  FETCH_DOCUMENTS_FOLDERS_SUCCESS,
  FETCH_DOCUMENTS_FILE_TYPES_SUCCESS,
  FETCH_DOCUMENTS_DATE_RANGE_SUCCESS,
  SET_DOCUMENTS_EDIT_CHECKED,
  BEGIN_DOCUMENTS_DELETE_FILE,
  CANCEL_DOCUMENTS_DELETE_FILE,
  DELETE_DOCUMENTS_FILE,
  DELETE_DOCUMENTS_FILE_SUCCESS,
  DELETE_DOCUMENTS_FILE_ERROR,
  LOAD_DOCUMENTS_FILTERS,
  LOAD_DOCUMENTS_FILTERS_SUCCESS,
  LOAD_DOCUMENTS_FILTERS_ERROR,
  SAVE_DOCUMENTS_EDIT_FOLDER_ERROR,
  SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS,
  CLEAR_DOCUMENTS_ERRORS,
} from "../actions/action.types";
import { findIndex, get, unionBy, uniqBy, filter } from "lodash";

const getVesselsForGroup = (group, vesselGroupVessels, vessels) => {
  if (!group) return [];
  return vessels.reduce((acc, vessel) => {
    if (
      findIndex(vesselGroupVessels, {
        groupId: group.id,
        vesselId: vessel.id,
      }) !== -1
    ) {
      acc.push(vessel);
    }
    return acc;
  }, []);
};

const resetGroupFilter = (groups, vesselGroupVessels, vessels) => {
  if (groups && groups.length === 1) {
    return {
      filterGroup: groups[0],
      canSelectGroup: false,
      vesselsForSelectedGroup: getVesselsForGroup(
        groups[0],
        vesselGroupVessels,
        vessels
      ),
    };
  }
  return {
    filterGroup: null,
    canSelectGroup: true,
    vesselsForSelectedGroup: [],
  };
};

export const validateFile = (file) => {
  let isValid = true;
  let validationMessage = [];
  if (!file.name) {
    isValid = false;
    validationMessage.push("Filename is required");
  }
  if (!file.vesselDocumentFolderId) {
    isValid = false;
    validationMessage.push("Folder is required");
  }
  if (file.new === true && !file.target) {
    isValid = false;
    validationMessage.push("File is required");
  }
  if (file.policyChecked !== true) {
    isValid = false;
    validationMessage.push("Must accept policy");
  }
  return {
    ...file,
    isValid,
    validationMessage,
  };
};

export const validateFolder = (folder) => {
  let isValid = true;
  if (!folder.name) {
    isValid = false;
  }
  return {
    ...folder,
    isValid,
  };
};

const initialState = {
  isLoading: false,
  isLoadingDocuments: false,
  searchString: "",
  vesselGroups: [],
  vessels: [],
  fileTypes: [],
  ...resetGroupFilter(),
  filterVessel: null,
  filterModified: null,
  filterFileType: null,
  dateLimits: {
    from: new Date(2017, 1, 1).toISOString(),
    to: new Date(Date.now()).toISOString(),
  },
  documents: [],
};

export const documents = createReducer(initialState, {
  [LOAD_DOCUMENTS_FILTERS]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [LOAD_DOCUMENTS_FILTERS_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [LOAD_DOCUMENTS_FILTERS_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }),
  [SET_DOCUMENTS_SEARCH_STRING]: (state, { searchString }) => ({
    ...state,
    searchString: searchString,
  }),
  [TOGGLE_DOCUMENT_FOLDER_OPEN]: (state, { folderId }) => {
    const docs = state.documents.map((folder) => {
      if (folder.id === folderId) {
        return {
          ...folder,
          expanded: !(folder.expanded || false),
        };
      }
      return folder;
    });
    return {
      ...state,
      documents: docs,
    };
  },
  [CLEAR_DOCUMENTS_FILTERS]: (state) => ({
    ...state,
    searchString: "",
    filterVessel: null,
    filterModified: null,
    filterFileType: null,
    ...resetGroupFilter(
      state.filteredVesselGroups,
      state.vesselGroupVessels,
      state.vessels
    ),
  }),
  [SET_DOCUMENTS_VESSEL_GROUP_FILTER]: (state, { group }) => ({
    ...state,
    filterGroup: group,
    filterVessel: null,
    vesselsForSelectedGroup: getVesselsForGroup(
      group,
      state.vesselGroupVessels,
      state.vessels
    ),
  }),
  [SET_DOCUMENTS_VESSEL_FILTER]: (state, { vessel }) => ({
    ...state,
    filterVessel: vessel,
  }),
  [SET_DOCUMENTS_MODIFIED_FILTER]: (state, { date }) => ({
    ...state,
    filterModified: date,
  }),
  [SET_DOCUMENTS_FILE_TYPE_FILTER]: (state, { fileType }) => ({
    ...state,
    filterFileType: fileType,
  }),
  [BEGIN_DOCUMENTS_EDIT_FILE]: (state, { file }) => ({
    ...state,
    editFile: validateFile({
      ...file,
    }),
  }),
  [CANCEL_DOCUMENTS_FILE]: (state) => ({
    ...state,
    editFile: null,
  }),
  [SET_DOCUMENTS_EDIT_FILENAME]: (state, { filename }) => {
    if (!state.editFile) return;
    return {
      ...state,
      editFile: validateFile({
        ...state.editFile,
        name: filename,
        hasChanges: true,
      }),
    };
  },
  [SET_DOCUMENTS_EDIT_VESSEL_ID]: (state, { vesselId }) => {
    if (!state.editFile) return;
    return {
      ...state,
      editFile: validateFile({
        ...state.editFile,
        vesselId,
        hasChanges: true,
      }),
    };
  },
  [SET_DOCUMENTS_EDIT_FILE]: (state, { file, target }) => {
    if (!state.editFile) return { ...state };
    return {
      ...state,
      editFile: validateFile({
        ...state.editFile,
        name: state.editFile.name ? state.editFile.name : get(file, "name", ""),
        sizeInBytes: get(file, "sizeInBytes", 0) / 1000,
        fileType: get(file, "type"),
        target,
        hasChanges: true,
      }),
    };
  },
  [SET_DOCUMENTS_EDIT_FILE_FOLDER_ID]: (state, { folderId }) => {
    if (!state.editFile) return;
    return {
      ...state,
      editFile: validateFile({
        ...state.editFile,
        vesselDocumentFolderId: folderId,
        hasChanges: true,
      }),
    };
  },
  [SET_DOCUMENTS_EDIT_CHECKED]: (state, { policyChecked }) => ({
    ...state,
    editFile: validateFile({
      ...state.editFile,
      policyChecked,
      hasChanges: true,
    }),
  }),
  [SAVE_DOCUMENTS_FILE_SUCCESS]: (state) => {
    const docs = state.documents.map((folder) => {
      return {
        ...folder,
        files: folder.files.map((file) => {
          if (file.id === state.editFile.id) {
            return {
              ...file,
              ...state.editFile,
            };
          }
          return file;
        }),
      };
    });
    return {
      ...state,
      documents: docs,
      editFile: null,
    };
  },
  [BEGIN_DOCUMENTS_ADD_FILE]: (state, { folder }) => ({
    ...state,
    editFile: validateFile({
      new: true,
      folder: folder,
    }),
  }),
  [SAVE_DOCUMENTS_FILE]: (state) => ({
    ...state,
    editFile: {
      ...state.editFile,
      isSaving: true,
      progress: 0,
    },
  }),
  [SAVE_DOCUMENTS_FILE_PROGRESS]: (state, { progress }) => ({
    ...state,
    editFile: {
      ...state.editFile,
      isSaving: true,
      progress,
    },
  }),
  [SAVE_DOCUMENTS_FILE_SUCCESS]: (state) => ({
    ...state,
    editFile: null,
  }),
  [BEGIN_DOCUMENTS_DELETE_FILE]: (state, { fileId }) => ({
    ...state,
    editFile: null,
    deleteFile: {
      fileId,
    },
  }),
  [CANCEL_DOCUMENTS_DELETE_FILE]: (state) => ({
    ...state,
    deleteFile: null,
  }),
  [DELETE_DOCUMENTS_FILE]: (state) => ({
    ...state,
    deleteFile: {
      ...state.deleteFile,
      isDeleting: true,
    },
  }),
  [DELETE_DOCUMENTS_FILE_SUCCESS]: (state) => ({
    ...state,
    deleteFile: null,
  }),
  [DELETE_DOCUMENTS_FILE_ERROR]: (state, { error }) => ({
    ...state,
    deleteFile: null,
    error,
  }),
  [SAVE_DOCUMENTS_FILE_ERROR]: (state, { error }) => ({
    ...state,
    editFile: null,
    error,
  }),
  [BEGIN_DOCUMENTS_EDIT_FOLDER]: (state, { folder }) => ({
    ...state,
    editFolder: validateFolder({
      ...folder,
    }),
  }),
  [BEGIN_DOCUMENTS_ADD_FOLDER]: (state) => ({
    ...state,
    editFolder: {
      new: true,
    },
  }),
  [CANCEL_DOCUMENTS_EDIT_FOLDER]: (state) => ({
    ...state,
    editFolder: null,
  }),
  [SET_DOCUMENTS_EDIT_FOLDER_NAME]: (state, { folderName }) => ({
    ...state,
    editFolder: validateFolder({
      ...state.editFolder,
      name: folderName,
      hasChanges: true,
    }),
  }),
  [SET_DOCUMENTS_EDIT_FOLDER_DESC]: (state, { description }) => ({
    ...state,
    editFolder: validateFolder({
      ...state.editFolder,
      description: description,
      hasChanges: true,
    }),
  }),
  [SAVE_DOCUMENTS_EDIT_FOLDER]: (state) => ({
    ...state,
    editFolder: {
      ...state.editFolder,
      isSaving: true,
    },
  }),
  [SAVE_DOCUMENTS_EDIT_FOLDER_SUCCESS]: (state) => ({
    ...state,
    editFolder: null,
  }),
  [SAVE_DOCUMENTS_EDIT_FOLDER_ERROR]: (state, { error }) => ({
    ...state,
    editFolder: null,
    error,
  }),
  [SET_DOCUMENTS_COMPANY_ID]: (state, { companyId }) => {
    const selectedCompanyId = companyId;
    const filteredVesselGroups = filter(state.vesselGroups, {
      companyId: selectedCompanyId,
    });
    return {
      ...state,
      filteredVesselGroups,
      ...resetGroupFilter(
        filteredVesselGroups,
        state.vesselGroupVessels,
        state.vessels
      ),
      selectedCompanyId,
    };
  },
  [FETCH_DOCUMENTS_FILES]: (state) => ({
    ...state,
    isLoadingDocuments: true,
  }),
  [FETCH_DOCUMENTS_FILES_SUCCESS]: (state, { data }) => {
    const expandedFolders = filter(get(state, "documents", []), {
      expanded: false,
    }).map((folder) => folder.id);
    const docs = data.map((folder) => ({
      ...folder,
      expanded: expandedFolders.includes(folder.id) !== true,
    }));
    return {
      ...state,
      isLoadingDocuments: false,
      documents: docs,
    };
  },
  [FETCH_DOCUMENTS_FILES_ERROR]: (state, { error }) => ({
    ...state,
    isLoadingDocuments: false,
    documents: [],
    error,
  }),
  [FETCH_DOCUMENTS_DATE_RANGE_SUCCESS]: (state, { data }) => ({
    ...state,
    dateLimits: {
      from: new Date(data.from || Date.now()).toISOString(),
      to: new Date(data.to || Date.now()).toISOString(),
    },
  }),
  [FETCH_DOCUMENTS_FILE_TYPES_SUCCESS]: (state, { data }) => ({
    ...state,
    fileTypes: data,
  }),
  [FETCH_DOCUMENTS_FOLDERS_SUCCESS]: (state, { folders }) => ({
    ...state,
    folders,
  }),
  [FETCH_USER_DATA_SUCCESS]: (state, { data }) => {
    let userCompany = {
      id: data.userDetails.companyId,
      name: data.userDetails.companyName,
    };
    let companies = unionBy(
      uniqBy(data.groups, "companyId").map((group) => ({
        id: group.companyId,
        name: group.companyName,
      })),
      [userCompany],
      "id"
    );
    let selectedCompanyId = userCompany.id;
    let filteredVesselGroups = filter(data.groups, {
      companyId: selectedCompanyId,
    });
    return {
      ...state,
      vessels: data.vessels,
      vesselGroupVessels: data.vesselGroupVessels,
      vesselGroups: data.groups,
      filteredVesselGroups,
      ...resetGroupFilter(
        filteredVesselGroups,
        data.vesselGroupVessels,
        data.vessels
      ),
      companies,
      selectedCompanyId,
    };
  },
  [CLEAR_DOCUMENTS_ERRORS]: (state) => ({
    ...state,
    error: null,
  }),
});
