import { filter, isNil, map, mapValues } from "lodash";

export const convertObjToFields = (obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    return {
      ...accumulator,
      [key]: {
        value: obj[key],
      },
    };
  }, {});
};

export const applyObjToFieldValues = (fieldObj, obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    return {
      ...accumulator,
      [key]: {
        ...accumulator[key],
        value: obj[key],
      },
    };
  }, fieldObj);
};

export const validateFields = (fields, context = null) => {
  return Object.entries(fields).reduce((acc, [key, val]) => {
    if (val.validation) {
      let isValid = true;
      let validationMessage = "";
      const validations = val.validation.map((v) => v(val.value, context));
      const validationLabel = filter(
        map(validations, "validationLabel"),
        (v) => !isNil(v)
      ).join(", ");
      if (validations.some((v) => v.isValid === false)) {
        isValid = false;
        validationMessage = filter(
          map(validations, "validationMessage"),
          (v) => !isNil(v)
        ).join(", ");
      }
      acc[key] = {
        ...val,
        isValid,
        validationMessage,
        validationLabel,
      };
    } else {
      acc[key] = {
        ...val,
        isValid: true,
      };
    }

    return acc;
  }, {});
};

export const checkFormValidation = (form) => {
  return {
    ...form,
    ...Object.entries(form.fields).reduce(
      (accumulator, [, field]) => {
        if (!form.isUpdate || field.hasChanges) {
          accumulator.hasChanges = true;
        }

        if (field.isValid === false && accumulator.isValid === true) {
          accumulator.isValid = false;
        }

        return accumulator;
      },
      { hasChanges: false, isValid: true }
    ),
  };
};

export const convertFieldsToKeyValues = (form) =>
  mapValues(form.fields, "value");

export const addCustomValidationProperties = (field) => {
  if (!field) {
    return {};
  }
  const validationAttr = {
    customValidation: true,
  };

  if (field.isValid !== undefined) {
    validationAttr.isValid = field.isValid;
  }

  if (field.validationMessage !== undefined) {
    validationAttr.validationMessage = field.validationMessage;
  }

  if (field.validationLabel !== undefined) {
    validationAttr.validationLabel = field.validationLabel;
  }

  return validationAttr;
};
