import React from "react";
import styles from "./Triangle.css";
import { Direction } from "../../../common/direction";

interface Props {
  direction: Direction;
}

export const Triangle = ({ direction }: Props) => {
  return (
    <div className={`${styles.triangle} ${classNameDirection[direction]}`} />
  );
};

const classNameDirection: Record<Direction, string> = {
  [Direction.HighToLow]: styles.triangleDown,
  [Direction.LowToHigh]: styles.triangleUp,
};
