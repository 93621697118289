import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { get, isEmpty, pick, sortBy } from "lodash";
import { checkFormValidation, convertObjToFields } from "../../../common/forms";

const actions = vesselConfig.vesselPerformanceIndicators;
const defaultFields = {
  performanceIndicators: undefined,
  minValue: undefined,
  maxValue: undefined,
};

const initialState = {
  data: [],
  performanceIndicators: [],
};
const initialForm = {
  isVisible: false,
  isLoading: false,
  isSaving: false,
  hasChanges: false,
  isDeleting: false,
  fields: convertObjToFields(defaultFields),
};

export default createReducer(initialState, {
  [actions.FETCH]: (state, { vesselId }) => ({
    ...state,
    data: [],
    performanceIndicators: [],
    isLoading: true,
    vesselId,
  }),
  [actions.FETCH_SUCCESS]: (
    state,
    { performanceIndicators, vesselPerformanceIndicators }
  ) => ({
    ...state,
    data: sortBy(vesselPerformanceIndicators, (x) => x.label),
    performanceIndicators,
    isLoading: false,
  }),
  [actions.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error: get(error, "message", error),
  }),
  [actions.EDIT]: (state, { id }) => ({
    ...state,
    data: state.data.map((vpi) => {
      if (vpi.id === id) {
        const performanceIndicators = [
          {
            performanceIndicatorId: vpi.performanceIndicatorId,
            minValue: vpi.minValue,
            maxValue: vpi.maxValue,
          },
        ];
        return {
          ...vpi,
          performanceIndicators,
          form: {
            fields: convertObjToFields(
              pick(
                { ...vpi, performanceIndicators },
                Object.keys(defaultFields)
              )
            ),
            isVisible: true,
            showMinMaxValue: true,
          },
        };
      } else {
        return vpi;
      }
    }),
  }),
  [actions.NEW]: (state) => {
    return {
      ...state,
      data: [
        {
          ...defaultFields,
          form: {
            ...initialForm,
            fields: convertObjToFields(defaultFields),
            isVisible: true,
            isNew: true,
          },
        },
        ...state.data,
      ],
    };
  },
  [actions.CANCEL_EDIT]: (state, { id }) => {
    return {
      ...state,
      data: state.data
        .filter((x) => x.id)
        .map((vpi) => {
          if (vpi.id === id) {
            return {
              ...vpi,
              form: initialForm,
            };
          } else {
            return vpi;
          }
        }),
    };
  },
  [actions.INPUT_CHANGED]: (state, { id, name, value, valid, message }) => ({
    ...state,
    data: state.data.map((vpi) => {
      if (vpi.id === id) {
        const isPerformanceIndicatorChanged = name === "performanceIndicators";
        const field = get(vpi.form.fields, name);
        const minMaxValues =
          isPerformanceIndicatorChanged &&
          getDefaultMinMaxValues(state.performanceIndicators, value);

        let fields = {
          ...vpi.form.fields,
          [name]: {
            ...field,
            isValid: valid !== undefined ? valid : !isEmpty(value),
            message: message || "",
            hasChanges: true,
          },
          ...minMaxValues,
        };

        if (isPerformanceIndicatorChanged) {
          fields.performanceIndicators.value = value.map((pi) => ({
            performanceIndicatorId: pi.id,
            minValue: pi.minValue,
            maxValue: pi.maxValue,
          }));
        } else {
          fields[name].value = value;
        }

        return {
          ...vpi,
          form: checkFormValidation({
            ...vpi.form,
            fields,
            showMinMaxValue: fields.performanceIndicators.value.length === 1,
          }),
        };
      } else {
        return vpi;
      }
    }),
  }),
  [actions.SAVE_SUCCESS]: (state, { isNew, data }) => {
    const updatedList = isNew
      ? state.data.flatMap((x) => (!x.id ? data : x))
      : state.data.map((x) => (x.id === data.id ? data : x));

    return {
      ...state,
      data: sortBy(updatedList, "label"),
    };
  },
  [actions.SAVE_ERROR]: (state, { id, error }) => {
    return {
      ...state,
      data: state.data.map((vpi) =>
        vpi.id === id
          ? {
              ...vpi,
              form: {
                ...vpi.form,
                isLoading: false,
                error: get(error, "message", error),
              },
            }
          : vpi
      ),
    };
  },
  [actions.DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vpi) => {
        if (vpi.id === id) {
          return {
            ...vpi,
            form: {
              ...vpi.form,
              isDeleting: true,
            },
          };
        } else {
          return vpi;
        }
      }),
    ],
  }),
  [actions.CANCEL_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vpi) => {
        if (vpi.id === id) {
          return {
            ...vpi,
            form: {
              ...vpi.form,
              isDeleting: false,
            },
          };
        } else {
          return vpi;
        }
      }),
    ],
  }),
  [actions.CONFIRM_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vpi) => {
        if (vpi.id === id) {
          return {
            ...vpi,
            form: {
              ...vpi.form,
              isLoading: true,
            },
          };
        } else {
          return vpi;
        }
      }),
    ],
  }),
  [actions.CONFIRM_DELETE_SUCCESS]: (state, { id }) => ({
    ...state,
    data: [...state.data.filter((vpi) => vpi.id !== id)],
  }),
  [actions.CONFIRM_DELETE_ERROR]: (state, { id, error }) => ({
    ...state,
    data: [
      ...state.data.map((vpi) => {
        if (vpi.id === id) {
          return {
            ...vpi,
            form: {
              ...vpi.form,
              isLoading: false,
              error,
            },
          };
        } else {
          return vpi;
        }
      }),
    ],
  }),
});

const getDefaultMinMaxValues = (
  performanceIndicators,
  selectedPerformanceIndicators
) => {
  const performanceIndicator = performanceIndicators.find(
    (x) => x.id === selectedPerformanceIndicators[0]?.id
  );
  const minValue =
    selectedPerformanceIndicators.length === 1
      ? get(performanceIndicator, "minValue")
      : undefined;
  const maxValue =
    selectedPerformanceIndicators.length === 1
      ? get(performanceIndicator, "maxValue")
      : undefined;

  return {
    minValue: {
      value: minValue,
      hasChanges: true,
    },
    maxValue: {
      value: maxValue,
      hasChanges: true,
    },
  };
};
