export const convertUnit = (
  fromUnit: string,
  toUnit: string,
  value: number,
  density: number
): number | null => {
  if (!value) {
    return value;
  }

  if (fromUnit === toUnit) {
    return value;
  }

  let convertedUnit = convertSailingPerformanceUnit(
    fromUnit,
    toUnit,
    value,
    density
  );
  if (convertedUnit || convertedUnit === null) {
    return convertedUnit;
  }

  convertedUnit = convertPassivePerformanceMassUnit(
    fromUnit,
    toUnit,
    value,
    density
  );
  if (convertedUnit || convertedUnit === null) {
    return convertedUnit;
  }

  convertedUnit = convertPassivePerformanceVolumeUnit(
    fromUnit,
    toUnit,
    value,
    density
  );
  if (convertedUnit || convertedUnit === null) {
    return convertedUnit;
  }

  convertedUnit = convertFuelUnit(fromUnit, toUnit, value, density);
  if (convertedUnit || convertedUnit === null) {
    return convertedUnit;
  }

  convertedUnit =
    convertSpeedOverGroundUnit(fromUnit, toUnit, value) ||
    convertPowerUsageUnit(fromUnit, toUnit, value) ||
    convertWindUnit(fromUnit, toUnit, value);

  return convertedUnit === undefined ? -1 : convertedUnit;
};

type ConversionResult = number | null | undefined;

function convertSailingPerformanceUnit(
  fromUnit: string,
  toUnit: string,
  value: number,
  density: number
): ConversionResult {
  if (fromUnit === "kg/nm") {
    if (toUnit === "L/nm") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value / density;
    }
  }

  if (fromUnit === "L/nm") {
    if (toUnit === "kg/nm") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value * density;
    }
  }

  if (fromUnit === "m/s") {
    if (toUnit === "knots") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value * density;
    }
  }

  return undefined;
}

function convertPassivePerformanceVolumeUnit(
  fromUnit: string,
  toUnit: string,
  value: number,
  density: number
): ConversionResult {
  if (fromUnit === "L/h") {
    if (toUnit === "kg/h") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value * density;
    }
    if (toUnit === "t/day") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value * density * 0.024;
    }
    if (toUnit === "m3/day") {
      return value * 0.024;
    }
  }

  return undefined;
}

function convertPassivePerformanceMassUnit(
  fromUnit: string,
  toUnit: string,
  value: number,
  density: number
): ConversionResult {
  if (fromUnit === "kg/h") {
    if (toUnit === "L/h") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value / density;
    }
    if (toUnit === "t/day") {
      return value * 0.024;
    }
    if (toUnit === "m3/day") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return (value * 0.024) / density;
    }
  }

  return undefined;
}

function convertFuelUnit(
  fromUnit: string,
  toUnit: string,
  value: number,
  density: number
): ConversionResult {
  if (fromUnit === "L") {
    if (toUnit === "kg") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value * density;
    }
  }

  if (fromUnit === "kg") {
    if (toUnit === "l") {
      if (!density) {
        console.error("Density is not set");
        return null;
      }
      return value / density;
    }
  }

  return undefined;
}

function convertSpeedOverGroundUnit(
  fromUnit: string,
  toUnit: string,
  value: number
): ConversionResult {
  if (fromUnit === "kn") {
    if (toUnit === "km/h") {
      return value * 1.852;
    }
    if (toUnit === "m/s") {
      return value * 0.5144;
    }
  }

  if (fromUnit === "m/s") {
    if (toUnit === "km/h") {
      return value * 3.6;
    }
    if (toUnit === "kn") {
      return value * 1.9438;
    }
  }

  return undefined;
}

function convertPowerUsageUnit(
  fromUnit: string,
  toUnit: string,
  value: number
): ConversionResult {
  if (fromUnit === "kW") {
    if (toUnit === "bhp") {
      return value * 1.341;
    }
  }

  return undefined;
}

function convertWindUnit(
  fromUnit: string,
  toUnit: string,
  value: number
): ConversionResult {
  if (fromUnit === "kN") {
    if (toUnit === "t") {
      return value * 0.1019;
    }
  }

  if (fromUnit === "W") {
    if (toUnit === "kW") {
      return value * 1000;
    }
  }

  return undefined;
}

export class TimeZone {
  utcOffset: number;
  name: string;

  constructor(utcOffset: number, name: string) {
    this.utcOffset = utcOffset;
    this.name = name;
  }
}

export function getTimeZones(): TimeZone[] {
  // Generates a set of UTC offsets.
  const result = [];
  for (let i = -12; i < 15; i++) {
    const prefix = i > 0 ? "+" : "-";
    const name = i === 0 ? "UTC" : `UTC${prefix}${Math.abs(i)}`;
    result.push(new TimeZone(i, name));
  }
  return result;
}
