import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { get, sortBy } from "lodash";
import {
  applyObjToFieldValues,
  checkFormValidation,
  validateFields,
} from "../../../common/forms";
import {
  convertMetadataToFields,
  getMetadataFields,
  getUpdatedMetadataFormFields,
} from "../../../common/jsonMetadata";
import { from } from "../../../common/dates";
import {
  isNotZero,
  isPositiveInteger,
  isPositiveNumber,
  isRequired,
} from "../../../common/forms.validators";
import {
  FUEL_TYPE_IDS,
  ITEM_TYPES,
  METRICS,
  VESSEL_ITEM_CATEGORY,
} from "../../../config";

function validationContext(itemId, state) {
  return { itemId, items: state.data };
}

function isUniqueRemoteItemName(value, { itemId, items }) {
  const trimmedLc = (value || "").toLocaleLowerCase().trim();
  const isValid = items
    .filter((x) => x.id !== itemId)
    .every((x) => x.remoteItemName.toLocaleLowerCase() !== trimmedLc);
  return {
    isValid: isValid,
    validationMessage: isValid ? null : "Must be unique",
  };
}

const getNewFieldValueOrElseExistingValue = (
  fieldName,
  nameOfUpdatedField,
  updatedValue,
  form
) =>
  nameOfUpdatedField === fieldName
    ? updatedValue
    : form.fields[fieldName]?.value;

// There are many engines that have category unset, even though it is required to set it in frontend.
const mainEngineCategories = [
  VESSEL_ITEM_CATEGORY.ENGINE_MAIN,
  VESSEL_ITEM_CATEGORY.UNSET,
];

const isUniqueAssetNameIndex = (value, { itemId, items }) => {
  const currentItemFields = items.find((item) => item.id === itemId)?.form
    ?.fields;

  // if currentItemFields is undefined, fields have not yet been initialized, and we do not need to validate
  if (!currentItemFields) return { isValid: true, validationMessage: null };

  const itemsOfSameTypeAndCategory = getItemsOfSameTypeAndCategory(
    items,
    currentItemFields.itemTypeId.value,
    currentItemFields.category?.value || VESSEL_ITEM_CATEGORY.UNSET
  );
  const isValid = itemsOfSameTypeAndCategory
    .filter((x) => x.id !== itemId)
    .every((x) => x.assetNameIndex?.toString() !== value);
  return {
    isValid: isValid,
    validationMessage: isValid
      ? null
      : "Must be unique within items of same type and category",
  };
};

const getItemsOfSameTypeAndCategory = (items, itemTypeId, category) =>
  items.filter(
    (item) =>
      item.itemTypeId === itemTypeId &&
      (item.category === category ||
        [item.category, category].every((x) =>
          mainEngineCategories.includes(x)
        ))
  );

const isAssetNameRequired = (
  itemTypeId,
  hasParentVesselItem,
  isNew,
  category = undefined
) => {
  if (itemTypeId === ITEM_TYPES.ENGINE && (!isNew || category)) {
    return true;
  }
  if (
    itemTypeId === ITEM_TYPES.SHORE_CONNECTION ||
    itemTypeId === ITEM_TYPES.BATTERY ||
    itemTypeId === ITEM_TYPES.BOILER ||
    itemTypeId === ITEM_TYPES.THRUSTER ||
    itemTypeId === ITEM_TYPES.PROPULSOR
  ) {
    return true;
  }
  if (itemTypeId === ITEM_TYPES.CONSUMER) {
    return !!hasParentVesselItem;
  }
  return false;
};

const assetNameIndexValidation = [
  isPositiveInteger,
  isNotZero,
  isRequired,
  isUniqueAssetNameIndex,
];

const getNextAssetNameIndex = (items, itemTypeId, category) => {
  const existingAssetNameIndices = getItemsOfSameTypeAndCategory(
    items,
    itemTypeId,
    category
  )
    .map((item) => item.assetNameIndex)
    .filter((assetNameIndex) => isFinite(assetNameIndex));

  return existingAssetNameIndices.length > 0
    ? Math.max(...existingAssetNameIndices) + 1
    : 1;
};

const getNextViewPosition = (items, itemTypeId) => {
  const existingViewPositions = items
    .filter((item) => item.itemTypeId === itemTypeId)
    .map((item) => item.viewPosition)
    .filter((viewPosition) => isFinite(viewPosition));

  return existingViewPositions.length > 0
    ? Math.max(...existingViewPositions) + 1
    : 1;
};

const getRemoteItemName = (itemTypeId, category, assetNameIndex) => {
  // With AssetNameIndex
  if (itemTypeId === ITEM_TYPES.ENGINE) {
    if (category) {
      return category === VESSEL_ITEM_CATEGORY.ENGINE_AUX
        ? `aux${assetNameIndex}`
        : `me${assetNameIndex}`;
    }
    return "";
  }
  if (itemTypeId === ITEM_TYPES.SHORE_CONNECTION) return `sc${assetNameIndex}`;
  if (itemTypeId === ITEM_TYPES.CONSUMER)
    return assetNameIndex ? `consumer${assetNameIndex}` : "";
  if (itemTypeId === ITEM_TYPES.BATTERY) return `battery${assetNameIndex - 1}`;
  if (itemTypeId === ITEM_TYPES.BOILER) return `boiler${assetNameIndex}`;
  if (itemTypeId === ITEM_TYPES.THRUSTER) return `thruster${assetNameIndex}`;
  if (itemTypeId === ITEM_TYPES.PROPULSOR) return `propulsor${assetNameIndex}`;

  // Without AssetNameIndex
  if (itemTypeId === ITEM_TYPES.HULL) return "hull";

  // The rest are mostly for health management and remoteItem name does not really matter
  // Therefore we return empty string and the user has to input the RemoteItemName manually
  return "";
};

const defaultVesselItem = {
  id: {
    value: undefined,
  },
  itemTypeId: {
    value: "",
    validation: [isRequired],
  },
  remoteItemName: {
    value: "",
    validation: [isRequired, isUniqueRemoteItemName],
  },
  name: {
    value: "",
    validation: [isRequired],
  },
  description: {
    value: "",
  },
  viewPosition: {
    value: null,
    validation: [isPositiveInteger],
  },
  initialRunningHours: {
    value: 0,
    validation: [isPositiveNumber],
  },
  fuelTypes: {
    value: [],
  },
};

const initialState = {
  data: [],
  itemTypes: [],
  fuelTypes: [],
  hasChanges: false,
  isLoading: false,
  fuelTypeUnitKg: null,
};
const initialForm = {
  isVisible: true,
  hasChanges: false,
  fields: defaultVesselItem,
  isDeleting: false,
  metadataFields: [],
  isLoading: false,
};
const initialMetrics = {
  hasChanges: false,
  data: [],
};

export default createReducer(initialState, {
  [vesselConfig.vesselItems.FETCH]: (_state, { vesselId }) => ({
    ...initialState,
    isLoading: true,
    vesselId,
  }),
  [vesselConfig.vesselItems.FETCH_SUCCESS]: (
    state,
    { vesselItems, itemTypes, fuelTypes, vesselDetails }
  ) => {
    return {
      ...state,
      data: sortBy(vesselItems, "itemType", "name"),
      itemTypes,
      fuelTypes,
      isLoading: false,
      fuelTypeUnitKg: {
        [FUEL_TYPE_IDS.MDO]: vesselDetails.mdoUnitKgPerHour,
        [FUEL_TYPE_IDS.HFO]: vesselDetails.hfoUnitKgPerHour,
        [FUEL_TYPE_IDS.MGO]: vesselDetails.mgoUnitKgPerHour,
        [FUEL_TYPE_IDS.Methanol]: vesselDetails.methanolUnitKgPerHour,
        [FUEL_TYPE_IDS.ULSFO]: vesselDetails.ulsfoUnitKgPerHour,
        [FUEL_TYPE_IDS.VLSFO]: vesselDetails.vlsfoUnitKgPerHour,
        [FUEL_TYPE_IDS.LNG]: true,
      },
    };
  },
  [vesselConfig.vesselItems.FETCH_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }),
  [vesselConfig.vesselItems.BEGIN_EDIT]: (state, { id }) => {
    return {
      ...state,
      data: [
        ...state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              parentVesselItems: getParentOptions(state, item.id),
              form: {
                ...initialForm,
                fields: validateFields(
                  defaultVesselItem,
                  validationContext(id, state)
                ),
                isLoading: true,
              },
              metrics: {
                ...initialMetrics,
              },
            };
          } else {
            return item;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.BEGIN_EDIT_SUCCESS]: (
    state,
    { id, data, metrics }
  ) => {
    data.ehmStartTime = data.ehmStartTime && from(data.ehmStartTime);

    let metadataFields = getMetadataFields(
      get(data, "itemTypeId"),
      null,
      state.fuelTypeUnitKg
    );

    const vesselItemFields = applyObjToFieldValues(initialForm.fields, data);
    isAssetNameRequired(
      vesselItemFields.itemTypeId.value,
      vesselItemFields.parentVesselItemId?.value
    );
    return {
      ...state,
      data: [
        ...state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              form: {
                ...initialForm,
                fields: validateFields(
                  {
                    ...vesselItemFields,
                    // Even though assetNameIndex is already populated in vesselItemsFields, we need to overwrite it to add validation.
                    ...(isAssetNameRequired(
                      vesselItemFields.itemTypeId.value,
                      vesselItemFields.parentVesselItemId?.value,
                      false
                    ) && {
                      assetNameIndex: {
                        ...vesselItemFields.assetNameIndex,
                        validation: assetNameIndexValidation,
                      },
                    }),
                    ...convertMetadataToFields(
                      metadataFields,
                      data.jsonMetadata
                    ),
                  },
                  validationContext(id, state)
                ),
                metadataFields,
                isLoading: false,
              },
              metrics: {
                ...item.metrics,
                data: metrics.map((metric) => ({
                  ...metric,
                  isVisible: isMetricVisible(
                    metric.metricId,
                    data.fuelTypes,
                    state.fuelTypeUnitKg
                  ),
                })),
              },
            };
          } else {
            return item;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.BEGIN_EDIT_ERROR]: (state, { id, error }) => ({
    ...state,
    data: [
      ...state.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            form: {
              ...item.form,
              isLoading: false,
              error,
            },
          };
        } else {
          return item;
        }
      }),
    ],
  }),
  [vesselConfig.vesselItems.CANCEL_EDIT]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data
        .filter((x) => x.id !== undefined)
        .map((vi) => {
          if (vi.id === id) {
            return {
              ...vi,
              form: {
                ...initialForm,
                isVisible: false,
              },
            };
          } else {
            return vi;
          }
        }),
    ],
  }),
  [vesselConfig.vesselItems.METRIC_CHANGED]: (
    state,
    { id, metricId, setting, value }
  ) => {
    return {
      ...state,
      data: [
        ...state.data.map((vi) => {
          if (vi.id === id) {
            return {
              ...vi,
              metrics: {
                ...vi.metrics,
                hasChanges: true,
                data: vi.metrics.data.map((vim) =>
                  vim.metricId === metricId
                    ? {
                        ...vim,
                        [setting]: value,
                      }
                    : vim
                ),
              },
            };
          } else {
            return vi;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.INPUT_CHANGED]: (state, { id, name, value }) => {
    return {
      ...state,
      data: [
        ...state.data.map((vi) => {
          if (vi.id === id) {
            let form = { ...vi.form };
            let metrics = { ...vi.metrics };

            if (name === "itemTypeId") {
              const metadataFields = [
                ...getMetadataFields(value, state.data, state.fuelTypeUnitKg),
              ];

              form = getUpdatedMetadataFormFields(
                vi.form,
                value,
                metadataFields,
                state.fuelTypeUnitKg
              );
              metrics = { ...initialMetrics };
            }

            const field = get(form.fields, name);

            // Get existing or new value for some fields needed for other calculations
            const itemTypeId = getNewFieldValueOrElseExistingValue(
              "itemTypeId",
              name,
              value,
              form
            );
            const parentVesselItemId = getNewFieldValueOrElseExistingValue(
              "parentVesselItemId",
              name,
              value,
              form
            );
            const category = getNewFieldValueOrElseExistingValue(
              "category",
              name,
              value,
              form
            );

            const insertAssetNameIndex =
              !form.fields.assetNameIndex &&
              isAssetNameRequired(
                itemTypeId,
                !!parentVesselItemId,
                !form.fields.id.value,
                category
              );

            const removeAssetNameIndex =
              form.fields.assetNameIndex &&
              !isAssetNameRequired(itemTypeId, !!parentVesselItemId);

            if (removeAssetNameIndex) {
              delete form.fields.assetNameIndex;
            }

            const assetNameIndex = insertAssetNameIndex
              ? getNextAssetNameIndex(
                  state.data,
                  itemTypeId,
                  category || VESSEL_ITEM_CATEGORY.UNSET
                )
              : form.fields.assetNameIndex?.value;

            return {
              ...vi,
              metrics,
              form: checkFormValidation({
                ...form,
                fields: validateFields(
                  {
                    ...form.fields,
                    [name]: {
                      ...field,
                      value: value,
                      hasChanges: true,
                    },
                    ...(insertAssetNameIndex && {
                      assetNameIndex: {
                        value: assetNameIndex,
                        validation: assetNameIndexValidation,
                      },
                    }),
                    ...(!form.fields.remoteItemName?.value &&
                      ["category", "itemTypeId", "parentVesselItemId"].includes(
                        name
                      ) && {
                        remoteItemName: {
                          ...form.fields.remoteItemName,
                          value: getRemoteItemName(
                            itemTypeId,
                            category,
                            assetNameIndex
                          ),
                        },
                      }),
                    ...(!form.fields.viewPosition?.value &&
                      name === "itemTypeId" && {
                        viewPosition: {
                          ...form.fields.viewPosition,
                          value: getNextViewPosition(state.data, itemTypeId),
                        },
                      }),
                    ...(name === "itemTypeId" &&
                      value === ITEM_TYPES.ENGINE && {
                        category: {
                          value: "",
                          validation: [isRequired],
                        },
                      }),
                  },
                  validationContext(id, state)
                ),
              }),
            };
          } else {
            return vi;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.FUEL_TYPES_CHANGED]: (state, { id, value }) => {
    return {
      ...state,
      data: [
        ...state.data.map((vi) => {
          if (vi.id === id) {
            let form = { ...vi.form };
            let metrics = { ...vi.metrics };

            const addedFuelTypes = value.filter(
              (fuelType) => !form.fields.fuelTypes.value.includes(fuelType)
            );
            const metricsToBecomeVisibleAndDisabled = Object.entries(
              fuelConsumptionMetrics
            )
              .filter(
                ([, { isKgMetric, fuelTypeId, isAvgMetric }]) =>
                  addedFuelTypes.includes(fuelTypeId) &&
                  isKgMetric === state.fuelTypeUnitKg[fuelTypeId] &&
                  isAvgMetric
              )
              .map(([metricId]) => metricId);

            const metricsToBecomeVisibleAndEnabled = Object.entries(
              fuelConsumptionMetrics
            )
              .filter(
                ([, { isKgMetric, fuelTypeId, isAvgMetric }]) =>
                  addedFuelTypes.includes(fuelTypeId) &&
                  isKgMetric === state.fuelTypeUnitKg[fuelTypeId] &&
                  !isAvgMetric
              )
              .map(([metricId]) => metricId);

            const removedFuelTypes = form.fields.fuelTypes.value.filter(
              (fuelType) => !value.includes(fuelType)
            );
            const metricsToBecomeInvisible = Object.entries(
              fuelConsumptionMetrics
            )
              .filter(
                ([, { isKgMetric, fuelTypeId }]) =>
                  removedFuelTypes.includes(fuelTypeId) &&
                  isKgMetric === state.fuelTypeUnitKg[fuelTypeId]
              )
              .map(([metricId]) => metricId);

            return {
              ...vi,
              metrics: {
                ...metrics,
                hasChanges: true,
                data: metrics.data.map((metric) => {
                  if (
                    metricsToBecomeVisibleAndDisabled.includes(metric.metricId)
                  ) {
                    return { ...metric, isVisible: true };
                  } else if (
                    metricsToBecomeVisibleAndEnabled.includes(metric.metricId)
                  ) {
                    return {
                      ...metric,
                      showTrend: true,
                      showOperation: true,
                      showOperationDetail: true,
                      showCompare: true,
                      isVisible: true,
                    };
                  } else if (
                    metricsToBecomeInvisible.includes(metric.metricId)
                  ) {
                    return {
                      ...metric,
                      showTrend: false,
                      showOperation: false,
                      showOperationDetail: false,
                      showCompare: false,
                      isVisible: false,
                    };
                  } else {
                    return metric;
                  }
                }),
              },
              form: checkFormValidation({
                ...form,
                fields: validateFields(
                  {
                    ...form.fields,
                    fuelTypes: {
                      ...form.fields.fuelTypes,
                      value: value,
                      hasChanges: true,
                    },
                  },
                  validationContext(id, state)
                ),
              }),
            };
          } else {
            return vi;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.CREATE]: (state) => {
    return {
      ...state,
      data: [
        {
          form: {
            ...initialForm,
            fields: validateFields(
              defaultVesselItem,
              validationContext(null, state)
            ),
          },
          metrics: {
            ...initialMetrics,
          },
          parentVesselItems: getParentOptions(state, null),
        },
        ...state.data,
      ],
    };
  },
  [vesselConfig.vesselItems.SAVE]: (state, { id }) => {
    return {
      ...state,
      data: [
        ...state.data.map((vi) => {
          if (vi.id === id) {
            return {
              ...vi,
              form: {
                ...vi.form,
                isLoading: true,
              },
            };
          } else {
            return vi;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.SAVE_SUCCESS]: (state, { isNew, data }) => {
    return {
      ...state,
      data: sortBy(
        state.data.map((vi) => {
          if ((isNew && !vi.id) || vi.id === data.id) {
            return {
              ...data,
            };
          } else {
            return vi;
          }
        }),
        "itemType",
        "name"
      ),
    };
  },
  [vesselConfig.vesselItems.SAVE_ERROR]: (state, { id, error }) => {
    return {
      ...state,
      data: [
        ...state.data.map((vi) => {
          if (vi.id === id) {
            return {
              ...vi,
              form: {
                ...vi.form,
                isLoading: false,
                error,
              },
            };
          } else {
            return vi;
          }
        }),
      ],
    };
  },
  [vesselConfig.vesselItems.DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vi) => {
        if (vi.id === id) {
          return {
            ...vi,
            form: {
              ...vi.form,
              isDeleting: true,
            },
          };
        } else {
          return vi;
        }
      }),
    ],
  }),
  [vesselConfig.vesselItems.CANCEL_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vi) => {
        if (vi.id === id) {
          return {
            ...vi,
            form: {
              ...vi.form,
              isDeleting: false,
            },
          };
        } else {
          return vi;
        }
      }),
    ],
  }),
  [vesselConfig.vesselItems.CONFIRM_DELETE]: (state, { id }) => ({
    ...state,
    data: [
      ...state.data.map((vi) => {
        if (vi.id === id) {
          return {
            ...vi,
            form: {
              ...vi.form,
              isLoading: true,
              isVisible: false,
            },
          };
        } else {
          return vi;
        }
      }),
    ],
  }),
  [vesselConfig.vesselItems.CONFIRM_DELETE_SUCCESS]: (state, { id }) => ({
    ...state,
    data: [...state.data.filter((vi) => vi.id !== id)],
  }),
  [vesselConfig.vesselItems.CONFIRM_DELETE_ERROR]: (state, { id, error }) => ({
    ...state,
    data: [
      ...state.data.map((vi) => {
        if (vi.id === id) {
          return {
            ...vi,
            form: {
              ...vi.form,
              isVisible: true,
              isLoading: false,
              error,
            },
          };
        } else {
          return vi;
        }
      }),
    ],
  }),
});

export const allVesselItems = (state) => state.data || [];

export function getParentOptions(state, vesselItemId) {
  const vesselItems = state.data || [];
  return vesselItems.filter((vi) => vi.id !== vesselItemId);
}

const fuelConsumptionMetrics = {
  [METRICS.METRIC_SUM_MDO_CONSUMPTION]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.MDO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_MDO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.MDO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_MDO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.MDO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_MDO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.MDO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_MGO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.MGO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_MGO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.MGO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_SUM_HFO_CONSUMPTION]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.HFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_HFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.HFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_HFO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.HFO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_HFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.HFO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_SUM_LNG_CONSUMPTION]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.LNG,
    isAvgMetric: false,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_LNG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.LNG,
    isAvgMetric: true,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_METHANOL]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.Methanol,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_METHANOL_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.Methanol,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_ULSFO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.ULSFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_ULSFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.ULSFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_ULSFO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.ULSFO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_ULSFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.ULSFO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_VLSFO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.VLSFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_FUEL_CONSUMPTION_VLSFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.VLSFO,
    isAvgMetric: false,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_VLSFO]: {
    isKgMetric: false,
    fuelTypeId: FUEL_TYPE_IDS.VLSFO,
    isAvgMetric: true,
  },
  [METRICS.METRIC_AVG_FUEL_CONSUMPTION_VLSFO_KG]: {
    isKgMetric: true,
    fuelTypeId: FUEL_TYPE_IDS.VLSFO,
    isAvgMetric: true,
  },
};

const isMetricVisible = (metricId, fuelTypeIds, fuelTypeUnitKg) => {
  if (!(metricId in fuelConsumptionMetrics)) {
    return true;
  }
  const { isKgMetric, fuelTypeId } = fuelConsumptionMetrics[metricId];
  return (
    fuelTypeIds.includes(fuelTypeId) &&
    isKgMetric === fuelTypeUnitKg[fuelTypeId]
  );
};
