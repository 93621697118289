import PropTypes from "prop-types";
import React from "react";
import styles from "./TrendCategoryItem.css";
import RadioCheckbox from "../../RadioCheck/RadioCheckbox";
import Button from "../../../../common/components/Button";
import CollapseButtonIcon from "../../CollapseButtonIcon/CollapseButtonIcon";
import { renderVesselItem } from "../TrendCategoryItemType/TrendCategoryItemType";
import classNames from "../../../../common/classNames";

export default class TrendCategoryItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showItems: props.showItems,
    };
  }

  showItems() {
    this.setState((state) => ({
      showItems: !state.showItems,
    }));
  }

  render() {
    const {
      name,
      isActive,
      items,
      onToggle,
      onToggleItem,
      showButton,
      childItems,
      onToggleVesselItem,
      onToggleMetric,
      isChildren,
      fullHeight,
    } = this.props;

    const paneStyle = fullHeight ? { height: "calc(100% - 10px)" } : {};

    return (
      <>
        <div
          className={classNames(
            styles.trendCategoryItemContainer,
            isChildren && styles.childrenItems
          )}
          style={paneStyle}
        >
          <div className={styles.trendCategoryHeaderContainer}>
            <RadioCheckbox
              label={name}
              checked={isActive}
              horizontalStretch
              onCheck={onToggle}
            />
            {showButton && (
              <Button
                type={"content"}
                width={"26px"}
                margin={"0 12px 0 0"}
                padding={"0 10px"}
                circle
                clickAction={this.showItems.bind(this)}
              >
                <CollapseButtonIcon
                  showItems={this.state.showItems}
                  height={"10px"}
                  width={"10px"}
                />
              </Button>
            )}
          </div>
          {items &&
            this.state.showItems &&
            items.map((item) => {
              return (
                <div
                  key={item.id}
                  className={styles.trendCategoryMetricContainer}
                >
                  <RadioCheckbox
                    label={item.name}
                    checked={item.isActive}
                    horizontalStretch
                    onCheck={() => onToggleItem(item.id)}
                  />
                </div>
              );
            })}
        </div>
        {childItems &&
          this.state.showItems &&
          childItems.map((i) =>
            renderVesselItem(i, onToggleVesselItem, onToggleMetric, true)
          )}
      </>
    );
  }
}

TrendCategoryItem.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
    })
  ),
  onToggle: PropTypes.func.isRequired,
  onToggleItem: PropTypes.func,
  onToggleVesselItem: PropTypes.func,
  onToggleMetric: PropTypes.func,
  showButton: PropTypes.bool,
  showItems: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
