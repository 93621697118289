import { isNumber } from "lodash";
import {
  FETCH_FLEET_TABLE,
  FETCH_FLEET_TABLE_ERROR,
  FETCH_FLEET_TABLE_SUCCESS,
} from "../actions/action.types";

const createEnabledMetricIndex = (vessels) => {
  if (!vessels.length) {
    return {};
  }

  return vessels.reduce((index, vessel) => {
    vessel.metrics.forEach((m) => {
      if (isNumber(m.value)) {
        index[m.metricName + "_" + m.unit] = true;
      }
    });
    return index;
  }, {});
};

const filterEmptyColumns = (data) => {
  const enabledMetricIndex = createEnabledMetricIndex(data.vessels);

  const vessels = data.vessels.map((vessel) => {
    vessel.metrics = vessel.metrics.filter(
      (metric) => enabledMetricIndex[metric.metricName + "_" + metric.unit]
    );
    return vessel;
  });

  return {
    ...data,
    vessels,
  };
};

const initialState = {
  data: { vessels: [] },
  isLoading: true,
};

const fetchFleetTable = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_FLEET_TABLE:
      return initialState;
    case FETCH_FLEET_TABLE_SUCCESS:
      return {
        ...state,
        data: filterEmptyColumns(action.data),
        isLoading: false,
      };
    case FETCH_FLEET_TABLE_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default fetchFleetTable;
