import React, { useEffect } from "react";
import commonStyles from "../MainDashboard/MainDashboard.css";
import styles from "./FleetCIIScore.css";
import FleetCIIScoreSparkline from "./FleetCIIScoreSparkline";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/components/Loader";
import { fetchCIIScores } from "../../actions/action.fetchCIIScores";
import { organizeScores } from "./CIIScoreOrganizer";
import { createOperationClass } from "../../../common/OperationClass/Factory";
import { OperationClassID } from "../../../common/operation/operationClassID";
import { IOperationClass } from "../../../common/OperationClass/IOperationClass";
import { IOperationMode } from "../../../common/OperationMode/IOperationMode";
import { createOperationMode } from "../../../common/OperationMode/Factory";
import { CiiState, FleetCIIScoreProps } from "./Types";
import { OperationId } from "../../../common/operation/operationId";

const FleetCIIScore: React.FC<FleetCIIScoreProps> = ({
  group,
}: FleetCIIScoreProps) => {
  const ciiScores = useSelector((state: CiiState) => {
    return state.ciiScores;
  });

  function getOperationClassesToInclude(): IOperationClass[] {
    return [
      createOperationClass(OperationClassID.DP),
      createOperationClass(OperationClassID.Transit),
      createOperationClass(OperationClassID.AHTS),
      createOperationClass(OperationClassID.Fishing),
    ];
  }

  function getOperationModesToInclude(): string[] {
    const classes: IOperationClass[] = getOperationClassesToInclude();
    const modeIds = classes
      .map((x: IOperationClass) => {
        return x.getOperationModes();
      })
      .flat();
    const modes = modeIds.map((x: OperationId) => {
      return createOperationMode(x);
    });
    return modes.map((x: IOperationMode) => {
      return x.abbreviation;
    });
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCIIScores(getOperationModesToInclude(), group));
  }, [group]);

  if (ciiScores === undefined || ciiScores.data === undefined) {
    return <Loader text={"Loading"} expand={true} />;
  } else {
    return (
      <div className={styles.fleetCIIScoreActivity}>
        <div className={commonStyles.componentHeader}>Fleet CII Score</div>
        <div className={styles.sparkline}>
          <FleetCIIScoreSparkline data={organizeScores(ciiScores.data)} />
        </div>
      </div>
    );
  }
};

export default FleetCIIScore;
