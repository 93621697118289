import createReducer from "./createReducer";
import { atEndOfMonth, atStartOfMonth, now } from "../common/dates";
import {
  FETCH_ANALYSIS_FILTER,
  FETCH_ANALYSIS_FILTER_SUCCESS,
  FETCH_ANALYSIS_OPERATIONS,
  FETCH_ANALYSIS_OPERATIONS_ERROR,
  FETCH_ANALYSIS_OPERATIONS_SUCCESS,
  FETCH_ANALYSIS_ENGINES,
  FETCH_ANALYSIS_ENGINES_SUCCESS,
  FETCH_ANALYSIS_ENGINES_ERROR,
  FETCH_ANALYSIS_PROPULSORS,
  FETCH_ANALYSIS_PROPULSORS_SUCCESS,
  FETCH_ANALYSIS_PROPULSORS_ERROR,
  FETCH_ANALYSIS_AXIS_TYPES,
  FETCH_ANALYSIS_AXIS_TYPES_SUCCESS,
  FETCH_ANALYSIS_AXIS_TYPES_ERROR,
  FETCH_ANALYSIS_PLOT,
  FETCH_ANALYSIS_PLOT_SUCCESS,
  SET_ANALYSIS_AXIS_VALUE_TYPE,
  SET_ANALYSIS_DATE,
  SET_ANALYSIS_FILTER_VALUE,
  TOGGLE_ANALYSIS_OPERATION,
  TOGGLE_ANALYSIS_ENGINE,
  TOGGLE_ANALYSIS_PROPULSOR,
  SET_ANALYSIS_Z_DOMAIN,
  SET_ANALYSIS_VESSEL,
} from "../actions/action.types";
import { filter, find, includes, get, uniq } from "lodash";
import { roundNumberAt } from "../../common/numbers";

export const valueTypes = [
  {
    id: "Wind",
    label: "Absolute Windspeed",
    unit: "m/s",
  },
  {
    id: "WindHead",
    label: "Head Windspeed",
    unit: "m/s",
  },
  {
    id: "Speed",
    label: "Vessel speed",
    unit: "kts",
  },
  {
    id: "Power",
    label: "Engine power",
    unit: "kW",
  },
  {
    id: "CO2",
    label: "CO2",
    unit: "kg",
  },
  {
    id: "PropPower",
    label: "Propulsion power",
    unit: "kW",
  },
  {
    id: "RunningEngines",
    label: "Number of running engines",
  },
];

export const operationalValueTypes = [
  {
    id: "EngineNumber",
    label: "Engine number",
    unit: "",
  },
  {
    id: "FuelConsumption",
    label: "Fuel Consumption",
    unit: "kg",
  },
  {
    id: "EngineSpeed",
    label: "Engine speed",
    unit: "rpm",
  },
  {
    id: "EnginePower",
    label: "Engine power",
    unit: "kW",
  },
  {
    id: "SFC",
    label: "Specific Fuel Consumption",
    unit: "g/kWh",
  },
];

const initialState = {
  vesselId: null,
  valueTypes: valueTypes,
  operationalValueTypes: operationalValueTypes,
  date: {
    range: [
      atStartOfMonth(now()).toISOString(),
      atEndOfMonth(now()).toISOString(),
    ],
    timeOffset: 0,
    value: now().toISOString(),
    type: "month",
  },
  xAxisType: { id: "Speed", label: "Vessel speed through water", unit: "kn" },
  yAxisType: { id: "Power", label: "Engine power", unit: "kW" },
  zAxisType: find(valueTypes, (x) => x.id === "CO2"),
  analysisType: 0,
  operations: {
    isLoading: false,
    data: [],
  },
  engines: {
    isLoading: false,
    data: [],
  },
  propulsors: {
    isLoading: false,
    data: [],
  },
  axisTypes: {
    isLoading: false,
    data: [],
  },
  plot: {
    isLoading: false,
    data: [],
  },
  zDomain: { min: null, max: null, values: [] },
  filters: {
    Speed: {
      minValue: null,
      maxValue: null,
      values: [],
      isLoading: false,
    },
    RunningEngines: {
      minValue: null,
      maxValue: null,
      values: [],
      isLoading: false,
    },
    Wind: {
      minValue: null,
      maxValue: null,
      values: [],
      isLoading: false,
    },
    PitchEnergyFast: {
      minValue: null,
      maxValue: null,
      values: [],
      isLoading: false,
    },
  },
};

export const getSelectedOperationIds = (operations) => {
  return filter(operations, (x) => x.isSelected === true).map((x) => x.id);
};

export const getSelectedEngineIds = (engines) => {
  return filter(engines, (x) => x.isSelected === true).map((x) => x.id);
};
export const getSelectedPropulsorIds = (propulsors) => {
  return filter(propulsors, (x) => x.isSelected === true).map((x) => x.id);
};

const generateInitialZDomain = (data) => {
  let min = Math.floor(get(data, "valueRange.minZ", 0));
  let max = Math.ceil(get(data, "valueRange.maxZ", 0));

  let values =
    uniq(
      data.data.map((val) => {
        return {
          value: Math.floor(val.z),
        };
      })
    ).sort((a, b) => a.value - b.value) || [];
  return {
    min,
    max,
    values,
  };
};

const parseFilterData = (filterData) => {
  return filterData
    ? {
        minValue: roundNumberAt(2, filterData.minValue),
        maxValue: roundNumberAt(2, filterData.maxValue),
        values: filterData.values
          ? filterData.values.map((v) => ({
              fromValue: roundNumberAt(2, v.min),
              toValue: roundNumberAt(2, v.max),
              barValue: v.numberOfEntries,
            }))
          : [],
      }
    : {};
};

export default createReducer(initialState, {
  [SET_ANALYSIS_VESSEL]: (state, { vesselId }) => {
    return {
      ...initialState,
      date: state.date,
      vesselId,
    };
  },
  [SET_ANALYSIS_DATE]: (state, { date }) => {
    return {
      ...state,
      date: date,
    };
  },
  [FETCH_ANALYSIS_OPERATIONS]: (state) => {
    return {
      ...state,
      operations: {
        isLoading: true,
        data: state.operations.data || [],
      },
    };
  },
  [FETCH_ANALYSIS_OPERATIONS_SUCCESS]: (state, { data }) => {
    const operations = data.map((operation) => {
      const prevOperation = state.operations.data.find(
        (o) => o.id === operation.id
      );
      if (prevOperation) {
        return {
          ...operation,
          isSelected: prevOperation.isSelected,
        };
      }
      return { ...operation, isSelected: true };
    });
    return {
      ...state,
      operations: {
        isLoading: false,
        data: operations,
      },
    };
  },
  [FETCH_ANALYSIS_OPERATIONS_ERROR]: (state, { error }) => {
    return {
      ...state,
      operations: {
        isLoading: false,
        error: error,
      },
    };
  },
  [FETCH_ANALYSIS_ENGINES]: (state) => {
    return {
      ...state,
      engines: {
        isLoading: true,
        data: state.engines.data || [],
      },
    };
  },
  [FETCH_ANALYSIS_ENGINES_SUCCESS]: (state, { data }) => {
    const engines = data.map((engine) => {
      const prevEngines = state.engines.data.find((o) => o.id === engine.id);
      if (prevEngines) {
        return {
          ...engine,
          isSelected: prevEngines.isSelected,
        };
      }
      return { ...engine, isSelected: true };
    });
    return {
      ...state,
      engines: {
        isLoading: false,
        data: engines,
      },
    };
  },
  [FETCH_ANALYSIS_ENGINES_ERROR]: (state, { error }) => {
    return {
      ...state,
      engines: {
        isLoading: false,
        error: error,
      },
    };
  },
  [FETCH_ANALYSIS_PROPULSORS]: (state) => {
    return {
      ...state,
      propulsors: {
        isLoading: true,
        data: state.propulsors.data || [],
      },
    };
  },
  [FETCH_ANALYSIS_PROPULSORS_SUCCESS]: (state, { data }) => {
    const propulsors = data.map((propulsor) => {
      const prevPropulsor = state.propulsors.data.find(
        (o) => o.id === propulsor.id
      );
      if (prevPropulsor) {
        return {
          ...propulsor,
          isSelected: prevPropulsor.isSelected,
        };
      }
      return { ...propulsor, isSelected: true };
    });
    return {
      ...state,
      propulsors: {
        isLoading: false,
        data: propulsors,
      },
    };
  },
  [FETCH_ANALYSIS_PROPULSORS_ERROR]: (state, { error }) => {
    return {
      ...state,
      propulsors: {
        isLoading: false,
        error: error,
      },
    };
  },
  [FETCH_ANALYSIS_AXIS_TYPES]: (state) => {
    return {
      ...state,
      axisTypes: {
        isLoading: true,
        data: state.axisTypes.data || [],
      },
    };
  },
  [FETCH_ANALYSIS_AXIS_TYPES_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      axisTypes: {
        isLoading: false,
        data,
      },
    };
  },
  [FETCH_ANALYSIS_AXIS_TYPES_ERROR]: (state, { error }) => {
    return {
      ...state,
      axisTypes: {
        isLoading: false,
        error: error,
      },
    };
  },
  [TOGGLE_ANALYSIS_OPERATION]: (state, { operationId }) => {
    const operations = state.operations.data.map((operation) => {
      if (operation.id !== operationId) return operation;
      return {
        ...operation,
        isSelected: !operation.isSelected,
      };
    });
    return {
      ...state,
      operations: {
        data: operations,
      },
      plot: {
        ...state.plot,
        isLoading: true,
      },
    };
  },
  [TOGGLE_ANALYSIS_ENGINE]: (state, { engineId }) => {
    const engines = state.engines.data.map((engine) => {
      if (engine.id !== engineId) return engine;
      return {
        ...engine,
        isSelected: !engine.isSelected,
      };
    });
    return {
      ...state,
      engines: {
        data: engines,
      },
    };
  },
  [TOGGLE_ANALYSIS_PROPULSOR]: (state, { propulsorId }) => {
    const propulsors = state.propulsors.data.map((propulsor) => {
      if (propulsor.id !== propulsorId) return propulsor;
      return {
        ...propulsor,
        isSelected: !propulsor.isSelected,
      };
    });
    return {
      ...state,
      propulsors: {
        data: propulsors,
      },
    };
  },
  [FETCH_ANALYSIS_FILTER]: (state, { filterType }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filterType]: {
          isLoading: true,
        },
      },
    };
  },
  [FETCH_ANALYSIS_FILTER_SUCCESS]: (state, { data, filterType }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filterType]: {
          isLoading: false,
          ...parseFilterData(data),
        },
      },
    };
  },
  [SET_ANALYSIS_FILTER_VALUE]: (state, { filterType, minValue, maxValue }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [filterType]: {
          ...state.filters[filterType],
          minValue,
          maxValue,
        },
      },
    };
  },
  [FETCH_ANALYSIS_PLOT]: (state) => {
    return {
      ...state,
      plot: {
        ...state.plot,
        isLoading: true,
      },
    };
  },
  [FETCH_ANALYSIS_PLOT_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      plot: {
        ...state.plot,
        isLoading: false,
        data,
      },
      zDomain: generateInitialZDomain(data),
    };
  },
  [SET_ANALYSIS_Z_DOMAIN]: (state, { domain }) => {
    const [min, max] = domain;
    return {
      ...state,
      zDomain: {
        ...state.zDomain,
        min,
        max,
      },
    };
  },
  [SET_ANALYSIS_AXIS_VALUE_TYPE]: (state, { axis, valueField }) => {
    if (!includes(["x", "y", "z"], axis)) {
      return;
    }
    return {
      ...state,
      [`${axis}AxisType`]: valueField,
    };
  },
});
