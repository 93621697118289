import React from "react";
import { Column, DataItem } from "./Types";
import { RowItem } from "./RowItem";

type RowProps = {
  rowData: DataItem;
  columns: Column[];
};

export const Row: React.FC<RowProps> = ({ rowData, columns }: RowProps) => {
  return (
    <>
      {columns.map((column, index) => {
        const key = `column_${rowData.id}_${index}`;

        return (
          <RowItem
            rowData={rowData}
            column={column}
            index={index}
            key={`column_${key}`}
          />
        );
      })}
    </>
  );
};
