import createReducer from "./createReducer";
import { hullPerformance } from "../actions/action.types";
import { addMonths, from, atStartOfMonth } from "../common/dates";
import { get, first, min, sortBy, max, last, flatMap, compact } from "lodash";
import {
  HULL_PERFORMANCE_INDICATORS,
  HULL_PERFORMANCE_INDICATOR_STATUS,
} from "../config";

const initialState = {
  isLoading: true,
  performance: {
    performanceValues: [],
    performanceIndicators: [],
    isLoading: false,
  },
  dryDockEvents: {
    data: [],
    isLoading: false,
  },
};

export default createReducer(
  { ...initialState },
  {
    [hullPerformance.INITIALIZE]: () => ({ ...initialState }),
    [hullPerformance.INITIALIZE_SUCCESS]: (state, { vesselId }) => {
      return {
        ...state,
        vesselId,
        isLoading: false,
      };
    },
    [hullPerformance.INITIALIZE_ERROR]: (state, { error }) => ({
      ...state,
      error,
      isLoading: false,
    }),
    [hullPerformance.FETCH_DOCK_EVENTS]: (state) => ({
      ...state,
      dryDockEvents: {
        data: [],
        isLoading: true,
      },
    }),
    [hullPerformance.FETCH_DOCK_EVENTS_SUCCESS]: (state, { data }) => {
      const dryDockEvents = data.map((d) => ({
        startTime: from(d.startTime),
        endTime: d.endTime && from(d.endTime),
      }));
      return {
        ...state,
        dryDockEvents: {
          isLoading: false,
          data: dryDockEvents,
        },
      };
    },
    [hullPerformance.FETCH_DOCK_EVENTS_ERROR]: (state, { error }) => {
      return {
        ...state,
        dryDockEvents: {
          ...state.dryDockEvents,
          isLoading: false,
          error,
        },
      };
    },
    [hullPerformance.SET_INTERVAL]: (state) => ({
      ...state,
      isLoading: true,
      error: undefined,
    }),
    [hullPerformance.SET_INTERVAL_SUCCESS]: (state, { from, to }) => ({
      ...state,
      isLoading: false,
      interval: {
        from,
        to,
      },
    }),
    [hullPerformance.SET_INTERVAL_ERROR]: (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [hullPerformance.FETCH_PERFORMANCE_VALUES]: (state) => ({
      ...state,
      performance: {
        ...initialState.performance,
        isLoading: true,
      },
    }),
    [hullPerformance.FETCH_PERFORMANCE_VALUES_SUCCESS]: (
      state,
      { performanceValues, performanceIndicators }
    ) => {
      return {
        ...state,
        performance: {
          isLoading: false,
          performanceValues: performanceValues.map((d) => ({
            x: from(d.time),
            y: d.value,
            z: d.value,
          })),
          performanceIndicators: performanceIndicators.map((pi) => {
            return {
              time: pi.time && from(pi.time),
              value: pi.value,
              type: pi.type,
              isValid: pi.status === "ok",
              statusMessage: getPerformanceIndicatorStatusMessage(pi),
              title: getPerformanceIndicatorTitle(pi.type),
              description: getPerformanceIndicatorDescription(pi.type),
              evaluationPeriod: {
                intervals: get(pi, "evaluationPeriod.intervals", []).map(
                  (i) => ({
                    from: from(i.start),
                    to: from(i.end),
                  })
                ),
                value: get(pi, "evaluationPeriod.value"),
              },
              referencePeriod: {
                intervals: get(pi, "referencePeriod.intervals", []).map(
                  (i) => ({
                    from: from(i.start),
                    to: from(i.end),
                  })
                ),
                value: get(pi, "referencePeriod.value"),
              },
            };
          }),
        },
      };
    },
    [hullPerformance.FETCH_PERFORMANCE_VALUES_ERROR]: (state, { error }) => {
      return {
        ...state,
        performance: {
          isLoading: false,
          error,
        },
      };
    },
  }
);

export const getHullPerformanceIndicator = (type) => {
  return get(HULL_PERFORMANCE_INDICATORS, type);
};

const getPerformanceIndicatorStatusMessage = (pi) => {
  const piDefaults = get(HULL_PERFORMANCE_INDICATORS, pi.type);
  if (
    pi.status === HULL_PERFORMANCE_INDICATOR_STATUS.ok &&
    piDefaults.messageTemplate
  ) {
    return piDefaults.messageTemplate(pi);
  }
  return get(HULL_PERFORMANCE_INDICATOR_STATUS, pi.status);
};

const getPerformanceIndicatorTitle = (type) => {
  return get(HULL_PERFORMANCE_INDICATORS, `${type}.title`);
};
const getPerformanceIndicatorDescription = (type) => {
  return get(HULL_PERFORMANCE_INDICATORS, `${type}.description`);
};

export const getInterval = (dockEvents, startTime, currentDate) => {
  let intervalTo =
    dockEvents.length > 0 &&
    from(
      get(
        last(
          sortBy(
            dockEvents,
            (x) => x.endTime,
            (x) => x.startTime
          )
        ),
        "startTime"
      )
    );

  if (!intervalTo || intervalTo < currentDate) {
    intervalTo = addMonths(1, atStartOfMonth(currentDate));
  }

  return {
    from: from(startTime),
    to: intervalTo,
    now: currentDate,
  };
};

export const getXDomain = (
  interval,
  dockEvents,
  performanceValues,
  performanceIndicators
) => {
  const firstPerformanceIndicatorReference = min(
    flatMap(performanceIndicators, (pi) => {
      return pi.referencePeriod.intervals.map((r) => r.from);
    })
  );

  const firstDockEvent =
    dockEvents.length > 0 && min(dockEvents.map((d) => d.startTime));

  const lastPerformanceIndicatorEvaluation = max(
    flatMap(performanceIndicators, (pi) => {
      return pi.evaluationPeriod.intervals.map((r) => r.to);
    })
  );

  const intervalFrom = get(interval, "from");
  const intervalTo = get(interval, "to");

  const firstValue = get(first(performanceValues), "x");
  const lastValue = get(last(performanceValues), "x");

  const from =
    min(
      compact([firstDockEvent, firstValue, firstPerformanceIndicatorReference])
    ) || intervalFrom;

  const to = max(
    compact([intervalTo, lastValue, lastPerformanceIndicatorEvaluation])
  );

  return {
    from,
    to,
  };
};

export const getYDomain = (data) => {
  const yDomain = data.map((x) => x.y);
  return {
    from: Math.ceil((min(yDomain) - 20) / 10) * 10 || -100,
    to: Math.ceil((max(yDomain) + 10) / 10) * 10 || 20,
  };
};
