import React from "react";
import { flatMap } from "lodash";
import styles from "./DateRangePicker.css";
import monthStyles from "./Month/Month.css";
import dayStyles from "./DateItemButton/DateItemButton.css";
import pickerStyles from "../PickerStyles.css";
import ContextBrowser from "../ContextBrowser";
import Divider from "../Divider";
import classNames from "../../../../common/classNames";

const DateRangePicker = (props) => {
  const { options, apply, getNextOptions, getPrevOptions } = props;
  const { page1, page2, hasNext, hasPrev } = options;

  return (
    <div>
      <ContextBrowser
        mode={"split"}
        title={[page1.title, page2.title]}
        next={hasNext}
        prev={hasPrev}
        onNext={getNextOptions}
        onPrev={getPrevOptions}
      />

      <div className={styles.monthContainer}>
        {renderMonth(page1, props)}
        <Divider visible={true} />
        {renderMonth(page2, props)}
      </div>

      <button
        className={pickerStyles.applyBtn}
        onClick={apply}
        disabled={!options.isSelectionValid}
        data-test-id="datePickerCustomApply-button"
      >
        Apply
      </button>
    </div>
  );
};

function renderMonth({ weeks }, props) {
  return (
    <div className={classNames(monthStyles.container, monthStyles.activeRange)}>
      <div className={monthStyles.weekdayTitles}>
        {props.options.weekHeaders.map((h, i) => (
          <span key={i} className={monthStyles.weekdayTitle}>
            {h}
          </span>
        ))}
      </div>
      <div className={monthStyles.days}>
        {flatMap(weeks, (week) => week.map((item) => renderDay(item, props)))}
      </div>
    </div>
  );
}

function renderDay(item, props) {
  if (item.isEmpty) {
    return (
      <div
        key={item.key}
        className={classNames(monthStyles.day, monthStyles.dummy)}
      />
    );
  }
  const firstBetween =
    item.isSelectionEnd &&
    !item.isSelectionStart &&
    props.options.isSelectionValid;
  const lastBetween =
    item.isSelectionStart &&
    !item.isSelectionEnd &&
    props.options.isSelectionValid;
  return (
    <div
      key={item.key}
      className={classNames(
        monthStyles.day,
        item.isSelectionStart && monthStyles.startpoint,
        item.isSelectionEnd && monthStyles.endpoint,
        firstBetween && monthStyles.firstBetween,
        lastBetween && monthStyles.lastBetween,
        item.isSelectionBetween && monthStyles.inbetween
      )}
    >
      {!item.isEmpty && (
        <button
          className={dayStyles.button}
          disabled={!item.isEnabled}
          onClick={() => props.select(item)}
          data-test-id="datePickerCustomDates-buttons"
        >
          {item.title}
        </button>
      )}
    </div>
  );
}

export default DateRangePicker;
