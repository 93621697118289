import React, { FC } from "react";
import styles from "./ManualFuelDataFormDialog.css";
import RowHeader from "./RowHeader";
import RowData from "./RowData";
import DeleteButton from "./DeleteButton";
import { FuelDataType } from "./Types";

interface RowProps {
  rowNumber: number;
  disabled: boolean;
  data: FuelDataType[];
  fuelTypes: string[];
  onFormDataChanged: (
    field: string,
    value: any,
    index: number,
    meta?: any
  ) => void;
  removeRow: (index: number) => void;
}

const Row: FC<RowProps> = ({
  rowNumber,
  disabled,
  data,
  fuelTypes,
  onFormDataChanged,
  removeRow,
}) => {
  return (
    <div className={styles.container} key={rowNumber} data-testid="Row">
      <RowHeader
        rowNumber={rowNumber}
        disabled={disabled}
        data={data}
        onFormDataChanged={onFormDataChanged}
      />
      <RowData
        rowNumber={rowNumber}
        disabled={disabled}
        data={data}
        fuelTypes={fuelTypes}
        onFormDataChanged={onFormDataChanged}
      />
      <DeleteButton rowNumber={rowNumber} removeRow={removeRow} />
    </div>
  );
};

export default Row;
