import React from "react";
import PropTypes from "prop-types";
import styles from "./Form.css";
import classNames from "../../../common/classNames";

export default class FormBaseController extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { label, labelPosition, message, style, children } = this.props;
    return (
      <div
        className={classNames(
          styles.formElementContainer,
          labelPosition === "left" ? styles.leftAlignedContainer : ""
        )}
        style={style}
      >
        <div className={styles.labelContainer}>
          <label className={styles.label}>{label}</label>
          {message && <span className={styles.requiredMessage}>{message}</span>}
        </div>
        {children}
      </div>
    );
  }
}

FormBaseController.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  message: PropTypes.string,
  style: PropTypes.object,
};
