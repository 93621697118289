import { OPERATIONAL_PROFILE_PLAN_ACTIONS } from "../actions/action.types";
import { prepareDonutData } from "../common/donuts";
import { groupBy, sumBy } from "lodash";
import moment from "moment";

const addOriginalValues = (data, selectedRange) => {
  if (data.data && data.data.length > 0) {
    data.data.map((entry) => {
      entry.originalValue = entry.value;
      entry.fromToDate =
        moment.utc(entry.operationalProfile.fromDate).format("DD/MM/YYYY") +
        " - " +
        moment.utc(entry.operationalProfile.toDate).format("DD/MM/YYYY");
    });

    let groupedValues = groupBy(data.data, "fromToDate");
    let optionSelected = false;
    let lastKey;
    let operationalProfilePlans = [];
    data.data = [];
    Object.keys(groupedValues).forEach(function (key) {
      lastKey = key;
      let opp = {
        visibleValue: moment
          .utc(groupedValues[key][0].operationalProfile.fromDate)
          .format("YYYY"),
        realValue:
          moment
            .utc(groupedValues[key][0].operationalProfile.fromDate)
            .format("DD/MM/YYYY") +
          " - " +
          moment
            .utc(groupedValues[key][0].operationalProfile.toDate)
            .format("DD/MM/YYYY"),
      };

      if (
        selectedRange === undefined &&
        moment.utc().toDate() >
          moment
            .utc(groupedValues[key][0].operationalProfile.fromDate)
            .toDate() &&
        moment.utc(groupedValues[key][0].operationalProfile.toDate).toDate() >
          moment.utc().toDate()
      ) {
        if (data.context === "group") {
          data.data.push(...groupedValues[key]);
        } else {
          data.data = groupedValues[key];
        }
        opp.selected = true;
        data.key = key;
        optionSelected = true;
        data.total = sumBy(data.data, "value");
        data.fromDate = data.data[0].operationalProfile.fromDate;
        data.toDate = data.data[0].operationalProfile.toDate;
      }
      operationalProfilePlans.push(opp);
    });

    if (selectedRange !== undefined) {
      data.data = groupedValues[selectedRange];
      operationalProfilePlans.find(
        (x) => x.realValue === selectedRange
      ).selected = true;
      data.key = selectedRange;
      optionSelected = true;
      data.total = sumBy(data.data, "value");
      data.fromDate = data.data[0].operationalProfile.fromDate;
      data.toDate = data.data[0].operationalProfile.toDate;
    }

    data.operationalProfilePlanDateRanges = operationalProfilePlans;

    if (!optionSelected) {
      data.data = groupedValues[lastKey];
      operationalProfilePlans.find(
        (x) => x.realValue === lastKey
      ).selected = true;
      data.key = lastKey;
      data.total = sumBy(data.data, "value");
      data.fromDate = data.data[0].operationalProfile.fromDate;
      data.toDate = data.data[0].operationalProfile.toDate;
    }
  }
  return data;
};

let prepareData;

const operationalProfilePlan = (
  state = {
    isLoading: true,
  },
  action = {}
) => {
  switch (action.type) {
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_PLAN_DATA:
      return {
        ...state,
        isLoading: true,
        data: undefined,
        error: undefined,
      };
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_PLAN_DATA_SUCCESS:
      prepareData = addOriginalValues(JSON.parse(JSON.stringify(action.data)));
      return {
        ...state,
        isLoading: false,
        data: prepareDonutData(prepareData),
        serverData: action.data,
      };
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_PLAN_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case OPERATIONAL_PROFILE_PLAN_ACTIONS.SET_WORKING_PERIOD:
      prepareData = addOriginalValues(
        JSON.parse(JSON.stringify(state.serverData)),
        action.period
      );
      return {
        ...state,
        data: prepareDonutData(prepareData),
      };
    default:
      return state;
  }
};
export default operationalProfilePlan;
