import React, { Component } from "react";
import { connect } from "react-redux";
import { VesselProfile } from "../components/VesselProfile/VesselProfile";

class VesselProfileContainer extends Component {
  render() {
    if (!this.props.vessel) {
      return <div />;
    }
    return <VesselProfile {...this.props.vessel} />;
  }
}

export default connect((state, props) => {
  const vesselId = props.context.vesselId;
  const vessel = state.pageContext.vessels[vesselId];
  return { vessel };
})(VesselProfileContainer);
