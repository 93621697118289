import * as d3 from "d3";
import { max, isDate, isEmpty, min, isNumber, groupBy, isNil } from "lodash";
import { Renderer } from "@pixi/core";
import "@pixi/unsafe-eval";
import * as PIXI from "pixi.js";
import PropTypes from "prop-types";
import React from "react";
import { color, graphColors } from "../../../common/colors";
import Loader from "../../../common/components/Loader/Loader";
import { getScale } from "../../../common/components/Helpers/YScale";
import { formatNumber } from "../../../common/numbers";
import { COSMETIC_CONSTS } from "../../config";
import styles from "./MultiSeriesPlotChart.css";
import {
  createRegressionLineFormula,
  hexStringToInt,
  rgbToHex,
} from "../PlotChart/PlotChart";

const MARGIN_LEFT = 80;
const MARGIN_BOTTOM = 80;
const MARGIN_RIGHT = 50;
const MARGIN_TOP = 45;

const PLOT_COLORS = graphColors.map((c) => {
  return d3.rgb(c);
});

const defaultAxisLabelTextStyle = {
  fontSize: "14px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--light-grey"),
  fontWeight: "400",
  textAlign: "right",
};

const defaultHoverLabelTextStyle = {
  fontSize: "16px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--page-background"),
  fontWeight: "bold",
  textAlign: "center",
};

const getYAxis = (index, margins, height, width) => {
  if (index === 0) {
    return {
      x1: margins.left,
      y1: height - margins.bottom,
      x2: margins.left,
      y2: margins.top,
    };
  }
  if (index === 1) {
    return {
      x1: width - margins.right,
      y1: height - margins.bottom,
      x2: width - margins.right,
      y2: margins.top,
    };
  }
};

export class MultiSeriesPlotChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.performRenderStage = this.performRenderStage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this.setupScales();
      this.setupAxis();
      this.addRegressionLine();
      this.addCustomElements();
      this.addLabels();
    }

    if (this.props.zDomain !== prevProps.zDomain) {
      this.setZMinMax();
    }

    if (this.props.data !== prevProps.data) {
      this.setupScales();
      this.setupAxis();
      this.addData();
      this.addRegressionLine();
      this.addNormalLine();
      this.addCustomElements();
      this.addLabels();
    } else {
      this.addRegressionLine();
      this.updateData();
      this.addNormalLine();
      this.addCustomElements();
    }

    this.renderStage();
    this.addBaseline();
  }

  componentDidMount() {
    this.setupRenderer();
    this.setupTextures();
    this.setupScales();
    this.setupAxis();
    this.addData();
    this.addRegressionLine();
    this.addNormalLine();
    this.addCustomElements();
    this.addLabels();
    this.renderStage();
    this.addBaseline();
  }

  componentWillUnmount() {
    this.renderer.destroy();
  }

  setupRenderer() {
    const { width, height, forceCanvas } = this.props;
    PIXI.utils.skipHello();
    this.renderer = new Renderer({
      width,
      height,
      backgroundAlpha: 0,
      antialias: true,
      forceCanvas,
    });
    this.stage = new PIXI.Container();
    this.axisContainer = new PIXI.Container();
    this.baselineContainer = new PIXI.Container();
    this.plotContainer = new PIXI.Container();
    this.hoverContainer = new PIXI.Container();
    this.regressionContainer = new PIXI.Container();
    this.normalContainer = new PIXI.Container();
    this.customContainer = new PIXI.Container();
    this.customHoverContainer = new PIXI.Container();
    this.blurFilter = new PIXI.BlurFilter();
    this.labelContainer = new PIXI.Container();
    this.plotContainer.filters = [this.blurFilter];
    this.blurFilter.enabled = false;
    this.stage.addChild(this.axisContainer);
    this.stage.addChild(this.baselineContainer);
    this.stage.addChild(this.plotContainer);
    this.stage.addChild(this.regressionContainer);
    this.stage.addChild(this.normalContainer);
    this.stage.addChild(this.customContainer);
    this.stage.addChild(this.hoverContainer);
    this.stage.addChild(this.customHoverContainer);
    this.stage.addChild(this.labelContainer);
    this.targetDiv.appendChild(this.renderer.view);
  }

  setupTextures() {
    const circleGfx = new PIXI.Graphics();
    circleGfx.beginFill(hexStringToInt(color("--white")));
    circleGfx.drawCircle(3, 3, this.props.plotStyle.circleRadius);
    circleGfx.endFill();
    this.circleTexture = this.renderer.generateTexture(circleGfx);
    const squareGfx = new PIXI.Graphics();
    squareGfx.beginFill(hexStringToInt(color("--white")));
    squareGfx.drawRect(3, 3, 10, 10);
    squareGfx.endFill();
    this.squareTexture = this.renderer.generateTexture(squareGfx);
    const hoverGfx = new PIXI.Graphics();
    hoverGfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
    hoverGfx.beginFill(hexStringToInt(color("--white")));
    hoverGfx.drawRoundedRect(0, 0, 100, 32, 5);
    hoverGfx.endFill();
    hoverGfx.lineStyle(0);
    hoverGfx.beginFill(hexStringToInt(color("--white")));
    hoverGfx.moveTo(40, 30);
    hoverGfx.lineTo(50, 42);
    hoverGfx.lineTo(60, 30);
    hoverGfx.endFill();
    hoverGfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
    hoverGfx.moveTo(40, 32);
    hoverGfx.lineTo(50, 42);
    hoverGfx.lineTo(60, 32);

    this.hoverBackgroundTexture = this.renderer.generateTexture(hoverGfx);
  }

  setMargins() {
    const { series, margins } = this.props;
    this.margins = {
      left: margins.left,
      top: margins.top,
      right: margins.right,
      bottom:
        margins.bottom +
        (!isNil(series[0]) && series[0].xDataType === "DateTime" ? 50 : 20),
    };
  }

  getMinMax() {
    const { series } = this.props;
    let flatVals = series.flatMap((s) => s.values);
    if (!isNil(series[0]) && series[0].xDataType === "DateTime") {
      flatVals = flatVals.map((v) => {
        return { ...v, x: new Date(v.x) };
      });
    }

    return this.getXMinMax(flatVals);
  }

  setupScales() {
    const { series, width, height, margins, axisLabelStyle } = this.props;
    const { minX, maxX } = this.getMinMax();
    this.setMargins();
    const originalLeftMargin = margins.left;
    const yScales = [];
    const groupedSeries = groupBy(series, "yUnit");
    if (Object.keys(groupedSeries).length > 1) {
      this.margins = {
        ...this.margins,
        right: margins.right + 20,
      };
    }
    let prevMaxYWidth = 0;
    Object.keys(groupedSeries).forEach((key) => {
      const groupedSerie = groupedSeries[key];
      const flattenedSerie = groupedSerie.flatMap((v) => v.values);
      const colorIndexes = groupedSerie.map((s) => s.colorIndex);
      let minY, maxY;
      if (this.hasNormalLine()) {
        const n1 = this.normalFunction(minX);
        const n2 = this.normalFunction(maxX);
        minY =
          Math.min(
            n1,
            n2,
            d3.min(flattenedSerie, (v) => v.y)
          ) || 0;
        maxY =
          Math.max(
            n1,
            n2,
            d3.max(flattenedSerie, (v) => v.y)
          ) || 100;
      } else {
        minY = d3.min(flattenedSerie, (v) => v.y) || 0;
        maxY = d3.max(flattenedSerie, (v) => v.y) || 100;
      }
      const { yDomain, scaleY, ticksY } = this.getYDomainInfo(minY, maxY);
      let maxYTickWidth = 0;
      if (ticksY && ticksY.length > 0) {
        const axisLabelTextStyle = new PIXI.TextStyle(axisLabelStyle);
        maxYTickWidth = max(
          ticksY.forEach((y) => {
            const yAxisLabel = new PIXI.Text(y, axisLabelTextStyle);
            return yAxisLabel.width;
          })
        );
      }

      if (prevMaxYWidth < maxYTickWidth) {
        this.margins = {
          ...this.margins,
          left: originalLeftMargin + maxYTickWidth,
        };
      }
      yScales.push({
        minY,
        maxY,
        yDomain,
        scaleY,
        ticksY,
        unit: key,
        colors: colorIndexes,
      });
    });
    const { scaleX, ticksX, xDomain } = this.getXDomainInfo(minX, maxX);
    this.domain = {
      minX,
      maxX,
      scaleX,
      ticksX,
      xDomain,
      yScales,
    };

    this.setZMinMax();
    this.renderer.resize(width, height);
  }

  getXMinMax(series) {
    const minX = d3.min(series, (v) => v.x) || 0;
    const maxX = d3.max(series, (v) => v.x) || 100;
    return {
      minX,
      maxX,
    };
  }

  getXDomainInfo(minX, maxX) {
    const { width } = this.props;
    const xDomain = [minX, maxX];

    const scaleX = isDate(minX)
      ? d3
          .scaleTime()
          .domain(xDomain)
          .range([this.margins.left, width - this.margins.right])
      : d3
          .scaleLinear()
          .domain(xDomain)
          .range([this.margins.left, width - this.margins.right]);

    const ticksX = scaleX.ticks();
    return {
      xDomain,
      scaleX,
      ticksX,
    };
  }

  getYDomainInfo(minY, maxY) {
    const { height, yScaleType, yScaleProperties } = this.props;
    const yDomain = [minY, maxY];
    const scaleY = getScale(yScaleType, yScaleProperties)
      .domain(yDomain)
      .range([height - this.margins.bottom, this.margins.top]);
    const ticksY = scaleY.ticks(10);

    return {
      yDomain,
      scaleY,
      ticksY,
    };
  }

  recalculateZScale(series) {
    const { plotColors } = this.props;
    const minZ = series.zDomain[0] || d3.min(series.values, (v) => v.z);
    const maxZ = series.zDomain[1] || d3.max(series.values, (v) => v.z);
    this.domain.scaleZ = d3
      .scaleLinear()
      .domain([minZ, (maxZ - minZ) / 2 + minZ, maxZ])
      .range(plotColors)
      .interpolate(d3.interpolateRgb);
  }
  setZMinMax() {
    const { series, plotColors } = this.props;
    const minZ = d3.min(series, (s) => d3.min(s.values, (v) => v.z));
    const maxZ = d3.max(series, (s) => d3.max(s.values, (v) => v.z));
    this.domain.scaleZ = d3
      .scaleLinear()
      .domain([minZ, (maxZ - minZ) / 2 + minZ, maxZ])
      .range(plotColors)
      .interpolate(d3.interpolateHcl);
    this.domain.minZ = minZ;
    this.domain.maxZ = maxZ;
  }

  getAxisLabel(label, unit, x, y, axisLabelTextStyle) {
    const unitOrEmptyString = unit ? ` (${unit})` : "";
    const axisLabel = `${label ? label : ""}${unitOrEmptyString}`;
    const axisLabelText = new PIXI.Text(axisLabel, axisLabelTextStyle);
    axisLabelText.x = x;
    axisLabelText.y = y;
    return axisLabelText;
  }

  setupAxis() {
    const { height, width, axisLabelStyle, customXAxisLegendRenderer, series } =
      this.props;
    const margins = this.margins;
    const axisLabelTextStyle = new PIXI.TextStyle(axisLabelStyle);
    const { yScales } = this.domain;
    const gfx = new PIXI.Graphics();
    this.axisContainer.removeChildren();
    this.axisContainer.addChildAt(gfx, 0);
    this.customHoverContainer.removeChildren();
    const xAxis = {
      x1: margins.left,
      y1: height - margins.bottom,
      x2: width - margins.right,
      y2: height - margins.bottom,
    };

    this.setupYScale(yScales, margins, gfx, axisLabelTextStyle);
    this.setupTickX(gfx, xAxis, axisLabelTextStyle);

    let labelFound = false;
    series.forEach((s, index) => {
      const { xLabel, xUnit, yLabel, yUnit } = s;
      if ((xLabel || xUnit) && !labelFound) {
        labelFound = true;
        let xAxisLabel;
        if (customXAxisLegendRenderer) {
          xAxisLabel = customXAxisLegendRenderer({
            xLabel,
            xUnit,
            margins,
            width,
            height,
            axisLabelTextStyle,
          });
        } else {
          const x =
            (width - margins.right - margins.left) / 2 +
            margins.left -
            xAxisLabel.width / 2;
          const y = height - margins.bottom + 40;
          xAxisLabel = this.getAxisLabel(
            xLabel,
            xUnit,
            x,
            y,
            axisLabelTextStyle
          );
        }
        this.axisContainer.addChild(xAxisLabel);
      }
      if (yLabel || yUnit) {
        const x = index === 0 ? margins.left + 5 : width - margins.right - 5;
        const y = margins.top / 2;
        const yAxisLabel = this.getAxisLabel(
          yLabel,
          yUnit,
          x,
          y,
          axisLabelTextStyle
        );
        index === 0
          ? yAxisLabel.anchor.set(0, 0.5)
          : yAxisLabel.anchor.set(1, 0.5);
        this.axisContainer.addChild(yAxisLabel);
      }
    });

    gfx.lineStyle(2, hexStringToInt(color("--light-grey")));
    gfx.moveTo(xAxis.x1, xAxis.y1);
    gfx.lineTo(xAxis.x2, xAxis.y2);
    this.renderStage();
  }

  setupTickX(gfx, xAxis, axisLabelTextStyle) {
    const { plotStyle, series } = this.props;
    const { ticksX, scaleX } = this.domain;
    for (const tickX of ticksX) {
      const xVal = scaleX(tickX);
      gfx.lineStyle(2, hexStringToInt(color("--light-grey")));
      gfx.moveTo(xVal, xAxis.y1);
      gfx.lineTo(xVal, xAxis.y1 + plotStyle.circleRadius);
      const label = new PIXI.Text(
        this.formatTickLabel(tickX),
        axisLabelTextStyle
      );
      if (!isNil(series[0]) && series[0].xDataType === "DateTime") {
        label.rotation = Math.PI / 12;
        label.x = xVal;
      } else {
        label.x = xVal - label.width / 2;
      }
      label.y = xAxis.y1 + 10;
      this.axisContainer.addChildAt(label, 0);
    }
  }

  setupYScale(yScales, margins, gfx, axisLabelTextStyle) {
    const { height, width, plotStyle, plotColors } = this.props;

    yScales.forEach((y, index) => {
      const { colors } = y;
      const yAxis = getYAxis(index, margins, height, width);

      if (!isNil(yAxis)) {
        if (yScales.length > 1 && colors.length > 1) {
          const lineLength = yAxis.y2 - yAxis.y1;
          let y1 = yAxis.y1;
          const yInterVal = lineLength / colors.length;
          colors.forEach((c) => {
            const lineColor = rgbToHex(plotColors[c]);
            gfx.lineStyle(2, lineColor);
            gfx.moveTo(yAxis.x1, y1);
            gfx.lineTo(yAxis.x2, yInterVal);
            y1 += yInterVal;
          });
        } else {
          const lineColor =
            yScales.length > 1
              ? rgbToHex(plotColors[colors[0]])
              : hexStringToInt(color("--light-grey"));
          gfx.lineStyle(2, lineColor);
          gfx.moveTo(yAxis.x1, yAxis.y1);
          gfx.lineTo(yAxis.x2, yAxis.y2);
        }
        const { ticksY, scaleY } = y;
        for (const tickY of ticksY) {
          let yVal = scaleY(tickY);
          const lineColor = hexStringToInt(color("--light-grey"));

          if (index === 0) {
            gfx.lineStyle(2, lineColor);
            gfx.moveTo(yAxis.x1, yVal);
            gfx.lineTo(yAxis.x1 - plotStyle.circleRadius, yVal);
            const label = new PIXI.Text(tickY, axisLabelTextStyle);
            label.x = yAxis.x1 - label.width - 10;
            label.y = yVal - 7;
            this.axisContainer.addChildAt(label, 0);
          } else if (index === 1) {
            gfx.lineStyle(2, lineColor);
            gfx.moveTo(yAxis.x1, yVal);
            gfx.lineTo(yAxis.x1 + plotStyle.circleRadius, yVal);
            const label = new PIXI.Text(tickY, axisLabelTextStyle);
            label.x = yAxis.x1 + label.width - 8;
            label.y = yVal - 7;
            this.axisContainer.addChildAt(label, 0);
          }
        }
      }
    });
  }

  formatTickLabel(label) {
    return (
      (this.props.formatTickLabel && this.props.formatTickLabel(label)) || label
    );
  }

  isInsideZDomain(val) {
    return val.z >= this.domain.minZ && val.z <= this.domain.maxZ;
  }

  updateData() {
    const { series, plotStyle, colorizeZIndex, plotColors } = this.props;
    let spriteIndex = 0;
    series.forEach((s) => {
      if (colorizeZIndex) {
        this.recalculateZScale(s);
      }
      const { yUnit, colorIndex } = s;
      const yScale =
        series.length > 1
          ? this.domain.yScales.find((x) => x.unit === yUnit)
          : this.getDefaultYScale();

      for (const serieValue of s.values) {
        const val = serieValue;
        let x;
        if (s.xDataType === "DateTime") {
          x = this.domain.scaleX(new Date(val.x)) - plotStyle.circleRadius;
        } else {
          x = this.domain.scaleX(val.x) - plotStyle.circleRadius;
        }
        const y = !isNil(yScale)
          ? yScale.scaleY(val.y) - plotStyle.circleRadius
          : NaN;
        if (isNaN(x) || isNaN(y)) {
          continue;
        }
        const sprite = this.plotContainer.getChildAt(spriteIndex);
        sprite.x = x;
        sprite.y = y;
        sprite.alpha = this.props.plotStyle.alpha;
        sprite.visible = this.isInsideZDomain(val);
        sprite.tint = colorizeZIndex
          ? rgbToHex(this.domain.scaleZ(val.z))
          : rgbToHex(plotColors[colorIndex]);
        spriteIndex++;
      }
    });
  }

  getDefaultYScale() {
    return !isNil(this.domain.yScales[0]) ? this.domain.yScales[0] : null;
  }

  createSprite(val, s, plotStyle, colorizeZIndex, plotColors) {
    const x = this.computeXValue(val, s, plotStyle);
    const y = this.computeYValue(val, s, plotStyle);
    if (isNaN(x) || isNaN(y)) return null;

    const sprite = new PIXI.Sprite(this.circleTexture);
    sprite.x = x;
    sprite.y = y;
    sprite.buttonMode = true;
    sprite.alpha = plotStyle.alpha;
    sprite.visible = this.isInsideZDomain(val);
    sprite.tint = rgbToHex(
      this.getSpriteTint(colorizeZIndex, val, s, plotColors)
    );
    sprite.interactive = true;
    return sprite;
  }

  computeXValue(val, s, plotStyle) {
    return (
      this.domain.scaleX(s.xDataType === "DateTime" ? new Date(val.x) : val.x) -
      plotStyle.circleRadius
    );
  }

  computeYValue(val, s, plotStyle) {
    const yScale = this.getYScale(s);
    return !isNil(yScale) ? yScale.scaleY(val.y) - plotStyle.circleRadius : NaN;
  }

  getYScale(s) {
    const { series } = this.props;
    return series.length > 1 && s.yUnit !== undefined
      ? this.domain.yScales.find((x) => x.unit === s.yUnit)
      : this.getDefaultYScale();
  }

  getSpriteTint(colorizeZIndex, val, s, plotColors) {
    return colorizeZIndex
      ? this.domain.scaleZ(val.z)
      : plotColors[s.colorIndex];
  }

  handleSpriteHover(
    sprite,
    val,
    s,
    customHoverItemRenderer,
    hoverLabelTextStyle,
    plotStyle
  ) {
    const { colorizeZIndex } = this.props;
    let spriteHoverContainer;
    sprite.on("mouseover", () => {
      spriteHoverContainer = this.createHoverContainer(
        sprite,
        val,
        s,
        customHoverItemRenderer,
        hoverLabelTextStyle,
        colorizeZIndex
      );
      this.hoverContainer.addChild(spriteHoverContainer);
      sprite.alpha = 1.0;
      this.performRenderStage();
    });
    sprite.on("mouseout", () => {
      this.hoverContainer.removeChild(spriteHoverContainer);
      sprite.alpha = plotStyle.alpha;
      this.performRenderStage();
    });
  }

  createHoverContainer(
    sprite,
    val,
    s,
    customHoverItemRenderer,
    hoverLabelTextStyle,
    colorizeZIndex
  ) {
    if (customHoverItemRenderer) {
      return customHoverItemRenderer({
        sprite,
        value: val,
        xUnit: s.xUnit,
        yUnit: s.yUnit,
        zUnit: s.zUnit,
        hoverLabelTextStyle,
        margins: this.margins,
        width: this.props.width,
        height: this.props.height,
        zDataType: s.zDataType,
        seriesColor: this.getSpriteTint(colorizeZIndex, val, s),
        renderer: this.renderer,
      });
    } else {
      return this.createDefaultHoverContainer(
        sprite,
        val,
        s,
        hoverLabelTextStyle,
        colorizeZIndex
      );
    }
  }

  createDefaultHoverContainer(
    sprite,
    val,
    s,
    hoverLabelTextStyle,
    colorizeZIndex
  ) {
    const container = new PIXI.Container();
    const hoverSprite = new PIXI.Sprite(this.hoverBackgroundTexture);
    hoverSprite.tint = rgbToHex(this.getSpriteTint(colorizeZIndex, val, s));
    container.addChild(hoverSprite);

    const label = new PIXI.Text(
      `${formatNumber(val.z)}${this.formatZUnit(s.zUnit)}`,
      hoverLabelTextStyle
    );
    label.x = 50 - label.width / 2;
    label.y = 18 - label.height / 2;
    container.addChild(label);
    container.x = sprite.x - 45;
    container.y = sprite.y - 42;

    return container;
  }
  addData() {
    const {
      series,
      hoverLabelStyle,
      customHoverItemRenderer,
      plotStyle,
      plotColors,
      colorizeZIndex,
    } = this.props;
    const hoverLabelTextStyle = new PIXI.TextStyle(hoverLabelStyle);
    this.plotContainer.removeChildren();
    let spriteIndex = 0;
    series.forEach((s) => {
      if (colorizeZIndex) {
        this.recalculateZScale(s);
      }

      for (const serie of s.values) {
        const val = serie;
        const sprite = this.createSprite(
          val,
          s,
          plotStyle,
          colorizeZIndex,
          plotColors
        );
        if (!sprite) continue;
        this.plotContainer.addChildAt(sprite, spriteIndex);
        this.handleSpriteHover(
          sprite,
          val,
          s,
          customHoverItemRenderer,
          hoverLabelTextStyle,
          plotStyle
        );
        spriteIndex++;
      }
    });
  }

  formatZUnit(zUnit) {
    return zUnit ? " " + zUnit : "";
  }

  addRegressionLine() {
    this.regressionContainer.removeChildren();
    const { series, regressionLineStyle, plotColors } = this.props;

    series.forEach((s, index) => {
      if (s.regressionLine !== true) {
        return;
      }
      if (isEmpty(s.values)) {
        return;
      }
      const values = s.values.filter(
        (val) => val.x !== undefined && val.y !== undefined
      );
      const xData = values.map((d) => d.x);
      const yData = values.map((d) => d.y);
      const gfx = new PIXI.Graphics();
      const yScale =
        series.length > 1 && s.yUnit !== undefined
          ? this.domain.yScales.find((x) => x.unit === s.yUnit)
          : this.getDefaultYScale();
      this.regressionContainer.addChildAt(gfx, 0);
      const regressionFx = createRegressionLineFormula(
        xData,
        yData,
        yScale.yDomain
      );
      const stdDev = this.calculateStandardDeviation(values, regressionFx);

      const minX = min(xData);
      const maxX = max(xData);

      gfx.lineStyle(
        regressionLineStyle.lineWidth,
        hexStringToInt(regressionLineStyle.color),
        0.75
      );
      const [x1, y1] = regressionFx(minX);
      const [x2, y2] = regressionFx(maxX);

      gfx.moveTo(this.domain.scaleX(x1), yScale.scaleY(y1));
      gfx.lineTo(this.domain.scaleX(x2), yScale.scaleY(y2));
      if (isNumber(s.stdDev) && stdDev > 0) {
        const [x3, y3] = regressionFx(minX, +s.stdDev * stdDev);
        const [x4, y4] = regressionFx(maxX, +s.stdDev * stdDev);
        const [x5, y5] = regressionFx(minX, -s.stdDev * stdDev);
        const [x6, y6] = regressionFx(maxX, -s.stdDev * stdDev);
        gfx.lineStyle(1.5, rgbToHex(plotColors[index]), 0.5);
        gfx.moveTo(this.domain.scaleX(x3), yScale.scaleY(y3));
        gfx.lineTo(this.domain.scaleX(x4), yScale.scaleY(y4));
        gfx.moveTo(this.domain.scaleX(x5), yScale.scaleY(y5));
        gfx.lineTo(this.domain.scaleX(x6), yScale.scaleY(y6));
      }
    });
  }

  hasNormalLine() {
    return this.props.normalLineB || this.props.normalLineM;
  }

  normalFunction(x) {
    return this.props.normalLineM * x + this.props.normalLineB;
  }

  addNormalLine() {
    this.normalContainer.removeChildren();
    if (!this.hasNormalLine()) {
      return;
    }
    const { minX, maxX, scaleX, yScales } = this.domain;
    const p1 = [scaleX(minX), yScales[0].scaleY(this.normalFunction(minX))];
    const p2 = [scaleX(maxX), yScales[0].scaleY(this.normalFunction(maxX))];
    const gfx = new PIXI.Graphics();
    this.normalContainer.addChildAt(gfx, 0);
    gfx.lineStyle(1.5, hexStringToInt(color("--white")), 0.75);
    gfx.moveTo(...p1);
    gfx.lineTo(...p2);
  }

  addCustomElements() {
    this.customContainer.removeChildren();
    return (
      this.props.customElements &&
      this.props.customElements(
        this.props.data,
        this.domain,
        this.customContainer,
        this.margins,
        this.performRenderStage
      )
    );
  }

  calculateStandardDeviation(data, regressionFx) {
    const reduceAddition = (prev, cur) => prev + cur;
    const squared = data.map((d) => Math.pow(d.y - regressionFx(d.x)[1], 2));
    const squaredSum = squared.reduce(reduceAddition);
    const intermediate = squaredSum / (data.length - 2);
    return Math.sqrt(intermediate);
  }

  renderStage() {
    const { isLoading } = this.props;
    this.blurFilter.enabled = !!isLoading;
    requestAnimationFrame(this.performRenderStage);
  }

  performRenderStage() {
    this.renderer.render(this.stage);
  }

  addLabels() {
    const { series, plotColors, height, width, axisLabelStyle, baseline } =
      this.props;
    const axisLabelTextStyle = new PIXI.TextStyle(axisLabelStyle);
    this.labelContainer.removeChildren();
    let totalWidth = 0;
    const y = height - this.margins.bottom / 4;
    let x = width / 2;
    const labels = series.map((s) => {
      const sprite = new PIXI.Sprite(this.squareTexture);
      sprite.tint = rgbToHex(plotColors[s.colorIndex]);
      const label = new PIXI.Text(s.yLabel, axisLabelTextStyle);
      totalWidth += sprite.width + label.width + 30;
      return {
        sprite,
        label,
      };
    });
    x -= totalWidth / labels.length;
    labels.forEach((l) => {
      const { sprite, label } = l;
      sprite.x = x;
      sprite.y = y + 3;
      label.x = sprite.width + x + 5;
      label.y = y;
      x += label.width + sprite.width + 30;
      this.labelContainer.addChild(label);
      this.labelContainer.addChild(sprite);
    });

    if (baseline) {
      const sprite = new PIXI.Sprite(this.squareTexture);
      sprite.tint = hexStringToInt(color(`--${baseline.color}`));
      const label = new PIXI.Text(baseline.label, axisLabelTextStyle);
      totalWidth += sprite.width + label.width + 30;
      sprite.x = x;
      sprite.y = y + 3;
      label.x = sprite.width + x + 5;
      label.y = y;
      x += label.width + sprite.width + 30;
      this.labelContainer.addChild(label);
      this.labelContainer.addChild(sprite);
    }
  }

  addBaseline() {
    const { baseline } = this.props;

    if (!baseline) {
      return;
    }

    this.baselineContainer.removeChildren();
    const { color: lineColor, xIntervals, yIntervals } = baseline;

    if (xIntervals.length !== yIntervals.length) {
      return;
    }

    const { scaleX, yScales } = this.domain;
    const graphics = new PIXI.Graphics();
    const startX = scaleX(xIntervals[0]);
    const startY = yScales[0].scaleY(yIntervals[0]);
    graphics.moveTo(startX, startY);

    xIntervals.forEach((xInterval, i) => {
      const x = scaleX(xInterval);
      const y = yScales[0].scaleY(yIntervals[i]);
      if (isNaN(x) || isNaN(y)) {
        return;
      }
      this.baselineContainer.addChildAt(graphics, 0);
      graphics.lineStyle(1, hexStringToInt(color(`--${lineColor}`)), 0.75);
      graphics.lineTo(x, y);
    });
  }

  render() {
    const { width, height, isLoading } = this.props;
    return (
      <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className={styles.MultiSeriesPlotChartContainer}
      >
        {isLoading && (
          <Loader text="Loading" className={styles.loader} expand={true} />
        )}
        <div
          ref={(el) => (this.targetDiv = el)}
          style={{ width: `${width}px`, height: `${height}px` }}
        />
      </div>
    );
  }
}

MultiSeriesPlotChart.defaultProps = {
  margins: {
    left: MARGIN_LEFT,
    top: MARGIN_TOP,
    right: MARGIN_RIGHT,
    bottom: MARGIN_BOTTOM,
  },
  colorizeZIndex: false,
  regressionLineStyle: {
    lineWidth: 1.75,
    color: color("--red-base"),
  },
  plotStyle: {
    circleRadius: 6,
    alpha: 0.7,
  },
  plotColors: PLOT_COLORS,
  axisLabelStyle: defaultAxisLabelTextStyle,
  hoverLabelStyle: defaultHoverLabelTextStyle,
  normalLineB: 0,
  normalLineM: 0,
  series: [],
  yScaleType: "linear",
  yScaleProperties: {},
};

MultiSeriesPlotChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  colorizeZIndex: PropTypes.bool,
  margins: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  axisLabelStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fill: PropTypes.string,
    fontWeight: PropTypes.string,
    textAlign: PropTypes.string,
  }),
  hoverLabelStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fill: PropTypes.string,
    fontWeight: PropTypes.string,
    textAlign: PropTypes.string,
  }),
  regressionLineStyle: PropTypes.shape({
    lineWidth: PropTypes.number,
    color: PropTypes.string,
  }),
  plotStyle: PropTypes.shape({
    circleRadius: PropTypes.number.isRequired,
    alpha: PropTypes.number.isRequired,
  }),
  plotColors: PropTypes.array.isRequired,
  customXAxisLegendRenderer: PropTypes.func,
  normalLineB: PropTypes.number,
  normalLineM: PropTypes.number,
  series: PropTypes.array,
  yScaleType: PropTypes.oneOf(["linear", "logarithmic", "exponential"]),
  yScaleProperties: PropTypes.shape({
    exponent: PropTypes.number,
  }),
  baseline: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    xIntervals: PropTypes.arrayOf(PropTypes.number),
    yIntervals: PropTypes.arrayOf(PropTypes.number),
  }),
};
