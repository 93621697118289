import React from "react";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import SparklineLine from "../../../common/components/Sparkline/SparklineLine";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import SparklineLegends from "../../../common/components/Sparkline/SparklineLegends";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineTooltip from "../../../common/components/Sparkline/SparklineTooltip";
import * as d3 from "d3";
import { CIIScores, CIIScoresForAYear } from "./Types";

function getColor(i: number, steps: number) {
  const red = Math.max(0, 32 - (i * 25) / steps);
  const green = Math.max(0, 191 - (i * 149) / steps);
  const blue = Math.max(0, 230 - (i * 179) / steps);
  return `rgb(${red}, ${green}, ${blue})`;
}

type FleetCIIScoreSparklineProps = {
  data: CIIScores;
  padding?: number;
};

const FleetCIIScoreSparkline: React.FC<FleetCIIScoreSparklineProps> = ({
  data,
  padding = 0,
}: FleetCIIScoreSparklineProps) => {
  function getLegends() {
    const items = data.scores.map((_, i) => {
      return {
        text: (data.year - i).toString(),
        color: getColor(i, data.scores.length),
        dataSetIndex: i,
      };
    });
    return items.reverse();
  }

  function getLines() {
    return data.scores.map((_: CIIScoresForAYear, i: number) => {
      return (
        <SparklineLine
          curve={"Linear"}
          dataSetIndex={i}
          lastValueIndicator={i === 0}
          color={getColor(i, data.scores.length)}
          key={"CiiLine_" + (data.year - i)}
          showHoverPoint={false}
        />
      );
    });
  }

  function getToolTips() {
    const toolTips = data.scores.map((_: CIIScoresForAYear, i: number) => {
      return {
        dataSetIndex: i,
        color: getColor(i, data.scores.length),
        key: `CiiToolTip_${i}`,
      };
    });

    return <SparklineTooltip tooltips={toolTips} />;
  }

  const adjust = (dim: number): number => {
    if (dim === 0) {
      return dim;
    }
    return dim - padding;
  };

  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => {
        return (
          <Sparkline
            data={data.scores}
            width={adjust(width)}
            height={adjust(height)}
            enableMouseOver={true}
          >
            <SparklineGrid yTicks={5} xTicks={6} showX={true} />
            <SparklineLegends legends={getLegends()} />
            <SparklineXAxis tickFormat={d3.utcFormat("%b")} />
            <SparklineYAxis ticks={5} />
            <SparklineHover>
              {getLines()}
              {getToolTips()}
            </SparklineHover>
          </Sparkline>
        );
      }}
    </AutoSizer>
  );
};

export default FleetCIIScoreSparkline;
