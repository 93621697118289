import { calendarEvents } from "./action.types";
import { EVENTS } from "../api";
import { isNil, find, get } from "lodash";
import fetch from "./action.fetch";
import {
  dateWithTimezoneOffset,
  removeTimeOffsetFromDate,
} from "../common/dates";

export const fetchCalendarEvents = (vesselId, dateRange, timeOffset) =>
  fetch({
    url: EVENTS.getEventsForVessel(vesselId, dateRange, timeOffset),
    actionType: calendarEvents.FETCH_CALENDAR_EVENTS,
    actionData: { dateRange, timeOffset },
  });

export const addEvent = () => ({
  type: calendarEvents.ADD_EVENT,
});

export const toggleCalendarEvents = () => ({
  type: calendarEvents.TOGGLE_CALENDAR_EVENTS,
});

export const editEvent = (id) => (dispatch, getState) => {
  const { calendar, pageContext } = getState();
  const event = find(get(calendar, "events.data", []), (x) => x.id === id);
  if (isNil(event)) {
    return;
  }

  const eventType = get(pageContext, "eventTypes", {})[event.eventTypeId];
  if (isNil(eventType)) {
    return;
  }

  dispatch({
    type: calendarEvents.EDIT_EVENT,
    event,
    eventType,
  });
};

export const closeEventDialog = () => ({
  type: calendarEvents.CLOSE_EVENT_DIALOG,
});

export const eventFieldValueChanged = (
  field,
  value,
  isValid,
  message,
  properties
) => {
  return {
    type: calendarEvents.EVENT_FIELD_VALUE_CHANGED,
    field,
    value,
    isValid,
    message,
    properties,
  };
};

export const saveCalendarEvent =
  (vesselId, timeOffset) =>
  (dispatch, getState, { http }) => {
    const { calendar } = getState();
    const { eventDialog } = calendar;
    const { event } = eventDialog;
    const isNew = eventDialog.mode === "new";
    const data = {
      id: !isNew ? event.id.value : undefined,
      title: event.title.value,
      eventTypeId: event.eventTypeId.value.id,
      startTime: !isNil(event.startTime.value)
        ? // We remove the time offset and compensate for the local time zone in order to save the date in UTC in the data base.
          removeTimeOffsetFromDate(
            dateWithTimezoneOffset(event.startTime.value),
            timeOffset
          )
        : undefined,
      endTime: !isNil(event.endTime.value)
        ? removeTimeOffsetFromDate(
            dateWithTimezoneOffset(event.endTime.value),
            timeOffset
          )
        : undefined,
      description: event.description.value,
    };

    dispatch({
      type: calendarEvents.SAVE_EVENT,
      data: data,
    });
    const method = isNew ? "post" : "put";
    http({
      method: method,
      url: EVENTS.createUpdateEvent(vesselId),
      data,
    })
      .then((result) => {
        dispatch({
          type: calendarEvents.SAVE_EVENT_SUCCESS,
          data: result,
        });
        dispatch(
          fetchCalendarEvents(vesselId, calendar.date.range, timeOffset)
        );
      })
      .catch((error) => {
        dispatch({
          type: calendarEvents.SAVE_EVENT_ERROR,
          error,
        });
      });
  };

export const deleteCalendarEvent = (event) => ({
  type: calendarEvents.DELETE_EVENT,
  event,
});

export const confirmDeleteCalendarEvent =
  (vesselId, event, timeOffset) =>
  (dispatch, getState, { http }) => {
    const { calendar } = getState();
    dispatch({
      type: calendarEvents.CONFIRM_DELETE_EVENT,
      id: event.id.value,
    });
    http
      .delete(EVENTS.deleteEvent(event.id.value))
      .then((result) => {
        dispatch({
          type: calendarEvents.CONFIRM_DELETE_EVENT_SUCCESS,
          id: event.id.value,
          data: result,
        });
        dispatch(
          fetchCalendarEvents(vesselId, calendar.date.range, timeOffset)
        );
      })
      .catch((error) => {
        dispatch({
          type: calendarEvents.CONFIRM_DELETE_EVENT_ERROR,
          id: event.id.value,
          error,
        });
      });
  };
