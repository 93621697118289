import styles from "./DataGridView.css";
import React from "react";
import { Column } from "./Types";

type HeadersProps = {
  columns: Column[];
};

export const Headers: React.FC<HeadersProps> = ({ columns }: HeadersProps) => {
  return (
    <>
      {columns.map((c) => (
        <div className={styles.headerItem} key={c.field}>
          {c.name || " "}
          {!!c.link && (
            <span onClick={c.link.action}>
              <img src={c.link.icon} height={12} alt="edit" />
            </span>
          )}
        </div>
      ))}
    </>
  );
};
