import {
  ColumnSortingOptions,
  FleetPerformanceResponse,
  Vessel,
} from "../../views/FleetPerformance/types";
import { VESSELS } from "./fleetPerformanceMock";
import { Direction } from "../../common/direction";
import { DefaultBodyType, http, HttpResponse, StrictRequest } from "msw";
import { mockBaseUrl } from "../config";

const handleRequest = (
  request: StrictRequest<DefaultBodyType>,
  sortFunction: (
    searchParams: URLSearchParams
  ) => (a: Vessel, b: Vessel) => number
) => {
  const searchParams = new URL(request.url).searchParams;
  const page = Number(searchParams.get("pageNumber"));
  const numberOfVesselsPerPage = Number(searchParams.get("pageSize"));
  const sortedAscendingVessels = [...VESSELS].sort(sortFunction(searchParams));
  const sortedVessels =
    Number(searchParams.get("sortingOrder")) === Direction.LowToHigh
      ? sortedAscendingVessels
      : [...sortedAscendingVessels].reverse();

  const currentPageVessels = sortedVessels.slice(
    numberOfVesselsPerPage * (page - 1),
    numberOfVesselsPerPage * page
  );
  const response: FleetPerformanceResponse = {
    vessels: currentPageVessels,
    numberOfVessels: VESSELS.length,
  };
  return HttpResponse.json(response);
};

const fleetPerformanceOrderedByColumn = http.get(
  `${mockBaseUrl}/api/v2/fleet-performance/fleet-performance-ordered-by-column`,
  ({ request }) =>
    handleRequest(request, (searchParams) => (a, b) => {
      switch (Number(searchParams.get("sorting"))) {
        case ColumnSortingOptions.Name:
          return a.vessel.name.localeCompare(b.vessel.name);
        case ColumnSortingOptions.Trend:
          return a.trend.percentageValue - b.trend.percentageValue;
        case ColumnSortingOptions.Consumption:
          return (
            a.consumption
              .map((c) => c.consumption)
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              ) -
            b.consumption
              .map((c) => c.consumption)
              .reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              )
          );
        default:
          throw new Error(
            `Sorting option not handled ${searchParams.get("sorting")}`
          );
      }
    })
);

const fleetPerformanceOrderedByOperation = http.get(
  `${mockBaseUrl}/api/v2/fleet-performance/fleet-performance-ordered-by-operation`,
  ({ request }) =>
    handleRequest(request, (searchParams) => (a, b) => {
      const aConsumption = a.consumptionPerOperation
        .find((x) => x.operationId === Number(searchParams.get("sorting")))!
        .consumption.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.consumption,
          0
        );

      const bConsumption = b.consumptionPerOperation
        .find((x) => x.operationId === Number(searchParams.get("sorting")))!
        .consumption.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.consumption,
          0
        );

      return aConsumption - bConsumption;
    })
);

export const fleetPerformanceEndpoints = [
  fleetPerformanceOrderedByColumn,
  fleetPerformanceOrderedByOperation,
];
