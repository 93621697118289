import React from "react";
import { FormPopup, FormSelectBox } from "../../../components/Form";
import { connect } from "react-redux";
import { UserImportForm } from "./UserImportForm";
import {
  cancelUserImport,
  confirmUserImport,
  downloadUsersExample,
} from "../../../actions/admin/action.users";
import {
  fetchVesselGroups,
  setUserImportSelectedCompany,
  setUserImportSelectedGroup,
  onSelectedUserImportFile,
} from "../../../actions/admin";
import {
  selectAllCompanies,
  selectCompanyById,
} from "../../../reducers/admin/reducer.companies";
import {
  selectAllGroups,
  selectGroupById,
  selectGroupsByCompany,
} from "../../../reducers/admin/reducer.vesselGroups";
import { isCompanyAdmin } from "../../../reducers/reducer.user";
import styles from "./UserImportPopup.css";

class UserImportPopup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onPopupFormHide = this.onPopupFormHide.bind(this);
    this.onPopupFormSave = this.onPopupFormSave.bind(this);
    this.onPopupFormCancel = this.onPopupFormCancel.bind(this);
  }

  onPopupFormHide() {
    this.props.cancelUserImport();
  }

  onPopupFormSave() {
    this.props.confirmUserImport();
  }

  onPopupFormCancel() {
    this.props.cancelUserImport();
  }

  onCompanyChange(_name, company) {
    this.props.setUserImportSelectedCompany((company && company.id) || null);
  }

  onGroupChange(_name, group) {
    this.props.setUserImportSelectedGroup((group && group.id) || null);
  }
  onFileChange(_name, file) {
    this.props.onSelectedUserImportFile(file);
  }

  componentDidMount() {
    this.props.fetchVesselGroups();
  }

  handleDownloadClick(data, fileName) {
    this.props.downloadUsersExample(data, fileName);
  }

  render() {
    const { userImport, companies, groups, selectedCompany, selectedGroup } =
      this.props;

    const { showDialog, isSaving, isComplete, data, exampleData } = userImport;
    const enableSave = selectedCompany && !isSaving && !isComplete;

    if (!showDialog) {
      return <div />;
    }
    return (
      <FormPopup
        title="Import users"
        saveButtonText="Import"
        cancelButtonText={isComplete ? "Close" : "Cancel"}
        showDialog={showDialog}
        visible={true}
        onHide={this.onPopupFormHide}
        onCancel={this.onPopupFormHide}
        onSave={this.onPopupFormSave}
        enableSave={enableSave}
      >
        <FormSelectBox
          name="companyId"
          label="Company"
          selected={selectedCompany}
          options={companies}
          optionValKey="id"
          optionLabelKey="name"
          onChange={this.onCompanyChange}
          required
        />
        <FormSelectBox
          name="groupId"
          label="Group"
          selected={selectedGroup}
          options={groups}
          optionValKey="id"
          optionLabelKey="name"
          onChange={this.onGroupChange}
        />
        <UserImportForm onFileChange={this.onFileChange} data={data} />
        <div className={styles.infoText}>
          <p>
            <a
              onClick={() =>
                this.handleDownloadClick(exampleData, `example_users.xlsx`)
              }
            >
              Download example data
            </a>
          </p>
        </div>
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => {
  const companies = selectAllCompanies(state.admin.companies);
  const allGroups = selectAllGroups(state.admin.vesselGroups);
  const userImport = state.admin.users.userImport;
  const currentUser = state.user;
  const companyAdmin = isCompanyAdmin(currentUser);

  return {
    companies,
    allGroups,
    groups: selectGroupsByCompany(
      userImport.selectedCompanyId,
      state.admin.vesselGroups
    ),
    selectedCompany: selectCompanyById(
      userImport.selectedCompanyId,
      state.admin.companies
    ),
    selectedGroup: selectGroupById(
      userImport.selectedGroupId,
      state.admin.vesselGroups
    ),
    userImport,
    companyAdmin,
  };
};

const mapDispatchToProps = {
  confirmUserImport,
  cancelUserImport,
  fetchVesselGroups,
  setUserImportSelectedCompany,
  setUserImportSelectedGroup,
  onSelectedUserImportFile,
  downloadUsersExample,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserImportPopup);
