import React from "react";
import PropTypes from "prop-types";
import classNames from "../../../common/classNames";
import styles from "./DataDetails.css";

class DataDetailsComponentContainer extends React.PureComponent {
  render() {
    const { children, style, fullWidth } = this.props;
    return (
      <div
        className={classNames(
          styles.componentContainer,
          fullWidth && styles.fullWidth
        )}
        style={style}
      >
        {children}
      </div>
    );
  }
}

DataDetailsComponentContainer.propTypes = {
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default DataDetailsComponentContainer;
