import { v4 as uuidv4 } from "uuid";
let idCounter = 0;

const getNextId = () => {
  if (idCounter >= 1000) {
    idCounter = 0;
  }
  idCounter++;
  return idCounter;
};

// NB! Use window.crypto.randomUUID if possisble instead: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
// window.crypto.randomUUID is only available in secure contexts (meaning https or localhosts), though.
// Using the uuid module, we at least use something well tested, and it uses the getRandomValues() of the Crypto interface,
// which can be called from an insecure context.
export const generateUUID = () => uuidv4();

export const getUniqueId = () => `${Date.now()}${getNextId()}`;
