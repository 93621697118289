import React, { Component } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { ESC } from "../../common/keyCodes";
import classNames from "../../../common/classNames";
import styles from "./Popup.css";
import { Portal } from "react-portal";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, true);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, true);
  }

  handleKeyUp(event) {
    if (event.keyCode === ESC) {
      this.props.onHide();
    }
  }

  renderFooter() {
    const { buttons, transparentContent } = this.props;
    const footerClassName = transparentContent
      ? classNames(styles.footer, styles.dark_background)
      : styles.footer;
    if (!buttons) return;
    return (
      <div className={footerClassName}>
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`${styles.footerButton} ${
              button.type === "alert" ? styles.footerButtonAlert : ""
            }`}
            disabled={button.disabled === true}
            onClick={button.action}
            value={button.label}
          >
            {button.label}
          </button>
        ))}
      </div>
    );
  }

  render() {
    const {
      children,
      title,
      visible,
      onHide,
      target,
      testId,
      transparentContent,
    } = this.props;
    if (!visible) {
      return <div />;
    }

    const popupContainerClassName = transparentContent
      ? styles.popupContainer
      : classNames(styles.popupContainer, styles.dark_background);
    const headerContainerClassName = transparentContent
      ? classNames(styles.headerContainer, styles.dark_background)
      : styles.headerContainer;

    return (
      <Portal node={document && document.getElementById(target)}>
        <div className={styles.popupOuterContainer}>
          <div className={styles.overlay} onClick={onHide} />
          <div className={popupContainerClassName} data-testid={testId}>
            <div className={styles.contentContainer}>
              <div className={headerContainerClassName}>
                <div className={styles.header}>{title}</div>
                <button className={styles.closeButton} onClick={onHide}>
                  ×
                </button>
              </div>
            </div>
            <div className={styles.content}>{children}</div>
            {this.renderFooter()}
          </div>
        </div>
      </Portal>
    );
  }
}

Popup.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
  target: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ),
  testId: PropTypes.string,
  transparentContent: PropTypes.bool,
};

Popup.defaultProps = {
  onHide: noop,
  onShow: noop,
};

export default Popup;
