import React from "react";
import PropTypes from "prop-types";
import styles from "./SparklineGraph.css";
import * as d3 from "d3";
import { color } from "../../colors";

export default class SparklineGraph extends React.PureComponent {
  constructor(props) {
    super(props);
    this.minColor = color("--bright-red");
    this.maxColor = color("--bright-green");
    this.passiveColor = color("--blue-base");
    this.zeroColor = color("--dark-grey");
  }

  setupDomain() {
    const { trend, minValue, maxValue, negative } = this.props;
    this.zeroValue = trend ? (minValue + maxValue) / 2 : minValue;
    this.scaleY = d3.scaleLinear().domain([minValue, maxValue]).range([0, 100]);
    this.scaledZeroValue = this.scaleY(this.zeroValue);
    const getColorsArray = () =>
      negative
        ? [this.maxColor, this.zeroColor, this.minColor]
        : [this.minColor, this.zeroColor, this.maxColor];
    this.colorScale = d3
      .scaleLinear()
      .domain(
        trend ? [minValue, this.zeroValue, maxValue] : [minValue, maxValue]
      )
      .range(trend ? getColorsArray() : [this.zeroColor, this.passiveColor]);
  }

  getValueStyle(value) {
    const { minValue, maxValue } = this.props;
    const cappedValue = Math.min(Math.max(value, minValue), maxValue);
    const scaledValue = this.scaleY(cappedValue);
    const style = {};
    if (scaledValue < this.scaledZeroValue) {
      style.top = this.scaledZeroValue + "%";
      style.height = this.scaledZeroValue - scaledValue + "%";
    } else {
      style.top = 100 - scaledValue + "%";
      style.height = scaledValue - this.scaledZeroValue + "%";
    }
    const fromColor = this.colorScale(Math.min(this.zeroValue, cappedValue));
    const toColor = this.colorScale(Math.max(this.zeroValue, cappedValue));
    style.background = `linear-gradient(to top, ${fromColor}, ${toColor})`;
    return style;
  }

  render() {
    const { trend, data } = this.props;

    this.setupDomain();

    return (
      <ul className={styles.sparklineGraph} data-is-trend={trend}>
        {data.map((value, index) => {
          return (
            <li title={value} key={index} className={styles.barContainer}>
              <span
                className={styles.valueChunk}
                style={this.getValueStyle(value)}
              />
            </li>
          );
        })}
      </ul>
    );
  }
}

SparklineGraph.defaultProps = {
  minValue: -30,
  maxValue: 30,
  trend: true,
  negative: false,
};

SparklineGraph.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  trend: PropTypes.bool,
  negative: PropTypes.bool,
  data: PropTypes.array.isRequired,
};
