import React from "react";
import PropTypes from "prop-types";
import InlinePanel from "../InlinePanel/InlinePanel";
import FormSelectBox from "../../../../components/Form/FormSelectBox/FormSelectBox";
import FormTextBox from "../../../../components/Form/FormTextBox/FormTextBox";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import { get, matchesProperty, compact, isEmpty } from "lodash";
import styles from "./VesselPerformanceIndicatorsController.css";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog";

class VesselPerformanceIndicatorForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onInputChanged = this.onInputChanged.bind(this);
    this.onSelectChanged = this.onSelectChanged.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
  }

  getButtons({ hasChanges, isLoading, isValid }) {
    return compact([
      this.props.rowId && {
        label: "Delete",
        action: this.onDelete,
        disabled: isLoading,
      },
      {
        label: "Cancel",
        action: this.onCancel,
        disabled: isLoading,
      },
      {
        label: "Save",
        action: this.onSave,
        disabled: !hasChanges || !isValid || isLoading,
      },
    ]);
  }

  onCancel() {
    this.props.cancelAction(this.props.rowId);
  }
  onSave() {
    this.props.saveAction(this.props.rowId);
  }

  onInputChanged(name, value, valid, message) {
    this.props.inputChanged(this.props.rowId, name, value, valid, message);
  }

  onSelectChanged(name, value, valid, message) {
    if (!this.props.form.isNew) {
      value = [value];
    }
    this.props.inputChanged(this.props.rowId, name, value, valid, message);
  }
  onDelete() {
    this.props.deleteAction(this.props.rowId);
  }
  onCancelDelete() {
    this.props.cancelDeleteAction(this.props.rowId);
  }
  onConfirmDelete() {
    this.props.confirmDeleteAction(this.props.rowId);
  }

  render() {
    const { form, performanceIndicators } = this.props;
    const isNew = form.isNew;
    const selectedPerformanceIndicator = performanceIndicators.find(
      matchesProperty(
        "id",
        get(
          form,
          "fields.performanceIndicators.value[0].performanceIndicatorId"
        )
      )
    );
    let selectedPerformanceIndicators;
    let performanceIndicatorIds = get(
      form,
      "fields.performanceIndicators.value"
    )?.map((x) => x.performanceIndicatorId);
    if (!isEmpty(performanceIndicatorIds)) {
      selectedPerformanceIndicators = performanceIndicators.filter((vpi) => {
        return performanceIndicatorIds.includes(vpi.id);
      });
    }
    const buttons = this.getButtons(form);

    return (
      <InlinePanel
        title={this.props.title}
        buttons={buttons}
        isLoading={form.isLoading}
        error={form.error}
      >
        <FormGroup>
          <div className={styles.container}>
            <div className={styles.column}>
              <FormSelectBox
                name="performanceIndicators"
                label="Performance indicator"
                selected={
                  isNew
                    ? selectedPerformanceIndicators
                    : selectedPerformanceIndicator
                }
                options={performanceIndicators}
                optionValKey="id"
                optionLabelKey="label"
                onChange={this.onSelectChanged}
                required
                multi={isNew}
                testId={"performanceIndicatorIdSelect"}
              />
            </div>
            {form.showMinMaxValue && (
              <div className={styles.column}>
                <FormTextBox
                  name="minValue"
                  label="Min Value"
                  value={get(form, "fields.minValue.value")}
                  onChange={this.onInputChanged}
                  type={"number"}
                />
                <FormTextBox
                  name="maxValue"
                  label="Max Value"
                  value={get(form, "fields.maxValue.value")}
                  onChange={this.onInputChanged}
                  type={"number"}
                />
              </div>
            )}
            {this.props.form.isDeleting && (
              <ConfirmDeleteDialog
                onCancelClick={this.onCancelDelete}
                onConfirmClick={this.onConfirmDelete}
                title={"Delete performance indicator"}
                message="Are you sure you want to delete this performance indicator? This action can not be undone."
              />
            )}
          </div>
        </FormGroup>
      </InlinePanel>
    );
  }
}

VesselPerformanceIndicatorForm.propTypes = {
  performanceIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string,
  form: PropTypes.shape({
    fields: PropTypes.shape({
      id: PropTypes.object,
      performanceIndicators: PropTypes.object,
      minValue: PropTypes.object,
      maxValue: PropTypes.object,
    }),
    isLoading: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool,
    isDeleting: PropTypes.bool,
    error: PropTypes.string,
  }),
  rowId: PropTypes.string,
  inputChanged: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  cancelDeleteAction: PropTypes.func.isRequired,
  confirmDeleteAction: PropTypes.func.isRequired,
};

export default VesselPerformanceIndicatorForm;
