import T from "prop-types";
import React from "react";
import { VESSELMARKERS } from "../../config";
import styles from "./VesselMapMarker.css";
import classNames from "../../../common/classNames";
import { fromISOString, formatDateTime } from "../../common/dates";

const altText = "Icon representing vessel connection and efficiency";

const titleText = (vesselName, efficiency, connection, positionDate) => {
  connection =
    connection === "connected"
      ? "connected"
      : "not connected. Time of last position: " +
        formatDateTime(fromISOString(positionDate));
  efficiency = efficiency === "noValue" ? "" : `, ${efficiency}`;
  return `${vesselName} (${connection}${efficiency})`;
};

const VesselMapMarker = ({
  efficiency,
  connection,
  vesselName,
  showVesselName,
  position,
}) => (
  <div
    className={classNames(
      styles.container,
      showVesselName && styles.showLabel,
      connection === "notConnected" && styles.notConnected
    )}
  >
    {!efficiency ? (
      <img
        src={VESSELMARKERS.base64.states.inactive}
        title="Connection or efficiency data not sufficient to show accurate icon"
        alt={altText}
      />
    ) : (
      <img
        className={styles.marker}
        src={VESSELMARKERS.base64[efficiency][connection]}
        title={titleText(
          vesselName,
          efficiency,
          connection,
          position.positionDate
        )}
        alt={altText}
      />
    )}
    <div className={styles.label}>
      <span>{vesselName}</span>
    </div>
  </div>
);

VesselMapMarker.propTypes = {
  connection: T.string,
  efficiency: T.string,
  vesselName: T.string,
  showVesselName: T.bool,
};

export default VesselMapMarker;
