import * as d3 from "d3";
import {
  isEmpty,
  isNumber,
  max,
  min,
  isNil,
  last,
  first,
  isDate,
} from "lodash";
import { Renderer } from "@pixi/core";
import "@pixi/unsafe-eval";
import * as PIXI from "pixi.js";
import PropTypes from "prop-types";
import React from "react";
import { color } from "../../../common/colors";
import Loader from "../../../common/components/Loader/Loader";
import { formatNumber } from "../../../common/numbers";
import { COSMETIC_CONSTS } from "../../config";
import styles from "./PlotChart.css";

const MARGIN_LEFT = 80;
const MARGIN_BOTTOM = 80;
const MARGIN_RIGHT = 50;
const MARGIN_TOP = 45;

export const PLOT_COLOR_1 = "#1bc3f7";
export const PLOT_COLOR_2 = "#efce65";
export const PLOT_COLOR_3 = "#fc406a";

const PLOT_COLORS = [
  d3.rgb(PLOT_COLOR_1),
  d3.rgb(PLOT_COLOR_2),
  d3.rgb(PLOT_COLOR_3),
];

const defaultAxisLabelTextStyle = {
  fontSize: "14px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--light-grey"),
  fontWeight: "400",
  textAlign: "right",
};

const defaultHoverLabelTextStyle = {
  fontSize: "16px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: color("--page-background"),
  fontWeight: "bold",
  textAlign: "center",
};

export class PlotChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.performRenderStage = this.performRenderStage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this.setupScales();
      this.setupAxis();
      this.addRegressionLine();
      this.addCustomElements();
    }

    if (this.props.zDomain !== prevProps.zDomain) {
      this.recalculateZScale();
    }

    if (this.props.data !== prevProps.data) {
      this.setupScales();
      this.setupAxis();
      this.addData();
      this.addRegressionLine();
      this.addNormalLine();
      this.addCustomElements();
    } else {
      this.addRegressionLine();
      this.updateData();
      this.addNormalLine();
      this.addCustomElements();
    }

    this.renderStage();
  }

  componentDidMount() {
    this.setupRenderer();
    this.setupTextures();
    this.setupScales();
    this.setupAxis();
    this.addData();
    this.addRegressionLine();
    this.addNormalLine();
    this.addCustomElements();
    this.renderStage();
  }

  componentWillUnmount() {
    this.renderer.destroy();
  }

  setupRenderer() {
    const { width, height } = this.props;
    PIXI.utils.skipHello();
    this.renderer = new Renderer({
      width,
      height,
      backgroundAlpha: 0,
      antialias: true,
    });
    this.stage = new PIXI.Container();
    this.axisContainer = new PIXI.Container();
    this.plotContainer = new PIXI.Container();
    this.hoverContainer = new PIXI.Container();
    this.regressionContainer = new PIXI.Container();
    this.normalContainer = new PIXI.Container();
    this.customContainer = new PIXI.Container();
    this.customHoverContainer = new PIXI.Container();
    this.blurFilter = new PIXI.BlurFilter();
    this.plotContainer.filters = [this.blurFilter];
    this.blurFilter.enabled = false;
    this.stage.addChild(this.axisContainer);
    this.stage.addChild(this.plotContainer);
    this.stage.addChild(this.regressionContainer);
    this.stage.addChild(this.normalContainer);
    this.stage.addChild(this.customContainer);
    this.stage.addChild(this.hoverContainer);
    this.stage.addChild(this.customHoverContainer);
    this.targetDiv.appendChild(this.renderer.view);
  }

  setupTextures() {
    const circleGfx = new PIXI.Graphics();
    circleGfx.beginFill(hexStringToInt(color("--white")));
    circleGfx.drawCircle(3, 3, this.props.plotStyle.circleRadius);
    circleGfx.endFill();
    this.circleTexture = this.renderer.generateTexture(circleGfx);

    const hoverGfx = new PIXI.Graphics();
    hoverGfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
    hoverGfx.beginFill(hexStringToInt(color("--white")));
    hoverGfx.drawRoundedRect(0, 0, 100, 32, 5);
    hoverGfx.endFill();
    hoverGfx.lineStyle(0);
    hoverGfx.beginFill(hexStringToInt(color("--white")));
    hoverGfx.moveTo(40, 30);
    hoverGfx.lineTo(50, 42);
    hoverGfx.lineTo(60, 30);
    hoverGfx.endFill();
    hoverGfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
    hoverGfx.moveTo(40, 32);
    hoverGfx.lineTo(50, 42);
    hoverGfx.lineTo(60, 32);

    this.hoverBackgroundTexture = this.renderer.generateTexture(hoverGfx);
  }

  setupScales() {
    const { data, width, height, margins, axisLabelStyle } = this.props;
    const { minX, maxX } = this.getXMinMax(data);

    this.margins = {
      left: margins.left,
      top: margins.top,
      right: margins.right,
      bottom: margins.bottom,
    };

    let minY, maxY;
    if (this.hasNormalLine()) {
      const n1 = this.normalFunction(minX);
      const n2 = this.normalFunction(maxX);
      minY =
        Math.min(
          n1,
          n2,
          d3.min(data, (v) => v.y)
        ) || 0;
      maxY =
        Math.max(
          n1,
          n2,
          d3.max(data, (v) => v.y)
        ) || 100;
    } else {
      minY = Math.min(d3.min(data, (v) => v.y)) || 0;
      maxY = Math.max(d3.max(data, (v) => v.y)) || 100;
    }

    const { yDomain, scaleY, ticksY } = this.getYDomainInfo(minY, maxY);

    let maxYTickWidth = 0;
    if (ticksY && ticksY.length > 0) {
      const axisLabelTextStyle = new PIXI.TextStyle(axisLabelStyle);
      maxYTickWidth = max(
        ticksY.map((x) => {
          const yAxisLabel = new PIXI.Text(x, axisLabelTextStyle);
          return yAxisLabel.width;
        })
      );
    }

    this.margins = {
      ...this.margins,
      left: this.margins.left + maxYTickWidth,
    };

    const { scaleX, ticksX, xDomain } = this.getXDomainInfo();

    this.domain = {
      minX,
      minY,
      maxX,
      maxY,
      scaleX,
      ticksX,
      scaleY,
      ticksY,
      yDomain,
      xDomain,
    };

    this.recalculateZScale();

    this.renderer.resize(width, height);
  }

  getXMinMax(data) {
    const minX = d3.min(data, (v) => v.x) || 0;
    const maxX = d3.max(data, (v) => v.x) || 100;
    return {
      minX,
      maxX,
    };
  }

  getXDomainInfo() {
    const { data, width } = this.props;
    const { minX, maxX } = this.getXMinMax(data);
    const xDomain = isNil(this.props.xDomain)
      ? [minX, maxX]
      : this.props.xDomain;

    const scaleX = isDate(minX)
      ? d3
          .scaleTime()
          .domain(xDomain)
          .range([this.margins.left, width - this.margins.right])
      : d3
          .scaleLinear()
          .domain(xDomain)
          .range([this.margins.left, width - this.margins.right]);

    const ticksX = scaleX.ticks();
    return {
      xDomain,
      scaleX,
      ticksX,
    };
  }

  getYDomainInfo(minY, maxY) {
    const { height } = this.props;
    const yDomain = isNil(this.props.yDomain)
      ? [minY, maxY]
      : this.props.yDomain;

    const scaleY = d3
      .scaleLinear()
      .domain(yDomain)
      .range([height - this.margins.bottom, this.margins.top]);
    const ticksY = scaleY.ticks(10);

    return {
      yDomain,
      scaleY,
      ticksY,
    };
  }

  recalculateZScale() {
    const { zDomain, data, plotColors } = this.props;
    const minZ = zDomain ? zDomain.min : d3.min(data, (v) => v.z);
    const maxZ = zDomain ? zDomain.max : d3.max(data, (v) => v.z);
    this.domain.scaleZ = d3
      .scaleLinear()
      .domain([minZ, (maxZ - minZ) / 2 + minZ, maxZ])
      .range(plotColors)
      .interpolate(d3.interpolateHcl);
    this.domain.minZ = minZ;
    this.domain.maxZ = maxZ;
  }

  setupAxis() {
    const {
      height,
      width,
      xLabel,
      xUnit,
      yLabel,
      yUnit,
      axisLabelStyle,
      customXAxisLegendRenderer,
      customYAxisLegendRenderer,
      plotStyle,
    } = this.props;
    const margins = this.margins;
    const axisLabelTextStyle = new PIXI.TextStyle(axisLabelStyle);
    const { ticksY, ticksX, scaleY, scaleX } = this.domain;
    const gfx = new PIXI.Graphics();
    this.axisContainer.removeChildren();
    this.axisContainer.addChildAt(gfx, 0);
    this.customHoverContainer.removeChildren();

    const yAxis = {
      x1: margins.left,
      y1: height - margins.bottom,
      x2: margins.left,
      y2: margins.top,
    };
    const xAxis = {
      x1: margins.left,
      y1: height - margins.bottom,
      x2: width - margins.right,
      y2: height - margins.bottom,
    };

    for (const tickY of ticksY) {
      let yVal = scaleY(tickY);
      gfx.lineStyle(2, hexStringToInt(color("--light-grey")));
      gfx.moveTo(yAxis.x1, yVal);
      gfx.lineTo(yAxis.x1 - plotStyle.circleRadius, yVal);
      gfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
      gfx.moveTo(xAxis.x1, yVal);
      gfx.lineTo(xAxis.x2, yVal);
      const label = new PIXI.Text(tickY, axisLabelTextStyle);
      label.x = yAxis.x1 - label.width - 10;
      label.y = yVal - 7;
      this.axisContainer.addChildAt(label, 0);
    }

    for (const tickX of ticksX) {
      let xVal = scaleX(tickX);
      gfx.lineStyle(2, hexStringToInt(color("--light-grey")));
      gfx.moveTo(xVal, xAxis.y1);
      gfx.lineTo(xVal, xAxis.y1 + plotStyle.circleRadius);
      gfx.lineStyle(2, hexStringToInt(color("--dark-grey")));
      gfx.moveTo(xVal, yAxis.y1);
      gfx.lineTo(xVal, yAxis.y2);
      const label = new PIXI.Text(
        this.formatTickLabel(tickX),
        axisLabelTextStyle
      );
      label.x = xVal - label.width / 2;
      label.y = xAxis.y1 + 10;
      this.axisContainer.addChildAt(label, 0);
    }

    if (xLabel || xUnit) {
      let xAxisLabel;
      if (customXAxisLegendRenderer) {
        xAxisLabel = customXAxisLegendRenderer({
          xLabel,
          xUnit,
          margins,
          width,
          height,
          axisLabelTextStyle,
        });
      } else {
        xAxisLabel = new PIXI.Text(
          this.formatAxisLabel(xLabel, xUnit),
          axisLabelTextStyle
        );
        xAxisLabel.x =
          (width - margins.right - margins.left) / 2 +
          margins.left -
          xAxisLabel.width / 2;
        xAxisLabel.y = height - margins.bottom + 40;
      }
      this.axisContainer.addChild(xAxisLabel);
    }

    if (yLabel || yUnit) {
      let yAxisLabel;
      if (customYAxisLegendRenderer) {
        yAxisLabel = customYAxisLegendRenderer({
          yLabel,
          yUnit,
          margins,
          width,
          height,
          axisLabelTextStyle,
          performRenderStage: this.performRenderStage,
        });
        this.customHoverContainer.addChild(yAxisLabel);
      } else {
        yAxisLabel = new PIXI.Text(
          this.formatAxisLabel(yLabel, yUnit),
          axisLabelTextStyle
        );
        yAxisLabel.rotation = -(Math.PI / 2);
        yAxisLabel.x = margins.left - 50 - yAxisLabel.height;
        yAxisLabel.y =
          (height - margins.bottom - margins.top) / 2 + margins.top;
        this.axisContainer.addChild(yAxisLabel);
      }
    }

    gfx.lineStyle(2, hexStringToInt(color("--light-grey")));
    gfx.moveTo(yAxis.x1, yAxis.y1);
    gfx.lineTo(yAxis.x2, yAxis.y2);
    gfx.moveTo(xAxis.x1, xAxis.y1);
    gfx.lineTo(xAxis.x2, xAxis.y2);
    this.renderStage();
  }

  formatAxisLabel(label, unit) {
    const unitOrEmpty = unit ? ` (${unit})` : "";
    return `${label ?? ""}${unitOrEmpty}`;
  }

  formatTickLabel(label) {
    return (
      (this.props.formatTickLabel && this.props.formatTickLabel(label)) || label
    );
  }

  isInsideZDomain(val) {
    return val.z >= this.domain.minZ && val.z <= this.domain.maxZ;
  }

  updateData() {
    const { data } = this.props;
    for (let i = 0; i < data.length; i++) {
      const val = data[i];
      const sprite = this.plotContainer.getChildAt(i);
      const x = this.domain.scaleX(val.x) - this.props.plotStyle.circleRadius;
      const y = this.domain.scaleY(val.y) - this.props.plotStyle.circleRadius;
      sprite.x = x;
      sprite.y = y;
      sprite.alpha = this.props.plotStyle.alpha;
      sprite.visible = this.isInsideZDomain(val);
      sprite.tint = rgbToHex(this.domain.scaleZ(val.z));
    }
  }

  addData() {
    const {
      data,
      xUnit,
      yUnit,
      zUnit,
      hoverLabelStyle,
      customHoverItemRenderer,
      zDataType,
      plotStyle,
    } = this.props;
    const hoverLabelTextStyle = new PIXI.TextStyle(hoverLabelStyle);
    this.plotContainer.removeChildren();
    for (const dataValue of data) {
      const val = dataValue;
      const sprite = new PIXI.Sprite(this.circleTexture);
      const x = this.domain.scaleX(val.x) - plotStyle.circleRadius;
      const y = this.domain.scaleY(val.y) - plotStyle.circleRadius;
      let spriteHoverContainer;
      sprite.x = x;
      sprite.y = y;
      sprite.buttonMode = true;
      sprite.alpha = plotStyle.alpha;
      sprite.visible = this.isInsideZDomain(val);
      sprite.tint = rgbToHex(this.domain.scaleZ(val.z));
      this.plotContainer.addChild(sprite);
      sprite.interactive = true;
      sprite.on("mouseover", () => {
        const { width, height } = this.props;
        if (customHoverItemRenderer) {
          spriteHoverContainer = customHoverItemRenderer({
            sprite,
            value: val,
            domain: this.domain,
            xUnit,
            yUnit,
            zUnit,
            hoverLabelTextStyle,
            margins: this.margins,
            width,
            height,
            zDataType,
          });
        } else {
          spriteHoverContainer = new PIXI.Container();
          const hoverSprite = new PIXI.Sprite(this.hoverBackgroundTexture);
          hoverSprite.tint = rgbToHex(this.domain.scaleZ(val.z));
          spriteHoverContainer.addChild(hoverSprite);
          const label = new PIXI.Text(
            `${formatNumber(val.z)}${zUnit ? " " + zUnit : ""}`,
            hoverLabelTextStyle
          );
          label.x = 50 - label.width / 2;
          label.y = 18 - label.height / 2;
          spriteHoverContainer.addChild(label);
          spriteHoverContainer.x = sprite.x - 45;
          spriteHoverContainer.y = sprite.y - 42;
        }
        this.hoverContainer.addChild(spriteHoverContainer);
        sprite.alpha = 1.0;
        this.performRenderStage();
      });
      sprite.on("mouseout", () => {
        this.hoverContainer.removeChild(spriteHoverContainer);
        sprite.alpha = plotStyle.alpha;
        this.performRenderStage();
      });
    }
  }

  addRegressionLine() {
    this.regressionContainer.removeChildren();
    const { data, showRegressionLine, stdDeviation, regressionLineStyle } =
      this.props;
    if (showRegressionLine !== true) {
      return;
    }
    if (isEmpty(data)) {
      return;
    }

    const xData = data.map((d) => d.x);
    const yData = data.map((d) => d.y);
    const gfx = new PIXI.Graphics();

    this.regressionContainer.addChildAt(gfx, 0);
    const regressionFx = createRegressionLineFormula(
      xData,
      yData,
      this.domain.yDomain
    );
    const stdDev = this.calculateStandardDeviation(data, regressionFx);

    const minX = min(xData);
    const maxX = max(xData);

    gfx.lineStyle(
      regressionLineStyle.lineWidth,
      hexStringToInt(regressionLineStyle.color),
      0.75
    );
    const [x1, y1] = regressionFx(minX);
    const [x2, y2] = regressionFx(maxX);

    gfx.moveTo(this.domain.scaleX(x1), this.domain.scaleY(y1));
    gfx.lineTo(this.domain.scaleX(x2), this.domain.scaleY(y2));
    if (isNumber(stdDeviation) && stdDeviation > 0) {
      const [x3, y3] = regressionFx(minX, +stdDeviation * stdDev);
      const [x4, y4] = regressionFx(maxX, +stdDeviation * stdDev);
      const [x5, y5] = regressionFx(minX, -stdDeviation * stdDev);
      const [x6, y6] = regressionFx(maxX, -stdDeviation * stdDev);
      gfx.lineStyle(1.5, hexStringToInt(color("--blue-base")), 0.5);
      gfx.moveTo(this.domain.scaleX(x3), this.domain.scaleY(y3));
      gfx.lineTo(this.domain.scaleX(x4), this.domain.scaleY(y4));
      gfx.moveTo(this.domain.scaleX(x5), this.domain.scaleY(y5));
      gfx.lineTo(this.domain.scaleX(x6), this.domain.scaleY(y6));
    }
  }

  hasNormalLine() {
    return this.props.normalLineB || this.props.normalLineM;
  }

  normalFunction(x) {
    return this.props.normalLineM * x + this.props.normalLineB;
  }

  addNormalLine() {
    this.normalContainer.removeChildren();

    if (!this.hasNormalLine()) {
      return;
    }
    const { minX, maxX, scaleX, scaleY } = this.domain;
    const p1 = [scaleX(minX), scaleY(this.normalFunction(minX))];
    const p2 = [scaleX(maxX), scaleY(this.normalFunction(maxX))];
    const gfx = new PIXI.Graphics();
    this.normalContainer.addChildAt(gfx, 0);
    gfx.lineStyle(1.5, hexStringToInt(color("--white")), 0.75);
    gfx.moveTo(...p1);
    gfx.lineTo(...p2);
  }

  addCustomElements() {
    this.customContainer.removeChildren();
    return (
      this.props.customElements &&
      this.props.customElements(
        this.props.data,
        this.domain,
        this.customContainer,
        this.margins,
        this.performRenderStage
      )
    );
  }

  calculateStandardDeviation(data, regressionFx) {
    const reduceAddition = (prev, cur) => prev + cur;
    const squared = data.map((d) => Math.pow(d.y - regressionFx(d.x)[1], 2));
    const squaredSum = squared.reduce(reduceAddition);
    const intermediate = squaredSum / (data.length - 2);
    return Math.sqrt(intermediate);
  }

  renderStage() {
    const { isLoading } = this.props;
    this.blurFilter.enabled = !!isLoading;
    requestAnimationFrame(this.performRenderStage);
  }

  performRenderStage() {
    this.renderer.render(this.stage);
  }

  render() {
    const { width, height, isLoading } = this.props;
    return (
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          position: "relative",
        }}
        className={styles.plotChartContainer}
      >
        {isLoading && (
          <Loader text="Loading" className={styles.loader} expand={true} />
        )}
        <div
          ref={(el) => (this.targetDiv = el)}
          style={{ width: `${width}px`, height: `${height}px` }}
        />
      </div>
    );
  }
}

export function createRegressionLineFormula(xData, yData, yDomain) {
  const reduceAddition = (prev, cur) => prev + cur;
  const xBar = (xData.reduce(reduceAddition) * 1.0) / xData.length;
  const yBar = (yData.reduce(reduceAddition) * 1.0) / yData.length;
  const sortedYDomain = yDomain.sort((a, b) => a - b);
  const minYDomain = first(sortedYDomain);
  const maxYDomain = last(sortedYDomain);

  const squareXX = xData
    .map((d) => Math.pow(d - xBar, 2))
    .reduce(reduceAddition);

  const meanDiffXY = xData
    .map((d, i) => (d - xBar) * (yData[i] - yBar))
    .reduce(reduceAddition);
  const slope = meanDiffXY / squareXX;
  const intercept = yBar - xBar * slope;
  return (xVal, b = 0) => {
    let x = xVal;
    let y = x * slope + intercept + b;
    if (y < minYDomain) {
      x = (minYDomain - intercept - b) / slope;
      y = x * slope + intercept + b;
    } else if (y > maxYDomain) {
      x = (maxYDomain - intercept - b) / slope;
      y = x * slope + intercept + b;
    }
    return [x, y];
  };
}

PlotChart.defaultProps = {
  margins: {
    left: MARGIN_LEFT,
    top: MARGIN_TOP,
    right: MARGIN_RIGHT,
    bottom: MARGIN_BOTTOM,
  },
  regressionLineStyle: {
    lineWidth: 1.75,
    color: color("--red-base"),
  },
  plotStyle: {
    circleRadius: 6,
    alpha: 0.7,
  },
  plotColors: PLOT_COLORS,
  axisLabelStyle: defaultAxisLabelTextStyle,
  hoverLabelStyle: defaultHoverLabelTextStyle,
  showRegressionLine: false,
  stdDeviation: 0,
  normalLineB: 0,
  normalLineM: 0,
  forceCanvas: false,
};

PlotChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  zLabel: PropTypes.string,
  xUnit: PropTypes.string,
  yUnit: PropTypes.string,
  zUnit: PropTypes.string,
  zDomain: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  yDomain: PropTypes.arrayOf(PropTypes.number),
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)])
        .isRequired,
      y: PropTypes.number.isRequired,
      z: PropTypes.number.isRequired,
    })
  ),
  margins: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
  }),
  axisLabelStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fill: PropTypes.string,
    fontWeight: PropTypes.string,
    textAlign: PropTypes.string,
  }),
  hoverLabelStyle: PropTypes.shape({
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    fill: PropTypes.string,
    fontWeight: PropTypes.string,
    textAlign: PropTypes.string,
  }),
  regressionLineStyle: PropTypes.shape({
    lineWidth: PropTypes.number,
    color: PropTypes.string,
  }),
  plotStyle: PropTypes.shape({
    circleRadius: PropTypes.number.isRequired,
    alpha: PropTypes.number.isRequired,
  }),
  plotColors: PropTypes.array.isRequired,
  customXAxisLegendRenderer: PropTypes.func,
  customYAxisLegendRenderer: PropTypes.func,
  customHoverItemRenderer: PropTypes.func,
  showRegressionLine: PropTypes.bool,
  stdDeviation: PropTypes.number,
  normalLineB: PropTypes.number,
  normalLineM: PropTypes.number,
  forceCanvas: PropTypes.bool,
};

export const rgbToHex = (rgb) => {
  const digits = /(.*?)rgb\((\d+), (\d+), (\d+)\)/.exec(rgb);
  const red = parseInt(digits[2]);
  const green = parseInt(digits[3]);
  const blue = parseInt(digits[4]);
  return blue | (green << 8) | (red << 16);
};

export const hexStringToInt = (hex) => parseInt(hex.replace(/^#/, ""), 16);
