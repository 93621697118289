import { Column, DataItem } from "./Types";
import React from "react";
import styles from "./DataGridView.css";
import { OperationMode } from "./OperationMode";
import { isNumber } from "lodash";

type RowItemProps = {
  rowData: DataItem;
  column: Column;
  index: number;
};

export const RowItem: React.FC<RowItemProps> = ({
  rowData,
  column,
  index,
}: RowItemProps) => {
  return (
    <div
      key={column.field}
      className={index ? styles.valueItem : null}
      style={column.customStyleFn ? column.customStyleFn(rowData) : {}}
    >
      {column.field === "operationMode" ? (
        <OperationMode profile={rowData[column.field] as string} />
      ) : (
        <>
          {getValue(rowData, column)}
          {!!column.unit && <span>{column.unit}</span>}
        </>
      )}
    </div>
  );
};

const getValue = (data: DataItem, column: Column): number | string =>
  column.toFixed && data[column.field] && isNumber(data[column.field])
    ? (data[column.field] as number).toFixed(column.toFixed)
    : data[column.field];
