import {
  sortBy,
  uniqBy,
  groupBy,
  flow,
  property,
  filter,
  map,
  flatMap,
} from "lodash/fp";
import { DONUT } from "../api";

const withSortedData = (donut) => ({
  ...donut,
  data: sortBy(
    ["operationalProfile.category", "operationalProfile.legend"],
    donut.data
  ),
});

const valuesByLegend = (data = []) =>
  data.reduce((result, { value, operationalProfile = {} }) => {
    result[operationalProfile.legend] = value || 0;
    return result;
  }, {});

const withValuesByLegend = (donut) => ({
  ...donut,
  valuesByLegend: valuesByLegend(donut.data),
});

export const prepareDonutData = flow(withSortedData, withValuesByLegend);

const extractProfiles = flow(
  property("data.data"),
  map(property("operationalProfile")),
  filter((p) => p && p.profile && p.legend)
);

export const getProfilesGroups = flow(
  flatMap(extractProfiles),
  sortBy("sortKey"),
  uniqBy(property("profile")),
  sortBy("category"),
  groupBy(property("category"))
);

export const getProfiles = flow(sortBy("sortKey"));

export const createDonutUrl = (itemTypeMetricId, context) => {
  if (!context.dateRange) {
    throw new Error("context.dateRange is required");
  }
  if (context.groupId) {
    return DONUT.getGroup(
      itemTypeMetricId,
      context.groupId,
      context.dateRange,
      context.timeOffset
    );
  } else if (context.vesselId) {
    return DONUT.getVessel(
      itemTypeMetricId,
      context.vesselId,
      context.dateRange,
      context.timeOffset,
      context.states
    );
  }
  throw new Error("One of context.groupId and context.vesselId is required");
};
