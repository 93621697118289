import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchBestPerformance } from "../actions/action.fetchBestPerformance";
import Loader from "../../common/components/Loader/Loader";
import TopList from "../components/TopList/TopList";

class BestPerformanceContainer extends Component {
  componentDidMount() {
    this.props.fetchBestPerformance();
  }

  componentDidUpdate(prevProps) {
    if (this.props.config !== prevProps.config) {
      this.props.fetchBestPerformance();
    }
  }

  render() {
    const { period, data, error } = this.props.bestPerformance;
    if (!data) {
      return (
        <Loader text="Loading top list data" error={error} expand={true} />
      );
    }
    return <TopList period={period} {...data} />;
  }
}

export default connect(
  (state) => ({ bestPerformance: state.fetchBestPerformance }),
  { fetchBestPerformance }
)(BestPerformanceContainer);
