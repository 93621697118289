import { baseApi } from "../../store/baseApi";

const mockUserData = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMockUserData: build.query<UserDataMockResponse, undefined>({
      query: () => ({
        url: `/api/me`,
      }),
    }),
  }),
});

export const { useGetMockUserDataQuery } = mockUserData;

export interface UserDataMockResponse {
  groups: {
    id: string;
    name: string;
    companyId: string;
    companyName: string;
  }[];
  vessels: any;
  vesselGroupVessels: any;

  // Add proper types to the below if we need more user data in mock
  pageConfigs: Record<string, never>;
  userDetails: Record<string, never>;
  menu: Record<string, never>;
  performanceIndicators: Record<string, never>[];
  vesselPerformanceIndicators: Record<string, never>[];
  units: Record<string, never>[];
  vesselItems: Record<string, never>[];
  vesselItemMetrics: Record<string, never>[];
  itemTypes: Record<string, never>[];
  metrics: Record<string, never>[];
  itemTypeMetrics: Record<string, never>[];
  evenTypes: Record<string, never>[];
  operations: Record<string, never>[];
  itemTypeCategories: Record<string, never>[];
}
