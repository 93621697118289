import React from "react";
import {
  getLegendColor,
  getLegendName,
  getLegendTextColor,
} from "../OperationProfileHelper";
import styles from "./DataGridView.css";

type OperationModeProps = {
  profile: string;
};

type Style = {
  backgroundColor?: string;
  color?: string;
};

export const OperationMode: React.FC<OperationModeProps> = ({
  profile,
}: OperationModeProps) => {
  const style: Style = {};
  style.backgroundColor = getLegendColor(profile);
  const textColor = getLegendTextColor(profile);
  if (textColor) {
    style.color = textColor;
  }

  return (
    <div className={styles.operationMode}>
      <div style={style} className={`${styles.legendButton} ${styles.legend}`}>
        <span>{profile}</span>
      </div>

      {getLegendName(profile)}
    </div>
  );
};
