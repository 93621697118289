import React, { useEffect, useState } from "react";
import { useDragLayer } from "react-dnd";
import Xarrow from "react-xarrows";

const dragLayerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

const NodeItemDragLayer = () => {
  const [transform, setTransform] = useState();
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  useEffect(() => {
    if (currentOffset) {
      setTransform(
        `translate3d(${currentOffset.x}px, ${currentOffset.y}px, 0)`
      );
    } else {
      setTransform(null);
    }
  }, [currentOffset]);
  return (
    <div style={dragLayerStyles}>
      <div
        id={"nodeRelationDraggingEnd"}
        style={{
          position: "absolute",
          transform,
          WebkitTransform: transform,
          opacity: 0,
          width: "10px",
          height: "10px",
        }}
      />

      {isDragging ? (
        <Xarrow
          start={`nodeContainer${item.parentId}`}
          end={"nodeRelationDraggingEnd"}
          zIndex={1000}
          headSize={5}
          strokeWidth={3}
          headShape={"circle"}
        />
      ) : null}
    </div>
  );
};

export default NodeItemDragLayer;
