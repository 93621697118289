import React from "react";
import PropTypes from "prop-types";
import Sparkline from "../../../common/components/Sparkline/Sparkline";
import SparklineXAxis from "../../../common/components/Sparkline/SparklineXAxis";
import SparklineYAxis from "../../../common/components/Sparkline/SparklineYAxis";
import SparklineGrid from "../../../common/components/Sparkline/SparklineGrid";
import SparklineBars from "../../../common/components/Sparkline/SparklineBars";
import SparklineHover from "../../../common/components/Sparkline/SparklineHover";
import SparklineTooltip from "../../../common/components/Sparkline/SparklineTooltip";
import SparklineLegends from "../../../common/components/Sparkline/SparklineLegends";
import styles from "./VesselFuelConsumption.css";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";

const margin = {
  top: 10,
  right: 20,
  bottom: 35,
  left: 20,
};
const getMax = (data) => Math.max(...data.data.map((d) => d.y));
const withHeadroom = (val) => Math.ceil(val * 1.1);

export default class VesselFuelConsumption extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return this.renderGraphs();
  }

  renderGraphs() {
    const { graphData, endDate } = this.props;

    return graphData.map((x) => this.renderGraphData(x, endDate));
  }

  renderGraphData = (graphData, endDate) => {
    return (
      <div className={styles.graph} key={graphData.text}>
        <AutoSizer>
          {({ width, height }) => (
            <Sparkline
              width={width}
              height={height}
              data={graphData.data}
              xScaleType="band"
              xScalePadding={0.5}
              yDomain={[0, withHeadroom(getMax(graphData))]}
              margin={margin}
              dontSortXAxis={true}
            >
              <SparklineGrid showX={false} removeOuter={true} yTicks={4} />
              <SparklineXAxis
                showTickValues={true}
                aggregation={graphData.aggregation}
                endDate={endDate}
              />
              <SparklineYAxis
                ticks={4}
                label={graphData.unit}
                showTickValues={true}
              />
              <SparklineHover>
                <SparklineBars colors={[graphData.color]} barStyle="box" />
                <SparklineTooltip
                  tooltips={[
                    {
                      color: graphData.color,
                      format: tooltipFormatter(graphData),
                    },
                  ]}
                />
              </SparklineHover>

              <SparklineLegends
                horizontalPosition={"left"}
                legends={[graphData]}
              />
            </Sparkline>
          )}
        </AutoSizer>
      </div>
    );
  };
}

export const tooltipFormatter = (graphData) => (data) => {
  return [`${data.y} ${graphData.unit || ""}`];
};

VesselFuelConsumption.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      fuelConsumptionType: PropTypes.string.isRequired,
      aggregation: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      unit: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any.isRequired,
          y: PropTypes.number.isRequired,
        })
      ),
    })
  ).isRequired,
};
