import React from "react";
import EhmStatusPropertyValue from "./EhmStatusPropertyValue";
import DefaultPropertyValue from "./DefaultPropertyValue";
import HmStatusPropertyValue from "./HmStatusPropertyValue";
import HmAlertTimestampPropertyValue from "./HmAlertTimestampPropertyValue";
import HmResolutionPropertyValue from "./HmResolutionPropertyValue";
import HmTooltip from "./HmTooltip";

export const createPropertyValueComponent = (property) => {
  if (!property) return;

  switch (property.propertyType) {
    case "Ehm.Status":
      return <EhmStatusPropertyValue {...property} />;
    case "Hm.AlertStatus":
      return <HmStatusPropertyValue {...property} />;
    case "Hm.AlertTimestamp":
      return <HmAlertTimestampPropertyValue {...property} />;
    case "Ehm.Resolution":
      return <HmResolutionPropertyValue {...property} />;
    case "Ehm.Tooltip":
      return <HmTooltip {...property} />;
    default:
      return <DefaultPropertyValue {...property} />;
  }
};
