import React from "react";
import styles from "./Consumption.css";
import { Drop } from "./drop";
import { resolveLabel } from "../../../../../../common/unitLabels";
import { Vessel } from "../../../types";

interface Props {
  consumption: Vessel["consumption"];
}

export const Consumption = ({ consumption }: Props) => {
  const totalConsumption = consumption
    .map((c) => c.consumption)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  const numberOfFuelTypes = consumption.length;
  const consumptionOrdered = [...consumption].sort(
    (a, b) => a.fuelType - b.fuelType
  );
  const fuelTypesString =
    consumptionOrdered
      .slice(0, numberOfFuelTypesToShow)
      .map((c) => resolveLabel.fuelType(c.fuelType))
      .join(", ") +
    (numberOfFuelTypes > numberOfFuelTypesToShow
      ? ` + ${numberOfFuelTypes - numberOfFuelTypesToShow}`
      : "");

  const hoverText = consumption
    .map(
      (c) => `${resolveLabel.fuelType(c.fuelType)}: ${c.consumption.toFixed(2)}`
    )
    .join("\n");

  return (
    <div className={styles.gridContainer}>
      <div className={styles.consumption}>
        <div className={styles.consumptionValue}>
          {totalConsumption.toFixed(2)}
        </div>
        <div className={styles.consumptionUnit}>t</div>
      </div>
      <div className={styles.fuels} title={hoverText}>
        <Drop />
        <div className={styles.fuelsNumber}>({numberOfFuelTypes})</div>
        <div className={styles.fuelsTypes}>{fuelTypesString}</div>
      </div>
    </div>
  );
};

const numberOfFuelTypesToShow = 2;
