import React, { Component } from "react";
import { connect } from "react-redux";
import { Portal } from "react-portal";
import {
  showVesselImagePreview,
  hideVesselImagePreview,
} from "../../../../actions/admin/action.vesselconfig.onboardFrontendConfig";
import styles from "./VesselImagePreviewPopup.css";
import centeredModal from "../../../../../style/css/CenteredModal.css";
import Button from "../../../../../common/components/Button";
import classNames from "../../../../../common/classNames";
import LabelValue from "../../../../../onboard/components/LabelValue/LabelValue";
import { VesselPreview } from "../../../../../common/components/VesselPreview/VesselPreview";

const labelValueStyle = ({ rowIndex, colIndex }) => ({
  minWidth: "160px",
  msGridRow: rowIndex + 1,
  msGridColumn: colIndex + 1,
});

class VesselImagePreviewPopup extends Component {
  constructor(props) {
    super(props);
  }
  renderVesselImagePreview = (vesselImage) => {
    return (
      <div className={styles.form}>
        <div className={styles.vesselOverviewContainer}>
          <div className={styles.topContainer}>
            <div className={styles.pictureContainer}>
              <div className={styles.sternBowContainer}>
                <div className={styles.sternLabel}>Draft Stern</div>
                <div className={styles.sternValue}>5.40 m</div>
                <div className={styles.bowLabel}>Draft Bow</div>
                <div className={styles.bowValue}>5.00 m</div>
              </div>
              <VesselPreview vesselImage={vesselImage} />
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.valueContainer}>{this.renderValues()}</div>
          </div>
        </div>
        <div className={styles.note}>*The used values are not real</div>
      </div>
    );
  };

  renderValues() {
    const values = [
      {
        position: "Port",
        values: [
          { label: "RPM", value: "83.5", unit: "rpm" },
          { label: "Torque", value: "27.6", unit: "kNm" },
          {},
        ],
      },
      {
        position: "Center",
        values: [
          { label: "RPM", value: "79.8", unit: "rpm" },
          { label: "Torque", value: "34.2", unit: "kNm" },
          {},
        ],
      },
      {
        position: "Starboard",
        values: [
          { label: "RPM", value: "67.1", unit: "rpm" },
          { label: "Torque", value: "33.0", unit: "kNm" },
          {},
        ],
      },
    ];

    if (!values || !values.length) {
      return;
    }
    return (
      <div className={styles.propContainer}>
        {values.map((prop, i) => {
          return [
            <LabelValue
              key="position"
              label={prop.position}
              style={labelValueStyle({ rowIndex: i, colIndex: 0 })}
            />,

            prop.values.map((v, j) => (
              <LabelValue
                key={v.label || j}
                value={v.value}
                unit={v.unit}
                label={v.label}
                style={labelValueStyle({ rowIndex: i, colIndex: j + 1 })}
              />
            )),
          ];
        })}
      </div>
    );
  }

  render() {
    const {
      showVesselImagePreview: onShow,
      hideVesselImagePreview: onHide,
      showPopup,
      canPreview,
      vesselImage,
    } = this.props;

    return (
      <div>
        <Button value="Preview" clickAction={onShow} disabled={!canPreview} />
        {showPopup && (
          <Portal node={document && document.getElementById("popupContainer")}>
            <div className={centeredModal.container}>
              <div className={centeredModal.overlay} onClick={onHide} />

              <div
                className={classNames(
                  centeredModal.boxContainer,
                  styles.boxContainer
                )}
              >
                <div className={centeredModal.content}>
                  <div className={centeredModal.header}>
                    <div>Vessel image preview</div>
                    <button className={centeredModal.resetBtn} onClick={onHide}>
                      ×
                    </button>
                  </div>
                  <div className={centeredModal.body}>
                    {this.renderVesselImagePreview(vesselImage)}
                  </div>
                </div>
              </div>
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showPopup: state.admin.onboardFrontendConfig.showPopup,
    vesselImage: state.admin.onboardFrontendConfig.vesselImage,
  };
};

const mapDispatchToProps = {
  hideVesselImagePreview,
  showVesselImagePreview,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselImagePreviewPopup);
