import { post } from ".//action.fetch";
import { HEALTH_EVENTS_RESOLUTION } from "../api";
import {
  ACKNOWLEDGE_EVENT,
  PENDING_ACTION_EVENT,
  RESOLVE_EVENT,
} from "./action.types";

export const acknowledgeEvent = (eventId, vesselItemId) =>
  post({
    url: HEALTH_EVENTS_RESOLUTION.acknowledgeEvent(eventId),
    actionType: ACKNOWLEDGE_EVENT,
    actionData: { vesselItemId, eventId },
  });

export const resolveEvent = (eventId, vesselItemId) =>
  post({
    url: HEALTH_EVENTS_RESOLUTION.resolveEvent(eventId),
    actionType: RESOLVE_EVENT,
    actionData: { vesselItemId, eventId },
  });

export const revertEvent = (eventId, vesselItemId) =>
  post({
    url: HEALTH_EVENTS_RESOLUTION.revertEvent(eventId),
    actionType: PENDING_ACTION_EVENT,
    actionData: { vesselItemId, eventId },
  });
