import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../../components/Header";
import { GetCargoName, GetCargoUnit } from "../../../config";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import adminStyles from "../Admin.css";
import styles from "./CargoConfig.css";
import SelectBox from "../../../components/SelectBox";
import { formatDateTime } from "../../../common/dates";
import { FormTextBox } from "../../../components/Form";
import DatePickerContainer from "../../../components/DatePicker/DatePickerContainer";
import { getLimits } from "../../../common/vessels";
import {
  init,
  reset,
  fetchVessels,
  fetchCargo,
  updateCargo,
  saveCargo,
} from "../../../actions/admin/action.cargoconfig";
import { isNil } from "lodash";
import { createMonthFilter } from "../../../common/dateFilter";

class CargoConfig extends Component {
  constructor(props) {
    super(props);

    const monthFilter = createMonthFilter(new Date());

    this.state = {
      data: {},
      selectedDate: {
        type: monthFilter.type,
        value: monthFilter.value,
        range: [monthFilter.range.from, monthFilter.range.to],
        timeOffset: monthFilter.timeOffset,
      },
    };
  }

  componentDidMount() {
    const { init, fetchVessels } = this.props;
    init();
    fetchVessels();
  }

  componentDidUpdate(_, prevState) {
    const { data } = this.props;
    if (!this.state.cargos && data?.cargos?.length) {
      this.setState((state) => ({
        ...state,
        cargos: JSON.stringify(data.cargos),
      }));
    }
    this.loadData(prevState);
  }

  onChange(obj) {
    if (!obj.isValid) return;
    const { data, updateCargo } = this.props;
    const cargos = [...data.cargos];
    const rowIndex = cargos.findIndex((x) => x.legId === obj.id);
    const row = { ...cargos[rowIndex] };

    row.cargo = obj.value;
    row.isChanged = true;
    cargos[rowIndex] = row;

    updateCargo(cargos);
  }

  renderCargo({ legId, cargo, isChanged }) {
    if (!this.props.canEdit) {
      return null;
    }

    return (
      <FormTextBox
        name={`cargo_${legId}`}
        type="number"
        value={cargo}
        isChanged={isChanged}
        onChange={(_, updatedValue, isValid) =>
          this.onChange({
            id: legId,
            value: updatedValue,
            isValid,
          })
        }
        inline
        width="80%"
        textAlign="right"
      />
    );
  }

  renderDataGrid() {
    const { data } = this.props;
    return (
      <DataGrid
        data={data?.cargos}
        {...this.gridConfig(
          data?.fuelConsumptionColumns || [],
          data?.cargoType
        )}
        noWrapHeader
      />
    );
  }

  gridConfig(fuelColumns, cargoType) {
    const cargoHeader = GetCargoName(cargoType);
    const cargoUnit = GetCargoUnit(cargoType);
    return {
      columns: [
        {
          header: "Leg name",
          field: "name",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Start Time",
          field: "from",
          align: "left",
          flexGrow: 10,
          format: (v) => formatDateTime(new Date(v)),
        },
        {
          header: "Stop Time",
          field: "to",
          align: "left",
          flexGrow: 10,
          format: (v) => formatDateTime(new Date(v)),
        },
        {
          header: "Distance Sailed",
          headerSecondary: "nm",
          field: "distanceSailed",
          align: "right",
          flexGrow: 10,
          format: (v) => (isNil(v) ? "" : v.toFixed(2)),
        },
        {
          header: "Avg Speed",
          headerSecondary: "kn",
          field: "speed",
          align: "right",
          flexGrow: 10,
          format: (v) => (isNil(v) ? "" : v.toFixed(2)),
        },
        ...fuelColumns.map((x) => ({
          header: x.name,
          headerSecondary: x.unit,
          field: x.fuelTypeId,
          align: "right",
          flexGrow: 10,
          format: (v) => (isNil(v) ? "" : v.toFixed(2)),
        })),
        {
          header: "Cargo",
          headerSecondary: cargoHeader,
          field: "cargo",
          align: "right",
          template: this.renderCargo.bind(this),
          flexGrow: 20,
          format: (v) => (isNil(v) ? "" : v.toFixed(2)),
        },
        {
          header: "EEOI",
          headerSecondary: cargoUnit,
          field: "eeoi",
          align: "right",
          flexGrow: 1,
          format: (v) => (isNil(v) ? "" : v.toFixed(2)),
        },
      ],
    };
  }

  onSelectedVesselChange(vessel) {
    this.setState((state) => ({
      ...state,
      selectedVessel: vessel,
    }));
  }

  onDateChange = (date) => {
    this.setState((state) => ({
      ...state,
      selectedDate: date,
    }));
  };

  loadData(prevState) {
    const {
      selectedVessel: selectedVesselPrev,
      selectedDate: selectedDatePrev,
    } = prevState;
    const { selectedVessel, selectedDate } = this.state;
    if (
      selectedVessel &&
      selectedDate &&
      (selectedVesselPrev !== selectedVessel ||
        selectedDatePrev !== selectedDate)
    ) {
      this.setState((state) => ({
        ...state,
        cargos: null,
      }));
      this.props.fetchCargo({
        vesselId: selectedVessel.id,
        dateRange: selectedDate.range,
      });
    }
  }

  render() {
    const {
      vesselOptions,
      isLoading,
      hasError,
      error,
      data,
      reset,
      saveCargo,
      getVesselLimits,
    } = this.props;

    const hasChange = (data?.cargos || []).some((x) => x.isChanged);
    const limits = getVesselLimits(this.state.selectedVessel?.id);

    return (
      <div>
        <Header
          title={"Admin - Cargo configuration"}
          contentDistribution={"space-between"}
        >
          <div className={adminStyles.headerContainer}>
            <SelectBox
              className={adminStyles.headerContainerItem}
              placeholder={
                vesselOptions.isLoading ? "Loading..." : "Select vessel"
              }
              options={vesselOptions}
              optionValKey="id"
              optionLabelKey="name"
              selected={this.state.selectedVessel}
              onSelect={this.onSelectedVesselChange.bind(this)}
            />
          </div>
          <div className={styles.rightHeaderContainer}>
            {this.state.selectedVessel && (
              <DatePickerContainer
                limits={limits}
                current={this.state.selectedDate}
                vesselId={this.state.selectedVessel.id}
                onChange={this.onDateChange.bind(this)}
                supportedTypes={["year", "quarter", "month", "voyage", "leg"]}
              />
            )}
          </div>
        </Header>
        {isLoading || hasError ? (
          <Loader error={error} />
        ) : (
          <>
            <div className={styles.dataGridContainer}>
              {this.renderDataGrid()}
            </div>
            <div className={styles.gridFooter}>
              <span>Average EEOI:</span>
              {data.eeoi && (
                <>
                  <span>{data.eeoi}</span>
                  <span className={styles.unit}>
                    {GetCargoUnit(data?.cargoType)}
                  </span>
                </>
              )}
            </div>
            <div className={styles.actions}>
              <Button
                value="Reset"
                disabled={!hasChange}
                clickAction={() =>
                  this.state.cargos && reset(JSON.parse(this.state.cargos))
                }
              />
              <Button
                value="Save"
                clickAction={saveCargo}
                disabled={!hasChange}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedVesselId } = state.pageContext;
  const { vesselOptions, data, error, isLoading, hasError } =
    state.admin.cargoConfig || {};
  const getVesselLimits = (id) =>
    id ? getLimits(state.pageContext.vessels[id]) : {};

  return {
    getVesselLimits,
    vesselOptions,
    isLoading,
    hasError,
    data,
    error,
    selectedVessel:
      selectedVesselId && vesselOptions
        ? vesselOptions.find((x) => x.id === selectedVesselId)
        : null,
    canEdit: isSystemAdmin(state.user),
  };
};

const mapActionsToProps = {
  init,
  reset,
  fetchVessels,
  fetchCargo,
  updateCargo,
  saveCargo,
};

export default connect(mapStateToProps, mapActionsToProps)(CargoConfig);
