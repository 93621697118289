import { removeCurrentSelectedValues } from ".";
import { atEndOfYear, createDate, getYear, now } from "../../common/dates";

export default {
  canSelectNext: (value, { limits }) =>
    !limits || getYear(limits.to) > getYear(value),
  canSelectPrev: (value, { limits }) => !limits || limits.from < value,
  next: (value) => createDate(getYear(value) + 1),
  prev: (value) => createDate(getYear(value) - 1),
  defaultValue: ({ current, limits }, defaultDate) => {
    const currentValue =
      current && (current.currentSelectedValue || current.value);
    if (currentValue) {
      return createDate(getYear(currentValue.to || currentValue));
    }
    const value = defaultDate && (defaultDate.value.to || defaultDate.value);
    if (value && (!limits || value <= limits.to)) {
      return createDate(getYear(value));
    }
    return createDate(getYear((limits && limits.to) || now()));
  },
  clamp: (value, { limits, current }) => {
    removeCurrentSelectedValues(current);
    if (value < limits.from) {
      return createDate(getYear(limits.from));
    } else if (value > limits.to) {
      return createDate(getYear(limits.to));
    }
    return value;
  },
  format: (value, timeOffsetFormat) =>
    `${getYear(value)} ` + `${timeOffsetFormat ? timeOffsetFormat : ""}`,
  toJSON: (value) => ({
    from: value.toISOString(),
    to: atEndOfYear(value).toISOString(),
  }),
  options: (selectedValue, { limits }) => {
    const selectedYear = getYear(selectedValue);
    const fromYear = ((getYear(now()) - selectedYear) % 10) + selectedYear - 9;
    return createOptions(fromYear, selectedValue, limits);
  },
  selectOption: (option, options, { limits, current }, type, defaultDate) => {
    if (current) {
      current.currentSelectedValue = option.value;
      current.currentSelectedValueType = type;
    } else if (defaultDate) {
      defaultDate.value = option.value;
      defaultDate.type = type;
    }
    return createOptions(getYear(options.from), option.value, limits);
  },
  nextOptions: (currentOptions, { limits }) =>
    createOptions(
      getYear(currentOptions.to) + 1,
      currentOptions.selectedValue,
      limits
    ),
  prevOptions: (currentOptions, { limits }) =>
    createOptions(
      getYear(currentOptions.from) - 10,
      currentOptions.selectedValue,
      limits
    ),
};

function createOptions(fromYear, selectedValue, limits) {
  const selectedYear = getYear(selectedValue);
  const toYear = fromYear + 9;
  const fromLimit = getYear((limits && limits.from) || now());
  const toLimit = getYear((limits && limits.to) || now());
  const items = [];
  for (let i = fromYear; i <= toYear; i++) {
    items.push({
      value: createDate(i),
      title: i,
      isEnabled: i >= fromLimit && i <= toLimit,
      isSelected: i === selectedYear,
    });
  }

  const from = items[0];
  const to = items[items.length - 1];

  return {
    selectedValue,
    from: from.value,
    to: to.value,
    items,
    title: `${from.title} - ${to.title}`,
    hasNext: toLimit > toYear,
    hasPrev: fromLimit < fromYear,
  };
}
