import React, { Component } from "react";
import { get } from "lodash";
import { FormSelectBox } from "../../../../../components/Form";
import {
  DAILY_UNITS,
  EQUINOR_UNITS,
} from "../../PageConfigurationGrid/pageConfigurationGridData";

class EquinorReportEditForm extends Component {
  render() {
    const { params, onChange } = this.props;
    const equinorUnit = get(params, "equinorUnit", "").toLowerCase();
    const dailyUnit = get(params, "dailyUnit", "").toLowerCase();
    const selectedEquinorUnit = EQUINOR_UNITS.find(
      (eu) => eu.id === equinorUnit
    );
    const selectedDailyUnit = DAILY_UNITS.find((du) => du.id === dailyUnit);
    return (
      <div>
        <FormSelectBox
          name="equinorUnit"
          label="Equinor Unit"
          selected={selectedEquinorUnit}
          options={EQUINOR_UNITS}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onChange}
        />
        <FormSelectBox
          name="dailyUnit"
          label="Daily Unit"
          selected={selectedDailyUnit}
          options={DAILY_UNITS}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onChange}
        />
      </div>
    );
  }
}

export default EquinorReportEditForm;
