import React from "react";
import PropTypes from "prop-types";
import styles from "./HullPerformance.css";
import { PlotChart } from "../../components/PlotChart/PlotChart";
import AutoSizer from "../../../common/components/AutoSizer/AutoSizer";
import { formatDate } from "../../common/dates";
import { ansiColors, color } from "../../../common/colors";
import {
  hexStringToInt,
  createRegressionLineFormula,
  PLOT_COLOR_1,
  PLOT_COLOR_2,
  PLOT_COLOR_3,
} from "../PlotChart/PlotChart";
import * as PIXI from "pixi.js";
import { min, max, last } from "lodash";
import { COSMETIC_CONSTS } from "../../config";
import * as d3 from "d3";
import { HullPerformanceIndicatorPanel } from "./HullPerformanceIndicatorPanel";

const dockEventStyle = {
  fillOpacity: 0.3,
  borderWidth: 4,
  borderOpacity: 0.8,
  labelMargin: 5,
  color: ansiColors.yellow.color,
};

const dockEventLabelStyle = {
  fontSize: "14px",
  fontFamily: COSMETIC_CONSTS.fontFamily,
  fill: ansiColors.white.color,
  fontWeight: "400",
  textAlign: "left",
};

const plotStyle = {
  circleRadius: 3,
  alpha: 0.2,
};

const regressionLineStyle = {
  lineWidth: 4,
  color: ansiColors.cyan.color,
};

const isDomainValid = (xDomain, yDomain) => {
  return xDomain && yDomain && xDomain.length === 2 && yDomain.length === 2;
};

export const HullPerformance = ({
  performanceValues,
  performanceIndicators,
  dryDockEvents,
  xDomain,
  yDomain,
}) => {
  if (!isDomainValid(xDomain, yDomain)) {
    return <div />;
  }
  return (
    <div className={styles.container}>
      {
        <HullPerformanceIndicatorPanel
          performanceIndicators={performanceIndicators}
        />
      }
      <div className={styles.chartContainer}>
        <AutoSizer>
          {({ height, width }) => {
            return renderPlotChart(
              width,
              height,
              dryDockEvents,
              performanceValues,
              performanceIndicators.filter((pi) => pi.status === "ok"),
              xDomain,
              yDomain
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

const renderPlotChart = (
  width,
  height,
  dryDockEvents,
  performanceValues,
  _performanceIndicators,
  xDomain,
  yDomain
) => {
  return (
    <PlotChart
      data={performanceValues}
      isLoading={false}
      width={width}
      height={height}
      xLabel={""}
      xUnit={"time"}
      yLabel={"Speed Performance"}
      yUnit={"%"}
      customYAxisLegendRenderer={renderYAxisLegend}
      formatTickLabel={formatDate}
      yDomain={yDomain}
      xDomain={xDomain}
      showRegressionLine={false}
      plotStyle={plotStyle}
      margins={{ top: 60, left: 40, right: 40, bottom: 50 }}
      plotColors={[
        d3.rgb(PLOT_COLOR_3),
        d3.rgb(PLOT_COLOR_2),
        d3.rgb(PLOT_COLOR_1),
      ]}
      customElements={renderHullPerformance(dryDockEvents)}
    />
  );
};

// Custom renderer for y axis legend
const renderYAxisLegend = (context) => {
  const { yLabel, yUnit, margins, axisLabelTextStyle, performRenderStage } =
    context;

  const yLabelText = yLabel ? yLabel : "";
  const yUnitText = yUnit ? ` (${yUnit})` : "";
  const gfx = new PIXI.Graphics();
  const yAxisLabel = new PIXI.Text(
    `${yLabelText}${yUnitText}`,
    axisLabelTextStyle
  );

  yAxisLabel.x = margins.left - 40;
  yAxisLabel.y = margins.top - 40;
  gfx.addChild(yAxisLabel);

  const xPosition = yAxisLabel.x + yAxisLabel.width + 30;
  const yPosition = yAxisLabel.y + 10;

  gfx.beginFill(hexStringToInt(color("--light-grey")));
  gfx.drawCircle(xPosition, yPosition, 12);
  gfx.endFill();
  gfx.beginFill(hexStringToInt(color("--content-card-bg")));
  gfx.drawCircle(xPosition, yPosition, 10);
  gfx.endFill();

  const helpText = new PIXI.Text("i", {
    ...axisLabelTextStyle,
    fontWeight: 600,
  });
  helpText.x = xPosition - 2;
  helpText.y = yPosition - 8;
  gfx.addChild(helpText);

  const axisHoverContainer = new PIXI.Container();

  gfx.interactive = true;
  gfx.hitArea = new PIXI.Circle(xPosition, yPosition, 12);

  const hoverGfx = new PIXI.Graphics();
  hoverGfx.alpha = 0;
  gfx.addChild(hoverGfx);

  const popupPosition = { x: xPosition + 20, y: yPosition - 10 };

  hoverGfx.beginFill(hexStringToInt(color("--light-grey")));
  hoverGfx.drawRoundedRect(popupPosition.x, popupPosition.y, 300, 150, 10);
  hoverGfx.beginFill(hexStringToInt(color("--dark-mid-grey")));
  hoverGfx.drawRoundedRect(
    popupPosition.x + 2,
    popupPosition.y + 2,
    296,
    146,
    10
  );
  hoverGfx.endFill();

  const infoText = new PIXI.Text(
    `Speed performance shows relative difference between actual and expected speed for current propulsion power and wind conditions. Reduction in speed performance may indicate marine growth on hull and/or propeller.`,
    {
      ...axisLabelTextStyle,
      wordWrap: true,
      wordWrapWidth: 256,
    }
  );
  infoText.x = 40;
  infoText.y = 10;

  axisHoverContainer.addChild(infoText);
  axisHoverContainer.x = xPosition;
  axisHoverContainer.y = yPosition;

  hoverGfx.addChild(axisHoverContainer);

  gfx.mouseover = function () {
    hoverGfx.alpha = 1;
    performRenderStage();
  };

  gfx.mouseout = function () {
    hoverGfx.alpha = 0;
    performRenderStage();
  };

  return gfx;
};

const renderHullPerformance =
  (dryDockEvents) => (data, domain, customContainer, margins) => {
    renderDryDockEvents(data, dryDockEvents, domain, customContainer, margins);
  };

const renderDryDockEvents = (
  data,
  dryDockEvents,
  domain,
  customContainer,
  margins
) => {
  const regressionGfx = new PIXI.Graphics();
  regressionGfx.lineStyle(
    regressionLineStyle.lineWidth,
    hexStringToInt(regressionLineStyle.color),
    1
  );
  customContainer.addChildAt(regressionGfx, 0);
  const dockEventGfx = new PIXI.Graphics();
  customContainer.addChildAt(dockEventGfx, 1);

  dryDockEvents.map((dockEvent, index) => {
    const { scaleX, scaleY, maxX, yDomain } = domain;

    // Dock event overlay
    const event = {
      ...dockEvent,
      x: scaleX(dockEvent.startTime),
      width: max([
        dockEvent.endTime &&
          scaleX(dockEvent.endTime) - scaleX(dockEvent.startTime),
        dockEventStyle.borderWidth * 2,
      ]),
      height: scaleY(yDomain[0]) - margins.top,
      y: scaleY(yDomain[1]),
    };
    drawDockEvent(dockEventGfx, event);

    // Dock event label
    const label = getDryDockLabel(event, scaleX(maxX));
    label.y = scaleY(yDomain[0]) - margins.top + label.height;
    customContainer.addChildAt(label, 1);

    // Regression line between dock events
    const [x1, y1, x2, y2] = getRegressionLine(
      data,
      dryDockEvents.slice(index, index + 2),
      domain
    );
    regressionGfx.moveTo(x1, scaleY(y1));
    regressionGfx.lineTo(x2, scaleY(y2));
  });
};

const drawDockEvent = (gfx, event) => {
  gfx.beginFill(
    hexStringToInt(dockEventStyle.color),
    dockEventStyle.fillOpacity
  );
  gfx.drawRect(event.x, event.y, event.width, event.height);
  gfx.beginFill(
    hexStringToInt(dockEventStyle.color),
    dockEventStyle.borderOpacity
  );
  gfx.drawRect(event.x - 2, event.y, 2, event.height);
  gfx.drawRect(event.x + event.width, event.y, 2, event.height);
};

const getDryDockLabel = (dockEvent, maxX) => {
  // TODO: Is endTime more correct here?
  const label = new PIXI.Text(
    formatDate(dockEvent.startTime),
    dockEventLabelStyle
  );
  const labelX = dockEvent.x + dockEvent.width;
  label.x =
    labelX + label.width > maxX
      ? labelX - label.width - dockEvent.width - dockEventStyle.labelMargin
      : labelX + dockEventStyle.labelMargin;
  return label;
};

const getRegressionLine = (data, dryDockEvents, domain) => {
  const { scaleX, yDomain } = domain;
  if (dryDockEvents.length === 0 || data.length === 0) {
    return [];
  }
  const currentEvent = dryDockEvents[0].endTime;
  const nextEvent =
    dryDockEvents.length > 1 ? dryDockEvents[1].startTime : last(data).x;

  const intervalData = data.filter(
    (x) => x.x >= currentEvent && x.x < nextEvent
  );

  if (intervalData.length === 0) {
    return [];
  }

  const xData = intervalData.map((d) => scaleX(d.x));
  const yData = intervalData.map((d) => d.y);
  const regressionFx = createRegressionLineFormula(xData, yData, yDomain);

  const minX = min(xData);
  const maxX = max(xData);

  const [x1, y1] = regressionFx(minX);
  const [x2, y2] = regressionFx(maxX);
  return [x1, y1, x2, y2];
};

HullPerformance.propTypes = {
  performanceValues: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.instanceOf(Date).isRequired,
      y: PropTypes.number.isRequired,
    })
  ),
  performanceIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number,
      time: PropTypes.instanceOf(Date),
      evaluationStartTime: PropTypes.instanceOf(Date),
      evaluationEndTime: PropTypes.instanceOf(Date),
      evaluationValue: PropTypes.number,
      referenceStartTime: PropTypes.instanceOf(Date),
      referenceEndTime: PropTypes.instanceOf(Date),
      referenceValue: PropTypes.number,
    })
  ),
  dryDockEvents: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.instanceOf(Date),
      endTime: PropTypes.instanceOf(Date),
    })
  ),
};
