import { AxiosError } from "axios";
import {
  FETCH_API_ALIVE_ERROR,
  FETCH_API_ALIVE_SUCCESS,
} from "../actions/action.types";
import { clearInterval } from "timers";
import { IApiAvailabilityHandler } from "./IApiAvailabilityHandler";
import IApiClient from "../services/IApiClient";
import { Dispatch } from "redux";
import { IsAlive } from "./IsAlive";

const RETRY_INTERVAL = 20 * 1000;

export class ApiAvailabilityHandler implements IApiAvailabilityHandler {
  private intervalId: NodeJS.Timer | undefined;
  private readonly apiClient: IApiClient;
  private readonly dispatch: Dispatch;

  constructor(apiClient: IApiClient, dispatch: Dispatch) {
    this.apiClient = apiClient;
    this.dispatch = dispatch;
  }

  public checkApiAlive(): void {
    this.apiClient.checkApiAlive().then(
      (data: IsAlive) => {
        this.dispatch({ type: FETCH_API_ALIVE_SUCCESS, data });
      },
      (error: AxiosError) =>
        this.dispatch({ type: FETCH_API_ALIVE_ERROR, error })
    );
  }

  public startApiAvailabilityCheck(): void {
    this.intervalId = setInterval(() => this.checkApiAlive(), RETRY_INTERVAL);
  }

  public stopApiAvailabilityCheck(): void {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  }
}
