import React from "react";
import t from "prop-types";
import { connect } from "react-redux";
import { fetchEquinorVesselReport } from "../actions/action.equinor";
import ReportWidget from "../components/ReportWidget/ReportWidget";
import {
  calculateDailyOperations,
  calculateEquionorOperations,
} from "../reducers/reducer.equinor";
import { get } from "lodash";
import { formatNumber } from "../../common/numbers";
import styles from "./ConfiguredEquinorReportContainer.css";
import { PROFILES } from "../../common/config";

class ConfiguredEquinorReportContainer extends React.Component {
  componentDidMount() {
    this.props.fetch(this.props.context);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.context.vesselId !== prevProps.context.vesselId ||
      this.props.context.dateRange !== prevProps.context.dateRange
    ) {
      this.props.fetch(this.props.context);
    }
  }

  createReports() {
    const { equinor, config } = this.props;
    const equinorUnit = get(config, "params.equinorUnit", "l");
    const dailyUnit = get(config, "params.dailyUnit", "m3");
    const operationGroups = get(equinor, "operationGroups", []);
    const equinorOperations = calculateEquionorOperations(
      operationGroups,
      equinorUnit
    );
    const dailyOperation = calculateDailyOperations(operationGroups, dailyUnit);
    return [
      this.createReport(
        equinorOperations.operations,
        equinorOperations.average,
        `${equinorUnit}/h`,
        "Equinor report"
      ),
      this.createReport(
        dailyOperation.operations,
        dailyOperation.average,
        `${dailyUnit}/day`,
        "Daily Report"
      ),
    ];
  }

  static getLegendColor = (legend) =>
    PROFILES[legend] && PROFILES[legend].color;

  createReport(rows, average, unit, title) {
    return {
      title: title,
      columns: {
        name: {
          value: (x) => x.name,
          className: styles.name,
        },
        legends: {
          value: (x) => (
            <React.Fragment>
              {x.legends &&
                x.legends.map((l, i) => (
                  <span
                    style={{
                      backgroundColor:
                        ConfiguredEquinorReportContainer.getLegendColor(l),
                    }}
                    key={`legend_${i}`}
                  >
                    {l}
                  </span>
                ))}
            </React.Fragment>
          ),
          className: styles.legends,
        },
        avgFuelConsumption: {
          align: "right",
          value: (x) => (
            <React.Fragment>
              <span data-type={"value"}>
                {formatNumber(x.avgFuelConsumption)}
              </span>
              <span data-type={"unit"}>{unit}</span>
            </React.Fragment>
          ),
        },
      },
      rows,
      footer: () => (
        <React.Fragment>
          <div>Average</div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <span data-type="value">{formatNumber(average)}</span>
            <span data-type="unit">{unit}</span>
          </div>
        </React.Fragment>
      ),
    };
  }

  render() {
    const { equinor } = this.props;
    return (
      <ReportWidget
        reports={this.createReports()}
        isLoading={equinor.isLoading}
        error={equinor.error}
      />
    );
  }
}

ConfiguredEquinorReportContainer.propTypes = {
  config: t.shape({
    params: t.shape({
      equinorUnit: t.oneOf(["l", "kg"]),
      dailyUnit: t.oneOf(["m3", "t"]),
    }),
  }),
  context: t.object.isRequired,
};

ConfiguredEquinorReportContainer.defaultProps = {
  config: {
    params: {
      equinorUnit: "l",
      dailyUnit: "m3",
    },
  },
};

export default connect(
  (state, { context }) => ({
    context,
    equinor: state.equinor,
  }),
  (dispatch) => ({
    fetch: (context) => dispatch(fetchEquinorVesselReport(context)),
  })
)(ConfiguredEquinorReportContainer);
