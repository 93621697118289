import React from "react";
import PropTypes from "prop-types";
import styles from "./FormCheckBox.css";

const FormCheckBox = ({
  name,
  label,
  value,
  onChange,
  disabled,
  iconPosition,
}) => {
  const checkedContent = value ? "\u2714" : "";
  const changeHandle = (event) => {
    onChange(name, event.target.checked);
  };

  const fragments = {
    label: <span className={styles.label}>{label}</span>,
    icon: <span className={styles.stateGraphics}>{checkedContent}</span>,
    input: (
      <input
        type="checkbox"
        className={styles.checkBox}
        value={value}
        disabled={disabled}
        checked={value}
        name={name}
        onChange={changeHandle}
      />
    ),
  };

  let containerClasses = [styles.container];
  if (disabled) {
    containerClasses.push(styles.disabled);
  }

  if (iconPosition === "left") {
    containerClasses.push(styles.left);
  }

  return (
    <label className={containerClasses.join(" ")}>
      {iconPosition === "left" ? fragments.icon : fragments.label}
      {fragments.input}
      {iconPosition === "left" ? fragments.label : fragments.icon}
    </label>
  );
};

FormCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(["left", "right"]),
};

export default FormCheckBox;
