import { DateRange } from "../components/DatePicker/Types";
import fetch from "./action.fetch";
import { FLEET_ACTIVITY } from "../api_ts";
import { FETCH_FLEET_ACTIVITY } from "./action.types";
import { AnyAction } from "redux";

export function fetchFleetActivity(
  interval: DateRange,
  vesselGroup: string
): AnyAction {
  return fetch({
    url: FLEET_ACTIVITY.get(interval.from, interval.to, vesselGroup),
    actionType: FETCH_FLEET_ACTIVITY,
  }) as AnyAction;
}
