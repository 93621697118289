import {
  FETCH_ADMIN_VESSEL_GROUPS,
  FETCH_ADMIN_VESSEL_GROUPS_SUCCESS,
  FETCH_ADMIN_VESSEL_GROUPS_ERROR,
  FETCH_ADMIN_VESSEL_GROUP_SUCCESS,
  FETCH_ADMIN_VESSEL_GROUP_ERROR,
  CREATE_ADMIN_VESSEL_GROUP,
  CLOSE_ADMIN_VESSEL_GROUP_POPUP,
  SAVE_ADMIN_VESSEL_GROUP,
  SAVE_ADMIN_VESSEL_GROUP_SUCCESS,
  SAVE_ADMIN_VESSEL_GROUP_ERROR,
  SET_ADMIN_VESSEL_GROUP_VALUE,
  FETCH_ADMIN_VESSELS_SUCCESS,
  FETCH_ADMIN_COMPANIES_SUCCESS,
  DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN,
  DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE,
  DELETE_ADMIN_VESSEL_GROUP,
  DELETE_ADMIN_VESSEL_GROUP_SUCCESS,
  DELETE_ADMIN_VESSEL_GROUP_ERROR,
} from "../../actions/action.types";
import { get } from "lodash";
import { checkFormValidation, convertObjToFields } from "../../common/forms";
import createReducer from "../createReducer";

const initialState = {
  data: [],
  isLoading: false,
  showPopup: false,
  companies: [],
  vessels: [],
  deletePopup: {
    isVisible: false,
  },
  form: {
    fields: {},
    isSaving: false,
    isValid: false,
    hasChanges: false,
    isUpdate: false,
  },
};

export default createReducer(initialState, {
  [FETCH_ADMIN_VESSELS_SUCCESS]: (state, { data }) => {
    let vessels = [];

    if (data) {
      vessels = data.map((x) => ({ id: x.id, name: x.name }));
    }

    return {
      ...state,
      vessels,
    };
  },
  [FETCH_ADMIN_COMPANIES_SUCCESS]: (state, { data }) => {
    let companies = [];

    if (data) {
      companies = data.map((x) => ({ id: x.id, name: x.name }));
    }

    return {
      ...state,
      companies,
    };
  },
  [FETCH_ADMIN_VESSEL_GROUPS]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_ADMIN_VESSEL_GROUPS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      data,
    };
  },
  [FETCH_ADMIN_VESSEL_GROUPS_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [FETCH_ADMIN_VESSEL_GROUP_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      showPopup: true,
      form: {
        ...state.form,
        fields: convertObjToFields(data),
        isValid: true,
        hasChanges: false,
        isUpdate: true,
        isSaving: false,
      },
    };
  },
  [FETCH_ADMIN_VESSEL_GROUP_ERROR]: (state, { error }) => {
    return {
      ...state,
      hasError: true,
      form: {
        ...state.form,
        fields: {},
      },
      error,
    };
  },
  [CREATE_ADMIN_VESSEL_GROUP]: (state, { companyId }) => {
    const [defaultCompany] = state.companies;
    const defaultCompanyId = companyId
      ? companyId
      : get(defaultCompany, "id", null);
    return {
      ...state,
      showPopup: true,
      form: {
        fields: {
          name: { isValid: false },
          companyId: { isValid: !!defaultCompanyId, value: defaultCompanyId },
        },
        isSaving: false,
        isValid: false,
        hasChanges: false,
        isUpdate: false,
      },
    };
  },
  [CLOSE_ADMIN_VESSEL_GROUP_POPUP]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        fields: {},
      },
    };
  },
  [SAVE_ADMIN_VESSEL_GROUP]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        isSaving: true,
      },
    };
  },
  [SAVE_ADMIN_VESSEL_GROUP_SUCCESS]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
    };
  },
  [SAVE_ADMIN_VESSEL_GROUP_ERROR]: (state, { error }) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
      hasError: true,
      error,
    };
  },
  [SET_ADMIN_VESSEL_GROUP_VALUE]: (state, { name, value, valid, message }) => {
    const field = state.form[name];
    return {
      ...state,
      form: checkFormValidation({
        ...state.form,
        fields: {
          ...state.form.fields,
          [name]: {
            ...field,
            value: value,
            isValid: valid !== undefined ? valid : true,
            message: message || "",
            hasChanges: true,
          },
        },
      }),
    };
  },
  [DELETE_ADMIN_VESSEL_GROUP_POPUP_OPEN]: (state, vesselGroupId) => {
    return {
      ...state,
      showPopup: false,
      deletePopup: {
        isVisible: true,
        id: vesselGroupId,
      },
    };
  },
  [DELETE_ADMIN_VESSEL_GROUP_POPUP_CLOSE]: (state) => {
    return {
      ...state,
      showPopup: true,
      deletePopup: {
        isVisible: false,
      },
    };
  },
  [DELETE_ADMIN_VESSEL_GROUP]: (state) => {
    return {
      ...state,
      isLoading: true,
      deletePopup: {
        isVisible: false,
      },
      form: {
        ...state.form,
        isSaving: true,
      },
    };
  },
  [DELETE_ADMIN_VESSEL_GROUP_SUCCESS]: (state) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: true,
      },
      deletePopup: {
        isVisible: false,
      },
    };
  },
  [DELETE_ADMIN_VESSEL_GROUP_ERROR]: (state, { error }) => {
    return {
      ...state,
      showPopup: false,
      form: {
        ...state.form,
        isSaving: false,
      },
      hasError: true,
      error,
    };
  },
});

export const selectAllGroups = (state) => state.data || [];

export const selectGroupsByCompany = (companyId, state) =>
  selectAllGroups(state).filter((x) => x.companyId === companyId);

export const selectGroupById = (id, state) =>
  selectAllGroups(state).find((x) => x.id === id);

export const selectGroupsByIds = (ids, state) =>
  selectAllGroups(state).filter((x) => ids.includes(x.id));
