import { get, isEqual } from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  fetchCalendarOperationalData,
  fetchCalendarDocuments,
  fetchCalendarHealthEvents,
  fetchVessel,
  fetchVessels,
  toggleCalendarDocuments,
  toggleCalendarHealthEvents,
  toggleNav,
  togglePerformanceOperator,
} from "../../actions";
import {
  addEvent,
  closeEventDialog,
  confirmDeleteCalendarEvent,
  deleteCalendarEvent,
  editEvent,
  eventFieldValueChanged,
  fetchCalendarEvents,
  saveCalendarEvent,
  toggleCalendarEvents,
} from "../../actions/action.calendarEvents";
import {
  addManualFuelData,
  closeManualFuelDataDialog,
  fetchManualFuelData,
  fetchManualFuelDataConfig,
  toggleManualFuelData,
  manualFuelDataFieldValueChanged,
  addRowManualFuelDataDialog,
  removeRowManualFuelDataDialog,
  saveCalendarManualFuelData,
} from "../../actions/action.calendarManualFuelData";
import {
  filterValidEventTypes,
  loadingStatus,
} from "../../reducers/reducer.calendar";
import { calendarConfig } from "../../common/calendarConfig";
import { getLimits } from "../../common/vessels";
import ButtonToggle from "../../components/ButtonToggle";
import Calendar from "../../components/Calendar/Calendar";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import EventEditFormDialog from "../../components/Event/EventEditFormDialog";
import { Header } from "../../components/Header";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import { VIEWS } from "../../config";
import { getSelectedVessel } from "../../reducers/reducer.pageContext";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import {
  convertFiltersToQueryParams,
  getGroupFilter,
  getMonthFilter,
  getVesselFilter,
} from "../../selectors/filters";
import styles from "./CalendarView.css";
import Loader from "../../../common/components/Loader";
import ManualFuelDataDialog from "../../components/ManualFuelData/ManualFuelDataDialog";

const propTypes = {};

const defaultProps = {};

class CalendarView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewTitle: "Calendar",
    };

    this.dateSelected = this.dateSelected.bind(this);
    this.onToggleButton = this.onToggleButton.bind(this);
  }

  fetchCalendarData(filters, prevFilters = {}) {
    if (
      filters.isValid &&
      (!isEqual(filters.vesselFilter, prevFilters.vesselFilter) ||
        !isEqual(filters.dateFilter, prevFilters.dateFilter))
    ) {
      this.props.fetchVessel(this.props.activeVesselId);
      this.props.fetchCalendarOperationalData(
        this.props.activeVesselId,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
      this.props.fetchCalendarDocuments(
        this.props.activeVesselId,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
      this.props.fetchCalendarHealthEvents(
        this.props.activeVesselId,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
      this.props.fetchCalendarEvents(
        this.props.activeVesselId,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
      if (this.props.useManualFuelDataOnly === true) {
        this.props.fetchManualFuelData(
          this.props.activeVesselId,
          filters.dateFilter.value.range,
          parseInt(filters.dateFilter.value.timeOffset)
        );
        this.props.fetchManualFuelDataConfig(this.props.activeVesselId);
      }
    }
  }

  componentDidMount() {
    const { filters } = this.props;

    this.props.fetchVessels();

    if (filters.isValid) {
      this.fetchCalendarData(filters);
    } else {
      this.props.queryContext.navigate(
        VIEWS.calendar.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (filters.isValid) {
      this.fetchCalendarData(filters, prevProps.filters);
    } else {
      this.props.queryContext.navigate(
        VIEWS.calendar.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  }

  dateSelected(date) {
    this.props.queryContext.setDate(date);
  }

  getDayLabels(category) {
    if (!category.active) {
      return [];
    }
    return category.data
      .filter((event) => event.value !== undefined)
      .map((event) => {
        return {
          date: event.date,
          color: get(event, "color", category.color),
          header: get(event, "name", category.name),
          onClick: event.onClick,
          text: event.value,
        };
      });
  }

  getCalendarData(categories) {
    let result = [];
    categories.forEach((category) => {
      result = result.concat(this.getDayLabels(category));
    });

    return result;
  }

  onToggleButton(name) {
    switch (name) {
      case "Events":
        this.props.toggleCalendarEvents();
        break;
      case "Operational Data":
        this.props.togglePerformanceOperator();
        break;
      case "Documents":
        this.props.toggleCalendarDocuments();
        break;
      case "Health Events":
        this.props.toggleCalendarHealthEvents();
        break;
      case "Manual Fuel Data":
        this.props.toggleManualFuelData();
        break;
      default:
    }
  }

  handleEventFormSave = () => {
    const { activeVesselId, timeOffset } = this.props;
    this.props.saveCalendarEvent(activeVesselId, timeOffset);
  };
  handleManualFuelDataFormSave = () => {
    const { activeVesselId, timeOffset } = this.props;
    this.props.saveCalendarManualFuelData(activeVesselId, timeOffset);
  };
  handleConfirmDeleteCalendarEvent = (event) => {
    const { activeVesselId, timeOffset } = this.props;
    this.props.confirmDeleteCalendarEvent(activeVesselId, event, timeOffset);
  };

  renderToggleButtons(categories) {
    const buttonStyle = {
      position: "absolute !important",
      right: "0px",
      top: "0px",
      height: "46px",
      width: "46px",
      borderRadius: "50%",
      marginTop: "-47px",
      opacity: "0",
    };
    const operationInfoStyle = {
      textAlign: "center",
    };
    return (
      <div className={styles.headerMiddleContainer}>
        {categories.map((category, dayIdx) => {
          return (
            <div className={styles.buttonContainer} key={dayIdx}>
              <ButtonToggle
                active={category.active}
                clickAction={() => this.onToggleButton(category.name)}
                value={category.shortName}
                width="48px"
                lineColor={category.color}
                circle
              />
              {
                <ComponentInfo
                  width={-1}
                  modalTargetId={"popupContainer"}
                  buttonClick={(e) => {
                    e.stopPropagation();
                    this.onToggleButton(category.name);
                  }}
                  buttonStyle={buttonStyle}
                  template={<p style={operationInfoStyle}>{category.name}</p>}
                >
                  {" "}
                </ComponentInfo>
              }
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { calendar, eventTypes, activeVesselId, vesselDateLimits } =
      this.props;

    const { eventDialog } = calendar;
    const { manualFuelDataDialog } = calendar;
    const { manualFuelDataConfig } = calendar;
    let calendarData = this.getCalendarData([
      {
        ...calendar.performanceOperator,
        data: get(calendar, "performanceOperator.data", []).map((d) => ({
          date: d.date,
          ...calendarConfig.performanceOperator.value(d),
        })),
      },
      {
        ...calendar.documents,
        data: get(calendar, "documents.data", []).map((d) => ({
          date: d.date,
          ...calendarConfig.documents.value(d),
        })),
      },
      {
        ...calendar.healthEvents,
        data: get(calendar, "healthEvents.data", []).map((d) => ({
          date: d.date,
          ...calendarConfig.healthEvents.value(d),
        })),
      },
      {
        ...calendar.events,
        data: get(calendar, "events.data", []).map((d) =>
          calendarConfig.events.parseEvent(d, eventTypes, () =>
            this.props.editEvent(d.id)
          )
        ),
      },
      {
        ...calendar.manualFuelData,
        data: get(calendar, "manualFuelData.tags", []).map((d) => ({
          ...calendarConfig.manualFuelData.value(d),
          date: d.date,
          onClick: () => this.props.addManualFuelData(d.date),
        })),
      },
    ]);
    return (
      <div className={styles.calendarViewContainer}>
        <Header
          title="Calendar"
          contentDistribution="space-between"
          toggleNav={this.props.toggleNav}
        >
          <div className={styles.headerLeftContainer}>
            <VesselSelector />
          </div>
          {this.renderToggleButtons([
            calendar.events,
            calendar.performanceOperator,
            calendar.documents,
            calendar.healthEvents,
            ...(this.props.useManualFuelDataOnly
              ? [calendar.manualFuelData]
              : []),
          ])}
          <div className={styles.headerRightContainer}>
            <DatePickerContainer
              limits={vesselDateLimits}
              current={this.props.filters.dateFilter.value}
              vesselId={activeVesselId}
              onChange={this.dateSelected}
              supportedTypes={["month", "timeOffset"]}
            />
          </div>
        </Header>
        {this.props.isLoading || this.props.error ? (
          <div className={styles.loaderContainer}>
            <Loader text="Loading calendar details" error={this.props.error} />
          </div>
        ) : (
          this.props.filters.dateFilter.value && (
            <Calendar
              selectedMonth={moment(
                this.props.filters.dateFilter.value.range.from
              ).month()}
              selectedYear={moment(
                this.props.filters.dateFilter.value.range.from
              ).year()}
              tags={calendarData}
              addEvent={() => this.props.addEvent()}
              addManualFuelData={(day) => this.props.addManualFuelData(day)}
              useManualFuelDataOnly={this.props.useManualFuelDataOnly}
            />
          )
        )}

        <ManualFuelDataDialog
          visible={manualFuelDataDialog.visible}
          date={manualFuelDataDialog.date}
          data={manualFuelDataDialog.data}
          fuelTypes={manualFuelDataConfig.fuelTypes}
          isSaving={manualFuelDataDialog.isSaving}
          onFormDataChanged={this.props.manualFuelDataFieldValueChanged}
          addRow={this.props.addRowManualFuelDataDialog}
          removeRow={this.props.removeRowManualFuelDataDialog}
          onSave={this.handleManualFuelDataFormSave}
          onCancel={this.props.closeManualFuelDataDialog}
          onDelete={this.props.deleteCalendarEvent}
          onConfirmDelete={this.handleConfirmDeleteCalendarEvent}
          timeOffset={this.props.timeOffset}
        />
        <EventEditFormDialog
          visible={eventDialog.visible}
          mode={eventDialog.mode}
          event={eventDialog.event}
          eventTypes={filterValidEventTypes(eventTypes)}
          isValid={eventDialog.isValid}
          isSaving={eventDialog.isSaving}
          onFormDataChanged={this.props.eventFieldValueChanged}
          onSave={this.handleEventFormSave}
          onCancel={this.props.closeEventDialog}
          onDelete={this.props.deleteCalendarEvent}
          onConfirmDelete={this.handleConfirmDeleteCalendarEvent}
          timeOffset={this.props.timeOffset}
        />
      </div>
    );
  }
}

CalendarView.propTypes = propTypes;
CalendarView.defaultProps = defaultProps;

const getCalendarPageFilters = createSelector(
  [getVesselFilter, getGroupFilter, getMonthFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid:
        vesselFilter.isValid && groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const filters = getCalendarPageFilters(state, props);
  const timeOffset = parseInt(filters.dateFilter.value.timeOffset);
  const activeVesselId = filters.vesselFilter.value;
  const useManualFuelDataOnly = getSelectedVessel({
    selectedVesselId: activeVesselId,
    vessels: state.pageContext.vessels,
  })?.useManualFuelDataOnly;

  return {
    filters,
    timeOffset,
    activeVesselId,
    calendar: state.calendar,
    eventTypes: state.pageContext.eventTypes,
    vesselDateLimits: getLimits(
      getSelectedVessel({
        selectedVesselId: activeVesselId,
        vessels: state.pageContext.vessels,
      })
    ),
    useManualFuelDataOnly,
    ...loadingStatus(state.calendar),
  };
};

const mapDispatchToProps = {
  fetchVessel,
  fetchVessels,
  fetchCalendarOperationalData,
  fetchCalendarDocuments,
  fetchCalendarHealthEvents,
  fetchCalendarEvents,
  fetchManualFuelData,
  fetchManualFuelDataConfig,
  manualFuelDataFieldValueChanged,
  toggleManualFuelData,
  addRowManualFuelDataDialog,
  removeRowManualFuelDataDialog,
  saveCalendarManualFuelData,
  toggleNav,
  togglePerformanceOperator,
  toggleCalendarDocuments,
  toggleCalendarHealthEvents,
  toggleCalendarEvents,
  addEvent,
  addManualFuelData,
  closeManualFuelDataDialog,
  editEvent,
  saveCalendarEvent,
  deleteCalendarEvent,
  confirmDeleteCalendarEvent,
  closeEventDialog,
  eventFieldValueChanged,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
