export const MAP_VIEW = {
  defaultCenter: {
    lat: 62.472229,
    lng: 6.149482,
  },
  defaultZoom: 3,
};

export const donutRunData = (title, unit) => {
  return {
    title,
    unit,
    total: 9060084,
    type: "run-seconds",
    data: [
      {
        value: 4161415,
        operationalProfile: {
          category: "DP",
          profile: "DP",
          legend: "DP",
        },
      },
      {
        value: 1051047,
        operationalProfile: {
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
        },
      },
      {
        value: 638649,
        operationalProfile: {
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
        },
      },
      {
        value: 1013547,
        operationalProfile: {
          category: "General",
          profile: "Maneuver",
          legend: "M",
        },
      },
      {
        value: 22372,
        operationalProfile: {
          category: "General",
          profile: "Other",
          legend: "O",
        },
      },
    ],
  };
};

export const donutFuelConsumptionData = (title, unit) => {
  return {
    title,
    unit,
    total: 812052.806,
    type: "mdo-consumption-calculated",
    data: [
      {
        value: 283390.344,
        operationalProfile: {
          category: "DP",
          profile: "DP",
          legend: "DP",
        },
      },
      {
        value: 111311.027,
        operationalProfile: {
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
        },
      },
      {
        value: 152618.38,
        operationalProfile: {
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
        },
      },
      {
        value: 69539.568,
        operationalProfile: {
          category: "General",
          profile: "Maneuver",
          legend: "M",
        },
      },
      {
        value: 440.18,
        operationalProfile: {
          category: "General",
          profile: "Other",
          legend: "O",
        },
      },
    ],
  };
};

export const getEnergyBudgetOverviewPeriod = () => {
  const period = [];
  for (let i = 0; i < 11; i++) {
    period.push({ id: i, name: `${i}` });
  }
  return period;
};

export const GAUGE_DATA = {
  engine: { value: 85, performanceValue: 5 },
  performance: { value: 220.5, performanceValue: -3.5 },
  fuel: { value: 64.5, performanceValue: -16.2 },
  propulsion: { value: 45.5, performanceValue: 21 },
  trim: { value: 25, performanceValue: 10.5 },
  power: { value: 415.9, performanceValue: 10.2 },
  dashboard: { value: 41.5 },
};

export const VESSEL_COUNT = {
  title: "Vessel in fleet",
  type: "vessel-count",
  data: [
    {
      value: 15.0,
      operationalProfile: {
        category: "General",
        profile: "Offline",
        legend: "OFF",
      },
    },
    {
      value: 5.0,
      operationalProfile: {
        category: "DP",
        profile: "DP",
        legend: "DP",
      },
    },
    {
      value: 2.0,
      operationalProfile: {
        category: "General",
        profile: "Port",
        legend: "P",
      },
    },
    {
      value: 1.0,
      operationalProfile: {
        category: "Fish",
        profile: "Double Trawl",
        legend: "F2",
      },
    },
  ],
};

export const HISTORICAL_ENERGY_BUDGET_DATA = [
  {
    budgetValue: 200000,
    actualValue: 100000,
  },
  {
    budgetValue: 350000,
    actualValue: 200000,
  },
  {
    budgetValue: 150000,
    actualValue: 200000,
  },
  {
    budgetValue: 200000,
    actualValue: 100000,
  },
  {
    budgetValue: 50000,
    actualValue: 53400,
  },
  {
    budgetValue: 150000,
    actualValue: 125000,
  },
];

export const FUTURE_ENERGY_BUDGET_DATA = [
  {
    budgetValue: 250000,
    actualValue: 0,
  },
  {
    budgetValue: 300000,
    actualValue: 0,
  },
  {
    budgetValue: 150000,
    actualValue: 0,
  },
  {
    budgetValue: 200000,
    actualValue: 0,
  },
  {
    budgetValue: 50000,
    actualValue: 0,
  },
  {
    budgetValue: 150000,
    actualValue: 0,
  },
];

export const MONTHLY_FUEL_CONSUMPTION_DATA = [
  { value: 258.7884430646371 },
  { value: 832.8192030820687 },
  { value: 2852.197193572046 },
  { value: 2828.1458316216954 },
  { value: 2866.118090166687 },
  { value: 2848.651600317655 },
  { value: 2783.0761759663201 },
  { value: 2381.097355275252 },
  { value: 2562.625258512763 },
  { value: 3110.0842227458088 },
  { value: 3423.975175532636 },
  { value: 2572.8811250253211 },
];

export const equinorOperations = (equinorUnit) => {
  return {
    rows: [
      {
        avgFuelConsumption: 110.159,
        legends: ["DP2", "DP"],
        name: "DP",
      },
      {
        avgFuelConsumption: 235.419,
        legends: ["ET", "T"],
        name: "Transit",
      },
      {
        avgFuelConsumption: 118.504,
        legends: ["SC", "P"],
        name: "Port",
      },
    ],
    average: 154.694,
    title: "Equinor report",
    unit: `${equinorUnit}/h`,
  };
};

export const dailyOperations = (dailyUnit) => {
  return {
    rows: [
      {
        avgFuelConsumption: 2.6438,
        legends: ["DP2", "DP"],
        name: "DP",
      },
      {
        avgFuelConsumption: 5.65,
        legends: ["ET", "T"],
        name: "Transit",
      },
      {
        avgFuelConsumption: 2.844,
        legends: ["SC", "P"],
        name: "Port",
      },
    ],
    average: 3.712,
    title: "Daily Report",
    unit: `${dailyUnit}/day`,
  };
};

export const HEALTH_EVENTS_DATA = [
  { type: "DMEvent", healthEventCount: 6 },
  { type: "PropulsorEvent", healthEventCount: 198 },
  { type: "EngineEvent", healthEventCount: 39 },
  { type: "SteeringGearEvent", healthEventCount: 1 },
  { type: "ComponentStatusEvent", healthEventCount: 6 },
];

export const ITEM_TYPE_METRIC_MAPPINGS = {
  "9cf3ebed-3868-48af-bf91-af4214ba2215":
    "5258c5b3-d3c5-4cf9-a107-fa9a1338353d",
};

export const METRIC_MAPPINGS = {
  "23210ea9-2882-473d-b4ea-5efe1a94dbbc":
    "84215551-b98e-41ec-ad48-a80e9d9da262",
  "31482f1f-010f-4e41-bf8d-ef3f2ebbd115":
    "8a1027e7-ae4f-4307-af1d-335499275b65",
  "d9522680-7340-4150-8eef-6335d452b5f4":
    "bfa34914-0fca-49ac-ba66-797364f8fd5c",
};

export const COMPONENTS_LIST = {
  dashboard: [
    "MONTHLY_FUEL_USAGE",
    "VESSEL_COUNT",
    "MAP",
    "FUEL_EFFICIENCY_TODAY",
    "FUEL_EFFICIENCY_YESTERDAY",
    "FLEET_CONNECTION_STATUS",
    "ENERGY_BUDGET_OVERVIEW",
  ],
  fleet: [
    "MAP",
    "HEALTH_EVENT_STATUS",
    "ENERGY_BUDGET_RESULT",
    "DONUTS",
    "GAUGE",
  ],
  vessel: [
    "VESSEL_PROFILE",
    "VESSEL_MAP",
    "HEALTH_EVENT_STATUS",
    "ENERGY_BUDGET_RESULT",
    "DONUTS",
    "GAUGE",
    "EQUINOR_VESSEL_REPORT",
  ],
};

export const COMPONENT_PARAMS = {
  FUEL_EFFICIENCY_TODAY: {
    performanceIndicatorId: "11b44346-0ef5-4034-96aa-48a7e0ada5b7",
  },
  FUEL_EFFICIENCY_YESTERDAY: {
    performanceIndicatorId: "11b44346-0ef5-4034-96aa-48a7e0ada5b7",
  },
  MONTHLY_FUEL_USAGE: {
    itemTypeMetricId: "e9d9ca7e-a691-41a7-8ffe-3fc4c6192ecf",
  },
  ENERGY_BUDGET_OVERVIEW: {
    metricIds: [
      "23210ea9-2882-473d-b4ea-5efe1a94dbbc",
      "31482f1f-010f-4e41-bf8d-ef3f2ebbd115",
      "d9522680-7340-4150-8eef-6335d452b5f4",
    ],
    groupBy: "month",
    historicalCount: 5,
    futureCount: 1,
  },
  ENERGY_BUDGET_RESULT: {
    metricId: ["23210ea9-2882-473d-b4ea-5efe1a94dbbc"],
  },
  DONUTS: {
    itemTypeMetricIds: [
      "5258c5b3-d3c5-4cf9-a107-fa9a1338353d",
      "e9d9ca7e-a691-41a7-8ffe-3fc4c6192ecf",
    ],
  },
  GAUGE: {
    performanceIndicatorId: "9fed2e3f-5a08-42a2-8f46-106868727421",
  },
  EQUINOR_VESSEL_REPORT: {
    equinorUnit: "l",
    dailyUnit: "m3",
  },
};

export const EDITABLE_COMPONENTS = [
  "DONUTS",
  "GAUGE",
  "ENERGY_BUDGET_RESULT",
  "ENERGY_BUDGET_OVERVIEW",
  "MONTHLY_FUEL_USAGE",
  "EQUINOR_VESSEL_REPORT",
];

export const GROUP_BY = [
  {
    id: "month",
    name: "Month",
  },
  {
    id: "year",
    name: "Year",
  },
];

export const EQUINOR_UNITS = [
  {
    id: "l",
    name: "l",
  },
  {
    id: "kg",
    name: "kg",
  },
];

export const DAILY_UNITS = [
  {
    id: "m3",
    name: "m3",
  },
  {
    id: "t",
    name: "t",
  },
];
