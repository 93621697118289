import React from "react";
import PropTypes from "prop-types";
import styles from "./MonthPicker.css";
import pickerStyles from "../PickerStyles.css";
import ContextBrowser from "../ContextBrowser";
import classNames from "../../../../common/classNames";
import Divider from "../Divider";

function renderColumn(page, select) {
  return (
    <div className={styles.monthColumn}>
      {page.items.map((item) => (
        <div className={styles.monthItem} key={item.title}>
          <button
            disabled={!item.isEnabled}
            className={classNames(
              styles.button,
              item.isSelected && styles.active
            )}
            data-test-id="datePicker-months"
            onClick={() => select(item)}
          >
            {item.title}
          </button>
        </div>
      ))}
    </div>
  );
}

const MonthPicker = (props) => {
  const { options, getNextOptions, getPrevOptions, select, apply } = props;
  const { page1, page2, hasNext, hasPrev } = options;

  return (
    <div>
      <ContextBrowser
        mode="split"
        title={[page1.year, page2.year]}
        next={hasNext}
        prev={hasPrev}
        onNext={getNextOptions}
        onPrev={getPrevOptions}
      />
      <div className={styles.monthContainer}>
        {renderColumn(page1, select)}
        <Divider visible={true} />
        {renderColumn(page2, select)}
      </div>
      <button
        className={pickerStyles.applyBtn}
        onClick={apply}
        data-test-id="datePickerMonthApply-button"
      >
        Apply
      </button>
    </div>
  );
};

const pagePropType = PropTypes.shape({
  year: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.instanceOf(Date).isRequired,
      isEnabled: PropTypes.bool.isRequired,
      isSelected: PropTypes.bool.isRequired,
    })
  ),
});

MonthPicker.propTypes = {
  options: PropTypes.shape({
    page1: pagePropType.isRequired,
    page2: pagePropType.isRequired,
    hasNext: PropTypes.bool.isRequired,
    hasPrev: PropTypes.bool.isRequired,
  }).isRequired,
  select: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired,
  getNextOptions: PropTypes.func.isRequired,
  getPrevOptions: PropTypes.func.isRequired,
};

export default MonthPicker;
