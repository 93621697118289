import PropTypes from "prop-types";
import React from "react";
import styles from "./ContextBrowser.css";
import ContextBrowserPrevNextBtn from "./ContextBrowserPrevNextBtn.js";
import ContextBrowserTitle from "./ContextBrowserTitle.js";
import { TERMS } from "../../../config";

export default class ContextBrowser extends React.Component {
  prevNext(dir) {
    this.props.prevNext(dir);
    if (dir === "next") {
      this.props.onNext();
    } else {
      this.props.onPrev();
    }
  }

  render() {
    let prevButton, nextButton, border, title;
    const {
      selectedValue,
      dateFormat,
      isTitleDatePicker,
      showMonthYearPicker,
      limits,
      onClickTitle,
    } = this.props;

    prevButton = (
      <ContextBrowserPrevNextBtn
        direction="prev"
        enabled={this.props.prev}
        title={TERMS.prev}
        clickAction={this.prevNext.bind(this, "prev")}
      />
    );
    nextButton = (
      <ContextBrowserPrevNextBtn
        direction="next"
        enabled={this.props.next}
        title={TERMS.prev}
        clickAction={this.prevNext.bind(this, "next")}
      />
    );

    title = (
      <ContextBrowserTitle
        title={this.props.title}
        onClickTitle={onClickTitle}
        selectedValue={selectedValue}
        dateFormat={dateFormat}
        isTitleDatePicker={isTitleDatePicker}
        showMonthYearPicker={showMonthYearPicker}
        limits={limits}
      />
    );

    if (this.props.mode === "split") {
      border = <div className={styles.border} />;
    }

    //Since browsing left and right might not always be available
    //we have the boolean props "prev" and "next"
    //With these props we can enable/turn off browsing forth and back

    //Setting the "mode" will be necessary when we need to know
    //what we are actually browsing

    //If only one button is present we will not render the vertical border

    return (
      <div className={styles.container}>
        {prevButton}
        {border}
        {title}
        {nextButton}
      </div>
    );
  }
}

ContextBrowser.propTypes = {
  mode: PropTypes.any,
  title: PropTypes.any,
  prev: PropTypes.bool,
  next: PropTypes.bool,
  prevNext: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onClickTitle: PropTypes.func,
  isTitleDatePicker: PropTypes.bool,
  dateFormat: PropTypes.string,
  selectedValue: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
  limits: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
};

ContextBrowser.defaultProps = {
  prevNext: () => {},
};
