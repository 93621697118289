import t from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMapDataVessel } from "../actions/action.fetchMapData";
import { setMapViewData } from "../actions/action.setMapViewData";
import Loader from "../../common/components/Loader/Loader";
import VesselMap from "../components/VesselMap/VesselMap";
import { isContextChanged, isContextValid } from "../common/contexts";
import { fitMapToFirstVessel } from "../actions/action.fitMapToVessels";
import { getVessels } from "../reducers/reducer.fetchMapData";

class VesselMapContainer extends Component {
  componentDidMount() {
    if (isContextValid(this.props.context)) {
      this.props.fetchMapDataVessel(this.props.context);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isContextValid(this.props.context) &&
      isContextChanged(this.props.context, prevProps.context)
    ) {
      this.props.fetchMapDataVessel(this.props.context);
    }

    const prevVessels = getVessels(prevProps.mapData);
    const vessels = getVessels(this.props.mapData);

    if (prevVessels !== vessels) {
      this.props.fitMapToFirstVessel(vessels);
    }
  }

  render() {
    const {
      appConfig,
      mapData,
      mapView,
      locations,
      setMapViewData: onChange,
    } = this.props;
    if (!mapData.data) {
      return (
        <Loader text="Loading map data" error={mapData.error} expand={true} />
      );
    }
    return (
      <VesselMap
        apiKey={appConfig.GOOGLE_MAPS_API_KEY}
        vessels={mapData.data.vessels}
        locations={locations.locations}
        center={mapView.center}
        zoom={mapView.zoom}
        defaultCenter={mapView.defaultCenter}
        defaultZoom={mapView.defaultZoom}
        onChange={onChange}
      />
    );
  }
}

VesselMapContainer.propTypes = {
  context: t.object.isRequired,
};

export default connect(
  (state) => ({
    appConfig: state.appConfig,
    mapData: state.fetchMapData,
    mapView: state.mapView,
    locations: state.locations,
  }),
  { fetchMapDataVessel, fitMapToFirstVessel, setMapViewData }
)(VesselMapContainer);
