import React from "react";
import styles from "./Trend.css";
import Sparkline from "../../../../../../common/components/Sparkline/Sparkline";
import SparklineLine from "../../../../../../common/components/Sparkline/SparklineLine";
import { Vessel } from "../../../types";
import { DateType } from "../../../../../components/DatePicker/Types";
import { useDatePickerType } from "../../../hooks/useDatePickerType";

export const Trend = ({ graph, percentageValue }: Vessel["trend"]) => {
  const dateType = useDatePickerType();
  const isPositive = percentageValue >= 0;
  const graphData =
    graph.length === 0
      ? []
      : graph
          .map((c) => c.consumption)
          .reduce((previous, current) => {
            if (previous.length !== current.length) {
              throw new Error(
                "The length of the consumption graphs for different fuel types are not of the same type and cannot be joined"
              );
            }
            return previous.map((c, i) => c + current[i]);
          });

  return (
    <div className={styles.container}>
      {graphData.length > 0 && (
        <>
          <div className={styles.percentage}>
            <div
              className={`${styles.percentageTriangle} ${
                isPositive
                  ? styles.percentageTrianglePositive
                  : styles.percentageTriangleNegative
              }`}
            />
            <div
              className={`${styles.percentageValue} ${
                isPositive
                  ? styles.percentageValuePositive
                  : styles.percentageValueNegative
              }`}
            >
              {percentageValue.toFixed(1)}%
            </div>
          </div>
          <div className={styles.intervalText}>
            {dateType !== null ? intervalText[dateType] : ""}
          </div>
          <div className={styles.graphContainer}>
            <Sparkline
              // Unfortunately the Sparkline has a default margin of 10, so this needs to be reset by setting the prop to -10.
              margin={{ top: -10 }}
              width={160} // This need to match the width of the container of the trend column in css
              height={23}
              data={[graphData]}
              yDomain={[Math.min(...graphData), Math.max(...graphData)]}
              xDomain={[0, graphData.length]}
            >
              {/* The line actually looks better in most cases with the lastValueIndicator set to true.
                   However, the indicator sometimes gets clipped if it is at the top or bottom*/}
              <SparklineLine lastValueIndicator={false} strokeWidth={1} />
            </Sparkline>
          </div>
        </>
      )}
    </div>
  );
};

const intervalText: Record<DateType, string> = {
  dateRange: "",
  leg: "",
  month: "(since 1M)",
  quarter: "(since 1Q)",
  voyage: "",
  year: "(since 1Y)",
};
