import { MANUAL_FUEL_DATA } from "../api";
import { calendarManualFuelData } from "./action.types";
import fetch from "./action.fetch";
import { capitalize } from "lodash";
import moment from "moment/moment";

export const fetchManualFuelData = (vesselId, dateRange, timeOffset) =>
  fetch({
    url: MANUAL_FUEL_DATA.getDataForVessel(vesselId, dateRange, timeOffset),
    actionType: calendarManualFuelData.FETCH_CALENDAR_MANUAL_FUEL_DATA,
    actionData: { dateRange, timeOffset },
  });
export const fetchManualFuelDataConfig = (vesselId) =>
  fetch({
    url: MANUAL_FUEL_DATA.getConfigForVessel(vesselId),
    actionType: calendarManualFuelData.FETCH_MANUAL_FUEL_DATA_CONFIG,
  });
export const addManualFuelData = (day) => ({
  type: calendarManualFuelData.ADD_MANUAL_FUEL_DATA,
  day: moment.utc(day).format("YYYY-MM-DD"),
});
export const closeManualFuelDataDialog = () => ({
  type: calendarManualFuelData.CLOSE_MANUAL_FUEL_DATA_DIALOG,
});
export const toggleManualFuelData = () => ({
  type: calendarManualFuelData.TOGGLE_CALENDAR_MANUAL_FUEL_DATA,
});
export const manualFuelDataFieldValueChanged = (
  field,
  value,
  index,
  options
) => {
  if (value.id) {
    value = value.id;
  }
  return {
    type: calendarManualFuelData.MANUAL_FUEL_DATA_FIELD_VALUE_CHANGED,
    field,
    value,
    index,
    options,
  };
};
export const addRowManualFuelDataDialog = () => ({
  type: calendarManualFuelData.ADD_ROW_MANUAL_FUEL_DATA_DIALOG,
});
export const removeRowManualFuelDataDialog = (index) => ({
  type: calendarManualFuelData.REMOVE_ROW_MANUAL_FUEL_DATA_DIALOG,
  index,
});
export const saveCalendarManualFuelData =
  (activeVesselId, timeOffset) =>
  (dispatch, getState, { http }) => {
    const { calendar } = getState();
    const { manualFuelDataDialog } = calendar;
    const isNew = manualFuelDataDialog.mode === "new";
    const data = manualFuelDataDialog.data.map((d) => {
      let consumptions = d.consumptions.map((c) => {
        return {
          fuelType: capitalize(c.fuelType),
          consumption: c.consumption * 1000,
        };
      });
      return {
        ...d,
        consumptions: consumptions,
      };
    });
    const date = manualFuelDataDialog.date + "T00:00:00Z";
    dispatch({
      type: calendarManualFuelData.SAVE_MANUAL_FUEL_DATA,
    });
    const method = isNew ? "post" : "put";
    let request = {
      method: method,
      url: MANUAL_FUEL_DATA.addManualFuelData(activeVesselId),
      data,
    };
    if (method === "put" && data.length === 0) {
      request = {
        method: "delete",
        url: MANUAL_FUEL_DATA.deleteManualFuelData(activeVesselId, date),
      };
    }
    http(request)
      .then((result) => {
        dispatch({
          type: calendarManualFuelData.SAVE_MANUAL_FUEL_DATA_SUCCESS,
          data: result,
        });
        dispatch(
          fetchManualFuelData(activeVesselId, calendar.date.range, timeOffset)
        );
      })
      .catch((error) => {
        dispatch({
          type: calendarManualFuelData.SAVE_EVENT_ERROR,
          error,
        });
      });
  };
