import { CIIScore, CIIScores, CIIScoresForAYear, Point } from "./Types";

export function organizeScores(data: CIIScore[]): CIIScores {
  return {
    year: getMaxYear(data),
    scores: createScores(data),
  };
}

function createScores(data: CIIScore[]): CIIScoresForAYear[] {
  const result: CIIScoresForAYear[] = [];
  const dataMap = new Map<number, Point[]>();
  data.forEach((dataPoint: CIIScore) => {
    const year = new Date(dataPoint.date).getFullYear();
    if (!dataMap.has(year)) {
      dataMap.set(year, []);
    }
    const point = dataMap.get(year);
    if (point) {
      point.push(createPoint(dataPoint));
    }
  });

  const sortedKeys = Array.from(dataMap.keys()).sort(
    (a: number, b: number) => b - a
  );

  sortedKeys.forEach((key) => {
    const values = dataMap.get(key) ?? [];
    result.push(values);
  });

  return result;
}

function createPoint(data: CIIScore): Point {
  return {
    x: transformDate(data.date),
    y: data.score,
  };
}

function transformDate(date: string): Date {
  const source = new Date(new Date(date).setFullYear(2000));
  const dayOfWeek = source.getDay();
  const daysFromSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1; // Calculate days from the nearest Saturday, because 2000-01-01 is a Saturday

  const saturdayBeforeDate = new Date(source);
  saturdayBeforeDate.setDate(source.getDate() - daysFromSaturday);
  return saturdayBeforeDate;
}

function getMaxYear(data: CIIScore[]): number {
  if (data.length === 0) {
    return new Date().getFullYear(); // Return current year if the array is empty
  }

  return data.reduce((max: number, entry: CIIScore) => {
    const year = new Date(entry.date).getFullYear();
    return year > max ? year : max;
  }, new Date(data[0].date).getFullYear());
}
