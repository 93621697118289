import { isEqual, range } from "lodash";
import { removeCurrentSelectedValues } from ".";
import {
  addDays,
  addMonths,
  atEndOfDay,
  atEndOfMonth,
  atStartOfDay,
  createDate,
  getDay,
  getDayOfWeek,
  getMonth,
  getShortDayName,
  getYear,
  now,
  from as parseDateFrom,
} from "../../common/dates";
import monthPicker from "./monthPicker";

// TODO Handle selection of single day

const dayInMillis = 24 * 60 * 60 * 1000;
const maxDuration = 365;

const daysBetween = (from, to) =>
  Math.round((to.getTime() - from.getTime()) / dayInMillis);

const clamp = (value, { limits, current }) => {
  removeCurrentSelectedValues(current);
  if (!limits) {
    return value;
  }
  return {
    from: value.from < limits.from ? atStartOfDay(limits.from) : value.from,
    to: value.to > limits.to ? atStartOfDay(limits.to) : value.to,
  };
};

const defaultValue_handleDefaultDate = (defaultDate, limits) => {
  const { from, to } = defaultDate.value;
  if (from && to) {
    return {
      from: atStartOfDay(from),
      to: atStartOfDay(to),
    };
  } else if (Array.isArray(defaultDate.value)) {
    return {
      from: atStartOfDay(defaultDate.value[0]),
      to: atStartOfDay(defaultDate.value[1]),
    };
  }
  if (!limits || addDays(6, defaultDate.value) <= limits.to) {
    if (limits && defaultDate.value < limits.from) {
      return {
        from: atStartOfDay(limits.from),
        to: addDays(6, atStartOfDay(limits.from)),
      };
    }
    return {
      from: atStartOfDay(defaultDate.value),
      to: addDays(6, atStartOfDay(defaultDate.value)),
    };
  }
};

export default {
  canSelectNext: ({ to }, { limits }) =>
    !limits || to < atStartOfDay(limits.to),
  canSelectPrev: ({ from }, { limits }) => !limits || from > limits.from,
  next: ({ from, to }) => {
    const diff = Math.max(1, daysBetween(from, to));
    return { from: addDays(diff, from), to: addDays(diff, to) };
  },
  prev: ({ from, to }) => {
    const diff = Math.max(1, daysBetween(from, to));
    return {
      from: addDays(-diff, from),
      to: addDays(-diff, to),
    };
  },
  clamp,
  defaultValue: ({ current, limits }, defaultDate) => {
    const currentValue =
      current && (current.currentSelectedValue || current.value);
    if (currentValue) {
      return getCurrentValue(currentValue, current, limits);
    }
    if (defaultDate) {
      return defaultValue_handleDefaultDate(defaultDate, limits);
    }
    if (!limits) {
      return {
        from: addDays(-6, atStartOfDay(now())),
        to: atStartOfDay(now()),
      };
    }
    return clamp(
      {
        from: addDays(-6, atStartOfDay(limits.to)),
        to: atStartOfDay(limits.to),
      },
      { limits }
    );
  },
  format: ({ from, to }, timeOffsetFormat) =>
    `${getDay(from)}/${getMonth(from)}/${getYear(from)} - ` +
    `${getDay(to)}/${getMonth(to)}/${getYear(to)} ` +
    `${timeOffsetFormat ? timeOffsetFormat : ""}`,
  toJSON: ({ from, to }) => ({
    from: from.toISOString(),
    to: atEndOfDay(to).toISOString(),
  }),
  options: ({ from, to }, { limits }) => {
    const toMonth = getMonth(to);
    const isEven = toMonth % 2 === 0;
    const firstMonth = createDate(getYear(to), isEven ? toMonth - 1 : toMonth);
    return createOptions(firstMonth, { from, to }, { from, to }, { limits });
  },
  selectOption: (
    option,
    currentOptions,
    { limits, current },
    type,
    defaultDate
  ) => {
    let { from, to } = currentOptions.selectionState;
    let selectionState;
    const value = option.value;

    if (!from || (from && to)) {
      selectionState = { from: value, to: null };
    } else if (isEqual(from, value) && !to) {
      selectionState = { from: null, to: null };
    } else {
      selectionState = { from, to: value };
    }

    if (
      selectionState.from &&
      selectionState.to &&
      selectionState.from > selectionState.to
    ) {
      selectionState = { from: selectionState.to, to: selectionState.from };
    }

    const selectedValue = {
      from: selectionState.from,
      to: selectionState.to || selectionState.from,
    };

    if (current) {
      setCurrentSelectedValue(current, selectionState, type);
    } else if (defaultDate) {
      setDefaultValue(defaultDate, selectionState, type);
    }

    return createOptions(
      currentOptions.page1.month,
      selectedValue,
      selectionState,
      { limits }
    );
  },
  nextOptions: (currentOptions, { limits }) => {
    return createOptions(
      addMonths(1, currentOptions.page2.month),
      currentOptions.selectedValue,
      currentOptions.selectionState,
      { limits }
    );
  },
  prevOptions: (currentOptions, { limits }) => {
    return createOptions(
      addMonths(-2, currentOptions.page1.month),
      currentOptions.selectedValue,
      currentOptions.selectionState,
      { limits }
    );
  },
};

function createOptions(firstMonth, selectedValue, selectionState, { limits }) {
  const secondMonth = addMonths(1, firstMonth);

  // We set limit to one year before and after the first selected date.
  if (selectionState.from && !selectionState.to) {
    const fromLimit = new Date(
      new Date(selectionState.from).setDate(
        selectionState.from.getDate() - maxDuration
      )
    );
    const toLimit = new Date(
      new Date(selectionState.from).setDate(
        selectionState.from.getDate() + maxDuration
      )
    );
    limits = {
      from: fromLimit < limits.from ? limits.from : fromLimit,
      to: toLimit > limits.to ? limits.to : toLimit,
    };
  }

  return {
    selectedValue,
    selectionState,
    weekHeaders: range(0, 7).map((i) => getShortDayName(i + 1)),
    page1: {
      title: monthPicker.format(firstMonth),
      month: firstMonth,
      weeks: createWeeks(firstMonth, selectedValue, { limits }),
    },
    page2: {
      title: monthPicker.format(secondMonth),
      month: secondMonth,
      weeks: createWeeks(secondMonth, selectedValue, { limits }),
    },
    hasNext: limits && atStartOfDay(limits.to) > atEndOfMonth(secondMonth),
    hasPrev: limits && limits.from < firstMonth,
    isSelectionValid: Boolean(selectedValue.from) && Boolean(selectedValue.to),
  };
}

function createWeeks(startOfMonth, { from, to }, { limits }) {
  const fromLimit = limits && atStartOfDay(limits.from);
  const toLimit = limits && atStartOfDay(limits.to);
  const month = getMonth(startOfMonth);
  const endOfMonth = atEndOfMonth(startOfMonth);
  const firstDay = addDays(1 - getDayOfWeek(startOfMonth), startOfMonth);
  const lastDay = addDays(7 - getDayOfWeek(endOfMonth), endOfMonth);
  const weekCount = Math.ceil(daysBetween(firstDay, lastDay) / 7);
  const weekRange = range(0, 7);

  return range(0, weekCount).map((weekIndex) =>
    weekRange.map((dayOfWeekIndex) => {
      const date = addDays(weekIndex * 7 + dayOfWeekIndex, firstDay);
      const isSelectionStart = isEqual(date, from);
      const isSelectionEnd = isEqual(date, to);
      const isSelectionBetween =
        !isSelectionStart &&
        !isSelectionEnd &&
        from &&
        to &&
        date >= from &&
        date <= to;
      return {
        key: date.getTime(),
        value: date,
        title: getDay(date),
        isEmpty: getMonth(date) !== month,
        isEnabled: limits && date >= fromLimit && date <= toLimit,
        isSelectionStart,
        isSelectionEnd,
        isSelectionBetween,
      };
    })
  );
}

function getCurrentValue(currentValue, current, limits) {
  const { from, to } = currentValue;
  if (from && to) {
    return {
      from: atStartOfDay(from),
      to: atStartOfDay(to),
    };
  }
  if (!current.currentSelectedValue && current.range && limits && limits.to) {
    const rangeTo = parseDateFrom(current.range.to);
    return {
      from: addDays(
        -6,
        atStartOfDay(limits.to < rangeTo ? limits.to : rangeTo)
      ),
      to: atStartOfDay(atStartOfDay(limits.to < rangeTo ? limits.to : rangeTo)),
    };
  }
  if (limits && limits.from) {
    return {
      from: atStartOfDay(
        currentValue > limits.from ? currentValue : limits.from
      ),
      to: addDays(
        6,
        atStartOfDay(currentValue > limits.from ? currentValue : limits.from)
      ),
    };
  }
  return {
    from: atStartOfDay(currentValue),
    to: addDays(6, atStartOfDay(currentValue)),
  };
}

function setCurrentSelectedValue(current, selectionState, type) {
  if (selectionState.from) {
    current.currentSelectedValue = {};
    current.currentSelectedValue.from = selectionState.from;
    current.currentSelectedValue.to = selectionState.to || selectionState.from;
  } else {
    current.currentSelectedValue = null;
  }
  current.currentSelectedValueType = type;
}

function setDefaultValue(defaultDate, selectionState, type) {
  defaultDate.value = {};
  defaultDate.value.from = selectionState.from;
  defaultDate.value.to = selectionState.to || selectionState.from;
  defaultDate.type = type;
}
