import React from "react";
import { color } from "../../colors";
import PropTypes from "prop-types";
import { COSMETIC_CONSTS } from "../../../onshore/config";
import { SparklineContext } from "./Sparkline";

class SparklineLegends extends React.Component {
  constructor(props) {
    super(props);
    this.positionElements = this.positionElements.bind(this);
  }

  componentDidMount() {
    this.positionElements();
    this.props.addMarginProvider(this);
  }

  componentDidUpdate() {
    this.positionElements();
  }

  componentWillUnmount() {
    this.props.removeMarginProvider(this);
  }

  getMargins() {
    const { xDataType } = this.props;
    const dateTimeXDataType = xDataType === "DateTime" || xDataType === "Date";

    return {
      bottom: dateTimeXDataType ? 40 : 30,
    };
  }

  positionElements() {
    if (!this.el) return;
    const {
      width,
      height,
      horizontalPosition,
      xDataType,
      legendYOffset,
      legendXOffset,
      legendYPosition,
    } = this.props;

    const offsetY = legendYOffset || 0;
    const offsetX = legendXOffset || 0;
    const dateTimeXDataType = xDataType === "DateTime" || xDataType === "Date";
    const marginTop = dateTimeXDataType ? 40 : 28;
    const children = this.el.getElementsByTagName("g");
    const legendsWidth = Array.from(children).reduce((totalWidth, g) => {
      g.setAttribute("transform", `translate(${totalWidth})`);
      totalWidth += g.getBoundingClientRect().width + 10;
      return totalWidth;
    }, 0);
    const yPosition = legendYPosition ?? height + marginTop + offsetY;

    if (horizontalPosition === "center") {
      this.el.setAttribute(
        "transform",
        `translate(${width / 2 - legendsWidth / 2} ${yPosition})`
      );
    }
    if (horizontalPosition === "left") {
      this.el.setAttribute("transform", `translate(${offsetX} ${yPosition})`);
    }
    if (horizontalPosition === "right") {
      this.el.setAttribute(
        "transform",
        `translate(${width - legendsWidth} ${yPosition})`
      );
    }
  }

  render() {
    const { legends, tilting } = this.props;
    const textStyle = {
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 12,
      fill: color("--mid-grey"),
    };
    let rotation = "";
    if (tilting && tilting.number && +tilting.number <= legends.length) {
      const angle = tilting.angle || 8;
      rotation = ` rotate(${angle})`;
    }
    return (
      <g ref={(el) => (this.el = el)}>
        {legends.map((l, index) => (
          <g
            onClick={() => this.props.onLegendClicked(l.dataSetIndex)}
            style={{ cursor: "pointer", pointerEvents: "bounding-box" }}
            key={`legend_${index}`}
          >
            <rect
              height={12}
              width={12}
              style={{
                fill: l.color,
              }}
            />
            <text transform={`translate(15 11)${rotation}`} style={textStyle}>
              {l.text}
            </text>
          </g>
        ))}
      </g>
    );
  }
}

SparklineLegends.defaultProps = {
  height: 30,
  type: "SparklineLegends",
  horizontalPosition: "center",
};

SparklineLegends.propTypes = {
  height: PropTypes.number,
  horizontalPosition: PropTypes.oneOf(["left", "center", "right"]),
  tilting: PropTypes.shape({
    number: PropTypes.number,
    angle: PropTypes.number,
  }),
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      dataSetIndex: PropTypes.number,
    })
  ),
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineLegends {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
