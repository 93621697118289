import React from "react";
import PropTypes from "prop-types";
import { getLimits } from "../../common/vessels";
import RadioCheckbox from "../RadioCheck/RadioCheckbox";
import VesselTrackListElement from "./VesselTrackListElement/VesselTrackListElement";
import { debounce, isEqual } from "lodash";
import styles from "./VesselTrack.css";
import VirtualList from "../VirtualList/VirtualList";
import DatePickerContainer from "../DatePicker/DatePickerContainer";
import Loader from "../../../common/components/Loader/Loader";
import { addHours, from } from "../../common/dates";

export default class VesselTrack extends React.Component {
  constructor(props) {
    super(props);
    this.toggleVesselTrack = this.toggleVesselTrack.bind(this);
  }

  componentDidMount() {
    const { vesselId, date } = this.props;
    this.props.fetchPositionTrack(
      vesselId,
      date.range,
      parseInt(date.timeOffset)
    );
    this.renderVesselTrackRow = this.renderVesselTrackRow.bind(this);
    this.calcVesselTrackRowHeight = this.calcVesselTrackRowHeight.bind(this);
    this.onTrackRowsRendered = debounce(
      this.onTrackRowsRendered.bind(this),
      150,
      { trailing: true }
    );
    this.handleTrackListElementMouseEnter =
      this.handleTrackListElementMouseEnter.bind(this);
    this.handleTrackListElementOnMouseLeave =
      this.handleTrackListElementOnMouseLeave.bind(this);
    this.handleTrackListElementClick =
      this.handleTrackListElementClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { vesselId, date } = this.props;
    if (
      (vesselId && date && !isEqual(vesselId, prevProps.vesselId)) ||
      !isEqual(date, prevProps.date)
    ) {
      this.props.fetchPositionTrack(
        vesselId,
        date.range,
        parseInt(date.timeOffset)
      );
    }

    if (
      this.virtualList &&
      this.virtualList.list &&
      this.props.vesselTrack.activeItemIndex !== null &&
      !(
        this.props.vesselTrack.startIndex >
          this.props.vesselTrack.activeItemIndex &&
        this.props.vesselTrack.stopIndex <
          this.props.vesselTrack.activeItemIndex
      )
    ) {
      this.virtualList.list.scrollToRow(this.props.vesselTrack.activeItemIndex);
    }
  }

  toggleVesselTrack() {
    this.props.togglePositionTrack();
  }

  handleTrackListElementMouseEnter(trackElement) {
    this.props.hoverVesselTrackGroup(trackElement.index);
  }

  handleTrackListElementOnMouseLeave() {
    this.props.hoverVesselTrackGroup(null);
  }

  handleTrackListElementClick(trackElement) {
    if (!trackElement.track) {
      return;
    }
    const points = trackElement.track.map((t) => ({ lat: t.lat, lng: t.lng }));
    this.props.centerPointsInMap(points);
  }

  renderVesselTrackRow({ index, key, style }) {
    const { vesselTrack, date } = this.props;
    const prevElement = vesselTrack.track[index - 1];
    const element = vesselTrack.track[index];
    const timeOffset = parseInt(date.timeOffset);
    const showDate = VesselTrack.hasDifferentPositionDate(
      element,
      prevElement,
      timeOffset
    );
    const elementWithTimeOffset = JSON.parse(JSON.stringify(element));
    // We apply time offset only to the first positionDate for every track event, because only this date is shown in the vessel track view.
    if (timeOffset && elementWithTimeOffset.track.length) {
      elementWithTimeOffset.track[0].positionDate = addHours(
        timeOffset,
        from(elementWithTimeOffset.track[0].positionDate)
      ).toISOString();
    }

    return (
      <VesselTrackListElement
        key={key}
        style={style}
        trackElement={elementWithTimeOffset}
        onMouseEnter={this.handleTrackListElementMouseEnter}
        onMouseLeave={this.handleTrackListElementOnMouseLeave}
        onClick={this.handleTrackListElementClick}
        showDate={showDate}
        timeOffset={timeOffset}
      />
    );
  }

  static hasDifferentPositionDate(element, prevElement, timeOffset) {
    return !!(
      !prevElement ||
      (prevElement.track &&
      element.track &&
      prevElement.track.length > 0 &&
      element.track.length > 0 &&
      // If the timeOffset is different than 0 we compare the element and prevElement dates with included timeoffset.
      timeOffset
        ? addHours(timeOffset, from(prevElement.track[0].positionDate))
            .toISOString()
            .slice(0, 10) !==
          addHours(timeOffset, from(element.track[0].positionDate))
            .toISOString()
            .slice(0, 10)
        : prevElement.track[0].positionDate.slice(0, 10) !==
          element.track[0].positionDate.slice(0, 10))
    );
  }

  calcVesselTrackRowHeight({ index }) {
    const { vesselTrack, date } = this.props;
    const element = vesselTrack.track[index];
    const prevElement = vesselTrack.track[index - 1];
    const timeOffset = parseInt(date.timeOffset);
    const showDate = VesselTrack.hasDifferentPositionDate(
      element,
      prevElement,
      timeOffset
    );
    return VesselTrackListElement.calcHeight(element, showDate);
  }

  onTrackRowsRendered(args) {
    this.props.setVesselTrackScrollBoundary(args.startIndex, args.stopIndex);
  }

  handleDateChange(date) {
    this.props.onDateChanged(date);
  }

  render() {
    const { vesselTrack, vessels, vesselId, date } = this.props;

    return (
      <div className={styles.vesselTrackOuterContainer}>
        <div className={styles.vesselTrackControlContainer}>
          <RadioCheckbox
            label={"Show vessel track"}
            onCheck={this.toggleVesselTrack}
            checked={vesselTrack.isVisible}
          />
          {vesselTrack.isVisible && (
            <div className={styles.vesselTrackDatePickerContainer}>
              <DatePickerContainer
                current={date}
                limits={getLimits(vessels[vesselId])}
                vesselId={vesselId}
                onChange={this.handleDateChange}
                headerContainerStyle={{
                  minWidth: "13.5rem",
                  margin: "0 0.5rem",
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.vesselTrackListContainer}>
          {vesselTrack.isVisible && vesselTrack.isLoading && (
            <Loader text={"Loading vessel track"} />
          )}
          {!vesselTrack.isLoading && vesselTrack.isVisible && (
            <VirtualList
              ref={(el) => (this.virtualList = el)}
              rowHeight={this.calcVesselTrackRowHeight}
              rowCount={vesselTrack.track.length}
              rowRenderer={this.renderVesselTrackRow}
              onRowsRendered={this.onTrackRowsRendered}
              width={372}
            />
          )}
        </div>
      </div>
    );
  }
}

VesselTrack.propTypes = {
  vesselTrack: PropTypes.shape({
    track: PropTypes.array.isRequired,
    startIndex: PropTypes.number.isRequired,
    stopIndex: PropTypes.number.isRequired,
    activeItemIndex: PropTypes.number,
    isVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
  vessels: PropTypes.object.isRequired,
  vesselId: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  onDateChanged: PropTypes.func.isRequired,
  togglePositionTrack: PropTypes.func.isRequired,
  fetchPositionTrack: PropTypes.func.isRequired,
  setVesselTrackScrollBoundary: PropTypes.func.isRequired,
  hoverVesselTrackGroup: PropTypes.func.isRequired,
  centerPointsInMap: PropTypes.func.isRequired,
};
