import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../common/components/Layout/Layout";
import { TERMS, VIEWS } from "../config";
import { toggleNav, logOut } from "../actions";
import { getViewTitle } from "../common/views";
import { useQueryContext } from "../providers/QueryContextProvider";
import { useAppSelector, useThunkDispatch } from "../store/rootState";
import { useRootContext } from "../index";
import { AppConfig } from "../../../config/onshore/environments/AppConfig";

interface Props {
  children: React.ReactNode;
}

export const LayoutContainer = ({ children }: Props) => {
  const { authService } = useRootContext();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const queryContext = useQueryContext();
  const appConfigTitle = useAppSelector(
    (state) => (state.appConfig as AppConfig & { TITLE: string }).TITLE
  );

  useEffect(() => {
    document.title = `${getViewTitle(
      queryContext.pathname
    )} - ${appConfigTitle}`;
  }, [queryContext.pathname, appConfigTitle]);

  const user = useAppSelector((state) => state.user);
  const nav = useAppSelector((state) => state.nav);

  const navigateToPage = (page: any) => {
    queryContext.navigate(page.url);
  };

  const navigateToUserProfile = () => {
    queryContext.navigate(VIEWS.user.url);
    dispatch(toggleNav());
  };

  return (
    <Layout
      appTitle={TERMS.appTitle}
      user={user}
      pages={VIEWS}
      menu={nav.menu.main}
      isNavOpen={nav.isOpen}
      toggleNav={() => dispatch(toggleNav())}
      logOut={() => thunkDispatch(logOut(authService))}
      navigateToUserProfile={navigateToUserProfile}
      navigateToPage={navigateToPage}
    >
      {children as object}
    </Layout>
  );
};
