import React, { Component } from "react";
import styles from "./VesselPreview.css";

export class VesselPreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { vesselImage } = this.props;

    if (!vesselImage) {
      return <></>;
    }

    if (vesselImage.src) {
      return <img className={styles.picture} src={vesselImage.src} />;
    } else {
      return (
        <img
          className={styles.picture}
          src={URL.createObjectURL(vesselImage.file)}
        />
      );
    }
  }
}
