import { isNil } from "lodash";
import React from "react";
import { formatNumber } from "../../common/numbers";
import { color } from "../../common/colors";
import { EVENT_TYPES } from "../config";
import editIcon from "../../style/img/edit.svg";

const tableStyle = {
  table: {
    width: "100%",
    borderSpacing: "2px",
  },
  th: {
    textAlign: "left",
    fontWeight: "normal",
  },
  tdValue: {
    textAlign: "right",
    verticalAlign: "bottom",
  },
  tdUnit: {
    paddingLeft: "5px",
    verticalAlign: "bottom",
  },
};

const mfdStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  consumption: {
    display: "flex",
    justifyContent: "space-between",
  },
  edit: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginTop: "2px",
  },
};
const replaceLetters = (text) => {
  if (!text) return text;

  if (text.startsWith("l")) {
    return "L" + text.slice(1);
  } else if (text.endsWith("/l")) {
    return text.slice(0, -1) + "L";
  } else {
    return text;
  }
};

export const calendarConfig = {
  documents: {
    properties: {
      name: "Documents",
      shortName: "Doc",
      color: color("--blue-base"),
    },
    value: ({ documentCount }) => {
      return {
        value: (
          <span>
            <span>{formatNumber(documentCount)}</span>
          </span>
        ),
      };
    },
  },
  healthEvents: {
    properties: {
      name: "Health Events",
      shortName: "HE",
      color: color("--normal-red"),
    },
    value: ({ healthEventCount }) => {
      return {
        value: (
          <span>
            <span>{formatNumber(healthEventCount)}</span>
          </span>
        ),
      };
    },
  },
  manualFuelData: {
    properties: {
      name: "Manual Fuel Data",
      shortName: "MF",
      color: color("--magenta-normal"),
    },
    value: (fuelData) => {
      let consumptionSum = Object.entries(
        fuelData.consumptions.flat().reduce((acc, c) => {
          acc[c.fuelType] = (acc[c.fuelType] || 0) + c.consumption;
          return acc;
        }, {})
      ).map((d) => {
        return { fuelType: d[0], consumption: d[1] };
      });

      return {
        value: (
          <div style={mfdStyle.container}>
            {consumptionSum.map((c) => {
              return (
                <div key={c.fuelType} style={mfdStyle.consumption}>
                  <span>{c.fuelType}</span>
                  <span>{`${c.consumption.toFixed(3)}t`}</span>
                </div>
              );
            })}
            <div style={mfdStyle.edit}>
              <img src={editIcon} height={12} alt="edit"></img>
              <label>Edit</label>
            </div>
          </div>
        ),
      };
    },
  },
  events: {
    properties: {
      name: "Events",
      shortName: "E",
      color: color("--white"),
    },
    eventColors: {
      [EVENT_TYPES.configurationUpdate]: color("--bright-green"),
      [EVENT_TYPES.baselineUpdate]: color("--normal-green"),
      [EVENT_TYPES.hullCleaning]: color("--blue-darkest"),
      [EVENT_TYPES.propellerCleaning]: color("--blue-dark"),
      [EVENT_TYPES.dryDock]: color("--yellow-base"),
      [EVENT_TYPES.crew]: color("--yellow-darkest"),
    },
    parseEvent: (event, eventTypes, onClick) => {
      let eventType = eventTypes[event.eventTypeId];
      let eventColor =
        calendarConfig.events.eventColors[event.eventTypeId] ||
        calendarConfig.events.properties.color;
      let eventName =
        isNil(eventType) !== true
          ? eventType.name
          : calendarConfig.events.properties.name;
      return {
        color: eventColor,
        name: eventName,
        date: event.startTime,
        onClick: onClick,
        value: (
          <span>
            <span>{event.title}</span>
          </span>
        ),
      };
    },
  },
  performanceOperator: {
    properties: {
      name: "Operational Data",
      shortName: "Op",
      color: color("--orange-base"),
    },
    value: (operationData) => {
      return {
        value: (
          <table style={tableStyle.table}>
            <tbody>
              {operationData.values.map((d) => (
                <tr key={d.name}>
                  <th style={tableStyle.th}>{d.name}</th>
                  <td style={{ ...tableStyle.tdValue, color: d.color }}>
                    {formatNumber(d.value, d.unit)}
                  </td>
                  <td
                    style={{
                      ...tableStyle.tdUnit,
                      color: d.color,
                    }}
                  >
                    {replaceLetters(d.unit)}{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ),
      };
    },
  },
};
