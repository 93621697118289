import PropTypes from "prop-types";
import React from "react";
import styles from "./CategoryItem.css";
import { Icon } from "../../../../common/components/Icon/Icon";

export default class CategoryItem extends React.Component {
  render() {
    let categoryClass;
    switch (this.props.size) {
      case "medium":
        categoryClass = styles.categoryItemMedium;
        break;
      case "large":
        categoryClass = styles.categoryItemLarge;
        break;
      default:
        categoryClass = styles.categoryItem;
        break;
    }
    return (
      <div className={categoryClass}>
        <Icon icon={this.props.icon} size="l" />
        <div className={styles.textContainer}>
          <h2 className={styles.categoryTitle}>{this.props.title}</h2>
          <p className={styles.categoryDescription}>{this.props.description}</p>
        </div>
      </div>
    );
  }
}

CategoryItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
};
