import {
  FETCH_EMISSIONS_BY_OPERATION_MODES,
  FETCH_EMISSIONS_BY_OPERATION_MODES_ERROR,
  FETCH_EMISSIONS_BY_OPERATION_MODES_SUCCESS,
} from "../actions/action.types";
import createReducer from "./createReducer";
import {
  DataFromSource,
  EmissionsByOperationModesDictionary,
  EmissionsByOperationModesState,
} from "../views/Emissions/EmissionsType";

const initialState: EmissionsByOperationModesState = {
  isLoading: true,
  hasError: false,
  emissionsByOperationModesLists: {},
};

function createEmissionsByOperationModes() {
  return createReducer(initialState, {
    [FETCH_EMISSIONS_BY_OPERATION_MODES]: (
      state: EmissionsByOperationModesState
    ): EmissionsByOperationModesState => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [FETCH_EMISSIONS_BY_OPERATION_MODES_SUCCESS]: (
      state: EmissionsByOperationModesState,
      { data }: { data: DataFromSource[] }
    ): EmissionsByOperationModesState => {
      const updatedDictionary: EmissionsByOperationModesDictionary =
        data.reduce(
          (acc: EmissionsByOperationModesDictionary, obj: DataFromSource) => {
            acc[obj.title] = {
              data: {
                ...obj,
                type: "eeoi",
              },
            };
            return acc;
          },
          {}
        );
      return {
        ...state,
        emissionsByOperationModesLists: updatedDictionary,
        isLoading: false,
      };
    },
    [FETCH_EMISSIONS_BY_OPERATION_MODES_ERROR]: (
      state: EmissionsByOperationModesState,
      { error }: { error: string }
    ): EmissionsByOperationModesState => {
      return {
        ...state,
        hasError: true,
        error,
      };
    },
  });
}

export const emissionsByOperationModes = createEmissionsByOperationModes();
