import createReducer from "./createReducer";
import { excelExportActions } from "../actions/action.types";
import { addHours, fromISOString } from "../common/dates";
import { range } from "lodash";

const setTimeOffset = (date, offset) => {
  return addHours(offset, fromISOString(date));
};

const isNotFound = (error) => error?.response?.status === 404;

const initialState = {
  visible: false,
  running: false,
  error: undefined,
  dateRange: [],
  initialDateRange: [],
  vesselId: null,
  states: [],
  options: {
    timeOffset: {
      enabled: false,
      startTimeOffset: 0,
      endTimeOffset: 0,
      options: range(-12, 13).map((offset) => {
        const formattedOffset = offset > 0 ? `+${offset}` : offset;
        return {
          value: offset,
          label: `UTC ${offset === 0 ? "" : formattedOffset}`,
        };
      }),
    },
  },
};

export const excelExport = createReducer(initialState, {
  [excelExportActions.SHOW_EXPORT]: (
    state,
    { dateRange, vesselId, states }
  ) => ({
    ...state,
    visible: true,
    dateRange,
    initialDateRange: dateRange,
    vesselId,
    states,
  }),
  [excelExportActions.HIDE_EXPORT]: () => ({
    ...initialState,
  }),
  [excelExportActions.TOGGLE_TIME_OFFSET_OPTION]: (state) => ({
    ...state,
    dateRange: state.initialDateRange,
    options: {
      ...state.options,
      timeOffset: {
        ...initialState.options.timeOffset,
        enabled: !state.options.timeOffset.enabled,
      },
    },
  }),
  [excelExportActions.SET_START_TIME_OFFSET]: (state, { offset }) => {
    const startTime = setTimeOffset(state.initialDateRange.from, offset.value);
    const dateRange = { ...state.dateRange };
    dateRange.from = startTime;
    return {
      ...state,
      dateRange,
      options: {
        ...state.options,
        timeOffset: {
          ...state.options.timeOffset,
          startTimeOffset: offset.value,
        },
      },
    };
  },
  [excelExportActions.SET_END_TIME_OFFSET]: (state, { offset }) => {
    const endTime = setTimeOffset(state.initialDateRange.to, offset.value);
    const dateRange = { ...state.dateRange };
    dateRange.to = endTime;
    return {
      ...state,
      dateRange,
      options: {
        ...state.options,
        timeOffset: {
          ...state.options.timeOffset,
          endTimeOffset: offset.value,
        },
      },
    };
  },
  [excelExportActions.EXPORT]: (state) => ({
    ...state,
    running: true,
    error: undefined,
  }),
  [excelExportActions.EXPORT_SUCCESS]: () => ({
    ...initialState,
  }),
  [excelExportActions.EXPORT_ERROR]: (_, { error }) => ({
    ...initialState,
    visible: true,
    error: isNotFound(error)
      ? "No data for the selected period is available to export"
      : "An error occurred",
  }),
});
