import React from "react";
import PropTypes from "prop-types";
import styles from "./VesselTrackMapMarker.css";

class VesselTrackMapMarker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.data);
    }
  }

  render() {
    return <div onClick={this.handleClick} className={styles.container} />;
  }
}

VesselTrackMapMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default VesselTrackMapMarker;
