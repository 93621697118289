import React from "react";
import PropTypes from "prop-types";
import styles from "./FormGroup.css";

class FormGroup extends React.PureComponent {
  render() {
    const { title, children } = this.props;
    return (
      <div className={styles.formGroup}>
        <div className={styles.formGroupHeader}>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <form className={styles.form}>
          <div className={styles.formElement}>{children}</div>
        </form>
      </div>
    );
  }
}

FormGroup.propTypes = {
  title: PropTypes.string,
};

export default FormGroup;
