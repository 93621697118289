import React, { useEffect } from "react";
import { login, logOut } from "../../actions";
import styles from "./Auth.css";
import Loader from "../../../common/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import {
  RootState,
  useAppSelector,
  useThunkDispatch,
} from "../../store/rootState";
import { useRootContext } from "../../index";
import { ThunkDispatch } from "redux-thunk";

const LoggingIn = () => (
  <div className={styles.container}>
    <Loader text="Logging in..." />
  </div>
);

const Forbidden = () => {
  const dispatch = useThunkDispatch();
  const { authService } = useRootContext();
  const onLogOutClicked = (event: React.MouseEvent) => {
    event.preventDefault();
    dispatch(logOut(authService));
  };

  return (
    <div className={styles.container}>
      <h1>Forbidden</h1>
      <p>
        We are sorry, but you do not have the proper permissions to access the
        application.
      </p>
      <p>
        Try{" "}
        <a href="#" onClick={onLogOutClicked}>
          logging in
        </a>{" "}
        with a different account.
      </p>
    </div>
  );
};

const LoginError = ({ error }: { error: any }) => {
  if (error.forbidden) {
    return <Forbidden />;
  }
  return (
    <div className={styles.container}>
      <h1>We will be right back</h1>
      <p>Sorry for the inconvenience.</p>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
}

const EnsureLoggedIn = ({ children }: Props) => {
  const dispatch: ThunkDispatch<RootState, never, never> = useThunkDispatch();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { apiClient, authService } = useRootContext();

  useEffect(() => {
    if (!user.isLoggedIn) {
      dispatch(login(navigate, authService, apiClient));
    }
  }, []);

  if (user.error) {
    return <LoginError logOut={logOut} error={user.error} />;
  } else if (!user.isLoggedIn) {
    return <LoggingIn />;
  }
  return <>{children}</>;
};

export default EnsureLoggedIn;
