import React from "react";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import styles from "./DataDetailsComponentStatus.css";
import { color } from "../../../common/colors";
import { sortBy, some } from "lodash";

class DataDetailsComponentStatus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      componentId: null,
    };
  }

  showDetails(id) {
    if (this.state.componentId === id) {
      this.setState({ componentId: null });
    } else {
      this.setState({ componentId: id });
    }
  }

  getCircleFillColor(item) {
    if (item.statusList.length === 0) {
      return color("--mid-grey");
    }

    switch (item.statusList[0].status) {
      case "Normal":
        return color("--bright-green");
      case "Warning":
        return color("--yellow-base");
      case "Alarm":
        return color("--bright-red");
      case "Notification":
        return color("--orange-base");
      default:
        return color("white");
    }
  }

  getSortedComponents = (components) =>
    some(components, (x) => !isFinite(x.label))
      ? Array.from(components)
      : sortBy(components, (x) => +x.label);

  render() {
    return (
      <DataDetailsComponentContainer fullWidth={this.props.fullWidth}>
        <div className={styles.container}>
          <div className={styles.itemImageContainer}>
            <div className={styles.itemImageAndMarkerWrapper}>
              <img src={this.props.imageUrl} />
              {this.props.components.map((item, i) => {
                const markerStyle = {
                  position: "absolute",
                  left: "calc(" + item.locationX + "% - 30px)",
                  top: "calc(" + item.locationY + "% - 30px)",
                  cursor: "pointer",
                };

                return (
                  <svg
                    key={`list_item_${i}`}
                    height="60"
                    width="60"
                    style={markerStyle}
                    onClick={() => this.showDetails(item.id)}
                  >
                    <circle
                      cx="30"
                      cy="30"
                      r="20"
                      stroke={
                        this.state.componentId === item.id
                          ? color("--orange-base")
                          : "black"
                      }
                      fillOpacity="0.8"
                      strokeWidth="2"
                      fill={this.getCircleFillColor(item)}
                    />
                    <text
                      x="30"
                      y="30"
                      textAnchor="middle"
                      fill="black"
                      fontSize="20"
                      dy="0.4em"
                    >
                      {item.label}
                    </text>
                  </svg>
                );
              })}
            </div>
          </div>

          <div className={styles.labelContainer}>
            {this.getSortedComponents(this.props.components).map((item, i) => {
              return (
                <div
                  key={`list_item_${i}`}
                  className={`${styles.labelButtons} ${
                    this.state.componentId === item.id
                      ? styles.labelButtonActive
                      : ""
                  }`}
                  onClick={() => this.showDetails(item.id)}
                >
                  <div>
                    {item.label}) {item.componentName}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {this.state.componentId !== null ? (
          <div className={styles.tableContainer}>
            <div className={`${styles.flexTable} ${styles.header}`}>
              <div className={styles.flexHeader}>Time</div>
              <div className={styles.flexHeader}>Status</div>
            </div>
            {this.props.components
              .find((i) => i.id === this.state.componentId)
              .statusList.map((item, i) => {
                return (
                  <div
                    key={`list_item_${i}`}
                    className={`${styles.flexTable} ${styles.row}`}
                  >
                    <div className={styles.flexRow}>
                      {item.startTime.substring(0, 19).replace("T", " ")}
                    </div>
                    <div className={styles.flexRow}>{item.status}</div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </DataDetailsComponentContainer>
    );
  }
}

export default DataDetailsComponentStatus;
