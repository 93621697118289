import companies from "./reducer.companies";
import devOps from "./reducer.devOps";
import dataStatus from "./reducer.dataStatus";
import users from "./reducer.users";
import vessels from "./vessels";
import itemConfig from "./reducer.itemConfig";
import vesselGroups from "./reducer.vesselGroups";
import selectedCompanyId from "./reducer.selectedCompanyId";
import energyBudget from "./energyBudget";
import pageConfig from "./reducer.pageConfig";
import healthManagementEvent from "./reducer.healthManagementEvent";
import vesselConfig from "./vesselConfig/reducer.vesselConfig"; // TODO: Combine sub reducers into this first?
import vesselConfigDetails from "./vesselConfig/reducer.vesselConfigDetails";
import onboardFrontendConfig from "./vesselConfig/reducer.onboardFrontendConfig";
import onboardProcessorConfig from "./vesselConfig/reducer.onboardProcessorConfig";
import onshoreFrontendConfig from "./vesselConfig/reducer.onshoreFrontendConfig";
import vesselConfigOperations from "./vesselConfig/reducer.vesselOperations";
import vesselConfigItems from "./vesselConfig/reducer.vesselItems";
import baseline from "./vesselConfig/reducer.baseline";
import vesselConfigPerformanceIndicators from "./vesselConfig/reducer.vesselPerformanceIndicators";
import hullPerformanceConfig from "./vesselConfig/reducer.hullPerformanceConfig";
import cargoConfig from "./reducer.cargoconfig";
import energyFlow from "./vesselConfig/reducer.energyFlow";
import telemetry from "./reducer.telemetry";
import dataMaintenance from "./reducer.dataMaintenance";
import pageLayoutConfiguration from "./reducer.pageLayoutConfiguration";
import { combineReducers } from "redux";

export default combineReducers({
  companies,
  devOps,
  dataStatus,
  users,
  vessels,
  itemConfig,
  vesselGroups,
  selectedCompanyId,
  energyBudget,
  pageConfig,
  healthManagementEvent,
  vesselConfig,
  vesselConfigDetails,
  onboardFrontendConfig,
  onshoreFrontendConfig,
  vesselConfigOperations,
  vesselConfigItems,
  onboardProcessorConfig,
  vesselConfigPerformanceIndicators,
  baseline,
  hullPerformanceConfig,
  cargoConfig,
  energyFlow,
  telemetry,
  dataMaintenance,
  pageLayoutConfiguration,
});
