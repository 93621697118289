import createReducer from "./createReducer";
import {
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_ERROR,
} from "../actions/action.types";

const initialState = {
  data: [],
};

export default createReducer(initialState, {
  [FETCH_GROUPS_SUCCESS]: (_state, action) => ({
    data: action.data,
  }),

  [FETCH_GROUPS_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
  }),
});
