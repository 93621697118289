import {
  atEndOfDay,
  atEndOfMonth,
  atEndOfQuarter,
  atEndOfYear,
  atStartOfDay,
  atStartOfMonth,
  atStartOfQuarter,
  atStartOfYear,
  createDate,
  formatDate,
  formatQuarter,
  formatYear,
  formatYearMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDate,
  now,
  parseLocalDate,
  parseQuarter,
  parseYear,
  parseYearMonth,
  toLocalDateISOString,
  toQuarterISOString,
  toYearISOString,
  toYearMonthISOString,
  formatDateTime,
  parseLocalDateTime,
  toLocalDateTimeISOString,
} from "./dates";
import { isNullOrUndefined } from "../../common/objects";
import { get } from "lodash";
import {
  DatePickerValue,
  DateRangeAsString,
  DateRange,
  DateType,
  DateRangeWithId,
} from "../components/DatePicker/Types";

export function isDateRangeDifferent(
  a: DateRangeAsString,
  b: DateRangeAsString
): boolean {
  return !isSameDate(a.from, b.from) || !isSameDate(a.to, b.to);
}

export function isTimeOffsetDifferent(
  currentTimeOffset: number,
  prevTimeOffset: number
): boolean {
  return currentTimeOffset !== prevTimeOffset;
}

export function isDateWithinRange(date: Date, range: DateRange) {
  if (isNullOrUndefined(date) || isNullOrUndefined(range.from)) {
    return false;
  }
  return !isBefore(date, range.from) && !isAfter(date, range.to || now());
}

export function checkIfTimeOffsetIsValidUtcNumber(timeOffset: string): boolean {
  return (
    !isNaN(parseInt(timeOffset)) &&
    Number.isInteger(+timeOffset) &&
    parseInt(timeOffset) <= 14 &&
    parseInt(timeOffset) >= -12 &&
    timeOffset !== "-0"
  );
}

export function formatDateRange(
  range: DateRangeAsString,
  type: DateType
): string {
  switch (type) {
    case "year":
      return formatYear(range.from);
    case "quarter":
      return formatQuarter(range.from);
    case "month":
      return formatYearMonth(range.from);
    case "leg":
    case "voyage":
      return `${formatDateTime(range.from)} - ${formatDateTime(range.to)}`;
    default:
      return formatDate(range.from) + " - " + formatDate(range.to);
  }
}

export function defaultDateFilter(
  timeOffset = 0,
  { endTime }: any = {}
): DatePickerValue {
  const value: Date = atStartOfYear(new Date(endTime || Date.now()));
  return createYearFilter(value, timeOffset);
}

export function createYearFilter(date: Date, timeOffset = 0): DatePickerValue {
  const value = atStartOfYear(date);
  return {
    type: "year",
    value,
    range: {
      from: value.toISOString(),
      to: atEndOfYear(value).toISOString(),
    },
    timeOffset,
  };
}

export function createMonthFilter(date: Date, timeOffset = 0): DatePickerValue {
  const value = atStartOfMonth(date);
  return {
    type: "month",
    value,
    range: {
      from: value.toISOString(),
      to: atEndOfMonth(value).toISOString(),
    },
    timeOffset,
  };
}

export function createQuarterFilter(
  date: Date,
  timeOffset = 0
): DatePickerValue {
  const value = atStartOfQuarter(date);
  return {
    type: "quarter",
    value,
    range: {
      from: value.toISOString(),
      to: atEndOfQuarter(value).toISOString(),
    },
    timeOffset,
  };
}

export function createDateRangeFilter(
  date: DateRange,
  timeOffset = 0
): DatePickerValue {
  const { from, to } = date;
  const fromValue = atStartOfDay(from);
  const toValue = to ? atStartOfDay(to) : undefined;
  return {
    type: "dateRange",
    value: {
      from: fromValue,
      to: toValue,
    },
    range: {
      from: fromValue.toISOString(),
      to: toValue ? atEndOfDay(toValue).toISOString() : "",
    },
    timeOffset,
  };
}

export function createVesselStateFilter(
  date: DateRangeWithId,
  timeOffset = 0
): DatePickerValue {
  const { type, from, to, id } = date;
  return {
    type,
    value: {
      from,
      to,
      id,
    },
    range: {
      from: from.toISOString(),
      to: to.toISOString(),
    },
    timeOffset,
  };
}

export function createTimeOffsetFilter(
  date: DatePickerValue,
  timeOffset: number
): number {
  if (date.timeOffset && !isNaN(date.timeOffset)) {
    if (date.timeOffset < -12) {
      return -12;
    }
    if (date.timeOffset > 14) {
      return 14;
    }
    return 0;
  }
  return timeOffset;
}

function clampYearFilter(
  yearRange: DatePickerValue,
  limits: DateRange
): DatePickerValue {
  if (!limits) {
    return yearRange;
  } else if (yearRange.value < limits.from) {
    return createYearFilter(
      createDate(getYear(limits.from)),
      yearRange.timeOffset
    );
  } else if (limits.to && yearRange.value > limits.to) {
    return createYearFilter(
      createDate(getYear(limits.to)),
      yearRange.timeOffset
    );
  }
  return yearRange;
}

function clampMonthFilter(
  monthRange: DatePickerValue,
  limits: DateRange
): DatePickerValue {
  if (!limits) {
    return monthRange;
  }
  if (monthRange.value < limits.from) {
    return createMonthFilter(
      atStartOfMonth(limits.from),
      monthRange.timeOffset
    );
  }
  if (limits.to && monthRange.value > limits.to) {
    return createMonthFilter(atStartOfMonth(limits.to), monthRange.timeOffset);
  }
  return monthRange;
}

function clampQuarterFilter(
  quarterRange: DatePickerValue,
  limits: DateRange
): DatePickerValue {
  if (!limits) {
    return quarterRange;
  }
  if (limits.to && quarterRange.value > limits.to) {
    return createQuarterFilter(
      atStartOfQuarter(limits.to),
      quarterRange.timeOffset
    );
  }
  if (quarterRange.value < limits.from) {
    return createQuarterFilter(
      atStartOfQuarter(limits.from),
      quarterRange.timeOffset
    );
  }
  return quarterRange;
}

function clampDateRangeFilter(
  dateRange: DatePickerValue,
  limits: DateRange
): DatePickerValue {
  if (!limits) {
    return dateRange;
  }
  const value = dateRange.value as DateRange;
  const adjustedFrom =
    value.from < limits.from ? atStartOfDay(limits.from) : value.from;
  const getTo = () => {
    let result = undefined;
    if (limits.to && value.to) {
      if (value.to > limits.to) {
        result = atStartOfDay(limits.to);
      } else {
        result = value.to;
      }
    }
    return result;
  };
  const adjustedTo =
    value.to && value.to < adjustedFrom ? adjustedFrom : getTo();
  return createDateRangeFilter(
    {
      from: adjustedFrom,
      to: adjustedTo,
    },
    dateRange.timeOffset
  );
}

export function clampDateFilter(
  dateFilter: DatePickerValue,
  limits?: DateRange
): DatePickerValue {
  const from = (limits && limits.from) || new Date(Number.MIN_VALUE);
  const to = (limits && limits.to) || new Date();
  const ensuredLimits: DateRange = {
    from,
    to: to > from ? to : from,
  };
  switch (dateFilter.type) {
    case "year":
      return clampYearFilter(dateFilter, ensuredLimits);
    case "month":
      return clampMonthFilter(dateFilter, ensuredLimits);
    case "quarter":
      return clampQuarterFilter(dateFilter, ensuredLimits);
    case "dateRange":
      return clampDateRangeFilter(dateFilter, ensuredLimits);
    default:
      return dateFilter;
  }
}

export const dateFilterToJSON = (dateFilter: DatePickerValue): any => {
  const { type, value, timeOffset } = dateFilter || {};
  switch (type) {
    case "year":
      return { type, value: toYearISOString(value as Date), timeOffset };
    case "month":
      return { type, value: toYearMonthISOString(value as Date), timeOffset };
    case "quarter":
      return { type, value: toQuarterISOString(value as Date), timeOffset };
    case "dateRange": {
      const range = value as DateRange;
      return {
        type,
        timeOffset,
        value: {
          from: toLocalDateISOString(range.from),
          to: range.to ? toLocalDateISOString(range.to) : "",
        },
      };
    }
    case "voyage":
    case "leg": {
      const { id, from, to } = value as DateRangeWithId;
      return {
        type,
        id: id,
        value: {
          from: toLocalDateTimeISOString(from),
          to: to ? toLocalDateTimeISOString(to) : "",
        },
        timeOffset,
      };
    }
    default:
      return null;
  }
};

export const dateFilterFromJSON = (dateFilter?: DatePickerValue) => {
  if (!dateFilter) {
    return null;
  }

  const { type } = dateFilter;

  switch (type) {
    case "year": {
      const year = parseYear(dateFilter.value.toString());
      return year ? createYearFilter(year, dateFilter.timeOffset) : null;
    }
    case "month": {
      const yearMonth = parseYearMonth(dateFilter.value.toString());
      return yearMonth
        ? createMonthFilter(yearMonth, dateFilter.timeOffset)
        : null;
    }
    case "quarter": {
      const quarter = parseQuarter(dateFilter.value.toString());
      return quarter
        ? createQuarterFilter(quarter, dateFilter.timeOffset)
        : null;
    }
    case "dateRange": {
      const range = dateFilter.value as DateRange;
      const from = parseLocalDate(range.from.toString());
      const to = range.to ? parseLocalDate(range.to.toString()) : undefined;
      return from && to
        ? createDateRangeFilter({ from, to }, dateFilter.timeOffset)
        : null;
    }
    case "voyage":
    case "leg": {
      const range = dateFilter.value as DateRangeWithId;
      const from = parseLocalDateTime(range.from.toString());
      const to = parseLocalDateTime(range.to.toString());
      const id = range.id;
      return from && to
        ? createVesselStateFilter({ type, from, to, id }, dateFilter.timeOffset)
        : null;
    }
  }

  return null;
};

export const convertDateRangeToMonth = ({
  range,
  timeOffset,
}: DatePickerValue): DatePickerValue => {
  const from = new Date(range ? range.from : 0);
  return {
    value: atStartOfMonth(from),
    range: {
      from: atStartOfMonth(from).toISOString(),
      to: atEndOfMonth(from).toISOString(),
    },
    type: "month",
    timeOffset,
  };
};

export const convertVesselSpecificDateRange = (date: any): any => {
  const dateType = get(date, "type");
  const shouldConvert =
    (dateType === "leg" || dateType === "voyage") && date.from && date.to;

  return shouldConvert
    ? {
        type: "dateRange",
        from: toLocalDateISOString(new Date(date.from)),
        to: toLocalDateISOString(new Date(date.to)),
        timeOffset: date.timeOffset,
      }
    : date;
};
