import { TOGGLE_VESSEL_ITEM_DETAILS } from "./action.types";
import { fetchVesselItemDetails } from "./action.fetchVesselItemDetails";

export const toggleVesselItemDetails =
  (vesselItemId, dateRange, states, shouldFetch, timeOffset) => (dispatch) => {
    dispatch({ type: TOGGLE_VESSEL_ITEM_DETAILS, vesselItemId });
    if (shouldFetch) {
      dispatch(
        fetchVesselItemDetails(vesselItemId, dateRange, timeOffset, states)
      );
    }
  };
