import { ADMIN_COMPANIES, adminApi } from "../../api";
import fetch from "../action.fetch";
import { convertFieldsToKeyValues } from "../../common/forms";
import {
  FETCH_ADMIN_COMPANIES,
  FETCH_ADMIN_COMPANY,
  CREATE_ADMIN_COMPANY,
  CLOSE_ADMIN_COMPANY_POPUP,
  SAVE_ADMIN_COMPANY,
  SAVE_ADMIN_COMPANY_ERROR,
  SAVE_ADMIN_COMPANY_SUCCESS,
  SET_ADMIN_COMPANY_VALUE,
  admin,
} from "../action.types";

export const fetchCompanies = () =>
  fetch({
    url: ADMIN_COMPANIES.get(),
    actionType: FETCH_ADMIN_COMPANIES,
  });

export const fetchCompany = (id) => {
  return fetch({
    url: ADMIN_COMPANIES.getById(id),
    actionType: FETCH_ADMIN_COMPANY,
    actionData: {
      id,
    },
  });
};

export const fetchModules = () =>
  fetch({
    url: adminApi.modules.get(),
    actionType: admin.modules.FETCH,
  });

export const createCompany = () => ({
  type: CREATE_ADMIN_COMPANY,
});

export const closeCompanyPopup = () => ({
  type: CLOSE_ADMIN_COMPANY_POPUP,
});

export const setCompanyValue = (name, value, valid, message) => {
  return {
    type: SET_ADMIN_COMPANY_VALUE,
    name,
    value,
    valid,
    message,
  };
};

export const saveCompany =
  () =>
  (dispatch, getState, { http }) => {
    const state = getState();
    const { form } = state.admin.companies;
    const payload = convertFieldsToKeyValues(form);
    const isUpdate = form.isUpdate;

    dispatch({ type: SAVE_ADMIN_COMPANY });

    http({
      method: isUpdate ? "put" : "post",
      url: isUpdate ? ADMIN_COMPANIES.put(payload.id) : ADMIN_COMPANIES.post(),
      data: payload,
    })
      .then((result) => {
        dispatch({ type: SAVE_ADMIN_COMPANY_SUCCESS, data: result.data });
        dispatch(fetchCompanies());
      })
      .catch((error) => {
        dispatch({ type: SAVE_ADMIN_COMPANY_ERROR, error });
      });
  };
