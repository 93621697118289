import { FETCH } from "./action.types";

/**
 * Creates a fetch action which will be handled by the fetch middleware (see store/fetchMiddleware.js).
 *
 * The fetch middleware has a default behaviour to prevent concurrent requests of the same action type
 * to take effect. For instance, if the user looks at vessel `A` and then switches to vessel `B` before
 * the data for vessel `A` arrives, then the second `FETCH_VESSEL` action will cancel the response of first
 * `FETCH_VESSEL` action.
 *
 * In some cases, it is not enough to use the action type to distinguish the actions. An example is `FETCH_DONUT`
 * which is used in several instances in one page, but uses the same action type. For these cases, the `key`
 * parameter can be overridden. Donuts `D1` and `D2` for for vessel `A` could then use keys in the form
 * of `FETCH_DONUT_D1` and `FETCH_DONUT_D2`.
 *
 * @param params.url
 *  the url to fetch
 *
 * @param params.method
 *  one of "post" and "get", defaults to "get"
 *
 * @param params.payload
 *  the data to be sent along the request, e.g. for post requests
 *
 * @param params.actionType
 *  the action type that fetch middleware should use to dispatch actions. Initially params.actionType
 *  will be dispatched, then on response an action with the same type but with a `_SUCCESS` or `_ERROR` suffix
 *  is dispatched.
 *
 * @param [params.actionData]
 *  data to be included in the dispatched actions
 *
 * @param [params.takeAll]
 *  (default false) whether to take all responses for a specific action type, or only from the latest request
 *
 * @param [params.key]
 *  (defaults to params.actionType) the key used to distinguish resources which is fetched concurrently
 *
 * @returns {Object} the action object
 */
export default function fetch(params = {}) {
  if (!params.url) {
    throw new Error("url param is required");
  }
  if (!params.actionType) {
    throw new Error("actionType param is required");
  }
  if (params.actionType === FETCH) {
    throw new Error(
      "actionType cannot be FETCH (which is reserved for this action)"
    );
  }
  return {
    type: FETCH,
    method: params.method || "get",
    payload: params.payload,
    url: params.url,
    key: params.key,
    takeAll: params.takeAll,
    actionType: params.actionType,
    actionData: params.actionData,
    onSuccess: params.onSuccess,
    onError: params.onError,
  };
}

export const post = (params = {}) => fetch({ ...params, method: "post" });

export const put = (params = {}) => fetch({ ...params, method: "put" });
