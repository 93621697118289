import { SET_USER_PROFILE_USER_SETTING } from "./action.types";

export const setUserProfileUserSetting = (name, value, valid, message) => {
  return {
    type: SET_USER_PROFILE_USER_SETTING,
    name,
    value,
    valid,
    message,
  };
};
