import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import {
  companyChanged,
  vesselGroupChanged,
  inputChanged,
  vesselImageChanged,
  saveVessel,
  showGenerateApiTokenDialog,
  showWindConfigPopup,
  hideWindConfigPopup,
  closeGenerateApiTokenDialog,
  generateApiToken,
  saveWindConfig,
  downloadWindConfig,
  windConfigChanged,
  copyFromVesselChanged,
  showSaveDialog,
  hideSaveDialog,
} from "../../../../actions/admin/action.vesselconfig.details";
import { fetchVessels } from "../../../../actions/admin";
import Loader from "../../../../../common/components/Loader";
import { areFieldsValid } from "../../../../reducers/admin/vesselConfig/reducer.vesselConfigDetails";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import { noop, get, isEmpty, orderBy, has } from "lodash";
import FormGroup from "../../../../components/Form/FormGroup/FormGroup";
import {
  FormSelectBox,
  FormTextBox,
  FormImageUpload,
  FormCheckBox,
} from "../../../../components/Form";
import GenerateApiTokenPopup from "./GenerateApiTokenPopup";
import WindConfigPopup from "./WindConfigPopup";
import styles from "./VesselDetailsController.css";
import Button from "../../../../../common/components/Button";
import FormDatePicker from "../../../../components/Form/FormDatePicker/FormDatePicker";
import FormBaseController from "../../../../components/Form/FormBaseController";
import { CargoTypes } from "../../../../config";
import ConfirmSaveDialog from "../ConfirmSaveDialog";
import { createSelector } from "reselect";

class VesselDetailsController extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.subscriptionLevels = [
      { id: "basic", name: "Basic" },
      { id: "standard", name: "Standard" },
      { id: "premium", name: "Premium" },
    ];
  }

  componentDidMount() {
    if (!this.props.vessels.vesselList.data) {
      this.props.fetchVessels();
    }
  }

  handleInputChange = (name, value, isValid) => {
    this.props.inputChanged(name, value, isValid);
  };

  handleCompanyChange = (_, value, isValid) => {
    this.props.companyChanged(get(value, "id"), isValid);
  };

  handleSelectBoxChanged = (name, value, isValid) => {
    this.props.inputChanged(name, get(value, "id"), isValid);
  };

  handleVesselGroupChanged = (_, value, isValid) => {
    let vesselGroupIds = value.map((x) => x.id);
    this.props.vesselGroupChanged(vesselGroupIds, isValid);
  };

  handleVesselImageChanged = (name, file) => {
    this.props.vesselImageChanged(name, file);
  };

  handleCopyFromVesselChanged = (_, vessel) => {
    this.props.copyFromVesselChanged(vessel);
  };

  handleSaveVessel = () => {
    const { fields, copyFromVessel } = this.props;
    this.props.hideSaveDialog();
    this.props.saveVessel(fields, copyFromVessel.id);
  };

  renderContent() {
    const {
      error,
      isLoading,
      progressMessage,
      fields,
      isNew,
      hasAccessToken,
      vesselGroupsWithFullName,
      companies,
      showGenerateApiTokenDialog,
      showWindConfigPopup,
      windConfig,
    } = this.props;
    const selectedVesselGroupIds = get(fields, "vesselGroups.value", []);
    const selectedVesselGroups =
      selectedVesselGroupIds &&
      vesselGroupsWithFullName.filter((x) =>
        selectedVesselGroupIds.includes(x.id)
      );
    const selectedCargoType =
      fields.cargoType &&
      CargoTypes.filter((x) => x.id === fields.cargoType.value)[0];

    if (isLoading) {
      return (
        <Loader
          className={styles.loader}
          expand={true}
          error={error}
          text={progressMessage}
        />
      );
    }
    return (
      <div>
        <div className={styles.error}>{error}</div>
        <div className={styles.formContainer}>
          <div className={styles.formImageUploadContainerChild}>
            <FormImageUpload
              src={fields.vesselImage.src}
              fileName={fields.vesselImage.name}
              name={"vesselImage"}
              onChange={this.handleVesselImageChanged}
            />
          </div>
          <div className={styles.formContainerChild}>
            <FormGroup>
              <FormTextBox
                label="Vessel name"
                name={"name"}
                labelPosition={"left"}
                value={fields.name.value}
                onChange={this.handleInputChange}
                required={fields.name.required}
              />
              <FormTextBox
                label="IMO"
                name={"imo"}
                value={fields.imo.value}
                onChange={this.handleInputChange}
                labelPosition={"left"}
                required={fields.imo.required}
              />
              <FormSelectBox
                label="Company"
                name="company"
                labelPosition={"left"}
                options={companies || []}
                optionValKey={"id"}
                optionLabelKey={"name"}
                selectedVal={fields.company.value}
                selectedValKey={"id"}
                selected={fields.company.value}
                onChange={this.handleCompanyChange}
                testId={"companySelectBox"}
                required={fields.company.required}
                disabled={!isNew}
              />
              <FormSelectBox
                label="Groups"
                name="vesselGroups"
                labelPosition={"left"}
                options={
                  orderBy(vesselGroupsWithFullName, ["companyName"]) || []
                }
                optionValKey={"id"}
                optionLabelKey={"fullName"}
                selected={selectedVesselGroups}
                multi
                onChange={this.handleVesselGroupChanged}
                testId={"vesselGroupSelectBox"}
              />
              <FormSelectBox
                label="Cargo Type"
                name="cargoType"
                labelPosition={"left"}
                options={CargoTypes}
                optionValKey={"id"}
                optionLabelKey={"name"}
                selected={selectedCargoType}
                onChange={this.handleSelectBoxChanged}
                testId={"cargoTypeSelectBox"}
              />
              <FormDatePicker
                label={"Start Time"}
                name={"startTime"}
                showTimeSelect={false}
                value={fields.startTime.value}
                onChange={this.handleInputChange}
                labelPosition={"left"}
                testId={"startTime"}
                required={fields.startTime.required}
              />
              <div className={styles.formCheckboxContainer}>
                <FormCheckBox
                  name="useManualFuelDataOnly"
                  value={Boolean(fields.useManualFuelDataOnly.value)}
                  iconPosition="right"
                  label="Use manual fuel data only"
                  onChange={this.handleInputChange}
                />
              </div>
            </FormGroup>
          </div>
          <div className={styles.formContainerChild}>
            <FormGroup>
              <FormTextBox
                label="Remote Port"
                name={"remoteAccessPort"}
                value={get(fields, "remoteAccessPort.value")}
                onChange={this.handleInputChange}
                labelPosition={"left"}
              />
              <FormTextBox
                label="Edge Gateway Id"
                name={"edgeGatewayId"}
                value={get(fields, "edgeGatewayId.value")}
                onChange={this.handleInputChange}
                labelPosition={"left"}
              />
              {isNew && (
                <FormSelectBox
                  label="Subscription level"
                  name="subscriptionLevel"
                  labelPosition={"left"}
                  options={this.subscriptionLevels}
                  optionValKey={"id"}
                  optionLabelKey={"name"}
                  selectedVal={fields.subscriptionLevel.value}
                  selectedValKey={"id"}
                  selected={fields.subscriptionLevel.value}
                  onChange={this.handleSelectBoxChanged}
                  testId={"subscriptionLevel"}
                />
              )}
              {!isNew && (
                <FormBaseController
                  label={"Access Token"}
                  labelPosition={"left"}
                  style={{ height: "42px" }}
                  message={
                    hasAccessToken === true
                      ? "token generated"
                      : "not generated"
                  }
                >
                  <Button
                    height={"42px"}
                    width={"100%"}
                    value={
                      hasAccessToken === true
                        ? "Regenerate API access token"
                        : "Generate API access token"
                    }
                    clickAction={showGenerateApiTokenDialog}
                    testId={"createApiAccessTokenButton"}
                  />
                </FormBaseController>
              )}
              {!isNew && (
                <FormBaseController
                  label={"Wind config"}
                  labelPosition={"left"}
                  style={{ height: "42px" }}
                  message={windConfig.hasValue ? "set" : "not set"}
                >
                  <Button
                    height={"42px"}
                    width={"100%"}
                    value={"Configure Wind coefficients"}
                    clickAction={showWindConfigPopup}
                    testId={"showWindConfigPopup"}
                  />
                </FormBaseController>
              )}
              <div className={styles.formCheckboxContainer}>
                <FormCheckBox
                  name="mdoUnitKgPerHour"
                  value={Boolean(fields.mdoUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use MDO unit kg/h"
                  onChange={this.handleInputChange}
                />

                <FormCheckBox
                  name="hfoUnitKgPerHour"
                  value={Boolean(fields.hfoUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use HFO unit kg/h"
                  onChange={this.handleInputChange}
                />

                <FormCheckBox
                  name="mgoUnitKgPerHour"
                  value={Boolean(fields.mgoUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use MGO unit kg/h"
                  onChange={this.handleInputChange}
                />

                <FormCheckBox
                  name="methanolUnitKgPerHour"
                  value={Boolean(fields.methanolUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use Methanol unit kg/h"
                  onChange={this.handleInputChange}
                />

                <FormCheckBox
                  name="ulsfoUnitKgPerHour"
                  value={Boolean(fields.ulsfoUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use ULSFO unit kg/h"
                  onChange={this.handleInputChange}
                />

                <FormCheckBox
                  name="vlsfoUnitKgPerHour"
                  value={Boolean(fields.vlsfoUnitKgPerHour.value)}
                  iconPosition="right"
                  label="Use VLSFO unit kg/h"
                  onChange={this.handleInputChange}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    );
  }

  renderPopups() {
    const {
      accessToken,
      generateApiToken: onConfirm,
      closeGenerateApiTokenDialog: onClose,
      hideWindConfigPopup: onCancel,
      saveWindConfig: onSave,
      downloadWindConfig: onDownload,
      windConfig,
      fields,
      windConfigChanged: onInputChanged,
    } = this.props;

    const imo = get(fields, "imo.value");
    return (
      <>
        {accessToken.isVisible && (
          <GenerateApiTokenPopup
            vesselName={fields.name.value}
            hasAccessToken={this.props.hasAccessToken}
            secret={accessToken.data}
            isLoading={accessToken.isLoading}
            error={accessToken.error}
            onConfirm={onConfirm}
            onClose={onClose}
          />
        )}
        {windConfig.isVisible && (
          <WindConfigPopup
            {...windConfig}
            imo={imo}
            onInputChanged={onInputChanged}
            onDownload={onDownload}
            onSave={onSave}
            onCancel={onCancel}
          />
        )}
      </>
    );
  }

  render() {
    const {
      isOpen,
      isLoading,
      hasChanges,
      isValid,
      onToggleClick,
      saveVessel: onSave,
      fields,
      isNew,
      vessels,
      showSaveDialog: onShowSaveDialog,
      hideSaveDialog: onCancelClick,
      copyFromVessel,
    } = this.props;

    let allVessels = [];
    const vesselList = vessels.vesselList;

    if (has(vesselList, "data")) {
      allVessels = Object.values(vesselList.data);
    }

    const buttons = [
      {
        label: "Save",
        action: () => (copyFromVessel ? onShowSaveDialog() : onSave(fields)),
        disabled: isLoading || !isOpen || !hasChanges || !isValid,
        isVisible: isOpen,
      },
    ];
    return (
      <>
        <CollapsePanel
          title={"Vessel Details"}
          subTitle={"Add or customize new vessel, group or company"}
          icon={"hull"}
          isOpen={isOpen}
          buttons={buttons}
          onToggleClick={onToggleClick}
          testId={"vesselDetailsController"}
          isEdit={!isNew}
          vessels={allVessels}
          copyFromVesselChanged={this.handleCopyFromVesselChanged}
          copyFromVessel={copyFromVessel}
        >
          {this.renderContent()}
          {this.renderPopups()}
        </CollapsePanel>
        {this.props.showConfirm && copyFromVessel && (
          <ConfirmSaveDialog
            onCancelClick={onCancelClick}
            onConfirmClick={this.handleSaveVessel}
            title={"Save vessel"}
            message={`Are you sure you want to copy the vessel configuration from ${copyFromVessel.name} to ${fields.name.value}?`}
          />
        )}
      </>
    );
  }
}

VesselDetailsController.defaultProps = {
  isOpen: false,
  onToggleClick: noop,
};

VesselDetailsController.propTypes = {
  isOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

const getVesselGroupsWithFullName = createSelector(
  (state) => state.admin.vesselConfigDetails.vesselGroups,
  (vesselGroups) =>
    vesselGroups?.map((vesselGroup) => {
      vesselGroup.fullName = `${vesselGroup.name} (${vesselGroup.companyName})`;
      return vesselGroup;
    }) ?? []
);

const mapStateToProps = (state) => {
  return {
    ...state.admin.vesselConfigDetails,
    windConfig: {
      ...state.admin.vesselConfigDetails.windConfig,
      hasValue: !isEmpty(
        get(state, "admin.vesselConfigDetails.windConfig.value", "")
      ),
    },
    vesselGroupsWithFullName: getVesselGroupsWithFullName(state),
    isValid: areFieldsValid(state.admin.vesselConfigDetails),
    vessels: state.admin.vessels,
  };
};

const mapDispatchToProps = {
  inputChanged,
  companyChanged,
  vesselGroupChanged,
  vesselImageChanged,
  saveVessel,
  showGenerateApiTokenDialog,
  closeGenerateApiTokenDialog,
  generateApiToken,
  showWindConfigPopup,
  hideWindConfigPopup,
  saveWindConfig,
  downloadWindConfig,
  windConfigChanged,
  copyFromVesselChanged,
  showSaveDialog,
  hideSaveDialog,
  fetchVessels,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselDetailsController);
