import t from "prop-types";
import React from "react";
import styles from "./MetricsListItem.css";
import { Icon } from "../../../../common/components/Icon/Icon";
import Metric from "../Metric/Metric";
import Button from "../../../../common/components/Button";
import Loader from "../../../../common/components/Loader";
import MetricDetailsContainer from "./MetricDetailsContainer";
import { createPropertyValueComponent } from "../../PropertyValues/PropertyValues";
import { size, range } from "lodash";
import classNames from "../../../../common/classNames";

const icons = {
  fuel_use: "fuel",
  vessel_speed: "dashboard",
  distance: "map",
  cargo: "cargo",
  weather: "centeredHorizontal",
  events: "calendar",
  documents: "reporting",
  trips: "map",
  legs: "map",
  emissions: "emissions",
  battery: "battery",
  urea: "urea",
  winch: "dm-winch",
  "trawl winch": "dm-winch",
  generator: "power",
  engine: "engine",
  "engine (diesel)": "engine",
  "engine (diesel) (kg)": "engine",
  "engine (gas)": "engine",
  "engine (dual)": "engine",
  "engine (hfo)": "engine",
  "engine (hfo) (kg)": "engine",
  "engine (multi)": "engine",
  "engine (multi) (kg)": "engine",
  boiler: "engine",
  "boiler (kg)": "engine",
  "boiler (multi)": "engine",
  "boiler (multi) (kg)": "engine",
  "lp motor": "dm-motor",
  hull: "hull",
  engineoperation: "engine",
  propulsor: "propulsion",
  propulsoroperation: "propulsion",
  thruster: "propulsion",
  hulloperation: "hull",
  "lp pump": "dm-pump",
};

const trendButtonIcon = (
  <svg width="32" height="32">
    <g>
      <path
        fill="transparent"
        stroke="#fff"
        strokeWidth="2px"
        d="m3.525651,20.55127l5.175661,-8.92487l4.171429,8.644214l3.244444,-5.164076l3.398942,5.276338l8.342857,-10.664939"
      />
    </g>
  </svg>
);

class MetricsListItem extends React.PureComponent {
  static propTypes = {
    data: t.shape({
      vesselItemId: t.string.isRequired,
      type: t.string,
      metrics: t.array,
      title: t.string,
      description: t.string,
      isNoDetailsRow: t.bool,
    }).isRequired,
    details: t.shape({
      isExpanded: t.bool,
      data: t.any,
      error: t.any,
    }),
    dateRange: t.array.isRequired,
    toggleDetails: t.func,
    toggleTrend: t.func.isRequired,
  };

  render() {
    const {
      data,
      details = {},
      columnCount,
      dateRange,
      toggleDetails,
      toggleTrend,
      timeOffset,
    } = this.props;

    const { vesselItemId, isNoDetailsRow } = data;

    const metrics =
      data.metrics &&
      data.metrics.map((item, i) => (
        <div key={i} className={styles.listItemItem}>
          <Metric {...item} />
        </div>
      ));

    const properties =
      data.properties &&
      data.properties.map((item, i) => (
        <div key={`property_${i}`} className={styles.listItemItem}>
          {createPropertyValueComponent(item)}
        </div>
      ));

    const spaceCount = columnCount - (size(metrics) + size(properties));

    const spacers = range(spaceCount).map((i) => (
      <div key={`spacer_${i}`} className={styles.listItemItem} />
    ));

    const type = String(data.type).toLocaleLowerCase();
    const iconName = icons[type];
    const toggleTrendFunc = (e) => {
      e.stopPropagation();
      toggleTrend(vesselItemId);
    };
    return (
      <div
        className={styles.metricsListItem}
        data-expanded={!isNoDetailsRow && details.isExpanded}
        tabIndex="0"
      >
        <div
          className={classNames(
            styles.inner,
            isNoDetailsRow && styles.canNotOpen
          )}
          onClick={toggleDetails}
          data-test-id="vessel-metricsTableRows"
        >
          <div className={styles.listItemDescription}>
            {iconName && <Icon icon={iconName} size="l" />}
            <div className={styles.titleContainer}>
              <h3 className={styles.itemTitle}>{data.title}</h3>
              <span className={styles.description}>{data.description}</span>
            </div>
          </div>
          <div className={styles.metrics}>
            {properties}
            {metrics}
            {spacers}
          </div>
          <div
            className={styles.metricToggle}
            data-test-id="vessel-trendButton"
          >
            {toggleTrend && (
              <Button type="content" clickAction={toggleTrendFunc}>
                {trendButtonIcon}
              </Button>
            )}
          </div>
        </div>
        {!isNoDetailsRow &&
          details.isExpanded &&
          ((details.isLoading && (
            <Loader
              className={styles.loader}
              text={"Loading"}
              error={details.error}
              expand={true}
            />
          )) ||
            (!details.isLoading && (
              <MetricDetailsContainer
                data={details.data}
                error={details.error}
                columnCount={columnCount}
                dateRange={dateRange}
                toggleTrendVesselItem={toggleTrend}
                timeOffset={timeOffset}
              />
            )))}
      </div>
    );
  }
}

export default MetricsListItem;
