import PropTypes from "prop-types";
import React from "react";
import classNames from "../../classNames";
import { formatNumber } from "../../numbers";
import Gauge from "../Gauge/Gauge";
import SparklineGraph from "../SparklineGraph/SparklineGraph";
import styles from "./PerformanceGauge.css";

export default class PerformanceGauge extends React.PureComponent {
  renderGauge() {
    const {
      value,
      minValue,
      maxValue,
      performanceValue,
      performanceMinValue,
      performanceMaxValue,
      formatValue,
      unit,
      icon,
    } = this.props;

    return (
      <div className={styles.gaugeContainer}>
        <div
          className={styles.gaugeInnerContainer}
          data-test-id="gauge-innerContainer"
        >
          <Gauge
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            performanceValue={performanceValue}
            performanceMinValue={performanceMinValue}
            performanceMaxValue={performanceMaxValue}
            formatValue={formatValue}
            unit={unit}
            icon={icon}
          />
        </div>
      </div>
    );
  }

  renderSecondaryValue() {
    const { secondaryValue, secondaryUnit, secondaryType } = this.props;
    if (!secondaryValue) {
      return <div />;
    }
    return (
      <div className={styles.secondaryValueContainer}>
        <span className={styles.secondaryValue}>
          {formatNumber(secondaryValue)}
        </span>
        <span className={styles.secondaryUnit}>
          {secondaryUnit}
          <span className={styles.secondaryType}>({secondaryType})</span>
        </span>
      </div>
    );
  }

  renderSparkline() {
    const {
      sparklineData,
      minValue,
      maxValue,
      performanceMinValue,
      performanceMaxValue,
    } = this.props;

    const metadata =
      "performanceValue" in this.props
        ? {
            minValue: performanceMinValue,
            maxValue: performanceMaxValue,
            trend: true,
          }
        : { minValue, maxValue };

    return (
      <div className={styles.sparklineContainer}>
        <div className={styles.sparkline}>
          {sparklineData && sparklineData.length > 0 && (
            <SparklineGraph data={sparklineData} {...metadata} />
          )}
        </div>
      </div>
    );
  }

  renderHeader() {
    const { header, headerSize } = this.props;
    return (
      Boolean(header) && (
        <div
          className={classNames(
            styles.header,
            headerSize === "small" && styles.small
          )}
        >
          {header}
        </div>
      )
    );
  }

  render() {
    return (
      <PerformanceGaugeContext.Consumer>
        {(context) => (
          <div
            className={styles.outerContainer}
            data-test-id="gauge-outerContainer"
          >
            {this.renderHeader()}
            {this.renderGauge()}
            {this.renderSecondaryValue()}
            {this.renderSparkline()}
            {context.renderCustomFooter &&
              context.renderCustomFooter(this.props)}
          </div>
        )}
      </PerformanceGaugeContext.Consumer>
    );
  }
}

PerformanceGauge.defaultValues = {
  formatValue: formatNumber,
  headerSize: "medium",
};

PerformanceGauge.propTypes = {
  performanceIndicatorId: PropTypes.string,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  performanceValue: PropTypes.number,
  performanceMinValue: PropTypes.number,
  performanceMaxValue: PropTypes.number,
  formatValue: PropTypes.func,
  unit: PropTypes.string,
  header: PropTypes.string,
  headerSize: PropTypes.oneOf(["small", "medium"]),
  icon: PropTypes.string,
};

export const PerformanceGaugeContext = React.createContext({});
