import { call, getContext, put } from "redux-saga/effects";

export function* httpGet({ action, url, actionData }) {
  const http = yield getContext("http");
  try {
    yield put({ ...actionData, type: action });
    const res = yield call(http.get, url);
    const actionRes = {
      ...actionData,
      type: `${action}_SUCCESS`,
      data: res.data,
    };
    yield put(actionRes);
    return actionRes;
  } catch (err) {
    const actionErr = { ...actionData, type: `${action}_ERROR`, error: err };
    yield put(actionErr);
    return actionErr;
  }
}

export function* httpPost({ action, url, data, actionData }) {
  const http = yield getContext("http");
  try {
    yield put({ type: action, ...actionData });
    const res = yield call(http.post, url, data);
    const actionRes = {
      type: `${action}_SUCCESS`,
      ...actionData,
      data: res.data,
    };
    yield put(actionRes);
    return actionRes;
  } catch (err) {
    const actionErr = { type: `${action}_ERROR`, ...actionData, error: err };
    yield put(actionErr);
    return actionErr;
  }
}
