import React, { Component } from "react";
import PerformanceGauge from "../../common/components/PerformanceGauge";
import { prepareGaugeData } from "../common/gauges";
import Loader from "../../common/components/Loader/Loader";
import { fetchFuelEfficiencyToday } from "../actions/action.fetchFuelEfficiencyToday";
import { connect } from "react-redux";
import { fetchFuelEfficiencyYesterday } from "../actions/action.fetchFuelEfficiencyYesterday";

class FuelEfficiencyContainer extends Component {
  componentDidMount() {
    if (this.props.config.params) {
      this.props.fetch(this.props.config.params);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.config !== prevProps.config && this.props.config.params) {
      this.props.fetch(this.props.config.params);
    }
  }

  render() {
    const { gauge: { data, error } = {} } = this.props;
    if (!data) {
      return (
        <Loader text="Loading fuel efficiency data" error={error} expand />
      );
    }
    return <PerformanceGauge {...prepareGaugeData(data)} icon={"fuel"} />;
  }
}

export const FuelEfficiencyTodayContainer = connect(
  (state) => ({ gauge: state.fuelEfficiencyToday }),
  { fetch: fetchFuelEfficiencyToday }
)(FuelEfficiencyContainer);

export const FuelEfficiencyYesterdayContainer = connect(
  (state) => ({ gauge: state.fuelEfficiencyYesterday }),
  { fetch: fetchFuelEfficiencyYesterday }
)(FuelEfficiencyContainer);
