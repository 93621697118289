const SECONDS_IN_DAY = 86400;
const SECONDCOUNT_BEST = SECONDS_IN_DAY - 60; //One minute missing
const SECONDCOUNT_GOOD = SECONDS_IN_DAY - 60 * 60; //One hour missing
const SECONDCOUNT_OKAY = 1; //We at least have some data

const percentageOfDay = (seconds) => {
  return (seconds / SECONDS_IN_DAY) * 100;
};

export const dataQuality = {
  percentage: {
    BEST: percentageOfDay(SECONDCOUNT_BEST),
    GOOD: percentageOfDay(SECONDCOUNT_GOOD),
    OKAY: percentageOfDay(SECONDCOUNT_OKAY),
  },
  secondCount: {
    BEST: SECONDCOUNT_BEST,
    GOOD: SECONDCOUNT_GOOD,
    OKAY: SECONDCOUNT_OKAY,
  },
};
