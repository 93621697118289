import IAuthService from "./IAuthService";
import IApiClient from "../services/IApiClient";
import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { User } from "oidc-client-ts";
import {
  FETCH_USER_DATA_ERROR,
  FETCH_USER_DATA_SUCCESS,
} from "../actions/action.types";
import { AxiosError } from "axios";
import { ILoginHandler } from "./ILoginHandler";
import { IApiAvailabilityHandler } from "./IApiAvailabilityHandler";
import { UserData } from "./UserData";

export class LoginHandler implements ILoginHandler {
  private readonly authenticationService: IAuthService;
  private readonly apiClient: IApiClient;
  private readonly dispatch: Dispatch;
  private readonly navigate: NavigateFunction;
  private readonly apiAvailabiltyHandler: IApiAvailabilityHandler;

  constructor(
    authenticationService: IAuthService,
    apiClient: IApiClient,
    dispatch: Dispatch,
    navigate: NavigateFunction,
    apiAvailabiltyHandler: IApiAvailabilityHandler
  ) {
    this.authenticationService = authenticationService;
    this.apiClient = apiClient;
    this.dispatch = dispatch;
    this.navigate = navigate;
    this.apiAvailabiltyHandler = apiAvailabiltyHandler;
  }

  private async getUser(): Promise<User | null> {
    let user: User | null = null;
    try {
      user = await this.authenticationService.getUser();

      if (user === null) {
        user = await this.authenticationService.silentLogin();
      }
    } catch (_) {
      // Do nothing, we want errors to lead to no user being returned, forcing the user to do an interactive login
    }
    return user;
  }

  private async proceedWithUserData(userData: UserData): Promise<void> {
    this.dispatch({ type: FETCH_USER_DATA_SUCCESS, data: userData });
    await this.apiClient.postPageLoad();
    this.apiAvailabiltyHandler.startApiAvailabilityCheck();
    if (window.location.pathname === "/auth") {
      // To separate between logins and refreshes
      this.navigate("/");
    }
  }

  private proceedAsUnauthorized(error: AxiosError): void {
    this.dispatch({ type: FETCH_USER_DATA_ERROR, error: error });
    this.apiAvailabiltyHandler.startApiAvailabilityCheck();
    this.navigate("/unauthorized");
  }

  private proceedInteractively(): void {
    this.navigate("/authPrelim");
  }

  public async login(): Promise<void> {
    const user = await this.getUser();
    if (user === null) {
      this.proceedInteractively();
    } else {
      await this.silentLoginToVesselPerformance(false);
    }
  }

  public async silentLoginToVesselPerformance(
    shouldGetUser = true
  ): Promise<void> {
    if (shouldGetUser) {
      // We need to run getUser first, because that will update the tokens in httpClient and stores
      await this.getUser();
    }

    let userData: UserData;
    try {
      userData = await this.apiClient.fetchUserData();
    } catch (e) {
      this.proceedAsUnauthorized(e as AxiosError);
    }
    await this.proceedWithUserData(userData);
  }
}
