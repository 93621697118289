import { OPERATIONAL_PROFILE_PLAN_ACTIONS } from "./action.types";
import { OPERATIONAL_PROFILE_PLAN_DATA } from "../api";
import fetch from "./action.fetch";

const createUrl = (itemTypeMetricId, context) => {
  if (context.groupId) {
    return OPERATIONAL_PROFILE_PLAN_DATA.getGroup(
      context.groupId,
      itemTypeMetricId,
      context.dateRange
    );
  } else if (context.vesselId) {
    return OPERATIONAL_PROFILE_PLAN_DATA.getVessel(
      context.vesselId,
      itemTypeMetricId,
      context.dateRange
    );
  }
};

const createPlannedUrl = (itemTypeMetricId, context) => {
  if (context.groupId) {
    return OPERATIONAL_PROFILE_PLAN_DATA.getPlannedGroup(
      context.groupId,
      itemTypeMetricId,
      context.dateRange
    );
  } else if (context.vesselId) {
    return OPERATIONAL_PROFILE_PLAN_DATA.getPlannedVessel(
      context.vesselId,
      itemTypeMetricId,
      context.dateRange
    );
  }
};

const actualOperationalData = (itemTypeMetricIds, context) => {
  return fetch({
    url: createUrl(itemTypeMetricIds, context),
    actionType: OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_DATA,
  });
};

const plannedOperationalData = (itemTypeMetricIds, context) => {
  return fetch({
    url: createPlannedUrl(itemTypeMetricIds, context),
    actionType:
      OPERATIONAL_PROFILE_PLAN_ACTIONS.FETCH_OPERATIONAL_PROFILE_PLAN_DATA,
  });
};

export const setOperationalProfilePlanPeriod = (period) => {
  return {
    type: OPERATIONAL_PROFILE_PLAN_ACTIONS.SET_WORKING_PERIOD,
    period,
  };
};

export const fetchOperationalProfilePlan =
  (itemTypeMetricId, context) => (dispatch) => {
    dispatch(actualOperationalData(itemTypeMetricId, context));
    dispatch(plannedOperationalData(itemTypeMetricId, context));
  };
