import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchPageConfigs,
  fetchPageConfig,
  fetchCompanies,
  createPageConfig,
  openDeletePageConfigPopup,
  closeDeletePageConfigPopup,
  deletePageConfig,
  setSelectedCompany,
  fetchVesselGroups,
} from "../../../../actions/admin";
import { Header } from "../../../../components/Header";
import Loader from "../../../../../common/components/Loader/Loader";
import Button from "../../../../../common/components/Button/Button";
import DataGrid from "../../../../components/DataGrid/DataGrid";
import SelectBox from "../../../../components/SelectBox/SelectBox";
import { isSystemAdmin } from "../../../../reducers/reducer.user";
import PageConfigsPopup from "./PageConfigsPopup";
import PageConfigsDeletePopup from "./PageConfigsDeletePopup";
import styles from "../../Admin.css";

class PageConfigs extends Component {
  constructor(props) {
    super(props);

    this.gridConfig = {
      columns: [
        {
          header: "Name",
          field: "name",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Page Name",
          field: "pageName",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Company",
          field: "companyName",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Vessel Group",
          field: "vesselGroupName",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Vessel",
          field: "vesselName",
          align: "left",
          flexGrow: 10,
        },
        {
          header: "Actions",
          align: "left",
          template: this.renderActions.bind(this),
        },
      ],
    };
  }

  onEditItemClick(id) {
    this.props.fetchPageConfig(id);
  }

  onDeleteItemClick(id) {
    const pageConfig = this.props.pageConfigs.data.find((p) => p.id === id);
    this.props.openDeletePageConfigPopup(pageConfig);
  }

  componentDidMount() {
    this.fetchPageConfigs();
    this.props.fetchCompanies();
    this.props.fetchVesselGroups();
  }

  componentDidUpdate(prevProps) {
    const { selectedCompanyId } = this.props;
    if (prevProps.selectedCompanyId !== selectedCompanyId) {
      this.fetchPageConfigs();
    }
  }

  fetchPageConfigs() {
    const { selectedCompanyId } = this.props;
    this.props.fetchPageConfigs(selectedCompanyId);
  }

  renderActions({ id }) {
    if (!this.props.canEdit) {
      return null;
    }
    return (
      <div>
        <Button
          value="Edit"
          clickAction={() => this.onEditItemClick(id)}
          type="link"
        />
        &nbsp;
        <Button
          value="Delete"
          clickAction={() => this.onDeleteItemClick(id)}
          type="link"
        />
      </div>
    );
  }

  renderDataGrid() {
    const { isLoading, data, error } = this.props.pageConfigs;
    if (isLoading) {
      return <Loader error={error} />;
    }
    return <DataGrid data={data} {...this.gridConfig} />;
  }

  render() {
    const { companies, selectedCompanyId, canEdit } = this.props;
    const selectOptions = [{ id: null, name: "All" }].concat(companies);

    return (
      <div>
        <Header
          title="Admin - Page Configuration"
          contentDistribution="space-between"
        >
          <div className={styles.headerContainer}>
            <SelectBox
              options={selectOptions}
              optionValKey="id"
              optionLabelKey="name"
              onSelect={this.props.setSelectedCompany}
              selected={selectOptions.find((c) => c.id === selectedCompanyId)}
            />
            {canEdit && (
              <Button
                value="Create Page Config"
                clickAction={this.props.createPageConfig}
              />
            )}
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        <PageConfigsPopup />
        <PageConfigsDeletePopup />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pageConfigs: state.admin.pageConfig,
  companies: state.admin.pageConfig.companies,
  selectedCompanyId: state.admin.selectedCompanyId,
  canEdit: isSystemAdmin(state.user),
});

const mapActionsToProps = {
  fetchPageConfigs,
  fetchPageConfig,
  fetchCompanies,
  createPageConfig,
  openDeletePageConfigPopup,
  closeDeletePageConfigPopup,
  deletePageConfig,
  setSelectedCompany,
  fetchVesselGroups,
};

export default connect(mapStateToProps, mapActionsToProps)(PageConfigs);
