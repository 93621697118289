import PropTypes from "prop-types";
import CanvasMapOverlay from "../CanvasMapOverlay/CanvasMapOverlay";
import { OPERATION_MODES } from "../../../../common/config";
import { distance } from "../CanvasMapOverlay/utils";
import { minBy } from "lodash";

const RENDER_TRAIL = 500;
const HIT_TILE_SIZE = 128;
const HOVER_DISTANCE = 20;
const HOVER_LINE_WIDTH = 10;
const POINT_ZOOM_LIMIT = 10;

class VesselTrackMapOverlay extends CanvasMapOverlay {
  constructor(props) {
    super(props);
  }

  onMouseMove(event) {
    let mouseX,
      mouseY,
      e = event.nativeEvent;

    if (e.offsetX) {
      mouseX = e.offsetX;
      mouseY = e.offsetY;
    } else if (e.layerX) {
      mouseX = e.layerX;
      mouseY = e.layerY;
    }
    const xContainer = Math.floor(mouseX / HIT_TILE_SIZE);
    const yContainer = Math.floor(mouseY / HIT_TILE_SIZE);
    const hitContainer = this.hitContainer;
    if (!hitContainer || hitContainer.length === 0) return;
    const container = hitContainer[xContainer][yContainer];
    const minPoint = minBy(container, (point) =>
      distance({ x: mouseX, y: mouseY }, { x: point.x, y: point.y })
    );
    if (!minPoint) {
      this.raiseHoverEvent(null);
      return;
    }
    let dist = distance(
      { x: mouseX, y: mouseY },
      { x: minPoint.x, y: minPoint.y }
    );
    if (dist > HOVER_DISTANCE) {
      this.raiseHoverEvent(null);
      return;
    }
    this.raiseHoverEvent(minPoint);
  }

  resetHitContainer() {
    this.hitContainer = [];
    const { width, height } = this.props;
    const xCount = Math.ceil(width / HIT_TILE_SIZE);
    const yCount = Math.ceil(height / HIT_TILE_SIZE);
    for (let xIndex = 0; xIndex <= xCount; xIndex++) {
      const xContainer = (this.hitContainer[xIndex] = []);
      for (let yIndex = 0; yIndex <= yCount; yIndex++) {
        xContainer[yIndex] = [];
      }
    }
  }

  addHitPoint(x, y, data) {
    const xIndex = Math.floor(x / HIT_TILE_SIZE);
    if (xIndex < 0 || xIndex >= this.hitContainer.length) return;
    const xContainer = this.hitContainer[xIndex];
    const yIndex = Math.floor(y / HIT_TILE_SIZE);
    if (yIndex < 0 || yIndex >= xContainer.length) return;
    const yContainer = xContainer[yIndex];
    yContainer.push({
      x,
      y,
      data,
    });
  }

  raiseHoverEvent(point) {
    if (this.lastHoverPoint === point) return;
    this.props.onTrackPointHover(point);
    this.lastHoverPoint = point;
  }

  renderCanvas({ ctx, latLng2Tile }) {
    this.resetHitContainer();

    const { track, startIndex, stopIndex, hoverGroupIndex } = this.props;
    const opModesDef = [].concat(
      ...OPERATION_MODES.map((group) => group.profiles)
    );
    const twoPi = Math.PI * 2;
    const cacheCanvas = document.createElement("canvas");
    const cacheCtx = cacheCanvas.getContext("2d");

    cacheCtx.width = 200;
    cacheCtx.height = 20;
    cacheCtx.lineWidth = 2;
    const cachePoint = {
      x: 0,
      y: 0,
    };
    const opModes = opModesDef.reduce((agr, opMode) => {
      const point = {
        x: cachePoint.x + 5,
        y: cachePoint.y + 5,
      };
      cacheCtx.strokeStyle = opMode.color;
      cacheCtx.beginPath();
      cacheCtx.arc(point.x, point.y, 4, 0, twoPi, true);
      cacheCtx.closePath();
      cacheCtx.stroke();

      agr[opMode.name] = {
        ...opMode,
        circleCachePoint: {
          x: cachePoint.x,
          y: cachePoint.y,
        },
      };
      cachePoint.x += 10;
      return agr;
    }, {});

    ctx.lineWidth = 2;
    const renderStartIndex = Math.max(startIndex - RENDER_TRAIL, 0);
    const renderStopIndex = Math.min(track.length, stopIndex + RENDER_TRAIL);

    ctx.strokeStyle = "rgba(43,49,63,0.80)";
    this.renderTrackGroups(
      ctx,
      track,
      latLng2Tile,
      opModes,
      cacheCanvas,
      renderStartIndex,
      startIndex
    );
    this.renderTrackGroups(
      ctx,
      track,
      latLng2Tile,
      opModes,
      cacheCanvas,
      stopIndex - 1,
      renderStopIndex
    );
    this.renderTrackGroups(
      ctx,
      track,
      latLng2Tile,
      opModes,
      cacheCanvas,
      startIndex,
      stopIndex + 1,
      true
    );

    if (hoverGroupIndex >= 0 && track[hoverGroupIndex]) {
      const groupTrack = track[hoverGroupIndex].track;
      const nextGroup =
        track.length > hoverGroupIndex ? track[hoverGroupIndex + 1] : null;
      const nextGroupTrack = nextGroup ? nextGroup.track : null;
      const opMode = opModes[groupTrack[0].operationLegend];
      VesselTrackMapOverlay.renderHoverTrack(
        ctx,
        latLng2Tile,
        groupTrack,
        nextGroupTrack,
        opMode
      );
    }
  }

  renderTrackGroups(
    ctx,
    track,
    latLng2Tile,
    opModes,
    cacheCanvas,
    startIndex,
    stopIndex,
    useColor = false
  ) {
    for (let groupIndex = startIndex; groupIndex < stopIndex; groupIndex++) {
      const group = track[groupIndex];
      if (!group) continue;
      const groupTrack = group.track;
      let opMode, imagePoint;
      if (useColor) {
        opMode = opModes[groupTrack[0].operationLegend];
        imagePoint = opMode.circleCachePoint;
        ctx.strokeStyle = opMode.color;
      }
      const lastGroupPoint = this.renderTrackElement(
        ctx,
        groupTrack,
        latLng2Tile,
        imagePoint,
        cacheCanvas
      );
      const nextGroup = track[groupIndex + 1];
      if (nextGroup && lastGroupPoint) {
        const trackPoint = nextGroup.track[0];
        if (trackPoint) {
          const point = latLng2Tile(trackPoint);
          ctx.beginPath();
          ctx.moveTo(
            Math.floor(lastGroupPoint.x),
            Math.floor(lastGroupPoint.y)
          );
          ctx.lineTo(Math.floor(point.x), Math.floor(point.y));
          ctx.stroke();
        }
      }
    }
  }

  renderTrackElement(ctx, groupTrack, latLng2Tile, imagePoint, cacheCanvas) {
    const { zoom } = this.props;
    let lastGroupPoint;
    for (let trackIndex = 0; trackIndex < groupTrack.length; trackIndex++) {
      const trackPoint = groupTrack[trackIndex];
      const nextTrackPoint = groupTrack[trackIndex + 1];
      const point = latLng2Tile(trackPoint);
      if (imagePoint && zoom > POINT_ZOOM_LIMIT) {
        ctx.drawImage(
          cacheCanvas,
          imagePoint.x,
          imagePoint.y,
          10,
          10,
          point.x - 5,
          point.y - 5,
          10,
          10
        );
      }
      if (nextTrackPoint) {
        const nextPoint = latLng2Tile(nextTrackPoint);
        ctx.beginPath();
        ctx.moveTo(Math.floor(point.x), Math.floor(point.y));
        ctx.lineTo(Math.floor(nextPoint.x), Math.floor(nextPoint.y));
        ctx.stroke();
      } else {
        lastGroupPoint = point;
      }
      this.addHitPoint(point.x, point.y, trackPoint);
    }
    return lastGroupPoint;
  }

  static renderHoverTrack(ctx, latLng2Tile, track, nextGroupTrack, opMode) {
    ctx.lineWidth = HOVER_LINE_WIDTH;
    const rgb = VesselTrackMapOverlay.hexToRgb(opMode.color);
    ctx.strokeStyle = `rgba(${rgb.r},${rgb.g},${rgb.b}, 0.65)`;
    ctx.lineCap = "round";
    let lastPoint;
    ctx.beginPath();
    for (let trackIndex = 0; trackIndex < track.length; trackIndex++) {
      const trackPoint = track[trackIndex];
      const nextTrackPoint = track[trackIndex + 1];
      const point = latLng2Tile(trackPoint);
      lastPoint = point;

      if (nextTrackPoint) {
        const nextPoint = latLng2Tile(nextTrackPoint);
        ctx.moveTo(Math.floor(point.x), Math.floor(point.y));
        ctx.lineTo(Math.floor(nextPoint.x), Math.floor(nextPoint.y));
      }
    }
    if (lastPoint && nextGroupTrack && nextGroupTrack.length > 0) {
      const nextPoint = latLng2Tile(nextGroupTrack[0]);
      ctx.moveTo(Math.floor(lastPoint.x), Math.floor(lastPoint.y));
      ctx.lineTo(Math.floor(nextPoint.x), Math.floor(nextPoint.y));
    }

    ctx.stroke();
  }

  static hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
}

VesselTrackMapOverlay.propTypes = {
  track: PropTypes.array.isRequired,
  startIndex: PropTypes.number,
  stopIndex: PropTypes.number,
  hoverGroupIndex: PropTypes.number,
  onTrackPointHover: PropTypes.func,
  ...CanvasMapOverlay.propTypes,
};

VesselTrackMapOverlay.defaultProps = {
  startIndex: 0,
  stopIndex: 0,
};

export default VesselTrackMapOverlay;
