import { isFinite, round, curry, isNumber, toNumber, inRange } from "lodash";

const decimalMap = {
  trend: 2,
  latitude: 3,
  longitude: 3,
  percent: 1,
};

const suffixMap = {
  percent: "%",
};

export const isFiniteNumber = (value) => {
  return value && isFinite(toNumber(value));
};
export const isInRange = (value, minValue, maxValue) => {
  return (
    isFiniteNumber(value) &&
    isFiniteNumber(minValue) &&
    isFiniteNumber(maxValue) &&
    inRange(toNumber(value), toNumber(minValue), toNumber(maxValue) + 1)
  );
};

/**
 * Produces a function for rounding numbers, that takes a value and rounds it.
 * @param precision number of desired decimals on the result
 * @param n value to be rounded
 * @returns function(*=): *}
 */
export const roundNumberAt = curry((precision, n) => {
  return !isFinite(n) ? n : round(n, precision);
});

/**
 * Rounds a number to an integer
 * @param {Number} precision
 * @returns {Number} value
 */
export const roundNumberInt = roundNumberAt(0);

/**
 * Produces a function that formats the number to the given set of decimals.
 * @see roundNumberAt
 * @returns {function(*=): string}
 * @param precision
 */
export const formatNumberAt = curry((precision, n) => {
  return isFinite(n) ? roundNumberAt(precision, n).toFixed(precision) : "-";
});

export const formatNumber = (n, type) => {
  if (!isFinite(n)) {
    return n || "-";
  }
  const typeLc = String(type).toLocaleLowerCase();
  const decimals = decimalMap[typeLc] || 1;
  const suffix = suffixMap[typeLc] || "";
  return String(roundNumberAt(decimals, n)) + suffix;
};

export const formatPercent = (n) => formatNumber(n * 100, "percent");

export const roundNumber = (number, precision = 1) => {
  return roundNumberAt(precision, number);
};

//Can be called without a precision specified
export const roundConvert = (number, unit, precision) => {
  if (!isNumber(number)) {
    return { number: number || "-", unit: unit };
  }

  let convertSpec = getConvertSpec(number, unit);
  let newNumber = number / (convertSpec.conversion || 1);
  let rounded = roundNumberAt(
    isNumber(precision) ? precision : convertSpec.precision || 1,
    newNumber
  ); //Use specified precision if supplied

  return { number: rounded, unit: convertSpec.unit || unit, hasValue: true };
};

export const getConvertSpec = (number, unit) => {
  const currentSpec = {
    unit: unit,
    precision: 1,
    conversion: 1,
  };

  const convertedUnit =
    convertDistanceTime(number, unit) ||
    convertVolumeAndMass(number, unit) ||
    convertDebit(number, unit) ||
    convertPowerAndConsumption(number, unit);

  return (
    convertedUnit || {
      ...currentSpec,
      precision: number < 100 ? 1 : 0,
    }
  );
};

function convertVolumeAndMass(number, unit) {
  if (unit === "kg") {
    if (number > 100000) {
      return { unit: "t", precision: 1, conversion: 1000 };
    }
  }

  if (unit === "l" || unit === "L") {
    if (number > 100000) {
      return { unit: "m3", precision: 1, conversion: 1000 };
    }
  }

  if (unit === "m3" && number < 100) {
    return { unit: "L", precision: 1, conversion: 1 / 1000 };
  }
}

function convertDebit(number, unit) {
  if (unit === "l/h" || unit === "L/h") {
    if (number > 10000) {
      return { unit: "m3/hour", precision: 1, conversion: 1000 };
    }
  }
  if (unit === "l/nm" || unit === "L/nm") {
    if (number > 10000) {
      return { unit: "m3/nm", precision: 1, conversion: 1000 };
    }
  }
}

function convertDistanceTime(number, unit) {
  if (unit === "m") {
    if (number > 10000) {
      return { unit: "km", precision: 1, conversion: 1000 };
    }
  }

  if (unit === "s") {
    if (number > 60 * 120) {
      return { unit: "h", precision: 1, conversion: 60 * 60 };
    }
    if (number > 60) {
      return { unit: "min", precision: 1, conversion: 60 };
    }
  }
}

function convertPowerAndConsumption(number, unit) {
  if (unit === "kW") {
    if (number > 10000) {
      return { unit: "MW", precision: 1, conversion: 1000 };
    }
  }

  if (unit === "kWh") {
    if (number > 10000000) {
      return { unit: "GWh", precision: 1, conversion: 1000000 };
    }
    if (number > 10000) {
      return { unit: "MWh", precision: 1, conversion: 1000 };
    }
  }
}

export const extractTypeFromUnitValueString = (valueType) => {
  return /UnitValue<(?:Nullable<)?(.*?)(>)?>/g.exec(valueType)[1];
};
