import React, { FC } from "react";
import styles from "./ManualFuelDataFormDialog.css";
import trashIcon from "../../../style/img/trash.svg";

interface DeletePButtonProps {
  rowNumber: number;
  removeRow: (index: number) => void;
}

const DeleteButton: FC<DeletePButtonProps> = ({ rowNumber, removeRow }) => {
  return (
    <div
      className={styles.trashIconContainer}
      onClick={() => removeRow(rowNumber)}
      data-testid={`RemoveButton-${rowNumber}`}
    >
      <img
        className={styles.trashIcon}
        style={{ transform: rowNumber === 0 ? "" : "translatey(-50%)" }}
        src={trashIcon}
        alt={"Delete row"}
      />
    </div>
  );
};

export default DeleteButton;
