import {
  all,
  takeEvery,
  put,
  fork,
  select,
  getContext,
  call,
  takeLeading,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { isNil, get } from "lodash";
import { ADMIN_OPERATIONS, vesselConfigApi } from "../../api";

export function* initVesselOperations({ vesselId }) {
  yield call(fetchData, vesselId);
  yield fork(saveVesselOperationsWatcher);
  yield fork(deleteWatcher);
}

function* fetchData(vesselId) {
  const http = yield getContext("http");
  try {
    yield put({ type: vesselConfig.vesselOperations.FETCH, vesselId });
    const [operationsRes, vesselOperationsRes] = yield all([
      call(http.get, ADMIN_OPERATIONS.get()),
      call(http.get, vesselConfigApi.vesselOperations.getForVessel(vesselId)),
    ]);
    yield put({
      type: vesselConfig.vesselOperations.FETCH_SUCCESS,
      vesselId,
      operations: operationsRes.data,
      vesselOperations: vesselOperationsRes.data,
    });
  } catch (err) {
    yield put({
      type: vesselConfig.vesselOperations.FETCH_ERROR,
      vesselId,
      error: get(err, ["response", "data", "message"], "Failed to fetch data"),
    });
  }
}

function* saveVesselOperationsWatcher() {
  yield takeEvery(vesselConfig.vesselOperations.SAVE, saveVesselOperationSaga);
}

function* saveVesselOperationSaga({ vesselId, id, form }) {
  const { fields, showSpeed, showEngines, showTension } = form;

  const data = [...get(fields, "operations.value").map((x) => ({ ...x }))];
  const firstOperation = data[0];

  if (showSpeed) {
    firstOperation.minSpeed = get(fields, "minSpeed.value");
    firstOperation.maxSpeed = get(fields, "maxSpeed.value");
  }
  if (showEngines) {
    firstOperation.minEngines = get(fields, "minEngines.value");
    firstOperation.maxEngines = get(fields, "maxEngines.value");
  }
  if (showTension) {
    firstOperation.maxTension = get(fields, "maxTension.value");
  }

  if (isNil(id)) {
    yield call(saveNewVesselOperation, { vesselId, data });
  } else {
    yield call(updateExistingVesselOperation, {
      id,
      vesselId,
      data: firstOperation,
    });
  }
}

function* saveNewVesselOperation({ vesselId, data }) {
  const http = yield getContext("http");
  try {
    const res = yield call(
      http.post,
      vesselConfigApi.vesselOperations.post(vesselId),
      data
    );
    yield put({
      type: vesselConfig.vesselOperations.SAVE_NEW_SUCCESS,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: vesselConfig.vesselOperations.SAVE_NEW_ERROR,
      error: get(
        err,
        ["response", "data", "message"],
        "Error creating new vessel operation"
      ),
    });
  }
}

function* updateExistingVesselOperation({ id, vesselId, data }) {
  const http = yield getContext("http");
  try {
    const res = yield call(
      http.put,
      vesselConfigApi.vesselOperations.put(vesselId, id),
      data
    );
    yield put({
      type: vesselConfig.vesselOperations.UPDATE_EXISTING_SUCCESS,
      id,
      data: res.data,
    });
  } catch (err) {
    yield put({
      type: vesselConfig.vesselOperations.UPDATE_EXISTING_ERROR,
      id,
      error: get(
        err,
        ["response", "data", "message"],
        "Error updating vessel operation"
      ),
    });
  }
}

function* deleteWatcher() {
  yield takeLeading(
    vesselConfig.vesselOperations.CONFIRM_DELETE,
    deleteVesselOperation
  );
}

function* deleteVesselOperation({ id }) {
  const http = yield getContext("http");
  const vesselId = yield select((s) => s.admin.vesselConfigOperations.vesselId);

  try {
    yield call(
      http.delete,
      vesselConfigApi.vesselOperations.delete(vesselId, id)
    );
    yield put({
      type: vesselConfig.vesselOperations.CONFIRM_DELETE_SUCCESS,
      id,
    });
  } catch (error) {
    yield put({
      type: vesselConfig.vesselOperations.CONFIRM_DELETE_ERROR,
      error,
    });
  }
}
