import React from "react";
import Sparkline from "../../../../common/components/Sparkline/Sparkline";
import SparklineLine from "../../../../common/components/Sparkline/SparklineLine";
import SparklineTooltip from "../../../../common/components/Sparkline/SparklineTooltip";
import SparklineHover from "../../../../common/components/Sparkline/SparklineHover";
import { ITrendData } from "../commonTypes";
import { color } from "../../../../common/colors";

const stroke = {
  strokeWidth: 1.2,
  strokeOpacity: 1,
};

interface Props {
  data: ITrendData[];
}
export function FuelConsumptionTrend({ data }: Props): JSX.Element {
  return (
    <Sparkline width={160} height={35} data={data}>
      <SparklineLine
        color={color("--blue-base")}
        strokeWidth={stroke.strokeWidth}
        strokeOpacity={stroke.strokeOpacity}
      />
      <SparklineHover>
        <SparklineTooltip
          tooltips={[
            {
              color: color("--blue-base"),
            },
          ]}
        />
      </SparklineHover>
    </Sparkline>
  );
}
