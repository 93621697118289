import {
  put,
  all,
  fork,
  takeLatest,
  takeLeading,
  call,
  getContext,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { get } from "lodash";
import {
  vesselConfigApi,
  ADMIN_VESSEL_GROUPS,
  ADMIN_COMPANIES,
  ADMIN_VESSELS,
} from "../../api";
import download from "downloadjs";

const actions = vesselConfig.vesselDetails;
const api = vesselConfigApi.vesselDetails;

export function* initVesselConfigDetails({ isNew, vesselId }) {
  yield fork(initWatcher);
  yield put({ type: actions.INITIALIZE, isNew, vesselId });
}

function* initWatcher() {
  yield takeLatest(actions.INITIALIZE, init);
}

function* init({ isNew, vesselId }) {
  yield isNew ? call(newVessel) : call(editVessel, vesselId);
}

function* newVessel() {
  yield call(fetchNewVesselData);
  yield fork(saveVesselWatcher, null);
}

function* fetchNewVesselData() {
  const http = yield getContext("http");
  try {
    yield put({ type: actions.FETCH_NEW_VESSEL_DATA });
    const [companiesRes, vesselGroupsRes] = yield all([
      call(http.get, ADMIN_COMPANIES.get()),
      call(http.get, ADMIN_VESSEL_GROUPS.get()),
    ]);

    yield put({
      type: actions.FETCH_NEW_VESSEL_DATA_SUCCESS,
      vesselGroups: vesselGroupsRes.data,
      companies: companiesRes.data,
    });
  } catch (err) {
    yield put({
      type: actions.FETCH_NEW_VESSEL_DATA_ERROR,
      error: getErrorMessage(err) || "Failed to fetch data",
    });
  }
}

function* editVessel(vesselId) {
  yield call(fetchEditVesselData, vesselId);
  yield fork(saveVesselWatcher, vesselId);
  yield fork(generateApiTokenWatcher, vesselId);
  yield fork(downloadWindConfigWatcher);
  yield fork(saveWindConfigWatcher, vesselId);
}

function* fetchEditVesselData(vesselId) {
  const http = yield getContext("http");

  try {
    yield put({ type: actions.FETCH_DETAILS });

    const [vesselDetailsRes, companiesRes, vesselGroupsRes] = yield all([
      call(http.get, api.get(vesselId)),
      call(http.get, ADMIN_COMPANIES.get()),
      call(http.get, ADMIN_VESSEL_GROUPS.get()),
    ]);
    yield put({
      type: actions.FETCH_DETAILS_SUCCESS,
      vesselId,
      vesselDetails: vesselDetailsRes.data,
      vesselGroups: vesselGroupsRes.data,
      companies: companiesRes.data,
    });
  } catch (err) {
    yield put({
      type: actions.FETCH_DETAILS_ERROR,
      error: getErrorMessage(err) || "Failed to fetch data",
    });
  }
}

function* saveVesselWatcher(vesselId) {
  yield takeLeading(actions.SAVE_VESSEL, saveVessel, vesselId);
}

function* saveVessel(id, { fields, copyFromVesselId }) {
  const companyId = get(fields, "company.value");

  const formData = new FormData();
  formData.set("name", get(fields, "name.value"));
  formData.set("imo", get(fields, "imo.value"));
  formData.set("companyId", companyId);

  formData.set("startTime", fields.startTime.value.toISOString());
  formData.set("remoteAccessPort", get(fields, "remoteAccessPort.value", ""));
  formData.set("edgeGatewayId", get(fields, "edgeGatewayId.value", ""));
  formData.set("mdoUnitKgPerHour", get(fields, "mdoUnitKgPerHour.value", ""));
  formData.set("hfoUnitKgPerHour", get(fields, "hfoUnitKgPerHour.value", ""));
  formData.set("mgoUnitKgPerHour", get(fields, "mgoUnitKgPerHour.value", ""));
  formData.set(
    "methanolUnitKgPerHour",
    get(fields, "methanolUnitKgPerHour.value", "")
  );
  formData.set(
    "ulsfoUnitKgPerHour",
    get(fields, "ulsfoUnitKgPerHour.value", "")
  );
  formData.set(
    "vlsfoUnitKgPerHour",
    get(fields, "vlsfoUnitKgPerHour.value", "")
  );
  formData.set("cargoType", get(fields, "cargoType.value", ""));
  formData.set("subscriptionLevel", get(fields, "subscriptionLevel.value", ""));
  formData.set(
    "useManualFuelDataOnly",
    get(fields, "useManualFuelDataOnly.value", "")
  );

  if (copyFromVesselId) {
    formData.set("copyFromVesselId", copyFromVesselId);
  }

  const { vesselImage, vesselGroups, vesselGroupIds } = fields;

  if (vesselImage.hasChanges) {
    formData.append("file", vesselImage.file);
  }
  if (vesselGroups.hasChanges) {
    const prevValue = get(vesselGroupIds, "value", []) || [];
    const currentValue = get(vesselGroups, "value", []) || [];

    const added = currentValue.filter((x) => !prevValue.includes(x));
    if (added.length) {
      for (let i = 0; i < added.length; i++) {
        formData.set(`vesselGroupIds.added[${i}]`, added[i]);
      }
    }

    const removed = prevValue.filter((prev) => !currentValue.includes(prev));
    if (removed.length) {
      for (let i = 0; i < removed.length; i++) {
        formData.set(`vesselGroupIds.removed[${i}]`, removed[i]);
      }
    }
  }
  if (id) {
    formData.set("id", id);
    yield put({
      type: vesselConfig.vesselDetails.SET_PROGRESS_MESSAGE,
      message: "Saving vessel details",
    });
  } else {
    yield put({
      type: vesselConfig.vesselDetails.SET_PROGRESS_MESSAGE,
      message: "Creating new vessel with partitions. This will take some time.",
    });
  }

  try {
    const http = yield getContext("http");
    if (id) {
      yield call(http.put, api.put(id), formData);
      if (copyFromVesselId) {
        yield call(navigateToEdit, id);
        yield put({ type: vesselConfig.vesselDetails.SAVE_VESSEL_SUCCESS });
      } else {
        // Refetch vesselItem data since this can now be outdated
        yield put({ type: vesselConfig.vesselItems.FETCH, vesselId: id });
        yield put({ type: actions.INITIALIZE, vesselId: id });
      }
    } else {
      const res = yield call(http.post, api.post(), formData);
      yield call(navigateToEdit, res.data.id);
      yield put({ type: vesselConfig.vesselDetails.SAVE_VESSEL_SUCCESS });
    }
  } catch (err) {
    yield call(navigateToEdit, "new");
    yield put({
      type: vesselConfig.vesselDetails.SAVE_VESSEL_ERROR,
      error: getErrorMessage(err) || "Error saving vessel",
    });
  }
}

function* navigateToEdit(vesselId) {
  const history = yield getContext("history");
  yield call(history.replace, `/admin/vessels/${vesselId}`);
}

function* generateApiTokenWatcher(vesselId) {
  yield takeLeading(actions.GENERATE_API_TOKEN, generateApiToken, vesselId);
}

function* downloadWindConfigWatcher() {
  yield takeLeading(actions.DOWNLOAD_WINDCONFIG, downloadConfigSaga);
}
function* saveWindConfigWatcher(vesselId) {
  yield takeLeading(actions.SAVE_WINDCONFIG, saveWindConfigSaga, vesselId);
}

function* saveWindConfigSaga(id, { windCoefficients }) {
  const formData = new FormData();
  formData.append("windCoefficients", windCoefficients);

  try {
    const http = yield getContext("http");
    yield call(http.post, api.windConfig(id), formData);
    yield put({ type: actions.SAVE_WINDCONFIG_SUCCESS });
  } catch (err) {
    yield put({
      type: vesselConfig.vesselDetails.SAVE_WINDCONFIG_ERROR,
      error: getErrorMessage(err) || "Error saving wind coefficients",
    });
  }
}

function* generateApiToken(vesselId) {
  const http = yield getContext("http");
  try {
    const res = yield call(http.post, ADMIN_VESSELS.generateApiToken(vesselId));
    yield put({
      type: actions.GENERATE_API_TOKEN_SUCCESS,
      data: res.data.secret,
    });
  } catch (err) {
    yield put({
      type: actions.GENERATE_API_TOKEN_ERROR,
      error: getErrorMessage(err) || "Error generating api access token",
    });
  }
}

function* downloadConfigSaga({ data, filename }) {
  try {
    yield call(download, data, filename, "text/csv");
  } catch (err) {
    yield put({ type: actions.DOWNLOAD_WINDCONFIG_ERROR, error: err });
  }
}

function getErrorMessage(errorResponse) {
  return get(errorResponse, ["response", "data", "message"]);
}
