import { DateRange } from "../components/DatePicker/Types";
import fetch from "./action.fetch";
import { EMISSIONS_LIST } from "../api_ts";
import { AnyAction } from "redux";
import { FETCH_EMISSIONS_LIST } from "./action.types";
import { Direction } from "../common/direction";

export function fetchEmissionsList(
  interval: DateRange,
  group: string,
  operationClass: string,
  operationModes: string[],
  numberOfItemsToGet: number,
  sortingOrder: Direction
): AnyAction {
  return fetch({
    url: EMISSIONS_LIST.get(
      interval.from,
      interval.to,
      group,
      operationClass,
      operationModes,
      numberOfItemsToGet,
      sortingOrder
    ),
    actionType: FETCH_EMISSIONS_LIST,
    takeAll: true,
    actionData: { operationClass },
  }) as AnyAction;
}
