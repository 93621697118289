import download from "downloadjs";

function getDownloadData(res) {
  const contentType = res.headers["content-type"];
  return { data: new Blob([res.data], { type: contentType }), contentType };
}

function extractFileName(res) {
  const match = /filename=([^;]+);/.exec(
    res.headers["content-disposition"].replaceAll('"', "")
  );
  return match && match[1];
}

export function downloadFile(httpResponse) {
  const { data, contentType } = getDownloadData(httpResponse);
  const filename = extractFileName(httpResponse);
  download(data, filename, contentType);
}
