import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "../../../../common/classNames";
import Button from "../../../../common/components/Button/Button";
import Loader from "../../../../common/components/Loader/Loader";
import {
  createUser,
  editUserAccess,
  fetchCompanies,
  fetchUser,
  fetchUsers,
  importUsers,
  setSelectedCompany,
  sortUsers,
  clearErrors,
} from "../../../actions/admin";
import {
  requestUserPasswordReset,
  deletePopupClose,
  deleteUser,
} from "../../../actions/admin/action.users";
import { isoStringToFormattedDateTime } from "../../../common/dates";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { Header } from "../../../components/Header";
import SelectBox from "../../../components/SelectBox/SelectBox";
import { selectAllCompanies } from "../../../reducers/admin/reducer.companies";
import { isSystemAdmin, isCompanyAdmin } from "../../../reducers/reducer.user";
import styles from "../Admin.css";
import UserAccessPopup from "./UserAccessPopup";
import UserImportPopup from "./UserImportPopup";
import UserPasswordResetPopup from "./UserPasswordResetPopup";
import UserPopup from "./UserPopup";
import UserApiClientSecretPopup from "./UserApiClientSecretPopup";
import ErrorDialog from "../../../components/ErrorDialog/ErrorDialog";
import ConfirmDeleteDialog from "../VesselConfig/ConfirmDeleteDialog";

function rowClassNames(rowData) {
  return classNames(
    (rowData.deactivated || rowData.isApiClientUserOnly) && styles.muted
  );
}
const deleteUserPopupTitle = "Delete user";
const deleteUserPopupMessage =
  "Are you sure you want to delete this user? This action cannot be undone.";

const NameColumnContent = ({ rowData }) => (
  <span className={rowClassNames(rowData)}>
    {`${rowData.firstName ? rowData.firstName : ""}
      ${rowData.lastName ? rowData.lastName : ""}
      ${rowData.deactivated ? " (Deactivated)" : ""}
      ${rowData.isApiClientUserOnly ? " (API User)" : ""}
      ${rowData.clientId ? " (Client)" : ""}`}
  </span>
);

const EmailColumnContent = ({ rowData }) => (
  <span className={rowClassNames(rowData)}>{rowData.email}</span>
);

const LastLoginColumnContent = ({ rowData }) => (
  <span className={rowClassNames(rowData)}>
    {isoStringToFormattedDateTime(rowData.lastLogin)}
  </span>
);

const LastCountColumnContent = ({ rowData }) => (
  <span className={rowClassNames(rowData)}>{rowData.loginCount}</span>
);

class Users extends Component {
  constructor(props) {
    super(props);

    this.gridConfig = {
      columns: [
        {
          header: "Name",
          align: "left",
          field: "firstName",
          template: (rowData) => <NameColumnContent rowData={rowData} />,
        },
        {
          header: "Email",
          align: "left",
          field: "email",
          flexGrow: 2,
          template: (rowData) => <EmailColumnContent rowData={rowData} />,
        },
        {
          header: "Last Login",
          align: "left",
          field: "lastLogin",
          template: (rowData) => <LastLoginColumnContent rowData={rowData} />,
        },
        {
          header: "Login Count",
          align: "left",
          field: "loginCount",
          template: (rowData) => <LastCountColumnContent rowData={rowData} />,
        },
        {
          header: "Actions",
          align: "left",
          template: this.renderActions.bind(this),
        },
      ],
    };
  }

  onEditItemClick({ id }) {
    this.props.fetchUser(id);
  }

  onEditAccessClick(userData) {
    this.props.editUserAccess(userData);
  }

  onImportUsersClick(selectedCompanyId) {
    this.props.importUsers(selectedCompanyId);
  }

  onRequestUserPasswordReset(userdata) {
    this.props.requestUserPasswordReset(userdata);
  }

  componentDidMount() {
    this.props.fetchCompanies();
    this.fetchUsers();
  }

  componentDidUpdate(prevProps) {
    const { selectedCompanyId, companies } = this.props;

    if (!selectedCompanyId && companies.length === 1) {
      this.props.setSelectedCompany(companies[0]);
    }

    if (
      prevProps.selectedCompanyId !== selectedCompanyId &&
      companies.length > 0
    ) {
      this.fetchUsers();
    }
  }

  fetchUsers() {
    const { selectedCompanyId } = this.props;
    this.props.fetchUsers(selectedCompanyId);
  }

  renderActions(userData) {
    if (!this.props.canEdit) {
      return null;
    }
    return (
      <div className={styles.actions}>
        <Button
          value={"Edit details"}
          clickAction={() => this.onEditItemClick(userData)}
          type="link"
        />{" "}
        <Button
          value={"Edit access"}
          clickAction={() => this.onEditAccessClick(userData)}
          type="link"
        />{" "}
      </div>
    );
  }

  renderDataGrid() {
    const { isLoading, data, error, sortBy, sortDirection } = this.props.users;
    if (isLoading) {
      return <Loader error={error} />;
    }
    return (
      <DataGrid
        data={data}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={this.props.sortUsers}
        {...this.gridConfig}
      />
    );
  }

  render() {
    const {
      companies,
      selectedCompanyId,
      canCreate,
      users,
      clearErrors: onOkClick,
    } = this.props;
    const { error } = this.props.users;
    const selectOptions = [{ id: null, name: "All" }].concat(companies);
    return (
      <div>
        <Header title="Admin - Users" contentDistribution="space-between">
          <div className={styles.headerContainer}>
            <SelectBox
              options={selectOptions}
              optionValKey="id"
              optionLabelKey="name"
              onSelect={this.props.setSelectedCompany}
              selected={selectOptions.find((c) => c.id === selectedCompanyId)}
            />
            {canCreate && (
              <Button value="Create User" clickAction={this.props.createUser} />
            )}
            {canCreate && (
              <Button
                value="Import users"
                clickAction={() =>
                  this.onImportUsersClick(this.props.selectedCompanyId)
                }
              />
            )}
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        {users.deleteConfirmPopup.isVisible && (
          <ConfirmDeleteDialog
            onCancelClick={this.props.deletePopupClose}
            onConfirmClick={this.props.deleteUser}
            title={deleteUserPopupTitle}
            message={deleteUserPopupMessage}
          />
        )}
        {!error && <UserPopup />}
        <UserPasswordResetPopup />
        {users.userImport.showDialog && <UserImportPopup />}
        {users.accessEditor.isVisible && <UserAccessPopup />}
        <UserApiClientSecretPopup />
        {!!error && (
          <ErrorDialog
            title={"An error occurred"}
            message={error.response.data.message}
            onOkClick={onOkClick}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.users,
  companies: selectAllCompanies(state.admin.companies),
  selectedCompanyId: state.admin.selectedCompanyId,
  canEdit: isSystemAdmin(state.user) || isCompanyAdmin(state.user),
  canCreate: isSystemAdmin(state.user),
});

const mapActionsToProps = {
  fetchUsers,
  fetchUser,
  fetchCompanies,
  editUserAccess,
  createUser,
  setSelectedCompany,
  requestUserPasswordReset,
  sortUsers,
  importUsers,
  clearErrors,
  deletePopupClose,
  deleteUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Users);
