import reducer from "../reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

export type RootState = ReturnType<typeof reducer>;

// Typed selector hook, that makes the state parameter in the selector to have the type RootState
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Typed dispatch to use for thunks
export const useThunkDispatch = (): ThunkDispatch<RootState, never, never> =>
  useDispatch();
