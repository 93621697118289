import React from "react";
import PropTypes from "prop-types";
import styles from "./FormFileUpload.css";
import formStyles from "../Form.css";
import { get } from "lodash";
import classNames from "../../../../common/classNames";
import { formatNumber } from "../../../../common/numbers";

class FormFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      fileSize: 0,
      isChanged: false,
    };

    this.onInputFileTriggerClick = this.onInputFileTriggerClick.bind(this);
    this.onInputFileTriggerKeyDown = this.onInputFileTriggerKeyDown.bind(this);
    this.onInputFileChange = this.onInputFileChange.bind(this);
  }

  onInputFileTriggerClick() {
    this.fileInput.focus();
  }

  onInputFileTriggerKeyDown(event) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.fileInput.focus();
    }
  }

  onInputFileChange() {
    const { name, onChange } = this.props;
    const file = get(this, "fileInput.files[0]");
    this.setState({
      fileName: get(file, "name"),
      fileSize: get(file, "size", 0),
      isChanged: true,
    });
    if (onChange) {
      onChange(name, file, this.fileInput);
    }
  }

  render() {
    const { name, label, labelPosition, required, margin, width } = this.props;
    const { isChanged } = this.state;
    const formElementContainerStyle = {
      margin,
      width,
    };
    let showValidationError = false;
    let message = "";

    const fileSize =
      (this.state.fileSize === 0
        ? get(this.props, "existingFile.fileContent", "").length
        : this.state.fileSize) / 1000;
    const currentFileName =
      this.state.fileName === ""
        ? get(this.props, "existingFile.fileName")
        : this.state.fileName;

    return (
      <div
        className={classNames(
          formStyles.formElementContainer,
          labelPosition === "left" ? formStyles.leftAlignedContainer : "",
          isChanged && styles.isChanged
        )}
        style={formElementContainerStyle}
      >
        {!!label && (
          <div className={formStyles.labelContainer}>
            <label
              htmlFor={name}
              style={{ marginLeft: "10px" }}
              className={formStyles.label}
            >
              {label}
            </label>
            {!!required && (
              <span className={formStyles.requiredMessage}>required</span>
            )}
            {showValidationError && (
              <span className={formStyles.validationMessage}>{message}</span>
            )}
          </div>
        )}
        <div className={styles.fileUploadContainer}>
          <div className={styles.inputContainer}>
            <input
              ref={(el) => (this.fileInput = el)}
              onChange={this.onInputFileChange}
              className={styles.inputFile}
              name={name}
              type="file"
            />
            <label
              onClick={this.onInputFileTriggerClick}
              onKeyDown={this.onInputFileTriggerKeyDown}
              className={styles.inputFileTrigger}
              htmlFor={name}
            >
              Select a file...
            </label>
          </div>
          <div className={styles.fileName}>
            {currentFileName && (
              <div>
                <span>Selected file: </span>
                <span>
                  <b>{currentFileName}</b> ({formatNumber(fileSize)}kB)
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FormFileUpload.defaultProps = {
  labelPosition: "top",
  existingFile: {
    fileName: "",
    fileContent: "",
  },
};

FormFileUpload.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
  width: PropTypes.string,
  labelPosition: PropTypes.oneOf(["top", "left"]),
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default FormFileUpload;
