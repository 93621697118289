import { FETCH_HEALTH_EVENTS } from "./action.types";
import { HEALTH_EVENTS } from "../api";
import fetch from "./action.fetch";

const createHealthEventsUrl = (context) => {
  if (!context.dateRange) {
    throw new Error("context.dateRange is required");
  }
  if (context.groupId) {
    return HEALTH_EVENTS.getGroup(context.groupId, context.dateRange);
  } else if (context.vesselId) {
    return HEALTH_EVENTS.getVessel(context.vesselId, context.dateRange);
  }
  throw new Error("One of context.groupId and context.vesselId is required");
};

export const fetchHealthEvents = (context) => (dispatch) => {
  dispatch(
    fetch({
      url: createHealthEventsUrl(context),
      actionType: FETCH_HEALTH_EVENTS,
    })
  );
};
