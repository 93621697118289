import React from "react";
import Popup from "../Popup/Popup";
import PropTypes from "prop-types";
import styles from "./ErrorDialog.css";

export default class ErrorDialog extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, message, onOkClick } = this.props;

    return (
      <Popup
        visible={true}
        title={title}
        onHide={onOkClick}
        target={"popupContainer"}
        buttons={[
          {
            label: "Ok",
            action: onOkClick,
          },
        ]}
      >
        <div className={styles.container}>
          <p>{message}</p>
        </div>
      </Popup>
    );
  }
}

ErrorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onOkClick: PropTypes.func.isRequired,
};
