import t from "prop-types";
import React from "react";
import styles from "./MetricsListItemDetails.css";
import { TERMS } from "../../config";

export function MetricsListItemDetails({ data, detailsUrl }) {
  return (
    <div className={styles.container}>
      {/*
       <div className={styles.imageContainer}>
       <Image src={img}
       titleText={imgAltText}
       altText={imgAltText}
       loadingText="Loading equipment image"/>
       <div className={styles.gradient}></div>
       </div>
       */}
      <div className={styles.dataContainer}>
        <ul className={styles.dataList}>
          <li className={styles.dataItem} key={1}>
            <h3 className={styles.value}>{data.itemMake}</h3>
            {/* </<p className={styles.description}>{item.description}</p>*/}
          </li>
        </ul>
        {detailsUrl && (
          <a
            href={detailsUrl}
            className={styles.externalUrl}
            target="_blank"
            rel="noopener noreferrer"
            title={TERMS.factSheetExternal}
          >
            {TERMS.factSheetExternal}
          </a>
        )}
      </div>
    </div>
  );
}

MetricsListItemDetails.propTypes = {
  data: t.object.isRequired,
  // img: t.string,
  // imgAltText: t.string,
  detailsUrl: t.string,
};
