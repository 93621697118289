import React from "react";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import CompareTable from "../../components/CompareTable";
import { fetchCompare, removeCompareVessel } from "../../actions";

const mapStateToProps = (state) => ({
  pageContext: state.pageContext,
  compare: state.fetchCompare,
});

const mapDispatchToProps = {
  fetchCompare,
  removeCompareVessel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <>
    <Header title="Compare" fixed />
    <CompareTable {...props} />
  </>
));
