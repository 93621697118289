import createReducer from "./createReducer";
import { isNullOrUndefined } from "../../common/objects";
import { FETCH_USER_DATA_SUCCESS } from "../actions/action.types";
import { isKongsbergAdmin } from "../common/hasAccess";

export default createReducer(
  {},
  {
    [FETCH_USER_DATA_SUCCESS]: (state, { data }) => {
      return data.pageConfigs;
    },
  }
);

export const selectPageConfig = (
  user,
  pageConfig,
  selectedVesselId,
  selectedVessel,
  selectedVesselGroup,
  pageName,
  {
    vesselId,
    userDefaultId,
    selectedId,
    vesselGroupId,
    userRoles = [],
    userVesselGroups = [],
  } = {}
) => {
  const { companyId, companyName } = user;
  const pageConfigs =
    pageName === "dashboard" && isKongsbergAdmin(userRoles, companyId)
      ? filterAdminDashboards(
          pageConfig[pageName],
          userVesselGroups,
          companyId,
          companyName
        ) || []
      : filterPageConfigs(pageConfig[pageName], companyId) || [];

  const defaultPageConfig = pageConfigs.find((p) => p.name === "Default");
  const companyPageConfig =
    companyId &&
    pageConfigs.find(
      (p) => p.companyId === companyId && isNullOrUndefined(vesselId)
    );
  const vesselPageConfig =
    vesselId && pageConfigs.find((p) => p.vesselId === vesselId);
  const userDefaultPageConfig =
    userDefaultId && pageConfigs.find((p) => p.id === userDefaultId);
  const selectedPageConfig =
    selectedId && pageConfigs.find((p) => p.id === selectedId);
  const crossPageConfig = crossCompanyPageConfig(
    pageConfigs,
    pageName,
    companyName,
    selectedVesselId,
    selectedVessel,
    selectedVesselGroup
  );
  const vesselGroupPageConfig =
    vesselGroupId && pageConfigs.find((p) => p.vesselGroupId === vesselGroupId);

  return (
    crossPageConfig ||
    selectedPageConfig ||
    userDefaultPageConfig ||
    vesselPageConfig ||
    vesselGroupPageConfig ||
    companyPageConfig ||
    defaultPageConfig
  );
};

const equalCaseInsensitiveExcludeNullOrUndefined = (a, b) => {
  if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
    return false;
  } else {
    return a.toUpperCase() === b.toUpperCase();
  }
};

/*
  Cross company page configs
 */

const isCrossCompanyUser = (companyName) => {
  return companyName === "Rolls-Royce";
};

export const crossCompanyPageConfig = (
  pageConfigs,
  pageName,
  companyName,
  selectedVesselId,
  selectedVessel,
  selectedVesselGroup
) => {
  if (!isCrossCompanyUser(companyName)) {
    return false;
  }

  //--------------------------------------------------------------------------------------------------------------------
  if (pageName === "fleet") {
    return pageConfigs.find(
      (p) =>
        p.pageName === "fleet" &&
        equalCaseInsensitiveExcludeNullOrUndefined(
          p.companyId,
          selectedVesselGroup?.companyId
        )
    );
    //--------------------------------------------------------------------------------------------------------------------
  } else if (pageName === "vessel") {
    const vesselPageConfig = pageConfigs.find(
      (p) =>
        p.pageName === "vessel" &&
        equalCaseInsensitiveExcludeNullOrUndefined(
          p.companyId,
          selectedVessel?.companyId
        ) &&
        equalCaseInsensitiveExcludeNullOrUndefined(p.vesselId, selectedVesselId)
    );
    const groupPageConfig = pageConfigs.find(
      (p) =>
        p.pageName === "vessel" &&
        equalCaseInsensitiveExcludeNullOrUndefined(
          p.companyId,
          selectedVesselGroup?.companyId
        )
    );
    return vesselPageConfig ? vesselPageConfig : groupPageConfig;
  }
  //--------------------------------------------------------------------------------------------------------------------
  return null;
};

export const filterPageConfigs = (pageConfigs, userCompanyId) => {
  let result = [];

  //if a company page config exists, filter out the global page config
  result = filterDefaults(pageConfigs, userCompanyId);

  //filter out dashboard page configs for other companies
  result = filterDashboards(result, userCompanyId);

  return result;
};

/*
  When there is a company-specific page config, remove the default page config from the list
*/

export const filterDefaults = (pageConfigs, userCompanyId) => {
  return pageConfigs.filter((p) => {
    if (p.name === "Default") {
      return (
        (hasCompanyDefaultPageConfig(pageConfigs, userCompanyId) &&
          p.companyId) ||
        (!hasCompanyDefaultPageConfig(pageConfigs, userCompanyId) &&
          isNullOrUndefined(p.companyId))
      );
    } else {
      return true;
    }
  });
};

export const hasCompanyDefaultPageConfig = (configs, userCompanyId) => {
  return (
    configs.filter((c) => {
      const companyId = c.companyId ? c.companyId : null;
      return c.name === "Default" && companyId === userCompanyId;
    }).length > 0
  );
};

/*
  Filter dashboards: returns only the defaults, or those belonging to the user's company
*/

export const filterDashboards = (pageConfigs, userCompanyId) => {
  return pageConfigs.filter((p) => {
    const companyId = p.companyId ? p.companyId : null;
    if (p.pageName === "dashboard") {
      return companyId === null || companyId === userCompanyId;
    } else {
      return true;
    }
  });
};

/*
  Filter admin dashboards: returns all dashboards without company
  and those belonging to the companies the admin has access to.
*/

export const filterAdminDashboards = (
  dashboards,
  userVesselGroups,
  userCompanyId,
  userCompanyName
) => {
  const companyIds = getCompanyIds(userVesselGroups, userCompanyId);
  const result = dashboards.filter((p) => {
    const companyId = p.companyId ? p.companyId : null;
    return companyId === null || companyIds.includes(companyId);
  });
  return addCompanyNameToDashboardName(
    result,
    userVesselGroups,
    userCompanyId,
    userCompanyName
  );
};

const getCompanyIds = (userVesselGroups, userCompanyId) => {
  const set = new Set();
  set.add(userCompanyId);
  userVesselGroups.forEach((vg) => set.add(vg.companyId));
  return Array.from(set);
};

const addCompanyNameToDashboardName = (
  dashboards,
  userVesselGroups,
  userCompanyId,
  userCompanyName
) => {
  return dashboards.map((d) => {
    const companyName = getCompanyName(
      d,
      userVesselGroups,
      userCompanyId,
      userCompanyName
    );
    return {
      ...d,
      name: companyName ? `${d.name} (${companyName})` : d.name,
    };
  });
};

const getCompanyName = (
  dasboard,
  userVesselGroups,
  userCompanyId,
  userCompanyName
) => {
  if (dasboard.companyId) {
    if (dasboard.companyId === userCompanyId) {
      return userCompanyName;
    }
    const vesselGroup = userVesselGroups.find(
      (vg) => vg.companyId === dasboard.companyId
    );
    if (vesselGroup) {
      return vesselGroup.companyName;
    }
  }
  return undefined;
};
