import PropTypes from "prop-types";
import React from "react";
import styles from "./MetricTable.css";
import Metric from "../MetricsList/Metric/Metric";
import { Icon } from "../../../common/components/Icon/Icon";
import { some } from "lodash";
import RadioCheckbox from "../RadioCheck/RadioCheckbox";
import { flatMap, flow, map, uniqBy } from "lodash/fp";
import { connect } from "react-redux";
import classNames from "../../../common/classNames";
import { VIEWS, GetCargoUnit } from "../../config";
import PerformanceIndicator from "../PerformanceIndicator/PerformanceIndicator";
import { createPropertyValueComponent } from "../PropertyValues/PropertyValues";

const SAILING_PERFORMANCE = "SailingPerformance";
const PASSIVE_PERFORMANCE = "PassivePerformance";

class MetricTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      checkedVessels,
      vesselClick,
      addCompare,
      vessels,
      restrictedViews,
    } = this.props;

    const uniqueMetricDefinitions = extractUniqueMetricDefinitions(
      data.vessels
    );
    const vesselsWithData = vessels.map((vessel) => ({
      vesselData: data.vessels.find(
        (vesselData) => vessel.id === vesselData.vesselId
      ),
      vessel,
    }));

    const hasVesselData = vesselsWithData.some(
      (vd) => vd.vesselData !== undefined
    );

    const showCompareCheckbox =
      data.vessels.length > 0 &&
      !(restrictedViews && restrictedViews.includes(VIEWS.compare.viewName));
    const isVesselRestricted =
      restrictedViews && restrictedViews.includes(VIEWS.vessel.viewName);
    const vesselNameClassName = isVesselRestricted
      ? styles.nameContainer
      : classNames(styles.nameContainer, styles.pointer);

    const isHmConfigured = some(vesselsWithData, "vesselData.hmAlertStatus");

    const rows = vesselsWithData.map(({ vessel, vesselData }) => {
      const performanceIndicators = vesselData?.performanceIndicators || [];
      const sailingPerformanceIndicators = performanceIndicators.filter(
        (f) => f.performanceIndicatorType === SAILING_PERFORMANCE
      );
      const passivePerformanceIndicators = performanceIndicators.filter(
        (f) => f.performanceIndicatorType === PASSIVE_PERFORMANCE
      );
      return (
        <div
          key={vessel.id}
          className={`${styles.inner} ${styles.vessel}`}
          data-test-id="metric-table-row"
        >
          <div className={styles.listItemItem}>
            <div
              className={vesselNameClassName}
              onClick={() => {
                if (!isVesselRestricted) {
                  vesselClick(vessel);
                }
              }}
            >
              <Icon icon={"hull"} size="l" />{" "}
              <Metric testId={"metric-table-name"} value={vessel.name} />
            </div>
          </div>

          {isHmConfigured && (
            <div className={`${styles.listItemItem} ${styles.status}`}>
              {createPropertyValueComponent(vesselData?.hmAlertStatus)}
            </div>
          )}

          {uniqueMetricDefinitions
            .map((metricDefinition) => [
              metricDefinition,
              findMetricByDefinition(metricDefinition, vesselData),
            ])
            .map(([metricDefinition, metric]) => (
              <div
                key={metricDefinition.metricName + metricDefinition.unit}
                className={styles.listItemItem}
              >
                <Metric
                  value={metric.value}
                  unit={metric.unit}
                  description={metric.description}
                  testId={"metric-" + metricDefinition.metricName}
                />
              </div>
            ))}

          {this.renderEeoi(vesselData)}

          {showCompareCheckbox && checkedVessels.length < 4 && (
            <div
              className={styles.listItemItem}
              data-test-id="metric-table-compare"
            >
              <RadioCheckbox
                onCheck={() => addCompare(vessel)}
                checked={checkedVessels.some(
                  (checkedVessel) => checkedVessel.id === vessel.id
                )}
              />
            </div>
          )}

          {hasVesselData && (
            <>
              <div className={styles.listItemItem}>
                <PerformanceIndicator
                  indicators={sailingPerformanceIndicators}
                />
              </div>

              <div className={styles.listItemItem}>
                <PerformanceIndicator
                  indicators={passivePerformanceIndicators}
                />
              </div>
            </>
          )}
        </div>
      );
    });

    return (
      <div className={styles.table} tabIndex="0">
        {this.getHeadings(
          isHmConfigured,
          uniqueMetricDefinitions,
          hasVesselData,
          showCompareCheckbox
        )}
        {rows}
      </div>
    );
  }

  renderEeoi(vesselData) {
    return vesselData ? (
      <div className={styles.listItemItem}>
        <Metric
          value={
            vesselData.eeoi.averageEEOI !== undefined
              ? vesselData.eeoi.averageEEOI
              : "N/A"
          }
          unit={
            vesselData.eeoi.averageEEOI !== undefined
              ? GetCargoUnit(vesselData.eeoi.cargoType)
              : ""
          }
          testId={"averageEEOI"}
        />
      </div>
    ) : null;
  }

  getHeadings(
    isHmConfigured,
    uniqueMetricDefinitions,
    hasVesselData,
    showCompareCheckbox
  ) {
    return (
      <div className={styles.inner}>
        <div className={styles.title}>
          <h3>Vessel</h3>
        </div>

        {isHmConfigured && (
          <div className={styles.title}>
            <h3>Health Management</h3>
          </div>
        )}

        {uniqueMetricDefinitions.map((metricDefinition) => (
          <div
            key={metricDefinition.metricName + metricDefinition.unit}
            className={styles.title}
          >
            <h3>{metricDefinition.metricName}</h3>
          </div>
        ))}

        {hasVesselData && (
          <div className={styles.title}>
            <h3>EEOI</h3>
          </div>
        )}

        {showCompareCheckbox && (
          <div className={styles.title}>
            <h3>Compare</h3>
          </div>
        )}

        {hasVesselData && (
          <>
            <div className={styles.title}>
              <h3>Sailing Performance</h3>
            </div>
            <div className={styles.title}>
              <h3>Passive Performance</h3>
            </div>
          </>
        )}
      </div>
    );
  }
}

MetricTable.propTypes = {
  data: PropTypes.object.isRequired,
  addCompare: PropTypes.func.isRequired,
  checkedVessels: PropTypes.array.isRequired,
  vesselClick: PropTypes.func.isRequired,
};

const extractUniqueMetricDefinitions = flow(
  flatMap((x) => x.metrics),
  map((x) => ({ metricName: x.metricName, unit: x.unit })),
  uniqBy((x) => x.metricName + " " + x.unit)
);

const findMetricByDefinition = (metricDefinition, vesselData = {}) =>
  (vesselData.metrics || []).find(
    (m) =>
      m.metricName === metricDefinition.metricName &&
      m.unit === metricDefinition.unit
  ) || {};

const mapStateToProps = (state) => ({
  restrictedViews: state.userProfile.restrictedViews,
});

export default connect(mapStateToProps)(MetricTable);
