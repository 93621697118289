import { DateRange } from "../components/DatePicker/Types";

export const getLimits = (vessel: any): DateRange => ({
  from: (vessel && vessel.startTime && new Date(vessel.startTime)) || null,
  to: (vessel && vessel.endTime && new Date(vessel.endTime)) || null,
});

export const unionLimits = (vessels: any): DateRange => {
  if (!vessels.length) {
    return getLimits(null);
  }

  return vessels.reduce(
    (result: any, vessel: any) => {
      const limits = getLimits(vessel);
      if (!result.from || limits.from < result.from) {
        result.from = limits.from;
      }
      if (!result.to || (limits.to && limits.to > result.to)) {
        result.to = limits.to;
      }
      return result;
    },
    { from: null, to: null }
  );
};
