import { FETCH_CALENDAR_DOCUMENTS } from "./action.types";
import { DOCUMENTS } from "../api";
import fetch from "./action.fetch";

export const fetchCalendarDocuments = (vesselId, dateRange, timeOffset) =>
  fetch({
    url: DOCUMENTS.fetchCalendarDocuments(vesselId, dateRange, timeOffset),
    actionType: FETCH_CALENDAR_DOCUMENTS,
    actionData: { timeOffset },
  });
