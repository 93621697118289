import { IDonutData } from "../../components/FuelEmissions/commonTypes";

export const runAndEmissionsDonutDataResponse: IDonutData[] = [
  {
    type: "co2",
    title: "CO2 Emissions",
    itemTypeMetricId: "00000000-0000-0000-0000-000000000000",
    metricId: "00000000-0000-0000-0000-000000000000",
    unit: "kg",
    total: 286702550.0,
    fromDate: "2018-01-01T00:00:00.000Z",
    toDate: "2018-12-31T23:59:59.999Z",
    data: [
      {
        value: 3733.587229507,
        operationalProfileId: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
        operationalProfile: {
          id: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
          category: "General",
          profile: "Other",
          legend: "O",
          sortKey: 0,
        },
      },
      {
        value: 3815095.08729917,
        operationalProfileId: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
        operationalProfile: {
          id: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
          category: "General",
          profile: "Port",
          legend: "P",
          sortKey: 1,
        },
      },
      {
        value: 3910790.18522443,
        operationalProfileId: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
        operationalProfile: {
          id: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
          category: "General",
          profile: "Maneuver",
          legend: "M",
          sortKey: 2,
        },
      },
      {
        value: 32399754.2786858,
        operationalProfileId: "bd2715bc-b42e-4169-a2db-741c400a9adb",
        operationalProfile: {
          id: "bd2715bc-b42e-4169-a2db-741c400a9adb",
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
          sortKey: 3,
        },
      },
      {
        value: 160057238.641964,
        operationalProfileId: "918f02dc-2c51-47fa-a709-d3732423d79f",
        operationalProfile: {
          id: "918f02dc-2c51-47fa-a709-d3732423d79f",
          category: "General",
          profile: "Transit",
          legend: "T",
          sortKey: 4,
        },
      },
      {
        value: 64816642.9429024,
        operationalProfileId: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
        operationalProfile: {
          id: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
          sortKey: 5,
        },
      },
      {
        value: 715820.38584701,
        operationalProfileId: "479cca32-1126-4bef-b377-60ef98f93822",
        operationalProfile: {
          id: "479cca32-1126-4bef-b377-60ef98f93822",
          category: "General",
          profile: "Shore Connection",
          legend: "SC",
          sortKey: 6,
        },
      },
      {
        value: 13421694.3076184,
        operationalProfileId: "8396452f-67c0-4094-b24f-72e1f1e7754a",
        operationalProfile: {
          id: "8396452f-67c0-4094-b24f-72e1f1e7754a",
          category: "DP",
          profile: "DP",
          legend: "DP",
          sortKey: 100,
        },
      },
      {
        value: 7561780.21692141,
        operationalProfileId: "b86923a8-7f0b-40f2-8470-449272cf5508",
        operationalProfile: {
          id: "b86923a8-7f0b-40f2-8470-449272cf5508",
          category: "DP",
          profile: "DP Class 2",
          legend: "DP2",
          sortKey: 101,
        },
      },
    ],
  },
  {
    type: "nox",
    title: "NOx Emissions",
    itemTypeMetricId: "00000000-0000-0000-0000-000000000000",
    metricId: "00000000-0000-0000-0000-000000000000",
    unit: "kg",
    total: 0.0,
    fromDate: "2018-01-01T00:00:00.000Z",
    toDate: "2018-12-31T23:59:59.999Z",
    data: [
      {
        value: 11.0,
        operationalProfileId: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
        operationalProfile: {
          id: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
          category: "General",
          profile: "Other",
          legend: "O",
          sortKey: 0,
        },
      },
      {
        value: 1.0,
        operationalProfileId: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
        operationalProfile: {
          id: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
          category: "General",
          profile: "Port",
          legend: "P",
          sortKey: 1,
        },
      },
      {
        value: 0.0,
        operationalProfileId: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
        operationalProfile: {
          id: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
          category: "General",
          profile: "Maneuver",
          legend: "M",
          sortKey: 2,
        },
      },
      {
        value: 15.0,
        operationalProfileId: "bd2715bc-b42e-4169-a2db-741c400a9adb",
        operationalProfile: {
          id: "bd2715bc-b42e-4169-a2db-741c400a9adb",
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
          sortKey: 3,
        },
      },
      {
        value: 20.0,
        operationalProfileId: "918f02dc-2c51-47fa-a709-d3732423d79f",
        operationalProfile: {
          id: "918f02dc-2c51-47fa-a709-d3732423d79f",
          category: "General",
          profile: "Transit",
          legend: "T",
          sortKey: 4,
        },
      },
      {
        value: 15.0,
        operationalProfileId: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
        operationalProfile: {
          id: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
          sortKey: 5,
        },
      },
      {
        value: 3.0,
        operationalProfileId: "479cca32-1126-4bef-b377-60ef98f93822",
        operationalProfile: {
          id: "479cca32-1126-4bef-b377-60ef98f93822",
          category: "General",
          profile: "Shore Connection",
          legend: "SC",
          sortKey: 6,
        },
      },
      {
        value: 1.0,
        operationalProfileId: "8396452f-67c0-4094-b24f-72e1f1e7754a",
        operationalProfile: {
          id: "8396452f-67c0-4094-b24f-72e1f1e7754a",
          category: "DP",
          profile: "DP",
          legend: "DP",
          sortKey: 100,
        },
      },
      {
        value: 1.0,
        operationalProfileId: "b86923a8-7f0b-40f2-8470-449272cf5508",
        operationalProfile: {
          id: "b86923a8-7f0b-40f2-8470-449272cf5508",
          category: "DP",
          profile: "DP Class 2",
          legend: "DP2",
          sortKey: 101,
        },
      },
    ],
  },
  {
    type: "sox",
    title: "SOx Emissions",
    itemTypeMetricId: "00000000-0000-0000-0000-000000000000",
    metricId: "00000000-0000-0000-0000-000000000000",
    unit: "kg",
    total: 0.0,
    fromDate: "2018-01-01T00:00:00.000Z",
    toDate: "2018-12-31T23:59:59.999Z",
    data: [
      {
        value: 2.0,
        operationalProfileId: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
        operationalProfile: {
          id: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
          category: "General",
          profile: "Other",
          legend: "O",
          sortKey: 0,
        },
      },
      {
        value: 3.0,
        operationalProfileId: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
        operationalProfile: {
          id: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
          category: "General",
          profile: "Port",
          legend: "P",
          sortKey: 1,
        },
      },
      {
        value: 1.0,
        operationalProfileId: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
        operationalProfile: {
          id: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
          category: "General",
          profile: "Maneuver",
          legend: "M",
          sortKey: 2,
        },
      },
      {
        value: 1.0,
        operationalProfileId: "bd2715bc-b42e-4169-a2db-741c400a9adb",
        operationalProfile: {
          id: "bd2715bc-b42e-4169-a2db-741c400a9adb",
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
          sortKey: 3,
        },
      },
      {
        value: 0.3,
        operationalProfileId: "918f02dc-2c51-47fa-a709-d3732423d79f",
        operationalProfile: {
          id: "918f02dc-2c51-47fa-a709-d3732423d79f",
          category: "General",
          profile: "Transit",
          legend: "T",
          sortKey: 4,
        },
      },
      {
        value: 22.0,
        operationalProfileId: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
        operationalProfile: {
          id: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
          sortKey: 5,
        },
      },
      {
        value: 5.0,
        operationalProfileId: "479cca32-1126-4bef-b377-60ef98f93822",
        operationalProfile: {
          id: "479cca32-1126-4bef-b377-60ef98f93822",
          category: "General",
          profile: "Shore Connection",
          legend: "SC",
          sortKey: 6,
        },
      },
      {
        value: 4.0,
        operationalProfileId: "8396452f-67c0-4094-b24f-72e1f1e7754a",
        operationalProfile: {
          id: "8396452f-67c0-4094-b24f-72e1f1e7754a",
          category: "DP",
          profile: "DP",
          legend: "DP",
          sortKey: 100,
        },
      },
      {
        value: 2.0,
        operationalProfileId: "b86923a8-7f0b-40f2-8470-449272cf5508",
        operationalProfile: {
          id: "b86923a8-7f0b-40f2-8470-449272cf5508",
          category: "DP",
          profile: "DP Class 2",
          legend: "DP2",
          sortKey: 101,
        },
      },
    ],
  },
  {
    type: "run-seconds",
    title: "Run",
    itemTypeMetricId: "5258c5b3-d3c5-4cf9-a107-fa9a1338353d",
    metricId: "8fca6ef3-ce57-4134-a41c-f0e6c2d11e2f",
    unit: "s",
    total: 31275392.0,
    fromDate: "2018-01-01T00:00:00.000Z",
    toDate: "2019-01-01T00:00:00.000Z",
    data: [
      {
        value: 740.0,
        operationalProfileId: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
        operationalProfile: {
          id: "3807c843-d3c6-47f0-89f2-39578d5f9ffc",
          category: "General",
          profile: "Other",
          legend: "O",
          sortKey: 0,
        },
      },
      {
        value: 6228373.0,
        operationalProfileId: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
        operationalProfile: {
          id: "ddf2bf6f-ffa1-4b54-a577-ae4be665a621",
          category: "General",
          profile: "Port",
          legend: "P",
          sortKey: 1,
        },
      },
      {
        value: 2221351.0,
        operationalProfileId: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
        operationalProfile: {
          id: "57512d2b-19cc-4f2a-9ac2-959d16d51316",
          category: "General",
          profile: "Maneuver",
          legend: "M",
          sortKey: 2,
        },
      },
      {
        value: 1680399.0,
        operationalProfileId: "bd2715bc-b42e-4169-a2db-741c400a9adb",
        operationalProfile: {
          id: "bd2715bc-b42e-4169-a2db-741c400a9adb",
          category: "General",
          profile: "Eco Transit",
          legend: "ET",
          sortKey: 3,
        },
      },
      {
        value: 6283811.0,
        operationalProfileId: "918f02dc-2c51-47fa-a709-d3732423d79f",
        operationalProfile: {
          id: "918f02dc-2c51-47fa-a709-d3732423d79f",
          category: "General",
          profile: "Transit",
          legend: "T",
          sortKey: 4,
        },
      },
      {
        value: 1338306.0,
        operationalProfileId: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
        operationalProfile: {
          id: "8be8bdd5-b18c-41b2-ab9a-2f0bc3ccca9c",
          category: "General",
          profile: "Fast Transit",
          legend: "FT",
          sortKey: 5,
        },
      },
      {
        value: 5480.0,
        operationalProfileId: "479cca32-1126-4bef-b377-60ef98f93822",
        operationalProfile: {
          id: "479cca32-1126-4bef-b377-60ef98f93822",
          category: "General",
          profile: "Shore Connection",
          legend: "SC",
          sortKey: 6,
        },
      },
      {
        value: 9608533.0,
        operationalProfileId: "8396452f-67c0-4094-b24f-72e1f1e7754a",
        operationalProfile: {
          id: "8396452f-67c0-4094-b24f-72e1f1e7754a",
          category: "DP",
          profile: "DP",
          legend: "DP",
          sortKey: 100,
        },
      },
      {
        value: 3908399.0,
        operationalProfileId: "b86923a8-7f0b-40f2-8470-449272cf5508",
        operationalProfile: {
          id: "b86923a8-7f0b-40f2-8470-449272cf5508",
          category: "DP",
          profile: "DP Class 2",
          legend: "DP2",
          sortKey: 101,
        },
      },
    ],
  },
];
