import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  formatMonthValue,
  initMonthsForm,
  resetMonthsForm,
  saveMonthsForm,
  setMonthValue,
} from "../../../actions/admin/action.energyBudget";
import {
  calculateTotal,
  hasChanges,
  isInputValid,
} from "../../../reducers/admin/energyBudget/reducer.monthsForm";
import Button from "../../../../common/components/Button/Button";
import MonthsForm from "./MonthsForm";
import styles from "./EnergyBudget.css";

class MonthsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.initMonthsForm(this.props.data);
  }

  save() {
    if (hasChanges(this.props.form)) {
      this.props.saveMonthsForm(
        this.props.vesselId,
        this.props.metric.id,
        this.props.form
      );
    }
  }

  render() {
    return (
      <div>
        <MonthsForm
          unit={this.props.metric.unit}
          inputs={this.props.form.inputs}
          calculateTotal={calculateTotal}
          onChange={this.props.setMonthValue}
          onBlur={this.props.formatMonthValue}
        />
        <div className={styles.actions}>
          <Button value="Reset" clickAction={this.props.resetMonthsForm} />
          <Button
            value="Save"
            clickAction={this.save}
            disabled={!this.props.canSave}
          />
        </div>
      </div>
    );
  }
}

MonthsContainer.propTypes = {
  vesselId: PropTypes.string.isRequired,
  metric: PropTypes.shape({
    id: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  form: state.admin.energyBudget.monthsForm,
  canSave:
    isInputValid(state.admin.energyBudget.monthsForm) &&
    !state.admin.energyBudget.monthsForm.isSaving,
});

const mapDispatchToProps = {
  initMonthsForm,
  setMonthValue,
  formatMonthValue,
  resetMonthsForm,
  saveMonthsForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthsContainer);
