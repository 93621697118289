export const TERMS = {
  appTitle: "Intelligent Asset Management",
};

export const PAGES = {
  dashboard: { name: "Dashboard", url: "/", isIndex: true },
  dashboardNew: { name: "Dashboard", url: "/", isHidden: true },
  overview: { name: "Overview", url: "/overview" },
  benchmark: { name: "Benchmark", url: "/benchmark" },
  elbalance: { name: "El Balance", url: "/elbalance" },
  trend: { name: "Trend", url: "/trend" },
  battery: { name: "Battery", url: "/battery" },
  settings: { name: "Settings", url: "/settings" },
  dataquality: { name: "Data Quality", url: "/dataquality" },
  engines: { name: "Engines", url: "/engines" },
};

export const DASHBOARDCOMPONENTS = [
  "spacer",
  "sailingPerformance",
  "speedOverGround",
  "passivePerformance",
  "dashboardPower",
  "voyageInformation",
  "operatingCost",
  "legInformation",
  "weather",
  "waveRadar",
  "operation",
  "restLoad",
];

export const COMPONENT_INFO = {
  sailingPerformance: {
    title: "Sailing Performance",
    text:
      "Fuel consumption is showed per nautical mile, and is active in transit modes. " +
      "When a baseline is available, the fuel consumption is benchmarked against historical data at the same vessel speed. " +
      "Bad weather can result in bad performance. Sailing performance is only calculated when the vessel is in transit modes. " +
      "Transit modes typically starts at 4 knots, but this may be configured differently for your vessel.",
  },
  passivePerformance: {
    title: "Passive Performance",
    text:
      "Fuel consumption is showed per hour, and is mainly for operation modes without speed. " +
      "When a baseline is available, the fuel consumption is benchmarked against " +
      "historical data in the same operation mode.",
  },
  speedOverGround: {
    title: "Speed Over Ground",
    text: "Speed from the ship's GPS.",
  },
  weather: {
    title: "Weather",
    text:
      "Wind power - The resultant effect of the wind force on the power required to maintain a desired speed. " +
      "Wind force - The wind force on the ship. " +
      "Beaufort - The wind force scale (0-12) calculated from relative wind speed and direction according to ISO 19030-2:2016.",
  },
  propulsionPower: {
    title: "Propulsion Power",
    text: "Total propulsion power.",
  },
  propulsionPerformance: {
    title: "Propulsion Performance",
    text:
      "Added wave resistance. The current propulsion power is compared to the calm water power, " +
      "and the value is given in percent. This indicates how much higher the current power is. For Transit operations only.",
  },
  enginePerformance: {
    title: "Engine Performance",
    text:
      "The average specific fuel consumption of all engines online. " +
      "Baselined against historical data for the same total engine power.",
  },
  restLoad: {
    title: "Rest Load",
    text: "Accommodation, auxiliary equipment and loss power. (Total available power minus propulsion and battery charge power).",
  },
  enginePower: {
    title: "Total Power",
    text:
      "Engines: Total engine power transferred to switchboard and/or shaftlines. " +
      "Batteries: Net battery input or output power. Positive/blue value indicates discharging and negative/orange value indicates charging. " +
      "Shore: Input power from shore connection.",
  },
  lngRatio: {
    title: "LNG Ratio",
    text: "The ratio of diesel to LNG in dual fuel engines (by weight). A value of 20% indicates 20% diesel and 80% LNG.",
  },
  batteryPeakShaveCapacity: {
    title: "Peak Shave Capacity",
    text:
      "Based on the battery specification, the percentage value shows how much of the 24h peak shaving capacity that is used. " +
      "If the value is above 100% it indicates that the battery should be used more gently.",
  },
  batteryDailyDischargePerformance: {
    title: "Daily Discharge Performance",
    text:
      "Based on the battery specification, the number shows how much discharging has been done in the last 24 hours. " +
      "If the gauge is red, the battery has discharged more than the specification.",
  },
  batteryRateOfChange: {
    title: "Rate of Change",
    text:
      "Shows how much the power fluctuates in the different components. (E.g. propeller in RPM-mode in harsh conditions.) " +
      "If the value is high (orange color) it indicates that the component is improperly used, and changes are advised. (E.g. set propeller to power-mode).",
  },
  dashboardPower: {
    title: "Power",
    text:
      "Total: Total available power. (Sum of engine, battery output and shore connection power). " +
      "Propulsion: Total power used for propulsion, including thrusters. " +
      "Rest: Accommodation, auxiliary equipment and loss power. (Total available power minus propulsion and battery charge power). " +
      "Shore: Input power from shore connection.",
  },
  batteryPower: {
    title: "Power",
    text: "Shore Connection: Input power from shore connection. ",
  },
  operatingCost: {
    title: "Operating Cost",
    text: "Calculated cost. Includes fuel cost and run cost for engines, thrusters and propellers.",
  },
  seaMargin: {
    title: "Sea Margin",
    text: "Sea margin indicates extra propulsion power required due to external forces.",
  },
  legInformation: {
    title: "Leg Information",
    text: "Information about current leg. Updates every minute",
  },
  voyageInformation: {
    title: "Voyage Information",
    text: "Information about current voyage. Updates every minute",
  },
  waveRadar: {
    title: "Wave Radar",
    text:
      "Showing the latest received wave data for the significant, first and second waves. " +
      "No data will be displayed if there has been no fresh data in the last minute. " +
      "The signal reliability (degree of trust) is indicated by the color of the values; " +
      "white for high reliability (index at 70 or more), yellow for middle reliability " +
      "(index at 45 or more and less than 70) and orange for low reliability (index at less than 45). " +
      "Status indicators indicates if the latest data received is valid or not (green or red).",
  },
  benchmark: {
    title: "Benchmark",
    text: "The data shown is for the primary performance indicator fuel type.",
  },
};
