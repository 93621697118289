import { capitalize, get, omit, sortBy } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Header } from "../../../../components/Header";
import { VIEWS } from "../../../../config";
import PageConfigurationGrid from "../PageConfigurationGrid/PageConfigurationGrid";
import styles from "./PageLayoutConfiguration.css";
import {
  createGridLayout,
  updateGridLayout,
  clearPageLayoutConfiguration,
  closeSettingsDialog,
  setPageConfigValue,
  fetchPageConfig,
} from "../../../../actions/admin";
import GridConfigFlyout from "../../../../components/GridConfigFlyout/GridConfigFlyout";
import {
  COMPONENTS_LIST,
  COMPONENT_PARAMS,
} from "../PageConfigurationGrid/pageConfigurationGridData";
import SettingsPopup from "./SettingsPopup";
import {
  isComponentAlreadyAdded,
  checkRowsConfigValidity,
} from "../../../../common/pageConfig";
import { useNavigate } from "react-router-dom";

const PageLayoutConfiguration = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let rows = [];
    try {
      rows = JSON.parse(get(props.form, "fields.rows.value", "[]"));
    } catch {
      //do nothing
    }
    props.createGridLayout(rows);
  }, []);

  useEffect(() => {
    if (!pageName) {
      navigate(VIEWS.pageconfigs.url, { replace: true });
    }
  });

  useEffect(() => () => props.clearPageLayoutConfiguration(), []);

  const setMaxRows = () => {
    if (pageName) {
      return pageName.toLowerCase() === "fleet" ? 1 : 2;
    }
    return 0;
  };

  const { rowsCount, layout, settingsDialog, rowsConfig } = props;
  const pageName = props.form.fields?.pageName?.value;
  const maxCols = 5;
  const maxRows = setMaxRows();

  const onAddItem = (e) => {
    if (isComponentAlreadyAdded(e, rowsConfig)) {
      return;
    }
    const addedItem = e.toLowerCase();
    const { itemX, itemY } = getCoordinates(addedItem);

    if (itemX < maxCols && itemY < maxRows) {
      const colSpan = addedItem === "donuts" ? 2 : 1;
      const idNumber =
        layout.reduce((acc, item) => {
          return acc > getIdNumber(item.i) ? acc : getIdNumber(item.i);
        }, 0) + 1;
      const id = `${idNumber}_${e}`;
      const updatedLayout = sortBy(
        layout.concat({
          w: colSpan,
          h: 1,
          x: itemX,
          y: itemY,
          i: id,
          maxH: 1,
          moved: false,
          static: false,
        }),
        ["y", "x"]
      );

      const index = updatedLayout.map((item) => item.i).indexOf(id);
      const updatedRowsConfig = rowsConfig.slice();
      updatedRowsConfig.splice(index, 0, {
        id,
        params: COMPONENT_PARAMS[e],
        type: e,
        colSpan,
        row: itemY,
      });

      props.updateGridLayout(updatedLayout, updatedRowsConfig);
    }
  };

  const getCoordinates = (addedItem) => {
    let itemX = 0;
    let itemY = 0;
    const donutSpan = 2;
    for (let i = 0; i < maxRows; i++) {
      itemX = 0;
      itemY = i;
      const itemsInRow = layout.filter((item) => item.y === i);
      for (let item of itemsInRow) {
        const currentX = item.x;
        if (itemX < currentX) {
          break;
        }
        itemX = currentX + item.w;
      }
      if (itemX < maxCols) {
        break;
      }
    }
    if (addedItem === "donuts" && itemX + donutSpan > maxCols) {
      itemX = 0;
      itemY++;
    }
    return { itemX, itemY };
  };

  const onSave = () => {
    const pageConfiguration = [];
    rowsConfig.forEach((c) => {
      if (!pageConfiguration[c.row]) {
        pageConfiguration[c.row] = [];
      }
      const configItem = omit(c, ["id", "row"]);
      pageConfiguration[c.row].push({
        type: configItem.type,
        colSpan: configItem.colSpan,
        params: configItem.params,
        ...configItem,
      });
    });
    const rows = JSON.stringify(pageConfiguration, null, 1);
    props.setPageConfigValue("rows", rows);
    routeChange();
  };

  const getIdNumber = (id) => {
    return parseInt(id.substring(0, id.indexOf("_")));
  };

  const routeChange = () => {
    navigate(VIEWS.pageconfigs.url);
  };

  const onClearAll = () => {
    props.clearPageLayoutConfiguration();
  };

  const takenColumns = layout.reduce((acc, item) => acc + item.w, 0);
  const isLimitReached = takenColumns / maxRows >= maxCols;
  const pageNameCapitalized = capitalize(pageName ?? "");
  const componentsList =
    COMPONENTS_LIST[pageNameCapitalized.toLowerCase()] || [];
  const title = `${pageNameCapitalized} Page Configuration`;
  const subtitle = `The maximum number of rows for ${pageNameCapitalized} page is ${maxRows}`;
  const errorMessage =
    rowsCount > maxRows
      ? "You have exceeded the maximum number of rows for this page."
      : checkRowsConfigValidity(rowsConfig);

  return (
    <div>
      <Header title={title} sticky />
      <div className={styles.pageConfigurationContainer}>
        <PageConfigurationGrid maxRows={maxRows} maxCols={maxCols} />
        <GridConfigFlyout
          showing={true}
          componentsList={componentsList}
          disableList={!maxRows || isLimitReached}
          subtitle={subtitle}
          errorMessage={errorMessage}
          title="Components list"
          onClick={onAddItem}
          onSave={onSave}
          onCancel={routeChange}
          saveButtonValue={"Update JSON"}
          cancelButtonValue={"Cancel"}
          saveButtonType={"content"}
          saveButtonDisabled={!!errorMessage || !maxRows}
          cancelButtonType={"content"}
          cancelButtonPadding={"0 2.5rem"}
          buttons={[
            {
              label: "Clear all",
              action: onClearAll,
              disabled: !layout.length,
            },
          ]}
        />
      </div>
      {settingsDialog.visible && (
        <SettingsPopup onCancel={props.closeSettingsDialog} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const form = state.admin.pageConfig.form;
  const layout = state.admin.pageLayoutConfiguration.layout;
  const rowsCount = state.admin.pageLayoutConfiguration.rowsCount;
  const rowsConfig = state.admin.pageLayoutConfiguration.rowsConfig;
  const settingsDialog = state.admin.pageLayoutConfiguration.settingsDialog;
  return {
    form,
    layout,
    rowsCount,
    rowsConfig,
    settingsDialog,
  };
};

const mapActionsToProps = {
  createGridLayout,
  updateGridLayout,
  clearPageLayoutConfiguration,
  closeSettingsDialog,
  setPageConfigValue,
  fetchPageConfig,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PageLayoutConfiguration);
