import { SAVE_USER_PROFILE } from "./action.types";
import fetch from "./action.fetch";
import { USER } from "../api";
import { convertFieldsToKeyValues } from "../common/forms";

export const saveUserProfile = (userSettings) => {
  let payload = convertFieldsToKeyValues(userSettings);
  return fetch({
    url: USER.putSettings(),
    method: "put",
    payload: payload,
    actionType: SAVE_USER_PROFILE,
  });
};
