import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../../components/Header";
import styles from "../Admin.css";
import devOpsStyles from "./DevOps.css";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { fetchCompanies, fetchVesselStatuses } from "../../../actions/admin";
import Loader from "../../../../common/components/Loader";
import Timer from "../../../../common/components/Timer/Timer";
import {
  formatCpuLoadAvg,
  formatDiskUsage,
  formatLastPing,
  formatLastPingDetailed,
  formatSystem,
  formatUptime,
  formatUptimeDetailed,
  formatVersion,
  getActionsStatus,
  getCpuLoadAvgStatus,
  getDbStatus,
  getDbWarningStyle,
  getDiskStatus,
  getLastPingStatus,
  getProcessStatus,
  getSecurityStatus,
  getSystemStatus,
  getUptimeStatus,
  getVersionStatus,
  formatLastDataToShore,
  formatLastDataToShoreDetailed,
  getLastDataToShoreStatus,
  getProcessorBuildStatus,
} from "../../../common/devOpsFormatters";
import ComponentInfo from "../../../../common/components/ComponentInfo/ComponentInfo";
import DevOpsDetailedCellView from "./DevOpsDetailedCellView";
import { isoStringToFormattedDateTime } from "../../../common/dates";
import {
  fetchApplicationVersions,
  sortVessels,
  setSelectedCompanyForDevOps,
  onToggleVesselInsightVessels,
  fetchMinimumProcessorBuild,
} from "../../../actions/admin/action.devOps";
import { FormTextBox } from "../../../components/Form";
import SelectBox from "../../../components/SelectBox";
import Checkbox from "../../../../common/components/Checkbox/Checkbox";

export const refreshInterval = 60;

class DevOps extends Component {
  buttonStyle = {
    position: "absolute !important",
    left: 0,
    right: 0,
    margin: "-15px auto auto -5px",
    width: "100%",
    height: "100%",
    opacity: 0,
  };

  constructor(props) {
    super(props);
    this.state = { secondsCounter: 0 };
    this.onCompanySelect = this.onCompanySelect.bind(this);
    this.gridConfig = this.getGridConfig();
  }

  getGridConfig() {
    return {
      columns: [
        { header: "Company", field: "companyName", align: "left" },
        { header: "Name", field: "vesselName", align: "left" },
        {
          field: "lastPing",
          header: "Ping",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastPing(vessel),
              formatLastPing(vessel.lastPing)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getLastPingStatus(vessel),
              vessel.lastPing
            ),
          }),
        },
        {
          field: "uptime",
          header: "Uptime",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsUptime(vessel),
              formatUptime(vessel.uptime)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getUptimeStatus(vessel),
              vessel.uptime
            ),
          }),
        },
        {
          field: "processorBuild",
          header: "Processor build",
          align: "left",
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getProcessorBuildStatus(vessel, this.props.minimumProcessorBuild),
              vessel.processorBuild
            ),
          }),
        },
        {
          field: "dataToShore",
          header: "Data to shore",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastDataToShore(vessel),
              formatLastDataToShore(vessel.dataToShore)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getLastDataToShoreStatus(vessel),
              vessel.dataToShore
            ),
          }),
        },
        {
          field: "processRun",
          header: "Process",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsProcess(vessel),
              vessel.processRunDiff
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getProcessStatus(vessel),
              vessel.processRun
            ),
          }),
        },
        {
          field: "writeOK",
          header: "Db",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsDatabase(vessel),
              vessel.writeOkDiff
            ),
          extendedFormat: this.formatDb,
        },
        {
          field: "securityAlerts",
          header: "Security",
          align: "left",
          template: (vessel) =>
            this.renderCell(vessel, this.detailsSecurity(vessel), undefined),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSecurityStatus(vessel),
              vessel.securityAlerts
            ),
          }),
        },
        {
          field: "diskSize",
          header: "Disk",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsDisk(vessel),
              formatDiskUsage(vessel.diskSize)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getDiskStatus(vessel),
              vessel.diskSize
            ),
          }),
        },
        {
          header: "Cpu",
          align: "left",
          field: "cpuLoadAvg",
          format: formatCpuLoadAvg,
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getCpuLoadAvgStatus(vessel),
              vessel.cpuLoadAvg
            ),
          }),
        },
        {
          field: "actions",
          header: "Actions",
          align: "left",
          template: this.renderActionCell,
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getActionsStatus(vessel),
              vessel.actions
            ),
          }),
        },
        {
          field: "versions",
          header: "Version",
          align: "left",
          template: this.renderVersionCell,
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getVersionStatus(vessel),
              formatVersion(vessel.versionsOutdated),
              styles.okBackground
            ),
          }),
        },
        {
          field: "system",
          header: "System",
          align: "left",
          template: this.renderSystemCell,
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSystemStatus(vessel),
              formatSystem(vessel.lastPing),
              styles.okBackground
            ),
          }),
        },
      ],
    };
  }

  detailsDatabase = ({ writeOk, writeOkMax, writeError }) => [
    { name: "Kilobytes written in last report", value: writeOk },
    {
      name: "Maximum number of kilobytes written last week",
      value: writeOkMax,
    },
    { name: "Kilobytes of write errors", value: writeError },
  ];

  detailsDisk = ({ diskStatus }) => [
    {
      name: "Disk status code",
      value: diskStatus,
    },
  ];

  detailsLastPing = ({ lastPing, roundTripTime, ipAddress }) => [
    {
      name: "Duration since last ping",
      value: formatLastPingDetailed(lastPing),
    },
    {
      name: "Round trip time (ms)",
      value: roundTripTime,
    },
    {
      name: "Last IP address",
      value: ipAddress,
    },
  ];

  detailsProcess = ({ processRun, processRunMax }) => [
    {
      name: "Running processes in last report",
      value: processRun,
    },
    {
      name: "Maximum number of running processes last week",
      value: processRunMax,
    },
  ];

  detailsSecurity = ({
    securityFail2Ban,
    securityOneWay,
    securityUserLoginWithoutRa,
  }) => [
    { name: "Fail2Ban", value: securityFail2Ban },
    { name: "One way", value: securityOneWay },
    { name: "Login without Remote Access", value: securityUserLoginWithoutRa },
  ];

  detailsUptime = ({ uptime }) => [
    { name: "Uptime", value: formatUptimeDetailed(uptime) },
  ];

  detailsLastDataToShore = ({ dataToShore }) => [
    {
      name: "Last data to shore",
      value: formatLastDataToShoreDetailed(dataToShore),
    },
  ];

  formatDb = (vessel) => {
    const status = getDbStatus(vessel);
    const result = {
      className: this.getClassName(status, vessel.writeOkDiff),
    };
    result.style =
      status.isWarning && !status.isError
        ? getDbWarningStyle(vessel)
        : undefined;
    return result;
  };

  renderActionCell = (vessel) => {
    const pendingActions =
      vessel.actionDetails &&
      vessel.actionDetails.filter(
        (x) =>
          x.actionMetadata &&
          x.actionMetadata.scheduledOn === undefined &&
          x.actionMetadata.failedOn === undefined
      );
    const scheduledActions =
      vessel.actionDetails &&
      vessel.actionDetails.filter(
        (x) =>
          x.actionMetadata &&
          x.actionMetadata.scheduledOn !== undefined &&
          x.actionMetadata.failedOn === undefined
      );
    const failedActions =
      vessel.actionDetails &&
      vessel.actionDetails.filter(
        (x) => x.actionMetadata && x.actionMetadata.failedOn !== undefined
      );

    const renderUpdateTarget = (updateTarget) =>
      updateTarget && <li> Update target: {updateTarget}</li>;

    const renderFailedActions = (action, index) => {
      return (
        action && (
          <li key={index}>
            {action.type}
            {action.actionMetadata && (
              <ul
                style={{ listStyleType: "none" }}
                className={devOpsStyles.cellDetailMetadata}
              >
                <li>
                  Created on:{" "}
                  {isoStringToFormattedDateTime(
                    action.actionMetadata.createdOn
                  )}
                </li>
                {renderUpdateTarget(action.actionMetadata.updateTarget)}
                <li>
                  Scheduled on:{" "}
                  {isoStringToFormattedDateTime(
                    action.actionMetadata.scheduledOn
                  )}
                </li>
                <li>
                  Failed on:{" "}
                  {isoStringToFormattedDateTime(action.actionMetadata.failedOn)}
                </li>
                <li>{action.actionMetadata.failedForReason}</li>
              </ul>
            )}
          </li>
        )
      );
    };
    const renderPendingActions = (action, index) => {
      return (
        action && (
          <li key={index}>
            {action.type}
            {action.actionMetadata && (
              <ul
                style={{ listStyleType: "none" }}
                className={devOpsStyles.cellDetailMetadata}
              >
                <li>
                  Created on:{" "}
                  {isoStringToFormattedDateTime(
                    action.actionMetadata.createdOn
                  )}
                </li>
                {renderUpdateTarget(action.actionMetadata.updateTarget)}
              </ul>
            )}
          </li>
        )
      );
    };
    const renderScheduledActions = (action, index) => {
      return (
        action && (
          <li key={index}>
            {action.type}
            {action.actionMetadata && (
              <ul
                style={{ listStyleType: "none" }}
                className={devOpsStyles.cellDetailMetadata}
              >
                <li>
                  Created on:{" "}
                  {isoStringToFormattedDateTime(
                    action.actionMetadata.createdOn
                  )}
                </li>
                {renderUpdateTarget(action.actionMetadata.updateTarget)}
                <li>
                  Scheduled on:{" "}
                  {isoStringToFormattedDateTime(
                    action.actionMetadata.scheduledOn
                  )}
                </li>
              </ul>
            )}
          </li>
        )
      );
    };

    const template = (
      <div>
        {pendingActions && pendingActions.length > 0 && (
          <div className={devOpsStyles.cellActionStatusContainer}>
            <div className={devOpsStyles.cellDetailHeader}>Pending actions</div>
            <ol>{pendingActions.map(renderPendingActions)}</ol>
          </div>
        )}
        {scheduledActions && scheduledActions.length > 0 && (
          <div className={devOpsStyles.cellActionStatusContainer}>
            <div className={devOpsStyles.cellDetailHeader}>
              Scheduled actions
            </div>
            <ol>{scheduledActions.map(renderScheduledActions)}</ol>
          </div>
        )}
        {failedActions && failedActions.length > 0 && (
          <div className={devOpsStyles.cellActionStatusContainer}>
            <div className={devOpsStyles.cellDetailHeader}>Failed actions</div>
            <ol>{failedActions.map(renderFailedActions)}</ol>
          </div>
        )}
      </div>
    );
    return this.renderCellTemplate(vessel, template, vessel.actions);
  };

  renderSystemCell = (vessel) => {
    const template = (
      <FormTextBox
        name="systemMessage"
        label="System verification message"
        value={vessel.systemVerification}
        multiLine={true}
      />
    );
    return this.renderCellTemplate(vessel, template, undefined);
  };

  renderVersionCell = (vessel) => {
    const { applicationVersions } = this.props.list;

    const vesselVersionClassName = (applicationVersion, vesselVersion) => {
      if (vesselVersion === undefined) {
        return styles.placeholder;
      }
      if (applicationVersion !== vesselVersion) {
        return styles.warning;
      }
      return null;
    };
    const renderApplicationVersions = (application, index) => {
      const vesselApplication =
        vessel &&
        vessel.applications &&
        vessel.applications.find((x) => x.name === application.name);
      const className = vesselVersionClassName(
        application && application.version,
        vesselApplication && vesselApplication.version
      );
      return (
        application && (
          <li key={index}>
            {application.name}
            <ul
              style={{ listStyleType: "none" }}
              className={devOpsStyles.cellDetailMetadata}
            >
              <li>Lastest version: {application.version}</li>
              <li>
                {" "}
                Vessel version:{" "}
                <span className={className}>
                  {(vesselApplication && vesselApplication.version) || "N/A"}
                </span>
              </li>
            </ul>
          </li>
        )
      );
    };

    const template = (
      <div className={devOpsStyles.cellActionStatusContainer}>
        <div className={devOpsStyles.cellDetailHeader}>
          Application versions
        </div>
        <ul>{applicationVersions.map(renderApplicationVersions)}</ul>
      </div>
    );
    return this.renderCellTemplate(
      vessel,
      template,
      formatVersion(vessel.versionsOutdated)
    );
  };

  /**
   *  Render a cell with an OnClick / OnHover popup showing a simple list of details
   *
   * @param vessel  The vessel object
   * @param details Array of vessel details to display in popup on hover
   * @param value   Value to display in datagrid
   * @returns {*}   Returns a datagrid template to render the cell
   */
  renderCell = (vessel, details, value) => {
    if (!vessel) {
      return null;
    }
    const output = +value === 0 ? "0" : value || " ";

    return (
      <ComponentInfo
        modalTargetId={"popupContainer"}
        buttonStyle={this.buttonStyle}
        template={<DevOpsDetailedCellView data={details} />}
      >
        {output}
      </ComponentInfo>
    );
  };

  /**
   *  Render a cell with an OnClick / OnHover popup showing a custom template
   *
   * @param vessel    The vessel object
   * @param template  A custom template to render the popup monolog
   * @param value     Value to display in datagrid
   * @returns {*}     Returns a datagrid template to render the cell
   */
  renderCellTemplate = (vessel, template, value) => {
    if (!vessel) {
      return null;
    }
    return (
      <ComponentInfo
        modalTargetId={"popupContainer"}
        buttonStyle={this.buttonStyle}
        template={<DevOpsDetailedCellView template={template} />}
      >
        {value || " "}
      </ComponentInfo>
    );
  };

  componentDidMount() {
    this.props.fetchMinimumProcessorBuild();
    this.props.fetchVesselStatuses(this.props.selectedCompanyId);
    this.props.fetchApplicationVersions();
    this.props.fetchCompanies();
    this.interval = setInterval(() => {
      this.setState({ secondsCounter: this.state.secondsCounter + 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidUpdate() {
    const { isLoading, isVersionListLoading, isUpdating } = this.props.list;
    if (
      this.state.secondsCounter > refreshInterval &&
      !isLoading &&
      !isVersionListLoading &&
      !isUpdating
    ) {
      this.props.fetchVesselStatuses(this.props.selectedCompanyId);
      this.props.fetchApplicationVersions();
      this.setState({ secondsCounter: 0 });
    }
  }

  /**
   *
   * @param status
   * @param value             cell value
   * @param defaultClassname  default classname to use when format.value is null
   * @returns                 classname used to display the cell
   */
  getClassName = (status, value, defaultClassname = undefined) => {
    if (status.isDisabled) {
      return styles.disabledBackground;
    }
    const getOkBackgroundOrDefault = () =>
      value !== null ? styles.okBackground : defaultClassname;
    const getOkBackgroundOrUndefined = () =>
      value !== undefined ? getOkBackgroundOrDefault() : undefined;
    if (status.isWarning !== undefined) {
      const background = status.isWarning
        ? styles.warningBackground
        : getOkBackgroundOrUndefined();
      return status.isError ? styles.errorBackground : background;
    } else {
      return status.isError
        ? styles.errorBackground
        : getOkBackgroundOrUndefined();
    }
  };

  onCompanySelect(company) {
    this.props.setSelectedCompanyForDevOps(company.id);
    this.props.fetchVesselStatuses(company.id);
    this.setState({ secondsCounter: 0 });
  }
  render() {
    const { companies, selectedCompanyId, showVesselInsightVessels } =
      this.props;
    const selectOptions = [{ id: null, name: "All" }].concat(companies.data);
    return (
      <div>
        <Header title="Admin -  DevOps" contentDistribution="start">
          <SelectBox
            options={selectOptions}
            optionValKey={"id"}
            optionLabelKey={"name"}
            onSelect={this.onCompanySelect}
            selected={selectOptions.find((c) => c.id === selectedCompanyId)}
          />

          <div className={devOpsStyles.headerCheckbox}>
            <Checkbox
              label={"Show Vessel Insight vessels"}
              checked={showVesselInsightVessels}
              onChange={this.props.onToggleVesselInsightVessels}
            />
          </div>
          <div className={devOpsStyles.headerTimer}>
            <Timer
              prefix={"Updated"}
              postfix={"seconds ago"}
              seconds={this.state.secondsCounter}
            />
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
      </div>
    );
  }

  renderDataGrid() {
    const { isLoading, isVersionListLoading, vessels, error } = this.props.list;
    const { sortBy, sortDirection, sortVessels: onSort } = this.props;

    if (isLoading || isVersionListLoading) {
      return <Loader error={error} />;
    }
    return (
      <DataGrid
        data={vessels}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
        {...this.gridConfig}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { devOps } = state.admin;
  const { vessels, showVesselInsightVessels, minimumProcessorBuild } = devOps;

  return {
    companies: state.admin.companies,
    list: {
      ...devOps,
      vessels: showVesselInsightVessels
        ? vessels
        : vessels.filter((x) => !x.isVesselInsight),
    },
    sortBy: devOps.sortBy,
    sortDirection: devOps.sortDirection,
    selectedCompanyId: devOps.selectedCompanyId,
    showVesselInsightVessels,
    minimumProcessorBuild,
  };
};

const mapActionsToProps = {
  fetchApplicationVersions,
  fetchVesselStatuses,
  sortVessels,
  fetchCompanies,
  setSelectedCompanyForDevOps,
  onToggleVesselInsightVessels,
  fetchMinimumProcessorBuild,
};

export default connect(mapStateToProps, mapActionsToProps)(DevOps);
