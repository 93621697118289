import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import styles from "./Grid.css";
import GridCell from "./GridCell";

export default class Grid extends PureComponent {
  render() {
    const {
      children,
      templateColumn,
      templateRow,
      columnCount,
      rowCount,
      rowWeights,
      height,
    } = this.props;
    const style = {};
    const repeat = (count) => {
      return Array.from({ length: count })
        .map(() => "1fr")
        .join(" ");
    };

    if (templateColumn) {
      style.gridTemplateColumns = templateColumn;
      style.msGridColumns = templateColumn;
    } else if (columnCount) {
      const templateColumns = repeat(columnCount);
      style.gridTemplateColumns = templateColumns;
      style.msGridColumns = templateColumns;
    }

    if (templateRow) {
      style.gridTemplateRows = templateRow;
      style.msGridRows = templateRow;
    } else if (rowWeights) {
      const templateRows = rowWeights.map((w) => `${w}fr`).join(" ");
      style.gridTemplateRows = templateRows;
      style.msGridRows = templateRows;
    } else if (rowCount) {
      const templateRows = repeat(rowCount);
      style.gridTemplateRows = templateRows;
      style.msGridRows = templateRows;
    }
    if (height) {
      style.height = height;
    }
    return (
      <div style={style} className={styles.gridContainer}>
        {children}
      </div>
    );
  }
}

Grid.defaultProps = {
  gap: "inherit",
  height: "100vh",
};

Grid.propTypes = {
  columnCount: PropTypes.number,
  rowCount: PropTypes.number,
  height: PropTypes.string,
  rowWeights: PropTypes.arrayOf(PropTypes.number),
  templateColumn: PropTypes.string,
  templateRow: PropTypes.string,
  children: function (props, propName, componentName) {
    const prop = props[propName];
    let error = null;
    React.Children.forEach(prop, function (child) {
      if (child.type !== GridCell) {
        error = new Error(
          "`" + componentName + "` children should be of type `GridCell`."
        );
      }
    });
    return error;
  },
};
