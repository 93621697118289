import PropTypes from "prop-types";
import React from "react";
import styles from "./TrendCategoryPane.css";
import TrendCategoryItem from "./TrendCategoryItem/TrendCategoryItem";
import TrendCategoryItemType from "./TrendCategoryItemType/TrendCategoryItemType";
import Button from "../../../common/components/Button";
import { Icon } from "../../../common/components/Icon/Icon";

export default class TrendCategoryPane extends React.PureComponent {
  renderVesselItemType(vesselItemType) {
    return (
      <TrendCategoryItemType
        key={vesselItemType.title}
        onToggleVesselItem={this.props.onToggleVesselItem}
        onToggleMetric={this.props.onToggleMetric}
        vesselItemType={vesselItemType}
        showItems={hasSelectedItems(vesselItemType.itemTypes)}
        vesselId={this.props.vesselId}
        isDeselectAllActivated={this.props.isDeselectAllActivated}
        disableDeselectAllButton={this.props.disableDeselectAllButton}
      />
    );
  }

  render() {
    const {
      vesselItems,
      performanceIndicators,
      showOperations,
      onTogglePerformanceIndicator,
      onTogglePerformanceIndicators,
      onToggleOperations,
      onClearVesselItems,
      disabled,
    } = this.props;

    return (
      <div className={styles.trendCategoryPaneContainer}>
        <div
          className={styles.trendItemsContainer}
          data-test-id="trend-operationsContainer"
        >
          <TrendCategoryItem
            key={"toggleOperations"}
            id={"toggleOperations"}
            name={"Operations"}
            isActive={showOperations}
            onToggle={() => onToggleOperations()}
          />
          {vesselItems.map((vi) => this.renderVesselItemType(vi))}
        </div>
        {performanceIndicators.isVisible && (
          <div
            className={styles.performanceItemsContainer}
            data-test-id="trend-performanceIndicators"
          >
            <TrendCategoryItem
              name="Performance indicators"
              isActive={performanceIndicators.isActive}
              items={performanceIndicators.entries}
              onToggle={onTogglePerformanceIndicators}
              onToggleItem={onTogglePerformanceIndicator}
              fullHeight={performanceIndicators.entries.length < 3}
              showItems
            />
          </div>
        )}
        <div className={styles.deselectAllButton}>
          <Button
            type="content"
            disabled={disabled}
            clickAction={() => onClearVesselItems()}
          >
            <span>Deselect all</span>
            <Icon icon={"removeIcon"} className={styles.removeIcon}></Icon>
          </Button>
        </div>
      </div>
    );
  }
}

export const hasSelectedItems = (items) => {
  if (items.some((x) => x.isActive || x.metrics?.some((m) => m.isActive))) {
    return true;
  }

  let i = 0;
  let found = false;
  while (i < items.length && !found) {
    let item = items[i];
    if (item.items && item.items.length) {
      found = hasSelectedItems(item.items);
    }
    i++;
  }
  return found;
};

TrendCategoryPane.propTypes = {
  vesselItems: PropTypes.array.isRequired,
  performanceIndicators: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    entries: PropTypes.array.isRequired,
  }).isRequired,
  onToggleVesselItem: PropTypes.func.isRequired,
  onToggleMetric: PropTypes.func.isRequired,
  onTogglePerformanceIndicators: PropTypes.func.isRequired,
  onTogglePerformanceIndicator: PropTypes.func.isRequired,
  showOperations: PropTypes.bool.isRequired,
  onToggleOperations: PropTypes.func.isRequired,
  onClearVesselItems: PropTypes.func.isRequired,
  vesselId: PropTypes.string.isRequired,
  isDeselectAllActivated: PropTypes.bool.isRequired,
  disableDeselectAllButton: PropTypes.func.isRequired,
};
