import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { noop, get, matchesProperty } from "lodash";
import {
  editVesselOperation,
  inputChanged,
  saveVesselOperation,
  cancelEditVesselOperation,
  newVesselOperation,
  deleteVesselOperation,
  cancelDeleteVesselOperation,
  confirmDeleteVesselOperation,
} from "../../../../actions/admin/action.vesselconfig.vesselOperations";
import Loader from "../../../../../common/components/Loader";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import DataTable from "../../../../components/DataTable/DataTable";
import styles from "./VesselOperationsController.css";
import VesselOperationForm from "./VesselOperationForm";
import {
  getColumns,
  getShowMinMaxEngines,
  getShowMaxTension,
} from "../../../../reducers/admin/vesselConfig/reducer.vesselOperations";

class VesselOperationsController extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
  }

  editTemplate = ({ operationName, form }, rowId) => {
    const title = operationName ? "Edit " + operationName : "New operation";
    const { vesselConfigOperations } = this.props;
    const selectedOperations = get(vesselConfigOperations, "data", []);
    const selectedOperation = get(
      selectedOperations.find((sp) => sp.id === rowId),
      "operationId",
      {}
    );
    const operations = get(vesselConfigOperations, "operations", []).filter(
      (p) =>
        !selectedOperations.some(matchesProperty("operationId", p.id)) ||
        selectedOperation === p.id
    );
    const vesselId = this.props.vesselConfigOperations.vesselId;
    const {
      showMinMaxEngines,
      showMaxTension,
      inputChanged,
      saveVesselOperation,
      cancelEditVesselOperation,
      deleteVesselOperation,
      cancelDeleteVesselOperation,
      confirmDeleteVesselOperation,
    } = this.props;
    return (
      <VesselOperationForm
        operations={operations}
        title={title}
        form={form}
        rowId={rowId}
        vesselId={vesselId}
        showMinMaxEngines={showMinMaxEngines}
        showMaxTension={showMaxTension}
        inputChanged={inputChanged}
        saveAction={saveVesselOperation}
        cancelAction={cancelEditVesselOperation}
        deleteAction={deleteVesselOperation}
        cancelDeleteAction={cancelDeleteVesselOperation}
        confirmDeleteAction={confirmDeleteVesselOperation}
      />
    );
  };

  onEdit = (row) => {
    this.props.editVesselOperation(row);
  };

  renderContent() {
    const { isLoading, data, error } = this.props.vesselConfigOperations;
    if (isLoading || error) {
      return (
        <div className={styles.loaderContainer}>
          <Loader expand={true} error={error} />
        </div>
      );
    }
    return (
      <div className={styles.gridContainer}>
        <DataTable
          testId={"datatable"}
          data={data}
          columns={this.props.columns}
          canEdit={true}
          onEdit={this.onEdit}
          editTemplate={this.editTemplate}
        />
      </div>
    );
  }

  render() {
    const { isOpen, enabled, onToggleClick, isCreating } = this.props;

    const buttons = [
      {
        label: "New",
        action: this.props.newVesselOperation,
        disabled: !enabled || isCreating,
        isVisible: isOpen,
      },
    ];

    return (
      <CollapsePanel
        title={"Operations"}
        subTitle={"Configure available operations"}
        isOpen={isOpen}
        testId={"vesselOperationsController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
        buttons={buttons}
      >
        {this.renderContent()}
      </CollapsePanel>
    );
  }
}

VesselOperationsController.defaultProps = {
  isOpen: false,
  enabled: false,
  onToggleClick: noop,
};

VesselOperationsController.propTypes = {
  isOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

const mapStateToProps = (state) => {
  const data = get(state, "admin.vesselConfigOperations.data", []);
  const showMinMaxEngines = getShowMinMaxEngines(data);
  const showMaxTension = getShowMaxTension(data);
  return {
    vesselConfigOperations: state.admin.vesselConfigOperations,
    operations: state.admin.vesselConfigOperations.operations,
    isCreating: data.some((x) => x.id === undefined),
    showMinMaxEngines,
    showMaxTension,
    columns: getColumns(showMinMaxEngines, showMaxTension),
  };
};

const mapDispatchToProps = {
  editVesselOperation,
  inputChanged,
  saveVesselOperation,
  cancelEditVesselOperation,
  newVesselOperation,
  deleteVesselOperation,
  cancelDeleteVesselOperation,
  confirmDeleteVesselOperation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselOperationsController);
