import React, { Component } from "react";
import PropTypes from "prop-types";
import Popup from "../../Popup/Popup";
import Button from "../../../../common/components/Button/Button";
import Loader from "../../../../common/components/Loader/Loader";
import styles from "./FormPopup.css";

class FormPopup extends Component {
  componentDidMount() {
    if (!this.props.isLoading) {
      this.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isLoading &&
      Boolean(this.props.isLoading) !== Boolean(prevProps.isLoading)
    ) {
      this.focus();
    }
  }

  focus() {
    if (this.contentEl) {
      const input = this.contentEl.querySelector(
        "input:not([type=hidden]), textarea, select"
      );
      if (input) {
        input.focus();
        if (input.type === "text") {
          input.setSelectionRange(input.value.length, input.value.length);
        }
      }
    }
  }

  render() {
    const {
      title,
      visible,
      isSaving,
      isLoading,
      onHide,
      onSave,
      onCancel,
      children,
      enableSave,
      enableCancel,
      saveButtonText,
      cancelButtonText,
      saveButtonBorderColor,
      cancelButtonBorderColor,
      additionalButtons,
      testId,
    } = this.props;
    if (isSaving || isLoading) {
      return (
        <div className={styles.loaderContainer} data-testid={testId}>
          <Loader text={isSaving ? "Saving" : "Loading"} />
        </div>
      );
    }
    return (
      <Popup title={title} visible={visible} onHide={onHide} testId={testId}>
        <div
          className={styles.contentContainer}
          ref={(el) => (this.contentEl = el)}
        >
          {children}
        </div>
        <div className={styles.actionContainer}>
          {additionalButtons
            .filter((b) => b.visible)
            .map((b, i) => (
              <Button
                key={`button_${i}`}
                value={b.label}
                disabled={b.disabled}
                clickAction={b.action}
                borderColor={b.borderColor}
              />
            ))}
          <Button
            key={"save_button"}
            value={saveButtonText}
            clickAction={onSave}
            borderColor={saveButtonBorderColor}
            disabled={!enableSave}
            testId={"saveButton"}
          />
          <Button
            key={"cancel_button"}
            value={cancelButtonText}
            clickAction={onCancel}
            borderColor={cancelButtonBorderColor}
            disabled={!enableCancel}
            testId={"cancelButton"}
          />
        </div>
      </Popup>
    );
  }
}

FormPopup.defaultProps = {
  saveButtonText: "Save",
  cancelButtonText: "Cancel",
  enableCancel: true,
  additionalButtons: [],
};

FormPopup.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  enableSave: PropTypes.bool,
  enableCancel: PropTypes.bool,
  isSaving: PropTypes.bool,
  isLoading: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  saveButtonBorderColor: PropTypes.string,
  cancelButtonBorderColor: PropTypes.string,
  additionalButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      borderColor: PropTypes.string,
      visible: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
  testId: PropTypes.string,
};

export default FormPopup;
