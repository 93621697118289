import React from "react";
import PropTypes from "prop-types";
import styles from "./LabelValue.css";

const LabelValue = ({ label, value, unit, style, direction, size }) => {
  return (
    <div
      data-direction={direction}
      data-size={size}
      className={styles.labelValueContainer}
      style={style}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.valueContainer}>
        <div className={styles.value}>{value}</div>
        {unit && <div className={styles.unit}>{unit}</div>}
      </div>
    </div>
  );
};

LabelValue.defaultProps = {
  direction: "horizontal",
  size: "medium",
};

LabelValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  unit: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(["medium", "large"]),
  direction: PropTypes.oneOf(["horizontal", "vertical", "verticalCentered"]),
};

export default LabelValue;
