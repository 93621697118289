import createReducer from "./createReducer";
import { find } from "lodash";
import moment from "moment";
import {
  FETCH_VESSEL_STATUS_HISTORY,
  FETCH_VESSEL_STATUS_HISTORY_SUCCESS,
  FETCH_VESSEL_STATUS_HISTORY_ERROR,
  SET_VESSEL_STATUS_HISTORY_SELECTED_DAY,
} from "../actions/action.types";

const initialState = {
  showDay: false,
  selectedDay: null,
  days: [],
  isLoading: false,
  hasError: false,
};

export default createReducer(initialState, {
  [FETCH_VESSEL_STATUS_HISTORY]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FETCH_VESSEL_STATUS_HISTORY_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      data,
      isLoading: false,
      showDay: false,
      selectedDay: null,
    };
  },
  [FETCH_VESSEL_STATUS_HISTORY_ERROR]: (state) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  },
  [SET_VESSEL_STATUS_HISTORY_SELECTED_DAY]: (state, { selectedDay }) => {
    return {
      ...state,
      showDay: true,
      selectedDay: selectedDay,
    };
  },
});

/*
 Populate all the days in the selected range (backend only returns days with values)
*/

export const populateDays = (sourceDays, { range }) => {
  let start = moment.utc(range.from);
  let end = moment.utc(range.to);
  let days = [];
  for (let m = moment(start); m.isBefore(end); m.add(1, "days")) {
    const day = find(sourceDays, { day: m.toISOString() });
    days.push({
      date: m.format("YYYY-MM-DD"),
      secondCount: day ? day.secondCount : null,
    });
  }
  return days;
};
