import React, { Component } from "react";
import { ITEM_TYPE_METRICS } from "../../../../../config";
import { get } from "lodash";
import FormSelectBox from "./../../../../../components/Form/FormSelectBox/FormSelectBox";

class MonthlyFuelUsageEditForm extends Component {
  render() {
    const { params, onChange } = this.props;
    const itemTypeMetricId = get(params, "itemTypeMetricId", "");
    const itemTypeMetrics = ITEM_TYPE_METRICS.filter(
      (m) => m.label !== "Run"
    ).map((itm) => {
      return {
        id: itm.name,
        name: itm.label,
      };
    });
    const selected = itemTypeMetrics.find((itm) => itm.id === itemTypeMetricId);
    return (
      <FormSelectBox
        name="itemTypeMetricId"
        label="Item Type Metric"
        selected={selected}
        options={itemTypeMetrics}
        optionValKey="id"
        optionLabelKey="name"
        onChange={onChange}
      />
    );
  }
}

export default MonthlyFuelUsageEditForm;
