import { SET_MAP_VIEW_DATA } from "./action.types";
import { fetchLocationsInArea } from "./action.fetchLocationsInArea";

export const setMapViewData = (data) => (dispatch) => {
  if (data.marginBounds && data.zoom >= 9) {
    dispatch(fetchLocationsInArea(data.marginBounds.nw, data.marginBounds.se));
  }
  dispatch({
    type: SET_MAP_VIEW_DATA,
    data,
  });
};
