import styles from "./Icon.css";
import React from "react";
import { IconProps } from "./Types";

const Icon: React.FC<IconProps> = ({ title, color }: IconProps) => {
  function getColorComponents(colorString: string): number[] {
    const tempElement = document.createElement("div");
    tempElement.style.color = colorString;
    document.body.appendChild(tempElement);
    const computedStyle = getComputedStyle(tempElement);
    const color = computedStyle.color; // The computed color value
    document.body.removeChild(tempElement);

    // Extract the RGB or HSL values from the color string
    const regex = /(\d+(\.\d+)?)/g;
    return color.match(regex)?.map(Number) || [];
  }

  function getStyle(alpha: number): React.CSSProperties {
    const components = getColorComponents(color);
    return {
      backgroundColor: `rgba(${components[0]}, ${components[1]}, ${components[2]}, ${alpha})`,
    };
  }

  return (
    <div className={styles.circlesWrapper} data-test-id={`${title}-icon`}>
      <div className={styles.circle} style={getStyle(0.2)}></div>
      <div className={styles.circle} style={getStyle(0.4)}></div>
      <div className={styles.circle} style={getStyle(1)}>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default Icon;
