import React, { Component } from "react";
import { connect } from "react-redux";
import { isDateWithinRange } from "../../common/dateFilter";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import CalendarHeatmap from "react-calendar-heatmap";
import { Header } from "../../components/Header";
import Loader from "../../../common/components/Loader/Loader";
import styles from "./ServiceHistory.css";
import "./CalendarHeatmap.css";
import {
  fetchVesselStatusHistoryData,
  setVesselStatusHistorySelectedDay,
} from "../../actions/action.vesselStatusHistory";
import { atStartOfDay, isAfter, today, from } from "../../common/dates";
import { dataQuality } from "../../../common/boundaries";
import { createSelector } from "reselect";
import {
  convertFiltersToQueryParams,
  getGroupFilter,
  getVesselFilter,
  getDateFilter,
  getLimitsForVesselInQuery,
} from "../../selectors/filters";
import { VIEWS } from "../../config";
import { isEqual } from "lodash";
import { populateDays } from "../../reducers/reducer.vesselStatusHistory";
import { getTimeOffsetFormat } from "../../reducers/datePicker";

class ServiceHistory extends Component {
  constructor(props) {
    super(props);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
  }

  componentDidMount() {
    const { filters } = this.props;
    if (!filters.isValid) {
      this.props.queryContext.navigate(
        VIEWS.servicehistory.url,
        convertFiltersToQueryParams(filters),
        true
      );
    } else {
      this.props.fetchVesselStatusHistoryData(
        filters.vesselFilter.value,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;
    if (filters.isValid && !isEqual(filters, prevProps.filters)) {
      this.props.fetchVesselStatusHistoryData(
        filters.vesselFilter.value,
        filters.dateFilter.value.range,
        parseInt(filters.dateFilter.value.timeOffset)
      );
    }
  }

  handleYearChange(year) {
    this.props.queryContext.setDate(year);
  }

  onDayClick(day) {
    if (day && day.selectable) {
      this.props.setVesselStatusHistorySelectedDay(day);
    }
  }

  styleDays(days, vesselDateLimits, selectedDay) {
    return days.map((day) => {
      return this.styleDay(day, vesselDateLimits, selectedDay);
    });
  }

  styleDay(day, vesselDateLimits, selectedDay) {
    const vesselFrom = vesselDateLimits.from;
    const vesselTo = vesselDateLimits.to;

    const selectedDayClass =
      selectedDay && selectedDay.date === day.date ? "selected" : "";

    /*
    Range limit (unclickable greyed out cells)
      - exclude future dates
      - exclude dates outwith Vessel.StartTime and Vessel.EndTime
    */

    if (
      day.date &&
      (isAfter(atStartOfDay(new Date(day.date)), today()) ||
        !isDateWithinRange(new Date(day.date), {
          from: from(vesselFrom),
          to: from(vesselTo ?? today()),
        }))
    ) {
      return {
        ...day,
        cellStyles: `color-empty`,
      };
    }

    /*
      Style and return data for dates within range
    */

    if (!day) {
      return {
        ...day,
        cellStyles: `color-worst selectable ${selectedDayClass}`,
        selectable: true,
      };
    }

    if (day.secondCount >= dataQuality.secondCount.BEST) {
      return {
        ...day,
        cellStyles: `color-best selectable ${selectedDayClass}`,
        selectable: true,
      };
    } else if (day.secondCount >= dataQuality.secondCount.GOOD) {
      return {
        ...day,
        cellStyles: `color-good selectable ${selectedDayClass}`,
        selectable: true,
      };
    } else if (day.secondCount >= dataQuality.secondCount.OKAY) {
      return {
        ...day,
        cellStyles: `color-okay selectable ${selectedDayClass}`,
        selectable: true,
      };
    } else {
      return {
        ...day,
        cellStyles: `color-bad selectable ${selectedDayClass}`,
        selectable: true,
      };
    }
  }

  renderDayDetails() {
    const { selectedDay, showDay, filters } = this.props;
    const timeOffset = parseInt(filters.dateFilter.value.timeOffset);
    if (!showDay) {
      return <div />;
    }
    return (
      <div className={styles.dayDetailsContainer}>
        <h1>{selectedDay.date + " " + getTimeOffsetFormat(timeOffset)}</h1>
        <div>
          <span>Total SecondCount (Hull Data):</span>
          &nbsp;<span>{selectedDay.secondCount}</span>
        </div>
      </div>
    );
  }

  renderCalendarHeatmap() {
    const {
      days,
      isLoading,
      hasError,
      vesselDateLimits,
      selectedDay,
      filters,
    } = this.props;
    if (isLoading || hasError || !days) {
      return (
        <div className={styles.loader}>
          <Loader error={hasError} text={"Loading"} />
        </div>
      );
    }
    const styledDays = this.styleDays(days, vesselDateLimits, selectedDay);
    const startDate = new Date(filters.dateFilter.value.range.from);
    const endDate = new Date(filters.dateFilter.value.range.to);

    // In some cases CalenderHeatmap skips the first day of the year (even if we work with UTC dates).
    // To avoid this, we convert to local date and remove the time zone difference.
    return (
      <div>
        <div className={styles.calendarHeatmapContainer}>
          <CalendarHeatmap
            startDate={
              startDate.getTime() +
              startDate.getTimezoneOffset() * 60 * 1000 -
              1
            }
            endDate={
              endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000
            }
            showOutOfRangeDays={true}
            values={styledDays}
            onClick={this.onDayClick}
            classForValue={(value) => {
              if (!value) {
                return "color-hidden";
              } else {
                return value.cellStyles;
              }
            }}
          />
        </div>
        {this.renderDayDetails()}
      </div>
    );
  }

  render() {
    const { vesselDateLimits, filters } = this.props;
    if (!filters.isValid) {
      return <div />;
    }
    return (
      <div>
        <Header title="Service history" contentDistribution="space-between">
          <VesselSelector />
          <div className={styles.rightHeaderContainer}>
            <DatePickerContainer
              current={filters.dateFilter.value}
              limits={vesselDateLimits}
              vesselId={filters.vesselFilter.value}
              onChange={this.handleYearChange}
              supportedTypes={["year", "timeOffset"]}
            />
          </div>
        </Header>
        {this.renderCalendarHeatmap()}
      </div>
    );
  }
}

const getServiceHistoryFilters = createSelector(
  [getVesselFilter, getGroupFilter, getDateFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid:
        vesselFilter.isValid && groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const filters = getServiceHistoryFilters(state, props);
  return {
    filters,
    vesselStatusHistory: state.vesselStatusHistory,
    vesselDateLimits: getLimitsForVesselInQuery(state, props),
    showDay: state.vesselStatusHistory.showDay,
    selectedDay: state.vesselStatusHistory.selectedDay,
    isLoading: state.vesselStatusHistory.isLoading,
    hasError: state.vesselStatusHistory.hasError,
    days:
      filters.dateFilter.isValid &&
      populateDays(state.vesselStatusHistory.data, filters.dateFilter.value),
  };
};

const mapDispatchToProps = {
  fetchVesselStatusHistoryData,
  setVesselStatusHistorySelectedDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHistory);
