import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { color } from "../../colors";
import { COSMETIC_CONSTS } from "../../../onshore/config";
import { SparklineContext } from "./Sparkline";
import { isArray } from "lodash";
import { getTimeOffsetFormat } from "../../../onshore/reducers/datePicker";
import {
  formatHour,
  formatWeek,
  getShortMonthName,
  from,
  getDayOfWeek,
} from "../../../onshore/common/dates";
import { HOUR, MONTH, WEEK } from "../../../onshore/common/timeUnits";

const formatValue = (value, tickFormat, aggregation, endDate) => {
  if (aggregation === HOUR) {
    value = formatHour(from(value));
  } else if (aggregation === WEEK) {
    value = endDate
      ? formatWeek(from(value), endDate)
      : formatWeek(from(value));
  } else if (aggregation === MONTH) {
    value = getShortMonthName(value);
  }
  if (tickFormat) return tickFormat(value);
  return value;
};

class SparklineXAxis extends React.PureComponent {
  componentDidMount() {
    this.props.addMarginProvider(this);
  }

  getMargins() {
    const bb = this.containerElement.getBoundingClientRect();
    return {
      bottom: bb.height,
    };
  }

  componentWillUnmount() {
    this.props.removeMarginProvider(this);
  }

  getXTicks() {
    const {
      ticks,
      xScaleType,
      useUniqueValuesForBand,
      uniqueXValues,
      xDomain,
    } = this.props;

    if (isArray(ticks)) {
      return ticks;
    }
    if (xScaleType === "band") {
      return useUniqueValuesForBand
        ? uniqueXValues
        : d3.ticks(xDomain[0], xDomain[xDomain.length - 1], ticks);
    }
    return d3.ticks(xDomain[0], xDomain[xDomain.length - 1], ticks);
  }

  getStyles() {
    const lineStyle = {
      fill: "none",
      strokeWidth: 2,
      stroke: color("--light-grey"),
    };

    const tickStyle = {
      fill: "none",
      strokeWidth: 2,
      stroke: color("--light-grey"),
      shapeRendering: "crispEdges",
    };

    const textStyle = {
      fill: color("--light-grey"),
      stroke: "none",
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 12,
      textAnchor: "middle",
      alignmentBaseline: "central",
    };

    const labelStyle = {
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 12,
      textAnchor: "end",
      alignmentBaseline: "central",
      fill: color("--light-grey"),
    };

    const timeOffsetStyle = {
      fill: color("--light-grey"),
      fontFamily: COSMETIC_CONSTS.fontFamily,
      fontSize: 14,
    };

    return { lineStyle, tickStyle, textStyle, labelStyle, timeOffsetStyle };
  }

  render() {
    const {
      height,
      width,
      xOffset,
      scaleX,
      label,
      showTickValues,
      tickFormat,
      xDataType,
      timeOffset,
      aggregation,
      endDate,
    } = this.props;

    const xTicks = this.getXTicks();
    const { lineStyle, tickStyle, textStyle, labelStyle, timeOffsetStyle } =
      this.getStyles();
    const additionalHeight = 2;
    const rotation =
      xDataType === "DateTime" || xDataType === "Date"
        ? 8
        : this.props.rotation;

    return (
      <g>
        <text style={timeOffsetStyle}>{getTimeOffsetFormat(timeOffset)}</text>
        <line
          style={lineStyle}
          x1={0}
          y1={height + additionalHeight}
          x2={width}
          y2={height + additionalHeight}
        />
        <g
          transform={`translate(${xOffset} 0)`}
          ref={(el) => (this.containerElement = el)}
        >
          {xTicks.map((t) => (
            <line
              style={tickStyle}
              key={`xLine${t}`}
              x1={scaleX(t)}
              y1={height + additionalHeight}
              x2={scaleX(t)}
              y2={height + additionalHeight + 3}
            />
          ))}
          {showTickValues &&
            xTicks.map((t, i) => {
              let transform;
              let style = textStyle;
              transform = `translate(${scaleX(t)}, ${
                height + 12 - (rotation > 0 ? additionalHeight : 0)
              }) rotate(${rotation})`;
              style = {
                ...textStyle,
                textAnchor: rotation > 0 ? "start" : "middle",
              };
              return (
                <text
                  style={style}
                  key={`xAxisLabel${t}`}
                  transform={transform}
                >
                  {i === xTicks.length - 1 &&
                  endDate &&
                  getDayOfWeek(from(endDate)) !== 7
                    ? formatValue(t, tickFormat, aggregation, endDate)
                    : formatValue(t, tickFormat, aggregation)}
                </text>
              );
            })}
        </g>
        {label && (
          <text style={labelStyle} x={width} y={height - 10} textAnchor="end">
            {/*TODO: Moved label temporarily. Should be parameterized, instead.*/}
            {label}
          </text>
        )}
      </g>
    );
  }
}

SparklineXAxis.defaultProps = {
  ticks: 6,
  showTickValues: true,
  type: "SparklineXAxis",
  rotation: 0,
};

SparklineXAxis.propTypes = {
  ticks: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  label: PropTypes.string,
  showTickValues: PropTypes.bool,
  tickFormat: PropTypes.func,
  rotation: PropTypes.number,
};

export default React.forwardRef((props, ref) => (
  <SparklineContext.Consumer>
    {(context) => <SparklineXAxis {...context} {...props} ref={ref} />}
  </SparklineContext.Consumer>
));
