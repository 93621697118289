import "core-js/stable";
import "regenerator-runtime/runtime";
import "../style/css/Reset.css";
import "../style/css/Global.css";
import React, { createContext, useContext } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import createStore from "./store";
import { initServices } from "./services";
import { RoutesOnshore } from "./RoutesOnshore";
import { QueryContextProvider } from "./providers/QueryContextProvider";
import { getAppConfigFromDocument } from "../../config/onshore/environments/AppConfig";
import { createRootContext } from "./RootContext";
import { setupMocking } from "./setupMocking";

const appConfig = getAppConfigFromDocument();
const services = initServices(appConfig);
const store = createStore(appConfig, services);
const rootContextObject = createRootContext(
  services,
  appConfig,
  store.dispatch
);
export const RootContext = createContext(rootContextObject);

const root = createRoot(document.getElementById("app") as Element);

setupMocking(appConfig.MOCK_ENABLED === "true").then(() => {
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryContextProvider>
          <RootContext.Provider value={rootContextObject}>
            <RoutesOnshore />
          </RootContext.Provider>
        </QueryContextProvider>
      </BrowserRouter>
    </Provider>
  );
});

export const useRootContext = () => useContext(RootContext);
