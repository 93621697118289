import React from "react";
import { FormPopup } from "../../../components/Form";
import { connect } from "react-redux";
import {
  cancelUserPasswordReset,
  confirmUserPasswordReset,
} from "../../../actions/admin/action.users";

class UserPasswordResetPopup extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let { showDialog, confirmed, sending, completed, user, success } =
      this.props.passwordReset;
    let enableSave =
      (confirmed !== true && sending !== true) || completed === true;
    let enableCancel = confirmed !== true && sending !== true;
    if (!showDialog) {
      return <div />;
    }
    return (
      <FormPopup
        title={"Send password reset"}
        saveButtonText={"Ok"}
        onSave={() => {
          if (completed === true) {
            this.props.cancelUserPasswordReset();
          } else {
            this.props.confirmUserPasswordReset();
          }
        }}
        onCancel={() => {
          this.props.cancelUserPasswordReset();
        }}
        onHide={() => {
          this.props.cancelUserPasswordReset();
        }}
        enableSave={enableSave}
        enableCancel={enableCancel}
        isSaving={false}
        visible={showDialog}
      >
        {sending !== true && confirmed !== true && (
          <div>
            Reset password for user <b>{user.email}</b>?
          </div>
        )}
        {sending === true && (
          <div>
            Sending password reset for user <b>{user.email}</b>
          </div>
        )}
        {completed === true && success === true && (
          <div>Password reset sent</div>
        )}
        {completed === true && success === false && (
          <div>Password reset failed to send</div>
        )}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  passwordReset: state.admin.users.passwordReset,
});

const mapDispatchToProps = {
  confirmUserPasswordReset,
  cancelUserPasswordReset,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPasswordResetPopup);
