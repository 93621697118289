import PropTypes from "prop-types";
import React from "react";
import classNames from "../../../common/classNames";
import styles from "./CollapseButtonIcon.css";

export default class CollapseButtonIcon extends React.PureComponent {
  render() {
    const { showItems, height, width } = this.props;
    return (
      <div>
        <svg
          viewBox="0 0 12.7 12.7"
          preserveAspectRatio="xMidYMid meet"
          xmlSpace="preserve"
          height={height}
          width={width}
          display={"flex"}
        >
          <g
            className={classNames(
              styles.rotationGroup,
              showItems && styles.showItems
            )}
          >
            <g transform="translate(0,-284.3)" id="layer1">
              <path
                style={{ stroke: "none", fill: "#ffffff" }}
                d="m 6.370148,286.65348 c -2.1034724,2.10937 -4.2069446,4.21875 -6.31041694,6.32812 0.50866149,0.52046 1.01732294,1.04092 1.52598434,1.56137 1.5948108,-1.5994 3.1896217,-3.1988 4.7844326,-4.7982 1.5948109,1.5994 3.1896218,3.1988 4.784433,4.7982 0.508661,-0.52045 1.017322,-1.04091 1.525983,-1.56137 -2.103472,-2.10937 -4.2069439,-4.21875 -6.310416,-6.32812 z"
              />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

CollapseButtonIcon.propTypes = {
  showItems: PropTypes.bool.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
