import React from "react";
import styles from "../../FuelConsumption/FuelConsumption.css";

const Area = ({ data, width, height }) => {
  const path = (points) => {
    const d = points.map((point) => {
      return point.x + "," + point.y;
    });

    d.push(`${width},${height}`);
    d.push(`0,${height}`);

    return <path d={`M${d.join("L")}Z`} style={{ fill: "url(#gradient)" }} />;
  };

  return (
    <g className={styles.area}>
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#2de3f3" stopOpacity="0.5" />
          <stop offset="100%" stopColor="#2de3f3" stopOpacity="0" />
        </linearGradient>
      </defs>
      {path(data)}
    </g>
  );
};

Area.propTypes = {};

export default Area;
