import fetch from "./action.fetch";
import { FETCH_OPERATION_TREND } from "./action.types";
import { TREND } from "../api";

export const fetchOperationTrend = (vesselId, dateRange, timeOffset, filters) =>
  fetch({
    url: TREND.getOperationTrend(vesselId, dateRange, timeOffset, filters),
    actionType: FETCH_OPERATION_TREND,
    actionData: {
      vesselId,
      dateRange,
      timeOffset,
      filters,
    },
  });
