import { vesselConfig } from "../action.types";

export const inputChanged = (field, value) => ({
  type: vesselConfig.onshoreFrontendConfig.INPUT_CHANGED,
  field,
  value,
});

export const save = (fields) => ({
  type: vesselConfig.onshoreFrontendConfig.SAVE,
  fields,
});
