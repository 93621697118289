import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import classNames from "../../common/classNames";
import VesselFuelConsumption from "../components/VesselFuelConsumption/VesselFuelConsumption";
import styles from "../components/VesselFuelConsumption/VesselFuelConsumption.css";
import { getTimeOffsetFormat } from "../reducers/datePicker";

class VesselFuelConsumptionContainer extends React.Component {
  render() {
    const { isLoading, data, date } = this.props;

    if (isLoading || isEmpty(data)) {
      // We make the fuel consumption block appear to be part of the
      // item table (with the knowledge that fuel consumption data
      // is loaded alongside the item table data). So we don't show a spinner
      // and we don't show anything if there is no data.
      return null;
    }

    const fuelConsumptionType = data.length ? data[0].fuelConsumptionType : "";
    const timeOffset = parseInt(date.timeOffset);

    return (
      <div className={styles.outerContainer}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h2>
              {fuelConsumptionType} fuel consumption (
              {getTimeOffsetFormat(timeOffset)})
            </h2>
          </div>
          {this.renderContent(date)}
        </div>
      </div>
    );
  }

  renderContent(date) {
    const { data, error } = this.props;

    if (error) {
      return (
        <div className={classNames(styles.messageContainer, styles.error)}>
          {error}
        </div>
      );
    }

    return (
      <div className={styles.graphs}>
        <VesselFuelConsumption graphData={data} endDate={date?.range.to} />
      </div>
    );
  }
}

VesselFuelConsumptionContainer.propTypes = {
  date: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

export default connect((state) => state.fetchVesselFuelConsumption)(
  VesselFuelConsumptionContainer
);
