import React from "react";
import PropTypes from "prop-types";
import styles from "./Progress.css";

const Progress = ({ value }) => {
  return (
    <div className={styles.container}>
      <div className={styles.bar} />
      <div className={styles.activeBar} style={{ width: `${value}%` }} />
    </div>
  );
};

Progress.propTypes = {
  value: PropTypes.number.isRequired,
};

Progress.defaultProps = {
  value: 0,
};

export default Progress;
