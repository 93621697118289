import { admin } from "../action.types";

export const fetchVessels = () => ({
  type: admin.dataMaintenance.FETCH_VESSELS,
});

export const fetchVesselSuccess = (data) => ({
  type: admin.dataMaintenance.FETCH_VESSELS_SUCCESS,
  data,
});

export const fetchVesselError = (error) => ({
  type: admin.dataMaintenance.FETCH_VESSELS_ERROR,
  error,
});

export const fetchDataCount = (args) => ({
  type: admin.dataMaintenance.FETCH_DATA_COUNT,
  payload: args,
});

export const fetchDataCountSuccess = (data) => ({
  type: admin.dataMaintenance.FETCH_DATA_COUNT_SUCCESS,
  data,
});

export const fetchDataCountError = (error) => ({
  type: admin.dataMaintenance.FETCH_DATA_COUNT_ERROR,
  error,
});

export const deleteData = (args) => ({
  type: admin.dataMaintenance.DELETE_DATA,
  payload: args,
});

export const deleteDataSuccess = () => ({
  type: admin.dataMaintenance.DELETE_DATA_SUCCESS,
});

export const deleteDataError = (error) => ({
  type: admin.dataMaintenance.DELETE_DATA_ERROR,
  error,
});
