import React, { Component } from "react";
import { PROFILES } from "../../../../../common/config";
import { formatNumber } from "../../../../../common/numbers";
import ReportWidget from "../../../../components/ReportWidget/ReportWidget";
import styles from "./EquinorReport.css";

class EquinorReport extends Component {
  getLegendColor(legend) {
    return PROFILES[legend] && PROFILES[legend].color;
  }

  createReports() {
    const { equinorOperations, dailyOperations } = this.props;

    return [
      this.createReport(
        equinorOperations.rows,
        equinorOperations.average,
        equinorOperations.unit,
        equinorOperations.title
      ),
      this.createReport(
        dailyOperations.rows,
        dailyOperations.average,
        dailyOperations.unit,
        dailyOperations.title
      ),
    ];
  }

  createReport(rows, average, unit, title) {
    return {
      title: title,
      columns: {
        name: {
          value: (x) => x.name,
          className: styles.name,
        },
        legends: {
          value: (x) => (
            <React.Fragment>
              {x.legends &&
                x.legends.map((l, i) => (
                  <span
                    style={{
                      backgroundColor: this.getLegendColor(l),
                    }}
                    key={`legend_${i}`}
                  >
                    {l}
                  </span>
                ))}
            </React.Fragment>
          ),
          className: styles.legends,
        },
        avgFuelConsumption: {
          align: "right",
          value: (x) => (
            <React.Fragment>
              <span data-type={"value"}>
                {formatNumber(x.avgFuelConsumption)}
              </span>
              <span data-type={"unit"}>{unit}</span>
            </React.Fragment>
          ),
        },
      },
      rows,
      footer: () => (
        <React.Fragment>
          <div>Average</div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <span data-type="value">{formatNumber(average)}</span>
            <span data-type="unit">{unit}</span>
          </div>
        </React.Fragment>
      ),
    };
  }

  render() {
    return <ReportWidget reports={this.createReports()} />;
  }
}

export default EquinorReport;
