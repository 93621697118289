import React, { Component } from "react";
import { connect } from "react-redux";
import {
  editItemConfig,
  fetchContextData,
  sortItemTypes,
} from "../../../actions/admin/action.itemConfig";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import styles from "../Admin.css";
import EditItemPopup from "./EditItemPopup";

class ItemConfigView extends Component {
  constructor(props) {
    super(props);
    this.gridConfig = {
      columns: [
        {
          header: "Name",
          field: "name",
          align: "left",
          flexGrow: 2,
        },
        {
          header: "Category",
          field: "category",
          align: "left",
          flexGrow: 2,
        },
        {
          header: "Actions",
          align: "center",
          template: this.renderActions,
        },
      ],
    };
  }

  componentDidMount() {
    this.props.fetchContextData();
  }

  renderActions = (itemType) => {
    return (
      <div>
        {itemType.supportsItemConfig && (
          <Button
            value="Item config"
            clickAction={() =>
              this.props.editItemConfig({ itemTypeId: itemType.id })
            }
            type="link"
          />
        )}
      </div>
    );
  };

  renderDataGrid() {
    const { isLoading, itemTypes, error, sortBy, sortDirection } =
      this.props || {};
    if (!itemTypes || isLoading || error) {
      return <Loader error={error} />;
    }
    return (
      <DataGrid
        data={itemTypes}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={this.props.sortItemTypes}
        {...this.gridConfig}
      />
    );
  }

  render() {
    const { form } = this.props;
    return (
      <div>
        <Header
          title="Admin - Item Config"
          contentDistribution="space-between"
        />
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        {form && <EditItemPopup />}
      </div>
    );
  }
}

const mapStateToProps = (state) => state.admin.itemConfig;

const mapActionsToProps = {
  fetchContextData,
  editItemConfig,
  sortItemTypes,
};

export default connect(mapStateToProps, mapActionsToProps)(ItemConfigView);
