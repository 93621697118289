import React from "react";
import { LogoProps } from "./Types";
import styles from "./Logo.css";
import Icon from "./Icon";
import { createOperationClass } from "../../../common/OperationClass/Factory";
import { createOperationMode } from "../../../common/OperationMode/Factory";
import { truncate } from "../../../common/strings";
import { IOperationClass } from "../../../common/OperationClass/IOperationClass";

const Logo: React.FC<LogoProps> = ({ operationType }: LogoProps) => {
  const operationClass: IOperationClass = createOperationClass(operationType);
  const modesString: string = operationClass
    .getOperationModes()
    .map((mode) => createOperationMode(mode).title)
    .join(", ");
  const titleString = `${operationClass.title} performance`;

  return (
    <div
      className={styles.logoContainer}
      data-test-id={`${operationClass.abbreviation}-logo`}
    >
      <Icon title={operationClass.abbreviation} color={operationClass.color} />
      <div>
        <LogoTitle
          titleString={titleString}
          testId={operationClass.abbreviation}
        />
        <LogoModes
          modesString={modesString}
          titleString={titleString}
          testId={operationClass.abbreviation}
        />
      </div>
    </div>
  );
};

type LogoTitleProps = {
  titleString: string;
  testId: string;
};

const LogoTitle: React.FC<LogoTitleProps> = ({
  titleString,
  testId,
}: LogoTitleProps) => (
  <div className={styles.logoTitle} data-test-id={`${testId}-title`}>
    {titleString}
  </div>
);

type LogoModesProps = {
  modesString: string;
  titleString: string;
  testId: string;
};

const LogoModes: React.FC<LogoModesProps> = ({
  modesString,
  titleString,
  testId,
}: LogoModesProps) => (
  <div className={styles.logoModes} data-test-id={`${testId}-modes`}>
    <span className={styles.displayText}>
      {truncate(modesString, titleString.length * 2.2)}
    </span>
    <span className={styles.tooltipText}>{modesString}</span>
  </div>
);

export default Logo;
