import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEnergyBudgetOverview } from "../actions/action.energyBudget";
import EnergyBudgetOverview from "../components/EnergyBudget/EnergyBudgetOverview";
import Loader from "../../common/components/Loader/Loader";

class EnergyBudgetOverviewContainer extends PureComponent {
  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.config !== prevProps.config) {
      this.fetch();
    }
  }

  fetch() {
    this.props.fetchEnergyBudgetOverview(this.props.config.params);
  }

  render() {
    const { data, error } = this.props.energyBudgetOverview;
    if (!data) {
      return (
        <Loader text="Loading energy budget" expand={true} error={error} />
      );
    }
    return <EnergyBudgetOverview data={data} />;
  }
}

EnergyBudgetOverviewContainer.propTypes = {
  config: PropTypes.shape({
    params: PropTypes.shape({
      metricIds: PropTypes.arrayOf(PropTypes.string),
      groupBy: PropTypes.string,
      historicalCount: PropTypes.number,
      futureCount: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    energyBudgetOverview: state.energyBudgetOverview,
  };
}

const actions = {
  fetchEnergyBudgetOverview,
};

export default connect(mapStateToProps, actions)(EnergyBudgetOverviewContainer);
