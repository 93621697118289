import { takeLeading, call, getContext, put, fork } from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import { downloadFile } from "../../common/downloads";

const actions = vesselConfig.onboardFrontendConfig;
const api = vesselConfigApi.onboardFrontendConfig;

export function* initOnboardFrontendConfig({ vesselId }) {
  yield call(fetchData, vesselId);
  yield fork(downloadWatcher, vesselId);
  yield fork(saveWatcher, vesselId);
  yield fork(deployWatcher, vesselId);
}

function* fetchData(vesselId) {
  const http = yield getContext("http");
  try {
    yield put({
      type: actions.FETCH,
    });
    const res = yield call(http.get, api.get(vesselId));
    yield put({
      type: actions.FETCH_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
    });
  }
}

function* downloadWatcher(vesselId) {
  yield takeLeading(actions.DOWNLOAD, downloadConfig, vesselId);
}

function* downloadConfig(vesselId) {
  const http = yield getContext("http");
  try {
    const res = yield call(http, {
      method: "post",
      url: api.download(vesselId),
      responseType: "arraybuffer",
    });
    yield call(downloadFile, res);
    yield put({ type: actions.DOWNLOAD_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DOWNLOAD_ERROR, error });
  }
}

function* saveWatcher(vesselId) {
  yield takeLeading(actions.SAVE, saveConfig, vesselId);
}

function* saveConfig(vesselId, { config }) {
  try {
    const http = yield getContext("http");
    const payload = {
      frontendConfig: config.frontendConfig,
      vesselImageBase64: config.vesselImageBase64,
      vesselImageUrl: config.vesselImageUrl,
    };
    const res = yield call(http.post, api.post(vesselId), payload);
    yield put({ type: actions.SAVE_SUCCESS, data: res.data });
  } catch (err) {
    yield put({ type: actions.SAVE_ERROR, error: err });
  }
}

function* deployWatcher(vesselId) {
  yield takeLeading(actions.DEPLOY, deployConfig, vesselId);
}

function* deployConfig(vesselId) {
  try {
    const http = yield getContext("http");
    const res = yield call(http.post, api.deploy(vesselId));
    yield put({ type: actions.DEPLOY_SUCCESS, data: res.data });
  } catch (err) {
    yield put({ type: actions.DEPLOY_ERROR, error: err });
  }
}
