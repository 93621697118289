import t from "prop-types";
import React from "react";
import styles from "./MetricsList.css";
import MetricsListItem from "./MetricsListItem/MetricsListItem";
import { noop, sortBy } from "lodash/fp";
import { connect } from "react-redux";
import { VIEWS } from "../../config";

const sortByPositionAndName = sortBy(["vesselItemViewPosition", "name"]);

class MetricsList extends React.Component {
  static propTypes = {
    showTitle: t.bool,
    title: t.string,
    vesselItemData: t.arrayOf(
      t.shape({
        vesselItemId: t.string.isRequired,
      })
    ),
    vesselItemDetails: t.object.isRequired,
    toggleVesselItemDetails: t.func.isRequired,
    toggleTrendVesselItem: t.func.isRequired,
    dateRange: t.array.isRequired,
    columnCount: t.number.isRequired,
  };

  static defaultProps = {
    showTitle: true,
  };

  constructor(props) {
    super(props);
    this.renderMetricsListItem = this.renderMetricsListItem.bind(this);
  }

  renderMetricsListItem(item) {
    if (!item) {
      return <div />;
    }

    const {
      vesselItemDetails,
      toggleVesselItemDetails,
      toggleTrendVesselItem,
      dateRange,
      states,
      columnCount,
      restrictedViews,
      timeOffset,
    } = this.props;

    const details = vesselItemDetails[item.vesselItemId];
    const isTrendRestricted =
      restrictedViews && restrictedViews.includes(VIEWS.trend.viewName);
    return (
      <MetricsListItem
        key={item.vesselItemId}
        data={item}
        details={details}
        columnCount={columnCount}
        toggleDetails={
          item.isNoDetailsRow
            ? noop()
            : () =>
                toggleVesselItemDetails(
                  item.vesselItemId,
                  dateRange,
                  states,
                  !details,
                  timeOffset
                )
        }
        toggleTrend={isTrendRestricted ? undefined : toggleTrendVesselItem}
        dateRange={dateRange}
        timeOffset={timeOffset}
      />
    );
  }

  render() {
    const { showTitle, title, vesselItemData } = this.props;
    return (
      <div className={styles.container}>
        {showTitle && (
          <div className={styles.title}>
            <h3>{title}</h3>
          </div>
        )}
        {sortByPositionAndName(vesselItemData).map(this.renderMetricsListItem)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  restrictedViews: state.userProfile.restrictedViews,
});

export default connect(mapStateToProps)(MetricsList);
