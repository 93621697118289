import { flatMap, range } from "lodash";
import { updateIn } from "../../../../common/arrays";
import { admin } from "../../../actions/action.types";
import {
  createDate,
  getDaysInMonth,
  getYear,
  now,
} from "../../../common/dates";
import createReducer from "../../createReducer";
import { formatNumber } from "../../../../common/numbers";

export default createReducer(
  { inputs: [] },
  {
    [admin.energyBudget.INIT_MONTHS_FORM]: (state, { data }) => ({
      data,
      inputs: prepareInputs(data),
    }),

    [admin.energyBudget.SET_MONTH_VALUE]: (
      state,
      { rowIndex, colIndex, value, isValid }
    ) => ({
      ...state,
      inputs: updateIn(state.inputs, rowIndex, (row) =>
        updateIn(row, colIndex, (cell) => ({
          ...cell,
          value,
          hasError: !isValid,
          isChanged: true,
        }))
      ),
    }),

    [admin.energyBudget.FORMAT_MONTH_VALUE]: (
      state,
      { rowIndex, colIndex }
    ) => ({
      ...state,
      inputs: updateIn(state.inputs, rowIndex, (row) =>
        updateIn(row, colIndex, (cell) => ({
          ...cell,
          value: cell.hasError
            ? cell.value
            : formatNumber(parseValueOrDefault(cell)),
        }))
      ),
    }),

    [admin.energyBudget.RESET_MONTHS_FORM]: (state) => ({
      ...state,
      inputs: prepareInputs(state.data),
    }),

    [admin.energyBudget.SAVE_MONTHS_FORM]: (state) => ({
      ...state,
      isSaving: true,
    }),

    [admin.energyBudget.SAVE_MONTHS_FORM_SUCCESS]: (state, { data }) => ({
      data,
      inputs: prepareInputs(data),
    }),

    [admin.energyBudget.SAVE_MONTHS_FORM_ERROR]: (state, { error }) => ({
      ...state,
      isSaving: false,
      error,
    }),
  }
);

const aggregateMonth = (year, month, data) => {
  return data.reduce((accu, entry) => {
    if (entry.year === year && entry.month === month) {
      return accu + (entry.value || 0);
    }
    return accu;
  }, 0);
};

const prepareInputs = (data = []) => {
  const currentYear = getYear(now());
  const firstYearInData = data.reduce(
    (min, entry) => (entry.year < min ? entry.year : min),
    currentYear
  );
  const startYear = Math.min(firstYearInData, currentYear);
  const years = range(startYear, currentYear + 5);
  const months = range(1, 13);
  return years.map((year) =>
    months.map((month) => ({
      year,
      month,
      value: formatNumber(aggregateMonth(year, month, data)),
    }))
  );
};

export const isInputValid = (state) =>
  state.inputs && state.inputs.every((row) => row.every((x) => !x.hasError));

export const hasChanges = (state) =>
  state.inputs && state.inputs.some((row) => row.some((x) => x.isChanged));

const parseValueOrDefault = ({ value, hasError }, defaultValue = 0) => {
  if (hasError) {
    return defaultValue;
  }
  const parsedValue = parseFloat(value.trim());
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

export const spreadMonthToDays = (monthInput) => {
  const scale = 10.0; // maintain a precision of 1
  const scaledValue = parseValueOrDefault(monthInput) * scale;
  const daysInMonth = getDaysInMonth(
    createDate(monthInput.year, monthInput.month)
  );
  const divValue = Math.floor(scaledValue / daysInMonth);
  const remainder = scaledValue % daysInMonth;

  return range(1, daysInMonth + 1).map((day) => ({
    year: monthInput.year,
    month: monthInput.month,
    day,
    value: (day <= remainder ? divValue + 1 : divValue) / scale, // spread the remainder along the first elements
  }));
};

export const getChangedMonthInputs = (state) =>
  flatMap(state.inputs, (row) => row.filter((x) => x.isChanged));

export const prepareChangedMonthsPayload = (state) =>
  flatMap(getChangedMonthInputs(state), spreadMonthToDays);

export const calculateTotal = (row) =>
  row.reduce((total, entry) => total + parseValueOrDefault(entry), 0);
