import React, { FC } from "react";
import Button from "../../../common/components/Button";
import styles from "./ManualFuelDataFormDialog.css";

const Info: FC = () => {
  return (
    <div className={styles.info}>
      <Button
        circle={true}
        width={"16"}
        padding={"1px"}
        disabled={true}
        value={<p style={{ fontSize: "var(--font-size--s)" }}>i</p>}
        type={"smallButton"}
      />
      <p>Metric units are in tons</p>
    </div>
  );
};

export default Info;
