import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";

export const VESSEL_DETAILS = "vesselDetails";
export const ONBOARD_FRONTEND_CONFIG = "onboardFrontendConfig";
export const ONBOARD_PROCESSOR_CONFIG = "onboardProcessorConfig";
export const ONSHORE_FRONTEND_CONFIG = "onshoreFrontendConfig";
export const BASELINE = "baseline";
export const VESSEL_OPERATIONS = "vesselOperations";
export const VESSEL_ITEMS = "vesselItems";
export const VESSEL_PERFORMANCE_INDICATORS = "vesselPerformanceIndicators";
export const HULL_PERFORMANCE_CONFIG = "hullPerformanceConfig";
export const ENERGY_FLOW = "energyFlowConfig";

export const initialState = {
  mode: "edit",
  panels: [
    { name: VESSEL_DETAILS, isVisible: true },
    { name: VESSEL_ITEMS, isVisible: false },
    { name: VESSEL_OPERATIONS, isVisible: false },
    { name: VESSEL_PERFORMANCE_INDICATORS, isVisible: false },
    { name: BASELINE, isVisible: false },
    { name: ENERGY_FLOW, isVisible: false },
    { name: ONBOARD_FRONTEND_CONFIG, isVisible: false },
    { name: ONBOARD_PROCESSOR_CONFIG, isVisible: false },
    { name: ONSHORE_FRONTEND_CONFIG, isVisible: false },
    { name: HULL_PERFORMANCE_CONFIG, isVisible: false },
  ],
};

export default createReducer(initialState, {
  [vesselConfig.INIT]: (_state, { isNew }) => ({
    ...initialState,
    mode: isNew ? "new" : "edit",
    isLoading: true,
  }),
  [vesselConfig.INIT_SUCCESS]: (state, { vessel }) => ({
    ...state,
    vessel,
    isLoading: false,
  }),
  [vesselConfig.INIT_ERROR]: (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  }),
  [vesselConfig.TOGGLE_PANEL]: (state, { name, isVisible }) => ({
    ...state,
    panels: state.panels.map((p) =>
      p.name === name ? { ...p, isVisible } : p
    ),
  }),
});
