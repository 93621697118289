import { OperationClassID } from "./operationClassID";
import { color } from "../colors";

export enum OperationId {
  None = -1,
  Other = 0,
  Port = 1,
  Maneuver = 2,
  TransitEco = 3,
  Transit = 4,
  TransitFast = 5,
  PortShoreConnection = 6,
  Standby = 7,
  PortBattery = 8,
  Dp = 100,
  DpClass2 = 101,
  DpGangway = 102,
  DpManeuver = 103,
  AnchorHandlingDp = 200,
  AnchorHandlingTensioning = 201,
  AnchorHandlingTowing = 202,
  SingleTrawl = 300,
  DoubleTrawl = 301,
  Fishing = 302,
  TripleTrawl = 303,
  Shooting = 304,
  Hoisting = 305,
  Embarking = 400,
}

export const operationLegend: Record<OperationId, string> = {
  [OperationId.None]: "OFF",
  [OperationId.Other]: "O",
  [OperationId.Port]: "P",
  [OperationId.Maneuver]: "M",
  [OperationId.TransitEco]: "ET",
  [OperationId.Transit]: "T",
  [OperationId.TransitFast]: "FT",
  [OperationId.PortShoreConnection]: "SC",
  [OperationId.Standby]: "S",
  [OperationId.PortBattery]: "PB",
  [OperationId.Dp]: "DP",
  [OperationId.DpClass2]: "DP2",
  [OperationId.DpGangway]: "DPG",
  [OperationId.DpManeuver]: "DP2M",
  [OperationId.AnchorHandlingDp]: "AHDP",
  [OperationId.AnchorHandlingTensioning]: "AHTe",
  [OperationId.AnchorHandlingTowing]: "AHTw",
  [OperationId.SingleTrawl]: "F1",
  [OperationId.DoubleTrawl]: "F2",
  [OperationId.Fishing]: "F",
  [OperationId.TripleTrawl]: "F3",
  [OperationId.Shooting]: "FS",
  [OperationId.Hoisting]: "FH",
  [OperationId.Embarking]: "E",
};

export const operationName: Record<OperationId, string> = {
  [OperationId.None]: "Offline",
  [OperationId.Other]: "Other",
  [OperationId.Port]: "At Port",
  [OperationId.Maneuver]: "Maneuver",
  [OperationId.TransitEco]: "Eco Transit",
  [OperationId.Transit]: "Transit",
  [OperationId.TransitFast]: "Fast Transit",
  [OperationId.PortShoreConnection]: "Port With Shore Connection",
  [OperationId.Standby]: "Standby",
  [OperationId.PortBattery]: "Port With Battery",
  [OperationId.Dp]: "DP",
  [OperationId.DpClass2]: "DP Class 2",
  [OperationId.DpGangway]: "DP Gangway",
  [OperationId.DpManeuver]: "DP Maneuver",
  [OperationId.AnchorHandlingDp]: "Anchor Handling DP",
  [OperationId.AnchorHandlingTensioning]: "Anchor Handling Tensioning",
  [OperationId.AnchorHandlingTowing]: "AH Towing",
  [OperationId.SingleTrawl]: "Single Trawl",
  [OperationId.DoubleTrawl]: "Double Trawl",
  [OperationId.Fishing]: "Fishing",
  [OperationId.TripleTrawl]: "Triple Trawl",
  [OperationId.Shooting]: "Shooting",
  [OperationId.Hoisting]: "Hoisting",
  [OperationId.Embarking]: "Embarking",
};

export const operationToOperationClass: Record<OperationId, OperationClassID> =
  {
    [OperationId.None]: OperationClassID.General,
    [OperationId.Other]: OperationClassID.Passive,
    [OperationId.Port]: OperationClassID.Passive,
    [OperationId.Maneuver]: OperationClassID.Transit,
    [OperationId.TransitEco]: OperationClassID.Transit,
    [OperationId.Transit]: OperationClassID.Transit,
    [OperationId.TransitFast]: OperationClassID.Transit,
    [OperationId.PortShoreConnection]: OperationClassID.General,
    [OperationId.Standby]: OperationClassID.DP,
    [OperationId.PortBattery]: OperationClassID.General,
    [OperationId.Dp]: OperationClassID.DP,
    [OperationId.DpClass2]: OperationClassID.DP,
    [OperationId.DpGangway]: OperationClassID.DP,
    [OperationId.DpManeuver]: OperationClassID.DP,
    [OperationId.AnchorHandlingDp]: OperationClassID.AHTS,
    [OperationId.AnchorHandlingTensioning]: OperationClassID.AHTS,
    [OperationId.AnchorHandlingTowing]: OperationClassID.AHTS,
    [OperationId.SingleTrawl]: OperationClassID.Fishing,
    [OperationId.DoubleTrawl]: OperationClassID.Fishing,
    [OperationId.Fishing]: OperationClassID.Fishing,
    [OperationId.TripleTrawl]: OperationClassID.Fishing,
    [OperationId.Shooting]: OperationClassID.Fishing,
    [OperationId.Hoisting]: OperationClassID.Fishing,
    [OperationId.Embarking]: OperationClassID.Passive,
  };

export const operationClassToOperations: Record<
  OperationClassID,
  OperationId[]
> = Object.entries(operationToOperationClass).reduce(
  (acc, [operationId, operationClassId]) => {
    if (!acc[operationClassId]) {
      acc[operationClassId] = [];
    }
    acc[operationClassId].push(operationId as unknown as OperationId);
    return acc;
  },
  {} as Record<OperationClassID, OperationId[]>
);

export const operationColor: Record<OperationId, string> = {
  [OperationId.None]: color("--grey-100"),
  [OperationId.Other]: color("--grey-300"),
  [OperationId.Port]: color("--grey-500"),
  [OperationId.Maneuver]: color("--blue-dark"),
  [OperationId.TransitEco]: color("--blue-darkest"),
  [OperationId.Transit]: color("--blue-base"),
  [OperationId.TransitFast]: color("--blue-bright"),
  [OperationId.PortShoreConnection]: color("--mid-grey"),
  [OperationId.Standby]: color("--orange-darkest"),
  [OperationId.PortBattery]: color("--grey-200"),
  [OperationId.Dp]: color("--orange-dark"),
  [OperationId.DpClass2]: color("--orange-base"),
  [OperationId.DpGangway]: color("--orange-brightest"),
  [OperationId.DpManeuver]: color("--orange-bright"),
  [OperationId.AnchorHandlingDp]: color("--red-dark"),
  [OperationId.AnchorHandlingTensioning]: color("--red-bright"),
  [OperationId.AnchorHandlingTowing]: color("--red-base"),
  [OperationId.SingleTrawl]: color("--yellow-dark"),
  [OperationId.DoubleTrawl]: color("--yellow-base"),
  [OperationId.Fishing]: color("--yellow-dark"),
  [OperationId.TripleTrawl]: color("--yellow-darkest"),
  [OperationId.Shooting]: color("--yellow-bright"),
  [OperationId.Hoisting]: color("--yellow-brightest"),
  [OperationId.Embarking]: color("--grey-600"),
};

export const operationTextColor: Record<OperationId, string | undefined> = {
  [OperationId.None]: color("--grey-500"),
  [OperationId.Other]: undefined,
  [OperationId.Port]: undefined,
  [OperationId.Maneuver]: undefined,
  [OperationId.TransitEco]: undefined,
  [OperationId.Transit]: undefined,
  [OperationId.TransitFast]: undefined,
  [OperationId.PortShoreConnection]: undefined,
  [OperationId.Standby]: undefined,
  [OperationId.PortBattery]: color("--grey-500"),
  [OperationId.Dp]: undefined,
  [OperationId.DpClass2]: undefined,
  [OperationId.DpGangway]: undefined,
  [OperationId.DpManeuver]: undefined,
  [OperationId.AnchorHandlingDp]: undefined,
  [OperationId.AnchorHandlingTensioning]: undefined,
  [OperationId.AnchorHandlingTowing]: undefined,
  [OperationId.SingleTrawl]: undefined,
  [OperationId.DoubleTrawl]: undefined,
  [OperationId.Fishing]: undefined,
  [OperationId.TripleTrawl]: undefined,
  [OperationId.Shooting]: undefined,
  [OperationId.Hoisting]: undefined,
  [OperationId.Embarking]: undefined,
};
