import styles from "./styling/styles.css";
import { Link } from "react-router-dom";
import React from "react";

export function PrivacyContainer() {
  return (
    <div className={styles.privacyContainer} data-testid="privacy-text">
      <div>
        <p>
          By logging into our service you agree to our{" "}
          <Link to="https://www.kongsbergdigital.com/privacy-policy">
            privacy policy.
          </Link>
        </p>
        <p>
          For more information visit our{" "}
          <a href="https://www.kongsbergdigital.com/products">
            KONGSBERG DIGITAL PRODUCTS
          </a>{" "}
          website.
        </p>
        <p>&copy; {new Date().getUTCFullYear()} - Kongsberg Digital</p>
      </div>
    </div>
  );
}
