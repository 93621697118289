import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { FormSelectBox } from "../../../components/Form";
import styles from "./UserAccessForm.css";

export function UserAccessForm({
  companies,
  groups,
  selectedCompany,
  selectedGroups,
  currentAssignments,
  onCompanyChange,
  onGroupsChange,
}) {
  return (
    <div>
      <FormSelectBox
        name="companyId"
        label="Company"
        selected={selectedCompany}
        options={companies}
        optionValKey="id"
        optionLabelKey="name"
        onChange={onCompanyChange}
      />
      <FormSelectBox
        multi
        name="groupId"
        label="Vessel groups"
        selected={selectedGroups}
        options={groups}
        optionValKey="id"
        optionLabelKey="name"
        onChange={onGroupsChange}
      />
      <div>
        {!isEmpty(currentAssignments) && (
          <div className={styles.assignments}>
            <strong>Current assignments:</strong>
            {Object.entries(currentAssignments).map(
              ([company, assignments]) => (
                <span key={company} className={styles.assignment}>
                  <span>{company}</span>
                  <span className={styles.groups}>
                    ({assignments.map((a) => a.group).join(", ")})
                  </span>
                </span>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const IdName = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
});

UserAccessForm.propTypes = {
  companies: PropTypes.arrayOf(IdName).isRequired,
  groups: PropTypes.arrayOf(IdName).isRequired,
  selectedCompany: IdName,
  selectedGroups: PropTypes.arrayOf(IdName),
  currentAssignments: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string.isRequired,
      })
    )
  ),
  onCompanyChange: PropTypes.func.isRequired,
  onGroupsChange: PropTypes.func.isRequired,
};
