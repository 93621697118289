import { useSelector } from "react-redux";
import {
  convertFiltersToQueryParams,
  getDateRangeFilter,
} from "../../../selectors/filters";
import { useQueryContext } from "../../../providers/QueryContextProvider";
import { useEffect } from "react";
import { VIEWS } from "../../../config";
import {
  DatePickerValue,
  DateRange,
} from "../../../components/DatePicker/Types";
import { RootState } from "../../../store/rootState";

/**
 *
 */
export const useDatePickerFilter = (): [
  isValid: boolean,
  dateRange: DateRange,
  dateValue: any,
  onDateChange: (newDate: DatePickerValue) => void
] => {
  const queryContext = useQueryContext();
  const dateFilter = useSelector((state: RootState) =>
    getDateRangeFilter(state, { queryContext })
  );

  useEffect(() => {
    if (!dateFilter.isValid) {
      queryContext.navigate(
        VIEWS.mainDashboard.url,
        convertFiltersToQueryParams({
          dateFilter,
        }),
        true
      );
    }
  }, []);

  const onDateChange = (newDate: DatePickerValue) => {
    queryContext.setDate(newDate);
  };

  return [
    dateFilter.isValid ?? false,
    dateFilter.value.range as DateRange,
    dateFilter.value,
    onDateChange,
  ];
};
