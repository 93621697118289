import createReducer from "../createReducer";
import { admin } from "../../actions/action.types";

const initialState = {
  data: {},
  isLoading: false,
  vesselOptions: [],
  selectedVessel: null,
  hasError: false,
};

export default createReducer(initialState, {
  [admin.cargoConfig.INIT]: (state) => {
    return {
      ...state,
      data: {},
      isLoading: false,
    };
  },
  [admin.cargoConfig.FETCH_VESSELS]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [admin.cargoConfig.FETCH_VESSELS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      hasError: false,
      isLoading: false,
      vesselOptions: data,
    };
  },
  [admin.cargoConfig.FETCH_VESSELS_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [admin.cargoConfig.FETCH_CARGO]: (state) => {
    return {
      ...state,
      data: {},
      hasError: false,
      error: null,
      isLoading: true,
    };
  },
  [admin.cargoConfig.FETCH_CARGO_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      data,
    };
  },
  [admin.cargoConfig.FETCH_CARGO_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      hasError: true,
      error,
    };
  },
  [admin.cargoConfig.UPDATE_CARGO]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        cargos: payload,
      },
    };
  },
  [admin.cargoConfig.RESET]: (state, { payload }) => {
    return {
      ...state,
      data: {
        ...state.data,
        cargos: payload,
      },
    };
  },
});
