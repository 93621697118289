import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchHealthEvents } from "../actions/action.fetchHealthEvents";
import Loader from "../../common/components/Loader/Loader";
import { HealthEventStatus } from "../components/HealthEventStatus/HealthEventStatus";
import { isContextChanged, isContextValid } from "../common/contexts";

class HealthEventsContainer extends Component {
  componentDidMount() {
    if (isContextValid(this.props.context)) {
      this.props.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isContextValid(this.props.context) &&
      (isContextChanged(this.props.context, prevProps.context) ||
        this.props.config !== prevProps.config)
    ) {
      this.props.fetch();
    }
  }

  render() {
    const { data, error } = this.props.healthEvents;
    if (!data) {
      return (
        <Loader text="Loading health events" error={error} expand={true} />
      );
    }
    return <HealthEventStatus data={data} />;
  }
}

export default connect(
  (state, { context }) => ({
    context,
    healthEvents: state.healthEvents,
  }),
  (dispatch, { context }) => ({
    fetch: () => dispatch(fetchHealthEvents(context)),
  })
)(HealthEventsContainer);
