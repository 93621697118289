import React from "react";
import PropTypes from "prop-types";
import styles from "./Label.css";

export default class Label extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, style } = this.props;
    return (
      <div className={styles.label} style={style}>
        {label}
      </div>
    );
  }
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.shape(),
};
