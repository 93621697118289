import { FETCH_MAP_DATA } from "../actions/action.types";
import { createRequestReducer } from "./createRequestReducer";
import { values, some } from "lodash/fp";

export default createRequestReducer(FETCH_MAP_DATA);

export const getVessels = (mapData) => {
  return (mapData.data && mapData.data.vessels) || null;
};

export const getVesselsByGroup = (vessels, vesselGroupVessels, groupId) => {
  vesselGroupVessels = values(vesselGroupVessels);
  return (
    vessels &&
    vessels.filter((mapData) =>
      some(
        (vg) => vg.groupId === groupId && mapData.vesselId === vg.vesselId,
        vesselGroupVessels
      )
    )
  );
};
