import t from "prop-types";
import React from "react";
import Loader from "../../../common/components/Loader/Loader";
import { VesselStatus } from "./VesselStatus";
import styles from "./VesselStatus.css";
import { groupBy, sortBy, keys } from "lodash";

const mapCompany = (name, group) => {
  let sortedVessels = sortBy(group, "vesselName");
  let vessels = sortedVessels.map((v, k) => (
    <div key={k} className={styles.gridCell}>
      <VesselStatus {...v} />
    </div>
  ));

  return (
    <div key={name} className={styles.company}>
      <h1>{name}</h1>
      <div className={styles.vessels}>{vessels}</div>
    </div>
  );
};

export const VesselStatusList = ({ data, error }) => {
  if (!data) {
    return (
      <Loader text="Loading vessel status..." error={error} expand={true} />
    );
  }

  let groupedData = groupBy(data, "companyName");

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h3>Vessel status</h3>
      </div>
      <div className={styles.grid}>
        {keys(groupedData)
          .sort()
          .map((name) => mapCompany(name, groupedData[name]))}
      </div>
    </div>
  );
};

VesselStatusList.propTypes = {
  data: t.array,
  error: t.any,
};
