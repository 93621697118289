import { pageLayoutConfiguration } from "../action.types";

export const createGridLayout = (rows) => ({
  type: pageLayoutConfiguration.CREATE_GRID_LAYOUT,
  rows,
});

export const updateGridLayout = (layout, rowsConfig) => ({
  type: pageLayoutConfiguration.UPDATE_GRID_LAYOUT,
  layout,
  rowsConfig,
});

export const updateRowsCount = (rowsCount) => ({
  type: pageLayoutConfiguration.UPDATE_ROWS_COUNT,
  rowsCount,
});

export const clearPageLayoutConfiguration = () => ({
  type: pageLayoutConfiguration.CLEAR_PAGE_LAYOUT_CONFIGURATION,
});

export const openSettingsDialog = (id) => ({
  type: pageLayoutConfiguration.OPEN_SETTINGS_DIALOG,
  id,
});

export const closeSettingsDialog = () => ({
  type: pageLayoutConfiguration.CLOSE_SETTINGS_DIALOG,
});

export const updateRowsConfig = (rowsConfig) => ({
  type: pageLayoutConfiguration.UPDATE_ROWS_CONFIG,
  rowsConfig,
});
