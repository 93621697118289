import { FETCH_GAUGE } from "./action.types";
import fetch from "./action.fetch";
import { createGaugeUrl } from "../common/gauges";

export const fetchGauge = (performanceIndicatorId, context) =>
  fetch({
    url: createGaugeUrl(performanceIndicatorId, context),
    key: `${FETCH_GAUGE}_${performanceIndicatorId}`,
    actionType: FETCH_GAUGE,
    actionData: { performanceIndicatorId, context },
  });
