import T from "prop-types";
import React from "react";
import { VESSELMARKERS } from "../../config";

export default class MapMarker extends React.Component {
  imgString(fill, radius) {
    return VESSELMARKERS.base64[fill][radius];
  }

  render() {
    let { efficiency, connection } = this.props;

    let image =
      !efficiency || !connection
        ? this.imgString("states", "inactive")
        : this.imgString(efficiency, connection);
    const altText = "Icon representing vessel connection and efficiency";
    return <img src={image} alt={altText} />;
  }
}

MapMarker.propTypes = {
  connection: T.string,
  efficiency: T.string,
};
