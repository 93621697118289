import React from "react";
import { EmissionsTrendSelected } from "../commonTypes";
import FleetCIIScoreSparkline from "../../../../onshore/views/FleetCIIScore/FleetCIIScoreSparkline";
import styles from "./EmissionsTrend.css";
import { Emissions } from "./Emissions";
import Loader from "../../../../common/components/Loader";
import { useAppSelector } from "../../../store/rootState";

const EmissionsTrend = () => {
  const { series, selected, isLoading } = useAppSelector(
    (state) => state.fuelEmissions.emissionsTrend
  );
  const isEmissions = selected === EmissionsTrendSelected.Emissions;

  if (isLoading) return <Loader />;

  return (
    <div className={styles.container}>
      {isEmissions ? (
        <Emissions data={series.emissions} />
      ) : (
        <FleetCIIScoreSparkline data={series.cii} padding={40} />
      )}
    </div>
  );
};

export default EmissionsTrend;
