import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  configureRemoteControlVessel,
  fetchCompanies,
  fetchVessels,
  setSelectedCompany,
} from "../../../actions/admin";
import { Header } from "../../../components/Header";
import Loader from "../../../../common/components/Loader/Loader";
import Button from "../../../../common/components/Button/Button";
import DataGrid from "../../../components/DataGrid/DataGrid";
import SelectBox from "../../../components/SelectBox/SelectBox";
import { isSystemAdmin } from "../../../reducers/reducer.user";
import styles from "../Admin.css";
import ConfigureRemoteAccessPopup from "./ConfigureRemoteControlPopup";
import {
  configureSensorReplicationVessel,
  fetchVesselUpdateTargets,
} from "../../../actions/admin/action.vessels";
import {
  formatLastPing,
  getLastPingStatus,
} from "../../../common/devOpsFormatters";
import SensorReplicationPopup from "./SensorReplicationPopup";
import { useNavigate } from "react-router-dom";

const Vessels = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const vesselList = useSelector((state) => state.admin.vessels.vesselList);
  const companies = useSelector((state) => state.admin.companies.data || []);
  const selectedCompanyId = useSelector(
    (state) => state.admin.selectedCompanyId
  );
  const canEdit = useSelector((state) => isSystemAdmin(state.user));

  useEffect(() => {
    // Fetch data when the component mounts or when dependencies change
    dispatch(fetchVessels(selectedCompanyId));
    dispatch(fetchVesselUpdateTargets());
    dispatch(fetchCompanies());
  }, [dispatch, selectedCompanyId]);

  useEffect(() => {
    // Refetch vessels when selectedCompanyId changes
    fetchVesselsFromSelectedCompany();
  }, [selectedCompanyId]);

  const fetchVesselsFromSelectedCompany = () => {
    dispatch(fetchVessels(selectedCompanyId));
  };

  const renderActions = (vessel) => {
    if (!canEdit) {
      return null;
    }
    return (
      <div>
        <Button
          value="Edit"
          clickAction={() => navigateTo("/admin/vessels/" + vessel.id)}
          type="link"
        />
        &nbsp;-&nbsp;
        <Button
          value="Remote Control"
          clickAction={() => dispatch(configureRemoteControlVessel(vessel))}
          type="link"
        />
        &nbsp;-&nbsp;
        <Button
          value="Sensor Replication"
          clickAction={() => dispatch(configureSensorReplicationVessel(vessel))}
          type="link"
        />
      </div>
    );
  };

  const renderLastPing = (vessel) => {
    const status = getLastPingStatus(vessel);
    const value = formatLastPing(vessel.lastPing);
    const getWarningStyle = () => (status.isWarning ? styles.warning : null);
    const className = status.isError ? styles.error : getWarningStyle();

    return className !== null ? (
      <div className={className}>{value}</div>
    ) : (
      <div>{value}</div>
    );
  };

  const gridConfig = {
    columns: [
      {
        header: "Name",
        field: "name",
        align: "left",
        flexGrow: 3,
      },
      {
        header: "Imo",
        field: "imo",
        align: "left",
        flexGrow: 1,
      },
      {
        header: "Last ping",
        align: "left",
        template: renderLastPing,
        flexGrow: 1,
      },
      {
        header: "Remote Access",
        align: "left",
        field: "remoteAccess",
        flexGrow: 1,
      },
      {
        header: "Actions",
        align: "left",
        template: renderActions,
        flexGrow: 2,
      },
    ],
  };

  const renderDataGrid = () => {
    const { isLoading, data, error } = vesselList;
    if (isLoading) {
      return <Loader error={error} />;
    }
    return <DataGrid data={data} {...gridConfig} />;
  };

  const selectOptions = [{ id: null, name: "All" }].concat(companies);

  return (
    <div>
      <Header title="Admin - Vessels" contentDistribution="space-between">
        <div className={styles.headerContainer}>
          <SelectBox
            options={selectOptions}
            optionValKey="id"
            optionLabelKey="name"
            onSelect={(company) => dispatch(setSelectedCompany(company))}
            selected={selectOptions.find((c) => c.id === selectedCompanyId)}
          />
          {canEdit && (
            <Button
              value="New vessel"
              clickAction={() => navigateTo("/admin/vessels/new")}
            />
          )}
        </div>
      </Header>
      <div className={styles.dataGridContainer}>{renderDataGrid()}</div>
      {vesselList.showRemoteAccessControlPopup && (
        <ConfigureRemoteAccessPopup />
      )}
      {vesselList.showSensorReplicationPopup && <SensorReplicationPopup />}
    </div>
  );
};

export default Vessels;
