import React from "react";
import DataDetailsComponentContainer from "./DataDetailsComponentContainer";
import styles from "./DataDetailsImage.css";

class DataDetailsImage extends React.PureComponent {
  render() {
    const { src, fullWidth } = this.props;
    return (
      <DataDetailsComponentContainer fullWidth={fullWidth}>
        <div className={styles.container}>
          <img src={src} className={styles.image} />
        </div>
      </DataDetailsComponentContainer>
    );
  }
}

export default DataDetailsImage;
