import t from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import PerformanceGauge from "../../common/components/PerformanceGauge";
import { fetchGauge } from "../actions/action.fetchGauge";
import { isContextChanged, isContextValid } from "../common/contexts";
import { prepareGaugeData } from "../common/gauges";
import Loader from "../../common/components/Loader/Loader";
import { getGauge } from "../reducers/reducer.gauges";

class GaugeContainer extends Component {
  componentDidMount() {
    if (isContextValid(this.props.context)) {
      this.props.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      isContextValid(this.props.context) &&
      (isContextChanged(this.props.context, prevProps.context) ||
        this.props.config !== prevProps.config)
    ) {
      this.props.fetch();
    }
  }

  render() {
    const { gauge: { data, error } = {} } = this.props;
    if (!data) {
      return <Loader text="Loading performance data" error={error} expand />;
    }
    return <PerformanceGauge {...prepareGaugeData(data)} />;
  }
}

GaugeContainer.propTypes = {
  config: t.shape({
    params: t.shape({
      performanceIndicatorId: t.string.isRequired,
    }).isRequired,
  }),
  context: t.object.isRequired,
};

export default connect(
  (state, { config, context }) => ({
    gauge: getGauge(
      state.gauges,
      config.params.performanceIndicatorId,
      context
    ),
  }),
  (dispatch, { config, context }) => ({
    fetch: () =>
      dispatch(fetchGauge(config.params.performanceIndicatorId, context)),
  })
)(GaugeContainer);
