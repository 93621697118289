import {
  takeLeading,
  call,
  getContext,
  put,
  fork,
  select,
  all,
} from "redux-saga/effects";
import { vesselConfig } from "../../actions/action.types";
import { vesselConfigApi } from "../../api";
import { get } from "lodash";
import download from "downloadjs";

const actions = vesselConfig.hullPerformance;
const api = vesselConfigApi.hullPerformanceConfig;

export function* initHullPerformanceConfig({ vesselId }) {
  yield call(getInitialData, vesselId);
  yield fork(downloadWatcher);
  yield fork(saveWatcher, vesselId);
  yield fork(resetWatcher, vesselId);
  yield fork(requestCalculationWatcher, vesselId);
  yield fork(getCalculationResultWatcher, vesselId);
  yield fork(recalculateWatcher, vesselId);
}
function* downloadWatcher() {
  yield takeLeading(actions.DOWNLOAD, downloadConfigSaga);
}

function* resetWatcher(vesselId) {
  yield takeLeading(actions.RESET, getInitialData, vesselId);
}

function* saveWatcher(vesselId) {
  yield takeLeading(actions.SAVE, saveHullPerformanceConfig, vesselId);
}

function* requestCalculationWatcher(vesselId) {
  yield takeLeading(actions.REQUEST_CALCULATION, requestCalculation, vesselId);
}

function* recalculateWatcher(vesselId) {
  yield takeLeading(actions.RECALCULATE, recalculate, vesselId);
}

function* getCalculationResultWatcher(vesselId) {
  yield takeLeading(
    actions.FETCH_CALCULAION_RESULT,
    getCalculationResult,
    vesselId
  );
}

function* getInitialData(vesselId) {
  const http = yield getContext("http");
  try {
    yield put({
      type: actions.FETCH,
      vesselId,
    });
    const [config, validation, calculationResult] = yield all([
      call(http.get, api.get(vesselId)),
      call(http.post, api.validate(vesselId)),
      call(http.get, api.calculationResult(vesselId)),
    ]);
    yield put({
      type: actions.FETCH_SUCCESS,
      configData: config.data,
      validationData: validation.data,
      calculationResultData: calculationResult.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      error,
    });
  }
}

function* requestCalculation(vesselId) {
  const http = yield getContext("http");
  try {
    yield call(http.post, api.requestCalculation(vesselId));
    yield put({
      type: actions.REQUEST_CALCULATION_SUCCESS,
    });
    yield put({
      type: actions.FETCH_CALCULAION_RESULT,
      vesselId,
    });
  } catch (error) {
    yield put({
      type: actions.REQUEST_CALCULATION_ERROR,
      error,
    });
  }
}

function* recalculate(vesselId) {
  const http = yield getContext("http");
  try {
    yield call(http.post, api.recalculate(vesselId));
    yield put({
      type: actions.RECALCULATE_SUCCESS,
    });
    yield put({
      type: actions.FETCH_CALCULAION_RESULT,
      vesselId,
    });
  } catch (error) {
    yield put({
      type: actions.RECALCULATE_ERROR,
      error,
    });
  }
}

function* downloadConfigSaga({ data, filename }) {
  try {
    yield call(download, data, filename, "text/csv");
    yield put({ type: actions.DOWNLOAD_SUCCESS });
  } catch (err) {
    yield put({ type: actions.DOWNLOAD_ERROR, error: err });
  }
}

function* saveHullPerformanceConfig(vesselId) {
  const http = yield getContext("http");
  const state = yield select((s) => s.admin.hullPerformanceConfig);
  try {
    const payload = new FormData();
    if (get(state, "fields.speedPowerTxt.hasChanges", false)) {
      payload.append("speedPower", get(state, "fields.speedPowerTxt.value"));
    }
    if (get(state, "fields.displacementDraughtTxt.hasChanges", false)) {
      payload.append(
        "displacementDraught",
        get(state, "fields.displacementDraughtTxt.value")
      );
    }
    payload.append("isIsoCompliant", get(state, "fields.isIsoCompliant.value"));
    const res = yield call(http.post, api.save(vesselId), payload);
    yield put({ type: actions.SAVE_SUCCESS, data: res.data });
    yield put({ type: actions.RESET, vesselId });
  } catch (error) {
    console.error(error);
    yield put({ type: actions.SAVE_ERROR, error });
  }
}

function* getCalculationResult(vesselId) {
  const http = yield getContext("http");
  try {
    const res = yield call(http.get, api.calculationResult(vesselId));
    yield put({
      type: actions.FETCH_CALCULAION_RESULT_SUCCESS,
      data: res.data,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_CALCULAION_RESULT_ERROR,
      error,
    });
  }
}
