import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get, matchesProperty, orderBy } from "lodash";
import {
  fetchPageConfig,
  fetchCompanies,
  fetchVessels,
  closePageConfigPopup,
  savePageConfig,
  setPageConfigValue,
  setPageJsonConfig,
  clearPageJsonConfig,
  clearPageConfigRows,
  clearPageConfigVesselId,
  clearPageConfigVesselGroupId,
} from "../../../../actions/admin";
import {
  FormTextBox,
  FormSelectBox,
  FormPopup,
} from "../../../../components/Form";
import Button from "../../../../../common/components/Button";
import { VIEWS } from "../../../../config";
import styles from "./PageConfigs.css";
import { checkJsonValidity } from "../../../../common/pageConfig";
import { useNavigate } from "react-router-dom";

const pageOptions = [
  { id: "vessel", name: "Vessel" },
  { id: "fleet", name: "Fleet" },
  { id: "dashboard", name: "Dashboard" },
];

const jsonConfigs = {
  value: "fields.jsonConfig.value",
  id: "fields.jsonConfig.id",
  lastCopiedId: "fields.jsonConfig.lastCopiedId",
};

const PageConfigsPopup = (props) => {
  const navigate = useNavigate();

  const onPopupFormHide = () => {
    props.closePageConfigPopup();
  };

  const onPopupFormSave = () => {
    props.savePageConfig();
  };

  const onPopupFormCancel = () => {
    props.closePageConfigPopup();
  };

  const onPopupFormInputChange = (name, value, valid, message) => {
    props.setPageConfigValue(name, value, valid, message);
  };

  const onPopupFormSelectChange = (name, selected, valid, message) => {
    props.setPageConfigValue(name, selected.id, valid, message);
    if (name === "pageName" || name === "companyId") {
      clearFields(name, selected);
    }
  };

  useEffect(() => {
    props.fetchCompanies();
    props.fetchVessels();
  }, []);

  const routeChange = () => {
    navigate(VIEWS.pagelayoutconfiguration.url);
  };

  const getConfigOptions = () => {
    const pageName = get(props.form, "fields.pageName.value", "").toLowerCase();
    return props.data
      .filter((d) => d.pageName.toLowerCase() === pageName)
      .map((x) => {
        return {
          ...x,
          labelName: x.companyName ? `${x.name} (${x.companyName})` : x.name,
        };
      });
  };

  const onCopyConfigChange = (_name, selected) => {
    const lastCopiedId = get(props.form, jsonConfigs.lastCopiedId);
    const jsonConfig = selected
      ? {
          value: selected.rows,
          id: selected.id,
          lastCopiedId,
        }
      : {
          value: [],
          id: "",
          lastCopiedId: "",
        };
    props.setPageJsonConfig(jsonConfig);
  };

  const updateJson = () => {
    const rows = JSON.stringify(
      get(props.form, jsonConfigs.value, []),
      null,
      1
    );
    const jsonConfig = get(props.form, "fields.jsonConfig", {});
    const updatedJsonConfig = {
      ...jsonConfig,
      lastCopiedId: jsonConfig.id,
    };
    props.setPageConfigValue("rows", rows);
    props.setPageJsonConfig(updatedJsonConfig);
  };

  const clearFields = (name, selected) => {
    const pageName = get(props.form, "fields.pageName.value", "");
    if (
      name === "pageName" &&
      pageName.toLowerCase() === selected.name.toLowerCase()
    ) {
      return;
    }
    if (name === "pageName") {
      if (get(props.form, "fields.rows.value")) {
        props.clearPageConfigRows();
      }
      if (get(props.form, "fields.jsonConfig.id")) {
        props.clearPageJsonConfig();
      }
    }
    if (get(props.form, "fields.vesselId.value")) {
      props.clearPageConfigVesselId();
    }
    if (get(props.form, "fields.vesselGroupId.value")) {
      props.clearPageConfigVesselGroupId();
    }
  };

  const renderForm = () => {
    const emptyOption = { id: null, name: "-" };
    let vesselOptions = [emptyOption].concat(props.vessels);
    const companyOptions = [emptyOption].concat(props.companies);
    let vesselGroupOptions = [emptyOption].concat(props.vesselGroups);
    const configOptions = getConfigOptions();
    const selectedConfigOption = configOptions.find(
      matchesProperty("id", get(props.form, jsonConfigs.id))
    );
    const disableJsonButton =
      get(props.form, jsonConfigs.id) ===
      get(props.form, jsonConfigs.lastCopiedId);

    if (!props.showPopup) {
      return <div />;
    }

    const selectedCompany = companyOptions.find(
      matchesProperty("id", get(props.form, "fields.companyId.value"))
    );

    const selectedVessel = vesselOptions.find(
      matchesProperty("id", get(props.form, "fields.vesselId.value"))
    );

    const selectedPage = pageOptions.find(
      matchesProperty(
        "id",
        get(props.form, "fields.pageName.value", "").toLowerCase()
      )
    );

    const selectedVesselGroup = vesselGroupOptions.find(
      matchesProperty("id", get(props.form, "fields.vesselGroupId.value"))
    );

    if (selectedCompany?.id) {
      vesselGroupOptions = vesselGroupOptions.filter(
        (vg) => vg.companyId === selectedCompany.id
      );
      vesselOptions = vesselOptions.filter(
        (v) => v.companyId === selectedCompany.id
      );
      if (vesselGroupOptions.length) {
        vesselGroupOptions.unshift(emptyOption);
      }
      if (vesselOptions.length) {
        vesselOptions.unshift(emptyOption);
      }
    }

    return (
      <div>
        <FormTextBox
          name="name"
          label="Name"
          value={get(props.form, "fields.name.value")}
          onChange={onPopupFormInputChange}
          required
        />
        <FormSelectBox
          name="pageName"
          label="Page Name"
          selected={selectedPage}
          options={pageOptions}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onPopupFormSelectChange}
          required
        />
        <FormSelectBox
          name="companyId"
          label="Company"
          selected={selectedCompany}
          options={companyOptions}
          optionValKey="id"
          optionLabelKey="name"
          onChange={onPopupFormSelectChange}
        />
        {selectedPage.id === "fleet" && (
          <FormSelectBox
            name="vesselGroupId"
            label="Vessel Group (optional)"
            selected={selectedVesselGroup || ""}
            options={vesselGroupOptions}
            optionValKey="id"
            optionLabelKey="name"
            onChange={onPopupFormSelectChange}
            disabled={!selectedCompany || !selectedCompany.id}
          />
        )}
        {selectedPage.id === "vessel" && (
          <FormSelectBox
            name="vesselId"
            label="Vessel"
            selected={selectedVessel || ""}
            onChange={onPopupFormSelectChange}
            optionValKey="id"
            optionLabelKey="name"
            options={vesselOptions}
          />
        )}
        <FormTextBox
          name="rows"
          label="JSON"
          value={get(props.form, "fields.rows.value")}
          onChange={onPopupFormInputChange}
          required
          multiLine
          type="json"
          rows={10}
          jsonConfigValidation
        />
        <div className={styles.buttonContainer}>
          <Button
            value={props.form.isUpdate ? "Edit JSON" : "Configure JSON"}
            clickAction={routeChange}
            disabled={
              !disableJsonButton ||
              !checkJsonValidity(get(props.form, "fields.rows.value"))
            }
          />
          <div className={styles.selectBoxContainer}>
            <FormSelectBox
              label="Copy JSON from"
              name="jsonConfig"
              labelPosition={"left"}
              options={orderBy(configOptions, ["name"]) || []}
              optionValKey={"id"}
              optionLabelKey={"labelName"}
              onChange={onCopyConfigChange}
              selected={selectedConfigOption || ""}
              className={styles.selectBoxStyle}
              clearable={true}
            />
          </div>
          <Button
            value={"Update JSON"}
            disabled={disableJsonButton}
            clickAction={updateJson}
          />
        </div>
      </div>
    );
  };

  const { isSaving } = props.form;
  const enableSave =
    (!props.form.isUpdate || props.form.hasChanges) &&
    props.form.isValid &&
    !isSaving;
  return (
    <FormPopup
      title={
        props.form.isUpdate ? "Edit Page Config" : "Create new Page Config"
      }
      visible={props.showPopup}
      isSaving={isSaving}
      enableSave={enableSave}
      onHide={onPopupFormHide}
      onSave={onPopupFormSave}
      onCancel={onPopupFormCancel}
    >
      {renderForm()}
    </FormPopup>
  );
};

const mapStateToProps = (state) => {
  const { vessels, companies, showPopup, form, data, vesselGroups } =
    state.admin.pageConfig;
  return {
    vessels,
    companies,
    showPopup,
    form,
    data,
    vesselGroups,
  };
};

const mapDispatchToProps = {
  fetchPageConfig,
  fetchCompanies,
  fetchVessels,
  closePageConfigPopup,
  savePageConfig,
  setPageConfigValue,
  setPageJsonConfig,
  clearPageJsonConfig,
  clearPageConfigRows,
  clearPageConfigVesselId,
  clearPageConfigVesselGroupId,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageConfigsPopup);
