import {
  createDate,
  getMonth,
  getYear,
  atStartOfMonth,
  atEndOfMonth,
  now,
  getMonthName,
  from as parseDateFrom,
} from "../../common/dates";
import { isEqual } from "lodash";
import { removeCurrentSelectedValues } from ".";

export default {
  canSelectNext: (value, { limits }) =>
    !limits || value < createDate(getYear(limits.to), getMonth(limits.to)),
  canSelectPrev: (value, { limits }) => !limits || value > limits.from,
  next: (value) => createDate(getYear(value), getMonth(value) + 1),
  prev: (value) => createDate(getYear(value), getMonth(value) - 1),
  defaultValue: ({ current, limits }, defaultDate) => {
    const currentValue =
      current && (current.currentSelectedValue || current.value);
    if (currentValue) {
      return getCurrentValue(currentValue, current, limits);
    }
    const value = defaultDate && (defaultDate.value.to || defaultDate.value);
    if (value && (!limits || value <= limits.to)) {
      if (limits && value < limits.from) {
        return atStartOfMonth(limits.from);
      }
      return atStartOfMonth(value);
    }
    return (limits && atStartOfMonth(limits.to)) || atStartOfMonth(now());
  },
  clamp: (value, { limits, current }) => {
    removeCurrentSelectedValues(current);
    if (!limits) {
      return value;
    }
    if (value < limits.from) {
      return atStartOfMonth(limits.from);
    }
    if (value > limits.to) {
      return atStartOfMonth(limits.to);
    }
    return value;
  },
  format: (value, timeOffsetFormat) =>
    `${getMonth(value)}/${getYear(value)} ` +
    `${timeOffsetFormat ? timeOffsetFormat : ""}`,
  toJSON: (value) => ({
    from: value.toISOString(),
    to: atEndOfMonth(value).toISOString(),
  }),
  options: (selectedValue, { limits }) => {
    const selectedYear = getYear(selectedValue);
    const evenStartYear = selectedYear - (selectedYear % 2);
    return createOptions(evenStartYear, selectedValue, limits);
  },
  selectOption: (option, options, { limits, current }, type, defaultDate) => {
    if (current) {
      current.currentSelectedValue = option.value;
      current.currentSelectedValueType = type;
    } else if (defaultDate) {
      defaultDate.value = option.value;
      defaultDate.type = type;
    }
    return createOptions(options.page1.year, option.value, limits);
  },
  nextOptions: (currentOptions, { limits }) => {
    return createOptions(
      currentOptions.page2.year + 1,
      currentOptions.selectedValue,
      limits
    );
  },
  prevOptions: (currentOptions, { limits }) => {
    return createOptions(
      currentOptions.page1.year - 2,
      currentOptions.selectedValue,
      limits
    );
  },
};

function createOptions(startYear, selectedValue, limits) {
  return {
    selectedValue,
    page1: {
      year: startYear,
      items: createMonthItems(startYear, selectedValue, limits),
    },
    page2: {
      year: startYear + 1,
      items: createMonthItems(startYear + 1, selectedValue, limits),
    },
    hasNext: limits && getYear(limits.to) > startYear + 1,
    hasPrev: limits && getYear(limits.from) < startYear,
  };
}

function createMonthItems(year, selectedValue, limits) {
  const items = [];
  for (let i = 1; i <= 12; i++) {
    const value = createDate(year, i);
    items.push({
      value,
      title: getMonthName(i),
      isSelected: isEqual(value, selectedValue),
      isEnabled:
        limits &&
        value >= atStartOfMonth(limits.from) &&
        value <= atStartOfMonth(limits.to),
    });
  }
  return items;
}

function getCurrentValue(currentValue, current, limits) {
  if (currentValue.to) {
    return atStartOfMonth(currentValue.to);
  }
  if (!current.currentSelectedValue && current.range && limits && limits.to) {
    const rangeTo = parseDateFrom(current.range.to);
    return atStartOfMonth(rangeTo < limits.to ? rangeTo : limits.to);
  }
  if (limits && limits.from) {
    return atStartOfMonth(
      currentValue > limits.from ? currentValue : limits.from
    );
  }
  return atStartOfMonth(currentValue);
}
