export enum OperationClassID {
  DP = 0,
  Transit = 1,
  AHTS = 2,
  Fishing = 3,
  Passive = 4,
  General = 5,
}

export const operationClassTitle: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "DP",
  [OperationClassID.Transit]: "Transit",
  [OperationClassID.AHTS]: "Anchor Handling Tug Supply",
  [OperationClassID.Fishing]: "Fishing",
  [OperationClassID.Passive]: "Passive",
  [OperationClassID.General]: "General",
};

export const operationClassLegend: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "DP",
  [OperationClassID.Transit]: "T",
  [OperationClassID.AHTS]: "AHTS",
  [OperationClassID.Fishing]: "F",
  [OperationClassID.Passive]: "P",
  [OperationClassID.General]: "G",
};

export const operationClassColor: Record<OperationClassID, string> = {
  [OperationClassID.DP]: "#ff9a53",
  [OperationClassID.Transit]: "#2ee4f4",
  [OperationClassID.AHTS]: "#e76670",
  [OperationClassID.Fishing]: "#efce65",
  [OperationClassID.Passive]: "#757e7e",
  [OperationClassID.General]: "light grey",
};
