const fixedPrecision = (d) => {
  return Math.floor(d * 1000);
};

export default (l1, l2) => {
  if (!l1 || !l2) {
    return false;
  }
  if (l1 === l2) {
    return true;
  }
  if (!l1 || !l2) {
    return false;
  }
  if (l1.lat === l2.lat && l1.lng === l2.lng) {
    return true;
  }
  return (
    fixedPrecision(l1.lat) === fixedPrecision(l2.lat) &&
    fixedPrecision(l1.lng) === fixedPrecision(l2.lng)
  );
};
