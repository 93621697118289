import { get } from "lodash";
import { COMPONENTS } from "../config";

export const checkParamsValidity = (value) => {
  let errorMessage = "";
  try {
    const rows = JSON.parse(value);
    for (const row of rows) {
      for (const item of row) {
        if (!COMPONENTS.includes(item.type) || !areParamsValid(item)) {
          errorMessage = "Not a valid Configuration";
          break;
        }
      }
      if (errorMessage) {
        break;
      }
    }
    return errorMessage;
  } catch {
    return errorMessage;
  }
};

export const areParamsValid = (item) => {
  switch (item.type) {
    case "DONUTS":
      return item.params && item.params.itemTypeMetricIds;
    case "GAUGE":
      return item.params && item.params.performanceIndicatorId;
    case "ENERGY_BUDGET_RESULT":
      return item.params && item.params.metricId;
    case "ENERGY_BUDGET_OVERVIEW":
      return item.params && item.params.metricIds;
    case "MONTHLY_FUEL_USAGE":
      return item.params && item.params.itemTypeMetricId;
    default:
      return true;
  }
};

export const checkJsonValidity = (value) => {
  if (value) {
    try {
      JSON.parse(value);
    } catch {
      return false;
    }
  }
  return true;
};

export const isComponentAlreadyAdded = (type, rowsConfig) => {
  return type !== "GAUGE" && rowsConfig.some((x) => x.type === type);
};

export const checkRowsConfigValidity = (rowsConfig) => {
  let errorMessage = "";
  for (const element of rowsConfig) {
    if (element.id.includes("Invalid")) {
      errorMessage = `There is a component with ${getComponentTitle(
        element.id
      ).toLowerCase()}.`;
      break;
    }
  }
  return errorMessage;
};

export const getComponentTitle = (id) => {
  return id.substring(id.indexOf("_") + 1);
};

export const convertToArray = (value) => {
  return [].concat(value).map((v) => v);
};

export const getMetrics = (metricIds, metrics) => {
  return metricIds
    .map((id) => {
      const metric = metrics.find((mi) => mi.name === id);
      return get(metric, "label");
    })
    .filter((x) => x);
};
