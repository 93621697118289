import t from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../components/Header";
import { NavAdmin } from "../../components/NavAdmin";
import styles from "./Admin.css";

class Admin extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Header title="Administration" />
        <div className={styles.container}>
          <NavAdmin menu={this.props.nav.menu.admin} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

Admin.propTypes = {
  nav: t.shape({
    menu: t.object.isRequired,
  }),
};

const mapStateToProps = (state) => ({ nav: state.nav });
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(Admin);
