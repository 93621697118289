import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
export const createTelemetryService = () => {
  const initialize = (instrumentationKey, browserHistory) => {
    if (!browserHistory || !instrumentationKey) {
      return;
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: true,
        disableAjaxTracking: true,
        autoTrackPageVisitTime: true,
        //enableAutoRouteTracking: true, // enable to track query string parameters. Seems buggy in current version
        excludeRequestFromAutoTrackingPatterns: [
          /\/api\//i, // backend dependency
          /auth/i, // auth server
          /sockjs/i, // socket connection
        ],
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });

    appInsights.loadAppInsights();
  };

  return { appInsightsReact: reactPlugin, appInsights, initialize };
};

export const getAppInsights = () => appInsights;
