import PropTypes from "prop-types";
import React from "react";
import Image from "../../../components/Image";
import styles from "./ProfileImage.css";

const ProfileImage = ({ imageUrl, name, companyName }) => {
  return (
    <div className={styles.profileImageContainer}>
      <div className={styles.imageContainer}>
        <Image
          src={imageUrl}
          titleText="Profile image"
          altText="Profile image"
          loadingText="Loading profile image"
        />
        <div className={styles.imageGradient} />
      </div>
      <div className={styles.titleContainer}>
        <h2 className={styles.profileName}>{name}</h2>
        <p className={styles.subHeader}>{companyName}</p>
      </div>
    </div>
  );
};

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  companyName: PropTypes.string,
};

export default ProfileImage;
