import * as React from "react";
import t from "prop-types";
import styles from "./ReportWidget.css";
import { map } from "lodash";
import classNames from "../../../common/classNames";
import Loader from "../../../common/components/Loader";

export default class ReportWidget extends React.PureComponent {
  renderContent() {
    const { reports, isLoading, error } = this.props;
    if (Boolean(isLoading) === true || error) {
      return (
        <Loader
          text={"Loading report"}
          className={styles.loader}
          error={error}
        />
      );
    }
    return (
      <div>
        {reports.map((report, reportIdx) => (
          <div key={`report_${reportIdx}`} className={styles.reportContainer}>
            {report.title && <div className={styles.title}>{report.title}</div>}
            <div className={styles.rowContainer}>
              {report.rows &&
                report.rows.map((r, rowIdx) => (
                  <div className={styles.row} key={`report_row_${rowIdx + 1}`}>
                    {report.columns &&
                      map(report.columns, (col, colIdx) => {
                        const value = col.value(r);
                        const align = col.align || "left";
                        return (
                          <div
                            className={classNames(styles.value, col.className)}
                            style={{ textAlign: align }}
                            key={`report_value_${rowIdx + 1}_${colIdx}`}
                          >
                            {value}
                          </div>
                        );
                      })}
                  </div>
                ))}
              {report.footer && (
                <div className={classNames(styles.row, styles.footer)}>
                  {report.footer(report)}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { title } = this.props;
    return (
      <div className={styles.container}>
        {title && <div className={styles.title}>{title}</div>}
        {this.renderContent()}
      </div>
    );
  }
}

ReportWidget.propTypes = {
  title: t.string,
  reports: t.arrayOf(
    t.shape({
      title: t.string,
      columns: t.objectOf(
        t.shape({
          value: t.func.isRequired,
          className: t.string,
        })
      ),
      rows: t.arrayOf(t.any),
      footer: t.func,
    })
  ),
  isLoading: t.bool,
  error: t.string,
};
