import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import styles from "./ComponentInfo.css";

export default class ComponentInfo extends React.PureComponent {
  state = {
    clicked: false,
    hover: false,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    const { modalTargetId } = this.props;
    this.modalTarget = document.getElementById(modalTargetId);
    this.modalTarget?.appendChild(this.el);
    window.addEventListener("click", this.handleTargetClick);

    if (this.popupContainer) {
      this.positionPopupContainer();
    }
  }

  handleTargetClick = () => {
    this.setState({
      clicked: false,
    });
  };

  componentWillUnmount() {
    this.modalTarget?.removeChild(this.el);
    window.removeEventListener("click", this.handleTargetClick);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.popupContainer && this.isVisible() !== this.isVisible(prevState)) {
      this.positionPopupContainer();
    }
  }

  static offset(el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  getPosition = () => {
    const { xMargin, yMargin } = this.props;
    const offset = ComponentInfo.offset(this.modalTarget);
    const elOffset = ComponentInfo.offset(this.popupButton);
    const elBounds = this.popupButton.getBoundingClientRect();
    const popupBounds = this.popupContainer.getBoundingClientRect();
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let x, y;
    if (
      elOffset.left + popupBounds.width + xMargin >
      windowWidth + scrollLeft
    ) {
      x = elOffset.left - offset.left - xMargin - popupBounds.width;
    } else {
      x = elOffset.left - offset.left + elBounds.width + xMargin;
    }
    if (
      elOffset.top + popupBounds.height + yMargin >
      windowHeight + scrollTop
    ) {
      y = elOffset.top - offset.top - yMargin - popupBounds.height;
    } else {
      y = elOffset.top - offset.top + yMargin;
    }
    return { x, y };
  };

  positionPopupContainer = () => {
    const position = this.getPosition();
    this.popupContainer.style.visibility = "visible";
    this.popupContainer.style.left = `${position.x}px`;
    this.popupContainer.style.top = `${position.y}px`;
  };

  handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({
      clicked: !this.state.clicked,
    });
  };

  isVisible({ hover, clicked } = this.state) {
    return clicked || hover;
  }

  renderPopup() {
    if (!this.isVisible()) return;
    const { text, title, width, height, template } = this.props;
    return ReactDOM.createPortal(
      <div
        className={styles.popupContainer}
        ref={(el) => (this.popupContainer = el)}
        style={{
          visibility: "hidden",
        }}
      >
        <div
          className={styles.popupInnerContainer}
          style={{
            width: width && (width >= 0 ? `${width}px` : "inherit"),
            height: height && (height >= 0 ? `${height}px` : "inherit"),
          }}
        >
          {template}
          {!template && title && <h1>{title}</h1>}
          {!template && <p>{text}</p>}
        </div>
      </div>,
      this.el
    );
  }

  renderPopupButton() {
    const { buttonStyle, buttonText, buttonClick } = this.props;
    return (
      <div
        className={styles.popupButton}
        onClick={buttonClick || this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-visible={this.isVisible()}
        style={buttonStyle}
        ref={(el) => (this.popupButton = el)}
      >
        <span>{buttonText || "i"}</span>
        {this.renderPopup()}
      </div>
    );
  }

  render() {
    const { children } = this.props;
    if (!children) {
      return this.renderPopupButton();
    }
    return (
      <div className={styles.container}>
        <div className={styles.content}>{children}</div>
        {this.renderPopupButton()}
      </div>
    );
  }
}

ComponentInfo.defaultProps = {
  xMargin: 8,
  yMargin: 0,
  width: 300,
};

ComponentInfo.propTypes = {
  modalTargetId: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  template: PropTypes.object,
  xMargin: PropTypes.number,
  yMargin: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  buttonStyle: PropTypes.object,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func,
};
