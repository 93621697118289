import { prepareChangedMonthsPayload } from "../../reducers/admin/energyBudget/reducer.monthsForm";
import fetch, { post } from "../action.fetch";
import { admin } from "../action.types";
import { ENERGY_BUDGET_ADMIN } from "../../api";

export const fetchVessels = () =>
  fetch({
    url: ENERGY_BUDGET_ADMIN.getVessels(),
    actionType: admin.energyBudget.FETCH_VESSELS,
  });

export const fetchEnergyBudgetForVessel = (vesselId, metricId) =>
  fetch({
    url: ENERGY_BUDGET_ADMIN.getForVessel(vesselId, metricId),
    actionType: admin.energyBudget.FETCH,
    actionData: { vesselId, metricId },
  });

export const updateQueryParams = (
  navigate,
  queryParams,
  { replace = false } = {}
) => navigate(`/admin/energybudget`, { queryParams, replace });

export const initMonthsForm = (data) => ({
  type: admin.energyBudget.INIT_MONTHS_FORM,
  data,
});

export const setMonthValue = (data) => ({
  type: admin.energyBudget.SET_MONTH_VALUE,
  ...data,
});

export const formatMonthValue = (data) => ({
  type: admin.energyBudget.FORMAT_MONTH_VALUE,
  ...data,
});

export const resetMonthsForm = () => ({
  type: admin.energyBudget.RESET_MONTHS_FORM,
});

export const saveMonthsForm = (vesselId, metricId, form) =>
  post({
    url: ENERGY_BUDGET_ADMIN.postForVessel(vesselId, metricId),
    actionType: admin.energyBudget.SAVE_MONTHS_FORM,
    payload: prepareChangedMonthsPayload(form),
  });
