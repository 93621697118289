import t from "prop-types";
import React, { Component } from "react";
import * as d3 from "d3";
import styles from "../../FuelConsumption/FuelConsumption.css";

class AxisWrapper extends Component {
  componentDidMount() {
    this.renderAxis();
  }

  componentDidUpdate() {
    this.renderAxis();
  }

  renderAxis() {
    d3.select(this.el).call(this.props.createAxis());
  }

  render() {
    return (
      <g
        className={styles.axis}
        ref={(el) => (this.el = el)}
        transform={this.props.transform}
      />
    );
  }
}

AxisWrapper.propTypes = {
  createAxis: t.func.isRequired,
  transform: t.string.isRequired,
};

const timeTickFormats = {
  month: d3.timeFormat("%b"),
  day: d3.timeFormat("%b %d"),
};

const amountAxisFormat = (unit) => (val) => !val ? null : `${val} ${unit}`;

const sizePropType = t.shape({
  margin: t.shape({
    left: t.number.isRequired,
    right: t.number.isRequired,
    top: t.number.isRequired,
    bottom: t.number.isRequired,
  }).isRequired,
  width: t.number.isRequired,
  height: t.number.isRequired,
});

export const TimeAxis = ({ size, scale, interval, data }) => {
  return (
    <AxisWrapper
      transform={`translate(${size.margin.left}, ${
        size.height - size.margin.bottom
      })`}
      createAxis={() =>
        d3
          .axisBottom(scale)
          .ticks(Math.min(6, data.length))
          .tickFormat(timeTickFormats[interval])
      }
    />
  );
};

TimeAxis.propTypes = {
  size: sizePropType.isRequired,
  scale: t.any.isRequired,
  interval: t.oneOf(["day", "month"]).isRequired,
  data: t.array.isRequired,
};

export const AmountAxis = ({ size, scale, unit }) => {
  return (
    <AxisWrapper
      transform={`translate(${size.margin.left}, ${size.margin.top})`}
      createAxis={() =>
        d3.axisLeft(scale).ticks(4).tickFormat(amountAxisFormat(unit))
      }
    />
  );
};

AmountAxis.propTypes = {
  size: sizePropType.isRequired,
  scale: t.any.isRequired,
  unit: t.string.isRequired,
};
