import {
  FETCH_POSITION_DETAILS_SUCCESS,
  FETCH_POSITION_DETAILS_ERROR,
} from "../actions/action.types";

const fetchPositionDetails = (
  state = {
    data: { vesselId: "" },
  },
  action = {}
) => {
  switch (action.type) {
    case FETCH_POSITION_DETAILS_SUCCESS:
      return {
        ...state,
        data: { ...action.data },
      };
    case FETCH_POSITION_DETAILS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default fetchPositionDetails;
