import {
  fork,
  put,
  takeLatest,
  getContext,
  call,
  select,
} from "redux-saga/effects";
import { hullPerformance } from "../actions/action.types";
import { EVENTS, hullPerformanceApi } from "../api";
import { get, isNil } from "lodash";
import { getInterval } from "../reducers/reducer.hullPerformance";
import { EVENT_TYPES } from "../config";

function* initializeHullPerformance({ vesselId }) {
  try {
    if (isNil(vesselId)) {
      throw new Error("No vessel selected");
    }

    const selectedVessel = yield select((s) => s.pageContext.vessels[vesselId]);
    const dockEvents = yield call(fetchDockEvents, vesselId);
    const interval = yield call(
      setInterval,
      dockEvents,
      get(selectedVessel, "startTime")
    );

    yield call(fetchPerformanceValues, { vesselId, interval });
    yield put({
      type: hullPerformance.INITIALIZE_SUCCESS,
      vesselId,
      interval,
    });
  } catch (err) {
    yield put({
      type: hullPerformance.INITIALIZE_ERROR,
      error: getErrorMessage(err),
    });
  }
}

function* fetchDockEvents(vesselId) {
  try {
    const http = yield getContext("http");
    yield put({ type: hullPerformance.FETCH_DOCK_EVENTS, vesselId });
    const { data } = yield call(
      http.get,
      EVENTS.getEventsForVesselByEventType(vesselId, EVENT_TYPES.dryDock)
    );
    yield put({
      type: hullPerformance.FETCH_DOCK_EVENTS_SUCCESS,
      data,
    });
    return data;
  } catch (err) {
    yield put({
      type: hullPerformance.FETCH_DOCK_EVENTS_ERROR,
      error: getErrorMessage(err),
    });
  }
}

function* fetchPerformanceValues({ vesselId, interval }) {
  try {
    const http = yield getContext("http");
    const { from, to } = interval;

    yield put({ type: hullPerformance.FETCH_PERFORMANCE_VALUES, vesselId });
    const response = yield call(
      http.get,
      hullPerformanceApi.getPerformanceValues(vesselId, from, to)
    );
    yield put({
      type: hullPerformance.FETCH_PERFORMANCE_VALUES_SUCCESS,
      performanceValues: get(response, "data.performanceValues", []),
      performanceIndicators: get(response, "data.performanceIndicators", []),
    });
  } catch (err) {
    yield put({
      type: hullPerformance.FETCH_PERFORMANCE_VALUES_ERROR,
      error: getErrorMessage(err),
    });
  }
}

function* setInterval(dockEvents, startTime) {
  try {
    const now = yield getContext("now");
    yield put({
      type: hullPerformance.SET_INTERVAL,
    });
    let interval = getInterval(dockEvents, startTime, now());
    yield put({
      type: hullPerformance.SET_INTERVAL_SUCCESS,
      ...interval,
    });
    return interval;
  } catch (err) {
    yield put({
      type: hullPerformance.SET_INTERVAL_ERROR,
      error: getErrorMessage(err),
    });
  }
}

function* initializeHullPerformanceWatcher() {
  yield takeLatest(hullPerformance.INITIALIZE, initializeHullPerformance);
}

export function* hullPerformanceSagas() {
  yield fork(initializeHullPerformanceWatcher);
}

function getErrorMessage(error) {
  return (
    get(error, ["response", "data", "message"]) ||
    get(error, "message") ||
    error
  );
}
