import t from "prop-types";
import React from "react";
import classNames from "../../../common/classNames";
import { Icon } from "../../../common/components/Icon/Icon";
import styles from "./TopList.css";
import { formatNumber } from "../../../common/numbers";

const TopListItem = (props) => (
  <div className={styles.item}>
    <div className={styles.itemName}>{props.vesselName}</div>
    <div className={styles.itemValues}>
      <div className={styles.main}>
        <span
          className={classNames(
            styles.mainValue,
            props.performanceValue < 0 && styles.negative
          )}
        >
          {formatNumber(props.performanceValue)}
        </span>
        <span>%</span>
      </div>
    </div>
  </div>
);

TopListItem.propTypes = {
  vesselId: t.string.isRequired,
  vesselName: t.string.isRequired,
  performanceValue: t.number.isRequired,
};

const TopList = ({ title, fieldLabel, data, period }) => (
  <div className={styles.topList}>
    <header className={styles.header}>
      <span className={styles.icon}>
        <Icon icon="performance" />
      </span>
      <span className={styles.title}>
        {title}
        <br />
        {fieldLabel}
      </span>
      <span className={styles.period}>{period}</span>
    </header>
    <section className={styles.items}>
      {data.map((item, key) => (
        <TopListItem key={key} {...item} />
      ))}
    </section>
  </div>
);

TopList.propTypes = {
  title: t.string.isRequired,
  fieldLabel: t.string.isRequired,
  data: t.array.isRequired,
  period: t.string.isRequired,
};

export default TopList;
