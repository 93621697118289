import { call, fork, put, takeLeading } from "redux-saga/effects";
import { admin } from "./../../actions/action.types";
import download from "downloadjs";
import { createExcelDocument } from "../../common/fileExcelWriter";

function* downloadWatcher() {
  yield takeLeading(admin.users.DOWNLOAD_USERS_EXAMPLE, downloadUsersSaga);
}

function* downloadUsersSaga({ data, fileName }) {
  const blobData = yield call(createExcelDocument, data);
  try {
    yield call(download, blobData, fileName, "application/vnd.ms-excel");
  } catch (err) {
    yield put({ type: admin.users.DOWNLOAD_USERS_EXAMPLE_ERROR, error: err });
  }
}

export function* usersSaga() {
  yield fork(downloadWatcher);
}
