import React from "react";
import { Header } from "../../components/Header";
import { HullPerformance } from "../../components/HullPerformance/HullPerformance";
import { connect } from "react-redux";
import { initialize } from "../../actions/action.hullPerformance";
import Loader from "../../../common/components/Loader/Loader";
import { get, some, isNil, isEqual } from "lodash";
import styles from "./HullPerformanceView.css";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import { getXDomain, getYDomain } from "../../reducers/reducer.hullPerformance";
import { createSelector } from "reselect";
import {
  convertFiltersToQueryParams,
  getGroupFilter,
  getVesselFilter,
} from "../../selectors/filters";
import { VIEWS } from "../../config";

class HullPerformanceView extends React.PureComponent {
  componentDidMount() {
    const { filters } = this.props;
    if (!this.props.filters.isValid) {
      const queryParams = convertFiltersToQueryParams(filters);
      this.props.queryContext.navigate(
        VIEWS.hullPerformance.url,
        queryParams,
        true
      );
    }
    this.props.initialize({
      vesselId: filters.vesselFilter.value,
    });
  }
  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (filters.isValid && !isEqual(filters, prevProps.filters)) {
      this.props.initialize({
        vesselId: filters.vesselFilter.value,
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <Header title="Hull performance" contentDistribution="space-between">
          <VesselSelector />
        </Header>
        <div className={styles.contentContainer}>{this.renderContent()}</div>
      </div>
    );
  }

  renderContent() {
    const {
      isLoading,
      error,
      performanceValues,
      dryDockEvents,
      performanceIndicators,
      xDomain,
      yDomain,
      vesselId,
    } = this.props;

    if (isLoading || error) {
      return <Loader expand={isLoading} error={error} />;
    }
    return (
      vesselId && (
        <HullPerformance
          performanceValues={performanceValues}
          performanceIndicators={performanceIndicators}
          dryDockEvents={dryDockEvents}
          xDomain={xDomain}
          yDomain={yDomain}
        />
      )
    );
  }
}

const getHullPerformaneViewFilters = createSelector(
  [getVesselFilter, getGroupFilter],
  (vesselFilter, groupFilter) => {
    return {
      isValid: vesselFilter.isValid && groupFilter.isValid,
      vesselFilter,
      groupFilter,
    };
  }
);

const mapStateToProps = (state, props) => {
  const performanceValues = get(
    state,
    "hullPerformance.performance.performanceValues",
    []
  );
  const performanceIndicators = get(
    state,
    "hullPerformance.performance.performanceIndicators",
    []
  );
  const dryDockEvents = get(state, "hullPerformance.dryDockEvents.data", []);

  const interval = get(state, "hullPerformance.interval");

  const xDomain =
    interval &&
    getXDomain(
      interval,
      dryDockEvents,
      performanceValues,
      performanceIndicators
    );
  const yDomain = getYDomain(performanceValues);

  const filters = getHullPerformaneViewFilters(state, props);

  return {
    filters,
    vesselId: get(filters, "vesselFilter.value"),
    queryContext: props.queryContext,
    performanceValues,
    performanceIndicators,
    dryDockEvents,
    xDomain: [get(xDomain, "from"), get(xDomain, "to")],
    yDomain: [get(yDomain, "from"), get(yDomain, "to")],
    isLoading:
      state.hullPerformance.isLoading ||
      get(state, "hullPerformance.dryDockEvents.isLoading") ||
      get(state, "hullPerformance.performance.isLoading") ||
      some(xDomain, isNil),
    error:
      state.hullPerformance.error ||
      get(state, "hullPerformance.performance.error") ||
      get(state, "hullPerformance.dryDockEvents.error"),
  };
};

const mapDispatchToProps = {
  initialize,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HullPerformanceView);
