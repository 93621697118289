import React from "react";
import SelectBox from "../SelectBox";

const HmResolutionPropertyValue = ({ value, onSelect, isDisabled }) => {
  const options = [
    { id: 1, name: value === "Acknowledged" ? value : "Acknowledge" },
    { id: 2, name: "Resolved" },
    { id: 3, name: "Pending Action" },
  ];

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <SelectBox
        placeholder="Resolution Status"
        options={options.filter((option) => option.name !== value)}
        optionValKey={"id"}
        optionLabelKey={"name"}
        selected={options.find((option) => option.name === value)}
        onSelect={onSelect}
        clearable={false}
        disabled={isDisabled || false}
      />
    </div>
  );
};

export default HmResolutionPropertyValue;
