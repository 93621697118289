import memoize from "memoize-one";
import React from "react";
import PropTypes from "prop-types";
import {
  getActiveMetricItemsData,
  getActivePerformanceItemsData,
  prepareXDomain,
  prepareTickData,
} from "../../reducers/reducer.trend";
import styles from "./TrendGraphView.css";
import TrendLineGraph from "./TrendLineGraph";
import TrendXAxis from "./TrendXAxis";
import TrendOperationsGraph from "./TrendOperationsGraph";
import { get } from "lodash";

const MIN_WIDTH = 1500;

const detectFirefox = () => typeof InstallTrigger !== "undefined";

export default class TrendGraphView extends React.PureComponent {
  static propTypes = {
    vesselItems: PropTypes.array.isRequired,
    vesselItemsData: PropTypes.object.isRequired,
    performanceIndicators: PropTypes.object.isRequired,
    performanceIndicatorsData: PropTypes.object.isRequired,
    dateRange: PropTypes.object.isRequired,
    showOperations: PropTypes.bool.isRequired,
    trendContainer: PropTypes.object,
    timeOffset: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.getActiveMetricItemsData = memoize(getActiveMetricItemsData);
    this.getActivePerformanceItemsData = memoize(getActivePerformanceItemsData);
    this.prepareXDomain = memoize(prepareXDomain);
    this.prepareTickData = memoize(prepareTickData);
  }

  componentDidUpdate() {
    const { trendContainer } = this.props;
    if (detectFirefox() && get(trendContainer, "scrollTop") > 0) {
      trendContainer.scrollTop = 0;
    }
  }

  render() {
    const {
      vesselItems,
      vesselItemsData,
      performanceIndicators,
      performanceIndicatorsData,
      operationsData,
      showOperations,
      dateRange,
      timeOffset,
    } = this.props;

    const graphItems = [
      ...this.getActiveMetricItemsData({
        vesselItems,
        vesselItemsData,
        dateRange,
      }),
      ...this.getActivePerformanceItemsData({
        performanceIndicators,
        performanceIndicatorsData,
        dateRange,
      }),
    ];

    const xDomain = this.prepareXDomain(dateRange);
    const tickData = this.prepareTickData(xDomain);

    return (
      <div className={styles.trendGraphViewContainer}>
        {showOperations && (
          <TrendOperationsGraph
            operations={operationsData}
            xDomain={xDomain}
            tickData={tickData}
            hoverContext="trend"
            timeOffset={timeOffset}
          />
        )}
        <div
          className={styles.trendGraphChartContainer}
          data-test-id="trend-graphContainer"
        >
          {graphItems.map((graphItem) => {
            return (
              <TrendLineGraph
                key={graphItem.id}
                xDomain={xDomain}
                tickData={tickData}
                graphItem={graphItem}
                minWidth={MIN_WIDTH}
                hoverContext="trend"
                timeOffset={timeOffset}
              />
            );
          })}
        </div>
        <div className={styles.trendAxisContainer} data-test-id="trend-axis">
          <TrendXAxis
            xDomain={xDomain}
            tickData={tickData}
            minWidth={MIN_WIDTH}
            hoverContext="trend"
            timeOffset={timeOffset}
          />
        </div>
      </div>
    );
  }
}
