import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { startCase, get, isEqual, find, each } from "lodash";
import { getBase64 } from "../../../common/fileConverter";
import "regenerator-runtime";

export default createReducer(
  {},
  {
    [vesselConfig.onboardFrontendConfig.FETCH]: () => ({
      isLoading: true,
    }),
    [vesselConfig.onboardFrontendConfig.FETCH_SUCCESS]: (_state, { data }) => ({
      data,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(data),
      vesselImage: { src: data.imageUrl },
    }),
    [vesselConfig.onboardFrontendConfig.FETCH_ERROR]: (_state, { error }) => ({
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.INPUT_CHANGED]: (
      state,
      { field, value }
    ) => ({
      ...state,
      fields: state.fields.map((f) => (f.name !== field ? f : { ...f, value })),
    }),
    [vesselConfig.onboardFrontendConfig.DASHBOARD_INPUT_CHANGED]: (
      state,
      { row, column, component }
    ) => ({
      ...state,
      dashboard: Object.assign([...state.dashboard], {
        [row]: Object.assign([...state.dashboard[row]], {
          [column]: { value: component.name, label: component.label },
        }),
      }),
    }),
    [vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED]: (
      state,
      { file }
    ) => ({
      ...state,
      vesselImage: {
        file,
        fileName: file.name,
      },
    }),
    [vesselConfig.onboardFrontendConfig.VESSEL_IMAGE_CHANGED_ERROR]: (
      state,
      { errors }
    ) => ({
      ...state,
      vesselImageErrors: errors,
    }),
    [vesselConfig.onboardFrontendConfig.SAVE]: (state) => ({
      ...state,
      isSaving: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.SAVE_SUCCESS]: (_state, { data }) => ({
      data,
      isSaving: false,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(data),
      vesselImage: { src: data.imageUrl },
    }),
    [vesselConfig.onboardFrontendConfig.SAVE_ERROR]: (state, { error }) => ({
      ...state,
      isSaving: false,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD]: (state) => ({
      ...state,
      isDownloading: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD_SUCCESS]: (state) => ({
      ...state,
      isDownloading: false,
    }),
    [vesselConfig.onboardFrontendConfig.DOWNLOAD_ERROR]: (
      state,
      { error }
    ) => ({
      ...state,
      isDownloading: false,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY]: (state) => ({
      ...state,
      isDeploying: true,
      error: null,
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY_SUCCESS]: (
      _state,
      { data }
    ) => ({
      data,
      fields: getMenuConfigFields(data),
      dashboard: getDashboardConfig(data),
    }),
    [vesselConfig.onboardFrontendConfig.DEPLOY_ERROR]: (state, { error }) => ({
      ...state,
      isDeploying: true,
      error: get(error, "message", error),
    }),
    [vesselConfig.onboardFrontendConfig.SHOW_VESSEL_IMAGE_PREVIEW]: (
      state
    ) => ({
      ...state,
      showPopup: true,
    }),
    [vesselConfig.onboardFrontendConfig.HIDE_VESSEL_IMAGE_PREVIEW]: (
      state
    ) => ({
      ...state,
      showPopup: false,
    }),
  }
);

export const getMenuConfigFields = ({ frontendConfig } = {}) => {
  const pageLabels = {
    elbalance: "El Balance",
    energyflow: "Energy Flow",
  };

  if (frontendConfig !== undefined && frontendConfig.pages !== undefined) {
    let sortedPages = {};
    Object.keys(frontendConfig.pages)
      .sort()
      .forEach((key) => {
        sortedPages[key] = frontendConfig.pages[key];
      });

    return Object.entries(sortedPages).map((page) => ({
      name: page[0],
      value: page[1],
      label: get(pageLabels, page[0], startCase(page[0])),
    }));
  }
  return [];
};

export const getDashboardConfig = ({ frontendConfig } = {}) => {
  if (
    frontendConfig !== undefined &&
    frontendConfig.dashboardConfig !== undefined
  ) {
    const { rows } = frontendConfig.dashboardConfig;
    rows[0].length === 4 && rows[0].unshift("operation");
    rows[1].length === 4 && rows[1].unshift("spacer");

    return rows.map((row) => {
      return row.map((component) => ({
        value: component,
        label: find(componentLabels, ["name", component]).label,
      }));
    });
  }
  return [];
};

const componentLabels = [
  { name: "spacer", label: "Spacer" },
  { name: "sailingPerformance", label: "Sailing Performance" },
  { name: "speedOverGround", label: "Speed Over Ground" },
  { name: "passivePerformance", label: "Passive Performance" },
  { name: "dashboardPower", label: "Power" },
  { name: "voyageInformation", label: "Voyage" },
  { name: "operatingCost", label: "Operating Cost" },
  { name: "legInformation", label: "Leg" },
  { name: "weather", label: "Weather" },
  { name: "waveRadar", label: "Wave Radar" },
  { name: "operation", label: "Operation" },
];

export const prepSave = async (fields, dashboard, vesselImage) => {
  let vesselImageBase64 = await getBase64(vesselImage.file);
  let config = {
    frontendConfig: { pages: {}, dashboardConfig: {} },
    vesselImageBase64,
    vesselImageUrl: vesselImage.src,
  };

  each(fields, (field) => {
    config.frontendConfig.pages[field.name] = field.value;
  });

  config.frontendConfig.dashboardConfig.rows = dashboard.map((row) => {
    return row.map((component) => component.value);
  });

  return config;
};

export const prepState = (state) => {
  const {
    data,
    fields,
    dashboard,
    isSaving,
    isDeploying,
    isLoading,
    error,
    vesselImage,
    vesselImageErrors,
  } = state;
  let hasChanges = fields && !isEqual(getMenuConfigFields(data), fields);
  hasChanges =
    hasChanges || (dashboard && !isEqual(getDashboardConfig(data), dashboard));
  hasChanges = hasChanges || vesselImage?.file;
  const savedOn = data && (data.modifiedOn || data.createdOn);
  return {
    componentLabels,
    dashboard,
    fields,
    error,
    isLoading,
    savedOn,
    deployedOn: data && data.deployedOn,
    canSave:
      !isSaving && (!savedOn || hasChanges) && !vesselImageErrors?.length,
    canDownload: !isSaving && !hasChanges && Boolean(savedOn),
    canDeploy: !isDeploying && !isSaving && !hasChanges && Boolean(savedOn),
    vesselImage,
    vesselImageErrors,
  };
};
