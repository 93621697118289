import React from "react";
import styles from "./styling/styles.css";
import brandLogo from "../../style/img/logo.png";
import { useNavigate } from "react-router-dom";

function LoginHeader() {
  const navigate = useNavigate();

  return (
    <div className={styles.mainContainerHeader}>
      <img
        alt={"Kongsberg logo"}
        className={styles.brandLogo}
        src={`${brandLogo}`}
      />
      <div className={styles.pageTitle}>Vessel Performance</div>
      <div onClick={() => navigate("/authPrelim")}>Log In</div>
    </div>
  );
}

export default LoginHeader;
