import T from "prop-types";
import React from "react";
import styles from "./VesselDetails.css";
import VesselDetailsIndicator from "./VesselDetailsIndicator";
import Loader from "../../../common/components/Loader/Loader";
import MapMarker from "./../../components/MapMarker/MapMarker";
import ButtonClose from "../ButtonClose";
import VesselTrack from "../VesselTrack/VesselTrack";

const propTypes = {
  isLoading: T.bool,
  performanceIndicator: T.string,
  vesselName: T.string,
  speed: T.number,
  connectivity: T.string,
  position: T.shape({
    lat: T.number,
    lng: T.number,
  }),
  indicators: T.array,
  operationalProfile: T.shape({
    profile: T.string,
  }),
  onClose: T.func.isRequired,
  ...VesselTrack.propTypes,
};

const defaultProps = {
  isLoading: true,
  performanceIndicator: "",
  vesselName: "",
  speed: 0,
  connectivity: "",
  position: {
    lat: 0,
    lng: 0,
  },
  indicators: [],
  operationalProfile: {
    profile: "",
  },
};

const renderPerformanceIndicator = (x, key) => {
  const value = "performanceValue" in x ? x.performanceValue : x.actualValue;
  const trend = "performanceValue" in x;

  return (
    <VesselDetailsIndicator
      key={key}
      value={value}
      trend={trend}
      unit={x.unit}
      description={x.label}
    />
  );
};

const VesselDetails = (props) => {
  if (props.isLoading) {
    return (
      <div className={styles.vesselDetailsContainer}>
        <Loader text="Loading position data" />
      </div>
    );
  }
  return (
    <div className={styles.vesselDetailsContainer}>
      <ButtonClose clickAction={props.onClose} title={"Close"} />
      <div className={styles.vesselDetailsTopContainer}>
        <div className={styles.vesselDetailsHeaderContainer}>
          <div className={styles.vesselDetailsHeaderIconContainer}>
            <MapMarker
              connection={props.connectivity}
              efficiency={props.performanceIndicator || "noValue"}
            />
          </div>
          <div className={styles.vesselNameContainer}>
            <div className={styles.vesselHeading}>{props.vesselName}</div>
            <div className={styles.vesselSubHeading}>
              {props.operationalProfile.profile}
            </div>
          </div>
        </div>
        <div className={styles.indicatorContainer}>
          <VesselDetailsIndicator
            description="Speed"
            unit="kn"
            value={props.speed}
          />
          {props.indicators.map(renderPerformanceIndicator)}
        </div>
        <div className={styles.indicatorContainer}>
          <VesselDetailsIndicator
            description="Latitude"
            unit={props.position.lat >= 0 ? "N" : "S"}
            value={Math.abs(props.position.lat)}
          />
          <VesselDetailsIndicator
            description="Longitude"
            unit={props.position.lng >= 0 ? "E" : "W"}
            value={Math.abs(props.position.lng)}
          />
        </div>
      </div>
      <div className={styles.vesselTrackContainer}>
        <VesselTrack {...props} />
      </div>
    </div>
  );
};

VesselDetails.propTypes = propTypes;

VesselDetails.defaultProps = defaultProps;

export default VesselDetails;
