import createReducer from "../../createReducer";
import { vesselConfig } from "../../../actions/action.types";
import { max } from "lodash";
import { getErrorMessage } from "../../../common/error";
const actionTypes = vesselConfig.energyFlow;

const initialState = {
  items: [],
  relations: [],
  layout: [],
  selectedVesselItem: undefined,
  isFormOpen: false,
  hasChanges: false,
};

export default createReducer(initialState, {
  [actionTypes.INITIALIZE]: (_state, { vesselId }) => ({
    ...initialState,
    vesselId,
  }),
  [actionTypes.FETCH_LAYOUT]: (state) => ({
    ...state,
    layout: [],
  }),
  [actionTypes.FETCH_LAYOUT_SUCCESS]: (state, { data }) => ({
    ...state,
    layout: data,
  }),
  [actionTypes.FETCH_LAYOUT_ERROR]: (state, { error }) => ({
    ...state,
    error: getErrorMessage({ error }),
  }),
  [actionTypes.FETCH_ITEMS]: (state) => ({
    ...state,
    items: [],
  }),
  [actionTypes.FETCH_ITEMS_SUCCESS]: (state, { data }) => ({
    ...state,
    items: data,
  }),
  [actionTypes.FETCH_ITEMS_ERROR]: (state, { error }) => ({
    ...state,
    error: getErrorMessage({ error }),
  }),
  [actionTypes.FETCH_RELATIONS]: (state) => ({
    ...state,
    relations: [],
  }),
  [actionTypes.FETCH_RELATIONS_SUCCESS]: (state, { data }) => ({
    ...state,
    relations: data,
  }),
  [actionTypes.FETCH_RELATIONS_ERROR]: (state, { error }) => ({
    ...state,
    error: getErrorMessage({ error }),
  }),
  [actionTypes.VESSELITEM_SELECTED]: (state, { selectedVesselItem }) => ({
    ...state,
    selectedVesselItem,
  }),
  [actionTypes.VESSELITEM_NEW_OPEN]: (state) => ({
    ...state,
    isFormOpen: true,
  }),
  [actionTypes.VESSELITEM_NEW_CLOSE]: (state) => ({
    ...state,
    isFormOpen: false,
  }),
  [actionTypes.VESSELITEM_NEW_ADD]: (state, { vesselItem }) => ({
    ...state,
    selectedVesselItem: null,
    hasChanges: true,
    layout: [
      ...state.layout,
      {
        x: 0,
        y: 0,
        vesselItemId: vesselItem.id,
        name: vesselItem.name,
        remoteItemName: vesselItem.remoteItemName,
      },
    ],
  }),
  [actionTypes.ADD_RELATION]: (state, { sourceId, destinationId }) => ({
    ...state,
    relations: [...state.relations, { sourceId, destinationId }],
  }),
  [actionTypes.DELETE_RELATION]: (state, { sourceId, destinationId }) => ({
    ...state,
    relations: state.relations.filter((x) => {
      return !(x.destinationId === destinationId && x.sourceId === sourceId);
    }),
  }),
  [actionTypes.UPDATE_NODE]: (state, { id, position }) => ({
    ...state,
    hasChanges: true,
    layout: [
      ...state.layout.map((n) => {
        if (n.vesselItemId === id) {
          return {
            ...n,
            transformX: position.x,
            transformY: position.y,
          };
        } else {
          return n;
        }
      }),
    ],
  }),
  [actionTypes.REMOVE_NODE]: (state, { id }) => ({
    ...state,
    layout: state.layout.filter((x) => {
      return x.vesselItemId !== id;
    }),
    relations: state.relations.filter((x) => {
      return !(x.destinationId === id || x.sourceId === id);
    }),
  }),
  [actionTypes.SAVE]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actionTypes.SAVE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
    relations: [],
    layout: [],
  }),
  [actionTypes.SAVE_ERROR]: (state, { error }) => {
    return {
      ...state,
      isLoading: false,
      error: getErrorMessage({ error }),
    };
  },
});

export const convertCoordinateToTopLeft = (width, height) => (node) => {
  return {
    ...node,
    x: width / 2 + node.x,
    y: height / 2 + node.y,
  };
};

const DEFAULT_PANEL_HEIGHT = 200;
const DEFAULT_PANEL_WIDTH = 1000;
const PANEL_MARGIN = 150;

export const getPanelSize = ({ layout }) => {
  return {
    width: getPanelWidth(layout),
    height: getPanelHeight(layout),
  };
};

const getPanelHeight = (layout) => {
  const yValues = layout.map((l) => Math.abs(l.y + (l.transformY || 0)));
  const panelHeight = max(yValues) || 0;
  return max([panelHeight * 2 + PANEL_MARGIN, DEFAULT_PANEL_HEIGHT]);
};

const getPanelWidth = (layout) => {
  const xValues = layout.map((l) => Math.abs(l.x + (l.transformX || 0)));
  const panelWidth = max(xValues) || 0;
  return max([panelWidth * 2 + PANEL_MARGIN, DEFAULT_PANEL_WIDTH]);
};
