import React, { Component } from "react";
import styles from "./MasterChildSelect.css";
import SelectBox from "../SelectBox/SelectBox";
import PropTypes from "prop-types";

export default class MasterChildSelect extends Component {
  getMasterStyleTweaks() {
    if (this.props.direction === "column") {
      return { marginBottom: "0.5rem" };
    } else {
      return { marginRight: "0.5rem" };
    }
  }

  render() {
    return (
      <div
        className={styles.container}
        style={{
          flexDirection: this.props.direction || "row",
          maxWidth: this.props.maxWidth || "none",
        }}
      >
        <div style={this.getMasterStyleTweaks()}>
          {this.props.masterOptions.length > 1 && (
            <SelectBox
              options={this.props.masterOptions}
              optionValKey={"id"}
              optionLabelKey={"name"}
              onSelect={this.props.onMasterSelect}
              selected={this.props.selectedMaster}
              placeholder="Select vessel group..."
            />
          )}
        </div>
        <SelectBox
          options={this.props.childOptions}
          optionValKey={"id"}
          optionLabelKey={"name"}
          onSelect={this.props.onSelect}
          selected={this.props.selectedChild}
          placeholder="Select vessel..."
        />
      </div>
    );
  }
}

const idName = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

MasterChildSelect.propTypes = {
  masterOptions: PropTypes.arrayOf(idName).isRequired,
  childOptions: PropTypes.arrayOf(idName).isRequired,
  selectedMaster: idName,
  selectedChild: idName,
  direction: PropTypes.oneOf(["column", "row"]),
  maxWidth: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onMasterSelect: PropTypes.func.isRequired,
};
