import React, { useEffect } from "react";
import { Header } from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { createSelector } from "reselect";
import styles from "./FuelAndEmissionsView.css";
import {
  convertFiltersToQueryParams,
  getGroupFilter,
  getVesselFilter,
  getDateFilter,
} from "../../selectors/filters";
import { changeFilters } from "../../actions";
import { IState } from "../../components/FuelEmissions/commonTypes";
import DatePickerContainer from "../../components/DatePicker/DatePickerContainer";
import VesselSelector from "../../components/VesselSelector/VesselSelector";
import FuelEmissions from "../../components/FuelEmissions";
import Loader from "../../../common/components/Loader";
import { VIEWS } from "../../config";
import { getLimits } from "../../common/vessels";
import { getSelectedVessel } from "../../reducers/reducer.pageContext";
import { ManualFuelDataIndicator } from "../../components/ManualFuelDataIndicator/ManualFuelDataIndicator";
import { useQueryContext } from "../../providers/QueryContextProvider";

const filterSelector = (props: unknown) => (state: IState) => {
  const filters = (getPageFilters as any)(state, props);
  const activeVesselId = filters.vesselFilter.value;
  return {
    filters,
    activeVesselId,
    isLoading: state.fuelEmissions.isLoading,
    vesselDateLimits: getLimits(
      getSelectedVessel({
        selectedVesselId: activeVesselId,
        vessels: state.pageContext.vessels,
      })
    ),
  };
};

const getPageFilters = createSelector(
  [getVesselFilter, getGroupFilter, getDateFilter],
  (vesselFilter, groupFilter, dateFilter) => {
    return {
      isValid:
        vesselFilter.isValid && groupFilter.isValid && dateFilter.isValid,
      vesselFilter,
      groupFilter,
      dateFilter,
    };
  }
);

const FuelAndEmissionsView = (): JSX.Element => {
  const queryContext = useQueryContext();
  const dispatch = useDispatch();
  const dispatchAction = (action: any) => dispatch(action) as AnyAction;
  const { filters, activeVesselId, vesselDateLimits, isLoading } = useSelector(
    filterSelector({ queryContext })
  );

  useEffect(() => {
    loadEmissionData();
  }, [activeVesselId, filters]);

  const loadEmissionData = () => {
    if (filters.isValid) {
      fetchEmissionData();
    } else {
      queryContext.navigate(
        VIEWS.fuelEmissions.url,
        convertFiltersToQueryParams(filters),
        true
      );
    }
  };

  const fetchEmissionData = () => {
    dispatchAction(
      changeFilters({
        vesselId: activeVesselId,
        dateFilter: filters.dateFilter.value,
      })
    );
  };

  const dateSelected = (date: any) => {
    queryContext.setDate(date);
  };

  const useManualFuelDataOnly: boolean = useSelector((state: IState) => {
    const filters = (getPageFilters as any)(state, { queryContext });
    const activeVesselId = filters.vesselFilter.value;
    const vessel = getSelectedVessel({
      selectedVesselId: activeVesselId,
      vessels: state.pageContext.vessels,
    });
    return vessel?.useManualFuelDataOnly;
  });

  return (
    <div className={styles.fuelEmissionsViewContainer}>
      <Header title="Fuel and Emissions" contentDistribution="space-between">
        <div className={styles.headerLeftContainer}>
          <VesselSelector />
          {useManualFuelDataOnly && <ManualFuelDataIndicator />}
        </div>
        <div className={styles.headerRightContainer}>
          <DatePickerContainer
            limits={vesselDateLimits}
            current={filters.dateFilter.value}
            vesselId={activeVesselId}
            onChange={dateSelected}
            supportedTypes={["year", "quarter", "month"]}
          />
        </div>
      </Header>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader text="Loading fuel emissions details" />
        </div>
      )}
      {filters.dateFilter.value && (
        <div
          className={styles.contentContainer}
          style={{ display: isLoading ? "none" : "block" }}
        >
          <FuelEmissions />
        </div>
      )}
    </div>
  );
};

export default FuelAndEmissionsView;
