import {
  filter,
  find,
  flow,
  get,
  keyBy,
  map,
  some,
  sortBy,
  uniqBy,
} from "lodash/fp";
import { get as lodashGet } from "lodash";
import { components, FETCH_USER_DATA_SUCCESS } from "../actions/action.types";
import { getViewsByPath } from "../common/views";
import createReducer from "./createReducer";
import { isSystemAdmin } from "./reducer.user";

// TODO move date selection here as well

const keyById = keyBy("id");

export default createReducer(
  {
    dataQuality: {
      showPopup: false,
      selectedPeriod: {},
      isLoading: false,
      lastPageRefresh: undefined,
    },
    selectedVesselId: null,
    selectedGroupId: null,
    vessels: {},
    groups: {},
    vesselGroupVessels: {},
    vesselItemMetrics: [],
    states: [],
  },
  {
    [FETCH_USER_DATA_SUCCESS]: (state, { data }) => {
      const vessels = prepareVessels(data.vessels);
      const groups = prepareGroups(data.groups);
      const vesselGroupVessels = keyById(data.vesselGroupVessels);

      const selectedGroupId =
        getUserDefaultGroupId(data, groups) ||
        getFleetGroupId(groups) ||
        getFirstGroupId(groups) ||
        null;

      const selectedVesselId =
        (selectedGroupId &&
          getFirstVesselIdInGroup(selectedGroupId, {
            vessels,
            vesselGroupVessels,
          })) ||
        null;

      const companyState = get(["userDetails", "companyState"], data);
      const metrics = data.metrics || {};

      return {
        ...state,
        companyState: companyState,
        defaultGroupId: selectedGroupId,
        defaultVesselId: selectedVesselId,
        selectedVesselId,
        selectedGroupId,
        vessels,
        groups,
        vesselGroupVessels,
        vesselItems: keyById(data.vesselItems),
        itemTypes: keyById(data.itemTypes),
        itemTypeMetrics: keyById(data.itemTypeMetrics),
        itemTypeCategories: keyById(data.itemTypeCategories),
        metrics: keyById(metrics),
        performanceIndicators: keyById(data.performanceIndicators),
        vesselPerformanceIndicators: keyById(data.vesselPerformanceIndicators),
        units: keyById(data.units),
        eventTypes: keyById(data.eventTypes),
        vesselItemMetrics: data.vesselItemMetrics,
        states: data.states,
      };
    },
    [components.vessel.dataQuality.FETCH]: (state) => {
      return {
        ...state,
        dataQuality: {
          ...state.dataQuality,
          isLoading: true,
          lastPageRefresh: new Date().toISOString(),
        },
      };
    },

    [components.vessel.dataQuality.FETCH_ERROR]: (state, { error }) => {
      return {
        ...state,
        dataQuality: {
          ...state.dataQuality,
          isLoading: false,
          error,
        },
      };
    },

    [components.vessel.dataQuality.FETCH_SUCCESS]: (state, { data }) => {
      return {
        ...state,
        dataQuality: {
          ...state.dataQuality,
          isLoading: false,
          selectedPeriod: data,
        },
      };
    },

    [components.vessel.dataQuality.HIDE]: (state) => ({
      ...state,
      dataQuality: {
        ...state.dataQuality,
        showPopup: false,
      },
    }),

    [components.vessel.dataQuality.SHOW]: (state) => ({
      ...state,
      dataQuality: {
        ...state.dataQuality,
        showPopup: true,
      },
    }),
  }
);

const withCompanyInName = (group) => ({
  ...group,
  name: `${group.name} (${group.companyName})`,
});

const makeGroupNamesUnique = (groups) =>
  uniqBy("companyId", groups).length > 1
    ? map(withCompanyInName, groups)
    : groups;

const prepareGroups = flow(
  sortBy(["companyName", "name"]),
  makeGroupNamesUnique,
  keyById
);

const prepareVessels = flow(sortBy(["name"]), keyById);

const getUserDefaultGroupId = (data, groups) => {
  const defaultGroupId = get(["userDetails", "defaultVesselGroupId"], data);
  return some((g) => g.id === defaultGroupId, groups) && defaultGroupId;
};

const getFleetGroupId = flow(
  find((x) => x.name === "Fleet"),
  get(["id"])
);

const getFirstGroupId = (groups) => Object.keys(groups)[0];

export const getFirstVesselIdInGroup = (
  groupId,
  { vessels, vesselGroupVessels }
) =>
  Object.keys(vessels).find((vesselId) =>
    isVesselInGroup(vesselId, groupId, { vesselGroupVessels })
  );

export const isVesselInGroup = (vesselId, groupId, { vesselGroupVessels }) =>
  some(
    (vgv) => vgv.groupId === groupId && vgv.vesselId === vesselId,
    vesselGroupVessels
  );

export const getVesselsInGroup = (groupId, { vessels, vesselGroupVessels }) =>
  filter(
    (vessel) => isVesselInGroup(vessel.id, groupId, { vesselGroupVessels }),
    vessels
  );

export const getFirstVesselInGroup = (groupId, pageContext) => {
  return (getVesselsInGroup(groupId, pageContext) || [])[0];
};

export const getVesselsInSelectedGroup = ({
  selectedGroupId,
  vessels,
  vesselGroupVessels,
}) => getVesselsInGroup(selectedGroupId, { vessels, vesselGroupVessels });

export const getSelectedVessel = ({ selectedVesselId, vessels }) =>
  find((x) => x.id === selectedVesselId, vessels);

export const getSelectedGroup = ({ selectedGroupId, groups }) =>
  find((x) => x.id === selectedGroupId, groups);

export const isVesselGroupEmpty = (groupId, pageContext) => {
  return Object.values(pageContext.vesselGroupVessels).every(
    (v) => v.groupId !== groupId
  );
};

export const getNonEmptyVesselGroups = (pageContext) => {
  const vesselGroups = Object.values(pageContext.groups);
  return vesselGroups.filter((g) => !isVesselGroupEmpty(g.id, pageContext));
};

export const getVesselGroupsInCompany = ({ companyId, groups }) =>
  filter({ companyId: companyId }, groups);

export const getVesselsInCompany = ({
  companyId,
  vessels,
  vesselGroupVessels,
  groups,
}) => {
  const vesselGroupIds =
    map((x) => x.id, filter({ companyId: companyId }, groups)) || [];
  const vesselIds =
    map(
      (x) => x.vesselId,
      filter((vgv) => vesselGroupIds.includes(vgv.groupId), vesselGroupVessels)
    ) || [];

  return filter((vessel) => vesselIds.includes(vessel.id), vessels) || [];
};

export const findVesselGroupForVessel = (vesselId, { vesselGroupVessels }) => {
  return Object.values(vesselGroupVessels)
    .filter((vgv) => vgv.vesselId === vesselId)
    .map((vgv) => vgv.groupId)[0];
};

export const filterVesselMenuAccess = (vessels, pathname, user) => {
  if (!isSystemAdmin(user) && !pathname.includes("/admin")) {
    const menu = lodashGet(getViewsByPath(), [pathname, "key"]);
    if (menu) {
      vessels = vessels.filter(
        (v) => v.menuItems.filter((m) => m.name === menu && m.visible).length
      );
    }
  }
  return vessels;
};
