import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styles from "./Checkbox.css";

class Checkbox extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  render() {
    const { label, checked, onChange, disabled } = this.props;
    return (
      <label className={`${disabled ? styles.disabled : styles.container}`}>
        <span className={styles.stateGraphics}>{checked ? "\u2714" : ""}</span>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={checked}
          onChange={(event) => onChange(!checked, event)}
          disabled={disabled}
        />
        <span className={styles.label}>{label}</span>
      </label>
    );
  }
}

export default Checkbox;
