import React from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./FormDatePicker.css";
import formStyles from "../Form.css";
import { isNil, isDate, get, isEmpty } from "lodash";
import * as moment from "moment";
import locale from "./locale.enGB";
import classNames from "../../../../common/classNames";

registerLocale("en-GB", locale);
setDefaultLocale("en-GB");

class FormDatePicker extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.calendarRef = React.createRef();
    this.state = {
      isFocused: false,
      isTouched: false,
    };
  }

  validate = (date) => {
    const { required } = this.props;
    if (required && isNil(date)) {
      return {
        valid: false,
        message: "Please enter a value",
      };
    }
    if (required && !isDate(date)) {
      return {
        valid: false,
        message: "Not a valid date",
      };
    }
    return {
      valid: true,
      message: "",
    };
  };

  handleChange = (date) => {
    const { onChange, name } = this.props;
    const { valid, message } = this.validate(date);
    onChange(name, date, valid, message);
    this.setState({ isTouched: true });
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false, isTouched: true });
  };

  handleRawChange = (event) => {
    const { inputValidationFormats, name, onChange } = this.props;
    const newValue = get(event, "target.value");

    if (!isNil(newValue) && !isEmpty(inputValidationFormats)) {
      const parsed = moment(newValue, inputValidationFormats, true);

      if (parsed.isValid()) {
        let date = this.clearHoursFromDate(parsed).toDate();
        onChange(name, date, true, "");
        this.calendarRef.current.setPreSelection(date);
      }
    }
  };

  clearHoursFromDate = (date) => {
    return date.subtract({ hours: date.hours() }).toDate();
  };

  render() {
    const {
      name,
      value,
      label,
      labelTag,
      labelPosition,
      isChanged,
      required,
      disabled,
      strictParsing,
      dateFormat,
      timeFormat,
      showMonthDropdown,
      showYearDropdown,
      showTimeSelect,
      isClearable,
      locale: datePickerLocale,
    } = this.props;
    const { isTouched } = this.state;
    const { valid, message } = this.validate(value);
    const showValidationError = isTouched && !valid;
    return (
      <div
        className={classNames(
          formStyles.formElementContainer,
          labelPosition === "left" ? formStyles.leftAlignedContainer : "",
          isChanged && formStyles.isChanged
        )}
        data-testid={this.props.testId}
      >
        {!!label && (
          <div className={formStyles.labelContainer}>
            <label htmlFor={name} className={formStyles.label}>
              {label}
            </label>
            {!!required && (
              <span className={formStyles.requiredMessage}>required</span>
            )}
            {showValidationError && (
              <span className={formStyles.validationMessage}>{message}</span>
            )}
            {labelTag && (
              <span className={formStyles.labelTag}>{labelTag}</span>
            )}
          </div>
        )}
        <DatePicker
          ref={this.calendarRef}
          onChange={this.handleChange}
          selected={value}
          strictParsing={strictParsing}
          showTimeSelect={showTimeSelect}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          className={styles.datePicker}
          popperClassName={styles.popperContainer}
          calendarClassName={styles.calendar}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          readOnly={disabled}
          onChangeRaw={this.handleRawChange}
          isClearable={isClearable}
          locale={datePickerLocale}
        />
      </div>
    );
  }
}

FormDatePicker.defaultProps = {
  label: "",
  required: false,
  disabled: false,
  strictParsing: true,
  showYearDropdown: true,
  showMonthDropdown: true,
  showTimeSelect: true,
  dateFormat: "yyyy-MM-dd HH:mm",
  timeFormat: "HH:mm",
  inputValidationFormats: ["YYYY-MM-DD", "YYYY/MM/DD", "YYYY-M-D", "YYYY/M/D"],
  isClearable: true,
};

FormDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelTag: PropTypes.string,
  labelPosition: PropTypes.string,
  isChanged: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeHolderText: PropTypes.string,
  strictParsing: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  inputValidationFormats: PropTypes.array,
  isClearable: PropTypes.bool,
  testId: PropTypes.string,
};

export default FormDatePicker;
