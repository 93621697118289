import { hullPerformance } from "../actions/action.types";

export const initialize = ({ vesselId, groupId }) => ({
  type: hullPerformance.INITIALIZE,
  vesselId,
  groupId,
});
export const selectVessel = (vesselId) => ({
  type: hullPerformance.SELECT_VESSEL,
  vesselId,
});
