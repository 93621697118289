import { padStart } from "lodash";

export const msToDuration = (millis) => {
  const absVal = Math.abs(millis);
  return {
    isNegative: millis < 0,
    days: absVal ? Math.floor(absVal / 86400000) : "--",
    hrs: absVal ? Math.floor((absVal % 86400000) / 3600000) : "--",
    min: absVal ? Math.floor((absVal % 3600000) / 60000) : "--",
    sec: absVal ? Math.floor(((absVal % 3600000) % 60000) / 1000) : "--",
  };
};

export const formatDuration = (
  { isNegative, days, hrs, min, sec },
  {
    alwaysIncludeSign,
    separator = ":",
    showSeconds = true,
    letters = false,
  } = {}
) => {
  const sign = isNegative ? "-" : "+";
  const daySign = letters ? "d" : "";
  const secondSign = letters ? "s" : "";
  return ""
    .concat(isNegative || alwaysIncludeSign ? sign : "")
    .concat(days > 0 ? ("" + days).concat(daySign + separator) : "")
    .concat(padStart(hrs, 2, "0").concat((letters ? "h" : "") + separator))
    .concat(padStart(min, 2, "0"))
    .concat(letters ? "m" : "")
    .concat(
      showSeconds ? separator.concat(padStart(sec, 2, "0") + secondSign) : ""
    );
};

export const formatDurationMs = (millis, options) =>
  formatDuration(msToDuration(millis), options);

export const formatDurationSec = (sec, options) =>
  formatDuration(msToDuration(sec * 1000), options);
