import React from "react";
import styles from "./SelectItemsHeader.css";
import { Icon } from "../../../common/components/Icon/Icon";
import Button from "../../../common/components/Button";

interface Props<T> {
  items: T[];
  idSelector: (item: T) => string;
  labelSelector: (item: T) => string;
  onClick: () => void;
  buttonText: string;
  onClose: () => void;
  onRemoveItem: (item: T) => void;
}

export const SelectItemsHeader = <T,>({
  items,
  idSelector,
  labelSelector,
  onClick,
  buttonText,
  onClose,
  onRemoveItem,
}: Props<T>) => {
  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {items.map((item) => (
          <div className={styles.item} key={idSelector(item)}>
            <div className={styles.itemLabel}>{labelSelector(item)}</div>
            <button
              className={styles.removeButton}
              onClick={() => onRemoveItem(item)}
            >
              <Icon icon={"remove"} size={"s"} />
            </button>
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        <Button value={buttonText} clickAction={onClick} />
      </div>
      <div className={styles.closeButtonContainer}>
        <button className={styles.closeButton} onClick={onClose}>
          <Icon icon={"remove"} size={"s"} />
        </button>
      </div>
    </div>
  );
};
