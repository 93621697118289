import T from "prop-types";
import React from "react";
import styles from "./OMDescriber.css";

class OMDescriber extends React.Component {
  render() {
    const style = {
      backgroundColor: this.props.color,
    };

    return (
      <div className={styles.container} style={style}>
        <span>{this.props.profile}</span>
      </div>
    );
  }
}

export default OMDescriber;

OMDescriber.propTypes = {
  profile: T.string,
  color: T.string,
};
