import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "../../../components/Header";
import adminStyles from "../Admin.css";
import styles from "./DataStatus.css";
import DataGrid from "../../../components/DataGrid/DataGrid";
import { fetchDataStatus } from "../../../actions/admin";
import Loader from "../../../../common/components/Loader";
import Timer from "../../../../common/components/Timer/Timer";
import {
  formatLastPing,
  formatLastPingDetailed,
  getLastPingStatus,
  formatLastDataToShore,
  formatLastDataToShoreDetailed,
  getLastDataToShoreStatus,
  formatSingleDayDataStatus,
  formatMissingSeconds,
  formatSingleDayMissingSeconds,
  formatLastFiveDaysMissingSeconds,
  getTodayDataStatus,
  getSingleDayDataStatus,
  getFiveDaysDataStatus,
  getMissingSecondsStatus,
  formatDate,
  reverseDataStatusPage,
} from "../../../common/dataStatusFormatters";
import ComponentInfo from "../../../../common/components/ComponentInfo/ComponentInfo";
import DataStatusDetailedCellView from "./DataStatusDetailedCellView";
import {
  setIncludeNotConnectedVessels,
  sortVessels,
  changeDataPage,
  changeUpdateStatus,
  changeVesselPage,
} from "../../../actions/admin/action.dataStatus";
import { FormCheckBox } from "../../../components/Form";
import { debounce } from "lodash";
import { Pagination } from "../../../components/Pagination";

export const refreshInterval = 60;
const vesselsPerPage = 50;

const createDataPageOptions = () => {
  const options = [];
  for (let i = 1; i <= 4; i++) {
    options.push({
      id: i,
      label: `${formatDate(i, -5)} - ${formatDate(i, -1)}`,
    });
  }
  return options;
};

const getShownVesselsRange = (vesselPage, vesselsLength) => {
  const vesselsFromNumber = (vesselPage - 1) * vesselsPerPage + 1;
  if (!vesselsLength) {
    return "0";
  }
  if (
    vesselsLength <= vesselsPerPage ||
    vesselPage * vesselsPerPage > vesselsLength
  ) {
    return `${vesselsFromNumber}-${vesselsLength}`;
  }
  return `${vesselsFromNumber}-${vesselPage * vesselsPerPage}`;
};

class DataStatus extends Component {
  buttonStyle = {
    position: "absolute !important",
    left: 0,
    right: 0,
    margin: "-15px auto auto -5px",
    width: "100%",
    height: "100%",
    opacity: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      secondsCounter: 0,
      includeNotConnectedVessels: true,
      dataPageOptions: createDataPageOptions(),
      vesselPageOptions: [],
    };
    this.gridConfig = this.getGridConfig();
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  getGridConfig() {
    const { dataPage } = this.props;
    return {
      columns: [
        { header: "Company", field: "companyName", align: "left" },
        { header: "Name", field: "vesselName", align: "left" },
        {
          field: "lastPing",
          header: "Ping",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastPing(vessel),
              formatLastPing(vessel.lastPing)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getLastPingStatus(vessel),
              vessel.lastPing
            ),
          }),
        },
        {
          field: "fiveDaysAgo",
          header: formatDate(dataPage, -5),
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsSingleDayMissingSeconds(vessel.fiveDaysAgo),
              formatSingleDayDataStatus(vessel.fiveDaysAgo),
              vessel.fiveDaysAgoDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSingleDayDataStatus(vessel.fiveDaysAgo),
              vessel.fiveDaysAgo
            ),
          }),
        },
        {
          field: "fourDaysAgo",
          header: formatDate(dataPage, -4),
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsSingleDayMissingSeconds(vessel.fourDaysAgo),
              formatSingleDayDataStatus(vessel.fourDaysAgo),
              vessel.fourDaysAgoDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSingleDayDataStatus(vessel.fourDaysAgo),
              vessel.fourDaysAgo
            ),
          }),
        },
        {
          field: "threeDaysAgo",
          header: formatDate(dataPage, -3),
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsSingleDayMissingSeconds(vessel.threeDaysAgo),
              formatSingleDayDataStatus(vessel.threeDaysAgo),
              vessel.threeDaysAgoDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSingleDayDataStatus(vessel.threeDaysAgo),
              vessel.threeDaysAgo
            ),
          }),
        },
        {
          field: "twoDaysAgo",
          header: formatDate(dataPage, -2),
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsSingleDayMissingSeconds(vessel.twoDaysAgo),
              formatSingleDayDataStatus(vessel.twoDaysAgo),
              vessel.twoDaysAgoDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSingleDayDataStatus(vessel.twoDaysAgo),
              vessel.twoDaysAgo
            ),
          }),
        },
        {
          field: "oneDayAgo",
          header: formatDate(dataPage, -1),
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsSingleDayMissingSeconds(vessel.oneDayAgo),
              formatSingleDayDataStatus(vessel.oneDayAgo),
              vessel.oneDayAgoDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getSingleDayDataStatus(vessel.oneDayAgo),
              vessel.oneDayAgo
            ),
          }),
        },
        {
          field: "today",
          header: "Today",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastDataToShore(vessel),
              formatSingleDayDataStatus(vessel.today),
              vessel.todayDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getTodayDataStatus(vessel.today),
              vessel.today
            ),
          }),
        },
        {
          field: "totalLastFiveDays",
          header: `Total 5 days ${formatDate(dataPage, -5)} - ${formatDate(
            dataPage,
            -1
          )}`,
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastFiveDaysMissingSeconds(vessel.totalLastFiveDays),
              formatSingleDayDataStatus(vessel.totalLastFiveDays),
              vessel.totalLastFiveDaysDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getFiveDaysDataStatus(vessel.totalLastFiveDays),
              vessel.totalLastFiveDays
            ),
          }),
        },
        {
          field: "missingSeconds",
          header: "Missing Seconds",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastDataToShore(vessel),
              formatMissingSeconds(vessel.missingSeconds),
              vessel.missingSecondsDiff || 0
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getMissingSecondsStatus(vessel.missingSeconds),
              vessel.missingSeconds
            ),
          }),
        },
        {
          field: "dataToShore",
          header: "Data to shore",
          align: "left",
          template: (vessel) =>
            this.renderCell(
              vessel,
              this.detailsLastDataToShore(vessel),
              formatLastDataToShore(vessel.dataToShore)
            ),
          extendedFormat: (vessel) => ({
            className: this.getClassName(
              getLastDataToShoreStatus(vessel),
              vessel.dataToShore
            ),
          }),
        },
      ],
    };
  }

  detailsLastPing = ({ lastPing }) => [
    {
      name: "Duration since last ping",
      value: formatLastPingDetailed(lastPing),
    },
  ];

  detailsLastDataToShore = ({ dataToShore }) => [
    {
      name: "Last data to shore (UTC)",
      value: formatLastDataToShoreDetailed(dataToShore),
    },
  ];

  detailsSingleDayMissingSeconds = (singleDayData) => [
    {
      name: "Missing seconds",
      value: formatSingleDayMissingSeconds(singleDayData),
    },
  ];

  detailsLastFiveDaysMissingSeconds = (lastFiveDaysData) => [
    {
      name: "Missing seconds",
      value: formatLastFiveDaysMissingSeconds(lastFiveDaysData),
    },
  ];

  /**
   *  Render a cell with an OnClick / OnHover popup showing a simple list of details
   *
   * @param vessel  The vessel object
   * @param details Array of vessel details to display in popup on hover
   * @param value   Value to display in datagrid
   * @param increasedSeconds Increased seconds from last update to display in datagrid
   * @returns {*}   Returns a datagrid template to render the cell
   */
  renderCell = (vessel, details, value, increasedSeconds) => {
    if (!vessel) {
      return null;
    }
    const output = +value === 0 ? "0" : value || " ";
    let increasedSecondsOutput = "";
    const plusSign = increasedSeconds >= 0 ? "+" : "";
    if (
      value !== "No data found" &&
      (increasedSeconds || increasedSeconds === 0)
    ) {
      increasedSecondsOutput = `(${plusSign}${increasedSeconds})`;
    }

    return (
      <ComponentInfo
        modalTargetId={"popupContainer"}
        buttonStyle={this.buttonStyle}
        template={<DataStatusDetailedCellView data={details} />}
      >
        {`${output} ${increasedSecondsOutput}`}
      </ComponentInfo>
    );
  };

  componentDidMount() {
    const { includeNotConnectedVessels, fetchDataStatus, dataPage } =
      this.props;
    this.updateVesselPageOptions();
    fetchDataStatus(
      includeNotConnectedVessels,
      reverseDataStatusPage(dataPage)
    );
    this.interval = setInterval(() => {
      this.setState({ secondsCounter: this.state.secondsCounter + 1 });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  componentDidUpdate(prevProps) {
    const { isLoading, isUpdating, isUpdated, hasError } = this.props.list;
    // We reset the timer when the vessels data is updated.
    if (!isUpdated && !isLoading && !isUpdating && !hasError) {
      this.setState({ secondsCounter: 0 });
      this.props.changeUpdateStatus(true);
    } else if (
      this.state.secondsCounter > refreshInterval &&
      !isLoading &&
      !isUpdating
    ) {
      const { includeNotConnectedVessels, fetchDataStatus, dataPage } =
        this.props;
      fetchDataStatus(
        includeNotConnectedVessels,
        reverseDataStatusPage(dataPage)
      );
      this.setState({ secondsCounter: 0 });
    }
    if (prevProps.list.vessels.length !== this.props.list.vessels.length) {
      this.updateVesselPageOptions();
    }
  }

  /**
   * @param status
   * @param value             cell value
   * @param defaultClassname  default classname to use when format.value is null
   * @returns                 classname used to display the cell
   */
  getClassName = (status, value, defaultClassname = undefined) => {
    if (status.isDisabled) {
      return adminStyles.disabledBackground;
    }
    const getOkBackgroundOrDefault = () =>
      value !== null ? adminStyles.okBackground : defaultClassname;
    const getOkBackgroundOrUndefined = () =>
      value !== undefined ? getOkBackgroundOrDefault() : undefined;
    if (status.isWarning !== undefined) {
      const background = status.isWarning
        ? adminStyles.warningBackground
        : getOkBackgroundOrUndefined();
      return status.isError ? adminStyles.errorBackground : background;
    } else {
      return status.isError
        ? adminStyles.errorBackground
        : getOkBackgroundOrUndefined();
    }
  };
  onCheckboxChange = (name, checked) => {
    const { setIncludeNotConnectedVessels, fetchDataStatus, dataPage } =
      this.props;

    setIncludeNotConnectedVessels(checked);
    fetchDataStatus(checked, reverseDataStatusPage(dataPage));
    this.props.changeVesselPage(1);
    this.setState({ secondsCounter: 0 });
  };
  render() {
    const { includeNotConnectedVessels, dataPage, list } = this.props;
    const { isLoading, isUpdating, vessels, vesselPage } = list;
    const disable = isLoading || isUpdating;
    const daysInPastPerPage = 5;
    const totalDaysInPast = 20;
    const shownVesselsRange = getShownVesselsRange(vesselPage, vessels.length);
    return (
      <div>
        <Header title="Admin - Data Status" contentDistribution="space-between">
          <span style={{ marginBottom: -16 }}>
            <FormCheckBox
              value={includeNotConnectedVessels}
              label="Show vessels with data older than 6 days?"
              name={"includeNotConnected"}
              onChange={this.onCheckboxChange}
            />
          </span>
          <Pagination
            postsPerPage={daysInPastPerPage}
            totalPosts={totalDaysInPast}
            currentPageNumber={dataPage}
            paginate={this.selectDataPage.bind(this)}
            drawSpacer={false}
            pageOptions={this.state.dataPageOptions}
            disable={disable}
          />
          <div className={styles.timerContainer}>
            {isUpdating || isLoading ? (
              <div className={adminStyles.updating}>
                Updating <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <Timer
                prefix={"Updated"}
                postfix={"seconds ago"}
                seconds={this.state.secondsCounter}
              />
            )}
          </div>
        </Header>
        <div className={styles.dataGridContainer}>{this.renderDataGrid()}</div>
        <div className={styles.gridFooter}>
          <span className={styles.footerLabel}>
            {`${shownVesselsRange} of ${vessels.length} total vessels`}
          </span>
          <div className={styles.vesselPagination}>
            <Pagination
              postsPerPage={vesselsPerPage}
              totalPosts={vessels.length || vesselPage}
              currentPageNumber={vesselPage}
              paginate={this.selectVesselPage.bind(this)}
              drawSpacer={false}
              pageOptions={this.state.vesselPageOptions}
            />
          </div>
        </div>
      </div>
    );
  }

  renderDataGrid() {
    const {
      isLoading,
      vessels,
      error,
      isLoadingDataPage,
      isUpdating,
      hasError,
      vesselPage,
    } = this.props.list;
    const vesselsToDisplay = vessels.slice(
      (vesselPage - 1) * vesselsPerPage,
      vesselPage * vesselsPerPage
    );
    if (isLoading || (isLoadingDataPage && isUpdating) || hasError) {
      return <Loader error={error} />;
    }
    const { sortBy, sortDirection, sortVessels: onSort } = this.props;

    return (
      <DataGrid
        data={vesselsToDisplay}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onSort}
        {...this.gridConfig}
      />
    );
  }

  selectDataPage(page) {
    this.props.changeDataPage(page);
    this.updateDataPage();
  }

  // We postpone sending the request to the server with 300 milliseconds,
  // in order to prevent from sending too many requests when changing the data pages for a short time of period,
  // because this will cause server error (503), if the server response is taking longer period of time.
  // Once the request is sent we disable the data paging buttons until the request is finished.
  updateDataPage = debounce(() => {
    const { includeNotConnectedVessels, dataPage } = this.props;
    this.props.fetchDataStatus(
      includeNotConnectedVessels,
      reverseDataStatusPage(dataPage)
    );
    this.setState({ secondsCounter: 0 });
    this.gridConfig = this.getGridConfig();
  }, 300);

  selectVesselPage(page) {
    this.props.changeVesselPage(page);
  }

  updateVesselPageOptions() {
    const { vessels } = this.props.list;
    const options = [];
    const numberOfPages = Math.ceil(vessels.length / vesselsPerPage);
    for (let i = 1; i <= numberOfPages; i++) {
      options.push({
        id: i,
        label: `Page ${i} of ${numberOfPages}`,
      });
    }
    this.setState((state) => ({
      ...state,
      vesselPageOptions: options,
    }));
  }
}

const mapStateToProps = (state) => ({
  list: state.admin.dataStatus,
  sortBy: state.admin.dataStatus.sortBy,
  sortDirection: state.admin.dataStatus.sortDirection,
  includeNotConnectedVessels: state.admin.dataStatus.includeNotConnectedVessels,
  dataPage: state.admin.dataStatus.dataPage,
});

const mapActionsToProps = {
  fetchDataStatus,
  sortVessels,
  setIncludeNotConnectedVessels,
  changeDataPage,
  changeUpdateStatus,
  changeVesselPage,
};

export default connect(mapStateToProps, mapActionsToProps)(DataStatus);
