import React from "react";
import styles from "./Operations.css";
import { OperationId } from "../../../../../../common/operation/operationId";
import { LeftArrow } from "./LeftArrow";
import { RightArrow } from "./RightArrow";
import { OperationScrolling } from "../../../types";
import { OperationArrowButton } from "./OperationArrowButton";
import { OperationHeaderCell } from "./OperationHeaderCell";

interface Props {
  operations: OperationId[];
  operationScrolling: OperationScrolling;
}

export const OperationHeader = ({ operations, operationScrolling }: Props) => {
  const { onClickLeft, onClickRight, canGoLeftOrRight, canGoLeft, canGoRight } =
    operationScrolling;

  return (
    <div className={styles.container}>
      {canGoLeftOrRight && (
        <OperationArrowButton enabled={canGoLeft} onClick={onClickLeft}>
          <LeftArrow />
        </OperationArrowButton>
      )}

      <div className={styles.operationContainer}>
        {operations.map((x) => (
          <OperationHeaderCell operationId={x} key={x} />
        ))}
      </div>
      {canGoLeftOrRight && (
        <OperationArrowButton enabled={canGoRight} onClick={onClickRight}>
          <RightArrow />
        </OperationArrowButton>
      )}
    </div>
  );
};
