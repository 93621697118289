import styles from "./User.css";
import Button from "../Button";
import t from "prop-types";
import React from "react";

export default function User({ user, logOut, userNameClicked, disabled }) {
  const hasName = user.firstName || user.lastName;
  return (
    <div className={styles.userContainer}>
      {hasName && (
        <Button
          value={`${user.firstName} ${user.lastName}`}
          clickAction={userNameClicked}
          type="link"
          disabled={disabled}
        />
      )}
      <div className={styles.logout}>
        <Button
          value="Log out"
          clickAction={logOut}
          type="link"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

User.propTypes = {
  user: t.shape({
    firstName: t.string,
    lastName: t.string,
  }).isRequired,
  logOut: t.func.isRequired,
  userNameClicked: t.func,
  disabled: t.bool,
};
