import fetch from "./action.fetch";
import { api } from "../api";
import { components } from "./action.types";

export const fetchVesselDataQuality = (
  vesselId,
  dateRange,
  timeOffset,
  states
) =>
  fetch({
    url: api.vessel.getDataQuality(vesselId, dateRange, timeOffset, states),
    actionType: components.vessel.dataQuality.FETCH,
  });
