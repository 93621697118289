import React from "react";
import styles from "./HmStatusPropertyValue.css";
import classNames from "../../../common/classNames";

const HmStatusPropertyValue = ({ value, name, showName = true }) => {
  return (
    <div className={"propertyContainer"}>
      <span
        className={classNames("propertyValue", styles.status)}
        data-type={value}
      >
        {value === "None" ? "" : value}
      </span>
      {showName && <span className={"propertyName"}>{name}</span>}
    </div>
  );
};

export default HmStatusPropertyValue;
