import { excelExportActions } from "./action.types";
import download from "downloadjs";

export const excelExportItem =
  (item) =>
  (dispatch, state, { http }) => {
    const action = {
      type: excelExportActions.EXPORT,
      item,
    };

    dispatch(action);

    const { excelExport } = state();
    const { vesselId, initialDateRange, options, states } = excelExport;
    const { timeOffset } = options;
    let startTimeOffset = 0,
      endTimeOffset = 0;
    if (timeOffset.enabled === true) {
      startTimeOffset = timeOffset.startTimeOffset;
      endTimeOffset = timeOffset.endTimeOffset;
    }
    const exportUrl = item.url(
      vesselId,
      initialDateRange,
      startTimeOffset,
      endTimeOffset,
      states
    );

    http
      .get(exportUrl, {
        responseType: "blob",
      })
      .then((result) => {
        const disposition = result.headers["content-disposition"];
        const contentType = result.headers["content-type"];
        let filename = "";
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
        }
        let blob = new Blob([result.data], { type: contentType });
        download(blob, filename, contentType);
        dispatch({
          ...action,
          type: excelExportActions.EXPORT_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          ...action,
          type: excelExportActions.EXPORT_ERROR,
          error,
        });
      });
  };

export const showExport = (vesselId, dateRange, states) => ({
  type: excelExportActions.SHOW_EXPORT,
  vesselId,
  dateRange,
  states,
});

export const hideExport = () => ({
  type: excelExportActions.HIDE_EXPORT,
});

export const toggleTimeOffsetOption = () => ({
  type: excelExportActions.TOGGLE_TIME_OFFSET_OPTION,
});

export const setStartTimeOffset = (offset) => ({
  type: excelExportActions.SET_START_TIME_OFFSET,
  offset,
});

export const setEndTimeOffset = (offset) => ({
  type: excelExportActions.SET_END_TIME_OFFSET,
  offset,
});
