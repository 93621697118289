import { IEmissionsTrendData } from "../../reducers/reducer.fuelEmissions";

export const emissionsTrendResponse: IEmissionsTrendData = {
  emissions: [
    { date: "2023-08-01", co2: 471393.176195, nox: 0.0 },
    { date: "2023-09-01", co2: 764531.034846, nox: 0.0 },
    { date: "2023-10-01", co2: 1401058.267921, nox: 0.0 },
    { date: "2023-11-01", co2: 703188.790886134, nox: 0.0 },
    { date: "2023-12-01", co2: 209207.125466641, nox: 0.0 },
    { date: "2024-01-01", co2: 86335.849936715, nox: 0.0 },
  ],
  cii: [
    { date: "2023-08-13T00:00:00.000Z", score: 146.908697 },
    { date: "2023-08-20T00:00:00.000Z", score: 1835.99356 },
    { date: "2023-08-27T00:00:00.000Z", score: 4.17840055 },
    { date: "2023-09-03T00:00:00.000Z", score: 32.9775927 },
    { date: "2023-09-10T00:00:00.000Z", score: 215.132316 },
    { date: "2023-09-17T00:00:00.000Z", score: 607.666421 },
    { date: "2023-09-24T00:00:00.000Z", score: 712.989669 },
    { date: "2023-10-01T00:00:00.000Z", score: 627.009588 },
    { date: "2023-10-08T00:00:00.000Z", score: 1512.58692 },
    { date: "2023-10-15T00:00:00.000Z", score: 707.896752 },
    { date: "2023-10-22T00:00:00.000Z", score: 427.413638 },
    { date: "2023-10-29T00:00:00.000Z", score: 495.189088 },
    { date: "2023-11-05T00:00:00.000Z", score: 3643.05287 },
    { date: "2023-11-12T00:00:00.000Z", score: 642.67413 },
    { date: "2023-11-19T00:00:00.000Z", score: 841.15264 },
    { date: "2023-11-26T00:00:00.000Z", score: 9227.282 },
    { date: "2023-12-03T00:00:00.000Z", score: 1481.81621 },
    { date: "2023-12-10T00:00:00.000Z", score: 15370.6391 },
    { date: "2023-12-17T00:00:00.000Z", score: 24749.9427 },
    { date: "2023-12-24T00:00:00.000Z", score: 12930.8103 },
    { date: "2023-12-31T00:00:00.000Z", score: 12379.4051 },
    { date: "2024-01-07T00:00:00.000Z", score: 11530.7218 },
  ],
};
