import { FETCH_LEGS } from "./action.types";
import { VESSEL_STATES } from "../api_ts";
import fetch from "./action.fetch";
import { conformDateRange, toYearMonthISOString } from "../common/dates";
import { DateRange } from "../components/DatePicker/Types";

export const fetchLegs = (
  vesselId: string,
  dateRange: Date[] | DateRange,
  timeOffset: number
) => {
  dateRange = conformDateRange(dateRange);
  const month = toYearMonthISOString(new Date(dateRange.from));
  return fetch({
    url: VESSEL_STATES.getVesselLegs(vesselId, dateRange, timeOffset),
    key: `${FETCH_LEGS}-${vesselId}`,
    actionType: FETCH_LEGS,
    actionData: { vesselId, month, timeOffset },
  });
};
