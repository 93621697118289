import React from "react";
import Loader from "../../../../common/components/Loader";
import DonutContainer from "../../DonutContainer";
import { IDonutDataState, IDonutData } from "../commonTypes";
import { prepareDonutData } from "../../../common/donuts";

const prepareDonuts = (data: IDonutData[]) =>
  data.map((d) => ({ data: prepareDonutData(d) }));

interface Props {
  donutData: IDonutDataState;
}

export function EmissionDonuts({ donutData }: Props): JSX.Element {
  return donutData.isLoading ? (
    <Loader text="Loading donut details" />
  ) : (
    <>
      {donutData.data.length > 0 && (
        <DonutContainer donuts={prepareDonuts(donutData.data)} />
      )}
    </>
  );
}

export default EmissionDonuts;
