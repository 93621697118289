import { FETCH_VESSEL_FUEL_CONSUMPTION } from "./action.types";
import { VESSEL_FUEL_CONSUMPTION } from "../api";
import fetch from "./action.fetch";

export const fetchVesselFuelConsumption = (
  vesselId,
  { from, to },
  timeOffset,
  states
) =>
  fetch({
    url: VESSEL_FUEL_CONSUMPTION.get(vesselId, from, to, timeOffset, states),
    actionType: FETCH_VESSEL_FUEL_CONSUMPTION,
    actionData: { timeOffset },
  });
