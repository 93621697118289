import React, { FC } from "react";
import { FuelDataType } from "./Types";
import { FormTextBox } from "../Form";

interface RowDataProps {
  rowNumber: number;
  disabled: boolean;
  data: FuelDataType[];
  fuelTypes: string[];
  onFormDataChanged: (
    field: string,
    value: any,
    index: number,
    meta?: any
  ) => void;
}

const RowData: FC<RowDataProps> = ({
  rowNumber,
  disabled,
  data,
  fuelTypes,
  onFormDataChanged,
}) => {
  return (
    <div style={{ display: "flex", gap: "16px" }}>
      {fuelTypes.map((ft) => (
        <FormTextBox
          label={rowNumber === 0 ? ft : ""}
          name={"consumptions"}
          onChange={(field: string, value: any) =>
            onFormDataChanged(field, value, rowNumber, { fuelType: ft })
          }
          multiLine={false}
          value={
            data[rowNumber]?.consumptions.find((c) => c.fuelType === ft)
              ?.consumption
          }
          disabled={disabled}
          width={"150px"}
          type={"number"}
          key={ft}
          unit={"t"}
          formatValue={(v: string) => v}
          data-testid={`${ft}-${rowNumber}`}
        />
      ))}
    </div>
  );
};

export default RowData;
