import React, { useEffect, useState } from "react";
import { EmissionsState, State, TopPerformingVesselsProps } from "./Types";
import Logo from "./Logo";
import DirectionPicker from "./DirectionPicker";
import styles from "./TopPerformingVessels.css";
import VesselList from "./VesselList";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmissionsList } from "../../actions/action.fetchEmissionsList";
import { createOperationClass } from "../../../common/OperationClass/Factory";
import { createOperationMode } from "../../../common/OperationMode/Factory";
import { IOperationClass } from "../../../common/OperationClass/IOperationClass";
import Loader from "../../../common/components/Loader";
import { Direction } from "../../common/direction";

const TopPerformingVessels: React.FC<TopPerformingVesselsProps> = ({
  interval,
  group,
  operationType,
  direction,
  numberOfItems,
}: TopPerformingVesselsProps) => {
  const [sortingDirection, setSortingDirection] = useState(direction);
  const dispatch = useDispatch();
  const state: EmissionsState = useSelector(
    (state: State) => state.emissionsState
  );
  const operationClass: IOperationClass = createOperationClass(operationType);
  const operationModes: string[] = operationClass
    .getOperationModes()
    .map((id) => {
      const operationMode = createOperationMode(id);
      return operationMode.abbreviation;
    });

  useEffect(() => {
    dispatch(
      fetchEmissionsList(
        interval,
        group,
        operationClass.abbreviation,
        operationModes,
        numberOfItems,
        sortingDirection
      )
    );
  }, [interval, group, sortingDirection]);

  function toggleDirection() {
    switch (sortingDirection) {
      case Direction.LowToHigh:
        setSortingDirection(Direction.HighToLow);
        break;
      case Direction.HighToLow:
        setSortingDirection(Direction.LowToHigh);
        break;
      default:
        throw new Error(`Unknown direction ${sortingDirection}`);
    }
  }

  function isListReady(): boolean {
    return (
      state !== undefined &&
      state.emissionsLists !== undefined &&
      state.emissionsLists[operationClass.abbreviation] !== undefined &&
      !state.emissionsLists[operationClass.abbreviation].isLoading
    );
  }

  return (
    <div className={styles.TopPerformingVessels}>
      <Logo operationType={operationType} />
      <DirectionPicker onClick={toggleDirection} direction={sortingDirection} />
      {isListReady() ? (
        <VesselList
          list={state.emissionsLists[operationClass.abbreviation].data}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default TopPerformingVessels;
