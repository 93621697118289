import React from "react";
import PropTypes from "prop-types";
import styles from "./Slider.css";
import mouseEventOffset from "../../common/mouse";
import { findIndex, sortBy, uniqBy, isNumber, isFinite } from "lodash";
import * as d3 from "d3";
import classNames from "../../../common/classNames";

const MIN_VALUE_SLIDER = 0x1;
const MAX_VALUE_SLIDER = 0x2;

export const VALUE_TYPE_SINGLE = "single";
export const VALUE_TYPE_RANGE = "range";
export const VALUE_TYPE_RANGE_EXCLUSIVE_END = "rangeExclusiveEnd";

const SLIDER_OFFSET = 17;

export class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minValue: props.minValue,
      maxValue: props.maxValue,
      values: [],
      isUpdating: false,
      isSliding: false,
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSliderMouseDown = this.handleSliderMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMinSliderMouseDown = this.handleMinSliderMouseDown.bind(this);
    this.handleMaxSliderMouseDown = this.handleMaxSliderMouseDown.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.values !== prevProps.values) {
      this.updateSliderStates();
    }
    if (this.state.values !== prevState.values) {
      this.updateSliderPositions();
    }
    if (
      this.props.minValue !== prevProps.minValue ||
      this.props.maxValue !== prevProps.maxValue
    ) {
      this.setState({
        minValue: this.props.minValue,
        maxValue: this.props.maxValue,
      });
    }
    if (
      ((this.state.minValue !== prevState.minValue ||
        this.state.maxValue !== prevState.maxValue) &&
        !this.state.isSliding) ||
      (this.state.isSliding !== prevState.isSliding && !this.state.isSliding)
    ) {
      this.updateSliderPositions();
    }
    if (this.state.isSliding !== prevState.isSliding && !this.state.isSliding) {
      const { onValueChange } = this.props;
      if (onValueChange) {
        onValueChange({
          minValue: this.state.minValue,
          maxValue: this.state.maxValue,
        });
      }
    }
  }

  componentDidMount() {
    this.minSlider.addEventListener(
      "touchstart",
      this.handleMinSliderMouseDown,
      { passive: false }
    );
    this.maxSlider.addEventListener(
      "touchstart",
      this.handleMaxSliderMouseDown,
      { passive: false }
    );
    window.addEventListener("mouseup", this.handleMouseUp);
    window.addEventListener("touchend", this.handleMouseUp, false);
    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("touchmove", this.handleMouseMove, false);
    this.updateSliderStates();
  }

  componentWillUnmount() {
    this.minSlider.removeEventListener(
      "touchstart",
      this.handleMinSliderMouseDown
    );
    this.maxSlider.removeEventListener(
      "touchstart",
      this.handleMaxSliderMouseDown
    );
    window.removeEventListener("mouseup", this.handleMouseUp);
    window.removeEventListener("touchend", this.handleMouseUp);
    window.removeEventListener("mousemove", this.handleMouseMove);
    window.removeEventListener("touchmove", this.handleMouseMove);
  }

  handleValueChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  handleMinSliderMouseDown(event) {
    this.handleSliderMouseDown(event, MIN_VALUE_SLIDER);
  }

  handleMaxSliderMouseDown(event) {
    this.handleSliderMouseDown(event, MAX_VALUE_SLIDER);
  }

  handleSliderMouseDown(event, sliderType) {
    const { values } = this.state;
    if (!values || !values.length) {
      return;
    }
    this.sliderDragType = sliderType;
    event.preventDefault();
  }

  handleMouseUp() {
    this.sliderDragType = null;
    this.setState({
      isSliding: false,
    });
  }

  handleMouseMove(event) {
    if (!this.sliderDragType) {
      return;
    }
    const mouseOffset = mouseEventOffset(event, this.sliderTrackContainer);
    const {
      stepSize,
      minValue: currMinValue,
      maxValue: currMaxValue,
      sliderTrackWidth,
      minSliderPosition,
      maxSliderPosition,
    } = this.state;
    let sliderYValue = Math.max(0, Math.min(mouseOffset[0], sliderTrackWidth));
    let sliderIndexValue = 0;
    if (this.sliderDragType === MIN_VALUE_SLIDER) {
      sliderYValue = Math.min(sliderYValue, maxSliderPosition - stepSize);
      sliderIndexValue = Math.round(sliderYValue / stepSize);
    } else {
      sliderYValue = Math.max(sliderYValue, minSliderPosition + stepSize);
      sliderIndexValue = Math.round(sliderYValue / stepSize) - 1;
    }
    const sliderValue = this.getValueForSlider(
      sliderIndexValue,
      this.sliderDragType
    );
    switch (this.sliderDragType) {
      case MIN_VALUE_SLIDER:
        this.setState({
          minValue: isNumber(sliderValue) ? sliderValue : currMinValue,
          minSliderPosition: sliderYValue,
          minValueIndex: sliderIndexValue,
          isSliding: true,
        });
        break;
      case MAX_VALUE_SLIDER:
        this.setState({
          maxValue: isNumber(sliderValue) ? sliderValue : currMaxValue,
          maxSliderPosition: sliderYValue,
          maxValueIndex: sliderIndexValue,
          isSliding: true,
        });
        break;
    }
  }

  updateSliderStates() {
    const sliderTrackWidth = this.sliderTrackContainer.offsetWidth;
    const { values } = this.props;
    const sortedValues = this.sortValues(values);
    const valuesCount = sortedValues.length;
    const stepSize = sliderTrackWidth / Math.max(1, valuesCount);
    if (sortedValues && valuesCount) {
      if (isFinite(this.props.minValue)) {
        this.setState({ minValue: this.props.minValue });
      } else {
        this.setState({
          minValue: this.getValueForSlider(0, MIN_VALUE_SLIDER, sortedValues),
        });
      }
    }
    if (sortedValues && valuesCount) {
      if (isFinite(this.props.maxValue)) {
        this.setState({ maxValue: this.props.maxValue });
      } else {
        this.setState({
          maxValue: this.getValueForSlider(
            valuesCount - 1,
            MAX_VALUE_SLIDER,
            sortedValues
          ),
        });
      }
    }
    this.setState({
      values: sortedValues,
      stepSize,
      sliderTrackWidth,
    });
  }

  updateSliderPositions() {
    const { minValue, maxValue, stepSize, sliderTrackWidth } = this.state;
    const minValueIndex = this.getValueIndex(minValue, MIN_VALUE_SLIDER);
    const maxValueIndex = this.getValueIndex(maxValue, MAX_VALUE_SLIDER);

    this.setState({
      minValueIndex: minValueIndex,
      maxValueIndex: maxValueIndex,
      minSliderPosition: minValueIndex >= 0 ? minValueIndex * stepSize : 0,
      maxSliderPosition:
        maxValueIndex >= 0
          ? maxValueIndex * stepSize + stepSize
          : sliderTrackWidth,
    });
  }

  sortValues(values) {
    const { valueType } = this.props;
    switch (valueType) {
      case VALUE_TYPE_SINGLE:
        return uniqBy(sortBy(values, "value"), "value");
      case VALUE_TYPE_RANGE:
      case VALUE_TYPE_RANGE_EXCLUSIVE_END:
        return uniqBy(sortBy(values, "fromValue"), "fromValue");
      default:
        return values;
    }
  }

  getValueIndex(value, sliderType) {
    const { values } = this.state;
    const { valueType } = this.props;
    switch (valueType) {
      case VALUE_TYPE_SINGLE:
        return findIndex(values, (item) => item.value === value);
      case VALUE_TYPE_RANGE:
        switch (sliderType) {
          case MIN_VALUE_SLIDER: {
            return findIndex(
              values,
              (item) => item.fromValue <= value && item.toValue > value
            );
          }
          case MAX_VALUE_SLIDER: {
            return findIndex(
              values,
              (item) => item.fromValue < value && item.toValue >= value
            );
          }
          default:
            return -1;
        }
      case VALUE_TYPE_RANGE_EXCLUSIVE_END:
        return findIndex(values, (item) => item.fromValue === value);
      default:
        return -1;
    }
  }

  getValueForSlider(index, sliderType, values) {
    const { valueType } = this.props;
    if (!values) {
      values = this.state.values;
    }
    if (!values) return null;
    const value = values[index];
    if (!value) return null;
    switch (valueType) {
      case VALUE_TYPE_SINGLE:
        return value.value;
      case VALUE_TYPE_RANGE:
        switch (sliderType) {
          case MIN_VALUE_SLIDER:
            return value.fromValue;
          case MAX_VALUE_SLIDER:
            return value.toValue;
        }
        break;
      case VALUE_TYPE_RANGE_EXCLUSIVE_END:
        return value.fromValue;
    }
  }

  renderSliderBar(value, index) {
    const { stepSize, minValueIndex, maxValueIndex } = this.state;
    return (
      <div
        key={`sliderBar_${index}`}
        className={classNames(
          styles.sliderBar,
          index >= minValueIndex &&
            index <= maxValueIndex &&
            styles.activeSliderBar
        )}
        style={{
          left: `${index * stepSize + 1}px`,
          height: `${value}px`,
          bottom: "0px",
          width: `${stepSize - 2}px`,
        }}
      />
    );
  }

  renderSliderBars() {
    const { values } = this.state;
    const yMax = d3.max(values, (v) => v.barValue);
    const yScale = d3.scaleLinear().range([0, 40]).domain([0, yMax]);
    return values.map((value, index) =>
      this.renderSliderBar(yScale(value.barValue), index)
    );
  }

  render() {
    const {
      minSliderPosition,
      maxSliderPosition,
      sliderTrackWidth,
      minValue,
      maxValue,
    } = this.state;
    const { showLabel, label, unit, valueType, values } = this.props;

    let valueLabel;
    if (
      minValue === maxValue ||
      (valueType === VALUE_TYPE_RANGE_EXCLUSIVE_END && values?.length === 1)
    ) {
      valueLabel = `${minValue}`;
    } else {
      valueLabel = `${minValue}-${maxValue}`;
    }

    return (
      <div className={styles.sliderContainer}>
        {showLabel && (
          <div className={styles.sliderLabelContainer}>
            <div className={styles.sliderLabel}>{label}</div>
            <div className={styles.sliderValueLabel}>
              <span>
                {valueLabel} {unit}
              </span>
            </div>
          </div>
        )}
        <div className={styles.sliderBarContainer}>
          {this.renderSliderBars()}
        </div>
        <div
          className={styles.sliderTrackContainer}
          ref={(el) => (this.sliderTrackContainer = el)}
        >
          <div className={styles.sliderTrack}>
            <div
              style={{ left: "0px", width: `${minSliderPosition}px` }}
              className={styles.sliderTrackPartInactive}
            />
            <div
              style={{
                left: `${minSliderPosition}px`,
                width: `${maxSliderPosition - minSliderPosition}px`,
              }}
              className={styles.sliderTrackPartActive}
            />
            <div
              style={{ left: `${maxSliderPosition}px`, right: "0px" }}
              className={styles.sliderTrackPartInactive}
            />
          </div>
          <div
            ref={(el) => (this.minSlider = el)}
            style={{
              left: `${
                minSliderPosition
                  ? minSliderPosition - SLIDER_OFFSET
                  : 0 - SLIDER_OFFSET
              }px`,
            }}
            className={styles.sliderKnobContainer}
            onMouseDownCapture={this.handleMinSliderMouseDown}
          >
            <div className={styles.sliderKnob} />
          </div>
          <div
            ref={(el) => (this.maxSlider = el)}
            style={{
              left: `${
                maxSliderPosition
                  ? maxSliderPosition - SLIDER_OFFSET
                  : sliderTrackWidth - SLIDER_OFFSET
              }px`,
            }}
            className={styles.sliderKnobContainer}
            onMouseDownCapture={this.handleMaxSliderMouseDown}
          >
            <div className={styles.sliderKnob} />
          </div>
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  showLabel: true,
  valueType: VALUE_TYPE_SINGLE,
};

Slider.propTypes = {
  label: PropTypes.string,
  unit: PropTypes.string,
  showLabel: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  valueType: PropTypes.oneOf([
    VALUE_TYPE_SINGLE,
    VALUE_TYPE_RANGE,
    VALUE_TYPE_RANGE_EXCLUSIVE_END,
  ]),
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        barValue: PropTypes.number,
      }),
      PropTypes.shape({
        fromValue: PropTypes.number.isRequired,
        toValue: PropTypes.number.isRequired,
        barValue: PropTypes.number,
      }),
    ])
  ),
  onValueChange: PropTypes.func,
};
