import { admin } from "../../actions/action.types";
import { checkFormValidation } from "../../common/forms";
import createReducer from "../createReducer";
import { orderBy } from "lodash";

const actions = admin.itemConfig;

const initialState = {};

export default createReducer(initialState, {
  [actions.FETCH_CONTEXT_DATA]: (state) => ({
    ...state,
    isLoading: true,
  }),

  [actions.FETCH_CONTEXT_DATA_SUCCESS]: (state, { data }) => ({
    ...state,
    isLoading: false,
    itemTypes: data.itemTypes,
    vessels: data.vessels,
    vesselItems: data.vesselItems,
  }),

  [actions.FETCH_CONTEXT_DATA_ERROR]: (state, { error }) => ({
    ...state,
    error,
  }),

  [actions.FETCH_ITEM_CONFIG]: (
    state,
    { itemTypeId, vesselId, vesselItemId }
  ) => ({
    ...state,
    form: {
      itemTypeId,
      vesselId,
      vesselItemId,
      isLoading: true,
    },
  }),

  [actions.FETCH_ITEM_CONFIG_SUCCESS]: (state, { data }) => ({
    ...state,
    form: {
      ...state.form,
      data,
      fields: {
        configJson: { value: data ? JSON.stringify(data, null, 2) : "" },
      },
      isValid: true,
      isLoading: false,
    },
  }),

  [actions.FETCH_ITEM_CONFIG_ERROR]: (state, { error }) => ({
    ...state,
    form: {
      ...state.form,
      isLoading: false,
      error: { message: error },
    },
  }),

  [actions.SET_FORM_VALUE]: (state, { name, value, valid, message }) => {
    const field = state.form[name];

    return {
      ...state,
      form: checkFormValidation({
        ...state.form,
        fields: {
          ...state.form.fields,
          [name]: {
            ...field,
            value: value,
            isValid: valid !== undefined ? valid : true,
            message: message || "",
            hasChanges: true,
          },
        },
      }),
    };
  },

  [actions.SAVE_ITEM_CONFIG]: (state) => {
    return {
      ...state,
      form: {
        ...state.form,
        isSaving: true,
      },
    };
  },

  [actions.SAVE_ITEM_CONFIG_SUCCESS]: (state) => {
    return {
      ...state,
      form: null,
    };
  },

  [actions.SAVE_ITEM_CONFIG_ERROR]: (state, { error, status, data }) => {
    return {
      ...state,
      form: {
        ...state.form,
        isSaving: false,
        error: { message: error, status, data },
      },
    };
  },

  [actions.CANCEL_EDIT]: (state) => ({
    ...state,
    form: null,
  }),

  [actions.SORT_ITEM_TYPES]: (state, { sortBy, sortDirection }) => ({
    ...state,
    sortBy,
    sortDirection,
    itemTypes: orderBy(state.itemTypes, [sortBy], [sortDirection]),
  }),
});

export function prepVesselItemOptions({ vesselItems, form }) {
  const { vesselId, itemTypeId } = form || {};
  const filteredItems = (vesselItems || []).filter(
    (vi) => vi.vesselId === vesselId && vi.itemTypeId === itemTypeId
  );
  return orderBy(filteredItems, "name");
}
