import { IPoint } from "../commonTypes";
import React from "react";
import AutoSizer from "../../../../common/components/AutoSizer/AutoSizer";
import { EmissionsGraph } from "./EmissionsGraph";

type EmissionsProps = {
  data: IPoint[][];
};

const padding = 40;
const adjust = (dim: number): number => {
  if (dim === 0) {
    return dim;
  }
  return dim - padding;
};

export const Emissions: React.FC<EmissionsProps> = ({
  data,
}: EmissionsProps) => {
  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => (
        <EmissionsGraph
          width={adjust(width)}
          height={adjust(height)}
          data={data}
        />
      )}
    </AutoSizer>
  );
};
