import T from "prop-types";
import React from "react";
import styles from "./VesselDetails.css";
import { formatNumber } from "../../../common/numbers";
import classNames from "../../../common/classNames";

const VesselDetailsIndicator = ({ description, trend, unit, value }) => {
  return (
    <div className={styles.vesselDetailsIndicatorContainer}>
      <div className={styles.vesselDetailsIndicatorValueContainer}>
        <span
          className={classNames(
            styles.vesselDetailsIndicatorValue,
            trend && value > 0 && styles.vesselDetailsIndicatorValuePositive,
            trend && value < 0 && styles.vesselDetailsIndicatorValueNegative
          )}
        >
          {formatNumber(value, description)}
        </span>{" "}
        <span className={styles.vesselDetailsIndicatorUnit}>{unit}</span>
      </div>
      <div className={styles.vesselDetailsDescription} title={description}>
        {description}
      </div>
    </div>
  );
};

VesselDetailsIndicator.propTypes = {
  description: T.string,
  trend: T.bool,
  unit: T.string,
  value: T.number,
};

export default VesselDetailsIndicator;
