import { VESSELS } from "../../api";
import moment from "moment";
export const vesselExportConfigurations = [
  {
    name: "Vessel export",
    description: "Export vessel data to Microsoft Excel format",
    url: (vesselId, dateRange, startTimeOffset, endTimeOffset, filters) =>
      VESSELS.getExport(
        vesselId,
        dateRange,
        startTimeOffset,
        endTimeOffset,
        filters
      ),
  },
  {
    name: "Vessel operation export",
    url: (vesselId, dateRange, startTimeOffset, endTimeOffset, filters) =>
      VESSELS.getOperationExport(
        vesselId,
        dateRange,
        startTimeOffset,
        endTimeOffset,
        filters
      ),
    description:
      "Export vessel data with rows for each operation change to Microsoft Excel format. Maximum one month can be exported at a time.",
    validate: (_vesselId, { from, to }) => {
      const d1 = new moment(from);
      const d2 = new moment(to);
      const duration = moment.duration(d2.diff(d1));
      if (duration.asDays() > 31) {
        return {
          isValid: false,
          message: "Please choose a shorter timespan. Maximum one month.",
        };
      }
      return {
        isValid: true,
      };
    },
  },
];
