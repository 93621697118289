import React from "react";
import styles from "./DataDetailsHeaderContainer.css";
import DataDetailsTooltip from "./DataDetailsTooltip";
import PropTypes from "prop-types";
import { get } from "lodash";
import { formatDateTime, fromISOString } from "../../common/dates";

class DataDetailsHeaderContainer extends React.Component {
  render() {
    const {
      contentTitle,
      showHeader,
      headerData,
      children,
      height,
      showEqualAspectRatio,
      componentDescription,
    } = this.props;
    const calcHeight = showEqualAspectRatio ? height * 2 : height;
    return (
      <>
        <DataDetailsTooltip title={contentTitle} text={componentDescription} />

        <div className={styles.container} style={{ height: `${calcHeight}px` }}>
          {showHeader && (
            <div className={styles.headerContainer}>
              <span>{headerData.name}</span>
              <p className={styles.headerValueContainer}>
                <span className={styles.value}>
                  {get(headerData, "value.value")}
                </span>
                <span className={styles.unit}>
                  {get(headerData, "value.unit")}
                </span>
              </p>
              <span className={styles.headerDate}>
                {get(headerData, "value.dateTime") &&
                  formatDateTime(
                    fromISOString(get(headerData, "value.dateTime"))
                  )}
              </span>
            </div>
          )}
          <div className={styles.contentContainer}>
            <span className={styles.title}>{contentTitle}</span>
            {children}
          </div>
        </div>
      </>
    );
  }
}

DataDetailsHeaderContainer.defaultProps = {
  showHeader: false,
  height: 360,
  showEqualAspectRatio: false,
};

DataDetailsHeaderContainer.propTypes = {
  height: PropTypes.number,
  contentTitle: PropTypes.string,
  componentDescription: PropTypes.string,
  showHeader: PropTypes.bool,
  showEqualAspectRatio: PropTypes.bool,
};

export default DataDetailsHeaderContainer;
