import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { sumBy, reduce, size, isNil, isNumber } from "lodash";
import { getOffset } from "../../../onshore/common/dom";
import { formatPercent, roundConvert } from "../../numbers";
import { getIconGraphics } from "../Icon/Icon";
import classNames from "../../classNames";
import styles from "./Donut.css";
import { graphColors, color } from "../../colors";

const dimension = 100;
const radius = 50;
const thickness = 8;
const shadowThickness = 2;
const innerRadiusPadding = 2.5;
const innerRadiusEndSegmentPadding = 1.5;
const innerRadiusThickness = 2;
const innerRadiusEndSegmentThickness = 4;
const innerRadiusEndSegmentWidth = 0.02;
const arc = d3
  .arc()
  .outerRadius(radius - thickness)
  .innerRadius(radius);
const innerArc = d3
  .arc()
  .outerRadius(radius - thickness - innerRadiusThickness - innerRadiusPadding)
  .innerRadius(radius - thickness - innerRadiusPadding);
const innerArcEndSegment = d3
  .arc()
  .outerRadius(
    radius -
      thickness -
      innerRadiusEndSegmentThickness -
      innerRadiusEndSegmentPadding
  )
  .innerRadius(radius - thickness - innerRadiusEndSegmentPadding);

export default class Donut extends React.Component {
  constructor(props) {
    super(props);
    this.isActive = this.isActive.bind(this);
    this.renderSlice = this.renderSlice.bind(this);
    this.renderInnerSlice = this.renderInnerSlice.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.positionLabelContainer = this.positionLabelContainer.bind(this);
  }

  initLabelContainer() {
    if (this.props.overlayEl && !this.labelContainerInitialized) {
      this.labelContainerInitialized = true;
      this.props.overlayEl.appendChild(this.labelContainer);
      window.addEventListener("resize", this.positionLabelContainer);
    }
  }

  positionLabelContainer() {
    const { top, left } = getOffset(this.container);
    const width = this.container.offsetWidth;
    const height = this.container.offsetHeight;
    this.labelContainer.style.top = `${top}px`;
    this.labelContainer.style.left = `${left}px`;
    this.labelContainer.style.width = `${width}px`;
    this.labelContainer.style.height = `${height}px`;
  }

  componentDidMount() {
    this.initLabelContainer();
    this.positionLabelContainer();
  }

  componentWillUnmount() {
    if (this.props.overlayEl && this.labelContainer) {
      this.props.overlayEl.removeChild(this.labelContainer);
      window.removeEventListener("resize", this.positionLabelContainer);
    }
  }

  shouldComponentUpdate(prevProps) {
    return (
      this.props.data !== prevProps.data ||
      size(this.props.activeNames) !== size(prevProps.activeNames.length)
    );
  }

  componentDidUpdate() {
    this.initLabelContainer();
    this.positionLabelContainer();
  }

  isActive(slice) {
    return (
      size(this.props.activeNames) === 0 ||
      this.props.activeNames.includes(this.props.idFn(slice.data))
    );
  }

  renderSlice(slice) {
    const name = this.props.nameFn(slice.data);
    const fillColor = this.isActive(slice)
      ? this.props.colorFn(slice.data)
      : color("--page-background");
    return (
      <path key={name} d={arc(slice)} fill={fillColor}>
        <title>
          {name} {formatPercent(slice.value)}
        </title>
      </path>
    );
  }

  renderInnerSlice(slice) {
    if (!this.isActive(slice)) return;
    const name = this.props.nameFn(slice.data);
    const value = this.props.valueFn(slice.data);
    const subValue = this.props.subValueFn(slice.data);
    if (isNil(subValue) || !isNumber(subValue)) return;
    const factor = Math.max(0, Math.min(subValue / value));
    const angle = Math.min(
      2 * Math.PI - Math.abs(slice.startAngle - slice.endAngle),
      Math.abs(slice.startAngle - slice.endAngle)
    );
    const startAngle = slice.startAngle;
    const endAngle = slice.startAngle + angle * factor;

    const fillColor =
      this.isActive(slice) && this.props.subColorFn
        ? this.props.subColorFn(slice.data)
        : graphColors[4];
    return [
      <path
        key={`${name}_innerArch`}
        d={innerArc({ startAngle: startAngle, endAngle: endAngle })}
        fill={fillColor}
      />,
      <path
        key={`${name}_innerArchStartElement`}
        d={innerArcEndSegment({
          startAngle: startAngle - innerRadiusEndSegmentWidth / 2,
          endAngle: startAngle + innerRadiusEndSegmentWidth / 2,
        })}
        fill={color("--white")}
      />,
      <path
        key={`${name}_innerArchEndElement`}
        d={innerArcEndSegment({
          startAngle: endAngle - innerRadiusEndSegmentWidth / 2,
          endAngle: endAngle + innerRadiusEndSegmentWidth / 2,
        })}
        fill={color("--white")}
      />,
    ];
  }

  renderLabel(slice, unit) {
    const name = this.props.nameFn(slice.data);
    const angle = (slice.startAngle + slice.endAngle) / 2 - Math.PI / 2; // rotate -45 degrees
    const isLeft = angle > Math.PI / 2;
    const isTop = angle < 0 || angle > Math.PI;
    const offset = 1;
    const x = (50 + offset) * Math.cos(angle) + 50;
    const y = (50 + offset) * Math.sin(angle) + 50;
    const translate = [isLeft ? "-100%" : "0%", isTop ? "-50%" : "0%"];
    const precision = unit === "%" ? 1 : 0;
    const { number: convertedValue, unit: convertedUnit } = roundConvert(
      slice.data.value,
      unit,
      precision
    );

    return (
      <span
        key={`label-${name}`}
        className={styles.donutLabel}
        style={{
          left: `${x}%`,
          top: `${y}%`,
          transform: `translate(${translate})`,
        }}
      >
        {convertedValue}&nbsp;
        <em>{convertedUnit}</em>
      </span>
    );
  }

  render() {
    const {
      title,
      unit,
      icon,
      data,
      valueFn,
      idFn,
      activeNames,
      subValueFn,
      overriddenTotal,
      overriddenUnit,
      overriddenTitle,
    } = this.props;
    const total = sumBy(data, valueFn);
    const pie = d3
      .pie()
      .value((d) => valueFn(d) / total)
      .sort(null);
    const assignedTotalValue =
      overriddenTotal === undefined ? total : overriddenTotal;
    const value =
      size(this.props.activeNames) === 0
        ? assignedTotalValue
        : reduce(
            data,
            (sum, slice) =>
              activeNames.includes(idFn(slice)) ? sum + valueFn(slice) : sum,
            0
          );

    const unitToUse =
      overriddenUnit === undefined || size(this.props.activeNames) !== 0
        ? unit
        : overriddenUnit;
    const pieData = pie(data);

    const precision = unit === "%" ? 1 : 0;
    const { number: convertedValue, unit: convertedUnit } = roundConvert(
      value,
      unitToUse,
      precision
    );

    const testId = this.props.testId;
    const titleToUse =
      overriddenTitle === undefined || size(this.props.activeNames) !== 0
        ? title
        : overriddenTitle;
    return (
      <div
        ref={(el) => (this.container = el)}
        className={styles.donutContainer}
        data-test-id={testId}
      >
        <svg className={styles.svg} viewBox={`0 0 ${dimension} ${dimension}`}>
          <g transform={`translate(${radius}, ${radius})`}>
            <defs>
              <linearGradient
                id="donut-bg-gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#2b313f" />
                <stop offset="100%" stopColor="#0f1114" />
              </linearGradient>
            </defs>

            <circle fill="url(#donut-bg-gradient)" cx="0" cy="0" r={radius} />

            {pieData.map(this.renderSlice)}

            {subValueFn && pieData.map(this.renderInnerSlice)}

            <circle
              fill="transparent"
              stroke="#000"
              strokeOpacity="0.5"
              strokeWidth={shadowThickness}
              cx="0"
              cy="0"
              r={radius - thickness + shadowThickness / 2}
            />
          </g>

          <svg
            x="0"
            y="20"
            width="100%"
            height="20"
            viewBox="0 0 64 64"
            preserveAspectRatio="xMidYMid meet"
            className={styles.donutIcon}
          >
            {getIconGraphics(icon)}
          </svg>
        </svg>

        <div className={styles.donutValueContainer}>
          <div className={styles.donutValue}>
            <span
              data-test-id={`${testId}-value`}
              className={styles.donutValuePart}
            >
              {convertedValue}
            </span>{" "}
            <span data-test-id={`${testId}-unit`} className={styles.donutUnit}>
              {convertedUnit}
            </span>
          </div>
          <div
            data-test-id={`${testId}-title`}
            className={styles.donutSubValue}
          >
            {titleToUse}
          </div>
        </div>

        <div
          ref={(el) => (this.labelContainer = el)}
          className={classNames(
            styles.labelContainer,
            size(this.props.activeNames) > 0 && styles.showLabelContainer
          )}
        >
          {pieData
            .filter(this.isActive)
            .map((slice) => this.renderLabel(slice, unit))}
        </div>
      </div>
    );
  }
}

Donut.propTypes = {
  title: PropTypes.string,
  unit: PropTypes.string,
  icon: PropTypes.string,
  activeNames: PropTypes.array,
  overlayEl: PropTypes.object, // should be PropTypes.instanceOf(Element), but that doesn't work while bundling with webpack
  data: PropTypes.array.isRequired,
  valueFn: PropTypes.func.isRequired,
  nameFn: PropTypes.func.isRequired,
  idFn: PropTypes.func.isRequired,
  colorFn: PropTypes.func.isRequired,
  subValueFn: PropTypes.func,
  subColorFn: PropTypes.func,
  testId: PropTypes.string,
  overriddenTotal: PropTypes.number,
  overriddenUnit: PropTypes.string,
  overriddenTitle: PropTypes.string,
};
