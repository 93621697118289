export async function setupMocking(enableMock: boolean) {
  if (!enableMock) {
    return;
  }

  const { mockWorker } = await import("./mockServer/mockWorker");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return mockWorker.start({
    onUnhandledRequest(request, print) {
      // Do not warn about unhandled requests to the same port as the application (usually static assets etc)
      if (request.url.includes(window.location.port)) {
        return;
      }

      // Print warning about other ports (usually api requests that is not handled by the mock service worker)
      print.warning();
    },
  });
}
