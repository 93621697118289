import React from "react";
import PropTypes from "prop-types";
import Loader from "../../../../../common/components/Loader";
import Popup from "../../../../components/Popup/Popup";
import styles from "../../Vessels/GenerateApiTokenPopup.css";

export default class GenerateApiTokenPopup extends React.Component {
  state = { keyCopied: false };

  copyKeyToClipboard = () => {
    navigator.clipboard.writeText(this.props.secret);
    this.setState({
      keyCopied: true,
    });
  };

  renderConfirmGenerateContent() {
    return (
      <div className={styles.container}>
        <p>
          Confirm generating new API access token for {this.props.vesselName}
        </p>
      </div>
    );
  }

  renderConfirmRegenerateContent() {
    return (
      <div className={styles.container}>
        <div className={styles.warningText}>Warning</div>
        <p>The current vessel API access token will be replaced</p>
      </div>
    );
  }

  renderLoadingContent() {
    return (
      <div className={styles.container}>
        <Loader expand={true} />
      </div>
    );
  }

  renderResultContent() {
    const { secret } = this.props;
    const { keyCopied } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.successText}>Success</div>
        <p>
          Vessel API access token generated successfully. Copy this token and
          keep it safe. After closing this window you will not be able to
          retrieve it unless you generate a new token.
        </p>
        <input
          type={"text"}
          value={secret}
          readOnly={true}
          className={styles.tokenContainer}
        />

        <div
          className={styles.copyLinkButton}
          onClick={this.copyKeyToClipboard}
        >
          Copy to clipboard
          {keyCopied && <span className={styles.check}>&#x2713;</span>}
        </div>
      </div>
    );
  }

  renderErrorContent() {
    return (
      <div className={styles.container}>
        <div className={styles.warningText}>Error</div>
        <p>An error occurred while generating the vessel API token</p>
      </div>
    );
  }

  render() {
    const { onClose, onConfirm, hasAccessToken, isLoading, secret, error } =
      this.props;
    let title,
      content,
      buttons = [],
      canClose = true;
    if (isLoading) {
      title = "Generating API access token";
      content = this.renderLoadingContent();
      canClose = false;
    } else if (secret) {
      title = "API access token generated";
      buttons = [
        {
          label: "Close",
          action: onClose,
        },
      ];
      content = this.renderResultContent();
    } else if (error) {
      title = "Error while generating token";
      content = this.renderErrorContent();
      buttons = [
        {
          label: "Close",
          action: onClose,
        },
      ];
    } else {
      title = "Confirm";
      content = hasAccessToken
        ? this.renderConfirmRegenerateContent()
        : this.renderConfirmGenerateContent();
      buttons = [
        {
          label: hasAccessToken ? "Regenerate" : "Generate",
          action: onConfirm,
        },
        {
          label: "Cancel",
          action: onClose,
        },
      ];
    }
    return (
      <Popup
        title={title}
        visible
        buttons={buttons}
        onHide={() => canClose && onClose()}
        testId="apiAccessTokenPopup"
      >
        {content}
      </Popup>
    );
  }
}

GenerateApiTokenPopup.propTypes = {
  vesselName: PropTypes.string.isRequired,
  hasAccessToken: PropTypes.bool,
  secret: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
};
