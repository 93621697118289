export enum EmissionsType {
  // Needs to correspond to the EmissionsType enum in the backend
  CO2,
  NOx,
  SOx,
}

export function getEmissionsTitle(type: EmissionsType): string {
  return EmissionsType[type] + " Emissions";
}

export interface EmissionsByOperationModesState {
  emissionsByOperationModesLists: EmissionsByOperationModesDictionary;
  isLoading: boolean;
  error?: string;
  hasError: boolean;
}

export type EmissionsByOperationModesDictionary = {
  [emissionsType: string]: DonutData;
};

export interface DonutData {
  data: DataFromSource;
  error?: string;
}

export interface DataFromSource {
  data: DataItemFromSource[];
  fromDate: Date;
  itemTypeMetricId: string;
  metricId: string;
  title: string;
  toDate: Date;
  total: number;
  type: string;
  unit: string;
}

interface DataItemFromSource {
  value: number;
  operationalProfileId: string;
  operationalProfile: OperationalProfile;
}

interface OperationalProfile {
  category: string;
  id: string;
  legend: string;
  profile: string;
  sortKey: number;
}
