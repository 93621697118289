import React from "react";
import styles from "./Operations.css";

interface Props {
  enabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
}
export const OperationArrowButton = ({ enabled, onClick, children }: Props) => {
  return (
    <button
      disabled={!enabled}
      className={`${styles.button} ${enabled ? styles.enabledButton : ""}`}
      onClick={onClick}
    >
      {enabled && children}
    </button>
  );
};
