import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import {
  editVesselItem,
  inputChanged,
  fuelTypesChanged,
  saveVesselItem,
  cancelEditVesselItem,
  newVesselItem,
  metricChanged,
  deleteVesselItem,
  cancelDeleteVesselItem,
  confirmDeleteVesselItem,
} from "../../../../actions/admin/action.vesselconfig.vesselItems";
import Loader from "../../../../../common/components/Loader";
import CollapsePanel from "../CollapsablePanel/CollapsePanel";
import DataTable from "../../../../components/DataTable/DataTable";
import VesselItemForm from "./VesselItemForm";

class VesselItemsController extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onEdit = this.onEdit.bind(this);
    this.editTemplate = this.editTemplate.bind(this);

    this.columns = [
      {
        header: "Item type",
        field: "itemType",
      },
      {
        header: "Name",
        field: "name",
      },
      {
        header: "View position",
        field: "viewPosition",
      },
      {
        header: "Remote item name",
        field: "remoteItemName",
      },
    ];
  }

  editTemplate = ({ name, form, parentVesselItems, metrics }, rowId) => {
    const title = name ? "Edit " + name : "New item";

    return (
      <VesselItemForm
        itemTypes={this.props.itemTypes}
        fuelTypes={this.props.fuelTypes}
        parentVesselItems={parentVesselItems}
        title={title}
        form={form}
        rowId={rowId}
        metrics={metrics}
        metricChanged={this.props.metricChanged}
        inputChanged={this.props.inputChanged}
        fuelTypesChanged={this.props.fuelTypesChanged}
        saveAction={this.props.saveVesselItem}
        cancelAction={this.props.cancelEditVesselItem}
        deleteAction={this.props.deleteVesselItem}
        cancelDeleteAction={this.props.cancelDeleteVesselItem}
        confirmDeleteAction={this.props.confirmDeleteVesselItem}
      />
    );
  };

  onEdit = (row) => {
    this.props.editVesselItem(row);
  };

  renderContent() {
    const { isLoading, data, error } = this.props.vesselConfigItems;
    if (isLoading || error) {
      return <Loader expand={true} error={error} />;
    }
    return (
      <div>
        <DataTable
          testId={"datatable"}
          data={data}
          columns={this.columns}
          canEdit={true}
          onEdit={this.onEdit}
          editTemplate={this.editTemplate}
        />
      </div>
    );
  }

  render() {
    const { isOpen, enabled, onToggleClick, isCreating } = this.props;

    const buttons = [
      {
        label: "New",
        action: this.props.newVesselItem,
        disabled: !enabled || isCreating,
        isVisible: isOpen,
      },
    ];

    return (
      <CollapsePanel
        title={"Items"}
        subTitle={"Configure available items"}
        isOpen={isOpen}
        testId={"vesselItemsController"}
        enabled={enabled}
        onToggleClick={onToggleClick}
        buttons={buttons}
      >
        {this.renderContent()}
      </CollapsePanel>
    );
  }
}

VesselItemsController.defaultProps = {
  isOpen: false,
  enabled: false,
  onToggleClick: noop,
};

VesselItemsController.propTypes = {
  isOpen: PropTypes.bool,
  onToggleClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  vesselConfigItems: state.admin.vesselConfigItems,
  itemTypes: state.admin.vesselConfigItems.itemTypes,
  fuelTypes: state.admin.vesselConfigItems.fuelTypes,
  isCreating: state.admin.vesselConfigItems.data.some(
    (x) => x.id === undefined
  ),
});

const mapDispatchToProps = {
  editVesselItem,
  inputChanged,
  fuelTypesChanged,
  saveVesselItem,
  cancelEditVesselItem,
  newVesselItem,
  metricChanged,
  deleteVesselItem,
  cancelDeleteVesselItem,
  confirmDeleteVesselItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselItemsController);
