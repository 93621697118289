import React from "react";
import { connect } from "react-redux";
import { FormCheckBox, FormPopup } from "../../../components/Form";
import Loader from "../../../../common/components/Loader";
import {
  closeSensorReplicationVessel,
  saveSensorReplicationVessel,
  setVesselSensorReplicationValue,
} from "../../../actions/admin/action.vessels";
import { get } from "lodash";
import styles from "./SensorReplicationPopup.css";

class SensorReplicationPopup extends React.Component {
  render() {
    const {
      closeSensorReplicationVessel: onClose,
      form,
      isSaving,
      saveSensorReplicationVessel: onSave,
      setVesselSensorReplicationValue: onChange,
    } = this.props;

    const loader = <Loader text="Downloading..." error={form.errorMessage} />;

    return (
      <FormPopup
        title={"Select onshore data granularity"}
        visible={true}
        isSaving={form.isSaving}
        enableSave={!form.isSaving}
        onSave={onSave}
        onCancel={onClose}
        onHide={onClose}
        saveButtonText="Download"
      >
        <div className={styles.info}>
          <p>Selecting shorter intervals improve the data precision.</p>
          <p>
            Selecting longer intervals will improve how fast data can be
            displayed in your web browser
          </p>
          <p>For optimal performance, select all three intervals.</p>
        </div>
        <div>
          <FormCheckBox
            value={get(form.fields, "useMinuteSensorAggregation.value")}
            label="Send aggregated sensor data per minute from vessel"
            name="useMinuteSensorAggregation"
            iconPosition="left"
            onChange={onChange}
          />
          <FormCheckBox
            value={get(form.fields, "useTenMinuteSensorAggregation.value")}
            label="Send aggregated sensor data per 10 minute from vessel"
            name="useTenMinuteSensorAggregation"
            iconPosition="left"
            onChange={onChange}
          />
          <FormCheckBox
            value={get(form.fields, "useHourSensorAggregation.value")}
            label="Send aggregated sensor data per hour from vessel"
            name="useHourSensorAggregation"
            iconPosition="left"
            onChange={onChange}
          />
        </div>
        <div className={styles.info}>
          <p>
            NOTE: This will generate and download a .csv file with all the
            required sensors to be replicated from InfluxSource1 for this
            specific vessel. This file can be imported into the "Sensor
            Replication Configuration" tool in Kognifai.
          </p>
        </div>
        {(isSaving || form.error) && <div>{loader}</div>}
      </FormPopup>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.admin.vessels.configureSensorReplication,
});

const mapDispatchToProps = {
  closeSensorReplicationVessel,
  saveSensorReplicationVessel,
  setVesselSensorReplicationValue,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SensorReplicationPopup);
