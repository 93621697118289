import { ITEM_TYPES, ENERGY_TYPES, FUEL_TYPE_IDS } from "../config";
import { isRequired } from "./forms.validators";

const batteryMetadata = [
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
  {
    name: "minPower",
    type: "number",
    label: "Min power",
    unit: "kW",
  },
  {
    name: "energyCapacity",
    type: "positiveNumber",
    label: "Energy capacity",
    unit: "kWh",
  },
  {
    name: "minEnergy",
    type: "number",
    label: "Min energy",
    unit: "kWh",
  },
  {
    name: "peakshaveCoefficient",
    type: "number",
    label: "Peak Shave Coefficient",
    unit: "",
  },
];

const consumerMetadata = [
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
  {
    name: "minPower",
    type: "number",
    label: "Min power",
    unit: "kW",
  },
  {
    name: "fixedPower",
    type: "positiveNumber",
    label: "Fixed power",
    unit: "kW",
  },
];

const shoreConnectionMetadata = [
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
];

const hullMetadata = [
  {
    name: "vesselLength",
    type: "positiveNumber",
    label: "Vessel length",
    unit: "m",
  },
  {
    name: "vesselBredth", // Typo is requered, named like this in processor
    type: "positiveNumber",
    label: "Vessel width",
    unit: "m",
  },
  {
    name: "designDraft",
    type: "positiveNumber",
    label: "Design draft",
    unit: "m",
  },
  {
    name: "avgDraftLadenDetectThreshold",
    type: "positiveNumber",
    label: "Draft threshold for laden/ballast detection",
    unit: "m",
  },
  {
    name: "areaTransverse",
    type: "positiveNumber",
    label: "Area transverse",
    unit: "m3",
  },
  {
    name: "propulsionEfficiencyCoefCalm",
    type: "number",
    label: "Propulsion efficiency coefficient Calm",
    unit: "",
  },
  {
    name: "propulsionEfficiencyCoefActual",
    type: "number",
    label: "Propulsion efficiency coefficient Actual",
    unit: "",
  },
  {
    name: "usePortShortConnectionWhenEnginesAreOff", // Typo is required, named like this in processor
    type: "bool",
    label: "Use port Shore connection when engines are off",
    unit: "",
  },
  {
    name: "useSpeedThroughWater",
    type: "bool",
    label: "Use speed through water for mode detection",
    unit: "",
  },
];

const propulsorMetadata = [
  {
    name: "propellerId",
    type: "options",
    label: "Position",
    unit: "",
    validation: [isRequired],
    options: [
      {
        text: "Port",
        value: 1,
      },
      {
        text: "Starboard",
        value: 2,
      },
      {
        text: "Center",
        value: 3,
      },
    ],
  },
  {
    name: "diameter",
    type: "positiveNumber",
    label: "Diameter",
    unit: "m",
    defaultValue: 2.8,
  },
  {
    name: "designPitch",
    type: "number",
    label: "Design pitch",
    unit: "%",
    defaultValue: 1,
  },
  {
    name: "etaGear",
    type: "number",
    label: "Eta gear",
    unit: "",
    defaultValue: 1,
  },
  {
    name: "waterDensity",
    type: "positiveNumber",
    label: "Water density",
    unit: "",
    defaultValue: 1025,
  },
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
];

const thrusterMetadata = [
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
];

const engineMetadata = [
  {
    name: "maxPower",
    type: "positiveNumber",
    label: "Max power",
    unit: "kW",
  },
  {
    name: "hasAuxBlower",
    type: "bool",
    label: "Has Auxiliary Blower",
    unit: "",
    defaultValue: "false",
  },
];

const getBoilerMetadata = (fuelTypeUnitKg) => {
  const unit = (useKg) => (useKg ? "kg/h" : "l/h");

  return [
    {
      name: "runPowerConsumption",
      type: "positiveNumber",
      label: "Run power consumption",
      unit: "kW",
    },
    {
      name: "runMdoConsumption",
      type: "positiveNumber",
      label: "Run MDO consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.MDO]),
    },
    {
      name: "runHfoConsumption",
      type: "positiveNumber",
      label: "Run HFO consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.HFO]),
    },
    {
      name: "runLngConsumption",
      type: "positiveNumber",
      label: "Run LNG consumption",
      unit: "kg/h",
    },
    {
      name: "runMgoConsumption",
      type: "positiveNumber",
      label: "Run MGO consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.MGO]),
    },
    {
      name: "runMethanolConsumption",
      type: "positiveNumber",
      label: "Run Methanol consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.Methanol]),
    },
    {
      name: "runUlsfoConsumption",
      type: "positiveNumber",
      label: "Run ULSFO consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.ULSFO]),
    },
    {
      name: "runVlsfoConsumption",
      type: "positiveNumber",
      label: "Run VLSFO consumption",
      unit: unit(fuelTypeUnitKg[FUEL_TYPE_IDS.VLSFO]),
    },
  ];
};

const energyConverter = [
  {
    name: "inputTypes",
    type: "options",
    label: "Input energy type",
    validation: [isRequired],
    options: Object.entries(ENERGY_TYPES).map(([value, text]) => ({
      value,
      text,
    })),
  },
  {
    name: "outputTypes",
    type: "options",
    label: "Output energy type",
    validation: [isRequired],
    options: Object.entries(ENERGY_TYPES).map(([value, text]) => ({
      value,
      text,
    })),
  },
];

const getItemTypeMetadata = (itemTypeId, fuelTypeUnitKg) => {
  switch (itemTypeId) {
    case ITEM_TYPES.BATTERY:
      return batteryMetadata;
    case ITEM_TYPES.CONSUMER:
      return consumerMetadata;
    case ITEM_TYPES.SHORE_CONNECTION:
      return shoreConnectionMetadata;
    case ITEM_TYPES.PROPULSOR:
      return propulsorMetadata;
    case ITEM_TYPES.THRUSTER:
      return thrusterMetadata;
    case ITEM_TYPES.ENGINE:
      return engineMetadata;
    case ITEM_TYPES.BOILER:
      return getBoilerMetadata(fuelTypeUnitKg);
    case ITEM_TYPES.HULL:
      return hullMetadata;
    case ITEM_TYPES.ENERGY_CONVERTER:
      return energyConverter;
  }
};

export default getItemTypeMetadata;
