import createReducer from "../createReducer";
import { admin } from "../../actions/action.types";
import { PAGES } from "../../../onboard/config";
import { orderBy } from "lodash";

const availablePages = Object.values(PAGES).map((x) => ({
  path: x.url,
  name: x.name,
}));

const initialState = {
  data: [],
  sortBy: "name",
  sortDirection: "asc",
  isLoading: false,
};

const sortTelemetry = (data, { sortBy, sortDirection } = {}) => {
  return orderBy(data, (x) => x[sortBy || initialState.sortBy] || "", [
    sortDirection || initialState.sortDirection,
  ]);
};

export default createReducer(initialState, {
  [admin.telemetry.FETCH_ADMIN_TELEMETRY]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [admin.telemetry.FETCH_ADMIN_TELEMETRY_SUCCESS]: (state, { data }) => {
    const pages = availablePages.map((page) => {
      const pageTelemetry = data.find((d) => d.path === page.path);
      return {
        ...page,
        ...pageTelemetry,
      };
    });

    return {
      ...state,
      isLoading: false,
      data: sortTelemetry(pages),
    };
  },
  [admin.telemetry.FETCH_ADMIN_TELEMETRY_ERROR]: (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }),
  [admin.telemetry.ADMIN_TELEMETRY_SORT]: (
    state,
    { sortBy, sortDirection }
  ) => ({
    ...state,
    sortBy,
    sortDirection,
    data: sortTelemetry(state.data, { sortBy, sortDirection }),
  }),
});
