import React from "react";
import PropTypes from "prop-types";
import styles from "./HullPerformanceIndicatorPanel.css";
import { orderBy, find } from "lodash";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import {
  HULL_PERFORMANCE_INDICATOR_DRY_DOCKING,
  HULL_PERFORMANCE_INDICATOR_IN_SERVICE,
  HULL_PERFORMANCE_INDICATOR_MAINTENANCE_TRIGGER,
  HULL_PERFORMANCE_INDICATOR_MAINTENANCE_EFFECT,
} from "../../config";
import { getHullPerformanceIndicator } from "../../reducers/reducer.hullPerformance";
import { formatNumberAt } from "../../../common/numbers";

export const HullPerformanceIndicatorPanel = ({ performanceIndicators }) => {
  const latestIndicators = orderBy(performanceIndicators, "time", "desc");
  const indicators = [
    getIndicator(
      latestIndicators,
      HULL_PERFORMANCE_INDICATOR_MAINTENANCE_TRIGGER
    ),
    getIndicator(latestIndicators, HULL_PERFORMANCE_INDICATOR_IN_SERVICE),
    getIndicator(
      latestIndicators,
      HULL_PERFORMANCE_INDICATOR_MAINTENANCE_EFFECT
    ),
    getIndicator(latestIndicators, HULL_PERFORMANCE_INDICATOR_DRY_DOCKING),
  ];

  return (
    <div className={styles.panelContainer}>
      {indicators.map((pi) => {
        return (
          <HullPerformanceIndicator key={pi.type} performanceIndicator={pi} />
        );
      })}
    </div>
  );
};

const HullPerformanceIndicator = ({ performanceIndicator }) => {
  const valueStyles = [styles.value];
  performanceIndicator.value &&
    valueStyles.push(
      performanceIndicator.value < 0
        ? styles.valueNegative
        : styles.valuePositive
    );

  return (
    <div className={styles.indicator} key={performanceIndicator.type}>
      <ComponentInfo
        modalTargetId={"popupContainer"}
        title={performanceIndicator.title}
        text={performanceIndicator.description}
      >
        <div className={styles.indicatorInner}>
          <div className={styles.panelText}>
            <div className={styles.title}>{performanceIndicator.title}</div>
            {performanceIndicator.isValid && (
              <div className={styles.statusMessage}>
                {performanceIndicator.statusMessage}
              </div>
            )}
            {!performanceIndicator.isValid && (
              <div className={styles.piCalculationMessage}>
                {performanceIndicator.statusMessage}
              </div>
            )}
          </div>

          <div className={styles.valueContainer}>
            <div className={valueStyles.join(" ")}>
              {performanceIndicator.value
                ? `${formatNumberAt(2, performanceIndicator.value)} %`
                : "-"}
            </div>
          </div>
        </div>
      </ComponentInfo>
    </div>
  );
};

const getIndicator = (indicators, key) => ({
  ...getHullPerformanceIndicator(key),
  ...find(indicators, (x) => x.type === key),
});

HullPerformanceIndicatorPanel.propTypes = {
  performanceIndicators: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      isValid: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number,
      time: PropTypes.instanceOf(Date),
      statusMessage: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};
