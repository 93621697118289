import PropTypes from "prop-types";
import React from "react";
import DatePicker from "react-datepicker";
import styles from "./ContextBrowser.css";
import { Portal } from "react-portal";
import { atStartOfMonth } from "../../../common/dates";

export default class ContextBrowserTitle extends React.Component {
  renderDatePicker(title) {
    const {
      selectedValue,
      dateFormat,
      showMonthYearPicker,
      limits,
      onClickTitle,
    } = this.props;
    // We position the date picker on top of the other pop up
    const CalendarContainer = ({ children }) => {
      const el = document.getElementById("calendar-portal");
      return <Portal container={el}>{children}</Portal>;
    };
    const customInput = <span className={styles.titleItem}>{title}</span>;
    return (
      <div>
        <DatePicker
          onChange={onClickTitle}
          selected={selectedValue}
          dateFormat={dateFormat}
          minDate={limits && atStartOfMonth(limits.from)}
          maxDate={(limits && limits.to) || new Date()}
          showMonthYearPicker={showMonthYearPicker}
          className={styles.datePicker}
          popperClassName={styles.popperContainer}
          calendarClassName={styles.calendar}
          onClickOutside={this.closeCalendar}
          customInput={customInput}
          popperContainer={CalendarContainer}
        />
      </div>
    );
  }

  render() {
    const { title, isTitleDatePicker } = this.props;
    let titleElement;

    if (typeof title === "object") {
      titleElement = title.map((item, i) => {
        return isTitleDatePicker ? (
          this.renderDatePicker(item)
        ) : (
          <span className={styles.titleItem} key={i}>
            {item}
          </span>
        );
      });
    } else if (typeof title === "string") {
      titleElement = isTitleDatePicker ? (
        this.renderDatePicker(title)
      ) : (
        <span className={styles.titleItem}>{title}</span>
      );
    }

    return <div className={styles.titleContainer}>{titleElement}</div>;
  }
}

ContextBrowserTitle.propTypes = {
  title: PropTypes.any.isRequired,
  onClickTitle: PropTypes.func,
  isTitleDatePicker: PropTypes.bool,
  dateFormat: PropTypes.string,
  selectedValue: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
  limits: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
};
