import { adminApi } from "../../api";
import { admin } from "../action.types";
import fetch from "../action.fetch";

export const fetchDataStatus = (allVessels, dataPage) =>
  fetch({
    url: adminApi.dataStatus.get(allVessels, dataPage),
    actionType: admin.dataStatus.FETCH,
  });

export const sortVessels = ({ sortBy, sortDirection }) => ({
  type: admin.dataStatus.SORT_VESSELS,
  sortBy,
  sortDirection,
});

export const setIncludeNotConnectedVessels = (checked) => ({
  type: admin.dataStatus.SET_CHECKED_ALL_VESSELS,
  checked,
});

export const changeDataPage = (page) => ({
  type: admin.dataStatus.CHANGE_DATA_PAGE,
  page,
});

export const changeUpdateStatus = (updated) => ({
  type: admin.dataStatus.CHANGE_UPDATE_STATUS,
  updated,
});

export const changeVesselPage = (page) => ({
  type: admin.dataStatus.CHANGE_VESSEL_PAGE,
  page,
});
