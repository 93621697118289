import React from "react";
import * as d3 from "d3";
import styles from "./FuelConsumption.css";
import Area from "../Common/ChartComponents/Area";
import TrendLine from "../Common/ChartComponents/TrendLine";
import Points from "../Common/ChartComponents/Points";
import { AmountAxis, TimeAxis } from "../Common/ChartComponents/Axes";

const FuelConsumption = ({ data, size }) => {
  const width = size.width - size.margin.left - size.margin.right;
  const height = size.height - size.margin.top - size.margin.bottom;

  const x = d3
    .scaleTime()
    .domain(
      d3.extent(data.data, (d) => {
        return new Date(d.date);
      })
    )
    .range([0, width]);

  const y = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(data.data, (d) => {
        return d.value;
      }),
    ])
    .range([height, 0]);

  const graphData = data.data.map((d) => {
    return {
      x: x(new Date(d.date)),
      y: y(d.value),
    };
  });

  const mean = d3.mean(data.data, (d) => {
    return y(d.value);
  });

  return (
    <svg className={styles.svg} width={size.width} height={size.height}>
      <text
        className={styles.title}
        transform={`translate(${size.margin.left},${size.margin.top / 1.67})`}
      >
        {data.title}
      </text>
      <g transform={`translate(${size.margin.left},${size.margin.top})`}>
        <Area data={graphData} width={width} height={height} />
        <line className={styles.mean} x1={0} y1={mean} x2={width} y2={mean} />
        <TrendLine data={graphData} />
        {graphData.length < 10 && <Points data={graphData} />}
      </g>
      <TimeAxis
        size={size}
        scale={x}
        interval={data.aggregation}
        data={graphData}
      />
      <AmountAxis size={size} scale={y} unit={data.unit} />
    </svg>
  );
};

FuelConsumption.propTypes = {};

export default FuelConsumption;
