import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import styles from "./GroupedBarGraph.css";
import { color } from "../../../../common/colors";
import { maxOf } from "../../EnergyBudget/EnergyBudgetOverviewHelper";

const colors = [
  color("--blue-dark"),
  color("--blue-bright"),
  color("--yellow-dark"),
  color("--yellow-base"),
  color("--red-dark"),
  color("--red-bright"),
  color("--orange-dark"),
  color("--orange-base"),
  color("--grey-400"),
  color("--grey-600"),
];

export class GroupedBarGraph extends React.PureComponent {
  componentDidMount() {
    this.updateD3();
  }

  componentDidUpdate() {
    this.updateD3();
  }

  updateD3() {
    if (!this.el) {
      return;
    }

    const { width, height, data, groupKeyFn, hideBottomAxis } = this.props;

    const margin = {
      left: 70,
      right: 5,
      top: 60,
      bottom: 40,
    };

    const dataLength = data.length;

    const graphWidth =
      (width - margin.left * dataLength - margin.right * dataLength) /
      dataLength;
    const graphHeight = height - margin.top - margin.bottom;

    const svg = d3.select(this.el);

    svg.selectAll("g").remove();

    // Legend
    const legendBarColor = color("--light-grey");

    const budgetBarLegendX = 10;
    const budgetBarLegendWidth = 32;
    const budgetBarLegendHeight = 3;

    svg
      .append("rect")
      .attr("x", budgetBarLegendX)
      .attr("y", 0)
      .attr("width", budgetBarLegendWidth)
      .attr("height", budgetBarLegendHeight)
      .attr("fill", legendBarColor)
      .style("filter", "url(#drop-shadow)");

    const actualBarLegendX =
      budgetBarLegendX + (budgetBarLegendWidth * 0.3) / 2;
    const actualBarLegendY = budgetBarLegendHeight + 8;
    const actualBarLegendWidth = budgetBarLegendWidth * 0.7;
    const actualBarLegendHeight = 14;

    svg
      .append("rect")
      .attr("x", actualBarLegendX)
      .attr("y", actualBarLegendY)
      .attr("width", actualBarLegendWidth)
      .attr("height", actualBarLegendHeight)
      .attr("fill", legendBarColor);

    const actualBarShadowLegendWidth = actualBarLegendWidth * 0.2;

    svg
      .append("rect")
      .attr(
        "x",
        actualBarLegendX + actualBarLegendWidth - actualBarShadowLegendWidth
      )
      .attr("y", actualBarLegendY)
      .attr("width", actualBarShadowLegendWidth)
      .attr("height", actualBarLegendHeight)
      .attr("fill", "#000")
      .attr("fill-opacity", 0.5);

    svg
      .append("line")
      .attr("x1", budgetBarLegendX + budgetBarLegendWidth / 2)
      .attr("y1", budgetBarLegendHeight)
      .attr("x2", actualBarLegendX + actualBarLegendWidth / 2)
      .attr("y2", actualBarLegendY)
      .attr("stroke-width", 0.5)
      .style("stroke", legendBarColor);

    const textLegendX = budgetBarLegendX + budgetBarLegendWidth + 5;
    const textLegendY = 8.5;

    svg
      .append("g")
      .attr("class", styles.legend)
      .append("text")
      .attr("transform", `translate(${textLegendX}, ${textLegendY})`)
      .text("Budget");

    svg
      .append("g")
      .attr("class", styles.legend)
      .append("text")
      .attr(
        "transform",
        `translate(${textLegendX},${actualBarLegendHeight + actualBarLegendY})`
      )
      .text("Actual");

    data.forEach((fuelConsumption, graphIndex) => {
      let values = fuelConsumption.values;

      // Graph
      const g = svg.append("g");

      let graphX = margin.left;

      if (graphIndex > 0) {
        graphX = graphX * (graphIndex + 1) + graphWidth * graphIndex;
      }

      g.attr("transform", `translate(${graphX}, ${margin.top})`);

      // Metric Name
      g.append("g")
        .attr("class", styles.metric)
        .append("text")
        .attr("transform", `translate(${graphWidth / 2}, -30)`)
        .style("text-anchor", "middle")
        .text(`${fuelConsumption.metricName} (${fuelConsumption.metricUnit})`);

      // X Axis
      const x = d3
        .scaleBand()
        .range([0, graphWidth])
        .domain(values.map(groupKeyFn))
        .padding(0.4);

      if (!hideBottomAxis) {
        g.append("g")
          .attr("transform", `translate(0, ${graphHeight})`)
          .attr("class", styles.axis)
          .call(d3.axisBottom(x).tickSizeOuter(0));
      }

      // Y Axis
      const y = d3
        .scaleLinear()
        .domain([0, maxOf(values)])
        .rangeRound([graphHeight, 0])
        .nice();

      g.append("g")
        .attr("class", styles.axis)
        .call(d3.axisLeft(y).tickSizeOuter(0).ticks(6));

      // Bars
      const bars = g.append("g").selectAll("g").data(values).enter();

      // Actual Bars
      const actualBarWidth = x.bandwidth();

      bars
        .append("rect")
        .attr("x", (value) => x(groupKeyFn(value)))
        .attr("y", (value) => y(value.actualValue))
        .attr("width", actualBarWidth)
        .attr("height", (value) =>
          Math.max(0, graphHeight - y(value.actualValue))
        )
        .attr("fill", colors[graphIndex * 2]);

      // Actual Bars Shadow
      const actualBarShadowWidth = actualBarWidth * 0.2;

      bars
        .append("rect")
        .attr(
          "x",
          (value) => x(groupKeyFn(value)) + x.bandwidth() - actualBarShadowWidth
        )
        .attr("y", (value) => y(value.actualValue))
        .attr("width", actualBarShadowWidth)
        .attr("height", (value) =>
          Math.max(0, graphHeight - y(value.actualValue))
        )
        .attr("fill", "#000")
        .attr("fill-opacity", 0.5);

      // Budget Bars
      const budgetBarWidth = x.bandwidth() + 10;
      const budgetBarHeight = actualBarWidth * 0.2;

      bars
        .append("rect")
        .filter((value) => value.budgetValue > 0)
        .attr("x", (value) => x(groupKeyFn(value)) - 5)
        .attr("y", (value) => y(value.budgetValue) - budgetBarHeight / 2)
        .attr("width", budgetBarWidth)
        .attr("height", budgetBarHeight)
        .style("filter", "url(#drop-shadow)")
        .attr("fill", colors[graphIndex * 2 + 1]);

      // Budget Lines
      const budgetLineWidth = actualBarWidth * 0.03;

      bars
        .append("line")
        .filter((value) => value.budgetValue > value.actualValue)
        .attr("x1", (value) => x(groupKeyFn(value)) + x.bandwidth() / 2)
        .attr("y1", (value) => y(value.budgetValue) + budgetBarHeight / 2)
        .attr("x2", (value) => x(groupKeyFn(value)) + x.bandwidth() / 2)
        .attr("y2", (value) => y(value.actualValue))
        .attr("stroke-width", budgetLineWidth)
        .style("stroke", colors[graphIndex * 2 + 1]);

      // Budget Bars Shadow
      const defs = svg.append("defs");
      const filter = defs
        .append("filter")
        .attr("id", "drop-shadow")
        .attr("height", "300%");
      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 2)
        .attr("result", "blur");
      filter
        .append("feOffset")
        .attr("in", "blur")
        .attr("dy", 2)
        .attr("result", "offsetBlur");

      const feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");
    });
  }

  render() {
    const { width, height } = this.props;
    return <svg ref={(el) => (this.el = el)} width={width} height={height} />;
  }
}

GroupedBarGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      groupBy: PropTypes.string.isRequired,
      fuelConsumptions: PropTypes.arrayOf(
        PropTypes.shape({
          metricName: PropTypes.string.isRequired,
          metricUnit: PropTypes.string.isRequired,
          values: PropTypes.arrayOf(
            PropTypes.shape({
              date: PropTypes.string.isRequired,
              budget: PropTypes.number.isRequired,
              actual: PropTypes.number.isRequired,
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  groupKeyFn: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hideBottomAxis: PropTypes.bool,
};
