import React, { Component } from "react";
import { connect } from "react-redux";
import { FleetConnectionStatus } from "../components/FleetConnectionStatus/FleetConnectionStatus";
import { fetchConnectionStatus } from "../actions/action.fetchConnectionStatus";
import Loader from "../../common/components/Loader/Loader";

class FleetConnectionStatusContainer extends Component {
  componentDidMount() {
    this.props.fetchConnectionStatus();
  }

  render() {
    const { data, error } = this.props.connectionStatus;

    if (!data) {
      return (
        <Loader text="Loading connection data" error={error} expand={true} />
      );
    }

    return (
      <FleetConnectionStatus
        vesselCount={data.total}
        connectedVesselCount={data.connected}
      />
    );
  }
}

export default connect(
  (state) => ({ connectionStatus: state.connectionStatus }),
  { fetchConnectionStatus }
)(FleetConnectionStatusContainer);
