import PropTypes from "prop-types";
import React from "react";
import { noop } from "lodash";
import { InlineSpinner } from "../Loader/Loader";
import styles from "./Button.css";

const readBorderStyles = (borderColor, border, borderRadius, circle) => {
  let style = {};
  if (borderColor) {
    style.borderColor = borderColor;
  }
  if (border) {
    style.border = border;
  }
  if (borderRadius) {
    style.borderRadius = borderRadius;
  }
  if (circle) {
    style.borderRadius = "50%";
  }
  return style;
};

const readColorStyles = (color, backgroundColor) => {
  let style = {};
  if (color) {
    style.color = color;
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  return style;
};

const readSpacingStyles = (margin, padding) => {
  let style = {};
  if (margin) {
    style.margin = margin;
  }
  if (padding) {
    style.padding = padding;
  }
  return style;
};

const readSizeStyles = (height, width, circle) => {
  let style = {};
  if (circle) {
    const size = Math.max(parseInt(height || 0), parseInt(width || 0));
    if (size) {
      style.height = `${size}px`;
      style.width = `${size}px`;
    }
  } else {
    if (width) {
      style.width = width;
    }
    if (height) {
      style.height = height;
    }
  }
  return style;
};

function readStyles({
  borderColor,
  border,
  color,
  backgroundColor,
  margin,
  width,
  height,
  circle,
  padding,
  borderRadius,
}) {
  const borderStyle = readBorderStyles(
    borderColor,
    border,
    borderRadius,
    circle
  );
  const colorStyle = readColorStyles(color, backgroundColor);
  const spacingStyle = readSpacingStyles(margin, padding);
  const sizeStyle = readSizeStyles(height, width, circle);
  return {
    ...borderStyle,
    ...colorStyle,
    ...spacingStyle,
    ...sizeStyle,
  };
}

export default function Button(props) {
  const {
    value,
    type,
    disabled,
    clickAction,
    mouseOverAction,
    children,
    testId,
    isLoading,
    lineColor,
  } = props;

  const content = type === "content" ? children : <span>{value}</span>;
  return (
    <button
      type="button"
      className={styles.button}
      data-testid={testId}
      data-type={type}
      onClick={clickAction}
      disabled={disabled}
      onMouseOver={mouseOverAction}
      style={readStyles(props)}
    >
      {isLoading && (
        <span className={styles.spinner}>
          <InlineSpinner />
        </span>
      )}
      {lineColor ? (
        <div className={styles.buttonTextContainer}>
          {content}
          <div
            className={styles.lineUnderText}
            style={{ background: lineColor }}
          />
        </div>
      ) : (
        content
      )}
    </button>
  );
}

Button.defaultProps = {
  type: "button",
  clickAction: noop,
};

Button.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf([
    "button",
    "smallButton",
    "link",
    "content",
    "alert",
    "dashed",
    "verySmallButton",
  ]),
  clickAction: PropTypes.func,
  disabled: PropTypes.bool,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  mouseOverAction: PropTypes.func,
  testId: PropTypes.string,
  isLoading: PropTypes.bool,
  lineColor: PropTypes.string,
  circle: PropTypes.bool,
};
