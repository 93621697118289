export const isExpired = (action) => {
  if (
    !action ||
    !action.actionMetadata ||
    action.actionMetadata.isExpired === undefined
  ) {
    return true;
  }

  return action.actionMetadata.isExpired;
};

export const isFailed = (action) => {
  if (!action || !action.actionMetadata) {
    return false;
  }

  const { completedOn, failedOn, failureAcknowledgedOn } =
    action.actionMetadata;

  // An action is considered as failed when it has failed
  // and not completed or acknowledged
  return failedOn && !completedOn && !failureAcknowledgedOn;
};

export const remoteControlDurations = [
  { length: 1200, description: "20 min" },
  { length: 3600, description: "1 hour" },
  { length: 21600, description: "6 hours" },
];
