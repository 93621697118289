import { createKey } from "../reducers/reducer.energyBudgetResult";
import {
  FETCH_ENERGYBUDGET_OVERVIEW,
  FETCH_ENERGYBUDGET_RESULT,
} from "./action.types";
import { ENERGY_BUDGET_DATA } from "../api";
import fetch from "./action.fetch";

export const fetchEnergyBudgetResult = (
  { metricId },
  { vesselId, groupId, dateRange, timeOffset, states }
) =>
  fetch({
    url: vesselId
      ? ENERGY_BUDGET_DATA.getResultForVessel(
          vesselId,
          metricId,
          dateRange,
          timeOffset,
          states
        )
      : ENERGY_BUDGET_DATA.getResultForGroup(
          groupId,
          metricId,
          dateRange,
          timeOffset
        ),
    actionType: FETCH_ENERGYBUDGET_RESULT,
    actionData: { metricId },
    key: `${FETCH_ENERGYBUDGET_RESULT}_${createKey({ metricId })}`,
  });

export const fetchEnergyBudgetOverview = ({
  metricIds,
  groupBy,
  historicalCount,
  futureCount,
}) =>
  fetch({
    url: ENERGY_BUDGET_DATA.getOverview({
      metricIds,
      groupBy,
      historicalCount,
      futureCount,
    }),
    actionType: FETCH_ENERGYBUDGET_OVERVIEW,
  });
