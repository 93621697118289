import { fitBounds } from "google-map-react";
import { extendBounds } from "../../common/map";
import createReducer from "./createReducer";
import {
  SET_MAP_VIEW_DATA,
  FIT_MAP_TO_VESSELS,
  FETCH_POSITION_DETAILS,
  FETCH_POSITION_DETAILS_SUCCESS,
  FETCH_POSITION_DETAILS_ERROR,
  CLOSE_POSITION_DETAILS,
  FETCH_MAP_DATA,
  FETCH_POSITION_TRACK,
  FETCH_POSITION_TRACK_SUCCESS,
  FETCH_POSITION_TRACK_ERROR,
  SET_VESSEL_TRACK_SCROLL_BOUNDARY,
  TOGGLE_POSITION_TRACK,
  HOVER_VESSEL_TRACK_ITEM,
  SET_ACTIVE_VESSEL_TRACK_ITEM,
  HOVER_VESSEL_TRACK_GROUP,
  CENTER_POINTS_IN_MAP,
  SET_VESSEL_TRACK_DATE,
  TOGGLE_MAP_POI,
  START_ZOOM,
  END_ZOOM,
} from "../actions/action.types";
import { now, atStartOfDay, atEndOfDay } from "../common/dates";

export const groupVesselTrack = (track) => {
  return track.reduce((acc, curr) => {
    let index = acc.length - 1;
    let group = acc[index] || {};
    let operationId = group.operationId;

    if (operationId && operationId === curr.operationId) {
      curr.index = index;
      group.track.push(curr);
    } else {
      curr.index = index + 1;
      acc.push({
        operationId: curr.operationId,
        operationName: curr.operationName,
        operationLegend: curr.operationLegend,
        track: [curr],
        index: index + 1,
      });
    }
    return acc;
  }, []);
};

export const defaultVesselTrackDate = {
  range: [atStartOfDay(now()).toISOString(), atEndOfDay(now()).toISOString()],
  value: {
    from: atStartOfDay(now()).toISOString(),
    to: atEndOfDay(now()).toISOString(),
  },
  type: "dateRange",
};

const initialState = {
  defaultCenter: {
    lat: 62.472229,
    lng: 6.149482,
  },
  vesselTrack: {
    isVisible: false,
    track: [],
    startIndex: 0,
    stopIndex: 0,
  },
  vesselTrackDate: defaultVesselTrackDate,
  defaultZoom: 4,
  showDetails: false,
  isLoadingDetails: false,
  showPoi: true,
};

export default createReducer(initialState, {
  [FETCH_MAP_DATA]: () => initialState,
  [FIT_MAP_TO_VESSELS]: (state, { vessels }) => {
    if (!state.size) {
      return { ...state, fitMapToVesselsLater: vessels };
    }
    return { ...state, ...fitVesselsToMap(vessels, state.size, state) };
  },
  [SET_MAP_VIEW_DATA]: (state, { data }) => {
    if (state.fitMapToVesselsLater && data.size) {
      return {
        ...state,
        ...data,
        ...fitVesselsToMap(state.fitMapToVesselsLater, data.size, state),
        fitMapToVesselsLater: null,
      };
    }
    return { ...state, ...data };
  },
  [FETCH_POSITION_DETAILS]: (state) => {
    return { ...state, isLoadingDetails: true, showDetails: true };
  },
  [FETCH_POSITION_DETAILS_SUCCESS]: (state) => {
    return { ...state, isLoadingDetails: false };
  },
  [FETCH_POSITION_DETAILS_ERROR]: (state) => {
    return { ...state, isLoadingDetails: false };
  },
  [CLOSE_POSITION_DETAILS]: (state) => {
    return {
      ...state,
      showDetails: false,
      vesselTrack: {
        ...state.vesselTrack,
        isVisible: false,
      },
    };
  },
  [FETCH_POSITION_TRACK]: (state) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        error: "",
        track: [],
        isLoading: true,
        startIndex: 0,
        stopIndex: 0,
      },
    };
  },
  [FETCH_POSITION_TRACK_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        track: groupVesselTrack(data.track),
        isLoading: false,
        startIndex: 0,
        stopIndex: 0,
      },
    };
  },
  [FETCH_POSITION_TRACK_ERROR]: (state, { error }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        error: error,
        isLoading: false,
        startIndex: 0,
        stopIndex: 0,
      },
    };
  },
  [TOGGLE_POSITION_TRACK]: (state) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        isVisible: !state.vesselTrack.isVisible,
      },
    };
  },
  [SET_VESSEL_TRACK_SCROLL_BOUNDARY]: (state, { startIndex, stopIndex }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        startIndex,
        stopIndex,
        activeItemIndex: null,
      },
    };
  },
  [HOVER_VESSEL_TRACK_ITEM]: (state, { item }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        hoveredItem: item,
        hoveredItemIndex: item && item.data ? item.data.index : null,
      },
    };
  },
  [SET_ACTIVE_VESSEL_TRACK_ITEM]: (state, { item }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        activeItemIndex: item.index,
      },
    };
  },
  [HOVER_VESSEL_TRACK_GROUP]: (state, { groupIndex }) => {
    return {
      ...state,
      vesselTrack: {
        ...state.vesselTrack,
        hoveredGroupIndex: groupIndex,
      },
    };
  },
  [CENTER_POINTS_IN_MAP]: (state, { points }) => {
    return {
      ...state,
      ...fitPointsToMap(points, state.size, state.zoom),
    };
  },
  [SET_VESSEL_TRACK_DATE]: (state, { date }) => {
    return {
      ...state,
      vesselTrackDate: date,
    };
  },
  [TOGGLE_MAP_POI]: (state, { showPoi }) => {
    return {
      ...state,
      showPoi: !showPoi,
    };
  },
  [START_ZOOM]: (state) => {
    return {
      ...state,
      isZooming: true,
    };
  },
  [END_ZOOM]: (state) => {
    return {
      ...state,
      isZooming: false,
    };
  },
});

const fitPointsToMap = (points, size, state) => {
  const currentCenter = state.center;
  const currentZoom = state.zoom;
  if (!points.length || !size) {
    return {
      center: currentCenter,
      zoom: currentZoom,
    };
  }
  if (points.length === 1) {
    return { center: points[0], zoom: Math.max(4, currentZoom || 0) };
  }

  const bounds = points.reduce(extendBounds, null);
  const { center, zoom } = fitBounds(bounds, size);

  return { center, zoom: Math.min(13, zoom) };
};

export const fitVesselsToMap = (vessels, size, state) => {
  let vesselPoints =
    vessels && vessels.length > 0
      ? vessels.map((v) => ({ lat: v.position.lat, lng: v.position.lng }))
      : [];
  return fitPointsToMap(vesselPoints, size, state);
};
