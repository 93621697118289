import { FETCH_ENERGYBUDGET_RESULT } from "../actions/action.types";
import { createIndexedRequestReducer } from "./createRequestReducer";
import { getConvertSpec, roundNumber } from "../../common/numbers";

export const createKey = ({ metricId }) => String(metricId).toLocaleLowerCase();

export const prepareEnergyBudgetData = (data) => {
  return data.map((d) => {
    const convertSpec = {
      budget: getConvertSpec(d.budgetValue, d.metricUnit),
      actual: getConvertSpec(d.actualValue, d.metricUnit),
    };

    // Can't convert if different units between actual and budget
    if (
      convertSpec.actual.unit !== convertSpec.budget.unit &&
      d.budgetValue !== 0 &&
      d.actualValue !== 0
    ) {
      return d;
    }

    return {
      ...d,
      budgetValue: roundNumber(
        d.budgetValue / convertSpec.budget.conversion,
        2
      ),
      actualValue: roundNumber(
        d.actualValue / convertSpec.actual.conversion,
        2
      ),
      metricUnit:
        d.actualValue !== 0 ? convertSpec.actual.unit : convertSpec.budget.unit,
    };
  });
};

export default createIndexedRequestReducer(
  FETCH_ENERGYBUDGET_RESULT,
  createKey,
  prepareEnergyBudgetData
);

export const getEnergyBudgetResult = (params, state) =>
  state[createKey(params)] || {};
