import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchEnergyBudgetResult } from "../actions/action.energyBudget";
import { isContextChanged, isContextValid } from "../common/contexts";
import EnergyBudgetResult from "../components/EnergyBudget/EnergyBudgetResult";
import { getEnergyBudgetResult } from "../reducers/reducer.energyBudgetResult";

class EnergyBudgetResultContainer extends Component {
  componentDidMount() {
    this.fetch();
  }

  shouldComponentUpdate(nextProps) {
    return (
      isContextChanged(nextProps.context, this.props.context) ||
      nextProps.energyBudgetResult !== this.props.energyBudgetResult
    );
  }

  componentDidUpdate(prevProps) {
    if (isContextChanged(this.props.context, prevProps.context)) {
      this.fetch();
    }
  }

  fetch() {
    if (isContextValid(this.props.context)) {
      this.props.fetchEnergyBudgetResult(
        this.props.config.params,
        this.props.context
      );
    }
  }

  render() {
    return <EnergyBudgetResult {...this.props.energyBudgetResult} />;
  }
}

EnergyBudgetResultContainer.propTypes = {
  config: PropTypes.shape({
    params: PropTypes.shape({
      metricId: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
      ]).isRequired,
    }).isRequired,
  }).isRequired,
  context: PropTypes.shape({
    vesselId: PropTypes.string,
    groupId: PropTypes.string,
    dateRange: PropTypes.object.isRequired,
  }).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    energyBudgetResult: getEnergyBudgetResult(
      ownProps.config.params,
      state.energyBudgetResult
    ),
  };
}

const actions = {
  fetchEnergyBudgetResult,
};

export default connect(mapStateToProps, actions)(EnergyBudgetResultContainer);
