import React from "react";
import styles from "./ColumnInfo.css";

export const ColumnInfo = () => (
  <div className={styles.container}>
    <div className={styles.sectionContainer}>
      <h2 className={styles.header}>Consumption</h2>
      <p className={styles.text}>
        Total sum of fuel consumption within the chosen time interval.
      </p>
    </div>
    <div className={styles.sectionContainer}>
      <h2 className={styles.header}>Trend</h2>
      <p className={styles.text}>
        The average consumption in the selected time interval compared to the
        previous interval of the same length. Eg. if you select a month, the
        number is the average consumption in the the selected month compared to
        the average consumption in the previous month.
      </p>
      <p className={styles.text}>
        The graph shows the average consumption in the previous and selected
        time interval
      </p>
    </div>
    <div className={styles.sectionContainer}>
      <h2 className={styles.header}>Operation Columns</h2>
      <p className={styles.text}>The average fuel consumption per hour.</p>
    </div>
  </div>
);
