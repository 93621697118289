import { IFuelConsumptionData } from "../../reducers/reducer.fuelEmissions";

export const fuelConsumptionsResponse: IFuelConsumptionData = {
  fuels: ["Mdo"],
  operationGroups: [
    { operationGroup: "P", operationProfiles: ["Maneuver", "Port"] },
    { operationGroup: "O", operationProfiles: ["Other"] },
    {
      operationGroup: "T",
      operationProfiles: ["Transit", "Eco Transit", "Fast Transit"],
    },
    { operationGroup: "DP", operationProfiles: ["DP Class 2", "DP"] },
  ],
  fuelConsumptionPerOperationGroup: [
    {
      fuel: "Mdo",
      consumptions: [
        { operationGroup: "O", value: 0.087288 },
        { operationGroup: "P", value: 0.10542 },
        { operationGroup: "T", value: 0.264961 },
        { operationGroup: "DP", value: 0.115646 },
      ],
      previousConsumptions: [
        { operationGroup: "O", value: 0.120481 },
        { operationGroup: "P", value: 0.103646 },
        { operationGroup: "T", value: 0.248567 },
        { operationGroup: "DP", value: 0.113464 },
      ],
      series: [
        {
          operationGroup: "O",
          series: [
            { y: 0.087318, x: "2018-01-01T00:00:00.000Z" },
            { y: 0.082799, x: "2018-08-01T00:00:00.000Z" },
          ],
        },
        {
          operationGroup: "P",
          series: [
            { y: 0.091916, x: "2018-01-01T00:00:00.000Z" },
            { y: 0.094545, x: "2018-02-01T00:00:00.000Z" },
            { y: 0.126423, x: "2018-03-01T00:00:00.000Z" },
            { y: 0.103026, x: "2018-04-01T00:00:00.000Z" },
            { y: 0.108967, x: "2018-05-01T00:00:00.000Z" },
            { y: 0.092822, x: "2018-06-01T00:00:00.000Z" },
            { y: 0.103059, x: "2018-07-01T00:00:00.000Z" },
            { y: 0.095302, x: "2018-08-01T00:00:00.000Z" },
            { y: 0.123733, x: "2018-09-01T00:00:00.000Z" },
            { y: 0.10745, x: "2018-10-01T00:00:00.000Z" },
            { y: 0.09427, x: "2018-11-01T00:00:00.000Z" },
            { y: 0.12028, x: "2018-12-01T00:00:00.000Z" },
          ],
        },
        {
          operationGroup: "T",
          series: [
            { y: 0.257553, x: "2018-01-01T00:00:00.000Z" },
            { y: 0.238011, x: "2018-02-01T00:00:00.000Z" },
            { y: 0.272792, x: "2018-03-01T00:00:00.000Z" },
            { y: 0.260228, x: "2018-04-01T00:00:00.000Z" },
            { y: 0.288557, x: "2018-05-01T00:00:00.000Z" },
            { y: 0.261145, x: "2018-06-01T00:00:00.000Z" },
            { y: 0.258357, x: "2018-07-01T00:00:00.000Z" },
            { y: 0.26763, x: "2018-08-01T00:00:00.000Z" },
            { y: 0.272796, x: "2018-09-01T00:00:00.000Z" },
            { y: 0.278623, x: "2018-10-01T00:00:00.000Z" },
            { y: 0.271708, x: "2018-11-01T00:00:00.000Z" },
            { y: 0.253483, x: "2018-12-01T00:00:00.000Z" },
          ],
        },
        {
          operationGroup: "DP",
          series: [
            { y: 0.113885, x: "2018-01-01T00:00:00.000Z" },
            { y: 0.11192, x: "2018-02-01T00:00:00.000Z" },
            { y: 0.1258, x: "2018-03-01T00:00:00.000Z" },
            { y: 0.112321, x: "2018-04-01T00:00:00.000Z" },
            { y: 0.142338, x: "2018-05-01T00:00:00.000Z" },
            { y: 0.113357, x: "2018-06-01T00:00:00.000Z" },
            { y: 0.129108, x: "2018-07-01T00:00:00.000Z" },
            { y: 0.1214, x: "2018-08-01T00:00:00.000Z" },
            { y: 0.109985, x: "2018-09-01T00:00:00.000Z" },
            { y: 0.112309, x: "2018-10-01T00:00:00.000Z" },
            { y: 0.100172, x: "2018-11-01T00:00:00.000Z" },
            { y: 0.106937, x: "2018-12-01T00:00:00.000Z" },
          ],
        },
      ],
    },
  ],
};
