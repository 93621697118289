module.exports = {
	customMedia: {

	},
	customProperties: {
		'--c-black': '#000',
		'--c-brightblack': '#58677a',
		'--c-red': '#a3334b',
		'--c-darkred': '#b3334b',
		'--c-brightred': '#fc406a',
		'--c-green': '#39bf65',
		'--c-brightgreen': '#4af381',
		'--c-yellow': '#dfbd4c',
		'--c-brightyellow': '#ffc100',
		'--c-blue': '#4367ac',
		'--c-brightblue': '#4c8bff',
		'--c-purple': '#795eab',
		'--c-brightpurple': '#b872ff',
		'--c-cyan': '#2291a0',
		'--c-brightcyan': '#2ee4f4',
		'--c-white': '#ddeeef',
		'--c-brightwhite': '#fff',
		'--c-orange': '#ff9933',
		'--c-brightorange': '#ffad5c',
		'--c-sardinia': '#279bce',
		'--bright-green': '#4af381',
		'--normal-green': '#39bf65',
		'--dark-green': '#218141',
		'--bright-red': '#fc406a',
		'--normal-red': '#e84569',
		'--magenta-normal': '#cf328d',
		'--black': '#000',
		'--dark-grey': '#2b313f',
		'--dark-mid-grey': '#262b37',
		'--mid-grey': '#58677a',
		'--light-grey': '#8395ac',
		'--lighter-grey': '#3b4859',
		'--white': '#ffffff',
		'--grey-000': '#0d0e0e',
		'--grey-100': '#272a2a',
		'--grey-200': '#414646',
		'--grey-300': '#5b6262',
		'--grey-400': '#757e7e',
		'--grey-500': '#8f9a9b',
		'--grey-600': '#a9b6b7',
		'--grey-700': '#c3d2d3',
		'--grey-800': '#ddeeef',
		'--blue-darkest': '#195362',
		'--blue-dark': '#2291a0',
		'--blue-base': '#2ee4f4',
		'--blue-bright': '#abf4fa',
		'--blue-brightest': '#d5f9fc',
		'--red-darkest': '#5c282c',
		'--red-dark': '#914652',
		'--red-base': '#e76670',
		'--red-bright': '#ee949b',
		'--red-brightest': '#fae0e2',
		'--yellow-darkest': '#5f5228',
		'--yellow-dark': '#bda450',
		'--yellow-base': '#efce65',
		'--yellow-bright': '#f3dc93',
		'--yellow-brightest': '#fbf5e0',
		'--orange-darkest': '#663d21',
		'--orange-dark': '#995c31',
		'--orange-base': '#ff9a53',
		'--orange-bright': '#ffb886',
		'--orange-brightest': '#ffeadc',
		'--page-background': '#0f1114',
		'--content-card-bg': '#171b25',
		'--text-normal': 'var(--light-grey)',
		'--screen-bg': 'var(--page-background)',
		'--content-card-bg-light': 'var(--dark-grey)',
		'--almost-black': 'var(--page-background)',
		'--op-m-blue': 'var(--blue-dark)',
		'--op-t-blue': 'var(--blue-base)',
		'--op-ft-blue': 'var(--blue-bright)',
		'--op-ss-blue': 'var(--blue-darkest)',
		'--op-ato-red': 'var(--red-bright)',
		'--op-ate-red': 'var(--red-base)',
		'--op-adp-red': 'var(--red-dark)',
		'--op-dt-yellow': 'var(--yellow-base)',
		'--op-st-yellow': 'var(--yellow-dark)',
		'--op-dp2-orange': 'var(--orange-base)',
		'--op-dps-orange': 'var(--orange-dark)',
		'--op-e-grey': 'var(--grey-600)',
		'--op-p-grey': 'var(--grey-500)',
		'--op-o-grey': 'var(--grey-300)',
		'--placeholder-grey': 'var(--grey-500)',
		'--fontSize': '1rem',
		'--primary-font': '"Roboto", sans-serif',
		'--font-size--xl': '3rem',
		'--font-size--l': '2rem',
		'--font-size--ls': '1.75rem',
		'--font-size--ml': '1.25rem',
		'--font-size--m': '1rem',
		'--font-size--ms': '0.9rem',
		'--font-size--s': '0.75rem',
		'--font-size--xs': '0.625rem',
		'--header-height': '5rem',
		'--subHeader-height': '5rem',
		'--compare-table-header-cell-height': '6.7rem',
		'--compare-table-cell-height': '6.7rem',
		'--compare-table-large-cell-height': '32rem',
		'--compare-table-cell-width': '24rem',
		'--compare-table-gutter': '0.2rem',
		'--grid-cell-width': '20%',
		'--grid-cell-height': '30.3125rem',
		'--grid-cell-padding': '0.25rem',
		'--header-z-index': '10',
		'--sticky-subHeader-z-index': '9',
		'--system-notification-z-index': '11',
		'--breakpoint-l': '1900px',
		'--breakpoint-m': '1200px'
	},
	customSelectors: {

	}
};
