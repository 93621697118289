import React, { useEffect } from "react";
import { EmissionsProps, EmissionsState } from "./Types";
import ComponentInfo from "../../../common/components/ComponentInfo/ComponentInfo";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmissionsByOperationModes } from "../../actions/action.fetchEmissionsByOperationModes";
import commonStyles from "../MainDashboard/MainDashboard.css";
import styles from "./Emissions.css";
import DonutContainer from "../../components/DonutContainer";
import Loader from "../../../common/components/Loader";
import {
  EmissionsByOperationModesDictionary,
  EmissionsByOperationModesState,
  EmissionsType,
  getEmissionsTitle,
  DonutData,
} from "./EmissionsType";

function getReadyDonuts(
  lists: EmissionsByOperationModesDictionary,
  types: EmissionsType[]
): DonutData[] {
  return types.map((type) => lists[getEmissionsTitle(type)]);
}

function getDonuts(stateData: EmissionsByOperationModesState): DonutData[] {
  return stateData.isLoading
    ? []
    : getReadyDonuts(stateData.emissionsByOperationModesLists, [
        EmissionsType.CO2,
        EmissionsType.NOx,
      ]);
}

const Emissions: React.FC<EmissionsProps> = ({
  interval,
  group,
}: EmissionsProps) => {
  const donuts = useSelector((state: EmissionsState) => {
    return getDonuts(state.emissionsByOperationModes);
  });
  const toolTipHeader = "Emissions";
  const toolTip =
    "Shows the distribution of emissions by operation type in the given date interval.";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmissionsByOperationModes(interval, group));
  }, [interval, group]);

  if (donuts.length === 0) {
    return <Loader text={"Loading"} expand={true} />;
  } else {
    return (
      <ComponentInfo
        modalTargetId="popupContainer"
        title={toolTipHeader}
        text={toolTip}
      >
        <div className={styles.emissionsActivity}>
          <div className={commonStyles.componentHeader}>Emissions</div>
          <DonutContainer donuts={donuts} testId="widget-emissionsDonuts" />
        </div>
      </ComponentInfo>
    );
  }
};

export default Emissions;
